import combineReducers from 'combine-reducers-global-state';
import { reducer as filters } from '../Modules/filters';
import { reducer as spinner } from '../Modules/spinner';
import { reducer as truckAlertEvents } from '../Modules/truckAlertEvents';
import { reducer as minesiteAreasTree } from '../Modules/minesiteAreasTree';
import { reducer as mapFeatures } from '../Modules/mapFeatures';
import { reducer as mapLabels } from '../Modules/mapLabels';
import { reducer as WKTAggregate } from '../Modules/WKTAggregate';
import { reducer as minesiteMap } from '../Modules/minesiteMap';
import namespace from '../Modules/namespace';
import {
    moduleName as mapLegendsName,
    reducer as mapLegends,
} from '../Modules/mapLegends';
import {
    moduleName as mapFeaturesName,
    reducer as mapFeatures_v2,
} from '../Modules/mapFeatures_v2';

export const REDUCER_KEY = namespace;
export default combineReducers({
    filters,
    spinner,
    truckAlertEvents,
    minesiteAreasTree,
    mapFeatures,
    mapLabels,
    WKTAggregate,
    minesiteMap,
    [mapLegendsName]: mapLegends,
    [mapFeaturesName]: mapFeatures_v2,
});
