import { put, call, all, takeLatest } from 'redux-saga/effects';
import * as resourceSagas from './resourceSagas';
import * as wiki from '../Help/Modules/wiki';

export function* fetchWikiRequested({ payload }) {
    const { response, error } = yield call(resourceSagas.getWikiByContentId, {
        ContentId: payload.contentId,
    });
    if (error) {
        yield put(
            wiki.actions.fetchWikiError({
                error: 'Failed to load Wiki resource',
            }),
        );
        return;
    }
    yield put(wiki.actions.fetchWikiSuccess(response));
}

export default function* watch() {
    yield all([
        takeLatest(wiki.actions.FETCH_WIKI_REQUESTED, fetchWikiRequested),
    ]);
}
