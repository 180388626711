import combineReducers from 'combine-reducers-global-state';
import filters from './filters';
import spinner from './spinner';
import surfaceRoughness from './surfaceRoughness';
import wktData from './wktData';
import ui from './ui';
import mapFeatures from './mapFeatures';
import namespace from '../Modules/namespace';
import { reducer as haulRoadConditionSummary } from '../Modules/haulRoadConditionSummary';
import { reducer as haulRoadConditionSummaryLatest } from '../Modules/haulRoadConditionSummaryLatest';
import { reducer as minesiteAreasTree } from '../Modules/minesiteAreasTree';
import { reducer as haulRoadConditionEvents } from '../Modules/haulRoadConditionEvents';
import { reducer as minesiteMap } from '../Modules/minesiteMap';
import {
    reducer as mapLegends,
    moduleName as mapLegendsName,
} from '../Modules/mapLegends';
import {
    reducer as mapFeatures_v2,
    moduleName as mapFeaturesName,
} from '../Modules/mapFeatures';

export const REDUCER_KEY = namespace;
export default combineReducers({
    filters,
    spinner,
    surfaceRoughness,
    wktData,
    ui,
    haulRoadConditionSummary,
    mapFeatures,
    haulRoadConditionSummaryLatest,
    minesiteAreasTree,
    haulRoadConditionEvents,
    minesiteMap,
    [mapLegendsName]: mapLegends,
    [mapFeaturesName]: mapFeatures_v2,
});
