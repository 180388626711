import { combineReducers } from 'redux';
import { reducer as filters } from '../Modules/filters';
import { reducer as spinner } from '../Modules/spinner';
import { reducer as engineDiagnosticCurves } from '../Modules/engineDiagnosticCurves';
import { reducer as shiftLoggerDeployments } from '../Modules/shiftLoggerDeployments';
import namespace from '../Modules/namespace';

export const REDUCER_KEY = namespace;
export default combineReducers({
    filters,
    spinner,
    engineDiagnosticCurves,
    shiftLoggerDeployments,
});
