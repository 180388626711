import { createReducer } from '../../Lib/reduxUtils';
import { LOAD_LOADING_EVENTS_CONFORMANCE_REPORT } from '../Actions';

export const initialState = {
    rows: [],
    total: {},
};

export const setLoaderResults = (state: any, { rows, total }: any) => ({
    rows,
    total,
});

export default createReducer(initialState, {
    [LOAD_LOADING_EVENTS_CONFORMANCE_REPORT]: setLoaderResults,
});
