import namespace from './namespace';
import { createCustomSlice } from '@rs/core/falcon/components/SearchBox/redux/redux';

export const moduleName = 'equipmentSearch';
export const selectState = (state: any) => {
    return state[namespace][moduleName];
};

const equipmentSearch = createCustomSlice(`${namespace}/${moduleName}`);

export const reducer = equipmentSearch.reducer;
export const actions = equipmentSearch.actions;
