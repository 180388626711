import createActions from '../../Modules/speedLimitedZones/actions';
import createReducer, {
    onSuccess,
} from '../../Modules/speedLimitedZones/reducer';
import createSelectors from '../../Modules/speedLimitedZones/selectors';
import { LOAD_RESULTS_SUCCESS } from '../Actions';
import namespace from './namespace';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].speedLimitedZones;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);

export const reducer = createReducer(
    {
        onSuccess: actions.FETCH_SPEED_LIMITED_ZONES_SUCCESS,
        onError: actions.FETCH_SPEED_LIMITED_ZONES_ERROR,
    },
    {
        [LOAD_RESULTS_SUCCESS]: (state, { speedLimitedZones }) =>
            onSuccess(state, speedLimitedZones),
    },
);

export const selectors = createSelectors(statePath);
