import React from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from '../Button/Button';

const commonProps = {
    format: 'DD/MM/YYYY',
    className: 'DatePicker__Input',
    dropdownClassName: 'DatePicker__Dropdown',
    allowClear: false,
};

const SinglePicker = ({ onChange, ...props }) => (
    <AntDatePicker
        {...commonProps}
        onChange={(newMoment) => {
            // Don't allow empty values in the datepicker
            if (newMoment) {
                onChange(newMoment, 'single');
            }
        }}
        {...props}
    />
);

const RangePicker = ({ onChange, today, ...props }) => (
    <AntDatePicker.RangePicker
        {...commonProps}
        ranges={{
            'Last 7 days': [today.clone().subtract(6, 'days'), today.clone()],
            'Last 30 days': [today.clone().subtract(29, 'days'), today.clone()],
        }}
        onChange={(newMomentArray) => {
            // Don't allow empty values in the datepicker
            if (newMomentArray.length !== 0) {
                onChange(newMomentArray, 'range');
            }
        }}
        {...props}
    />
);

const DatePicker = ({
    label,
    mode,
    onModeChange,
    allowModeSwitch,
    ...props
}) => {
    const PickerComponent = mode === 'range' ? RangePicker : SinglePicker;

    return (
        <div className={'DatePicker'}>
            {
                // If we don't have a label or a switcher don't render anything
                (label || allowModeSwitch) && (
                    <div className={'DatePicker__Label'}>
                        <span>
                            <label>{label}</label>
                        </span>
                        {allowModeSwitch && (
                            <Button
                                type={'link'}
                                onClick={() => {
                                    if (mode === 'range') {
                                        onModeChange('single');
                                    } else {
                                        onModeChange('range');
                                    }
                                }}
                            >
                                {mode === 'range'
                                    ? 'Switch to single date'
                                    : 'Switch to date range'}
                            </Button>
                        )}
                    </div>
                )
            }
            <PickerComponent {...props} />
        </div>
    );
};

DatePicker.defaultProps = {
    mode: 'single',
    allowModeSwitch: true,
    today: moment(),
};

DatePicker.propTypes = {
    // Label to display on top of the picker
    label: PropTypes.string,
    // What mode of date picker to use, single or range
    mode: PropTypes.oneOf(['single', 'range']),
    // Whether to allow switching between single and range mode
    allowModeSwitch: PropTypes.bool,
    // Callback for when the mode is change
    onModeChange: PropTypes.func,
    // Callback for when the selected date/s are changed
    onChange: PropTypes.func,
    // Value for the datepicker, can be a single moment or an length 2 array of moments
    value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
    // Moment for todays date in the correct timezone
    today: PropTypes.shape({
        clone: PropTypes.func,
    }),
};

RangePicker.propTypes = {
    onChange: PropTypes.func,
    // Moment for todays date in the correct timezone
    today: PropTypes.shape({
        clone: PropTypes.func,
    }),
};
SinglePicker.propTypes = {
    onChange: PropTypes.func,
};

export default DatePicker;
