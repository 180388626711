import { createHashHistory } from 'history';

const history = createHashHistory();

/**
 * Pushes a new location onto the history stack.
 * Used to navigate to a new page.
 * @param {string} location
 */
export const forwardTo = (location) => {
    history.push(location);
};

/**
 * Replaces a location on the history stack.
 * Used for sub page navigation where we don't want "Back" button
 * behaviour on the browser
 * @param {string} location
 */
export const replaceWith = (location) => {
    history.replace(location);
};

export default history;
