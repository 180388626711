import React from 'react';
import * as types from '../../types';
import PropTypes from 'prop-types';

// This must remain a class component as we are passing a ref
class ComponentToPrint extends React.Component {
    render() {
        return (
            <div className={this.props.className}>{this.props.children}</div>
        );
    }
}

ComponentToPrint.propTypes = {
    className: PropTypes.string,
    children: types.typeChildren,
};

export default ComponentToPrint;
