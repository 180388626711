import React from 'react';
import { LayerGroup, LayersControl, Marker, Pane } from 'react-leaflet';
import PropTypes from 'prop-types';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import cn from 'classnames';
import { createClusterMarker, createIconMarker, markers } from './createMarker';
import { ZINDEX } from './constants';
import shouldComponentUpdateUtil from './utils/shouldComponentUpdate';

function createTruckAlertIconMarker(type, severity) {
    const imgSrc = markers[type];
    if (!imgSrc) {
        throw new Error(`Invalid marker type, got: ${type}`);
    }

    const classNames = cn('Marker', `Marker--${type}`);
    const markerBackgroundClass = `Marker__Background--${severity.toLowerCase()}`;

    return createIconMarker(imgSrc, classNames, markerBackgroundClass);
}

function createTruckAlertClusterMarker(cluster, severity) {
    const markerBackgroundClass = `MinesiteMap__MarkerClusterContainer--${severity.toLowerCase()}`;
    return createClusterMarker(cluster, markerBackgroundClass);
}

class TruckAlertEvents extends React.Component {
    static propTypes = {
        // The text that will be displayed in the layer control
        name: PropTypes.string.isRequired,
        // The initial state of if to display the layer
        checked: PropTypes.bool.isRequired,
        // TODO array of TruckAlertEvents
        data: PropTypes.arrayOf(PropTypes.shape({})),
        // This applies the correct classes based on the level of the event
        severity: PropTypes.oneOf(['Low', 'Medium', 'High']).isRequired,
        // The order a layer should be rendered in, higher number displays on top
        priority: PropTypes.number.isRequired,
    };

    shouldComponentUpdate(nextProps) {
        return shouldComponentUpdateUtil(this.props, nextProps);
    }

    render() {
        const {
            data,
            name,
            checked,
            severity,
            priority,
            ...leafletInjectedProps
        } = this.props;

        const zIndex = ZINDEX.MAP_PANE + priority;
        const paneId = `TruckAlertEvents-${severity}`;

        if (!data || !Array.isArray(data) || !data.length) {
            return null;
        }
        return (
            <LayersControl.Overlay
                {...leafletInjectedProps}
                checked={checked}
                name={name}
            >
                <LayerGroup>
                    <Pane name={paneId} style={{ zIndex }}>
                        <MarkerClusterGroup
                            clusterPane={paneId}
                            iconCreateFunction={(cluster) =>
                                createTruckAlertClusterMarker(cluster, severity)
                            }
                            chunkedLoading={true}
                        >
                            {data
                                .filter(
                                    (row) =>
                                        row.Latitude !== null &&
                                        row.Longitude !== null,
                                )
                                .map((row, i) => (
                                    <Marker
                                        icon={createTruckAlertIconMarker(
                                            'truckAlert',
                                            severity,
                                        )}
                                        key={i}
                                        position={[row.Latitude, row.Longitude]}
                                    />
                                ))}
                        </MarkerClusterGroup>
                    </Pane>
                </LayerGroup>
            </LayersControl.Overlay>
        );
    }
}

export default TruckAlertEvents;
