import React from 'react';
import cn from 'classnames';
import { LoadUnitImprovementSymbolProps } from './types';

export const ARROW_DIRECTIONS = {
    OFFSIDE: 0,
    ONSIDE: 4,
    HEADBOARD: 2,
    REAR_OF_TRAY: 6,
    TRUCK_FRONT_RIGHT: 1,
    TRUCK_FRONT_LEFT: 3,
    TRUCK_BACK_RIGHT: 7,
    TRUCK_BACK_LEFT: 5,
};

type Props = LoadUnitImprovementSymbolProps & {
    arrowDirection?: number;
};

const PayloadBiasDirectionSymbol = ({
    arrowDirection,
    size,
    className,
}: Props) => (
    <i
        style={{ transform: `rotate(${arrowDirection * -45}deg)` }}
        className={cn('fa fa-long-arrow-right', size, className)}
    />
);

PayloadBiasDirectionSymbol.defaultProps = {
    size: 'fa-2x',
};

export default PayloadBiasDirectionSymbol;
