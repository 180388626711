import { createSelector } from 'reselect';
import { scaleThreshold } from 'd3-scale';
import { arrayToGroupedObject } from '@rs/core/utils/dataUtils';
import errorLogging from '@rs/core/utils/errorLogging';
import { REDUCER_KEY } from '../Reducers/index';
import {
    binPathSegments,
    combinePathSegmentsByClassification,
    formatPathSegmentsForMapDisplay,
} from '../../Utils/surfaceRoughness/surfaceRoughness';
import { selectors as minesiteMapSelectors } from '../Modules/minesiteMap';
import getFilters from './filters/getFilters';
import { selectors as haulRoadConditionSummarySelectors } from '../Modules/haulRoadConditionSummary';
import { createSortByPropertyFn } from '../../Lib/sortingUtils';
import { getComponentConfig } from '../../App/Selectors';

const {
    getHaulRoadConditionSummaryByShiftId,
} = haulRoadConditionSummarySelectors;

export const getSurfaceRoughness = (state) =>
    state[REDUCER_KEY].surfaceRoughness;

export const getSurfaceRoughnessByFilters = createSelector(
    getFilters,
    getSurfaceRoughness,
    (filters, surfaceRoughness) =>
        surfaceRoughness.byShiftId[filters.MapShiftId] || [],
);

export const getFilteredSurfaceRoughness = createSelector(
    getSurfaceRoughnessByFilters,
    minesiteMapSelectors.getEnabledLoadKeys,
    (state) => {
        const filters = getFilters(state);
        return getHaulRoadConditionSummaryByShiftId(state, filters.MapShiftId);
    },
    // TODO: Deprecate this in favour of falcon
    (state) => getComponentConfig(state, 'HaulRoadConditionPlotConfig'),
    (
        surfaceRoughness,
        enabledHRCLoadKeys,
        haulRoadConditionSummary,
        plotConfig,
    ) => {
        if (!surfaceRoughness.length || !haulRoadConditionSummary.length) {
            return {
                paths: [],
            };
        }

        const meanKeys = enabledHRCLoadKeys;

        const surfaceRoughnessByWhereOnMineId = arrayToGroupedObject(
            surfaceRoughness,
            'WomId',
        );
        const haulRoadConditionSummaryByWhereOnMineId = arrayToGroupedObject(
            haulRoadConditionSummary,
            'WhereOnMinesiteId',
        );

        const pathSegmentsByWhereOnMineId = Object.keys(
            surfaceRoughnessByWhereOnMineId,
        ).reduce((collection, womId) => {
            const pathSegments = surfaceRoughnessByWhereOnMineId[womId];
            const scales = haulRoadConditionSummaryByWhereOnMineId[womId] || [];
            if (!haulRoadConditionSummaryByWhereOnMineId[womId]) {
                errorLogging.logException(
                    new Error(
                        'HaulRoadCondition - surfaceRoughness & haulRoadConditionSummaries WOMIDs do not align. This may indicate a problem with the data',
                    ),
                );
            }
            const sortByBinLowerThreshold = createSortByPropertyFn(
                'BinLowerThreshold',
            );
            const loadedScaleDomain = scales
                .filter((d) => d.MeasurementType === 'Loaded')
                .sort(sortByBinLowerThreshold)
                .map((d) => d.BinUpperThreshold);

            const unLoadedScaleDomain = scales
                .filter((d) => d.MeasurementType === 'Unloaded')
                .sort(sortByBinLowerThreshold)
                .map((d) => d.BinUpperThreshold);

            const loadedBinScale = scaleThreshold()
                .domain(loadedScaleDomain)
                .range([...plotConfig.scaleRange]);

            const unloadedBinScale = scaleThreshold()
                .domain(unLoadedScaleDomain)
                .range([...plotConfig.scaleRange]);

            collection[womId] = binPathSegments(
                meanKeys,
                loadedBinScale,
                unloadedBinScale,
                pathSegments,
            );
            return collection;
        }, {});

        const combinedPathSegments = combinePathSegmentsByClassification(
            pathSegmentsByWhereOnMineId,
        );
        const paths = formatPathSegmentsForMapDisplay(combinedPathSegments);

        return {
            paths,
        };
    },
);
