import { arrayToObject } from '@rs/core/utils/dataUtils';
import { createReducer } from '../../Lib/reduxUtils';

const initialState = {
    byEmail: {},
};

export function onSuccess(state, results) {
    const normalisedResults = results.map((user) => ({
        ...user,
        normalisedEmail: user.Email.toLowerCase(),
    }));
    const byEmail = arrayToObject(normalisedResults, 'normalisedEmail');
    return {
        byEmail,
    };
}

export function onError() {
    return initialState;
}

export function reset() {
    return initialState;
}

export default function createReducers(actions, customActions = {}) {
    return createReducer(initialState, {
        [actions.onSuccess]: onSuccess,
        [actions.onError]: onError,
        /**
         * Ensure any custom action handlers pass along state + any additional arguments needed for the
         * action handlers
         */
        ...customActions,
    });
}
