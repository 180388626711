import { createSelector } from 'reselect';
import { selectors as minesiteMapSelectors } from '../Modules/minesiteMap';

const getPolygonLayers = createSelector(
    minesiteMapSelectors.getMinesiteMapPolygonLayers,
    (layers) => {
        return Object.keys(layers).reduce((a, b) => {
            a[b] = layers[b] === true ? 'VISIBLE' : 'HIDDEN';
            return a;
        }, {});
    },
);

export default getPolygonLayers;
