import { createReducer } from '../../Lib/reduxUtils';

const initialState = {
    features: [],
};

export function onSuccess(state, results) {
    const features = results.map((feature) => ({
        ...feature,
        Status: false,
    }));
    return {
        features,
    };
}

export function onError() {
    return initialState;
}

export default function createReducers(actions, customActions = {}) {
    return createReducer(initialState, {
        [actions.onSuccess]: onSuccess,
        [actions.onError]: onError,
        /**
         * Ensure any custom action handlers pass along state + any additional arguments needed for the
         * action handlers
         */
        ...customActions,
    });
}
