export default function createSelectors(statePath: any) {
    function get(state: any) {
        const slice = statePath(state);
        const isViewportValid = Array.isArray(slice.center);
        return {
            ...slice,
            isViewportValid,
        };
    }

    function getMaxBounds(state: any) {
        const slice = statePath(state);
        return slice.maxBounds;
    }

    function getBounds(state: any) {
        const slice = statePath(state);
        return slice.bounds;
    }

    function getMineSiteMapValues(state: any) {
        const slice = statePath(state);
        return {
            satellite: slice.satellite,
            aerial: slice.aerial,
            roadPath: slice.roadPath,
        };
    }

    function getMinesiteMapPolygonLayers(state: any) {
        const slice = statePath(state);
        return {
            speedRestrictedZones: slice.speedRestrictedZones,
            materialMovement: slice.materialMovement,
            mineFeatures: slice.mineFeatures,
            mineRegions: slice.mineRegions,
        };
    }
    return {
        get,
        getMaxBounds,
        getBounds,
        getMineSiteMapValues,
        getMinesiteMapPolygonLayers,
    };
}
