import { action } from '../../Lib/reduxUtils';

export const SUBMIT_CREATE_USER = 'CreateNewUser/SUBMIT_CREATE_USER';
export const submitCreateUser = (userInfo) => {
    return action(SUBMIT_CREATE_USER, { userInfo });
};

export const CREATE_USER_ERROR = 'CreateNewUser/CREATE_USER_ERROR';
export const createUserError = (apiErrorMessage) => {
    return action(CREATE_USER_ERROR, apiErrorMessage);
};

export const CREATE_USER_SUCCESS = 'CreateNewUser/CREATE_USER_SUCCESS';
export const createUserSuccess = (apiSuccessMessage) =>
    action(CREATE_USER_SUCCESS, apiSuccessMessage);

export const RESET_CREATE_USER_FORM = 'CreateNewUser/RESET_CREATE_USER_FORM';
export const resetCreateUserForm = () => action(RESET_CREATE_USER_FORM);
