import { TypeWKTLocus, TypeWKTLocusGeoJSON } from '../../types';

/**
 * Selects the geoJSON from a WKTShiftAggregateLocus response
 * @param locus
 */
export default function getWKTResponseGeoJSON(
    locus: TypeWKTLocus[],
): undefined | TypeWKTLocusGeoJSON {
    if (!Array.isArray(locus) || !locus.length) {
        return undefined;
    }
    return locus[0].GeoJSON;
}
