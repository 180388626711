import React from 'react';
import LoadingIcon from '@ant-design/icons/LoadingOutlined'
import { Spin as AntSpin } from 'antd';

// Overload the spinner to use another spinning icon
const Spin = ({ ...props }) => {
    return (
        <AntSpin
            indicator={<LoadingIcon data-testid={'spinner'} />}
            {...props}
        />
    );
};

Spin.propTypes = AntSpin.propTypes;

export default Spin;
