import * as React from 'react';

function SvgHaulTruck(props) {
    return (
        <svg viewBox="0 0 1024 754.85" {...props}>
            <path
                fill="#fff200"
                d="M21.29 196.24L23.84 253l120.51 51.02 25.09 129.92 225.37 117.92 185 29.87L687.63 438.9l88.5 4.32 72.52 163.99h146.57l-20-205-36.35-38.63-1.36-61.72-251.03 4.32v72.93l-77.51 2.46 38.27-298.96L21.29 196.24z"
            />
            <path d="M160.16 388.4l49.14-9.21-12.44-66.4L609 381.57l52.12-263L1024 88.31V0h-61.69l-.75 38.13c-31.57-.07-89.12-.19-146.28-.26-49.69-.07-89.36-.08-117.93 0-17 0-30.2.06-39.27.11-18 .12-19.87.13-24.32 2l-98.86 36L0 168.13v111.8l144.35 24.09zM59.32 208.65l85.43-14.72-3.75 21.2 49.24 8.7 6.87-38.91 65.09-11.22-10 55.54 49.2 8.89 13.25-73.45 63.7-11-17.8 93.89 49.13 9.31L431 144.6l64.68-11.15-23.46 130.87 49.22 8.81 26.62-148.76 65.65-23.91-44.35 223.8-511.1-85.3z" />
            <path d="M962.25 350.39l-.44-64.34h-27.06L934 180.4H743.89L723 286.05h-58.11v93.38h50v-43.38h197.23l.23 37.24L975 414.61v142.6h-41.92v50H1024V391.84zm-183.2-64.34l9.84-58.65h91.52v58.65zM422.94 556.45l109.49 17.28 4.18-49.96-113.67-17.94v50.62z" />
            <path d="M733.63 402.13A176.38 176.38 0 10910 578.48a176.38 176.38 0 00-176.37-176.35zm58.3 235.45a83.54 83.54 0 1124.47-59.07 83 83 0 01-24.47 59.04zM227.13 402.13A176.38 176.38 0 10403.5 578.48a176.38 176.38 0 00-176.37-176.35zm58.3 235.45a83.54 83.54 0 1124.47-59.07 83 83 0 01-24.47 59.04z" />
        </svg>
    );
}

export default SvgHaulTruck;
