import { createSelector } from 'reselect';
import * as powerBiReportsModule from '../Modules/powerBiReports';

const getEditReport = createSelector(
    powerBiReportsModule.selectors.getAll,
    (powerBiReportsState) => {
        const { currentEditPowerBiReport } = powerBiReportsState;
        return currentEditPowerBiReport;
    },
);
export default getEditReport;
