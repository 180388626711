export default {
    'Table__Cell--ConformanceLow':
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg==',
    'Table__Cell--ConformanceMedium':
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP838BQDwAGBQIApcDNuAAAAABJRU5ErkJggg==',
    'Table__Cell--ConformanceHigh':
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkmMlQDwACVAEa5lMJKQAAAABJRU5ErkJggg==',
    'Table__Cell--ConformanceNonAccessed':
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM88B8AAoUBwfkGMTcAAAAASUVORK5CYII=',
    'Table__Cell--ConformanceNoColor':
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
    'TruckAlertEvent--HighPriority':
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mO8GexfDwAFhAH8xVKEhwAAAABJRU5ErkJggg==',
    'TruckAlertEvent--MedPriority':
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8Wi9eDwAGCAIMgqMbUAAAAABJRU5ErkJggg==',
    'TruckAlertEvent--LowPriority':
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8f028HgAHNQJtKgRdEgAAAABJRU5ErkJggg==',
} as any;
