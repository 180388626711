import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highcharts';
import HC_NoData from 'highcharts/modules/no-data-to-display';
import HC_Histogram from 'highcharts/modules/histogram-bellcurve';
import HC_More from 'highcharts/highcharts-more';
import map from 'lodash/map';
import identity from 'lodash/identity';
import merge from 'lodash/merge';
import isObjectLike from 'lodash/isObjectLike';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import { getUnitsString } from '../../utils/unitsHelper';
import { defaultOptions } from './defaultChartOptions';
import TooltipFormatter from './tooltipFormatter';

// Add a new function to the Highcharts Point that will let us programmatically set the hover state / crosshair / tooltip
Highcharts.Point.prototype.highlight = function highlight(event) {
    const normalizedEvent = this.series.chart.pointer.normalize(event);
    this.onMouseOver(); // Show the hover marker
    this.series.chart.xAxis[0].drawCrosshair(normalizedEvent, this); // Show the crosshair
};

// Add module to display message when no data is given
HC_NoData(Highcharts);
// Add module to allow us to draw 'arearange' charts
HC_More(Highcharts);
// Add module to allow us to draw 'histogram' charts
HC_Histogram(Highcharts);

// Draw all 'arearange' legend symbols like 'line'
Highcharts.seriesTypes.arearange.prototype.drawLegendSymbol =
    Highcharts.seriesTypes.line.prototype.drawLegendSymbol;

/**
 * The same symbol as a triangle but inverted, use only on an inverted chart
 * This is because highcharts inverts markers as well when the chart is inverted
 * Open issue from 2011 - https://github.com/highcharts/highcharts/issues/422
 *
 * If making a custom path isn't possible, you'll need to instead hook into the chart 'load' event
 * and manually edit dom nodes as required
 *
 * If you want to adjust the positioning of your marker in code, adjust the x/y position instead
 * All the highcharts workarounds involve waiting for chart load event and selecting elements, positioning manually
 *
 * @param {number} x
 * @param {number} y
 * @param {number} w
 * @param {number} h
 * @return {*[]}
 */
Highcharts.SVGRenderer.prototype.symbols['triangle-inverted'] = function (
    x,
    y,
    w,
    h,
) {
    // This realigns the marker tip to be the center of the triangle
    //
    //          ^
    //         /o\ --- tip
    //        _ _ _
    //
    const offsetXToCenter = x + w;
    const offsetYToCenter = y + h / 2;
    // prettier-ignore
    return [
        'M', offsetXToCenter, offsetYToCenter,
        'L', offsetXToCenter - w, offsetYToCenter + h / 2,
        'L', offsetXToCenter - w, offsetYToCenter - h / 2,
        'L', offsetXToCenter, offsetYToCenter,
        'z'
    ];
};

if (Highcharts.VMLRenderer) {
    Highcharts.VMLRenderer.prototype.symbols['triangle-inverted'] =
        Highcharts.SVGRenderer.prototype.symbols['triangle-inverted'];
}

class FalconChart extends React.Component {
    constructor(props) {
        super(props);
        this.getFormattedData = this.getFormattedData.bind(this);
        this.getLegendOptions = this.getLegendOptions.bind(this);
        this.onLoad = this.onLoad.bind(this);

        // Default format for displaying date times looks like '10 Sep 2018'
        this.dateTimeFormat = '%e %b %Y';
    }

    // Hook into the Highcharts JS object so we can make api calls if we need
    onLoad(chart) {
        this.chart = chart;

        // Run any onLoad callbacks given to us, passing the Highcharts object as an argument
        const { onChartLoad } = this.props;
        onChartLoad(chart);
    }

    /**
     * Applies the supplied data formatter and does any other necessary
     * pre processing before passing the data to HighCharts.
     *
     * @returns {Array}
     */
    getFormattedData(data, dataFormatter) {
        const highchartsData = dataFormatter(data);

        // Apply marker styling
        return map(highchartsData, (series) => {
            const numberOfPoints = series.data.length;
            const { enableMarkers } = this.props;

            // We always render the markers, and then optionally hide them in the CSS
            series.marker = { enabled: true };

            series.data.forEach((point) => {
                if (isObjectLike(point)) {
                    // Remove all previous classes
                    point.className = '';

                    // We use a class to hide markers if we want them disabled
                    if (!enableMarkers) {
                        point.className = 'highcharts__point--hidden';
                    }

                    // If there is only one point in the data set
                    if (numberOfPoints === 1) {
                        point.className = 'highcharts__point--single';
                    }
                }
            });

            // Apply the colour class if it is passed in
            if (series.color) {
                series.className += ` FalconChart__series--${series.color}`;
            }

            return series;
        });
    }

    // Get the options for putting the legend at the center bottom or the right
    getLegendOptions(position) {
        switch (position) {
            case 'right':
                return {
                    align: 'right',
                    verticalAlign: 'middle',
                    layout: 'vertical',
                    itemMarginBottom: 20,
                };
            case 'center':
                return {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal',
                    itemMarginBottom: 0,
                };
            case 'top':
                return {
                    align: 'center',
                    verticalAlign: 'top',
                    layout: 'horizontal',
                    y: -65,
                };
            default:
                return {
                    enabled: false,
                };
        }
    }

    render() {
        const {
            data,
            dataFormatter,
            tooltipDecimalPlaces,
            height,
            legendPosition,
            options,
            showXValueOnTooltip,
            timezone,
            title,
            tooltipPosition,
            type,
            xAxisType,
            xAxisUnits,
            xLabel,
            yAxisUnits,
            yLabel,
            yMax,
            yMin,
            xMax,
            xMin,
            inverted,
        } = this.props;

        const formattedData = this.getFormattedData(data, dataFormatter);

        // This class is used to generate the right tooltip config
        this.tooltipFormatter = new TooltipFormatter(
            tooltipPosition,
            showXValueOnTooltip,
            getUnitsString(xAxisUnits),
            getUnitsString(yAxisUnits),
            tooltipDecimalPlaces,
            xAxisType === 'datetime',
            this.dateTimeFormat,
            formattedData.length,
        );

        let yAxisTitle = '';
        if (yLabel) {
            yAxisTitle += yLabel;
            if (yAxisUnits) {
                yAxisTitle += ` (${yAxisUnits})`;
            }
        } else {
            yAxisTitle += yAxisUnits;
        }

        // Build the general chart options
        const generatedOptions = {
            chart: {
                type,
                height,
                styledMode: true,
                // If no title but a yLabel, add top so is visible
                marginTop: yLabel && !title ? 30 : undefined,
                inverted,
            },
            credits: {
                enabled: false,
            },
            series: formattedData,
            title: {
                align: 'left',
                text: title,
                margin: yAxisTitle !== '' ? 35 : undefined,
            },
            time: {
                // Used to set the proper timezone when displaying datetimes on graphs
                // http://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/time/gettimezoneoffset/
                getTimezoneOffset: timezone
                    ? (timestamp) => {
                          return -moment.tz(timestamp, timezone).utcOffset();
                      }
                    : undefined,
            },
            xAxis: {
                title: { text: xLabel },
                labels: {
                    format:
                        // If we are displaying dates, don't use any unit string formatting
                        xAxisType === 'datetime'
                            ? undefined
                            : `{value}${getUnitsString(xAxisUnits)}`,
                },
                dateTimeLabelFormats: {
                    // Display the date correctly when just 1 point
                    millisecond: this.dateTimeFormat,
                    day: this.dateTimeFormat,
                    week: this.dateTimeFormat,
                    month: this.dateTimeFormat,
                },
                type: xAxisType,
                // Removes the tiny bit of space from the firstmost x axis point
                // and the Y axis
                minPadding: 0,
                min: xMin,
                max: xMax,
            },
            yAxis: {
                title: {
                    text: yAxisTitle,
                    align: 'high',
                    offset: 0,
                    rotation: 0,
                    y: -20,
                    reserveSpace: false,
                    textAlign: 'left',
                },
                min: yMin,
                max: yMax,
                // Show the ticks on the Y axis
                tickLength: 8,
                tickWidth: 1,
            },
            legend: legendPosition
                ? this.getLegendOptions(legendPosition)
                : undefined,
            tooltip: this.tooltipFormatter.getTooltipOptions(),
        };

        // Merge the falcon defaults with our generated options and parent options
        const config = {};
        merge(config, defaultOptions, generatedOptions, options);

        // Add the FalconChart classname here so it is appended to whatever other classname we applied
        // Also add little hack classname to get the tooltip to style properly
        config.chart.className += ` FalconChart__chart FalconChart__chart--tooltip-${tooltipPosition}`;

        return (
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={config}
                    callback={this.onLoad}
                />
            </div>
        );
    }
}

// TODO: Add descriptions
FalconChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    title: PropTypes.string,
    type: PropTypes.string,
    dataFormatter: PropTypes.func,
    tooltipDecimalPlaces: PropTypes.number,
    enableMarkers: PropTypes.bool,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    legendPosition: PropTypes.oneOf(['center', 'right', 'top', 'none']),
    onChartLoad: PropTypes.func,
    options: PropTypes.objectOf(PropTypes.any),
    timezone: PropTypes.string,
    showXValueOnTooltip: PropTypes.bool,
    tooltipPosition: PropTypes.oneOf(['topright', 'onchart']),
    xAxisType: PropTypes.oneOf([
        'linear',
        'logarithmic',
        'datetime',
        'category',
    ]),
    xAxisUnits: PropTypes.string,
    xLabel: PropTypes.string,
    yAxisUnits: PropTypes.string,
    yLabel: PropTypes.string,
    yMax: PropTypes.number,
    yMin: PropTypes.number,
    xMax: PropTypes.number,
    xMin: PropTypes.number,
    inverted: PropTypes.bool,
};

FalconChart.defaultProps = {
    dataFormatter: identity,
    tooltipDecimalPlaces: 1,
    enableMarkers: false,
    height: null,
    legendPosition: 'none',
    onChartLoad: identity,
    options: {},
    showXValueOnTooltip: true,
    tooltipPosition: 'onchart',
    xAxisType: 'linear',
    inverted: false,
};

export default FalconChart;
