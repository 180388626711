// The supported operator display formats
export enum OperatorDisplayFormats {
    All = 'All',
    Name = 'Name',
    Id = 'Id',
    Blurred = 'Blurred',
}
// TO-DO For future make this function return and boolen and the actual OperatorDisplayFormats enum
export const isOperatorDisplayFormatsValid = (i: string): boolean => {
    if (i in OperatorDisplayFormats) return true;
    return false;
};

// When the name cannot be parsed
export const DISPLAY_NAME_FALLBACK = 'unknown';

/**
 * Returns the id from a worker name
 * @param operatorName
 */
function operatorNameToDisplayId(operatorName: string): string {
    const parts = operatorName.match(/.*\((\d+)\)/);
    // Temp hack to work around driver unique id's starting with 0
    return (parts && parts[1]) || DISPLAY_NAME_FALLBACK;
}

/**
 * Returns only the name part of an operator name
 * @param operatorName
 */
function operatorNameToDisplayName(operatorName: string): string {
    const nameOnly = operatorName.replace(/\(.*?\)/g, '');
    return (nameOnly && nameOnly.trim()) || DISPLAY_NAME_FALLBACK;
}

/**
 * Tests if the operatorName is a pseudo operator (DP made up name)
 * @param operatorName
 */
function isOperatorUnknown(operatorName: string): boolean {
    return operatorName.toLowerCase().includes('unknown');
}

/**
 * Adds spacing between first / last name
 * @param name
 */
function prettifyName(name: string): string {
    return name.replace(/,/g, ', ');
}

export function format(type: string, operatorDisplayName: string): string {
    // If it's a falsey value always return a string
    if (!operatorDisplayName) {
        return DISPLAY_NAME_FALLBACK;
    }
    // If it's an unknown operator return the original string
    if (isOperatorUnknown(operatorDisplayName)) {
        return operatorDisplayName;
    }
    switch (OperatorDisplayFormats[type]) {
        case OperatorDisplayFormats.Name:
            return prettifyName(operatorNameToDisplayName(operatorDisplayName));
        case OperatorDisplayFormats.Id:
            return prettifyName(operatorNameToDisplayId(operatorDisplayName));
        /* eslint-disable no-fallthrough */
        case OperatorDisplayFormats.All:
            return prettifyName(operatorDisplayName);
        // If blurred, just show All and leave the blurring itself to the CSS
        case OperatorDisplayFormats.Blurred:
            return prettifyName(operatorDisplayName);
        default:
            return prettifyName(operatorDisplayName);
    }
}
