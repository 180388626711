import { all, takeLatest, put, select, call } from 'redux-saga/effects';
import shiftUtils from '@rs/core/utils/shiftUtils';
import {
    convertURLQueryStringToObject,
    updateURLQueryString,
} from '../Lib/queryStringUtils';
import {
    actions as filterActions,
    selectors as filterSelectors,
} from '../MaxmineDiagnostics/CriticalParams/Modules/filters';
import { actions as equipmentFilterActions } from '../MaxmineDiagnostics/CriticalParams/Modules/equipmentFilters';
import { actions as spinnerActions } from '../MaxmineDiagnostics/CriticalParams/Modules/spinner';
import { actions as parameterCheckActions } from '../MaxmineDiagnostics/CriticalParams/Modules/parameterChecks';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import { getParameterChecks } from './resourceSagas';
import { checkRequestsForErrors } from '../Api';
import equipmentFilters from '../MaxmineDiagnostics/CriticalParams/Selectors/equipmentFilters';

export function* filterUpdated(action) {
    const filters = yield select(filterSelectors.getFilters);
    // get equipment filters
    const { classes, models, subClasses } = yield select(equipmentFilters);
    const combinedFilters = {
        ...filters,
        EquipmentClass: classes,
        EquipmentSubClass: subClasses,
        EquipmentModel: models,
    };
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    yield call(getParameterChecksForShift, action);
    updateURLQueryString(combinedFilters);
    yield put(spinnerActions.setSpinnerState(false));
}

export function* getParameterChecksForShift() {
    const filters = yield select(filterSelectors.getFilters);
    const ShiftId = shiftUtils.getShiftIdFromDateString(
        filters.Date,
        filters.Shift,
    );

    const parameterChecks = yield call(getParameterChecks, {
        ShiftId,
    });

    // If there's an error then abort since it's the same endpoint to fetch the real data
    if (checkRequestsForErrors([parameterChecks])) {
        yield put(
            parameterCheckActions.fetchParameterChecksError({
                error: 'Failed to load parameterChecks',
            }),
        );
        return;
    }

    yield put(
        parameterCheckActions.fetchParameterChecksSuccess(
            parameterChecks.response,
        ),
    );
}

export function* updateFiltersWithURLParams(action) {
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const urlFilterValues = convertURLQueryStringToObject();
    yield put(filterActions.setFiltersWithUrlParams(urlFilterValues));
    yield put(
        equipmentFilterActions.setAllFilters({
            selectedClasses:
                (urlFilterValues.EquipmentClass &&
                    urlFilterValues.EquipmentClass.split(',')) ||
                [],
            selectedSubClasses:
                (urlFilterValues.EquipmentSubClass &&
                    urlFilterValues.EquipmentSubClass.split(',')) ||
                [],
            selectedModels:
                (urlFilterValues.EquipmentModel &&
                    urlFilterValues.EquipmentModel.split(',')) ||
                [],
        }),
    );
    yield call(getParameterChecksForShift, action);
    yield put(spinnerActions.setSpinnerState(false));
}

export default function* watch() {
    yield all([
        takeLatest(
            filterActions.UPDATE_FILTERS_WITH_URL_PARAMS,
            updateFiltersWithURLParams,
        ),
        takeLatest(
            [
                filterActions.FILTER_UPDATED,
                equipmentFilterActions.setSelectedClasses,
                equipmentFilterActions.setSelectedModels,
                equipmentFilterActions.setSelectedSubClasses,
                equipmentFilterActions.clearAll,
            ],
            filterUpdated,
        ),
    ]);
}
