import {
    put,
    take,
    call,
    select,
    takeLatest,
    all,
    fork,
    delay,
    race,
    join,
} from 'redux-saga/effects';
import { channel } from 'redux-saga';
import { CognitoAuth } from 'amazon-cognito-auth-js';
import isObject from 'lodash/isObject';
import shiftUtils from '@rs/core/utils/shiftUtils';
import errorLogging from '@rs/core/utils/errorLogging';
import { decodeClaim } from '@rs/core/utils/jwtUtils';
import { FEATURE_IDS } from '@rs/core/features';
import * as actions from '../Auth/Actions';
import * as resourceSagas from './resourceSagas';
import * as localStorage from '../Lib/localStorage';
import * as cookiesStorage from '../Lib/cookies';
import Cookies from 'js-cookie';
import { forwardTo } from '../App/createHistory';
import { stateSelectors as authSelectors } from '../Auth/Reducers';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import {
    APP_INIT_REQUEST,
    APP_INIT_SUCCESS,
    appInitError,
    appInitSuccess,
    fetchPrivateSiteConfigurationSuccess,
    fetchUserInformationSuccess,
} from '../App/Actions';
import { getShiftConfiguration } from '../App/Selectors';
import { FEATURE_META } from '../Auth/featureMeta';
import ReactGA from 'react-ga4';
import { ANALYTICS_CATEGORIES } from './analyticsWatchers';

const AWS_COGNITO_STORAGE_TMP_KEY = 'aws-cognito-tmp';
const AWS_CONGITO_URL_ACCESS_TOKEN = 'access_token';
const VALIDATE_S3_TOKEN_SUCCESS = 'VALIDATE_S3_TOKEN_SUCCESS';
const VALIDATE_S3_TOKEN_ERROR = 'VALIDATE_S3_TOKEN_ERROR';

/**
 * Returns a param from the URL string
 * @param name
 * @return {*}
 */
function urlParam(name) {
    const regex = `[\\?&#]${name}=([^&#]*)`;
    const results = new RegExp(regex).exec(window.location.href);
    if (results == null) {
        return null;
    }
    return decodeURIComponent(results[1]) || 0;
}

/**
 * Creates a URL string that can be formatted by Cognito, (ie. auth.parseCognitoWebResponse(locationString)).
 * Cognito wants the URL to look something like http://site.com/login/#access_token=1234
 * @return {string}
 */
function getCleanedLocationString() {
    const [, pageHashRoute, awsParams] = window.location.hash.split('#');
    // Remove trailing '?' character
    const pageHashRouteString = pageHashRoute.substring(
        0,
        pageHashRoute.length - 1,
    );
    const awsParamsString = `#${awsParams}`;
    const hackingAroundDoubleFragmentInUrl = `${window.location.origin}${pageHashRouteString}${awsParamsString}`;
    return hackingAroundDoubleFragmentInUrl;
}

/**
 * Constructs a CognitoAuth object with success/failure handlers
 * Note: this uses a channel for the handlers so that the calling generator Fn
 * can yield for a particular action. Otherwise the calling generator can't pause
 * and waitfor the callbacks will be complete
 * @param {object} providerData - The AWS Config providerData
 * @param {object} chan - A redux-sgaa channel to emit events on
 */
function initCognitoSDK(providerData, chan) {
    const auth = new CognitoAuth(providerData.authData);
    auth.userhandler = {
        onSuccess() {
            chan.put({ type: VALIDATE_S3_TOKEN_SUCCESS });
        },
        onFailure() {
            chan.put({ type: VALIDATE_S3_TOKEN_ERROR });
        },
    };
    return auth;
}

/**
 * Handles the AWS Cognito callbacks based on the action emitted from the channel
 * @param {object} action - Same as a redux action, type/payload
 * @param {string} awsToken - The token response from AWS Cognito
 * @param {string} authenticationMethodName - The authentication method associated with a user
 * @return {IterableIterator<*>}
 */
function* validateAwsToken(action, awsToken, authenticationMethodName) {
    if (action.type === VALIDATE_S3_TOKEN_SUCCESS) {
        const { response, error } = yield call(
            resourceSagas.postAuthenticateFederatedToken,
            {
                Token: awsToken,
                AuthenticationMethod: {
                    Name: authenticationMethodName,
                },
            },
        );
        // Remove the AWS token params from history
        window.location.replace(
            `${window.location.origin}${window.location.hash.split('?')[0]}`,
        );
        if (error) {
            yield put(
                actions.loginError(
                    'Invalid Username/Email or Password. Please contact your supervisor if you are unable to log in.',
                ),
            );
            return;
        }
        yield put(actions.tokenValidate({ token: response.Authorization }));
    } else if (action.type === VALIDATE_S3_TOKEN_ERROR) {
        // TODO not sure if we need to handle this case, as if they fail to login the user is
        // still on the 3rd party site.
    }
}

/**
 * Waits for a LOGOUT event & handles clearing stored authentication
 */
export function* logoutUser() {
    yield call(localStorage.clear);
    const hashParts = window.location.hash.split('?');
    const isLoginPage = ['#/login', '#/'].filter((str) => hashParts[0] === str)
        .length;
    if (!isLoginPage) {
        yield call(forwardTo, FEATURE_META[FEATURE_IDS.TAB_ID__LOGIN].path);
    }
}

/**
 * Redirects the user to their homepage if they were on the login / main page
 * Otherwise if authenticated & elsewhere does not redirect
 */
export function* redirectOnLoginSuccess() {
    const hashParts = window.location.hash.split('?');
    const shouldRedirect = ['#/login', '#/'].some(
        (str) => hashParts[0] === str,
    );
    if (shouldRedirect) {
        const state = yield select();
        const referrer = authSelectors.getReferrer(state);
        const homepage = authSelectors.getUsersHomePage(state);
        if (referrer) {
            // If we have a referrer URL (i.e. a user has been sent a MaxMine URL and needs to authenticate first),
            // after logging in we send them to the referrer URL
            yield call(forwardTo, referrer);
        } else {
            // Otherwise send them to their homepage
            yield call(forwardTo, homepage);
        }
    }
}

/**
 * This function uses the refresh token stores in the localstorage, to call Auth API /refresh-token
 * to get a new access token
 * @param updateInTheBackground - indicates should this saga schedule a task in the background
 * @return {Generator<*, void, *>}
 */
export function* refreshAccessToken(updateInTheBackground = true) {
    // Expiry is near, refresh access token
    const { response, error } = yield call(resourceSagas.refresh);

    if (response) {
        // Parse the token
        const decodedToken = decodeClaim(response.access_token);
        yield all([
            // set token in localStorage
            call(
                localStorage.setItem,
                localStorage.AUTH_TOKEN_KEY,
                response.access_token,
            ),
            // set refresh token in localStorage
            call(
                localStorage.setItem,
                localStorage.REFRESH_TOKEN_KEY,
                response.refresh_token,
            ),
            // set token in cookies
            call(
                Cookies.set,
                cookiesStorage.AUTH_TOKEN_KEY,
                response.access_token,
            ),
            // set refresh token in cookies
            call(
                Cookies.set,
                cookiesStorage.REFRESH_TOKEN_KEY,
                response.refresh_token,
            ),
        ]);

        // Update redux store
        yield put(
            actions.tokenUpdatedSuccess({
                token: response.access_token,
            }),
        );

        ReactGA.event({
            category: ANALYTICS_CATEGORIES.AUTH,
            action: 'Successfully refresh access token',
        });

        // If it should create a new task to refresh token in the background
        if (updateInTheBackground) {
            yield fork(refreshAccessTokenInTheBackground, decodedToken.exp);
        }
    } else if (error) {
        ReactGA.event({
            category: ANALYTICS_CATEGORIES.AUTH,
            action: 'Failed to refresh access token',
            value: error,
        });
    }
}

// This function starts a race condition between logout event and timeout on the access token expiry time
// 1. if the user logout before the token expires none action trigger
// 2. if the expiry time passed in the parameter timed out, call refreshAccessToken to refresh token
export function* refreshAccessTokenInTheBackground(expiry) {
    // Refresh 5 minutes before expiry
    const epoch = Math.round(Date.now() / 1000);
    const seconds = Math.max(0, expiry - epoch - 5 * 60);

    // Continue when user logout or expiry is near
    const { logout } = yield race({
        logout: take(actions.LOGOUT),
        timeout: delay(seconds * 1000),
    });

    // If user logout, end the refresh task.
    if (logout) return;

    yield call(refreshAccessToken);
}

/**
 * Validates an API token & logs the user in / out
 */
export function* validateToken() {
    while (true) {
        const { payload } = yield take(actions.TOKEN_VALIDATE);
        try {
            // Parse the token
            const decodedToken = decodeClaim(payload.token);
            yield all([
                // set token in localStorage
                call(
                    localStorage.setItem,
                    localStorage.AUTH_TOKEN_KEY,
                    payload.token,
                ),
                // set refresh token in localStorage
                call(
                    localStorage.setItem,
                    localStorage.REFRESH_TOKEN_KEY,
                    payload.refreshToken,
                ),
                // set token in cookies
                call(Cookies.set, cookiesStorage.AUTH_TOKEN_KEY, payload.token),
                // set refresh token in cookies
                call(
                    Cookies.set,
                    cookiesStorage.REFRESH_TOKEN_KEY,
                    payload.refreshToken,
                ),
            ]);

            // Create a task to refresh token
            yield fork(refreshAccessTokenInTheBackground, decodedToken.exp);
            // Update redux store
            yield put(
                actions.loginSuccess({
                    token: payload.token,
                    refreshToken: payload.refreshToken,
                    decodedToken,
                }),
            );
            errorLogging.setErrorLoggingUserContext(decodedToken);
        } catch (e) {
            yield put(actions.logout());
        }
    }
}

/**
 * The entry point for the auth flow. This function figures out what auth state the
 * app is in and then starts the appropriate flow for the type of authentication
 */
export function* authFlow() {
    yield take(APP_INIT_SUCCESS);
    const token = localStorage.getItem(localStorage.AUTH_TOKEN_KEY);
    const refreshToken = localStorage.getItem(localStorage.REFRESH_TOKEN_KEY);

    if (token) {
        yield put(actions.tokenValidate({ token, refreshToken }));
        yield take([actions.LOGIN_SUCCESS, actions.LOGOUT]);
    }
    while (true) {
        if (urlParam(AWS_CONGITO_URL_ACCESS_TOKEN)) {
            yield call(awsTokenFlow);
        }
        const action = yield take([
            actions.AUTH_USERNAME_SUBMITTED,
            actions.LOGIN_REQUESTED,
            actions.AUTH_LOGIN_SUBMITTED,
        ]);
        if (action.type === actions.LOGIN_REQUESTED) {
            try {
                yield call(accountSetupFlow, action);
            } catch (e) {
                continue;
            }
        } else if (action.type === actions.AUTH_USERNAME_SUBMITTED) {
            yield call(usernameSubmittedInLoginForm, action);
        } else if (action.type === actions.AUTH_LOGIN_SUBMITTED) {
            yield call(passwordSubmittedInLoginForm, action);
        }
    }
}

/**
 * @description This fn is responsible for calling the 'getAuthenticateMethod' saga given an email input.
 * Which dicates the login method that should be used based on the request params.
 *
 * @description It is called from the authFlow fn, and triggered when the auth action:
 * AUTH_USERNAME_SUBMITTED is triggered
 *
 * @example This generator fn will be triggered when a user clicks the 'continue' button
 * on the login screen of the client portal ui, when they are prompted to enter an email.
 */
export function* usernameSubmittedInLoginForm({ payload }) {
    const email = isObject(payload) ? payload.Email : payload;
    yield put(actions.setUserEmail(email));

    // Clear any previous error state before proceeding with the login API request
    yield put(actions.loginError(''));

    const fetchAuthenticationMethod = yield fork(
        resourceSagas.getAuthenticateMethod,
        {
            Email: email,
        },
    );

    let { result: authMethodResult, loading } = yield race({
        // toggle the spinner after 300ms, to avoid it flashing open and quickly disappearing
        loading: delay(300),
        result: join(fetchAuthenticationMethod),
    });

    if (loading) {
        // set loading
        yield put(actions.setSpinnerState(true, 'Loading'));
        authMethodResult = yield join(fetchAuthenticationMethod);
    }

    const {
        response: authMethodResponse,
        error: authMethodError,
    } = authMethodResult;

    if (authMethodError) {
        yield put(
            actions.loginError(
                'Invalid Username, Please contact your supervisor if you are unable to log in.',
            ),
        );
        return;
    }

    // save to the state
    yield put(
        actions.setAuthenticationMethod(
            authMethodResponse.AuthenticationMethod,
        ),
    );

    if (!authMethodResponse.AuthenticationMethod['Password-Required']) {
        yield put(actions.setSpinnerState(true, 'Redirecting'));
        localStorage.setItem(
            AWS_COGNITO_STORAGE_TMP_KEY,
            JSON.stringify(authMethodResponse),
        );
        const chan = channel();
        const auth = initCognitoSDK(
            authMethodResponse.AuthenticationMethod.ProviderData,
            chan,
        );
        auth.getSession();
        const action = yield take(chan);
        chan.close();
        yield call(
            validateAwsToken,
            action,
            auth.getSignInUserSession().idToken.jwtToken,
            authMethodResponse.AuthenticationMethod.Name,
        );
    }
    // unset loading state
    yield put(actions.setSpinnerState(false));
}

/**
 * Fetch the user's authentication method base on the email supplied
 * And store it in the state
 * @param {string} payload - email needs to be checked against
 * @return {IterableIterator<*>}
 */
export function* getAuthenticateMethod({ payload: email }) {
    const {
        response: authMethodResponse,
        error: authMethodError,
    } = yield call(resourceSagas.getAuthenticateMethod, { Email: email });

    yield put(
        actions.setAuthenticationMethod(
            authMethodResponse.AuthenticationMethod,
        ),
    );
}

/**
 * @description This fn is responsible for calling the 'login' saga with a userid & password.
 *
 * @description It is called from the authFlow fn, and triggered when the auth action:
 * AUTH_LOGIN_SUBMITTED is triggered
 *
 * @example This generator fn will be triggered when a user clicks the 'continue' button
 * on the login screen of the client portal ui, when they have entered a password.
 *
 */
export function* passwordSubmittedInLoginForm() {
    yield put(actions.loginError(''));

    const { userId, password } = yield select(authSelectors.getLoginForm);

    const { response: loginResponse, error: loginError } = yield call(
        resourceSagas.login,
        {
            Email: userId,
            Password: password,
        },
    );
    if (loginError) {
        yield put(actions.loginError('The username or password is incorrect'));
        return;
    }
    // Validate token
    yield put(
        actions.tokenValidate({
            token: loginResponse.Token,
            refreshToken: loginResponse.refresh_token,
        }),
    );
}

/**
 * The authentication flow for filling out the account setup form. Sets up the Users password, the user should then end up on the login form after this
 */
function* accountSetupFlow({ payload }) {
    yield put(actions.setUserEmail(payload?.Email));
    yield put(actions.loginError(''));
    const { error: loginError } = yield call(resourceSagas.login, {
        Email: payload.Email,
        NewPassword: payload.NewPassword,
        Recover: payload.Recover,
    });
    if (loginError) {
        yield put(actions.loginError(loginError));
        throw new Error();
    } else {
        yield forwardTo(FEATURE_META[FEATURE_IDS.TAB_ID__LOGIN].path);
    }
}

/**
 * The authentication flow for when the user is redirected back to the website with an access_token from AWS Cognito
 */
function* awsTokenFlow() {
    const chan = channel();
    const data = JSON.parse(localStorage.getItem(AWS_COGNITO_STORAGE_TMP_KEY));
    const auth = yield call(
        initCognitoSDK,
        data.AuthenticationMethod.ProviderData,
        chan,
    );
    const locationString = getCleanedLocationString();
    auth.parseCognitoWebResponse(locationString);

    const action = yield take(chan);
    chan.close();
    yield call(
        validateAwsToken,
        action,
        auth.getSignInUserSession().idToken.jwtToken,
        data.AuthenticationMethod.Name,
    );
}

export function* appInit() {
    while (true) {
        yield take(APP_INIT_REQUEST);

        // initiate refresh token when the app starts for once
        // ensure the subsequent API requests using the valid new access token
        const refreshToken = localStorage.getItem(
            localStorage.REFRESH_TOKEN_KEY,
        );
        if (refreshToken) {
            yield call(refreshAccessToken, false);
        }

        // Fetch new discovery route here
        // dispatch an error if needed
        const { response, error } = yield call(
            resourceSagas.getPublicDiscoverySiteConfiguration,
        );
        if (error) {
            const errorMessage = isObject(error)
                ? error.Error
                : error || 'Failed to load configuration';
            errorLogging.logException(new Error(errorMessage));
            yield put(appInitError({ error: errorMessage }));
            return;
        }
        yield put(appInitSuccess(response));
    }
}

export function* getUserInformation() {
    const { response, error } = yield call(resourceSagas.getUserInformation);
    if (error) {
        return;
    }
    yield put(fetchUserInformationSuccess(response));
}

export function* getPrivateSiteConfiguration() {
    const { response, error } = yield call(
        resourceSagas.getPrivateDiscoverySiteConfiguration,
    );
    if (error) {
        return;
    }
    const shiftConfig = yield select(getShiftConfiguration);
    shiftUtils.setShiftConfiguration(
        response.primaryShiftPatterns,
        shiftConfig.timezoneAtMinesite,
    );
    yield put(fetchPrivateSiteConfigurationSuccess(response));
}

export function* resetPasswordLinkRequested({ payload }) {
    yield put(
        actions.setSpinnerState(true, LOADING_MESSAGES.LOADING__PROCESSING),
    );

    yield call(getAuthenticateMethod, { payload: payload.Email });

    const authenticationMethod = yield select(
        authSelectors.getAuthenticationMethod,
    );

    // User credentials is not manage by MaxMine
    if (authenticationMethod['Password-Required'] === false) {
        yield put(
            actions.resetPasswordError(
                "It looks like you're trying to change your password," +
                    ' but your organisation uses Single Sign-On (SSO) for authentication. ' +
                    "Your password can be updated through your SSO provider's portal. " +
                    'You can speak to your IT administrator for more information',
            ),
        );
        yield put(actions.setSpinnerState(false));
        // Don't allow user to continue the flow
        return;
    }

    const apiResponse = yield call(resourceSagas.resetPasswordLinkResquest, {
        Email: payload.Email,
    });

    if (apiResponse.error) {
        yield put(actions.resetPasswordError(apiResponse.error));
        yield put(actions.setSpinnerState(false));
    } else {
        yield put(
            actions.resetPasswordSuccess(apiResponse.response.SuccessMessage),
        );
        yield put(actions.setSpinnerState(false));
    }
}

export default function* watch() {
    yield all([
        validateToken(),
        appInit(),
        takeLatest(actions.LOGIN_SUCCESS, getPrivateSiteConfiguration),
        takeLatest(actions.LOGIN_SUCCESS, getUserInformation),
        authFlow(),
        takeLatest(actions.LOGIN_SUCCESS, redirectOnLoginSuccess),
        takeLatest(actions.LOGOUT, logoutUser),
        takeLatest(
            actions.RESET_PASSWORD_LINK_REQUESTED,
            resetPasswordLinkRequested,
        ),
        takeLatest(
            actions.GET_AUTHENTICTION_METHOD_REQUESTED,
            getAuthenticateMethod,
        ),
    ]);
}
