import { action } from '../../Lib/reduxUtils';
import {
    FetchAllReportsSuccessPayload,
    FetchAllReportsErrorPayload,
    FetchReportSuccessPayload,
    FetchReportErrorPayload,
    PowerBiReportId,
    PreviewReportModalStatus,
    PreviewButtonStatus,
    SubmitCreateReportRequestPayload,
    SubmitEditReportRequestPayload,
    PowerBiReportStatus,
} from './types';

export default function createActions(ns: string) {
    const PREPARE_TO_FETCH_ALL_REPORTS = `${ns}/PREPARE_TO_FETCH_ALL_REPORTS`;
    const prepareToFetchAllReports = () => action(PREPARE_TO_FETCH_ALL_REPORTS);

    const FETCH_ALL_REPORTS_SUCCESS = `${ns}/FETCH_ALL_REPORTS_SUCCESS`;
    const fetchAllReportsSuccess = (results: FetchAllReportsSuccessPayload) =>
        action(FETCH_ALL_REPORTS_SUCCESS, results);

    const FETCH_ALL_REPORTS_ERROR = `${ns}/FETCH_ALL_REPORTS_ERROR`;
    const fetchAllReportsError = (error: FetchAllReportsErrorPayload) =>
        action(FETCH_ALL_REPORTS_ERROR, error);

    const PREPARE_TO_FETCH_REPORT = `${ns}/PREPARE_TO_FETCH_REPORT`;
    const prepareToFetchReport = (id: PowerBiReportId) =>
        action(PREPARE_TO_FETCH_REPORT, id);

    const FETCH_REPORT_SUCCESS = `${ns}/FETCH_REPORT_SUCCESS`;
    const fetchReportSuccess = (result: FetchReportSuccessPayload) =>
        action(FETCH_REPORT_SUCCESS, result);

    const FETCH_REPORT_ERROR = `${ns}/FETCH_REPORT_ERROR`;
    const fetchReportError = (error: FetchReportErrorPayload) =>
        action(FETCH_REPORT_ERROR, error);

    const PREPARE_TO_FETCH_EDIT_REPORT = `${ns}/PREPARE_TO_FETCH_EDIT_REPORT`;
    const prepareToFetchEditReport = (id: PowerBiReportId) =>
        action(PREPARE_TO_FETCH_EDIT_REPORT, id);

    const FETCH_CURRENT_EDIT_REPORT_SUCCESS = `${ns}/FETCH_CURRENT_EDIT_REPORT_SUCCESS`;
    const fetchCurrentEditReportSuccess = (result: FetchReportSuccessPayload) =>
        action(FETCH_CURRENT_EDIT_REPORT_SUCCESS, result);

    const FETCH_CURRENT_EDIT_REPORT_ERROR = `${ns}/FETCH_CURRENT_EDIT_REPORT_ERROR`;
    const fetchCurrentEditReportError = (error: FetchReportErrorPayload) =>
        action(FETCH_CURRENT_EDIT_REPORT_ERROR, error);

    const CURRENT_EDIT_REPORT_PREVIEW_CLICKED = `${ns}/CURRENT_EDIT_REPORT_PREVIEW_CLICKED`;
    const currentEditReportPreviewClicked = () =>
        action(CURRENT_EDIT_REPORT_PREVIEW_CLICKED);

    // TODO - create union types for field object
    const SUBMIT_EDIT_REPORT_INPUT_FIELD = `${ns}/SUBMIT_EDIT_REPORT_INPUT_FIELD`;
    const submitReportInputField = (field: object) =>
        action(SUBMIT_EDIT_REPORT_INPUT_FIELD, field);

    const SET_EDIT_REPORT_INPUT_FIELD = `${ns}/SET_EDIT_REPORT_INPUT_FIELD`;
    const setEditReportInputField = (field: Object) =>
        action(SET_EDIT_REPORT_INPUT_FIELD, field);

    const SET_EDIT_REPORT_FIELD_ERROR = `${ns}/SET_EDIT_REPORT_FIELD_ERROR`;
    const setEditReportFieldError = (error: string) =>
        action(SET_EDIT_REPORT_FIELD_ERROR, error);

    const PREVIEW_CLICKED = `${ns}/PREVIEW_CLICKED`;
    const previewClicked = () => action(PREVIEW_CLICKED);

    const PREVIEW_TOKEN_RECEIVED = `${ns}/PREVIEW_TOKEN_RECEIVED`;
    const previewTokenReceived = (token: string) =>
        action(PREVIEW_TOKEN_RECEIVED, token);

    const PREVIEW_MODAL_STATUS = `${ns}/PREVIEW_MODAL_STATUS`;
    const previewModalStatus = (status: PreviewReportModalStatus) =>
        action(PREVIEW_MODAL_STATUS, status);

    const PREVIEW_BUTTON_TOGGLE = `${ns}/PREVIEW_BUTTON_TOGGLE`;
    const previewButtonToggle = (status: PreviewButtonStatus) => {
        return action(PREVIEW_BUTTON_TOGGLE, status);
    };

    const CLEAR_DATA = `${ns}/CLEAR_DATA`;
    const clearData = () => action(CLEAR_DATA);

    const SUBMIT_CREATE_REPORT_REQUEST = `${ns}/SUBMIT_CREATE_REPORT_REQUEST`;
    const submitCreateReportRequest = (
        payload: SubmitCreateReportRequestPayload,
    ) => {
        return action(SUBMIT_CREATE_REPORT_REQUEST, payload);
    };

    const SUBMIT_EDIT_REPORT_REQUEST = `${ns}/SUBMIT_EDIT_REPORT_REQUEST`;
    const submitEditReportRequest = (
        payload: SubmitEditReportRequestPayload,
    ) => {
        return action(SUBMIT_EDIT_REPORT_REQUEST, payload);
    };

    const SET_POWER_BI_REPORT_STATUS = `${ns}/SET_POWER_BI_REPORT_STATUS`;
    const setPowerBiReportStatus = (payload: PowerBiReportStatus) => {
        return action(SET_POWER_BI_REPORT_STATUS, payload);
    };

    return {
        PREPARE_TO_FETCH_ALL_REPORTS,
        prepareToFetchAllReports,
        FETCH_ALL_REPORTS_SUCCESS,
        fetchAllReportsSuccess,
        FETCH_ALL_REPORTS_ERROR,
        fetchAllReportsError,
        PREPARE_TO_FETCH_REPORT,
        prepareToFetchReport,
        FETCH_REPORT_SUCCESS,
        fetchReportSuccess,
        FETCH_REPORT_ERROR,
        fetchReportError,
        PREPARE_TO_FETCH_EDIT_REPORT,
        prepareToFetchEditReport,
        FETCH_CURRENT_EDIT_REPORT_SUCCESS,
        fetchCurrentEditReportSuccess,
        FETCH_CURRENT_EDIT_REPORT_ERROR,
        fetchCurrentEditReportError,
        CURRENT_EDIT_REPORT_PREVIEW_CLICKED,
        currentEditReportPreviewClicked,
        SUBMIT_EDIT_REPORT_INPUT_FIELD,
        submitReportInputField,
        SET_EDIT_REPORT_INPUT_FIELD,
        setEditReportInputField,
        SET_EDIT_REPORT_FIELD_ERROR,
        setEditReportFieldError,
        PREVIEW_CLICKED,
        previewClicked,
        PREVIEW_TOKEN_RECEIVED,
        previewTokenReceived,
        PREVIEW_MODAL_STATUS,
        previewModalStatus,
        PREVIEW_BUTTON_TOGGLE,
        previewButtonToggle,
        CLEAR_DATA,
        clearData,
        SUBMIT_CREATE_REPORT_REQUEST,
        submitCreateReportRequest,
        SUBMIT_EDIT_REPORT_REQUEST,
        submitEditReportRequest,
        SET_POWER_BI_REPORT_STATUS,
        setPowerBiReportStatus,
    };
}
