export interface ParameterCheck {
    DataType: string;
    EquipmentClass: string;
    EquipmentModel: string;
    EquipmentSiteName: string;
    EquipmentSubClass: string;
    EquipmentSubModel: string;
    Requirement: string;
    Parameter: string;
    ShiftId: number;
    Status: string;
}

export interface ParameterGroup {
    name: string;
    parameters: Array<string>;
}

export interface GroupArrayIndexes {
    name: string;
    startIndex: number;
    endIndex: number;
}

export enum STATUSES {
    AVAILABLE = 'Available',
    SUSPECT = 'Suspect',
    MISSING = 'Missing',
    TRUCK_OFF = 'EquipmentOff',
}

export enum CRITICALITY {
    CRITICAL = 'Critical',
    NOT_CRITICAL = 'NotCritical',
    NOT_EXPECTED = 'NotExpected',
}

export enum GROUPS {
    GPS = 'GPS',
    OEM = 'OEM',
    MaxMine = 'MaxMine',
}

export const GROUPS_SORT_PRIORITY = {
    [GROUPS.GPS]: 1,
    [GROUPS.OEM]: 2,
    [GROUPS.MaxMine]: 3,
};

export const PARAMETERS = {
    [GROUPS.GPS]: {
        PVT: 'PVT',
        PVT_X: 'Position',
        COMP_X_Speed: 'Speed',
    },
    [GROUPS.OEM]: {
        Air_Filter_1_Pressure_Loss: 'Air Filter 1 Pressure Loss',
        Air_Filter_2_Pressure_Loss: 'Air Filter 2 Pressure Loss',
        Air_Filter_3_Pressure_Loss: 'Air Filter 3 Pressure Loss',
        Air_Filter_4_Pressure_Loss: 'Air Filter 4 Pressure Loss',
        alerts: 'Alerts',
        Auto_Retarder_State: 'Auto Retarder State',
        Ambient_Air_Temp: 'Ambient Air Temp',
        Atmospheric_Pressure: 'Atmospheric Pressure',
        Battery_Voltage: 'Battery Voltage',
        Body_Angle: 'Body Angle',
        Body_Up: 'Body Up',
        Cycle_State: 'Cycle State',
        Engine_Aftercooler_Temperature: 'Engine Aftercooler Temperature',
        Engine_Air_Inlet_Pressure: 'Engine Air Inlet Pressure',
        Engine_Coolant_Pressure: 'Engine Coolant Pressure',
        Engine_Coolant_Temp: 'Engine Coolant Temp',
        Engine_Coolant_Temperature: 'Engine Coolant Temperature',
        Engine_Crankcase_Pressure: 'Engine Crankcase Pressure',
        Engine_Desired_Speed: 'Engine Desired Speed',
        Engine_Fuel_Actuator_1_Control_Command:
            'Engine Fuel Actuator 1 Control Command',
        Engine_Fuel_Pressure: 'Engine Fuel Pressure',
        Engine_Fuel_Temp: 'Engine Fuel Temp',
        Engine_Injector_Metering_Rail_1_Pressure:
            'Engine Injector Metering Rail 1 Pressure',
        Engine_Inlet_Temperature: 'Engine Inlet Temperature',
        Engine_Intake_Manifold_1_Pressure: 'Engine Intake Manifold 1 Pressure',
        Engine_Intake_Manifold_1_Temperature:
            'Engine Intake Manifold 1 Temperature',
        Engine_Intake_Manifold_2_Temperature:
            'Engine Intake Manifold 2 Temperature',
        Engine_Load: 'Engine Load',
        Engine_Oil_Pressure: 'Engine Oil Pressure',
        Engine_Oil_Temp_1: 'Engine Oil Temp 1',
        Engine_Percent_Torque: 'Engine Percent Torque',
        Engine_Speed: 'Engine Speed',
        Exhaust_Gas_Port_10: 'Exhaust Gas Port 10',
        Exhaust_Gas_Port_11: 'Exhaust Gas Port 11',
        Exhaust_Gas_Port_12: 'Exhaust Gas Port 12',
        Exhaust_Gas_Port_13: 'Exhaust Gas Port 13',
        Exhaust_Gas_Port_14: 'Exhaust Gas Port 14',
        Exhaust_Gas_Port_15: 'Exhaust Gas Port 15',
        Exhaust_Gas_Port_16: 'Exhaust Gas Port 16',
        Exhaust_Gas_Port_1: 'Exhaust Gas Port 1',
        Exhaust_Gas_Port_2: 'Exhaust Gas Port 2',
        Exhaust_Gas_Port_3: 'Exhaust Gas Port 3',
        Exhaust_Gas_Port_4: 'Exhaust Gas Port 4',
        Exhaust_Gas_Port_5: 'Exhaust Gas Port 5',
        Exhaust_Gas_Port_6: 'Exhaust Gas Port 6',
        Exhaust_Gas_Port_7: 'Exhaust Gas Port 7',
        Exhaust_Gas_Port_8: 'Exhaust Gas Port 8',
        Exhaust_Gas_Port_9: 'Exhaust Gas Port 9',
        Exhaust_Gas_Temp_1: 'Exhaust Gas Temp 1',
        Exhaust_Gas_Temp_2: 'Exhaust Gas Temp 2',
        Exhaust_Gas_Temp_Average: 'Exhaust Gas Temp Average',
        Friction_Percent_Torque: 'Friction Percent Torque',
        Fuel_Level: 'Fuel Level',
        Fuel_Rate: 'Fuel Rate',
        Ground_Speed: 'Ground Speed',
        Idle_Hours: 'Idle Hours',
        Ignition_Voltage: 'Ignition Voltage',
        LF_Strut_Pressure: 'Strut Pressure LF',
        LR_Strut_Pressure: 'Strut Pressure LR',
        Left_Wheel_Speed: 'Left Wheel Speed',
        Manual_Retarder: 'Manual Retarder',
        Payload: 'Payload',
        Payload_VIMS: 'Payload VIMS',
        Payload_PMC: 'Payload PMC',
        RF_Strut_Pressure: 'Strut Pressure RF',
        RR_Strut_Pressure: 'Strut Pressure RR',
        Retarder: 'Retarder',
        Retarder_Brake_Light: 'Retarder Brake Light',
        Retarder_Mode: 'Retarder Mode',
        Right_Wheel_Speed: 'Right Wheel Speed',
        SMUHours: 'SMU Hours',
        Service_Brake: 'Service Brake',
        Speedometer_Speed: 'Speedometer Speed',
        System_Air_Pressure: 'System Air Pressure',
        Throttle_Position: 'Throttle Position',
        Tonnes_Moved: 'Tonnes Moved',
        Total_Engine_Hours: 'Total Engine Hours',
        Total_Fuel_Used: 'Total Fuel Used',
        Trans_Current_Gear: 'Trans Current Gear',
        Trans_Input_Speed: 'Trans Input Speed',
        Trans_Output_Speed: 'Trans Output Speed',
        Trans_Selected_Gear: 'Trans Selected Gear',
        Turbo_1_Boost_Pressure: 'Turbo 1 Boost Pressure',
        Turbo_1_Inlet_Pressure: 'Turbo 1 Inlet Pressure',
        Turbo_2_Boost_Pressure: 'Turbo 2 Boost Pressure',
        Turbo_2_Inlet_Pressure: 'Turbo 2 Inlet Pressure',
        Turbo_3_Inlet_Pressure: 'Turbo 3 Inlet Pressure',
        Turbo_4_Inlet_Pressure: 'Turbo 4 Inlet Pressure',
        Turbo_Boost_Pressure: 'Turbo Boost Pressure',
        Turbo_Boost_Pressure_1: 'Turbo Boost Pressure 1',
        Turbo_Boost_Pressure_2: 'Turbo Boost Pressure 2',
        acceleration_x: 'Acceleration X',
        angular_velocity_x: 'Angular Velocity X',
    },
    [GROUPS.MaxMine]: {
        LF_Strut_Pressure: 'Strut Pressure LF',
        RF_Strut_Pressure: 'Strut Pressure RF',
        LR_Strut_Pressure: 'Strut Pressure LR',
        RR_Strut_Pressure: 'Strut Pressure RR',
    },
};
