import history from '../App/createHistory';

/**
 * Gets the query string and returns it as an object
 * @returns {*}
 */
export function convertURLQueryStringToObject(keepNumbersAsStrings = false) {
    const parts = history.location.search.split('?');
    if (parts.length < 2) {
        return {};
    }
    const queryString = parts[1];
    return decodeQueryString(queryString, keepNumbersAsStrings);
}

/**
 * Decodes a queryString into an object
 * @param {string} - queryString
 * @returns {object}
 */
export function decodeQueryString(queryString, keepNumbersAsStrings = false) {
    if (!queryString) return {};
    const decoded = decodeURIComponent(queryString);
    const parts = decoded.split('&');
    const params = parts.reduce((collection, b) => {
        const [key, value] = b.split('=');
        const typedValue = convertStringToType(value, keepNumbersAsStrings);
        if (collection[key]) {
            collection[key] += `|${typedValue}`;
        } else {
            collection[key] = typedValue;
        }
        return collection;
    }, {});
    return params;
}

/**
 * Convert a string value into the correct type
 * @param value
 * @returns {*}
 */
export function convertStringToType(value, keepNumbersAsStrings = false) {
    if (value === 'undefined') return undefined;
    if (value === 'null') return null;
    if (value === 'true') return true;
    if (value === 'false') return false;
    if (value === '') return '';
    const v = Number(value);
    return isNaN(v) || keepNumbersAsStrings ? value : v;
}

/**
 * Encodes an object into a serialized string
 * @param params
 * @returns {string}
 */
export function getQueryString(params) {
    if (!params || !Object.keys(params).length) return '';
    const esc = encodeURIComponent;
    const groupedByKey = Object.keys(params).reduce((collection, key) => {
        const value = params[key];
        if (collection[key]) {
            collection[key] += `|${value}`;
        } else {
            collection[key] = value;
        }
        return collection;
    }, {});
    return Object.keys(groupedByKey)
        .map((key) => `${esc(key)}=${esc(groupedByKey[key])}`)
        .join('&');
}

/**
 * Updates the URL using history
 * @param {object} params the values to use as the search string
 * @param {object} overridingOptions override any of the history options
 * @param {string} pushOrReplace which method history should use to update the history stack
 */
export function updateURLQueryString(
    params,
    overridingOptions = {},
    pushOrReplace = 'push',
) {
    const qs = getQueryString(params);
    history[pushOrReplace]({
        pathname: history.location.pathname,
        search: `?${qs}`,
        ...overridingOptions,
    });
}

export function updateExistingURLQueryString(
    newParams,
    overridingOptions = {},
    pushOrReplace = 'push',
) {
    const existing = convertURLQueryStringToObject();
    const qs = getQueryString(Object.assign(existing, newParams));
    history[pushOrReplace]({
        pathname: history.location.pathname,
        search: `?${qs}`,
        ...overridingOptions,
    });
}

export function clearURLQueryString() {
    history.replace({
        pathname: history.location.pathname,
        search: `?`,
    });
}
