import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import { actions } from '../CreateUser';
import * as resourceSagas from './resourceSagas';
import { actions as spinnerActions } from '../CreateUser/Modules/spinner';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import { replace } from 'connected-react-router';
import { getMinesiteId } from '../App/Selectors';
import { stateSelectors as authSelectors } from '../Auth/Reducers';
import { getAuthenticateMethod } from './authWatchers';

export function* createUser({ payload }) {
    yield put(
        spinnerActions.setSpinnerState(
            true,
            LOADING_MESSAGES.LOADING__PROCESSING,
        ),
    );

    if (payload.userInfo.operatorPin === '') {
        payload.userInfo.operatorPin = null;
    }

    const minesiteId = yield select(getMinesiteId);

    yield call(getAuthenticateMethod, { payload: payload.userInfo.email });

    const authenticationMethod = yield select(
        authSelectors.getAuthenticationMethod,
    );

    // User credentials is not manage by MaxMine
    if (authenticationMethod['Password-Required'] === false) {
        yield put(
            actions.createUserError(
                `Please reach out to your company administrator and they'll help you out to create the account under ${
                    payload.userInfo.email.split('@').slice(-1)[0] // the domain name in the email or username
                }.`,
            ),
        );
        yield put(spinnerActions.setSpinnerState(false));
        // Dont allow user to continue the flow
        return;
    }

    const apiResponse = yield call(resourceSagas.postCreateUser, {
        OperatorPin: payload.userInfo.operatorPin,
        Crew: payload.userInfo.crew,
        Email: payload.userInfo.email,
        MinesiteId: minesiteId,
        FirstName: payload.userInfo.firstName,
        LastName: payload.userInfo.lastName,
        ContactNumber: payload.userInfo.contactNumber,
        EmployeeId: payload.userInfo.employeeId,
        UserName: payload.userInfo.userName,
        Password: payload.userInfo.password,
        Department: payload.userInfo.department,
        Role: payload.userInfo.role,
        // flag as internal user so not showing to customer
        IsMaxmineUser: payload.userInfo.department === 'MaxMine Internal',
    });
    if (apiResponse.error) {
        yield put(actions.createUserError(apiResponse.error));
        yield put(spinnerActions.setSpinnerState(false));
    } else {
        yield put(
            actions.createUserSuccess(apiResponse.response.SuccessMessage),
        );
        yield put(spinnerActions.setSpinnerState(false));
        yield put(
            replace({
                pathname: 'assign-group',
                search: `email=${encodeURIComponent(payload.userInfo.email)}`,
            }),
        );
    }
}

export default function* watch() {
    yield all([takeLatest(actions.SUBMIT_CREATE_USER, createUser)]);
}
