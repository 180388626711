import combineReducers from 'combine-reducers-global-state';
import { connectRouter } from 'connected-react-router';
import { reducer as toastrReducer } from 'react-redux-toastr';
import {
    rootReducer as tyresAndSafetyRootReducer,
    REDUCER_KEY as TYRES_AND_SAFETY_REDUCER_KEY,
} from '../TyresAndSafety';
import {
    rootReducer as operatorFeedbackRootReducer,
    OPERATOR_FEEDBACK_REDUCER_KEY,
} from '../OperatorFeedback';
import {
    rootReducer as productivityRootReducer,
    PRODUCTIVITY_REDUCER_KEY,
} from '../Productivity';
import {
    rootReducer as enginePerformanceRootReducer,
    REDUCER_KEY as ENGINE_PERFORMANCE_REDUCER_KEY,
} from '../EnginePerformance';
import {
    rootReducer as engineDiagnosticsRootReducer,
    REDUCER_KEY as ENGINE_DIAGNOSTICS_REDUCER_KEY,
} from '../EngineDiagnostics';
import {
    rootReducer as o4rOperatorRootReducer,
    REDUCER_KEY as O4ROPERATOR_REDUCER_KEY,
} from '../O4ROperator';
import { AUTH_REDUCER_KEY } from '../Auth/Reducers';
import { reducer as authReducer } from '../Auth/Reducers/reducer';
import {
    rootReducer as leagueTableRootReducer,
    LEAGUE_TABLE_REDUCER_KEY,
} from '../LeagueTable';
import {
    rootReducer as loaderLeagueReducer,
    REDUCER_KEY as LOADER_LEAGUE_REDUCER_KEY,
} from '../LoaderLeague';
import {
    rootReducer as biasLoadReducer,
    REDUCER_KEY as BIAS_LOAD_REDUCER_KEY,
} from '../BiasLoad';
import {
    rootReducer as maxmineDiagnosticsReducer,
    REDUCER_KEY as MAXMINE_DIAGNOSTICS_REDUCER_KEY,
} from '../MaxmineDiagnostics/CriticalParams';
import {
    rootReducer as haulRoadConditionReducer,
    REDUCER_KEY as HAUL_ROAD_CONDITION_REDUCER_KEY,
} from '../HaulRoadCondition';
import {
    rootReducer as haulTruckFillFactorReducer,
    REDUCER_KEY as HAUL_TRUCK_FILL_FACTOR_REDUCER_KEY,
} from '../HaulTruckFillFactor';
import appReducer, { REDUCER_KEY as APP_REDUCER_KEY } from './Reducers';
import {
    rootReducer as loadUnitDetailReducer,
    REDUCER_KEY as LOAD_UNIT_DETAIL_REDUCER_KEY,
} from '../LoadsInRangeDetail';
import {
    rootReducer as loadUnitTimeDetailReducer,
    REDUCER_KEY as LOAD_UNIT_TIME_DETAIL_REDUCER_KEY,
} from '../LoadingTimeDetail';
import downloadsReducer, {
    REDUCER_KEY as DOWNLOADS_REDUCER_KEY,
} from '../Downloads/reducer';
import {
    rootReducer as truckHistoryReducer,
    REDUCER_KEY as TRUCK_HISTORY_REDUCER_KEY,
} from '../TruckAlertsAnalysis/TruckHistory';
import {
    rootReducer as fleetStatusReducer,
    REDUCER_KEY as FLEET_STATUS_REDUCER_KEY,
} from '../TruckAlertsAnalysis/FleetStatus';
import {
    rootReducer as truckAlertsMapViewReducer,
    REDUCER_KEY as TRUCK_ALERTS_MAP_VIEW_REDUCER_KEY,
} from '../TruckAlertsAnalysis/MapView';
import {
    rootReducer as dataHealthReducer,
    REDUCER_KEY as DATA_HEALTH_REDUCER_KEY,
} from '../DataHealth';
import {
    rootReducer as productionDashboardReducer,
    REDUCER_KEY as PRODUCTION_DASHBOARD_REDUCER_KEY,
} from '../ProductionDashboard';
import {
    rootReducer as createUserReducer,
    REDUCER_KEY as CREATE_USER_REDUCER_KEY,
} from '../CreateUser';
import {
    rootReducer as editUserReducer,
    REDUCER_KEY as EDIT_USER_REDUCER_KEY,
} from '../EditUser';
import {
    rootReducer as manageUserReducer,
    REDUCER_KEY as MANAGE_USER_REDUCER_KEY,
} from '../ManageUser';
import {
    rootReducer as createGroupReducer,
    REDUCER_KEY as CREATE_GROUP_REDUCER_KEY,
} from '../CreateGroup';
import {
    rootReducer as manageGroupReducer,
    REDUCER_KEY as MANAGE_GROUP_REDUCER_KEY,
} from '../ManageGroups';
import {
    rootReducer as editGroupReducer,
    REDUCER_KEY as EDIT_GROUP_REDUCER_KEY,
} from '../EditGroup';
import {
    rootReducer as assignGroupReducer,
    REDUCER_KEY as ASSIGN_GROUP_REDUCER_KEY,
} from '../AssignGroups';
import {
    rootReducer as resultsDelayReducer,
    REDUCER_KEY as RESULTS_DELAY_REDUCER_KEY,
} from '../ResultsDelay';
import {
    rootReducer as activityManagementReducer,
    REDUCER_KEY as ACTIVITY_MANAGEMENT_REDUCER_KEY,
} from '../ActivityManagement';
import {
    reducer as dasReducer,
    REDUCER_KEY as DAS_REDUCER_KEY,
} from '../DAS/Reducers';
import {
    rootReducer as helpReducer,
    REDUCER_KEY as HELP_REDUCER_KEY,
} from '../Help';
import {
    rootReducer as fleetHistoryReducer,
    REDUCER_KEY as FLEET_HISTORY_REDUCER_KEY,
} from '../TruckAlertsAnalysis/FleetHistory';
import {
    rootReducer as driverOnKeyOnReducer,
    REDUCER_KEY as DRIVER_ON_KEY_ON_REDUCER_KEY,
} from '../MaxmineDiagnostics/DriverOnKeyOn';
import {
    rootReducer as networkCoverageReportReducer,
    REDUCER_KEY as NETWORK_COVERAGE_REPORT_REDUCER_KEY,
} from '../NetworkCoverage/';
import {
    rootReducer as powerBiReportReducer,
    REDUCER_KEY as POWER_BI_REPORT_REDUCER_KEY,
} from '../PowerBi/';

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        [APP_REDUCER_KEY]: appReducer,
        [PRODUCTION_DASHBOARD_REDUCER_KEY]: productionDashboardReducer,
        [RESULTS_DELAY_REDUCER_KEY]: resultsDelayReducer,
        [TYRES_AND_SAFETY_REDUCER_KEY]: tyresAndSafetyRootReducer,
        [OPERATOR_FEEDBACK_REDUCER_KEY]: operatorFeedbackRootReducer,
        [PRODUCTIVITY_REDUCER_KEY]: productivityRootReducer,
        [ENGINE_PERFORMANCE_REDUCER_KEY]: enginePerformanceRootReducer,
        [ENGINE_DIAGNOSTICS_REDUCER_KEY]: engineDiagnosticsRootReducer,
        [O4ROPERATOR_REDUCER_KEY]: o4rOperatorRootReducer,
        [AUTH_REDUCER_KEY]: authReducer,
        [LEAGUE_TABLE_REDUCER_KEY]: leagueTableRootReducer,
        toastr: toastrReducer,
        [LOADER_LEAGUE_REDUCER_KEY]: loaderLeagueReducer,
        [BIAS_LOAD_REDUCER_KEY]: biasLoadReducer,
        [MAXMINE_DIAGNOSTICS_REDUCER_KEY]: maxmineDiagnosticsReducer,
        [HAUL_ROAD_CONDITION_REDUCER_KEY]: haulRoadConditionReducer,
        [HAUL_TRUCK_FILL_FACTOR_REDUCER_KEY]: haulTruckFillFactorReducer,
        [LOAD_UNIT_DETAIL_REDUCER_KEY]: loadUnitDetailReducer,
        [LOAD_UNIT_TIME_DETAIL_REDUCER_KEY]: loadUnitTimeDetailReducer,
        [DOWNLOADS_REDUCER_KEY]: downloadsReducer,
        [TRUCK_HISTORY_REDUCER_KEY]: truckHistoryReducer,
        [FLEET_STATUS_REDUCER_KEY]: fleetStatusReducer,
        [TRUCK_ALERTS_MAP_VIEW_REDUCER_KEY]: truckAlertsMapViewReducer,
        [DATA_HEALTH_REDUCER_KEY]: dataHealthReducer,
        [CREATE_USER_REDUCER_KEY]: createUserReducer,
        [EDIT_USER_REDUCER_KEY]: editUserReducer,
        [MANAGE_USER_REDUCER_KEY]: manageUserReducer,
        [CREATE_GROUP_REDUCER_KEY]: createGroupReducer,
        [EDIT_GROUP_REDUCER_KEY]: editGroupReducer,
        [MANAGE_GROUP_REDUCER_KEY]: manageGroupReducer,
        [ASSIGN_GROUP_REDUCER_KEY]: assignGroupReducer,
        [ACTIVITY_MANAGEMENT_REDUCER_KEY]: activityManagementReducer,
        [HELP_REDUCER_KEY]: helpReducer,
        [DAS_REDUCER_KEY]: dasReducer,
        [FLEET_HISTORY_REDUCER_KEY]: fleetHistoryReducer,
        [DRIVER_ON_KEY_ON_REDUCER_KEY]: driverOnKeyOnReducer,
        [NETWORK_COVERAGE_REPORT_REDUCER_KEY]: networkCoverageReportReducer,
        [POWER_BI_REPORT_REDUCER_KEY]: powerBiReportReducer,
    });

export default createRootReducer;
