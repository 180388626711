import moment from 'moment';
import 'moment-timezone';
import errorLogging from '../../utils/errorLogging';
import { operator } from '../operator';

export const ALERT_REASON_CODE = {
    NO_LOGIN: 'NoLogin',
    NO_KEY_ON: 'NoKeyOn',
    EARLY_LOG_IN: 'EarlyLogIn',
    EARLY_LOG_OUT: 'EarlyLogOut',
    LATE_LOG_IN: 'LateLogIn',
    LATE_LOG_OUT: 'LateLogOut',
    LONG_LOGOUT_WHILE_ON: 'LongLogoutWhileOn',
    LONG_OFF_WHILE_LOGGED_IN: 'LongOffWhileLoggedIn',
    MULTIPLE_SIMULTANEOUS_LOGIN: 'MultipleSimultaneousLogin',
    SAME_OPERATOR_MULTIPLE_EQUIPMENT: 'SameOperatorMultipleEquipment',
};

// prettier-ignore
const ALERT_REASON_CODE_MESSAGES = {
    [ALERT_REASON_CODE.NO_LOGIN]: "No operator was logged into :EquipmentSiteName for more than :TimeThreshold seconds.",
    [ALERT_REASON_CODE.NO_KEY_ON]: ":OperatorDisplayName was logged into :EquipmentSiteName for more than :TimeThreshold seconds without the key switched on.",
    [ALERT_REASON_CODE.EARLY_LOG_IN]: ":OperatorDisplayName logged in more than :TimeThreshold seconds before the key switched on.",
    [ALERT_REASON_CODE.EARLY_LOG_OUT]: ":OperatorDisplayName logged out more than :TimeThreshold seconds before the key switched off.",
    [ALERT_REASON_CODE.LATE_LOG_IN]: ":OperatorDisplayName logged in more than :TimeThreshold seconds after the key switched on.",
    [ALERT_REASON_CODE.LATE_LOG_OUT]: ":OperatorDisplayName logged out more than :TimeThreshold seconds after the key switched off.",
    [ALERT_REASON_CODE.LONG_LOGOUT_WHILE_ON]: "There was a period of more than :TimeThreshold seconds where no operators were logged into :EquipmentSiteName.<br />These operators were logged in before or after this period:",
    [ALERT_REASON_CODE.LONG_OFF_WHILE_LOGGED_IN]: "There was a period of more than :TimeThreshold seconds where :EquipmentSiteName had the key switched off while these operators were still logged in:",
    [ALERT_REASON_CODE.MULTIPLE_SIMULTANEOUS_LOGIN]: "These operators were logged in to :EquipmentSiteName simultaneously:",
    [ALERT_REASON_CODE.SAME_OPERATOR_MULTIPLE_EQUIPMENT]: ":OperatorDisplayName was logged into multiple equipment simultaneously:",
};

// prettier-ignore
const ALERT_REASON_CODE_SUB_MESSAGES = {
    [ALERT_REASON_CODE.LONG_LOGOUT_WHILE_ON]: ':OperatorDisplayName from :StartTime to :StopTime',
    [ALERT_REASON_CODE.MULTIPLE_SIMULTANEOUS_LOGIN]: ':OperatorDisplayName from :StartTime to :StopTime',
    [ALERT_REASON_CODE.SAME_OPERATOR_MULTIPLE_EQUIPMENT]: ':EquipmentSiteName from :StartTime to :StopTime',
    [ALERT_REASON_CODE.LONG_OFF_WHILE_LOGGED_IN]: ':OperatorDisplayName from :StartTime to :StopTime',
};

// Matches words with a colon prefix
const MATCH_PLACEHOLDERS = /:\w+/g;

/**
 * Takes a string with placeholders and switches it out for the values
 * @param {string} stringWithPlaceholders - The string to replace, eg. ':OperatorDisplayName cool string" - > "Bob cool string"
 * @param {string[]} keysToReplace - An array of strings to replace in the template string
 * @param {object} placeholderValues - An object map where the keys match a placeholder and the values are what is replaced
 * @return {string}
 */
const replacePlaceholdersWithValues = (
    stringWithPlaceholders,
    keysToReplace,
    placeholderValues,
) => {
    return keysToReplace.reduce((message, placeholder) => {
        // remove colon prefix
        const value = placeholderValues[placeholder];
        const newStr = message.replace(placeholder, value);
        return newStr;
    }, stringWithPlaceholders);
};

const unixToFormatted = (timestamp, timezone) => {
    return moment.unix(timestamp).tz(timezone).format('HH:mm');
};

/**
 * Creates a string messages that can be used to display in the view
 * NOTE: may contain html so if using in JSX, use dangeriouslySetInnerHTML
 * @param {Object} operatorAlertEvent
 * @param {string} timezone
 * @return {{subMessages: string[], primaryMessage: string}}
 */
export function formatMessage(
    operatorAlertEvent,
    timezone,
    operatorDisplayNameType,
) {
    switch (operatorAlertEvent.AlertType) {
        case ALERT_REASON_CODE.NO_LOGIN:
        case ALERT_REASON_CODE.NO_KEY_ON:
        case ALERT_REASON_CODE.EARLY_LOG_IN:
        case ALERT_REASON_CODE.EARLY_LOG_OUT:
        case ALERT_REASON_CODE.LATE_LOG_IN:
        case ALERT_REASON_CODE.LATE_LOG_OUT: {
            const alertMessage =
                ALERT_REASON_CODE_MESSAGES[operatorAlertEvent.AlertType];

            const keysToReplace = alertMessage.match(MATCH_PLACEHOLDERS);
            if (!keysToReplace) {
                errorLogging.logException(
                    new Error(
                        `No placholders found in message, got: ${alertMessage}`,
                    ),
                );
                return {
                    subMessages: [],
                    primaryMessage: '',
                };
            }

            const placeholderValues = {
                ':OperatorDisplayName': operator.displayName.format(
                    operatorDisplayNameType,
                    operatorAlertEvent.Operators[0].operatorDisplayName,
                ),
                ':EquipmentSiteName':
                    operatorAlertEvent.Equipments[0].equipmentSiteName,
                ':TimeThreshold': operatorAlertEvent.TimeThreshold,
            };

            const primaryMessage = replacePlaceholdersWithValues(
                alertMessage,
                keysToReplace,
                placeholderValues,
            );
            return {
                primaryMessage,
                subMessages: [],
            };
        }
        case ALERT_REASON_CODE.LONG_OFF_WHILE_LOGGED_IN:
        case ALERT_REASON_CODE.LONG_LOGOUT_WHILE_ON:
        case ALERT_REASON_CODE.MULTIPLE_SIMULTANEOUS_LOGIN: {
            const primaryMessagePlaceholderValues = {
                ':EquipmentSiteName':
                    operatorAlertEvent.Equipments[0].equipmentSiteName,
                ':TimeThreshold': operatorAlertEvent.TimeThreshold,
            };

            const primaryMessage =
                ALERT_REASON_CODE_MESSAGES[operatorAlertEvent.AlertType];
            const primaryMessageKeysToReplace = primaryMessage.match(
                MATCH_PLACEHOLDERS,
            );
            const formattedPrimaryMessage = replacePlaceholdersWithValues(
                primaryMessage,
                primaryMessageKeysToReplace,
                primaryMessagePlaceholderValues,
            );

            const subMessages = operatorAlertEvent.Operators.map((alert) => {
                const subMessagePlaceholdersValues = {
                    ':OperatorDisplayName': operator.displayName.format(
                        operatorDisplayNameType,
                        alert.operatorDisplayName,
                    ),
                    ':StartTime': unixToFormatted(alert.startTime, timezone),
                    ':StopTime': unixToFormatted(alert.stopTime, timezone),
                };
                const subMessage =
                    ALERT_REASON_CODE_SUB_MESSAGES[
                        operatorAlertEvent.AlertType
                    ];
                const subMessageKeysToReplace = subMessage.match(
                    MATCH_PLACEHOLDERS,
                );

                return replacePlaceholdersWithValues(
                    subMessage,
                    subMessageKeysToReplace,
                    subMessagePlaceholdersValues,
                );
            });
            return {
                primaryMessage: formattedPrimaryMessage,
                subMessages,
            };
        }
        case ALERT_REASON_CODE.SAME_OPERATOR_MULTIPLE_EQUIPMENT: {
            const primaryMessagePlaceholderValues = {
                ':OperatorDisplayName': operator.displayName.format(
                    operatorDisplayNameType,
                    operatorAlertEvent.Operators[0].operatorDisplayName,
                ),
            };

            const primaryMessage =
                ALERT_REASON_CODE_MESSAGES[operatorAlertEvent.AlertType];
            const primaryMessageKeysToReplace = primaryMessage.match(
                MATCH_PLACEHOLDERS,
            );
            const formattedPrimaryMessage = replacePlaceholdersWithValues(
                primaryMessage,
                primaryMessageKeysToReplace,
                primaryMessagePlaceholderValues,
            );

            const subMessages = operatorAlertEvent.Equipments.map((alert) => {
                const subMessagePlaceholdersValues = {
                    ':EquipmentSiteName': alert.equipmentSiteName,
                    ':StartTime': unixToFormatted(alert.startTime, timezone),
                    ':StopTime': unixToFormatted(alert.stopTime, timezone),
                };
                const subMessage =
                    ALERT_REASON_CODE_SUB_MESSAGES[
                        operatorAlertEvent.AlertType
                    ];
                const subMessageKeysToReplace = subMessage.match(
                    MATCH_PLACEHOLDERS,
                );
                return replacePlaceholdersWithValues(
                    subMessage,
                    subMessageKeysToReplace,
                    subMessagePlaceholdersValues,
                );
            });
            return {
                primaryMessage: formattedPrimaryMessage,
                subMessages,
            };
        }
        default:
            errorLogging.logException(
                new Error(
                    `Unknown ALERT_REASON_CODE got: ${operatorAlertEvent.AlertType}`,
                ),
            );
            return {
                subMessages: [],
                primaryMessage: '',
            };
    }
}
