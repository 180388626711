import { put, select, call, takeLatest, all } from 'redux-saga/effects';
import { haulTruckFillFactor } from '@rs/core/application';
import shiftUtils from '@rs/core/utils/shiftUtils';
import errorLogging from '@rs/core/utils/errorLogging';
import {
    actions as filtersActions,
    selectors as filtersSelectors,
} from '../LoadsInRangeDetail/Modules/filters';
import { actions as spinnerActions } from '../LoadsInRangeDetail/Modules/spinner';
import * as loadUnitOperatorLogsModule from '../LoadsInRangeDetail/Modules/loadUnitOperatorLogs';
import * as loadingEventsConformanceModule from '../LoadsInRangeDetail/Modules/loadingEventsConformanceReport';
import * as loadingEventOperatorTargetsModule from '../LoadsInRangeDetail/Modules/loadingEventOperatorTargets';
import * as loadingEventsAggregateModule from '../LoadsInRangeDetail/Modules/loadingEventsAggregate';
import * as loadingEventsHistogramModule from '../LoadsInRangeDetail/Modules/loadingEventsHistogram';
import * as haulTruckFillFactorModule from '../LoadsInRangeDetail/Modules/haulTruckFillFactor';
import * as resourceSagas from './resourceSagas';
import {
    convertURLQueryStringToObject,
    updateURLQueryString,
} from '../Lib/queryStringUtils';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import { SELECT_STRING_DELIMITER } from '../Components/Select';
import { fetchAndStoreResponse } from './utils';
import { API_ARRAY_DELIMITER } from '../Api';

const { getModelsAndCustomisations } = haulTruckFillFactor;

export function* filterUpdated(action) {
    const updatedFilter = action.payload && action.payload.filterName;

    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );

    // These are just some optimisations so we're not always fetching everything
    if (
        updatedFilter === 'Date' ||
        updatedFilter === 'EndDate' ||
        updatedFilter === 'Shift'
    ) {
        // Clear the selected filters
        yield call(clearFilters);
        // If we've changed the shift date, re fetch everything
        yield all([
            call(getLoadUnitOperatorLogs),
            call(getHaulTruckFillFactor),

            call(getLoadingEventsConformanceReport),
            call(getLoadingEventOperatorTargets),
            call(getPayloadDataForTrendChart),
            call(getHistogramData),
        ]);
    } else if (updatedFilter === 'TargetType' || updatedFilter === 'BinSize') {
        // If we're only changing histogram related filters...
        yield call(getHistogramData);
    } else {
        yield all([
            call(getLoadingEventsConformanceReport),
            call(getLoadingEventOperatorTargets),
            call(getPayloadDataForTrendChart),
            call(getHistogramData),
        ]);
    }
    const filters = yield select(filtersSelectors.getFilters);
    updateURLQueryString(filters);
    yield put(spinnerActions.setSpinnerState(false));
}

export function* clearFilters() {
    yield put(
        filtersActions.setFiltersWithUrlParams({
            Operator: '',
            Material: '',
            Crew: '',
            LoadUnitGroup: '',
            LoadUnitModel: '',
            LoadUnit: '',
            Truck: '',
            TruckModelCustomisation: '',
        }),
    );
}

export function* getLoadUnitOperatorLogs() {
    const filters = yield select(filtersSelectors.getFilters);
    yield call(fetchAndStoreResponse, loadUnitOperatorLogsModule, {
        ShiftId: getShiftIds(filters),
    });
}

export function* getLoadingEventsConformanceReport() {
    const filters = yield select(filtersSelectors.getFilters);
    const { Models, Customisations } = getModelsAndCustomisations(
        filters.TruckModelCustomisation.split(SELECT_STRING_DELIMITER),
    );

    yield call(fetchAndStoreResponse, loadingEventsConformanceModule, {
        ShiftId: getShiftIds(filters),
        Material: filters.Material,
        Crew: filters.Crew,
        LoadUnitCategory: filters.LoadUnitGroup,
        LoadUnitModel: filters.LoadUnitModel,
        LoadUnitSiteName: filters.LoadUnit,
        HaulTruckSiteName: filters.Truck,
        HaulTruckModel: Models.join(API_ARRAY_DELIMITER),
        HaulTruckCustomisation: Customisations.join(API_ARRAY_DELIMITER),
    });
}

export function* getHaulTruckFillFactor() {
    const filters = yield select(filtersSelectors.getFilters);
    yield call(fetchAndStoreResponse, haulTruckFillFactorModule, {
        ShiftId: getShiftIds(filters),
    });
}

export function* getHistogramData() {
    const filters = yield select(filtersSelectors.getFilters);

    const { Models, Customisations } = getModelsAndCustomisations(
        filters.TruckModelCustomisation.split(SELECT_STRING_DELIMITER),
    );
    const params = {
        ShiftId: getShiftIds(filters),
        MaterialClasses: filters.Material,
        LoadUnitOperatorCrews: filters.Crew,
        LoadUnitEquipmentCategories: filters.LoadUnitGroup,
        LoadUnitSiteNames: filters.LoadUnit,
        LoadUnitModels: filters.LoadUnitModel,
        HaulTruckSiteNames: filters.Truck,
        HaulTruckModels: Models.join(API_ARRAY_DELIMITER),
        HaulTruckCustomisations: Customisations.join(API_ARRAY_DELIMITER),
        LoadUnitOperatorIds: filters.Operator,
        BinSize: filters.BinSize,
        TargetType: filters.TargetType,
    };

    yield call(fetchAndStoreResponse, loadingEventsHistogramModule, params);
}

export function* getPayloadDataForTrendChart() {
    const filters = yield select(filtersSelectors.getFilters);

    // Create a 30 day range to look back over
    const endDate = shiftUtils.createMomentInSiteTime(
        filters.EndDate || filters.Date,
    );
    const startDate = endDate.clone().subtract(30, 'days');
    const shiftIdRange = shiftUtils.generateShiftIdRange(
        startDate.format(shiftUtils.DATE_FORMAT__DISPLAY),
        endDate.format(shiftUtils.DATE_FORMAT__DISPLAY),
        filters.Shift.split(SELECT_STRING_DELIMITER),
    );

    const { Models, Customisations } = getModelsAndCustomisations(
        filters.TruckModelCustomisation.split(SELECT_STRING_DELIMITER),
    );
    // Make up the parameters for the request
    const params = {
        ShiftId: shiftIdRange.join(API_ARRAY_DELIMITER),
        MaterialClasses: filters.Material,
        LoadUnitOperatorCrews: filters.Crew,
        LoadUnitEquipmentCategories: filters.LoadUnitGroup,
        LoadUnitSiteNames: filters.LoadUnit,
        LoadUnitModels: filters.LoadUnitModel,
        HaulTruckSiteNames: filters.Truck,
        HaulTruckModels: Models.join(API_ARRAY_DELIMITER),
        HaulTruckCustomisations: Customisations.join(API_ARRAY_DELIMITER),

        LoadUnitOperatorIds: filters.Operator,
        TargetType: 'Payload',
    };

    yield call(fetchAndStoreResponse, loadingEventsAggregateModule, params);
}

export function* getLoadingEventOperatorTargets() {
    const filters = yield select(filtersSelectors.getFilters);

    const shiftIds = shiftUtils.generateShiftIdRange(
        filters.Date,
        filters.EndDate,
        filters.Shift.split(SELECT_STRING_DELIMITER),
    );

    const { Models, Customisations } = getModelsAndCustomisations(
        filters.TruckModelCustomisation.split(SELECT_STRING_DELIMITER),
    );

    const { response, error } = yield call(resourceSagas.getLatestShiftId);
    if (error || !response.length) {
        errorLogging.logException(
            new Error(
                'Unable to find latest shift uploaded, check the DB has data',
            ),
        );
        return;
    }

    const selectedOrLastUploadShiftId = shiftUtils.pickLatestUploadedShiftId(
        response[0].ShiftId,
        shiftIds[shiftIds.length - 1],
    );

    const params = {
        // The LoadingEventTargets accept a single ShiftId,
        // we just use the latest selected shiftid on the page
        ShiftId: selectedOrLastUploadShiftId,
        MaterialClasses: filters.Material,
        LoadUnitOperatorCrews: filters.Crew,
        LoadUnitEquipmentCategories: filters.LoadUnitGroup,
        LoadUnitSiteNames: filters.LoadUnit,
        LoadUnitModels: filters.LoadUnitModel,
        HaulTruckSiteNames: filters.Truck,
        HaulTruckModels: Models.join(API_ARRAY_DELIMITER),
        HaulTruckCustomisations: Customisations.join(API_ARRAY_DELIMITER),
        TargetType: 'Payload',
    };

    yield call(
        fetchAndStoreResponse,
        loadingEventOperatorTargetsModule,
        params,
    );
}

export function* updateFiltersWithURLParams() {
    const filterValues = convertURLQueryStringToObject(true);
    yield put(filtersActions.setFiltersWithUrlParams(filterValues));

    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    yield all([
        call(getLoadUnitOperatorLogs),
        call(getHaulTruckFillFactor),
        call(getLoadingEventsConformanceReport),
        call(getLoadingEventOperatorTargets),
        call(getPayloadDataForTrendChart),
        call(getHistogramData),
    ]);
    yield put(spinnerActions.setSpinnerState(false));
}

export default function* watch() {
    yield all([
        takeLatest(
            filtersActions.UPDATE_FILTERS_WITH_URL_PARAMS,
            updateFiltersWithURLParams,
        ),
        takeLatest(filtersActions.FILTER_UPDATED, filterUpdated),
    ]);
}

// Helper function
const getShiftIds = (filters) =>
    shiftUtils
        .generateShiftIdRange(
            filters.Date,
            filters.EndDate,
            filters.Shift.split(SELECT_STRING_DELIMITER),
        )
        .join(API_ARRAY_DELIMITER);
