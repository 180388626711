import { createReducer } from '../../Lib/reduxUtils';
import { LOAD_GROUPS_LIST } from '../Actions';

export const initialState = {
    rows: [],
};

export const setLoadGroupsListResults = (state, { rows }) => ({
    ...state,
    rows,
});

export default createReducer(initialState, {
    [LOAD_GROUPS_LIST]: setLoadGroupsListResults,
});
