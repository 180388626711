import { createSelector } from 'reselect';
import shiftUtils from '@rs/core/utils/shiftUtils';
import {
    filterOptionsToReactSelectFormat,
    filterShiftOptionsToReactSelectFormat,
} from '../../Utils/Filters/filterOptionsToReactSelectFormat';
import { SELECT_STRING_DELIMITER } from '../../Components/Select';
import { selectors as filtersSelectors } from '../Modules/filters';
import {
    getFilterOptionsFromArray,
    getMissingFilterResults,
} from '../../Utils/Filters/filters';
import { selectors as driversSelectors } from '../Modules/drivers';

export const getFilterOptions = createSelector(
    filtersSelectors.getFilters,
    driversSelectors.getAll,
    (filters, driverList) => {
        const shiftSelector = (d) => d.Shift;
        const crewSelector = (d) => d.Crew;

        const shiftOptions = getFilterOptionsFromArray(
            driverList,
            [[crewSelector, filters.Crews]],
            shiftSelector,
        );

        const crewOptions = getFilterOptionsFromArray(
            driverList,
            [[shiftSelector, filters.Shift]],
            crewSelector,
        );

        const shifts = shiftUtils.getAllShiftShortNamesForDate(filters.Date);

        return {
            Crews: filterOptionsToReactSelectFormat(
                getMissingFilterResults(filters.Crews, crewOptions),
            ),
            Shifts: filterShiftOptionsToReactSelectFormat(
                shifts.rawShiftsInDay,
                getMissingFilterResults(filters.Shift, shiftOptions),
            ),
            InWorkshop: [
                { label: 'Exclude Workshop', value: '0' },
                { label: 'Workshop Only', value: '1' },
            ],
            DateShortcuts: filterOptionsToReactSelectFormat([
                'Last Shift',
                'Last Day',
                'Last Week',
                'Last Month',
            ]),
            MaxMineAlertIds: [
                { label: 'Abusive Shift', value: 'Abusive_Shift' },
                { label: 'Brake Temperature', value: 'Brake_Temperature' },
                {
                    label: 'Body Up Ground Speed',
                    value: 'Body_Up_Ground_Speed',
                },
                { label: 'Engine Overspeed', value: 'Engine_Overspeed' },
                {
                    label: 'Engine Prelube Override',
                    value: 'Engine_Prelube_Override',
                },
                { label: 'Payload Overload', value: 'Payload_Overload' },
            ],
        };
    },
);

export const getMapFiltersToAPIParams = createSelector(
    filtersSelectors.getFilters,
    (filters) => {
        const params = {};
        params.shiftIdsAllShifts = shiftUtils.getAllFirstLastFromArray(
            shiftUtils.generateShiftIdRange(filters.Date, filters.EndDate),
        );

        const shiftIdsSelectedShifts = shiftUtils.generateShiftIdRange(
            filters.Date,
            filters.EndDate,
            filters.Shift.split(SELECT_STRING_DELIMITER),
        );
        params.shiftIdsSelectedShifts = shiftUtils.getAllFirstLastFromArray(
            shiftIdsSelectedShifts,
        );

        if (filters.Crews.length > 0) {
            params.Crew = filters.Crews;
        }
        if (filters.InWorkshop.length > 0) {
            params.InWorkshop = filters.InWorkshop;
        }
        if (filters.MaxMineAlertId.length > 0) {
            params.MaxMineAlertId = filters.MaxMineAlertId;
        }

        return params;
    },
);
