import { createReducer } from '../../Lib/reduxUtils';
import { FETCH_MAP_DATA_SUCCESS, FETCH_MAP_DATA_ERROR } from '../Actions';

export const initialState = [];

export const fetchSuccess = (state, { mapFeatures }) => mapFeatures || [];
export const fetchError = () => initialState;

export default createReducer(initialState, {
    [FETCH_MAP_DATA_SUCCESS]: fetchSuccess,
    [FETCH_MAP_DATA_ERROR]: fetchError,
});
