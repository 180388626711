import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TableLink extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const { onButtonClick, onClickData } = this.props;
        onButtonClick(onClickData);
    }

    render() {
        const {
            label,
            isDisabled,
            disabledTooltipText,
            enabledTooltipText,
        } = this.props;
        const disabledAttributes = {
            style: { cursor: 'help' },
            'data-rh': disabledTooltipText,
            'data-rh-cls': 'TableLinkTooltip',
            onClick: null,
        };
        const enabledAttributes = {
            onClick: this.handleClick,
            'data-rh': enabledTooltipText,
        };
        return (
            <div
                className="TableLink"
                {...(isDisabled ? disabledAttributes : enabledAttributes)}
            >
                {label}
            </div>
        );
    }
}

TableLink.defaultProps = {
    isDisabled: false,
    disabledTooltipText:
        "Not available with a date range, please untick 'date range' at the top of the page",
};

TableLink.propTypes = {
    label: PropTypes.string.isRequired,
    onClickData: PropTypes.shape({}),
    onButtonClick: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    disabledTooltipText: PropTypes.string.isRequired,
    enabledTooltipText: PropTypes.string.isRequired,
};

export default TableLink;
