import {
    all,
    delay,
    takeLatest,
    call,
    put,
    select,
    take,
    fork,
    cancel,
    cancelled,
} from 'redux-saga/effects';
import shiftUtils from '@rs/core/utils/shiftUtils';
import errorLogging from '@rs/core/utils/errorLogging';
import {
    convertURLQueryStringToObject,
    updateURLQueryString,
} from '../Lib/queryStringUtils';
import {
    getFilters,
    getMapFiltersToAPIParams,
} from '../Downloads/Selectors/filters';
import { actions as filterActions } from '../Downloads/Modules/filters';
import { actions as fileExportActions } from '../Downloads/Modules/fileExport';
import { downloadFile, addExtensionToFilename } from '../Utils/files';
import * as resourceSagas from './resourceSagas';
import { RESOURCE_PATHS } from '../Api';

export function* filterUpdated() {
    const filters = yield select(getFilters);
    // If dates are invalid go back to waiting for FILTER_UPDATED
    if (
        !shiftUtils.isDateStringValid(
            filters.Date,
            shiftUtils.DATE_FORMAT__VALIDATE,
        ) ||
        (filters.rangeSelected &&
            !shiftUtils.isDateStringValid(
                filters.EndDate,
                shiftUtils.DATE_FORMAT__VALIDATE,
            ))
    ) {
        return;
    }
    updateURLQueryString(filters);
}

export function* updateFiltersWithURLParams() {
    const urlFilterValues = convertURLQueryStringToObject();
    yield put(filterActions.setFiltersWithUrlParams(urlFilterValues));
}

export function* watchExportResults() {
    let lastRequestId = 0;
    // This keeps track of the exports that have been requested
    // e.g. tasks['Cycle Summaries'] = <saga_task>
    //      tasks['Truck Alerts'] = <saga_task>
    const tasks = {};

    while (true) {
        const { payload } = yield take(fileExportActions.EXPORT_RESULTS);
        // The label of the download e.g. 'Truck Alerts'
        const { label } = payload;
        lastRequestId += 1;

        // If there's an existing download task
        if (tasks[label]) {
            // Cancel any existing download
            yield cancel(tasks[label]);
        }
        tasks[label] = yield fork(handleExportResults, {
            ...payload,
            lastRequestId,
        });
    }
}

function* handleExportResults({ endpoint, label, lastRequestId }) {
    const params = yield select(getMapFiltersToAPIParams);
    const filters = yield select(getFilters);
    let results;

    yield put(
        fileExportActions.fetchExportResultsRequested({
            endpoint,
            label,
            filters,
            requestId: lastRequestId,
        }),
    );

    try {
        switch (endpoint) {
            case RESOURCE_PATHS.CLIENTREPORTS_MAIN_TIMINGSUMMARY:
                results = yield call(
                    resourceSagas.getClientReportsMainTimingSummary,
                    { ...params, asCSV: true },
                );
                break;
            case RESOURCE_PATHS.CLIENTREPORTS_MAIN_CYCLESUMMARIES:
                results = yield call(
                    resourceSagas.getClientReportsMainCycleSummaries,
                    { ...params, asCSV: true },
                );
                break;
            case RESOURCE_PATHS.CLIENTREPORTS_MAIN_TRUCKALERTEVENTS:
                results = yield call(
                    resourceSagas.getClientReportsMainTruckAlertEvents,
                    { ShiftId: params.shiftId, asCSV: true },
                );
                break;
            case RESOURCE_PATHS.CLIENTREPORTS_MAIN_SPEEDINGEVENTS:
                results = yield call(
                    resourceSagas.getClientReportsMainSpeedingEvents,
                    { shiftId: params.shiftId, asCSV: true },
                );
                break;
            case RESOURCE_PATHS.CLIENTREPORTS_MAIN_RAMPEVENTS:
                results = yield call(
                    resourceSagas.getClientReportsMainRampEvents,
                    {
                        shiftId: params.shiftId,
                        asCSV: true,
                    },
                );
                break;
            case RESOURCE_PATHS.CLIENTREPORTS_MAIN_CORNEREVENTS:
                results = yield call(
                    resourceSagas.getClientReportsMainCornerEvents,
                    { shiftId: params.shiftId, asCSV: true },
                );
                break;
            case RESOURCE_PATHS.CLIENTREPORTS_MAIN_HAULTRUCKFILLFACTOR: {
                results = yield call(
                    resourceSagas.getClientReportsMainHaulTruckFillFactor,
                    {
                        ShiftId: params.shiftId,
                        AsCSV: true,
                    },
                );
                break;
            }
            case RESOURCE_PATHS.CLIENTREPORTS_MAIN_HAULROADCONDITIONEVENTS: {
                results = yield call(
                    resourceSagas.getClientReportsMainHaulRoadConditionEvents,
                    { ShiftId: params.shiftId, AsCSV: true },
                );
                break;
            }
            case RESOURCE_PATHS.CLIENTREPORTS_MAIN_QUEUEING:
                results = yield call(resourceSagas.getClientReportsQueueing, {
                    shiftId: params.shiftId,
                    asCSV: true,
                });
                break;

            case RESOURCE_PATHS.CLIENTREPORTS_MAIN_EQUIPMENT_ACTIVITY_BASIC:
                results = yield call(
                    resourceSagas.getClientReportsEquipmentActivityBasic,
                    { shiftId: params.shiftId, asCSV: true },
                );
                break;

            case RESOURCE_PATHS.CLIENTREPORTS_MAIN_IDLE_TIME:
                results = yield call(resourceSagas.getClientReportsIdleTime, {
                    shiftId: params.shiftId,
                    asCSV: true,
                });
                break;

            case RESOURCE_PATHS.CLIENTREPORTS_MAIN_ACTIVITY_DETAIL:
                results = yield call(
                    resourceSagas.getClientReportsActivityDetail,
                    {
                        shiftId: params.shiftId,
                        asCSV: true,
                    },
                );
                break;

            case RESOURCE_PATHS.CLIENTREPORTS_MAIN_SHIFT_ACTIVITY_SUMMARY:
                results = yield call(
                    resourceSagas.getClientReportsShiftActivitySummary,
                    {
                        shiftId: params.shiftId,
                        asCSV: true,
                    },
                );
                break;

            case RESOURCE_PATHS.CLIENTREPORTS_MAIN_OPERATORS_IDENTIFICATION_LOGS:
                results = yield call(
                    resourceSagas.getClientReportsOperatorIdentificationLogs,
                    {
                        shiftId: params.shiftId,
                        asCSV: true,
                    },
                );
                break;

            case RESOURCE_PATHS.CLIENTREPORTS_MAIN_RAMP_EVENT_SUMMARIES:
                results = yield call(
                    resourceSagas.getClientReportsMainRampEventSummaries,
                    {
                        shiftId: params.shiftId,
                        asCSV: true,
                    },
                );
                break;

            case RESOURCE_PATHS.CLIENTREPORTS_MAIN_RAMP_EVENT_DETAILS:
                results = yield call(
                    resourceSagas.getClientReportsMainRampEventDetails,
                    {
                        shiftId: params.shiftId,
                        asCSV: true,
                    },
                );
                break;
            case RESOURCE_PATHS.CLIENTREPORTS_MAIN_EQUIPMENT_ALERTS:
                results = yield call(
                    resourceSagas.getClientReportsMainEquipmentAlerts,
                    {
                        shiftId: params.shiftId,
                        asCSV: true,
                    },
                );
                break;
            case RESOURCE_PATHS.CLIENTREPORTS_MAIN_HAUL_TRUCK_OPERATOR_LEAGUE:
                results = yield call(
                    resourceSagas.getClientReportsMainHaulTruckOperatorLeague,
                    {
                        shiftId: params.shiftId,
                        asCSV: true,
                    },
                );
                break;
            case RESOURCE_PATHS.CLIENTREPORTS_MAIN_LOAD_UNIT_OPERATOR_LEAGUE:
                results = yield call(
                    resourceSagas.getClientReportsMainLoadUnitOperatorLeague,
                    {
                        shiftId: params.shiftId,
                        asCSV: true,
                    },
                );
                break;
            case RESOURCE_PATHS.TIME_ACCOUNTING_ACTIVITIES:
                results = yield call(
                    resourceSagas.getTimeAccountingActivities,
                    {
                        shiftId: params.shiftId,
                        asCSV: true,
                    },
                );
                break;
            default:
                return;
        }

        if (results.error) {
            yield put(
                fileExportActions.fetchExportResultsError({
                    requestId: lastRequestId,
                    error: 'Failed to download file',
                }),
            );
            errorLogging.logException(new Error('Failed to download file'), {
                endpoint,
                label,
                filters,
            });
            return;
        }

        const filename = addExtensionToFilename(label, 'csv');
        downloadFile(results.response, filename);
        // Delaying the success to avoid flashing a message up & clearing too quickly if it downloads fast
        yield delay(1000);
        yield put(
            fileExportActions.fetchExportResultsSuccess({
                requestId: lastRequestId,
            }),
        );
    } finally {
        if (yield cancelled()) {
            // If the download is cancelled...
            yield put(
                fileExportActions.fetchExportResultsCancel({
                    requestId: lastRequestId,
                }),
            );
        }
    }
}

export default function* watch() {
    yield all([
        takeLatest(
            filterActions.UPDATE_FILTERS_WITH_URL_PARAMS,
            updateFiltersWithURLParams,
        ),
        takeLatest(filterActions.FILTER_UPDATED, filterUpdated),
        watchExportResults(),
    ]);
}
