import { createSelector } from 'reselect';
import moment from 'moment';
import shiftUtils from '@rs/core/utils/shiftUtils';
import { SELECT_STRING_DELIMITER } from '../../Components/Select';
import { BASE_STATE_NAME, REDUCER_KEY } from '../Reducers';

const getFilterOptions = createSelector(
    (state) => state[REDUCER_KEY][BASE_STATE_NAME],
    (base) => {
        const params = {};
        let convertedStartdate = moment(
            shiftUtils.epochToTZFormatted(
                base.startDate,
                shiftUtils.timezoneAtMinesite ?? undefined,
                shiftUtils.DATE_FORMAT__WITH_TIMEZONE,
            ),
        ).format('DD/MM/YYYY');
        let convertedEndDate = moment(
            shiftUtils.epochToTZFormatted(
                base.endDate,
                shiftUtils.timezoneAtMinesite ?? undefined,
                shiftUtils.DATE_FORMAT__WITH_TIMEZONE,
            ),
        ).format('DD/MM/YYYY');
        //DAS page has no concept of shift as there is no filter for it
        // so it has to be empty string which will give all the shifts depending on the dates/site
        let shift = '';
        // convert it into the 2020103021 format
        const shiftIdsSelectedShifts = shiftUtils.generateShiftIdRange(
            convertedStartdate,
            convertedEndDate,
            shift.split(SELECT_STRING_DELIMITER),
        );
        // get all the shifts related to the dates selected
        if (shiftIdsSelectedShifts !== undefined) {
            params.shiftIdsSelectedShifts = shiftUtils.getAllFirstLastFromArray(
                shiftIdsSelectedShifts,
            );
        } else return params;

        return params;
    },
);

export default getFilterOptions;
