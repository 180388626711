// Determine the minesite id from the window host

export default function determineMinesiteFromHost(hostUrl: string): string {
    // This is the format for the frontend runtime deployed in eks api-apps
    // e.g. <company id>-<minesite id>.frontend.development.max-mine.com
    const apiAppsUrl = hostUrl.match(
        /([A-Za-z]+)-([A-Za-z]+)\.frontend\.[A-Za-z]+\.max-mine\.com$/,
    );
    if (apiAppsUrl !== null) {
        return `${apiAppsUrl[1]}-${apiAppsUrl[2]}`;
    }

    // This is the format for the frontend deployed in production
    // e.g. <minesite id>.<company id>.max-mine.com
    const productionUrl = hostUrl.match(
        /([A-Za-z]+)\.([A-Za-z]+)\.max-mine\.com$/,
    );
    if (productionUrl) {
        return `${productionUrl[2]}-${productionUrl[1]}`;
    }

    return '';
}
