import { roundTo } from '@rs/core/utils/mathHelpers';
import {
    calculateSectionOpportunity,
    sumDriverProductivityEventTimeValues,
} from './driverShiftProductivityEvents';

export function formatShiftProductivityEventsIntoSelectOptions(
    shiftProductivityEventIds,
    driverShiftProductivityEvents,
) {
    return shiftProductivityEventIds.map((shiftProductivityEventId, index) => {
        const driverEventIds =
            driverShiftProductivityEvents.byShiftProductivityEventId[
                shiftProductivityEventId
            ];
        if (!driverEventIds) return null;
        const driverEvents = driverEventIds.map(
            (driverEventId) =>
                driverShiftProductivityEvents.byId[driverEventId],
        );
        const {
            totalLostTime,
            totalPassCount,
            totalDriverTime,
        } = sumDriverProductivityEventTimeValues(driverEvents);

        const roundedPassCount = Math.ceil(totalPassCount);
        const roundedTotalLostTime = roundTo(totalLostTime, 0);
        const sectionOpportunity = calculateSectionOpportunity(
            roundedTotalLostTime,
            totalDriverTime,
        );

        return {
            label: formatEventFilterLabel(
                index,
                sectionOpportunity,
                roundedTotalLostTime,
                roundedPassCount,
            ),
            value: shiftProductivityEventId.toString(),
        };
    });
}

/**
 * Creates a string formated for display in the Event filter
 * @param {number} eventIndex
 * @param {number} sectionOpportunity
 * @param {number} roundedTotalLostTime
 * @param {number} roundedPassCount
 * @return {string}
 */
export function formatEventFilterLabel(
    eventIndex,
    sectionOpportunity,
    roundedTotalLostTime,
    roundedPassCount,
) {
    return `Event ${
        eventIndex + 1
    }: Section Opportunity ${sectionOpportunity}% (${roundedTotalLostTime.toLocaleString()} secs / ${roundedPassCount} passes)`;
}
