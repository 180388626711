import React from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import {
    SynchronisedChart,
    WithDashedSeries,
    LineChart,
} from '@rs/core/falcon';

const LineChartWithDashedSeries = WithDashedSeries(LineChart);

class ProductivityCharts extends React.Component {
    shouldComponentUpdate(nextProps) {
        const { data } = this.props;
        if (deepEqual(data, nextProps.data)) {
            return false;
        }
        return true;
    }
    render() {
        const { data, onHover, chartHeight, showLegend } = this.props;

        // We need to format the data into highcharts form
        const charts = Object.keys(data).reduce(
            (collection, category, index) => {
                const series = data[category];
                // If not a single worker, we take the best practice and average from 'shiftAverage'
                const isForSingleWorker = Object.keys(series).includes(
                    'worker',
                );
                const solidData = getSolidData(series, isForSingleWorker);
                const dashedData = getDashedData(series);
                // Each of our charts, new charts can be added as a new case
                switch (category) {
                    case 'Mean_COMP_Lower_Tolerance_Speed_Driver':
                        collection.push(
                            <LineChartWithDashedSeries
                                title={'Speed (km/h)'}
                                yMin={0}
                                data={solidData}
                                dashedData={dashedData}
                                dashedDataFormatter={(dataObject) =>
                                    // Inject the range property that lets Highcharts
                                    // draw an area between the best practice data
                                    dataObject.map((seriesObject) => {
                                        seriesObject.type = 'arearange';
                                        return seriesObject;
                                    })
                                }
                                options={{
                                    chart: {
                                        zoomType: 'y',
                                    },
                                }}
                                height={chartHeight}
                                // Place the legend above this chart
                                legendPosition={showLegend ? 'top' : undefined}
                                key={index}
                            />,
                        );
                        break;
                    case 'Mean_Trans_Current_Gear_Driver':
                        collection.push(
                            <LineChartWithDashedSeries
                                title={'Gear'}
                                data={solidData}
                                dataFormatter={(gearData) => {
                                    roundData(gearData, 0);
                                    return gearData;
                                }}
                                dashedData={dashedData}
                                dashedDataFormatter={(dashedGearData) => {
                                    roundData(dashedGearData, 0);
                                    return dashedGearData;
                                }}
                                yMax={6}
                                tooltipDecimalPlaces={0}
                                height={chartHeight}
                                options={{
                                    chart: {
                                        zoomType: 'y',
                                    },
                                    yAxis: {
                                        // Keep the y axis ticks as integers
                                        allowDecimals: false,
                                        tickInterval: 2,
                                    },
                                }}
                                key={index}
                            />,
                        );
                        break;
                    case 'Mean_Throttle_Position_Driver':
                        collection.push(
                            <LineChartWithDashedSeries
                                title={'Throttle (%)'}
                                data={solidData}
                                dashedData={dashedData}
                                yMin={0}
                                yMax={100}
                                height={chartHeight}
                                key={index}
                                options={{
                                    chart: {
                                        zoomType: 'y',
                                    },
                                    yAxis: {
                                        // Ensure that there's a 50% tick
                                        tickInterval: 50,
                                    },
                                }}
                            />,
                        );
                        break;
                    case 'Mean_Retarder_Brake_Light_Driver':
                        collection.push(
                            <LineChartWithDashedSeries
                                title={'Retarder (on/off)'}
                                data={solidData}
                                dashedData={dashedData}
                                yMax={1}
                                height={chartHeight}
                                key={index}
                                options={{
                                    chart: {
                                        zoomType: 'y',
                                    },
                                }}
                            />,
                        );
                        break;
                    case 'Mean_Retarder_Driver':
                        collection.push(
                            <LineChartWithDashedSeries
                                title={'Retarder (%)'}
                                data={solidData}
                                dashedData={dashedData}
                                yMin={0}
                                yMax={100}
                                height={chartHeight}
                                key={index}
                                options={{
                                    chart: {
                                        zoomType: 'y',
                                    },
                                    yAxis: {
                                        // Ensure that there's a 50% tick
                                        tickInterval: 50,
                                    },
                                }}
                            />,
                        );
                        break;
                    case 'Z':
                        collection.push(
                            <LineChartWithDashedSeries
                                title={'Elevation (m)'}
                                data={solidData}
                                dashedData={dashedData}
                                height={chartHeight}
                                key={index}
                                options={{
                                    chart: {
                                        zoomType: 'y',
                                    },
                                }}
                            />,
                        );
                        break;
                    default:
                }
                return collection;
            },
            [],
        );

        return (
            <div className="ProductivityCharts">
                <SynchronisedChart onHover={onHover}>
                    {charts}
                </SynchronisedChart>
            </div>
        );
    }
}

/**
 * Helper to convert series data into the right format to display as a
 * <LineChart> solid line
 *
 * @param {object} series - The series data
 * @param {boolean} isForSingleWorker - Whether the data is for individual feedback
 * @returns {array}
 */
const getSolidData = (series, isForSingleWorker) => {
    return Object.keys(series).reduce((collection, name) => {
        const data = series[name];
        switch (name) {
            case 'shiftAverage':
                // If displaying for a whole crew, extract the shift average
                if (!isForSingleWorker) {
                    collection.push({
                        name: 'Shift Average',
                        data: getAverageData(data),
                    });
                }
                break;
            case 'topFive':
                collection.push({
                    name: 'Top Five',
                    color: 'top-five',
                    data: getAverageData(data),
                });
                break;
            case 'bottomFive':
                collection.push({
                    name: 'Bottom Five',
                    color: 'bottom-five',
                    data: getAverageData(data),
                });
                break;
            case 'worker':
                collection.push({
                    name: 'Me',
                    data: getAverageData(data),
                });
                break;
            default:
        }
        return collection;
    }, []);
};

/**
 * Helper to convert series data into the right format to display as a
 * <LineChartWithDashed> dashed line
 *
 * @param {object}
 * @returns {array}
 */
const getDashedData = (series) => {
    return Object.keys(series).reduce((collection, name) => {
        const data = series[name];
        if (name === 'worker') {
            collection.push({
                name: 'Best Practice',
                color: 'best-practice',
                data: getFrankData(data),
            });
        }
        return collection;
    }, []);
};

/**
 * Takes an array of points and extracts average point data
 *
 * @param {array}
 * @returns {array}
 */
const getAverageData = (array) => {
    return array.map((point) => {
        return {
            x: Math.round(point.displacement),
            y: point.average,
            latitude: point.latitude,
            longitude: point.longitude,
        };
    });
};

/**
 * Takes an array of points and extracts Frank point and range data
 *
 * @param {array}
 * @returns {array}
 */
const getFrankData = (array) => {
    return array.map((point) => {
        if (point.hasOwnProperty('frank_min')) {
            return {
                x: Math.round(point.displacement),
                low: point.frank_min,
                high: point.frank,
                truckX: point.x,
                truckY: point.y,
            };
        }
        return {
            x: Math.round(point.displacement),
            y: point.frank,
            truckX: point.x,
            truckY: point.y,
        };
    });
};

/**
 * Rounds every point.y value into a specified number of decimal places.
 * Mutates the array.
 *
 * @param {array} chartData - The data for the chart
 * @param {number} decimalPlaces - The number of decimals to round to
 */
const roundData = (chartData, decimalPlaces) => {
    chartData.forEach((series) => {
        series.data.forEach((point) => {
            if (point.y) {
                point.y = Number(point.y.toFixed(decimalPlaces));
            }
        });
    });
};

ProductivityCharts.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.shape({
                average: PropTypes.number,
                category: PropTypes.string,
                dCOMP_XY_Dist: PropTypes.number,
                displacement: PropTypes.number,
                frank: PropTypes.number,
                x: PropTypes.number,
                y: PropTypes.number,
            }),
        ),
    ).isRequired,
    onHover: PropTypes.func,
    chartHeight: PropTypes.number,
    showLegend: PropTypes.bool,
};

ProductivityCharts.defaultProps = {
    chartHeight: 140,
    showLegend: true,
};

// We use the recompose library to make the functional component pure as well
export default ProductivityCharts;
