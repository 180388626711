import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const EXPORT_RESULTS = `${namespace}/EXPORT_RESULTS`;
    const exportResults = ({ endpoint, label }) =>
        action(EXPORT_RESULTS, { endpoint, label });

    const FETCH_EXPORT_RESULTS_REQUESTED = `${namespace}/FETCH_EXPORT_RESULTS_REQUESTED`;
    const fetchExportResultsRequested = (requestConfig) =>
        action(FETCH_EXPORT_RESULTS_REQUESTED, requestConfig);

    const FETCH_EXPORT_RESULTS_SUCCESS = `${namespace}/FETCH_EXPORT_RESULTS_SUCCESS`;
    const fetchExportResultsSuccess = (requestConfig) =>
        action(FETCH_EXPORT_RESULTS_SUCCESS, requestConfig);

    const FETCH_EXPORT_RESULTS_ERROR = `${namespace}/FETCH_EXPORT_RESULTS_ERROR`;
    const fetchExportResultsError = ({ requestId, error }) =>
        action(FETCH_EXPORT_RESULTS_ERROR, { requestId, error });

    const FETCH_EXPORT_RESULTS_CANCEL = `${namespace}/FETCH_EXPORT_RESULTS_CANCEL`;
    const fetchExportResultsCancel = (requestConfig) =>
        action(FETCH_EXPORT_RESULTS_CANCEL, requestConfig);

    return {
        EXPORT_RESULTS,
        exportResults,
        FETCH_EXPORT_RESULTS_ERROR,
        fetchExportResultsError,
        FETCH_EXPORT_RESULTS_REQUESTED,
        fetchExportResultsRequested,
        FETCH_EXPORT_RESULTS_SUCCESS,
        fetchExportResultsSuccess,
        FETCH_EXPORT_RESULTS_CANCEL,
        fetchExportResultsCancel,
    };
}
