import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_WIKI_REQUESTED = `${namespace}/FETCH_WIKI_REQUEST`;
    const fetchWikiRequested = (contentId) =>
        action(FETCH_WIKI_REQUESTED, { contentId });

    const FETCH_WIKI_SUCCESS = `${namespace}/FETCH_WIKI_SUCCESS`;
    const fetchWikiSuccess = (HTMLString) =>
        action(FETCH_WIKI_SUCCESS, HTMLString);

    const FETCH_WIKI_ERROR = `${namespace}/FETCH_WIKI_ERROR`;
    const fetchWikiError = ({ error }) => action(FETCH_WIKI_ERROR, { error });

    return {
        FETCH_WIKI_REQUESTED,
        fetchWikiRequested,
        FETCH_WIKI_SUCCESS,
        fetchWikiSuccess,
        FETCH_WIKI_ERROR,
        fetchWikiError,
    };
}
