import React from 'react';
import PropTypes from 'prop-types';
import Control from 'react-leaflet-control';
import { withLeaflet } from 'react-leaflet';

class MapControl extends React.PureComponent {
    static propTypes = {
        // This is the bounds that the map with reset to on click. Should be a A L.latLngBounds instance
        children: PropTypes.node,
        // Internal only, Injected by react-leaflet (withLeaflet HOC)
        leaflet: PropTypes.shape({}),
        // Where to position the overlay
        position: PropTypes.oneOf([
            'topleft',
            'bottomleft',
            'topright',
            'bottomright',
        ]),
    };

    // hacky work around to make the legend display on page load
    // https://github.com/LiveBy/react-leaflet-control/issues/27#issuecomment-430564722
    componentDidMount() {
        this.forceUpdate();
    }

    render() {
        const { position, children } = this.props;

        return <Control position={position}>{children}</Control>;
    }
}

export default withLeaflet(MapControl);
