import { FEATURE_IDS } from '@rs/core/features';

export { FEATURE_IDS };

// TODO should these be a feature ?
export const NAVBAR_GROUP_IDS = {
    HAUL_OPERATOR_REPORTS: 1,
    LOAD_OPERATOR_REPORTS: 2,
    ASSET_MANAGEMENT: 3,
    DOWNLOADS: 4,
};

// TODO should these be a feature ?
export const FEATURE_CATEGORY_IDS = {
    NAVBAR: 1,
};
