import * as React from 'react';

function SvgStatusOff(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <circle cx={512} cy={512} r={498.84} fill="#fff" />
            <defs>
                <path
                    d="M938.88 512c0 235.26-190.93 426.67-425.61 426.67S87.66 747.26 87.66 512c0-235.26 190.93-426.67 425.61-426.67S938.88 276.74 938.88 512zM2.54 512c0 282.75 228.68 512 510.73 512S1024 794.75 1024 512 795.32 0 513.27 0 2.54 229.25 2.54 512z"
                    id="status-off_svg__a"
                />
                <path
                    d="M843.42 660.08c-18.79 41.11-43.99 76.47-75.62 106.31-31.62 29.83-69.28 53.48-112.96 71.16C611.16 855.16 565.37 864 517.46 864s-93.7-8.77-137.39-26.45c-43.68-17.68-81.33-41.4-112.96-71.16-31.62-29.77-56.83-65.2-75.61-106.31-18.71-41.11-28.11-84.21-28.11-129.29 0-52.68 12.37-102.26 37.11-148.82 24.73-46.56 59.57-85.6 104.42-117.13 13.23-9.29 27.87-12.9 44-10.83 16.12 1.99 28.96 9.28 38.51 21.66 9.87 12.15 13.62 25.78 11.28 41.03-2.27 15.18-9.95 27.41-22.78 36.69-30.14 21.36-53.39 47.59-69.83 78.53-16.44 30.94-24.66 63.95-24.66 98.94 0 30.06 6.26 58.79 18.71 86.12 12.45 27.33 29.28 50.98 50.49 70.95 21.21 19.96 46.34 35.8 75.39 47.51 29.04 11.72 59.57 17.61 91.51 17.61 31.93 0 62.46-5.89 91.51-17.61 29.04-11.71 54.17-27.55 75.38-47.51 21.22-19.97 38.05-43.62 50.49-70.95 12.45-27.33 18.71-56.06 18.71-86.12 0-34.99-8.22-68-24.66-98.94-16.44-30.94-39.69-57.09-69.82-78.53-12.92-9.28-20.51-21.44-22.78-36.69-2.27-15.18 1.48-28.88 11.27-41.03 9.55-12.45 22.47-19.67 38.75-21.66 16.28-1.99 30.92 1.62 43.76 10.83 44.85 31.53 79.69 70.57 104.42 117.13 24.74 46.56 37.11 96.22 37.11 148.82h-.16c-6.26 58.84-15.63 101.93-28.1 129.29zM576.4 475.24c0 15.03-5.87 28.07-17.53 39.05-11.67 10.97-25.52 16.5-41.49 16.5-15.97 0-29.83-5.53-41.49-16.5-11.66-10.98-17.53-24.02-17.53-39.05V197.57c0-15.02 5.87-28.06 17.53-39.04s25.52-16.5 41.49-16.5c15.97 0 29.82 5.52 41.49 16.5 11.74 10.98 17.53 24.02 17.53 39.04v277.67z"
                    id="status-off_svg__b"
                />
            </defs>
            <use xlinkHref="#status-off_svg__a" />
            <use
                xlinkHref="#status-off_svg__a"
                fillOpacity={0}
                stroke="#000"
                strokeOpacity={0}
            />
            <g>
                <use xlinkHref="#status-off_svg__b" />
                <use
                    xlinkHref="#status-off_svg__b"
                    fillOpacity={0}
                    stroke="#000"
                    strokeOpacity={0}
                />
            </g>
        </svg>
    );
}

export default SvgStatusOff;
