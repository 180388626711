import React from 'react';
import PropTypes from 'prop-types';
import DownIcon from '@ant-design/icons/DownOutlined';
import { Button, Menu, Radio } from 'antd';
import Dropdown from '../Dropdown/Dropdown';
import MenuIcon from '../../assets/js/MenuIcon';

const Navigation = ({ ...props }) => (
    <div className={'Navigation'} {...props} />
);

Navigation.Menu = ({ ...props }) => (
    <Menu mode={'inline'} className={'Navigation__Menu'} {...props} />
);

Navigation.SubMenu = ({ ...props }) => (
    <Menu.SubMenu className={'Navigation__SubMenu'} {...props} />
);

Navigation.Item = ({ ...props }) => (
    <Menu.Item className={'Navigation__Item'} {...props} />
);

Navigation.Group = ({ ...props }) => (
    <Menu.ItemGroup className={'Navigation__Group'} {...props} />
);

// Button that is displayed in the header to open and close the Drawer
Navigation.MenuButton = ({ ...props }) => (
    <Button
        type={'primary'}
        icon={<MenuIcon />}
        className={'Navigation__MenuButton'}
        {...props}
    />
);

Navigation.UserButton = ({ userName, onMenuClick }) => {
    const dropdownItems = (
        <Menu onClick={onMenuClick}>
            <Menu.Item key="logout">Logout</Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={dropdownItems}>
            <Button
                className={'Navigation__UserButton'}
                type={'primary'}
                size={'large'}
            >
                {userName}
                <DownIcon />
            </Button>
        </Dropdown>
    );
};

Navigation.HistoryStatusButton = (props) => {
    return (
        <Radio.Group
            className={'Navigation__HistoryStatusButton'}
            defaultValue={'status'}
            buttonStyle={'solid'}
            {...props}
        >
            <Radio.Button value="history">
                <strong>History</strong>
            </Radio.Button>
            <Radio.Button value="status">
                <strong>Status</strong>
            </Radio.Button>
        </Radio.Group>
    );
};

Navigation.UserButton.propTypes = {
    userName: PropTypes.string,
    onMenuClick: PropTypes.func,
};

// This fixes the issue where the components show up as "unknown" components in storybook
Navigation.Menu.displayName = 'Navigation.Menu';
Navigation.SubMenu.displayName = 'Navigation.SubMenu';
Navigation.Item.displayName = 'Navigation.Item';
Navigation.Group.displayName = 'Navigation.Group';

export default Navigation;
