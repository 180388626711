import { combineReducers } from 'redux';
import { reducer as filters } from '../Modules/filters';
import { reducer as spinner } from '../Modules/spinner';
import { reducer as engineDiagnosticPowerCurveMetrics } from '../Modules/engineDiagnosticPowerCurveMetrics';
import { reducer as equipmentUptime } from '../Modules/equipmentUptime';
import namespace from '../Modules/namespace';

export const REDUCER_KEY = namespace;
export default combineReducers({
    filters,
    spinner,
    engineDiagnosticPowerCurveMetrics,
    equipmentUptime,
});
