import React from 'react';
import { FEATURE_IDS } from '../../Auth/featureIds';
import { FEATURE_META } from '../../Auth/featureMeta';
import TableLink from '../../Components/TableLink/TableLink';
import { HasFeature } from '../../Components/HasFeature';
import { TypeTableColumn, TypeTableValue } from './tablePropTypes';
import OperatorDisplayName from '../../Components/OperatorDisplayName/OperatorDisplayName';

interface LeagueTableDriverCellProps {
    column: TypeTableColumn;
    index: boolean;
    value: TypeTableValue;
}

const LeagueTableDriverCell = ({
    column,
    index,
    value,
}: LeagueTableDriverCellProps) => (
    <td key={`${index}-${column.key}`} className={column.className}>
        <TableLink
            label="T"
            onClickData={{
                worker: value,
                url: FEATURE_META[FEATURE_IDS.TAB_ID__TYRES_AND_SAFETY].path,
            }}
            isDisabled={false}
            onButtonClick={column.customProps.onTableLinkClick}
            enabledTooltipText={
                FEATURE_META[FEATURE_IDS.TAB_ID__TYRES_AND_SAFETY].label
            }
        />
        <HasFeature
            featureId={FEATURE_IDS.LEAGUE__TABLE_SHOW_PRODUCTIVITY_LINK}
        >
            <TableLink
                label="P"
                onClickData={{
                    worker: value,
                    url:
                        FEATURE_META[
                            FEATURE_IDS.TAB_ID__OVERALL_PRODUCTIVITY_EVENTS
                        ].path,
                }}
                isDisabled={column.customProps.isProductivityLinkDisabled}
                onButtonClick={column.customProps.onTableLinkClick}
                enabledTooltipText={
                    FEATURE_META[
                        FEATURE_IDS.TAB_ID__OVERALL_PRODUCTIVITY_EVENTS
                    ].label
                }
            />
        </HasFeature>
        &nbsp;
        <OperatorDisplayName operatorName={value} />
    </td>
);

export default LeagueTableDriverCell;
