import {
    all,
    takeLatest,
    takeEvery,
    put,
    select,
    call,
} from 'redux-saga/effects';
import shiftUtils from '@rs/core/utils/shiftUtils';
import errorLogging from '@rs/core/utils/errorLogging';
import queryString from '@rs/core/utils/queryStringUtils';
import { notification } from '@rs/core/falcon';
import { FEATURE_IDS } from '@rs/core/features';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import * as resourceSagas from './resourceSagas';
import { API_ARRAY_DELIMITER, checkRequestsForErrors } from '../Api';
import { actions as mapFeaturesActions } from '../TyresAndSafety/Modules/mapFeatures';
import { actions as minesiteAreasTreeActions } from '../TyresAndSafety/Modules/minesiteAreasTree';
import { actions as spinnerActions } from '../TyresAndSafety/Modules/spinner';
import * as filtersModule from '../TyresAndSafety/Modules/filters';
import { actions as driversActions } from '../TyresAndSafety/Modules/drivers';
import getMapFiltersToParams from '../TyresAndSafety/Selectors/getMapFiltersToParams';
import downloadFile from '../Utils/files/downloadFile';
import getMapLabel from '../TyresAndSafety/Selectors/getMapLabel';
import * as actions from '../TyresAndSafety/Actions';
import getSyncStateToURL from '../TyresAndSafety/Selectors/getSyncStateToURL';
import * as minesiteMapModule from '../TyresAndSafety/Modules/minesiteMap';
import { AUTH_TOKEN_KEY, getItem } from '../Lib/localStorage';
import { getPageTitle, getPDFSiteConfig } from '../App/Selectors';
import { actions as tyresAndSafetyMapFeaturesActions } from '../TyresAndSafety/Modules/mapFeatures_v2';
import { getGraphqlSdk } from './utils';
import { Sdk, GetMapFeaturesQuery } from '../Graphql/Services';
import polygonLayers from '../TyresAndSafety/Selectors/getMinesiteMapPolygonLayers';
import { actions as mapLegendActions } from '../TyresAndSafety/Modules/mapLegends';
import { MAP_LAYER_LABELS } from '@rs/core/falcon/components/MinesiteMap/constants';
import {
    getAllUniqueSpeedZones,
    getUniqueColorScales,
    getUniqueMaterialcolorScales,
    getUniqueMaterials,
} from '../Modules/mapFeatures_v2/helper';
import { Visibility } from '@rs/core/falcon/components/NewLegend/reduxUtil';

export function* loadResults() {
    const state = yield select();
    const {
        shiftIdsAllShifts,
        shiftIdsSelectedShifts,
        shiftIdsConformance,
        ...params
    } = getMapFiltersToParams(state);
    const { response, error } = yield call(resourceSagas.getLatestShiftId);
    if (error || !Array.isArray(response) || !response.length) {
        errorLogging.logException(
            new Error(
                'Unable to find latest shift uploaded, check the DB has data',
            ),
        );
        return;
    }

    let selectedOrLastUploadShiftId = shiftUtils.pickLatestUploadedShiftId(
        response[0].ShiftId,
        shiftIdsSelectedShifts.last,
    );

    /*check if the latest shift id less than selected shift id
    This will ensure that you always get the latest shift id no matter what what kind of dates we select from the date selector
    */

    if (response[0].ShiftId < selectedOrLastUploadShiftId) {
        selectedOrLastUploadShiftId = response[0].ShiftId;
    }

    // Fetch data
    const results: [] = yield all([
        resourceSagas.getMapEvents({
            ...params,
            ShiftId: shiftIdsSelectedShifts.all.join(API_ARRAY_DELIMITER),
            IsConformant: 0,
        }),
        resourceSagas.getTopClusters({
            ...params,
            ShiftId: shiftIdsSelectedShifts.all.join(API_ARRAY_DELIMITER),
            IsConformant: 0,
        }),
        resourceSagas.getWKTAggregate({
            FirstShiftId: selectedOrLastUploadShiftId,
        }),
        resourceSagas.getMapLabels({ ShiftId: selectedOrLastUploadShiftId }),
        resourceSagas.getSpeedLimitedZones({
            ShiftId: selectedOrLastUploadShiftId,
        }),
        resourceSagas.getConformance({
            ...params,
            StartShiftId: shiftIdsConformance.first,
            EndShiftId: shiftIdsConformance.last,
        }),
        resourceSagas.getSpeedingEvents({
            ...params,
            ShiftId: shiftIdsSelectedShifts.all.join(API_ARRAY_DELIMITER),
            IsConformant: 0,
        }),
        resourceSagas.getRampEvents({
            ...params,
            ShiftId: shiftIdsSelectedShifts.all.join(API_ARRAY_DELIMITER),
            IsConformant: 0,
        }),
        resourceSagas.getCornerLeague({
            ...params,
            ShiftId: shiftIdsSelectedShifts.all.join(API_ARRAY_DELIMITER),
        }),
        resourceSagas.getMinesiteAreasTree(),
        resourceSagas.getS3Download(
            `${selectedOrLastUploadShiftId}/mapFeatures.geo.json`,
        ),
    ]);

    // Check for errors - ignore mapFeatures.geo.json error when missing as not critical & will prevent
    // other results from displaying
    const hasErrors = checkRequestsForErrors(
        results.slice(0, results.length - 2),
    );
    if (hasErrors) {
        yield put(
            actions.loadResultsSuccess({
                mapEvents: [],
                topClusters: [],
                wktData: {},
                mapLabels: [],
                speedLimitedZones: [],
                conformance: [],
                speedingEvents: [],
                rampEvents: [],
                cornerLeague: [],
            }),
        );
        yield put(spinnerActions.setSpinnerState(false));
        return;
    }

    const [
        mapEvents,
        topClusters,
        wktData,
        mapLabels,
        speedLimitedZones,
        conformance,
        speedingEvents,
        rampEvents,
        cornerLeague,
        minesiteAreasTree,
        mapFeatures,
    ] = results;

    yield put(
        actions.loadResultsSuccess({
            mapEvents: mapEvents.response,
            topClusters: topClusters.response,
            wktData: wktData.response,
            mapLabels: mapLabels.response,
            speedLimitedZones: speedLimitedZones.response,
            conformance: conformance.response,
            speedingEvents: speedingEvents.response,
            rampEvents: rampEvents.response,
            cornerLeague: cornerLeague.response,
        }),
    );
    yield put(
        minesiteAreasTreeActions.fetchMinesiteAreasTreeSuccess(
            minesiteAreasTree.response,
        ),
    );
    yield put(mapFeaturesActions.fetchMapFeaturesSuccess(mapFeatures.response));
}

function* filterUpdated() {
    const state = yield select();
    const filters = filtersModule.selectors.getFilters(state);
    // If dates are invalid go back to waiting for FILTER_UPDATED
    if (
        !shiftUtils.isDateStringValid(
            filters.Date,
            shiftUtils.DATE_FORMAT__VALIDATE,
        ) ||
        (filters.rangeSelected &&
            !shiftUtils.isDateStringValid(
                filters.EndDate,
                shiftUtils.DATE_FORMAT__VALIDATE,
            ))
    ) {
        return;
    }
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    // NOTE: these calls are serial because loadResults depends on getDrivers results
    yield call(getDrivers);
    yield call(loadResults);
    yield call(syncStateToURL);
    yield call(prepareToFetchMapFeatures);
    yield put(spinnerActions.setSpinnerState(false));
}

function* getDrivers() {
    const { shiftIdsAllShifts } = yield select(getMapFiltersToParams);
    const { response, error } = yield call(resourceSagas.getDrivers, {
        ShiftId: shiftIdsAllShifts.all.join(API_ARRAY_DELIMITER),
    });
    if (error) {
        return;
    }
    yield put(driversActions.fetchDriversSuccess(response));
}

function* updateFiltersWithURLParams() {
    // check if the mapfeatures already exists in the state
    const { TyresAndSafety } = yield select();
    const mapFeatures =
        TyresAndSafety?.mapFeatures_v2?.speedZonesGeojson?.features;

    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const params = queryString.convertURLQueryStringToObject();

    yield put(actions.syncURLToState(params));
    // NOTE: these calls are serial because loadResults depends on getDrivers results
    yield call(getDrivers);
    yield call(loadResults);
    if (mapFeatures.length === 0) {
        // only call map features if the mapFeatures state is empty
        yield call(prepareToFetchMapFeatures);
    }
    yield put(spinnerActions.setSpinnerState(false));
}

/**
 * This is a workaround for the PDF route which requires params for many different endpoints
 * It takes a nested object and prefixes the object values by their parent key
 * eg. { CornerLeague: { param1: value1 } } = { CornerLeagueParam1: value1 }
 * @param {object} params - The params for the multiple endpoints that the PDF endpoint requires
 * @return {object}
 */
function prefixPDFParams(params) {
    return Object.keys(params).reduce((newParams, key) => {
        const paramKeys = params[key];
        const prefixed = Object.keys(paramKeys).reduce(
            (prefixedParams, paramKey) => {
                prefixedParams[`${key}${paramKey}`] = params[key][paramKey];
                return prefixedParams;
            },
            {},
        );
        return {
            ...newParams,
            ...prefixed,
        };
    }, {});
}

function* printPDF({ payload }) {
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const { ComponentsToRender, MapFilters } = payload;
    const mapLabel = yield select(getMapLabel);
    const {
        shiftIdsAllShifts,
        shiftIdsSelectedShifts,
        shiftIdsConformance,
        ...filterParams
    } = yield select(getMapFiltersToParams);

    const {
        response: latestShiftResponse,
        error: latestShiftError,
    } = yield call(resourceSagas.getLatestShiftId);
    if (
        latestShiftError ||
        !Array.isArray(latestShiftResponse) ||
        !latestShiftResponse.length
    ) {
        errorLogging.logException(
            new Error(
                'Unable to find latest shift uploaded, check the DB has data',
            ),
        );
        return;
    }
    const selectedOrLastUploadShiftId = shiftUtils.pickLatestUploadedShiftId(
        latestShiftResponse[0].ShiftId,
        shiftIdsSelectedShifts.last,
    );

    const params = prefixPDFParams({
        CornerEvents: {
            ...filterParams,
            ShiftId: shiftIdsSelectedShifts.all.join(API_ARRAY_DELIMITER),
            IsConformant: 0,
        },
        TopClusters: {
            ...filterParams,
            ShiftId: shiftIdsSelectedShifts.all.join(API_ARRAY_DELIMITER),
            IsConformant: 0,
        },
        WKTAggregate: { FirstShiftId: selectedOrLastUploadShiftId },
        SpeedLimitedZones: { ShiftId: selectedOrLastUploadShiftId },
        Conformance: {
            ...filterParams,
            StartShiftId: shiftIdsConformance.first,
            EndShiftId: shiftIdsConformance.last,
        },
        SpeedingEvents: {
            ...filterParams,
            ShiftId: shiftIdsSelectedShifts.all.join(API_ARRAY_DELIMITER),
            IsConformant: 0,
        },
        RampEvents: {
            ...filterParams,
            ShiftId: shiftIdsSelectedShifts.all.join(API_ARRAY_DELIMITER),
            IsConformant: 0,
        },
        CornerLeague: {
            ...filterParams,
            ShiftId: shiftIdsSelectedShifts.all.join(API_ARRAY_DELIMITER),
        },
    });

    // UI Specific
    if (MapFilters) {
        params.ShowOverspeedCorners = MapFilters.ShowOverspeedCorners;
        params.ShowSpeedingInZones = MapFilters.ShowSpeedingInZones;
        params.ShowRampEvents = MapFilters.ShowRampEvents;
    }
    params.LabelDate = mapLabel.date;
    params.LabelShift = mapLabel.shift;
    params.LabelDriversAndCrew = mapLabel.driversAndCrew;
    params.ComponentsToRender = ComponentsToRender;

    const { response, error } = yield call(resourceSagas.printPDF, params);
    if (error) return;
    downloadFile(
        response,
        `TyresSafetyReport-${shiftUtils
            .createMomentInSiteTime()
            .format('YYYYMMDD-HHmm')}.pdf`,
    );
    yield put(spinnerActions.setSpinnerState(false));
}

export function* syncStateToURL() {
    const state = yield select();
    const params = getSyncStateToURL(state);
    queryString.updateURLQueryString(params);
}

export function* printPDFMap({ payload }) {
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const token = yield call(getItem, AUTH_TOKEN_KEY);

    const { baseURL } = yield select(getPDFSiteConfig);
    const title = yield select(
        getPageTitle,
        FEATURE_IDS.TAB_ID__TYRES_AND_SAFETY,
    );

    const params = queryString.getQueryString({
        authToken: token,
        pageTitle: title,
        ...payload.params,
    });

    const url = `${baseURL}${payload.endpoint}?${params}`;
    const { response, error } = yield call(resourceSagas.getPDFMap, url);

    const spacelessTitle = title.replace(/\s+/g, '');
    const now = shiftUtils.createMomentInSiteTime().format('YYYYMMDD-HHmm');
    const filename = `${spacelessTitle}-${now}.pdf`;

    yield put(spinnerActions.setSpinnerState(false));
    if (error) {
        notification.error({
            message: 'File download failed',
            description: `Error downloading ${filename}`,
        });
        return;
    }
    downloadFile(response, filename);
}

export function* prepareToFetchMapFeatures() {
    try {
        const state = yield select();
        const { shiftIdsSelectedShifts } = getMapFiltersToParams(state);

        const { last } = shiftIdsSelectedShifts;
        // get the graphql sdk
        const sdk: Sdk = getGraphqlSdk();
        const result: GetMapFeaturesQuery = yield call(sdk.GetMapFeatures, {
            input: {
                shiftIds: [last],
            },
        });

        const response = result?.getMapFeatures?.featuresByShiftId;
        //filter out the mapfeatures according to the last processed shift
        const featuresForSelectedShift = response?.filter(
            (arr) => arr?.shiftId === last,
        )[0];

        const {
            speedZonesGeojson,
            womidsGeojson,
            trackingRegionsGeojson,
            materialMovementsGeojson,
        } = featuresForSelectedShift;

        const emptyFeatureCollection = {
            type: 'FeatureCollection',
            features: [],
        };

        const parsedSpeedZonesGeojson =
            speedZonesGeojson === '' ||
            JSON.parse(speedZonesGeojson)?.features === null
                ? emptyFeatureCollection
                : JSON.parse(speedZonesGeojson);
        const parsedWomidsGeojson =
            womidsGeojson === '' || JSON.parse(womidsGeojson)?.features === null
                ? emptyFeatureCollection
                : JSON.parse(womidsGeojson);
        const parsedTrackingRegionsGeojson =
            trackingRegionsGeojson === '' ||
            JSON.parse(trackingRegionsGeojson)?.features === null
                ? emptyFeatureCollection
                : JSON.parse(trackingRegionsGeojson);
        const parsedMaterialMovementsGeojson =
            materialMovementsGeojson === '' ||
            JSON.parse(materialMovementsGeojson)?.features === null
                ? emptyFeatureCollection
                : JSON.parse(materialMovementsGeojson);

        yield all([
            put(
                tyresAndSafetyMapFeaturesActions.setSpeedZonesGeojson(
                    parsedSpeedZonesGeojson,
                ),
            ),
            put(
                tyresAndSafetyMapFeaturesActions.setWomidsGeojson(
                    parsedWomidsGeojson,
                ),
            ),
            put(
                tyresAndSafetyMapFeaturesActions.setTrackingRegionsGeojson(
                    parsedTrackingRegionsGeojson,
                ),
            ),
            put(
                tyresAndSafetyMapFeaturesActions.setMaterialMovementsGeojson(
                    parsedMaterialMovementsGeojson,
                ),
            ),
        ]);

        // get the state of the maplayers
        const {
            speedRestrictedZones,
            materialMovement,
            mineFeatures,
            mineRegions,
        } = yield select(polygonLayers);

        // set map legend sections
        yield put(
            mapLegendActions.SetSections([
                {
                    label: MAP_LAYER_LABELS.speedLimitedZones,
                    visibility: speedRestrictedZones,
                    items: Object.entries(
                        getUniqueColorScales(
                            getAllUniqueSpeedZones(
                                parsedSpeedZonesGeojson,
                            ) as any,
                        ),
                    ).map(([key, val]) => {
                        return {
                            label: `${key}kph`,
                            borderColor: `${val}`,
                            fillColor: `${val}`,
                            visibility: 'VISIBLE',
                        };
                    }),
                },
                {
                    label: MAP_LAYER_LABELS.materialMovement,
                    visibility: materialMovement,
                    items: Object.entries(
                        getUniqueMaterialcolorScales(
                            getUniqueMaterials(
                                parsedMaterialMovementsGeojson,
                            ) as any,
                        ),
                    ).map(([key, val]) => {
                        return {
                            label: key,
                            borderColor: val,
                            fillColor: val,
                            visibility: 'VISIBLE',
                        };
                    }),
                },
                {
                    /* any polygon that has Maintenance, TyreBay or Workshop in them will be categorised as Maintenence
                   anything else is ancilary/goline
                */

                    label: MAP_LAYER_LABELS.trackingRegions,
                    visibility: mineFeatures,
                    items: [
                        {
                            label: 'Maintenance',
                            borderColor: '#45818e',
                            fillColor: '#45818e',
                            visibility: 'VISIBLE',
                        },

                        {
                            label: 'Ancillary/GoLine',
                            borderColor: '#ff9900',
                            fillColor: '#ff9900',
                            visibility: 'VISIBLE',
                        },
                    ],
                },
                {
                    label: MAP_LAYER_LABELS.mineRegions,
                    visibility: mineRegions,
                    items: [
                        {
                            label: 'MineRegions',
                            borderColor: '#000000',
                            fillColor: '',
                            visibility: 'VISIBLE',
                        },
                    ],
                },
            ]),
        );
    } catch (error) {
        yield put(spinnerActions.setSpinnerState(false));
        throw new Error(error.message);
    }
}

type FlipLegendPayload = {
    payload: { sectionLabel: string; visibility: Visibility };
};

export function* flipLegendLayer({ payload }: FlipLegendPayload) {
    try {
        // fire action to toggle legend layer
        yield put(mapLegendActions.ChangeSectionVisibility(payload));
    } catch (error) {
        throw new Error(error.message);
    }
}

export default function* watch() {
    yield all([
        takeLatest(
            filtersModule.actions.UPDATE_FILTERS_WITH_URL_PARAMS,
            updateFiltersWithURLParams,
        ),
        takeLatest(filtersModule.actions.FILTER_UPDATED, filterUpdated),
        takeEvery(actions.PRINT_PDF, printPDF),
        takeEvery(actions.PRINT_PDF_MAP, printPDFMap),
        takeEvery(
            [
                minesiteMapModule.actions.MAP_VIEWPORT_CHANGED,
                minesiteMapModule.actions.MAP_FILTER_UPDATED,
            ],
            syncStateToURL,
        ),
        takeEvery(actions.MAP_LEGEND_LAYER_FLIP, flipLegendLayer),
    ]);
}
