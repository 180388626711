import React from 'react';
import PropTypes from 'prop-types';
import WarningIcon from '@ant-design/icons/WarningOutlined';
import errorLogging from '../../utils/errorLogging';
import { Row } from '../../falcon';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error) {
        errorLogging.logException(error);
        this.setState({ hasError: true });
    }

    render() {
        const { primaryMessage, secondaryMessage } = this.props;
        if (this.state.hasError) {
            return (
                <Row className="ErrorBoundary">
                    <div className="ErrorBoundary__Body">
                        <div className="ErrorBoundary__Style">
                            <WarningIcon className="ErrorBoundary__Icon" />
                            <div className="ErrorBoundary__PrimaryMessage">
                                {primaryMessage}
                            </div>
                            <div className="ErrorBoundary__SecondaryMessage">
                                {secondaryMessage}
                            </div>
                        </div>
                    </div>
                </Row>
            );
        }
        return this.props.children;
    }
}
ErrorBoundary.defaultProps = {
    primaryMessage: 'Oops! Something went wrong',
    secondaryMessage: 'Please refresh the page or try again later',
};

ErrorBoundary.propTypes = {
    primaryMessage: PropTypes.string,
    secondaryMessage: PropTypes.string,
    children: PropTypes.node,
};

export default ErrorBoundary;
