import createActions from '../../Modules/minesiteMap/actions';
import createReducer, {
    syncURLToState,
} from '../../Modules/minesiteMap/reducer';
import createSelectors from '../../Modules/minesiteMap/selectors';
import { SYNC_URL_TO_STATE } from '../Actions';
import namespace from './namespace';
import { getComponentConfig } from '../../App/Selectors';
import { getEnabledBackgroundLayers } from '../../Modules/minesiteMap/utils';
import { APP_INIT_SUCCESS } from '../../App/Actions';
import { MAP_LAYER_LABELS } from '@rs/core/falcon';
import { normaliseString } from '@rs/core/utils';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].minesiteMap;
const mapLabelKeys = normaliseString.mapLayerLabelsToCamelCase(
    MAP_LAYER_LABELS,
);
/**
 * Create the module parts
 */
export const actions = createActions(namespace);

const initialState = {
    [mapLabelKeys.background]: false,
    [mapLabelKeys.backgroundClientProvided]: true,
    [mapLabelKeys.roadPath]: false,
    [mapLabelKeys.equipment]: true,
    [mapLabelKeys.equipmentTrails]: true,
    [mapLabelKeys.holdingStates]: true,
    [mapLabelKeys.sources]: false,
    [mapLabelKeys.speedLimitedZones]: false,
    [mapLabelKeys.materialMovement]: false,
    [mapLabelKeys.trackingRegions]: false,
    [mapLabelKeys.mineRegions]: false,
    [mapLabelKeys.geofenceLabels]: true,
    center: undefined,
    zoom: 13,
};

function appInitSuccess(state, payload) {
    const mapConfig = payload.component.MinesiteMap;
    const defaultLayers = getEnabledBackgroundLayers(mapConfig);
    return { ...state, ...defaultLayers };
}

export const reducer = createReducer(
    {
        mapFilterUpdated: actions.MAP_FILTER_UPDATED,
        mapViewportChanged: actions.MAP_VIEWPORT_CHANGED,
    },
    {
        [SYNC_URL_TO_STATE]: syncURLToState,
        [actions.RESET_MAP_SETTINGS]: (state, _, rootState) => {
            const mapConfig = getComponentConfig(rootState, 'MinesiteMap');
            const defaultLayers = getEnabledBackgroundLayers(mapConfig);
            return {
                ...initialState,
                ...defaultLayers,
            };
        },
        [APP_INIT_SUCCESS]: appInitSuccess,
    },
    initialState,
);

export const selectors = createSelectors(statePath);
