import combineReducers from 'combine-reducers-global-state';
import { reducer as filters } from '../Modules/filters';
import { reducer as spinner } from '../Modules/spinner';
import { reducer as haulTruckFillFactor } from '../Modules/haulTruckFillFactor';
import { reducer as loadingEventOperatorTargets } from '../Modules/loadingEventOperatorTargets';
import { reducer as loadingEventsAggregate } from '../Modules/loadingEventsAggregate';
import { reducer as loadingEventsConformance } from '../Modules/loadingEventsConformanceReport';
import { reducer as loadUnitOperatorLogs } from '../Modules/loadUnitOperatorLogs';
import { reducer as loadingEventsHistogram } from '../Modules/loadingEventsHistogram';
import namespace from '../Modules/namespace';

export const REDUCER_KEY = namespace;
export default combineReducers({
    loadUnitOperatorLogs,
    filters,
    spinner,
    loadingEventsConformance,
    haulTruckFillFactor,
    loadingEventOperatorTargets,
    loadingEventsAggregate,
    loadingEventsHistogram,
});
