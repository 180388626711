import PropTypes from 'prop-types';
import React from 'react';
import merge from 'lodash/merge';
import identity from 'lodash/identity';
import FalconChart from '../FalconChart/FalconChart';

const LineChart = ({ options, ...props }) => {
    // Start building our line chart options...
    const lineOptions = {
        plotOptions: {
            series: {
                className: 'LineChart__series',
            },
        },
    };

    // Merge in any option overrides given to us
    merge(lineOptions, options);

    return <FalconChart options={lineOptions} type={'line'} {...props} />;
};

LineChart.propTypes = {
    /**
     * Data for the chart. See https://api.highcharts.com/highcharts/series for more info on how to correctly format the data.
     *
     * You can use the dataFormatter prop to convert this object to the correct format.
     */
    data: PropTypes.arrayOf(PropTypes.any).isRequired,

    /**
     * A meaningful name for the chart.
     */
    title: PropTypes.string,

    /**
     * Function that takes one argument 'data', which is the input data prop.
     *
     * Use this function to convert the data object into a correct format according to https://api.highcharts.com/highcharts/series.
     */
    dataFormatter: PropTypes.func,

    /**
     * Enable or disable showing a marker for each data point
     */
    enableMarkers: PropTypes.bool,

    /**
     * An explicit height for the chart. If a number, the height is given in pixels. If given a percentage string (for example '56%'), the height is given as the percentage of the actual chart width. This allows for preserving the aspect ratio across responsive sizes.
     *
     * By default (when null) the height is calculated from the offset height of the containing element, or 400 pixels if the containing element's height is 0.
     */
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Determines where the legend is placed.
     */
    legendPosition: PropTypes.oneOf(['center', 'right', 'top', 'none']),

    /**
     * Any instance specific options that you want to override (things like stylings etc.)
     *
     * See https://api.highcharts.com/highcharts for a list of chart options
     */
    options: PropTypes.objectOf(PropTypes.any),

    /**
     * The timezone to use when displaying dates and times.
     */
    timezone: PropTypes.string,

    /**
     * The type of X axis to display.
     */
    xAxisType: PropTypes.oneOf([
        'linear',
        'logarithmic',
        'datetime',
        'category',
    ]),

    /**
     * The units string that is appended to each X axis tick value
     */
    xAxisUnits: PropTypes.string,

    /**
     * The X axis label.
     */
    xLabel: PropTypes.string,

    /**
     * The units string that is appended to each Y axis tick value
     */
    yAxisUnits: PropTypes.string,

    /**
     * The Y axis label.
     */
    yLabel: PropTypes.string,

    /**
     * The maximum value for the Y axis
     */
    yMax: PropTypes.number,

    /**
     * The minimum value for the Y axis
     */
    yMin: PropTypes.number,
};

LineChart.defaultProps = {
    yAxisUnits: '',
    options: {},
    dataFormatter: identity,
};

export default LineChart;
