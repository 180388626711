import { combineReducers } from 'redux';

import { reducer as spinner } from '../Modules/spinner';
import { reducer as cyclesummariesPayload } from '../Modules/cyclesummariesPayload';
import namespace from '../Modules/namespace';

export const REDUCER_KEY = namespace;
export default combineReducers({
    spinner,
    cyclesummariesPayload,
});
