import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_HAUL_ROAD_CONDITION_SUMMARY_SUCCESS = `${namespace}/FETCH_HAUL_ROAD_CONDITION_SUMMARY_SUCCESS`;
    const fetchHaulRoadConditionSummarySuccess = (resultsArray) =>
        action(FETCH_HAUL_ROAD_CONDITION_SUMMARY_SUCCESS, resultsArray);

    const FETCH_HAUL_ROAD_CONDITION_SUMMARY_ERROR = `${namespace}/FETCH_HAUL_ROAD_CONDITION_SUMMARY_ERROR`;
    const fetchHaulRoadConditionSummaryError = ({ error }) =>
        action(FETCH_HAUL_ROAD_CONDITION_SUMMARY_ERROR, { error });

    return {
        FETCH_HAUL_ROAD_CONDITION_SUMMARY_SUCCESS,
        fetchHaulRoadConditionSummarySuccess,
        FETCH_HAUL_ROAD_CONDITION_SUMMARY_ERROR,
        fetchHaulRoadConditionSummaryError,
    };
}
