import { combineReducers } from 'redux';
import { featureTogglesByStringToById } from '@rs/core/features';
import { createReducer } from '../../Lib/reduxUtils';
import { APP_INIT_SUCCESS, APP_INIT_REQUEST, APP_INIT_ERROR } from '../Actions';
import createInitReducer from '../../Modules/init/reducer';

const initialState = {
    minesiteId: '',
    featureToggles: {},
};

const appInitRequest = (preloadedState) => ({
    ...initialState,
    ...preloadedState,
});

const appInitSuccess = (state, newSiteConfiguration) => {
    newSiteConfiguration.featureToggles = featureTogglesByStringToById(
        newSiteConfiguration.featureToggles,
    );

    return {
        ...state,
        ...newSiteConfiguration,
    };
};

const appInitError = () => initialState;

const publicSiteConfigReducer = createReducer(initialState, {
    [APP_INIT_REQUEST]: appInitRequest,
    [APP_INIT_SUCCESS]: appInitSuccess,
    [APP_INIT_ERROR]: appInitError,
});

const initReducer = createInitReducer({
    onRequest: APP_INIT_REQUEST,
    onSuccess: APP_INIT_SUCCESS,
    onError: APP_INIT_ERROR,
});

export default combineReducers({
    config: publicSiteConfigReducer,
    init: initReducer,
});
