import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

export interface LoadingEventOperatorTarget {
    ConformanceScore: ScoreTarget[];
    HaulTruckTypes: Model[];
    LoadUnitTypes: Model[];
    MaterialTypes: string[];
    ShiftId: number;
    Target: number;
    TargetName: string;
    TargetType: string;
}

export interface ScoreTarget {
    DeltaFromTarget: number;
    PercentFromTarget?: number;
    Score: number;
}

export interface Model {
    EquipmentModel: string;
    EquipmentSubModel: string;
}

export interface ConformanceConfig {
    min: number;
    max: number;
    conformance: 'Low' | 'Medium' | 'High';
}

/**
 * Takes a conformance definition and set of score targets, and outputs a conformance range. For example:
 *
 * Input:
 *   scoreTargets = [
 *      { Score: 0, DeltaFromTarget: -100},
 *      { Score: 0, DeltaFromTarget: -1},
 *      { Score: 0, DeltaFromTarget: 26},
 *      { Score: 0, DeltaFromTarget: 100},
 *      { Score: 75, DeltaFromTarget: 25},
 *      { Score: 100, DeltaFromTarget: 0},
 *   ]
 *
 *   conformanceConfig = [
 *      { min: 0, max: 80, conformance: 'Low' },
 *      { min: 80, max: 90, conformance: 'Medium' },
 *      { min: 90, max: 100, conformance: 'High' },
 *   ]
 *
 * Output:
 *  [
 *      { conformance: 'Low', min: -500, max: -100 },
 *      { conformance: 'Low', min: -100, max: 0 },
 *      { conformance: 'High', min: 0, max: 11 },
 *      { conformance: 'Medium', min: 11, max: 24 },
 *      { conformance: 'Low', min: 24, max: 81 },
 *      { conformance: 'Low', min: 81, max: 500 }
 *  ];
 *
 */
export function getConformanceRanges(
    scoreTargets: ScoreTarget[],
    conformanceConfig: ConformanceConfig[],
): ConformanceConfig[] {
    if (scoreTargets.length === 0 || conformanceConfig.length === 0) {
        return [];
    }
    // The scoreTargets define a mapping that looks like:
    //
    //  Score
    //      +
    //  100 |                   X         X
    //      |
    //   90 |
    //      |
    //   80 |
    //      |
    //   75 |             X
    //      |
    //      |
    //      |
    //    0 +--X-----X---------------------X------------+

    // Step 1:
    // Iterate through each min and max in conformanceConfig, and add
    // any values that don't exist to scoreTargets (using simple linear interpolation)
    //
    // New scoreTargets:
    //
    //  Score
    //      +
    //  100 |                   X         X
    //      |
    //   90 |                 O            O
    //      |
    //   80 |               O               O
    //      |
    //   75 |             X
    //      |
    //      |
    //      |
    //    0 +--X-----X-------------------------X--------+
    //
    scoreTargets = sortBy(scoreTargets, ['DeltaFromTarget']);
    conformanceConfig.forEach(({ min, max }) => {
        if (!_scoreExists(min, scoreTargets)) {
            scoreTargets = _addToScoreTargets(min, scoreTargets);
        }
        if (!_scoreExists(max, scoreTargets)) {
            scoreTargets = _addToScoreTargets(max, scoreTargets);
        }
    });

    // Step 2:
    // Remove all values in the new score targets that are not present
    // as min/max values in the conformance config.
    //
    // New scoreTargets:
    //
    //  Score
    //      +
    //  100 |                   X         X
    //      |
    //   90 |                 X            X
    //      |
    //   80 |               X               X
    //      |
    //   75 |
    //      |
    //      |
    //      |
    //    0 +--X-----X-------------------------X--------+
    //
    const allMinMaxValues = uniq(
        conformanceConfig.reduce((array, { min, max }) => {
            array.push(min);
            array.push(max);
            return array;
        }, []),
    );
    scoreTargets = scoreTargets.filter((target) =>
        allMinMaxValues.includes(target.Score),
    );

    // Step 3:
    // Create bands based on the scoreTargets and add the conformance
    // values from the conformanceConfig
    const ranges = _createRanges(scoreTargets, conformanceConfig);

    // Step 4:
    // At this point there are sections of ranges that can be aggregated together.
    // For example:
    //  [...
    //  { min: 30, max: 104, conformance: 'Low' },
    //  { min: 104, max: 110, conformance: 'Low' },
    //  ...]
    //
    //  can become
    //  [...
    //  { min: 30, max: 110, conformance: 'Low' },
    //  ...]
    return _combineRanges(ranges);
}

/**
 * Given a deltaTarget value, this will interpolate the correct score from
 * the scoreTargets
 */
export function getScoreForDeltaTarget(
    deltaFromTarget: number,
    scoreTargets: ScoreTarget[],
): number {
    const scoreTargetsSorted = sortBy(scoreTargets, ['DeltaFromTarget']);
    for (let i = 0; i <= scoreTargetsSorted.length - 2; i += 1) {
        const point1 = scoreTargetsSorted[i];
        const point2 = scoreTargetsSorted[i + 1];
        if (
            deltaFromTarget >= point1.DeltaFromTarget &&
            deltaFromTarget <= point2.DeltaFromTarget
        ) {
            return _interpolation(
                deltaFromTarget,
                [point1.DeltaFromTarget, point1.Score],
                [point2.DeltaFromTarget, point2.Score],
            );
        }
    }
    return null;
}

// Helpers

function _interpolation(x, [x1, y1], [x2, y2]): number {
    return y1 + ((x - x1) * (y2 - y1)) / (x2 - x1);
}

export function _scoreExists(
    score: number,
    scoreTargets: ScoreTarget[],
): Boolean {
    return scoreTargets.some((target) => target.Score === score);
}

export function _addToScoreTargets(
    score: number,
    scoreTargets: ScoreTarget[],
): ScoreTarget[] {
    const newTargets = [];

    for (let i = 0; i <= scoreTargets.length - 2; i += 1) {
        // Get two adjacent targets
        const point1 = scoreTargets[i];
        const point2 = scoreTargets[i + 1];
        if (
            // Does the score lie between these targets?
            (score > point1.Score && score < point2.Score) ||
            (score > point2.Score && score < point1.Score)
        ) {
            // It does! Interpolate and add it to newTargets
            newTargets.push({
                Score: score,
                DeltaFromTarget: _interpolation(
                    score,
                    [point1.Score, point1.DeltaFromTarget],
                    [point2.Score, point2.DeltaFromTarget],
                ),
            });
        }
    }
    // Merge and sort the new targets with the existing targets
    return sortBy(scoreTargets.concat(newTargets), ['DeltaFromTarget']);
}

export function _createRanges(
    scoreTargets: ScoreTarget[],
    conformanceConfig: ConformanceConfig[],
): ConformanceConfig[] {
    // NOTE: This function assumes that the score values in the targets
    // directly match up with what is in the config. E.g.
    // scoreTargets = [
    //   { Score: 0, DeltaFromTarget: -100 },
    //   { Score: 0, DeltaFromTarget: -1 },
    //   { Score: 75, DeltaFromTarget: -0.25 },
    //   { Score: 90, DeltaFromTarget: -0.1 },
    //   { Score: 100, DeltaFromTarget: 0 },
    //   { Score: 90, DeltaFromTarget: 10 },
    //   { Score: 75, DeltaFromTarget: 25 },
    //   { Score: 0, DeltaFromTarget: 26 },
    //   { Score: 0, DeltaFromTarget: 100 },
    // ]
    //
    // conformanceConfig = [
    //   { min: 0, max: 75, conformance: 'Low' },
    //   { min: 75, max: 90, conformance: 'Medium' },
    //   { min: 90, max: 100, conformance: 'High' },
    // ]

    const findConformance = (target1, target2) => {
        const averageScore = (target1.Score + target2.Score) / 2;
        return conformanceConfig.find(
            (config) =>
                config.min <= averageScore && averageScore <= config.max,
        ).conformance;
    };

    const ranges = [];
    for (let i = 0; i <= scoreTargets.length - 2; i += 1) {
        const target1 = scoreTargets[i];
        const target2 = scoreTargets[i + 1];
        const conformance = findConformance(target1, target2);
        ranges.push({
            min: target1.DeltaFromTarget,
            max: target2.DeltaFromTarget,
            conformance,
        });
    }
    return ranges;
}

export function _combineRanges(
    ranges: ConformanceConfig[],
): ConformanceConfig[] {
    // NOTE: This function assumes that the ranges are sorted, and that the
    // min, max numbers are properly linked.

    const canCombine = (range1, range2) =>
        range1.conformance === range2.conformance && range1.max === range2.min;

    // Check if there are no ranges to combine in the array
    const noneLeftToCombine = ranges.every((range, i) => {
        if (i === ranges.length - 1) return true;
        return !canCombine(range, ranges[i + 1]);
    });

    // If there are no ranges to combine just return...
    if (noneLeftToCombine) {
        return ranges;
    }

    const combinedRanges = [];
    let i = 0;

    while (i <= ranges.length - 1) {
        // If the last element in the array
        if (i === ranges.length - 1) {
            combinedRanges.push(ranges[i]);
            i += 1;
        } else if (canCombine(ranges[i], ranges[i + 1])) {
            const merged = {
                ...ranges[i],
                max: ranges[i + 1].max,
            };
            combinedRanges.push(merged);

            // Make sure to skip over [i+1]
            i += 2;
        } else {
            // Can't be combined
            combinedRanges.push(ranges[i]);
            i += 1;
        }
    }
    // Keep combining!
    return _combineRanges(combinedRanges);
}
