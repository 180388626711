import combineReducers from 'combine-reducers-global-state';
import { reducer as filters } from '../Modules/filters';
import { reducer as spinner } from '../Modules/spinner';
import { reducer as tableOrder } from '../Modules/tableOrder';
import { reducer as o4rOperatorByDriverShift } from '../Modules/o4rOperatorByDriverShift';
import { reducer as drivers } from '../Modules/drivers';
import namespace from '../Modules/namespace';

export const REDUCER_KEY = namespace;
export default combineReducers({
    filters,
    spinner,
    tableOrder,
    o4rOperatorByDriverShift,
    drivers,
});
