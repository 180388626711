import namespace from '../namespace';
import actions from './actions';

// NOTE: this file has been split up to fix a circular dependency problem
// A bit of a workaround but due to time constraints
export { actions };
export { reducer, FORM_STEPS, FORM_TYPES, initialState } from './reducer';
export const selectors = {
    get: (state) => state[namespace].editCircuitForm,
};
