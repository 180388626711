import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Menu, Button } from '@rs/core/falcon';
import ExportCSVLabel from './ExportCSVLabel';
import { FEATURE_IDS } from '../../Auth/featureIds';
import { HasFeature } from '../HasFeature';
import { callOrValue } from '../../Lib/componentUtils';

const PageControlFileExport = ({ items, label, onChange, isDisabled }) => {
    const allOptionsAreDisabled = items.every((item) => item.isDisabled);
    if (allOptionsAreDisabled) {
        return null;
    }

    const menu = (
        <Menu>
            {items.map((item, i) => (
                <Menu.Item key={i} onClick={() => onChange(item)}>
                    {item.label}
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <HasFeature featureId={FEATURE_IDS.PAGE_CONTROL__EXPORT_RESULTS}>
            <Dropdown
                overlay={menu}
                trigger={['click']}
                disabled={isDisabled || allOptionsAreDisabled}
            >
                <Button>{callOrValue(label)}</Button>
            </Dropdown>
        </HasFeature>
    );
};

PageControlFileExport.defaultProps = {
    items: [],
    label: ExportCSVLabel,
    onChange: () => null,
    isDisabled: false,
};

PageControlFileExport.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            isDisabled: PropTypes.bool,
        }),
    ),
    label: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    onChange: PropTypes.func,
    isDisabled: PropTypes.bool,
};

export default PageControlFileExport;
