import createActions from '../../Modules/meta/actions';
import createReducer from '../../Modules/meta/reducer';
import createSelectors from '../../Modules/meta/selectors';
import namespace from './namespace';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state: any) => state[namespace].meta;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);
export const reducer = createReducer({
    setFeatureId: actions.SET_FEATURE_ID,
    unsetFeatureId: actions.UNSET_FEATURE_ID,
});
export const selectors = createSelectors(statePath);
