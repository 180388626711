/**
 * Combines operator alert events when they are of the same type to minimise the amount of items
 * to be displayed
 * @param {{}[]} operatorAlertEvents
 * @return {{}[]}
 */
export default function mergeActivityBasicEvents(operatorAlertEvents) {
    const { series } = operatorAlertEvents.reduce(
        (collection, row, i, array) => {
            if (!collection.lastEvent) {
                collection.lastEvent = row;
            }
            if (
                collection.lastEvent.KeyOn === row.KeyOn &&
                collection.lastEvent.StopTime === row.StartTime
            ) {
                collection.lastEvent.StopTime = row.StopTime;
            } else if (i === array.length - 1) {
                // Current row doesn't match previous KeyOn
                // If last item add to collection
                collection.series.push(row);
            } else {
                // Setup for next iteration
                collection.series.push(collection.lastEvent);
                collection.lastEvent = row;
            }
            return collection;
        },
        { series: [], lastEvent: null },
    );
    return series;
}
