import React, { useCallback, useState } from 'react';
import { ControlOptions } from 'leaflet';
import Control from 'react-leaflet-control';

type MapControlProps = {
    position: ControlOptions['position'];
    content: React.ReactNode;
};

type MapControlWithPopupProps = {
    popupContent: React.ReactNode;
    popupVisible: boolean;
    onContentClick: () => void;
    onCloseClick: () => void;
    hide: boolean;
} & MapControlProps;

export const MapControl = ({ position, content }: MapControlProps) => {
    const contentToRender = React.createElement(content);
    return (
        <Control position={position}>
            <div className={'MinesiteMap__Legend__Container'}>
                {contentToRender}
            </div>
        </Control>
    );
};

export const MapControlWithPopup = ({
    position,
    content,
    popupContent,
    onContentClick,
    onCloseClick,
    popupVisible,
    hide = false,
}: MapControlWithPopupProps) => {
    const contentToRender = React.createElement(content);
    const popUpContentToRender = React.createElement(popupContent);
    if (hide) {
        return null;
    }
    return (
        <Control position={position}>
            {!popupVisible ? (
                <div
                    className={'MinesiteMap__Legend__Container clickable'}
                    onClick={onContentClick}
                >
                    <div>{contentToRender}</div>
                </div>
            ) : null}
            {!!popupVisible ? (
                <div className="MinesiteMap__Legend__Popup__Container">
                    <div className="close" onClick={onCloseClick}>
                        ×
                    </div>
                    <div className="content">{popUpContentToRender}</div>
                </div>
            ) : null}
        </Control>
    );
};
