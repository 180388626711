// reference to https://www.npmjs.com/package/react-ga4
export interface IGoogleAnalyticsEvent {
    category: TAnalyticsCategories;
    action: string;
    label?: string;
    value?: number;
    nonInteraction?: boolean;
    transport?: 'xhr' | 'beacon' | 'image';
}

/**
 * Represents a collection of tracked events for Google Analytics.
 */
export const TrackedEvents = {
    UserAuthentication: [
        'LoginSuccessfully',
        'LoginFailed',
        'LogoutSuccessfully',
    ],
    FeatureUsage: ['Visit', 'On', 'Off'],
    SubFeatureUsage: [
        'Visit',
        'Click',
        'PrintPDF - Page',
        'PrintPDF - Map',
        'Filter Updated',
        'Export CSV',
        'Linked to service desk',
        'Help modal opened',
    ],
    ErrorTracking: ['Warning', 'Fatal'],
} as const;

type TrackedEventsType = typeof TrackedEvents;

export type TAnalyticsCategories = keyof TrackedEventsType;
export type TActionNames<
    T extends TAnalyticsCategories
> = TrackedEventsType[T][number];

export const isActionNameValid = <T extends TAnalyticsCategories>(
    category: T,
    action: TActionNames<T>,
): action is TActionNames<T> => {
    return (
        // @ts-ignore
        TrackedEvents[category]?.includes(action) || false
    );
};
