import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_ENGINE_DIAGNOSTIC_POWER_CURVE_METRICS_SUCCESS = `${namespace}/FETCH_ENGINE_DIAGNOSTIC_POWER_CURVE_METRICS_SUCCESS`;
    const fetchEngineDiagnosticPowerCurveMetricsSuccess = (resultsArray) =>
        action(
            FETCH_ENGINE_DIAGNOSTIC_POWER_CURVE_METRICS_SUCCESS,
            resultsArray,
        );

    const FETCH_ENGINE_DIAGNOSTIC_POWER_CURVE_METRICS_ERROR = `${namespace}/FETCH_ENGINE_DIAGNOSTIC_POWER_CURVE_METRICS_ERROR`;
    const fetchEngineDiagnosticPowerCurveMetricsError = ({ error }) =>
        action(FETCH_ENGINE_DIAGNOSTIC_POWER_CURVE_METRICS_ERROR, { error });

    return {
        FETCH_ENGINE_DIAGNOSTIC_POWER_CURVE_METRICS_SUCCESS,
        fetchEngineDiagnosticPowerCurveMetricsSuccess,
        FETCH_ENGINE_DIAGNOSTIC_POWER_CURVE_METRICS_ERROR,
        fetchEngineDiagnosticPowerCurveMetricsError,
    };
}
