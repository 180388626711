import { convertMMUnit } from '@rs/core/utils/unitFormatters';
import shiftUtils from '@rs/core/utils/shiftUtils';
import moment from 'moment-timezone';

// Converts data response to machines/equipment
export function machinesFromData(data) {
    return data.features
        .filter(
            (feature) =>
                feature.geometry.coordinates !== null &&
                feature.geometry.coordinates.length > 0,
        )
        .map((feature) => machineFromDataFeature(feature));
}

// Adds data for metric to each machine
export function machineMetricsFromData(machines, data, metric) {
    if (data.results) {
        data.results.timeseries.forEach((machineData) => {
            machineData.data = machineData.data.map((d) => {
                // Convert times into browser local time
                return {
                    ...d,
                    timestamp: moment(
                        moment
                            .unix(d.timestamp)
                            .tz(shiftUtils.timezoneAtMinesite)
                            .format(shiftUtils.DATE_FORMAT__WITHOUT_TIMEZONE),
                        shiftUtils.DATE_FORMAT__WITHOUT_TIMEZONE,
                    ).unix(),
                };
            });
            machines = appendMachineValuesFromDataFeature(
                machines,
                { ...machineData, unit: data.results.unit },
                metric,
                true,
            );
        });
    } else if (data.features) {
        data.features
            .filter((feature) => feature.geometry.coordinates.length > 0)
            .forEach((feature) => {
                machines = appendMachineValuesFromDataFeature(
                    machines,
                    feature,
                    metric,
                    false,
                );
            });
    }
    return machines;
}

function machineFromDataFeature(feature) {
    const machine = {
        id: feature.properties.identifier.equipment_site_name,
        name: feature.properties.identifier.equipment_site_name,
        equipmentClass: feature.properties.identifier.equipment_class,
        equipmentSubClass: feature.properties.identifier.equipment_sub_class,
        coordinates: feature.geometry.coordinates.map((coord) => [
            coord[1],
            coord[0],
        ]),
        positions: feature.geometry.coordinates.map((coord, idx) => {
            return {
                x: coord[1],
                y: coord[0],
                timestamp: feature.properties.times[idx],
            };
        }),
        times: feature.properties.times,
        values: {},
        status: null,
    };

    return machine;
}

function appendMachineValuesFromDataFeature(
    machines,
    data,
    metric,
    chart = false,
) {
    if (chart) {
        const existingMachine = machines.find(
            (existing) => existing.id === data.identifier.equipment_site_name,
        );

        if (!existingMachine) return machines;

        if (!existingMachine.values[metric]) {
            existingMachine.values[metric] = {
                data: [],
                loading: false,
                loaded: false,
            };
        }

        existingMachine.values[metric].data = data.data.map((item) => {
            return {
                timestamp: item.timestamp * 1000,
                value: convertMMUnit(item.value, data.unit),
            };
        });
        existingMachine.values[metric].loading = false;
        existingMachine.values[metric].loaded = true;
        existingMachine.values[metric].unit = data.unit;
    } else {
        let existingMachine = machines.find(
            (existing) =>
                existing.id === data.properties.identifier.equipment_site_name,
        );

        if (!existingMachine) {
            existingMachine = machineFromDataFeature(
                data,
                machines.length,
                machines.length + 1,
            );
            machines.push(existingMachine);
        }
        existingMachine.values[metric] = data.properties.values.map(
            (value, idx) => {
                return { timestamp: data.properties.times[idx], value };
            },
        );
    }

    return machines;
}
