import { takeLatest, put, call, all } from 'redux-saga/effects';
import { actions } from '../ManageGroups';
import { forwardTo } from '../App/createHistory';
import * as resourceSagas from './resourceSagas';
import { actions as spinnerActions } from '../ManageUser/Modules/spinner';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';

function* loadGroups() {
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const { response, error } = yield call(resourceSagas.getGroupList);
    if (error) {
        return;
    }

    response.rows.forEach((row) => {
        if (!row.Description) {
            row.Description = '';
        }
    });
    yield all([
        put(actions.loadGroupsList(response)),
        put(spinnerActions.setSpinnerState(false)),
    ]);
}

/* eslint-disable require-yield */
// Could move this elsewhere, just call inside the component
// or use a <Link> component from react-router
// possible to use it here in the future
export function* linkToPage({ payload }) {
    forwardTo(`${payload.url}?id=${payload.groupData.Id}`);
}
/* eslint-enable */

export function* deleteGroup({ payload }) {
    yield put(
        spinnerActions.setSpinnerState(
            true,
            LOADING_MESSAGES.LOADING__PROCESSING,
        ),
    );

    const { error } = yield call(resourceSagas.postDeleteGroup, {
        Id: payload.groupData.Id,
    });
    if (error) {
        return;
    }

    yield all([
        put(actions.requestGroupsList()),
        put(spinnerActions.setSpinnerState(false)),
    ]);
}

export default function* watch() {
    yield all([
        takeLatest(actions.LINK_TO_PAGE, linkToPage),
        takeLatest(actions.REQUEST_GROUPS_LIST, loadGroups),
        takeLatest(actions.DELETE_GROUP, deleteGroup),
    ]);
}
