import PropTypes from 'prop-types';
import React from 'react';
import merge from 'lodash/merge';
import FalconChart from '../FalconChart/FalconChart';

const ColumnChart = ({ options, ...props }) => {
    // Start building our line chart options...
    const columnOptions = {
        xAxis: {
            type: 'category',
        },
        tooltip: {
            shared: true,
        },

        plotOptions: {
            column: {
                stacking: 'normal',
            },
        },
    };

    // Merge in any option overrides given to us
    merge(columnOptions, options);

    return (
        <FalconChart
            options={columnOptions}
            type={'column'}
            enableMarkers={true}
            {...props}
        />
    );
};

ColumnChart.propTypes = {
    /**
     * Any instance specific options that you want to override (things like stylings etc.)
     *
     * See https://api.highcharts.com/highcharts for a list of chart options
     */
    options: PropTypes.objectOf(PropTypes.any),

    /*
     * put in proptypes
     */
};

export default ColumnChart;
