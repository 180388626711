import {
    ColorRange,
    Features,
    MaterialMovementsGeojson,
    SpeedZonesGeojson,
    TrackingRegionsGeojson,
} from '../types';

export const speedZoneColorRange = [
    '#da70d6',
    '#3d85c6',
    '#cc0000',
    '#674ea7',
    '#f1c232',
    '#018001',
];

export const materialMovementColorRange = {
    Gold: '#da9d2c',
    Waste: '#a1e260',
    'oxide waste': '#a1e260',
    Coal: '#649bd2',
    Ore: '#da9d2c',
    'lgs ore': '#da9d2c',
    'mgs ore': '#da9d2c',
    'hgs ore': '#da9d2c',
    'shg ore': '#da9d2c',
    'oxide ore': '#da9d2c',
    Copper: '#da9d2c',
    Stemming: '#c43c39',
    Topsoil: '#6d3517',
    Earth: ' #6d3517',
    Basalt: '#595959',
    others: (name: string) => name, // this will assign an unknown material with its name as CSS color property
};

export const trackingRegionsColorRange: ColorRange = {
    maintenance: '#45818e',
    tyrebay: '#45818e',
    workshop: '#45818e',
    others: '#ff9900',
};
export const getAllUniqueSpeedZones = (speedZoneGeoJson: SpeedZonesGeojson) => {
    return speedZoneGeoJson.features
        .map((d) => d.properties?.signPostedUpperLimit)
        .filter((item, index, arr) => arr.indexOf(item) === index)
        .sort((a, b) => a - b);
};

export const getUniqueMaterials = (
    materialMovementGeoJson: MaterialMovementsGeojson,
) => {
    return materialMovementGeoJson.features
        .map((d) => d.properties?.material)
        .filter((item, index, arr) => arr.indexOf(item) === index)
        .sort((a, b) => a - b);
};

export const getUniqueColorScales = (uniqueSpeedZones: []) => {
    return uniqueSpeedZones.reduce((a, b, i) => {
        a[b] = speedZoneColorRange[i];
        return a;
    }, {});
};

export const getUniqueMaterialcolorScales = (uniqueMaterials: []) => {
    return uniqueMaterials.reduce((a, b: string) => {
        let color = b.toLowerCase();
        switch (color) {
            case 'coal':
                a[b] = materialMovementColorRange.Coal;
                break;
            case 'waste':
                a[b] = materialMovementColorRange.Waste;
                break;
            case 'oxide waste':
                a[b] = materialMovementColorRange.Waste;
                break;
            case 'gold':
                a[b] = materialMovementColorRange.Gold;
                break;
            case 'ore':
                a[b] = materialMovementColorRange.Ore;
                break;
            case 'lgs ore':
                a[b] = materialMovementColorRange.Ore;
                break;
            case 'mgs ore':
                a[b] = materialMovementColorRange.Ore;
                break;
            case 'hgs ore':
                a[b] = materialMovementColorRange.Ore;
                break;
            case 'shg ore':
                a[b] = materialMovementColorRange.Ore;
                break;
            case 'oxide ore':
                a[b] = materialMovementColorRange.Ore;
                break;
            case 'copper':
                a[b] = materialMovementColorRange.Copper;
                break;
            case 'stemming':
                a[b] = materialMovementColorRange.Stemming;
                break;
            case 'topsoil':
                a[b] = materialMovementColorRange.Topsoil;
                break;
            case 'earth':
                a[b] = materialMovementColorRange.Earth;
                break;
            case 'basalt':
                a[b] = materialMovementColorRange.Basalt;
                break;
            default:
                // any other unknown material will be assigned its name as CSS color property
                a[b] = materialMovementColorRange.others(color);
        }
        return a;
    }, {});
};

export const addColortoSpeedZonesGeojson = (
    speedZoneGeoJson: SpeedZonesGeojson,
) => {
    if (speedZoneGeoJson && speedZoneGeoJson.features.length === 0) return;

    const uniqueSpeedZones: [] = getAllUniqueSpeedZones(speedZoneGeoJson);

    const colorScale = getUniqueColorScales(uniqueSpeedZones);

    const withColorKey = speedZoneGeoJson.features.map((d) => {
        const newColorScale =
            colorScale[`${d.properties?.signPostedUpperLimit}`];
        return {
            ...d,
            properties: {
                ...d.properties,
                Color: newColorScale,
            },
        };
    });

    return {
        ...speedZoneGeoJson,
        features: withColorKey,
    };
};

export const addColorToMaterialMovementsGeojson = (
    materialMovementGeoJson: MaterialMovementsGeojson,
) => {
    if (
        materialMovementGeoJson &&
        materialMovementGeoJson.features.length === 0
    )
        return {};

    const uniqueMaterials = getUniqueMaterials(materialMovementGeoJson);

    const colorScale = getUniqueMaterialcolorScales(uniqueMaterials);

    const withColorKey = materialMovementGeoJson.features.map((d) => {
        const newColorScale = colorScale[`${d.properties?.material}`];

        return {
            ...d,
            properties: {
                ...d.properties,
                Color: newColorScale,
            },
        };
    });

    return {
        ...materialMovementGeoJson,
        features: withColorKey,
    };
};

export const addColortoTrackingRegionsPolygon = (
    trackingRegionGeoJson: TrackingRegionsGeojson,
) => {
    if (trackingRegionGeoJson && trackingRegionGeoJson.features.length === 0)
        return;

    const withColorKey = trackingRegionGeoJson.features.map((d) => {
        let nameLocation: string = (d?.properties?.name).toLowerCase();
        let trackingNametype = 'others';
        const areaMatcher = /maintenance|workshop|tyrebay/g;
        const matches = [...nameLocation.matchAll(areaMatcher)][0];
        if (matches && matches.length === 1) {
            trackingNametype = matches[0];
        }

        return {
            ...d,
            properties: {
                ...d.properties,
                Color: trackingRegionsColorRange[trackingNametype],
            },
        };
    });
    return {
        ...trackingRegionGeoJson,
        features: withColorKey,
    };
};

export const removeName = <GeoJsonType extends Features>(
    oneOfTheTypeOfGeoJson: GeoJsonType,
) => {
    const newFeatures = oneOfTheTypeOfGeoJson.features.map((geoJson) => {
        var { ...newProperties } = geoJson.properties;

        delete newProperties?.name;

        return {
            ...geoJson,
            properties: newProperties,
        };
    });

    return {
        ...oneOfTheTypeOfGeoJson,
        features: newFeatures,
    };
};
