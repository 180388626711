import { combineReducers } from 'redux';
import filters from './filters';
import spinner from './spinner';
import loaderOperatorLogs from './loaderOperatorLogs';
import loaderEvents from './loaderEvents';
import loadUnitConformanceWeightings from './loadUnitConformanceWeightings';

export const REDUCER_KEY = 'LoaderLeague';
export default combineReducers({
    loaderOperatorLogs,
    filters,
    spinner,
    loaderEvents,
    loadUnitConformanceWeightings,
});
