import React from 'react';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import PropTypes from 'prop-types';
import {
    AutoSizer,
    Grid,
    ScrollSync,
    defaultCellRangeRenderer,
} from 'react-virtualized';
import CellContainer from './CellContainer';
import GridLinesContainer from './GridLinesContainer';
import GridLinesHorizontal from './GridLinesHorizontal';
import GridLinesVertical from './GridLinesVertical';

const cellRangeRenderer = ({
    width,
    height,
    gridWidthFromLeft,
    avgColWidth,
    bodyColCount,
    bodyRowCount,
    rowHeight,
    maxGridWidth,
}) => (props) => {
    const children = defaultCellRangeRenderer(props);
    children.push(
        <GridLinesContainer
            key="key-1"
            style={{
                height,
                width: maxGridWidth,
                position: 'absolute',
                top: props.scrollTop,
                left: gridWidthFromLeft,
            }}
        >
            <GridLinesVertical
                colCount={bodyColCount}
                left={({ index }) => index * avgColWidth}
                height={height}
                width={avgColWidth}
            />
            <GridLinesHorizontal
                rowCount={bodyRowCount}
                height={rowHeight}
                width={width}
                left={() => props.scrollLeft}
                top={({ index }) => index * rowHeight - props.scrollTop}
            />
        </GridLinesContainer>,
    );
    return children;
};

/**
 * This grid layout is comprised of 9 grids to create the 'fixed' columns as follows
 *
 * | leftHeaderCell | gridHeader | rightHeaderCell |
 * | leftSideCell   | gridBody   | rightSideCell   |
 * |            <horizontal-scrollbar>             |
 * | leftFooterCell | gridFooter | rightFooterCell |
 *
 */

const GRID_NAMES = {
    LEFT_HEADER_CELL: 'leftHeaderCell',
    GRID_HEAD: 'gridHeader',
    RIGHT_HEADER_CELL: 'rightHeaderCell',
    LEFT_SIDE_CELL: 'leftSideCell',
    GRID_BODY: 'gridBody',
    RIGHT_SIDE_CELL: 'rightSideCell',
    LEFT_FOOTER_CELL: 'leftFooterCell',
    GRID_FOOTER: 'gridFooter',
    RIGHT_FOOTER_CELL: 'rightFooterCell',
};

function isGridEnabled(gridName, disabledGrids) {
    return disabledGrids.indexOf(gridName) === -1;
}

class PivotGrid extends React.PureComponent {
    static defaultProps = {
        frozenColumns: {
            left: 1,
            top: 1, // Note: Don't use these yet.
            right: 1,
            bottom: 1, // Note: Don't use these yet.
        },
        rowHeight: 30,
        headerRowHeight: 100,
        height: 500,
        minBodyRows: 12,
        renderLeftSideLabels: () => null,
        rootClassName: 'PivotGridContainer',
        disabledGrids: [],
        disableScrollbars: false,
    };

    static propTypes = {
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                width: PropTypes.number.isRequired,
                header: PropTypes.func.isRequired,
                cell: PropTypes.func.isRequired,
                footer: PropTypes.func.isRequired,
                headerContainerProps: PropTypes.shape({}),
            }),
        ),
        frozenColumns: PropTypes.shape({
            left: PropTypes.number,
            right: PropTypes.number,
            bottom: PropTypes.number,
            top: PropTypes.number,
        }),
        headerRowHeight: PropTypes.number,
        rowHeight: PropTypes.number,
        data: PropTypes.arrayOf(PropTypes.shape({})),
        height: PropTypes.number,
        renderLeftSideLabels: PropTypes.func,
        rootClassName: PropTypes.string,
        disabledGrids: PropTypes.arrayOf(
            PropTypes.oneOf([
                'leftHeaderCell',
                'gridHeader',
                'rightHeaderCell',
                'leftSideCell',
                'gridBody',
                'rightSideCell',
                'leftFooterCell',
                'gridFooter',
                'rightFooterCell',
            ]),
        ),
        disableScrollbars: PropTypes.bool,
    };

    state = {
        scrollbarsConfig: {
            horizontal: true,
            size: 0,
            vertical: false,
        },
    };

    componentDidUpdate() {
        // Force update the right footer grid, otherwise the value is still the old cached value
        // after filters have changed. No need to compare props since it's extending PureComponent
        if (this.pivotGridRightFooterGridContainerHeaderGrid) {
            this.pivotGridRightFooterGridContainerHeaderGrid.forceUpdate();
        }
    }

    getColumnWidth = ({ index }) => {
        return this.props.columns[index].width;
    };

    getColumnWidthInReverse = ({ index }) => {
        const { frozenColumns, columns } = this.props;
        return columns.slice(-frozenColumns.right)[index].width;
    };

    getRowHeight = ({ index }) => {
        return index === 0 ? this.props.headerRowHeight : this.props.rowHeight;
    };

    getTotalWidthOfColumns(columns) {
        return columns.reduce((total, col) => total + col.width, 0);
    }

    calculateGridValues = () => {
        const { data, columns, frozenColumns } = this.props;

        const defaultValues = {
            columnCount: columns.length,
            overscanColumnCount: 0,
            overscanRowCount: 5,
            rowCount: data.length - frozenColumns.bottom,
        };

        return defaultValues;
    };

    /**
     * Note: this is actually supposed to be an internal handler for the MultiGrid component
     * but it seems like a reliable way to detect scrollbar presence that is otherwise not output.
     * So possibly may break in future versions
     */
    handleScrollbarPresenceChange = (scrollbarsConfig) => {
        if (this.props.disableScrollbars) {
            this.setState({
                scrollbarsConfig: {
                    // Always forcing a horizontal scrollbar
                    horizontal: false,
                    size: 0,
                    vertical: false,
                },
            });
            return;
        }
        this.setState({
            scrollbarsConfig: {
                // Always forcing a horizontal scrollbar
                horizontal: true,
                size: scrollbarsConfig.size,
                vertical: scrollbarsConfig.vertical,
            },
        });
    };

    renderBodyCell = ({ columnIndex, key, rowIndex, style }) => {
        const { data, columns, frozenColumns } = this.props;
        const column = columns[columnIndex];
        const row = data[rowIndex];

        const containerProps = {
            className: 'PivotGrid__GridColumn__BodyGrid__Cell',
            key,
            style,
        };

        const isRightFrozenColumn =
            columnIndex === columns.length - frozenColumns.right;
        const isLeftFrozenColumn = columnIndex < frozenColumns.left;

        if (isRightFrozenColumn || isLeftFrozenColumn) {
            return <CellContainer {...containerProps} />;
        }

        return (
            <CellContainer {...containerProps}>
                {column.cell(row)}
            </CellContainer>
        );
    };

    renderHeaderCell = ({ columnIndex, key, rowIndex, style }) => {
        const { data, columns, frozenColumns } = this.props;
        if (
            columnIndex < frozenColumns.left ||
            columnIndex === columns.length - frozenColumns.right
        ) {
            return null;
        }
        const column = columns[columnIndex];
        const row = data[rowIndex];
        return (
            <CellContainer
                className="PivotGrid__GridColumn__HeaderGrid__HeaderCell"
                key={key}
                style={style}
            >
                {column.header(row)}
            </CellContainer>
        );
    };

    renderFooterCell = ({ columnIndex, key, rowIndex, style }) => {
        const { data, columns, frozenColumns } = this.props;
        if (
            columnIndex < frozenColumns.left ||
            columnIndex === columns.length - frozenColumns.right
        ) {
            return null;
        }
        const column = columns[columnIndex];
        const row = data.slice().reverse()[rowIndex];
        return (
            <CellContainer
                className="PivotGrid__GridColumn__FooterGrid__FooterCell"
                key={key}
                style={style}
            >
                {column.footer(row)}
            </CellContainer>
        );
    };

    renderLeftFooterCell = ({ columnIndex, key, style, rowIndex }) => {
        const { data, columns } = this.props;
        const column = columns[columnIndex];
        const row = data.slice().reverse()[rowIndex];
        return (
            <CellContainer
                className="PivotGrid__LeftFooterGridContainer__HeaderGrid__HeaderCell"
                key={key}
                style={style}
            >
                {column.footer(row)}
            </CellContainer>
        );
    };

    renderRightFooterCell = ({ columnIndex, key, style, rowIndex }) => {
        const { data, columns, frozenColumns } = this.props;
        const column = columns.slice(-frozenColumns.right)[columnIndex];
        const row = data.slice().reverse()[rowIndex];
        return (
            <CellContainer
                className="PivotGrid__RightFooterGridContainer__HeaderGrid__HeaderCell"
                key={key}
                style={style}
            >
                {column.footer(row)}
            </CellContainer>
        );
    };

    renderLeftHeaderCell = ({ columnIndex, key, style, rowIndex }) => {
        const { data, columns } = this.props;
        const column = columns[columnIndex];
        const row = data[rowIndex];
        return (
            <CellContainer
                className="PivotGrid__LeftHeaderGridContainer__HeaderGrid__HeaderCell"
                key={key}
                style={style}
                {...column.headerContainerProps}
            >
                {column.header(row)}
            </CellContainer>
        );
    };

    renderRightHeaderCell = ({ columnIndex, key, style, rowIndex }) => {
        const { data, columns, frozenColumns } = this.props;
        const column = columns.slice(-frozenColumns.right)[columnIndex];
        const row = data[rowIndex];
        return (
            <CellContainer
                className="PivotGrid__RightHeaderGridContainer__HeaderGrid__HeaderCell"
                key={key}
                style={style}
                {...column.headerContainerProps}
            >
                {column.header(row)}
            </CellContainer>
        );
    };

    renderLeftSideCell = ({ columnIndex, key, rowIndex, style }) => {
        const { data, columns, frozenColumns, disableScrollbars } = this.props;

        if (
            !disableScrollbars &&
            rowIndex === data.length - frozenColumns.top
        ) {
            return null;
        }

        const column = columns[columnIndex];
        const row = data[rowIndex];

        return (
            <CellContainer
                className="PivotGrid__LeftSideGridContainer__LeftSideGrid__Cell"
                key={key}
                style={style}
            >
                {column.cell(row)}
            </CellContainer>
        );
    };

    renderRightSideCell = ({ columnIndex, key, rowIndex, style }) => {
        const { data, columns, frozenColumns } = this.props;
        if (rowIndex === data.length - frozenColumns.top) {
            return null;
        }

        const column = columns.slice(-frozenColumns.right)[columnIndex];
        const row = data[rowIndex];

        return (
            <CellContainer
                className="PivotGrid__RightSideGridContainer__RightSideGrid__Cell"
                key={key}
                style={style}
            >
                {column.cell(row)}
            </CellContainer>
        );
    };

    render() {
        const {
            columnCount,
            overscanColumnCount,
            overscanRowCount,
            rowCount,
        } = this.calculateGridValues();

        const {
            data,
            columns,
            frozenColumns,
            rowHeight,
            headerRowHeight,
            height,
            renderLeftSideLabels,
            rootClassName,
            disabledGrids,
        } = this.props;

        const { scrollbarsConfig } = this.state;

        if (!data.length) return null;

        const gridWidthFromRight = frozenColumns.right
            ? this.getTotalWidthOfColumns(columns.slice(-frozenColumns.right))
            : 0;
        const gridWidthFromLeft = this.getTotalWidthOfColumns(
            columns.slice(0, frozenColumns.left),
        );
        const gridWidthBody = this.getTotalWidthOfColumns(
            columns.slice(frozenColumns.left, -frozenColumns.right),
        );

        const horizontalScrollbarSize = scrollbarsConfig.horizontal
            ? scrollbarsConfig.size
            : 0;
        const verticalScrollbarSize = scrollbarsConfig.vertical
            ? scrollbarsConfig.size
            : 0;

        return (
            <div className={rootClassName}>
                <ScrollSync>
                    {({ onScroll, scrollLeft, scrollTop }) => {
                        return (
                            <div className="PivotGrid">
                                {renderLeftSideLabels({
                                    scrollTop,
                                    bodyHeight:
                                        height - horizontalScrollbarSize,
                                })}
                                {isGridEnabled(
                                    GRID_NAMES.LEFT_HEADER_CELL,
                                    disabledGrids,
                                ) && (
                                    <div className="PivotGrid__LeftHeaderGridContainer">
                                        <Grid
                                            cellRenderer={
                                                this.renderLeftHeaderCell
                                            }
                                            className="PivotGrid__LeftHeaderGridContainer__HeaderGrid"
                                            width={gridWidthFromLeft}
                                            height={headerRowHeight}
                                            rowHeight={headerRowHeight}
                                            columnWidth={this.getColumnWidth}
                                            rowCount={frozenColumns.top}
                                            columnCount={frozenColumns.left}
                                        />
                                    </div>
                                )}
                                {isGridEnabled(
                                    GRID_NAMES.LEFT_SIDE_CELL,
                                    disabledGrids,
                                ) && (
                                    <div
                                        className="PivotGrid__LeftSideGridContainer"
                                        style={{
                                            top: headerRowHeight,
                                        }}
                                    >
                                        <Grid
                                            overscanColumnCount={
                                                overscanColumnCount
                                            }
                                            overscanRowCount={overscanRowCount}
                                            cellRenderer={
                                                this.renderLeftSideCell
                                            }
                                            columnWidth={this.getColumnWidth}
                                            columnCount={frozenColumns.left}
                                            className="PivotGrid__LeftSideGridContainer__LeftSideGrid"
                                            height={
                                                height - horizontalScrollbarSize
                                            }
                                            rowHeight={rowHeight}
                                            rowCount={rowCount}
                                            scrollTop={scrollTop}
                                            width={gridWidthFromLeft}
                                        />
                                    </div>
                                )}
                                {isGridEnabled(
                                    GRID_NAMES.LEFT_FOOTER_CELL,
                                    disabledGrids,
                                ) && (
                                    <div
                                        className="PivotGrid__LeftFooterGridContainer"
                                        style={{
                                            bottom: 0,
                                        }}
                                    >
                                        <Grid
                                            cellRenderer={
                                                this.renderLeftFooterCell
                                            }
                                            className="PivotGrid__LeftFooterGridContainer__HeaderGrid"
                                            width={gridWidthFromLeft}
                                            height={rowHeight}
                                            rowHeight={rowHeight}
                                            columnWidth={this.getColumnWidth}
                                            rowCount={frozenColumns.top}
                                            columnCount={frozenColumns.left}
                                        />
                                    </div>
                                )}
                                {isGridEnabled(
                                    GRID_NAMES.RIGHT_HEADER_CELL,
                                    disabledGrids,
                                ) && (
                                    <div
                                        className="PivotGrid__RightHeaderGridContainer"
                                        style={{
                                            right: verticalScrollbarSize,
                                        }}
                                    >
                                        <Grid
                                            cellRenderer={
                                                this.renderRightHeaderCell
                                            }
                                            className="PivotGrid__RightHeaderGridContainer__HeaderGrid"
                                            width={gridWidthFromRight}
                                            height={headerRowHeight}
                                            rowHeight={headerRowHeight}
                                            columnWidth={
                                                this.getColumnWidthInReverse
                                            }
                                            rowCount={frozenColumns.top}
                                            columnCount={frozenColumns.right}
                                        />
                                    </div>
                                )}
                                {isGridEnabled(
                                    GRID_NAMES.RIGHT_SIDE_CELL,
                                    disabledGrids,
                                ) && (
                                    <div
                                        className="PivotGrid__RightSideGridContainer"
                                        style={{
                                            top: headerRowHeight,
                                            right: verticalScrollbarSize,
                                        }}
                                    >
                                        <Grid
                                            overscanColumnCount={
                                                overscanColumnCount
                                            }
                                            overscanRowCount={overscanRowCount}
                                            cellRenderer={
                                                this.renderRightSideCell
                                            }
                                            columnWidth={
                                                this.getColumnWidthInReverse
                                            }
                                            columnCount={frozenColumns.right}
                                            className="PivotGrid__RightSideGridContainer__RightSideGrid"
                                            height={height - scrollbarSize()}
                                            rowHeight={rowHeight}
                                            rowCount={rowCount}
                                            scrollTop={scrollTop}
                                            width={gridWidthFromRight}
                                        />
                                    </div>
                                )}
                                {isGridEnabled(
                                    GRID_NAMES.RIGHT_FOOTER_CELL,
                                    disabledGrids,
                                ) && (
                                    <div
                                        className="PivotGrid__RightFooterGridContainer"
                                        style={{
                                            bottom: 0,
                                            right: verticalScrollbarSize,
                                        }}
                                    >
                                        <Grid
                                            ref={(el) =>
                                                (this.pivotGridRightFooterGridContainerHeaderGrid = el)
                                            }
                                            cellRenderer={
                                                this.renderRightFooterCell
                                            }
                                            className="PivotGrid__RightFooterGridContainer__HeaderGrid"
                                            width={gridWidthFromRight}
                                            height={rowHeight}
                                            rowHeight={rowHeight}
                                            columnWidth={
                                                this.getColumnWidthInReverse
                                            }
                                            rowCount={frozenColumns.bottom}
                                            columnCount={frozenColumns.right}
                                        />
                                    </div>
                                )}
                                <div className="PivotGrid__GridColumn">
                                    <AutoSizer disableHeight={true}>
                                        {({ width }) => {
                                            const minNumRowToFillHeight = Math.floor(
                                                height / rowHeight,
                                            );
                                            const bodyColsLength =
                                                columns.length -
                                                frozenColumns.left -
                                                frozenColumns.right;
                                            const avgColWidth =
                                                gridWidthBody / bodyColsLength;
                                            const maxGridWidth =
                                                width -
                                                gridWidthFromLeft -
                                                gridWidthFromRight;
                                            const minNumColsToFillWidth =
                                                Math.floor(
                                                    maxGridWidth / avgColWidth,
                                                ) + 1;

                                            const bodyRowCount =
                                                minNumRowToFillHeight > rowCount
                                                    ? minNumRowToFillHeight
                                                    : rowCount;
                                            const bodyColCount =
                                                minNumColsToFillWidth >
                                                bodyColsLength
                                                    ? minNumColsToFillWidth
                                                    : bodyColsLength;

                                            const containerWidth = Math.max(
                                                this.getTotalWidthOfColumns(
                                                    columns,
                                                ),
                                                width - verticalScrollbarSize,
                                            );
                                            const containerHeight = Math.max(
                                                bodyRowCount * rowHeight,
                                                height -
                                                    horizontalScrollbarSize,
                                            );

                                            return (
                                                <div>
                                                    <div
                                                        style={{
                                                            height: headerRowHeight,
                                                            width:
                                                                width -
                                                                verticalScrollbarSize,
                                                        }}
                                                    >
                                                        <Grid
                                                            className="PivotGrid__GridColumn__HeaderGrid"
                                                            columnWidth={
                                                                this
                                                                    .getColumnWidth
                                                            }
                                                            columnCount={
                                                                columnCount
                                                            }
                                                            height={
                                                                headerRowHeight
                                                            }
                                                            overscanColumnCount={
                                                                overscanColumnCount
                                                            }
                                                            cellRenderer={
                                                                this
                                                                    .renderHeaderCell
                                                            }
                                                            rowHeight={
                                                                this
                                                                    .getRowHeight
                                                            }
                                                            rowCount={
                                                                frozenColumns.top
                                                            }
                                                            scrollLeft={
                                                                scrollLeft
                                                            }
                                                            width={
                                                                width -
                                                                verticalScrollbarSize
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            height,
                                                            width,
                                                            position:
                                                                'relative',
                                                        }}
                                                    >
                                                        <Grid
                                                            className="PivotGrid__GridColumn__BodyGrid"
                                                            containerStyle={{
                                                                maxWidth: containerWidth,
                                                                width: containerWidth,
                                                                maxHeight: containerHeight,
                                                                height: containerHeight,
                                                            }}
                                                            cellRangeRenderer={cellRangeRenderer(
                                                                {
                                                                    width,
                                                                    height,
                                                                    gridWidthFromLeft,
                                                                    avgColWidth,
                                                                    bodyColCount,
                                                                    bodyRowCount,
                                                                    rowHeight,
                                                                    maxGridWidth,
                                                                    gridWidthFromRight,
                                                                },
                                                            )}
                                                            columnWidth={
                                                                this
                                                                    .getColumnWidth
                                                            }
                                                            columnCount={
                                                                columnCount
                                                            }
                                                            scrollToColumn={
                                                                columnCount - 1
                                                            }
                                                            height={height}
                                                            onScroll={onScroll}
                                                            overscanColumnCount={
                                                                overscanColumnCount
                                                            }
                                                            overscanRowCount={
                                                                overscanRowCount
                                                            }
                                                            cellRenderer={
                                                                this
                                                                    .renderBodyCell
                                                            }
                                                            rowHeight={
                                                                rowHeight
                                                            }
                                                            rowCount={rowCount}
                                                            width={width}
                                                            onScrollbarPresenceChange={
                                                                this
                                                                    .handleScrollbarPresenceChange
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    </AutoSizer>
                                    {isGridEnabled(
                                        GRID_NAMES.GRID_FOOTER,
                                        disabledGrids,
                                    ) && (
                                        <div className="PivotGrid__GridColumn">
                                            <AutoSizer disableHeight={true}>
                                                {({ width }) => (
                                                    <div>
                                                        <div
                                                            style={{
                                                                height: rowHeight,
                                                                width:
                                                                    width -
                                                                    verticalScrollbarSize,
                                                            }}
                                                        >
                                                            <Grid
                                                                className="PivotGrid__GridColumn__FooterGrid"
                                                                columnWidth={
                                                                    this
                                                                        .getColumnWidth
                                                                }
                                                                columnCount={
                                                                    columnCount
                                                                }
                                                                height={
                                                                    rowHeight
                                                                }
                                                                overscanColumnCount={
                                                                    overscanColumnCount
                                                                }
                                                                cellRenderer={
                                                                    this
                                                                        .renderFooterCell
                                                                }
                                                                rowHeight={
                                                                    rowHeight
                                                                }
                                                                rowCount={
                                                                    frozenColumns.bottom
                                                                }
                                                                scrollLeft={
                                                                    scrollLeft
                                                                }
                                                                width={
                                                                    width -
                                                                    verticalScrollbarSize
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </AutoSizer>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    }}
                </ScrollSync>
            </div>
        );
    }
}

export default PivotGrid;
