import React, { memo } from 'react';
import PropTypes from 'prop-types';
import arePropsEqual from '@rs/core/falcon/utils/arePropsEqual';

const ProductivityEventTable = ({
    rows,
    cols,
    onTableRowHover,
    tableClassName,
}) => {
    if (
        !Array.isArray(rows) ||
        !rows.length ||
        !Array.isArray(cols) ||
        !cols.length
    )
        return null;
    const onMouseHover = onTableRowHover || (() => null);
    return (
        <table
            className={`Table--Default ${
                tableClassName || 'ProductivityEventTable'
            }`}
        >
            <thead>
                <tr>
                    {cols.map((col) => (
                        <th
                            key={col.header}
                            className={col.classNames.header || ''}
                        >
                            {col.header}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows.map((row, rowIndex) => (
                    <tr
                        key={rowIndex}
                        onMouseEnter={() => onMouseHover(rowIndex, row)}
                        onMouseLeave={() => onMouseHover(null)}
                    >
                        {cols.map((col, colIndex) => (
                            <td
                                key={colIndex}
                                className={col.classNames.cell || ''}
                            >
                                {col.renderCell(row, rowIndex)}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

ProductivityEventTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.shape({})),
    cols: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string.isRequired,
            renderCell: PropTypes.func.isRequired,
            classNames: PropTypes.shape({
                cell: PropTypes.string,
                header: PropTypes.string,
            }),
        }),
    ),
    tableClassName: PropTypes.string,
    onTableRowHover: PropTypes.func,
};

export default memo(ProductivityEventTable, arePropsEqual);
