import * as React from 'react';
import FleetPlan from './Components/FleetPlanIframe';
import { useSelector } from 'react-redux';
import { getRouterState } from '../Modules/router/selectors';
const { Layout } = require('@rs/core/falcon');

const { SubHeader, Content } = Layout;

const SicFleetPlanPage = () => {
    const routerState = useSelector(getRouterState);

    const allQueryParams = routerState.location.query;
    return (
        <>
            <SubHeader>
                <SubHeader.Left>
                    <SubHeader.Title>Fleet Targets</SubHeader.Title>
                </SubHeader.Left>
            </SubHeader>
            <Content style={{ display: 'flex', padding: '0px 0px 0px 0px' }}>
                <FleetPlan queryParams={allQueryParams} />
            </Content>
        </>
    );
};

export default SicFleetPlanPage;
