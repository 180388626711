import shiftUtils from '@rs/core/utils/shiftUtils';
import { createReducer } from '../../Lib/reduxUtils';
import { FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS } from '../../App/Actions';
import { FILTER_UPDATED, SET_FILTERS_WITH_URL_PARAMS } from '../Actions';
import {
    setEndDateAfterDateSelected,
    setEndDateAfterRangeSelected,
} from '../../Utils/Filters/filters';

export const initialState = {
    Date: undefined,
    EndDate: undefined,
    Shift: '',
    TruckType: '',
    TruckId: '',
    rangeSelected: false,
};

const fetchPrivateSiteConfigurationSuccess = (state) => {
    const defaultDate = shiftUtils.newDefaultFilterDate();
    return {
        ...state,
        Date: defaultDate,
    };
};

export function filterUpdated(state, payload) {
    const { filterName, filterValue } = payload;
    const newState = {
        ...state,
        [filterName]:
            filterName === 'rangeSelected' ? filterValue : filterValue || '',
    };
    setEndDateAfterDateSelected(newState, filterName);
    setEndDateAfterRangeSelected(newState, filterName);
    return newState;
}

export function setFiltersWithUrlParams(state, filtersFromUrl) {
    return {
        ...state,
        ...filtersFromUrl,
    };
}

export default createReducer(initialState, {
    [FILTER_UPDATED]: filterUpdated,
    [SET_FILTERS_WITH_URL_PARAMS]: setFiltersWithUrlParams,
    [FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS]: fetchPrivateSiteConfigurationSuccess,
});
