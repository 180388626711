import { createReducer } from '../../Lib/reduxUtils';
import { LOAD_RESULTS_SUCCESS } from '../Actions';
import namespace from './namespace';

function createSelectors(statePath) {
    function get(state) {
        return statePath(state);
    }

    function getDisplayMessage(state) {
        const slice = statePath(state);
        return slice.displayMessage;
    }

    return {
        get,
        getDisplayMessage,
    };
}

const DISPLAY_MESSAGE__NO_RESULTS = 'There are no results for this query.';

const initialState = {
    displayMessage: '',
};

function loadResultsSuccess(state, { wktData }) {
    const displayMessage =
        !wktData ||
        !Array.isArray(wktData.WKTLocuses) ||
        !wktData.WKTLocuses.length
            ? DISPLAY_MESSAGE__NO_RESULTS
            : '';

    return {
        ...state,
        displayMessage,
    };
}

export const reducer = createReducer(initialState, {
    [LOAD_RESULTS_SUCCESS]: loadResultsSuccess,
});

const statePathSelector = (state) => state[namespace].ui;
export const selectors = createSelectors(statePathSelector);
