// NOTE: the values of the consts here should be same the keys of redux initial state
export const MAP_LAYER_LABELS = {
    background: 'Satellite',
    backgroundClientProvided: 'Aerial',
    roadPath: 'Road path',
    roadLabels: 'Road labels',
    hrcUnloaded: 'HRC - unloaded',
    hrcLoaded: 'HRC - loaded',
    hrcEventsLow: 'HRC events - low',
    hrcEventsMed: 'HRC events - med',
    hrcEventsHigh: 'HRC events - high',
    rampEvents: 'Ramp events',
    focusAreaLabels: 'Focus area labels',
    cornerEvents: 'Corner events',
    speedingInZone: 'Speeding Events',
    eventPaths: 'Event paths',
    truckMarker: 'Truck marker',
    truckAlertEventsLow: 'Truck Alert Events - low',
    truckAlertEventsMed: 'Truck Alert Events - med',
    truckAlertEventsHigh: 'Truck Alert Events - high',
    equipmentTrails: 'Equipment Trails',
    equipment: 'Equipment',
    holdingStates: 'Holding States',
    sources: 'Sources',
    destination: 'Destinations',
    trackingRegions: 'Mine Features',
    materialMovement: 'Material Movement',
    mineRegions: 'Mine Regions',
    speedLimitedZones: 'Speed Restricted zones',
    mapBox: 'MapBox',
    geofenceLabels: 'Geofence Labels',
};

export const ZINDEX = {
    MAP_PANE: 400,
};
