import { createReducer } from '../../Lib/reduxUtils';
import { canAccessFeature } from '../Selectors/features';
import {
    LOGIN_REQUESTED,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    SET_REFERRER,
    SET_USER_EMAIL,
    SET_SPINNER_STATE,
    AUTH_USERNAME_SUBMITTED,
    AUTH_PASSWORD_SUBMITTED,
    AUTH_LOGIN_SUBMITTED,
    FORM_STEP_FORWARD,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_SUCCESS,
    FORM_STEP_BACKWARD,
    TOKEN_UPDATED_SUCCESS,
    CLEAR_API_ERROR_MESSAGE,
    SET_AUTHENTICTION_METHOD,
} from '../Actions';
import * as roleIds from '../roleIds';
import { FEATURE_IDS } from '../featureIds';
import { FEATURE_META } from '../featureMeta';
import { getComponentConfig } from '../../App/Selectors';

const loginFormInitialState = {
    userId: '',
    password: '',
    step: 0,
};

export const initialState = {
    apiErrorMessage: '',
    successMessage: '',
    spinner: {
        isActive: false,
        message: '',
        faIcon: undefined,
    },
    decodedToken: {
        central_row_version: null,
        driver_id: null,
        email: null,
        exp: null,
        iss: null,
        mine_site_id: null,
        role_ids: [],
        site_details: {},
        user_id: null,
        features: [],
        referrer: '',
    },
    token: null,
    refreshToken: null,
    loginForm: loginFormInitialState,
    authenticationMethod: null,
};

// //////////////////////////////////////////////////////////////////////
//  SELECTORS
// //////////////////////////////////////////////////////////////////////

const getApiErrorMessage = (state) => state.apiErrorMessage;
const getPasswordResetSuccessMessage = (state) => state.successMessage;
const getSpinnerState = (state) => state.spinner;
const getEmailAddress = (state) => state.decodedToken.email;
const getFullName = (state) => {
    const { FirstName, LastName } = state.decodedToken.site_details;
    return FirstName ? `${FirstName} ${LastName}` : '';
};
const getUniqueDriverId = (state) =>
    state.decodedToken.driver_id ? state.decodedToken.driver_id : null;
const getUserRoles = (state) => state.decodedToken.role_ids;
const hasRole = (state, roleName) => {
    const userRoles = getUserRoles(state);
    if (userRoles === null) return false;
    return userRoles.indexOf(roleName) > -1;
};

const getUsersHomePage = (state, rootState) => {
    // When the home page has been disabled, then try to provide "alternate" home pages in this order:
    const alternateHomePageOrderFeatureIdOrder = [
        FEATURE_IDS.TAB_ID__PRODUCTION_DASHBOARD,
        FEATURE_IDS.TAB_ID__LEAGUE_TABLE,
        FEATURE_IDS.TAB_ID__OPERATOR_FEEDBACK,
        FEATURE_IDS.TAB_ID__TRUCK_PERFORMANCE,
        FEATURE_IDS.TAB_ID__LOAD_UNIT_OPERATOR_LEAGUE_TABLE,
        FEATURE_IDS.TAB_ID__OPERATOR_FEEDBACK,
        FEATURE_IDS.TAB_ID__HAUL_ROAD_CONDITION,
        FEATURE_IDS.TAB_ID__TRUCK_ALLOCATION,
    ];

    // These are ordered by the priority, eg. ROLE__ADMINSTRATOR path should trump ROLE__OPERATOR_LOADER path
    const roleToHomepageMap = {
        [roleIds.ROLE__ADMINSTRATOR]:
            FEATURE_META[FEATURE_IDS.TAB_ID__PRODUCTION_DASHBOARD].path,
        [roleIds.ROLE__DEV_VIEWER]:
            FEATURE_META[FEATURE_IDS.TAB_ID__PRODUCTION_DASHBOARD].path,
        [roleIds.ROLE__FULL_ACCESS]:
            FEATURE_META[FEATURE_IDS.TAB_ID__PRODUCTION_DASHBOARD].path,
        [roleIds.ROLE__TELFER__MANAGER]:
            FEATURE_META[FEATURE_IDS.TAB_ID__PRODUCTION_DASHBOARD].path,
        [roleIds.ROLE__MANAGER]:
            FEATURE_META[FEATURE_IDS.TAB_ID__PRODUCTION_DASHBOARD].path,
        [roleIds.ROLE__TELFER__SITE_CHAMPION]:
            FEATURE_META[FEATURE_IDS.TAB_ID__PRODUCTION_DASHBOARD].path,
        [roleIds.ROLE__PRODUCTION]:
            FEATURE_META[FEATURE_IDS.TAB_ID__PRODUCTION_DASHBOARD].path,
        [roleIds.ROLE__TELFER__OPERATOR_TRUCK]:
            FEATURE_META[FEATURE_IDS.TAB_ID__LEAGUE_TABLE].path,
        [roleIds.ROLE__OPERATOR_TRUCK]:
            FEATURE_META[FEATURE_IDS.TAB_ID__OPERATOR_FEEDBACK].path,
        [roleIds.ROLE__MAINTENANCE]:
            FEATURE_META[FEATURE_IDS.TAB_ID__TRUCK_PERFORMANCE].path,
        [roleIds.ROLE__OPERATOR_LOADER]:
            FEATURE_META[FEATURE_IDS.TAB_ID__LOAD_UNIT_OPERATOR_LEAGUE_TABLE]
                .path,
        [roleIds.ROLE__OPERATOR]:
            FEATURE_META[FEATURE_IDS.TAB_ID__OPERATOR_FEEDBACK].path,
    };

    // User has no roles setup, maybe a AWS Cognito user
    const userRoles = getUserRoles(state) || [];

    if (!userRoles.length) {
        const alternateHomeFeatureId = alternateHomePageOrderFeatureIdOrder.find(
            (featureId) => canAccessFeature(state, featureId),
        );
        if (alternateHomeFeatureId) {
            // Hacky work around for users dispatch operator homepage
            if (
                alternateHomeFeatureId === FEATURE_IDS.TAB_ID__TRUCK_ALLOCATION
            ) {
                const path =
                    FEATURE_META[FEATURE_IDS.TAB_ID__TRUCK_ALLOCATION].path;
                const truckAllocation = getComponentConfig(
                    rootState,
                    'TruckAllocation',
                );
                const firstWomid = truckAllocation.regions[0].WhereOnMinesiteId;
                return path.replace(':womid', firstWomid);
            }

            return FEATURE_META[alternateHomeFeatureId].path;
        }
        return FEATURE_META[FEATURE_IDS.TAB_ID__APP_LANDING].path;
    }

    const matchingRole = Object.keys(roleToHomepageMap).find((role) =>
        hasRole(state, role),
    );

    if (matchingRole) {
        return roleToHomepageMap[matchingRole];
    } else {
        const alternateHomeFeatureId = alternateHomePageOrderFeatureIdOrder.find(
            (featureId) => canAccessFeature(state, featureId),
        );

        if (alternateHomeFeatureId) {
            return FEATURE_META[alternateHomeFeatureId].path;
        }
        return FEATURE_META[FEATURE_IDS.TAB_ID__APP_LANDING].path;
    }
};

const getLoginForm = (state) => state.loginForm;

export const sliceSelectors = {
    getApiErrorMessage,
    getPasswordResetSuccessMessage,
    getSpinnerState,
    getEmailAddress,
    getFullName,
    getUserRoles,
    hasRole,
    getUsersHomePage,
    getUniqueDriverId,
    getLoginForm,
};

// //////////////////////////////////////////////////////////////////////
//  REDUCERS
// //////////////////////////////////////////////////////////////////////

export const loginRequested = (state) => ({
    ...state,
    apiErrorMessage: '',
    spinner: {
        isActive: true,
        message: '',
        faIcon: undefined,
    },
});

export const setUserEmail = (state, email) => ({
    ...state,
    loginForm: {
        userId: email,
    },
    successMessage: '',
});

export const setSpinnerState = (state, { isActive, message, faIcon }) => ({
    ...state,
    spinner: {
        isActive,
        message,
        faIcon,
    },
});

export const clearApiErrorMessage = (state) => ({
    ...state,
    apiErrorMessage: '',
});

export const loginSuccess = (state, { token, refreshToken, decodedToken }) => ({
    ...state,
    token,
    refreshToken,
    spinner: {
        isActive: false,
        message: '',
        faIcon: undefined,
    },
    decodedToken,
    loginForm: loginFormInitialState,
});

export const tokenUpdatedSuccess = (state, { token }) => ({
    ...state,
    token,
});

export const resetPasswordError = (state, apiErrorMessage) => ({
    ...state,
    successMessage: '',
    apiErrorMessage,
});

export const resetPasswordSuccess = (state, successMessage) => ({
    ...state,
    successMessage,
    apiErrorMessage: '',
});

export const loginError = (state, apiErrorMessage) => ({
    ...state,
    loginForm: {
        ...state.loginForm,
        step: 1,
    },
    apiErrorMessage,
    spinner: {
        isActive: false,
        message: '',
        faIcon: undefined,
    },
});

export const logout = () => ({
    ...initialState,
});

export const reducer = createReducer(initialState, {
    [LOGIN_REQUESTED]: loginRequested,
    [LOGIN_SUCCESS]: loginSuccess,
    [TOKEN_UPDATED_SUCCESS]: tokenUpdatedSuccess,
    [LOGIN_ERROR]: loginError,
    [LOGOUT]: logout,
    [SET_SPINNER_STATE]: setSpinnerState,
    [RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
    [RESET_PASSWORD_ERROR]: resetPasswordError,
    [SET_USER_EMAIL]: setUserEmail,
    [CLEAR_API_ERROR_MESSAGE]: clearApiErrorMessage,
    [AUTH_USERNAME_SUBMITTED]: (state, userId) => ({
        ...state,
        loginForm: {
            ...state.loginForm,
            userId,
        },
    }),
    [AUTH_PASSWORD_SUBMITTED]: (state, password) => ({
        ...state,
        loginForm: {
            ...state.loginForm,
            password,
        },
    }),
    [AUTH_LOGIN_SUBMITTED]: (state, loginForm) => {
        const { userId, password } = loginForm;
        return {
            ...state,
            loginForm: {
                ...state.loginForm,
                userId,
                password,
            },
        };
    },
    [FORM_STEP_FORWARD]: (state) => ({
        ...state,
        loginForm: {
            ...state.loginForm,
            step: 1,
        },
    }),

    [FORM_STEP_BACKWARD]: (state) => ({
        ...state,
        loginForm: {
            ...state.loginForm,
            step: 0,
        },
    }),
    [SET_REFERRER]: (state, referrer) => ({
        ...state,
        referrer,
    }),
    [SET_AUTHENTICTION_METHOD]: (state, method) => ({
        ...state,
        authenticationMethod: method,
    }),
});
