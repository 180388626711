// Taken from this repo: for some reason it crashes everything when importing this library so I've taken the method instead
// https://github.com/makinacorpus/Leaflet.GeometryUtil/blob/master/src/leaflet.geometryutil.js#L713
import L from 'leaflet';

/**
 Returns the point that is a distance and heading away from
 the given origin point.
 @param {L.LatLng} latlng: origin point
 @param {float} heading: heading in degrees, clockwise from 0 degrees north.
 @param {float} distance: distance in meters
 @returns {L.latLng} the destination point.
 Many thanks to Chris Veness at http://www.movable-type.co.uk/scripts/latlong.html
 for a great reference and examples.
 */
export function calculateDestination(latlng, inputHeading, distance) {
    /* eslint-disable operator-assignment */
    /* eslint-disable no-nested-ternary */
    const heading = (inputHeading + 360) % 360;
    const rad = Math.PI / 180;
    const radInv = 180 / Math.PI;
    const R = 6378137; // approximation of Earth's radius
    const lon1 = latlng.lng * rad;
    const lat1 = latlng.lat * rad;
    const rheading = heading * rad;
    const sinLat1 = Math.sin(lat1);
    const cosLat1 = Math.cos(lat1);
    const cosDistR = Math.cos(distance / R);
    const sinDistR = Math.sin(distance / R);
    const lat2 = Math.asin(
        sinLat1 * cosDistR + cosLat1 * sinDistR * Math.cos(rheading),
    );

    let lon2 =
        lon1 +
        Math.atan2(
            Math.sin(rheading) * sinDistR * cosLat1,
            cosDistR - sinLat1 * Math.sin(lat2),
        );
    lon2 = lon2 * radInv;
    lon2 = lon2 > 180 ? lon2 - 360 : lon2 < -180 ? lon2 + 360 : lon2;
    return L.latLng([lat2 * radInv, lon2]);
}
