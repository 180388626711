import { combineReducers } from 'redux';
import { reducer as wiki } from '../Modules/wiki';
import { reducer as status } from '../Modules/status';
import namespace from '../Modules/namespace';

export const REDUCER_KEY = namespace;
export default combineReducers({
    wiki,
    status,
});
