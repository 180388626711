import { action } from '../../Lib/reduxUtils';
import { GoogleAnalyticsEventBuilder } from '../../Utils/userAnalytics/googleAnalyticsEventBuilder';

const NAMESPACE = 'TyresAndSafety';

export const LOAD_RESULTS_SUCCESS = `${NAMESPACE}/LOAD_RESULTS_SUCCESS`;
export const loadResultsSuccess = ({
    mapEvents,
    topClusters,
    wktData,
    mapLabels,
    speedLimitedZones,
    conformance,
    speedingEvents,
    rampEvents,
    cornerLeague,
}) =>
    action(LOAD_RESULTS_SUCCESS, {
        mapEvents,
        topClusters,
        wktData,
        mapLabels,
        speedLimitedZones,
        conformance,
        speedingEvents,
        rampEvents,
        cornerLeague,
    });

export const PRINT_PDF = `${NAMESPACE}/PRINT_PDF`;
export const printPDF = (ComponentsToRender, MapFilters) =>
    action(
        PRINT_PDF,
        { ComponentsToRender, MapFilters },
        {
            analytics: new GoogleAnalyticsEventBuilder(
                'SubFeatureUsage',
                'PrintPDF - Page',
            )
                .setLabel(NAMESPACE)
                .build(),
        },
    );

export const PRINT_PDF_MAP = `${NAMESPACE}/PRINT_PDF_MAP`;
export const printPDFMap = ({ endpoint, params }) =>
    action(
        PRINT_PDF_MAP,
        { endpoint, params },
        {
            analytics: new GoogleAnalyticsEventBuilder(
                'SubFeatureUsage',
                'PrintPDF - Map',
            )
                .setLabel(NAMESPACE)
                .build(),
        },
    );

export const SYNC_URL_TO_STATE = `${NAMESPACE}/SYNC_URL_TO_STATE`;
export const syncURLToState = (params) => action(SYNC_URL_TO_STATE, params);

export const MAP_LEGEND_LAYER_FLIP = `${NAMESPACE}/MAP_LEGEND_LAYER_FLIP`;
export const mapLegendLayerFlip = (section, checked) => {
    return action(MAP_LEGEND_LAYER_FLIP, {
        sectionLabel: section,
        visibility: checked,
    });
};
