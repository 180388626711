import L from 'leaflet';

/**
 * Animates a marker position from a source to a destination using requestAnimationFrame
 * @param leafletElement
 * @param {object} coordFrom The source, L.LatLng instance
 * @param {object} coordTo - The destination, L.LatLng instance
 * @param {number} progressIncrement - How far should it progress at each requestAnimationFrame. eg. 0.0015 takes roughly 10seconds
 */
export function animateMarker(
    leafletElement,
    coordFrom,
    coordTo,
    progressIncrement = 0.0015,
) {
    const curLat = coordFrom.lat;
    const curLng = coordFrom.lng;
    const newLat = coordTo.lat;
    const newLng = coordTo.lng;
    let position;
    let slideFrame;
    let threshold = 0.0;

    const updatePosition = () => {
        if (threshold >= 1) {
            L.Util.cancelAnimFrame(slideFrame);
            return null;
        }
        position = new L.LatLng(
            curLat + (newLat - curLat) * threshold,
            curLng + (newLng - curLng) * threshold,
        );
        leafletElement.setLatLng(position);
        threshold += progressIncrement;
        slideFrame = L.Util.requestAnimFrame(updatePosition, this);
        return null;
    };
    updatePosition();
}
