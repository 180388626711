import React, { memo } from 'react';
import { extent } from 'd3-array';
import {
    LayerGroup,
    LayersControl,
    LayersControlProps,
    Marker,
    Pane,
    Tooltip,
} from 'react-leaflet';
import { ZINDEX } from './constants';
import UpdatingGeoJSON from './UpdatingGeoJSON';
import { LatLngExpression, PointExpression } from 'leaflet';
import arePropsEqual from '../../utils/arePropsEqual';
import center from '@turf/center';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import pointOnFeature from '@turf/point-on-feature';

type PolygonsProps = {
    data: Data[];
    borderColor: string;
    fillColor: string;
    checked: boolean;
    name: string;
    priority: number;
    injectedLeafletProps: LayersControlProps;
    fillOpacity?: number;
    showLabel: boolean;
};
type LabelProps = {
    label: string;
    position: LatLngExpression;
};
type GetFeatureCenterProps = GeometryType;

type Data = {
    type: string;
    geometry: GeometryType;
    properties: Properties;
};

type GeometryType = GeoJSON.Polygon;

type Properties = {
    category: string;
    firstshift: number;
    iteration: number;
    lastshift: number;
    name: string;
    Color: string;
};

const Polygons = ({
    data,
    borderColor,
    fillColor,
    fillOpacity,
    checked,
    name,
    priority,
    showLabel = true,
    ...injectedLeafletProps
}: PolygonsProps): JSX.Element => {
    // This is offsetting the marker size so the label is where the marker would be pointing
    const tooltipOffset: PointExpression = [0, 41];

    const Label = ({ position, label }: LabelProps) => (
        <Marker opacity={0} position={position}>
            <Tooltip
                direction="top"
                opacity={1}
                offset={tooltipOffset}
                permanent={true}
                className="RoadLabel"
                interactive={false}
            >
                <span>{label}</span>
            </Tooltip>
        </Marker>
    );

    const findTheLabelCoordinates = (
        feature: GetFeatureCenterProps,
    ): LatLngExpression => {
        const centerOfThePolygon = center(feature).geometry.coordinates;
        if (!booleanPointInPolygon(centerOfThePolygon, feature)) {
            // if the center coordinates are now fall into the polygon
            // find a random point that *guaranteed* to be on the surface of the feature.

            // returns [longitude, latitude]
            const point = pointOnFeature(feature).geometry.coordinates;
            return [point[1], point[0]];
        }
        // returns [longitude, latitude]
        return [centerOfThePolygon[1], centerOfThePolygon[0]];
    };

    const zIndexFill: number = ZINDEX.MAP_PANE + priority;
    const zIndexBorder: number = ZINDEX.MAP_PANE + priority + 1;

    const basePaneId: string = name;

    if (!data || !Array.isArray(data) || !data.length) {
        return null!;
    }

    return (
        <LayersControl.Overlay
            {...injectedLeafletProps}
            checked={checked}
            name={name}
        >
            <LayerGroup>
                <Pane
                    name={`${basePaneId}-fill`}
                    style={{ zIndex: zIndexFill }}
                >
                    {data.map((row, i) => {
                        return (
                            <React.Fragment
                                key={`${i}_${fillOpacity}_${fillColor}`}
                            >
                                {showLabel && row && row.properties.name && (
                                    <Label
                                        position={findTheLabelCoordinates(
                                            row.geometry,
                                        )}
                                        label={row.properties.name}
                                    />
                                )}
                                <UpdatingGeoJSON
                                    data={row.geometry}
                                    style={{
                                        fillOpacity: fillOpacity,
                                        fillColor:
                                            row.properties.Color != undefined
                                                ? row.properties.Color
                                                : fillColor,
                                    }}
                                />
                            </React.Fragment>
                        );
                    })}
                </Pane>
                <Pane
                    name={`${basePaneId}-border`}
                    style={{ zIndex: zIndexBorder }}
                >
                    {data.map((row, i) => {
                        return (
                            <React.Fragment
                                key={`${i}_${fillOpacity}_${fillColor}`}
                            >
                                <UpdatingGeoJSON
                                    data={row.geometry}
                                    style={{
                                        fillOpacity: 0,
                                        color:
                                            row.properties.Color != undefined
                                                ? row.properties.Color
                                                : borderColor,
                                    }}
                                />
                            </React.Fragment>
                        );
                    })}
                </Pane>
            </LayerGroup>
        </LayersControl.Overlay>
    );
};

export default memo(Polygons, arePropsEqual);
