import move from 'lodash-move';
import { createReducer, action } from '../../Lib/reduxUtils';
import namespace from './namespace';
import * as mineSnapshotModule from './mineSnapshot';
import * as pageActions from '../Actions';

function createActions(ns) {
    const CIRCUITS_ROW_MOVED = `${ns}/CIRCUITS_ROW_MOVED`;
    const circuitsRowMoved = (sourceCircuitId, destCircuitId) =>
        action(CIRCUITS_ROW_MOVED, { sourceCircuitId, destCircuitId });

    return {
        CIRCUITS_ROW_MOVED,
        circuitsRowMoved,
    };
}

function createSelectors(statePath) {
    function get(state) {
        return statePath(state);
    }

    return {
        get,
    };
}

export const actions = createActions(namespace);
export const selectors = createSelectors(
    (state) => state[namespace].circuitsOrder,
);

export const initialState = [];
export const onCircuitsRowMoved = (
    state,
    { sourceCircuitId, destCircuitId },
) => {
    const findIndex = (id) => state.findIndex((cid) => cid === id);

    const sourceIndex = findIndex(sourceCircuitId);
    const destIndex = findIndex(destCircuitId);

    return move(state, sourceIndex, destIndex);
};

export const onMineSnapshotUpdated = (state, { minesite }) => {
    const allIds = Object.keys(minesite.circuits);
    // Add any new ids that may have been created
    const newIds = allIds.filter((id) => state.indexOf(id) === -1);
    // Remove any old ids that may be deleted
    const oldIds = state.filter((id) => allIds.indexOf(id) > -1);
    // Preserve the existing order of circuits
    return [...oldIds, ...newIds];
};

export const initialiseCircuitOrder = (state, { circuitsOrder }) => {
    // If it hasn't been initialised
    if (state.length === 0) {
        return circuitsOrder || state;
    }
    // If there's no sessionStorage values
    return state;
};

export const reducer = createReducer(initialState, {
    [actions.CIRCUITS_ROW_MOVED]: onCircuitsRowMoved,
    [pageActions.INITIALISE_CIRCUIT_ORDER]: initialiseCircuitOrder,
    [mineSnapshotModule.actions.FETCH_MINESITE_SUCCESS]: onMineSnapshotUpdated,
});
