import PropTypes from 'prop-types';
import React, { Component } from 'react';
import identity from 'lodash/identity';
import merge from 'lodash/merge';
import FalconChart from '../FalconChart/FalconChart';

class PercentageAreaChart extends Component {
    constructor(props) {
        super(props);
        this.select = this.select.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        // We don't re render the graph when we make a new selection,
        // instead we call the HighCharts API to update the selection
        const { selectedValue } = this.props;
        if (selectedValue !== nextProps.selectedValue) {
            this.select(this.chart, nextProps.selectedValue);
            return false;
        }
        return true;
    }

    // Uses the HighCharts API to make a new selection
    select(chart, xValue) {
        if (xValue) {
            let isFirstPoint = true;
            // Go through each y axis series...
            chart.series.forEach((series) => {
                // Find the point with x = xValue
                const point = series.data.find((p) => p.x === xValue);

                // Leave if we can't find a point
                if (!point) {
                    // TODO: proper error handling, display something to user maybe
                    console.error("Couldn't find the point to select");
                    return;
                }

                // On the first point we deselect all previous points
                point.select(true, !isFirstPoint);
                isFirstPoint = false;
            });
        } else {
            // Deselect all points if the new selected value is null
            chart.getSelectedPoints().forEach((point) => point.select(false));
        }
    }

    // We send the clicked x value to the parent (which then should update props.selectedValue)
    handleClick() {
        const { onClick } = this.props;
        if (onClick && this.chart && this.chart.hoverPoints) {
            // Retrieve the x value of the clicked points.
            // Assumes that the points all have the same x value
            const clicked = this.chart.hoverPoints[0].x;
            onClick(clicked);
        }
    }

    render() {
        const {
            data,
            dataFormatter,
            options,
            selectedValue,
            ...props
        } = this.props;

        // Start building our percentage chart options...
        const percentageAreaOptions = {
            chart: {
                events: {
                    // Select the currently hovered points on chart background click
                    click: (event) => this.handleClick(event),
                },
            },
            xAxis: {
                crosshair: {
                    className: 'PercentageAreaChart-crosshair',
                    // Gives us a nice vertical line as we hover
                    enabled: true,
                },
            },
            tooltip: {
                // Tooltip format for the series points
                pointFormat:
                    '<span>{series.name}</span>: <b>{point.percentage:.0f}%</b> <br/>',
                // Tooltip format for the x axis
                headerFormat: '<span>{point.key}</span><br/>',
                // Shows multiple y series tooltips for a single x axis value
                split: true,
                // Override the falcon defaults
                positioner: null,
                formatter: null,
            },
            plotOptions: {
                area: {
                    stacking: 'percent',
                    className: 'PercentageAreaChart-series',
                    events: {
                        // Disable the clicking of the legend items to hide series
                        legendItemClick: (e) => {
                            e.preventDefault();
                        },
                        // Select the currently hovered points on point click
                        click: (event) => this.handleClick(event),
                    },
                    marker: {
                        enabled: true,
                        // Don't have different markers for each series, just use a circle
                        symbol: 'circle',
                    },
                    label: {
                        // Don't show the series name directly on the chart
                        onArea: false,
                    },
                },
            },
        };

        // Merge in any option overrides given to us
        merge(percentageAreaOptions, options);

        // Apply the formatter to our data
        const formattedData = dataFormatter(data);
        // We do this here and not in the FalconChart as we want to add our initial selected value if it exists
        formattedData.forEach((series) => {
            const point = series.data.find((p) => p.x === selectedValue);
            if (point) {
                point.selected = true;
            }
        });

        return (
            <FalconChart
                data={formattedData}
                // For percentages, always display from 0 to 100
                yMax={100}
                yMin={0}
                yAxisUnits={'%'}
                options={percentageAreaOptions}
                // Hook into the HighCharts JS object
                onChartLoad={(chartObject) => {
                    this.chart = chartObject;
                }}
                type={'area'}
                {...props}
            />
        );
    }
}

PercentageAreaChart.propTypes = {
    /**
     * Data for the chart. See https://api.highcharts.com/highcharts/series for more info on how to correctly format the data.
     *
     * You can use the dataFormatter prop to convert this object to the correct format.
     */
    data: PropTypes.arrayOf(PropTypes.any).isRequired,

    /**
     * A meaningful name for the chart.
     */
    title: PropTypes.string,

    /**
     * Function that takes one argument 'data', which is the input data prop.
     *
     * Use this function to convert the data object into a correct format according to https://api.highcharts.com/highcharts/series.
     */
    dataFormatter: PropTypes.func,

    /**
     * Enable or disable showing a marker for each data point
     */
    enableMarkers: PropTypes.bool,

    /**
     * An explicit height for the chart. If a number, the height is given in pixels. If given a percentage string (for example '56%'), the height is given as the percentage of the actual chart width. This allows for preserving the aspect ratio across responsive sizes.
     *
     * By default (when null) the height is calculated from the offset height of the containing element, or 400 pixels if the containing element's height is 0.
     */
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Determines where the legend is placed.
     */
    legendPosition: PropTypes.oneOf(['center', 'right', 'top', 'none']),

    /**
     * The onClick callback function is fired whenever the chart is clicked on. It receives one parameter 'value' which is the x value for the clicked line.
     *
     * For click selection to work properly, this function must cause an update to props.selectedValue.
     */
    onClick: PropTypes.func,

    /**
     * Any instance specific options that you want to override (things like stylings etc.)
     *
     * See https://api.highcharts.com/highcharts for a list of chart options
     */
    options: PropTypes.objectOf(PropTypes.any),

    /**
     * The x value to select on the chart. For PercentageAreaCharts, each series has a shared selection so if selectedValue = 10, each series with a point x=10 will be have that point selected. Think of it as a vertical selection line.
     *
     * Note that the selection of the graph is controlled on this prop. Use this in conjunction with props.onClick to properly enable select functionality. To disable selection on the graph, leave this prop as null.
     */
    selectedValue: PropTypes.number,

    /**
     * The timezone to use when displaying dates and times.
     */
    timezone: PropTypes.string,

    /**
     * The type of X axis to display.
     */
    xAxisType: PropTypes.oneOf([
        'linear',
        'logarithmic',
        'datetime',
        'category',
    ]),

    /**
     * The units string that is appended to each X axis tick value
     */
    xAxisUnits: PropTypes.string,

    /**
     * The X axis label.
     */
    xLabel: PropTypes.string,

    /**
     * The units string that is appended to each Y axis tick value
     */
    yAxisUnits: PropTypes.string,

    /**
     * The Y axis label.
     */
    yLabel: PropTypes.string,
};

PercentageAreaChart.defaultProps = {
    dataFormatter: identity,
    legendPosition: 'center',
    options: {},
    onClick: null,
    selectedValue: null,
};

export default PercentageAreaChart;
