import shiftUtils from '@rs/core/utils/shiftUtils';
import { operator } from '@rs/core/application';
import { SELECT_STRING_DELIMITER } from '../../Components/Select/Select';

export function sortAlphabetically(a: string, b: string) {
    return a.localeCompare(b);
}

/**
 * Used for filtering out a value against a filter.
 * @param filterValues
 * @param recordValue
 * @returns {boolean}
 */
export function filterRecordByFilter(
    filterValues: string | number | null | undefined,
    recordValue: string | number,
) {
    if (filterValues == null) {
        return false;
    }
    return (
        String(filterValues).length === 0 ||
        String(filterValues).indexOf(String(recordValue)) > -1
    );
}

/**
 * Returns unique primatives within an array
 * @param item
 * @param index
 * @param arr
 */
export function filterUniqueItems<T>(item: T, index: number, arr: Array<T>) {
    return arr.indexOf(item) === index;
}

/**
 * Handles setting the EndDate property on filters when the 'Date' filter was changed
 * @param filters
 * @param filterName
 * @returns {object}
 */
export function setEndDateAfterDateSelected(filters: any, filterName: any) {
    if (filterName === 'Date' && filters.hasOwnProperty('EndDate')) {
        const endDate = shiftUtils.createMomentInSiteTime(
            filters.EndDate,
            shiftUtils.DATE_FORMAT__DISPLAY,
        );
        const startDate = shiftUtils.createMomentInSiteTime(
            filters.Date,
            shiftUtils.DATE_FORMAT__DISPLAY,
        );
        if (filters.rangeSelected && endDate.isBefore(startDate)) {
            filters.EndDate = filters.Date;
        }
    }
    if (filterName === 'EndDate' && filters.hasOwnProperty('Date')) {
        const endDate = shiftUtils.createMomentInSiteTime(
            filters.EndDate,
            shiftUtils.DATE_FORMAT__DISPLAY,
        );
        const startDate = shiftUtils.createMomentInSiteTime(
            filters.Date,
            shiftUtils.DATE_FORMAT__DISPLAY,
        );
        if (filters.rangeSelected && endDate.isBefore(startDate)) {
            filters.Date = filters.EndDate;
        }
    }
    return filters;
}

/**
 * Handles setting the EndDate property on filters when the 'rangeSelected' filter was changed
 * @param filters
 * @param filterName
 * @returns {object}
 */
export function setEndDateAfterRangeSelected(filters: any, filterName: any) {
    if (filterName === 'rangeSelected' && filters.hasOwnProperty('EndDate')) {
        if (filters.rangeSelected) {
            filters.EndDate = filters.Date;
        } else {
            filters.EndDate = '';
        }
    }
    return filters;
}

/**
 * Filters out an array based on comparing arrays of values vs filter values.
 * @param array {array} The results to filter
 * @param arrayOfConditions {[[() => any, any]]} A nested array of [[valueSelector, filterValue]]
 * @param resultSelector {Function} Selects the final value to be used
 */

export function getFilterOptionsFromArray(
    array: any[] = [],
    arrayOfConditions: any[] = [],
    resultSelector = (d: any) => d,
): string[] {
    return array
        .filter((item) =>
            arrayOfConditions.every(
                ([selector, filterValue, customComparisonFn]: any) => {
                    const selectorValue = selector(item);
                    if (typeof customComparisonFn === 'function') {
                        return customComparisonFn(filterValue, selectorValue);
                    }
                    return filterRecordByFilter(filterValue, selectorValue);
                },
            ),
        )
        .map(resultSelector)
        .filter(filterUniqueItems)
        .sort(sortAlphabetically)
        .filter((item) => item !== undefined);
}

/**
 * Gets the filter results are selected but not in the validFilterOptions
 * @param filterString - The currently selected results
 * @param validFilterOptions - The filterOptions that valid for the current data set
 * @param sortingFn - Sort the merged filterOptions & invalidFilterResults
 * @return {Array}
 */
export function getMissingFilterResults(
    filterString: string,
    validFilterOptions: any,
    sortingFn = sortAlphabetically,
) {
    const filterAsArray = filterString.split(SELECT_STRING_DELIMITER);
    const invalidFilterResults = filterAsArray
        .filter((d) => validFilterOptions.indexOf(d) === -1)
        .filter((d) => d);

    const validFilterOptionsWithInvalidMissingResults = [
        ...validFilterOptions,
        ...invalidFilterResults,
    ].sort(sortingFn);

    return validFilterOptionsWithInvalidMissingResults;
}

/**
 * Takes an array of operator names and formats them into the react selecto format
 * @param operatorDisplayNames
 */
export function operatorOptionsToReactSelectFormat(
    operatorDisplayNames: string[],
    operatorDisplayNameType: string,
): { label: string; value: string | number }[] {
    return operatorDisplayNames.map((name) => ({
        label: operator.displayName.format(operatorDisplayNameType, name),
        value: name,
    }));
}
