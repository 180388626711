import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_EQUIPMENT_LATENCY_SUCCESS = `${namespace}/FETCH_EQUIPMENT_LATENCY_SUCCESS`;
    const fetchEquipmentLatencySuccess = (response) =>
        action(FETCH_EQUIPMENT_LATENCY_SUCCESS, response);

    const FETCH_EQUIPMENT_LATENCY_ERROR = `${namespace}/FETCH_EQUIPMENT_LATENCY_ERROR`;
    const fetchEquipmentLatencyError = (error) =>
        action(FETCH_EQUIPMENT_LATENCY_ERROR, error);

    return {
        FETCH_EQUIPMENT_LATENCY_SUCCESS,
        fetchEquipmentLatencySuccess,
        FETCH_EQUIPMENT_LATENCY_ERROR,
        fetchEquipmentLatencyError,
    };
}
