import { roundTo } from '@rs/core/utils/mathHelpers';

/**
 * Sums various time values for multiple driverShiftProductivityEvents
 * @param {array} driverShiftProductivityEvents
 * @return {*|{totalLostTime: number, totalPassCount: number, totalDriverTime: number}}
 */
export function sumDriverProductivityEventTimeValues(
    driverShiftProductivityEvents = [],
) {
    const initialCount = {
        totalLostTime: 0,
        totalPassCount: 0,
        totalDriverTime: 0,
    };

    return driverShiftProductivityEvents.reduce((aggregation, driverEvent) => {
        aggregation.totalLostTime += driverEvent.TotalLostTime;
        aggregation.totalPassCount += driverEvent.PassCount;
        aggregation.totalDriverTime += driverEvent.TotalTime;
        return aggregation;
    }, initialCount);
}

/**
 * Calculates the section opportunity as a percent
 * @param {number} roundedTotalLostTime
 * @param {number} totalTime
 * @return {number}
 */
export function calculateSectionOpportunity(roundedTotalLostTime, totalTime) {
    return roundTo((roundedTotalLostTime / totalTime) * 100, 1);
}
