import { put, select, call, takeLatest, all } from 'redux-saga/effects';
import shiftUtils from '@rs/core/utils/shiftUtils';
import { actions } from '../BiasLoad';
import {
    getMapFiltersToAPIParams,
    getFilters,
} from '../BiasLoad/Selectors/filters';
import {
    convertURLQueryStringToObject,
    updateURLQueryString,
    getQueryString,
} from '../Lib/queryStringUtils';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import * as resourceSagas from './resourceSagas';
import { forwardTo } from '../App/createHistory';

export function* filterUpdated() {
    const filters = yield select(getFilters);
    // If dates are invalid go back to waiting for FILTER_UPDATED
    if (
        !shiftUtils.isDateStringValid(
            filters.Date,
            shiftUtils.DATE_FORMAT__VALIDATE,
        ) ||
        (filters.rangeSelected &&
            !shiftUtils.isDateStringValid(
                filters.EndDate,
                shiftUtils.DATE_FORMAT__VALIDATE,
            ))
    ) {
        return;
    }
    yield put(actions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA));
    yield call(getLoadUnitOperatorLogs);
    yield all([call(getLoadingEventsBiasLoadReport), call(getLoadingEvents)]);
    updateURLQueryString(filters);
    yield put(actions.setSpinnerState(false));
}

export function* getLoadingEvents() {
    const { ShiftIdDayAndNight, ...params } = yield select(
        getMapFiltersToAPIParams,
    );
    const { response, error } = yield call(
        resourceSagas.getLoadingEvents,
        params,
    );
    if (error) {
        return;
    }
    yield put(actions.loadLoadingEvents(response));
}

export function* getLoadUnitOperatorLogs() {
    const { ShiftIdDayAndNight } = yield select(getMapFiltersToAPIParams);
    const { response, error } = yield call(
        resourceSagas.getLoadUnitOperatorLogs,
        {
            ShiftId: ShiftIdDayAndNight,
        },
    );
    if (error) {
        return;
    }
    yield put(actions.loadLoaderOperatorLogs(response));
}

export function* getLoadingEventsBiasLoadReport() {
    const { ShiftIdDayAndNight, ...params } = yield select(
        getMapFiltersToAPIParams,
    );
    const { response, error } = yield call(
        resourceSagas.getLoadingEventsBiasLoadReport,
        params,
    );
    if (error) {
        return;
    }
    yield put(actions.loadLoadingEventsBiasLoadReport(response));
}

export function* updateFiltersWithURLParams() {
    yield put(actions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA));
    const urlFilterValues = convertURLQueryStringToObject();
    yield put(actions.setFiltersWithUrlParams(urlFilterValues));
    yield call(getLoadUnitOperatorLogs);
    yield all([call(getLoadingEventsBiasLoadReport), call(getLoadingEvents)]);
    yield put(actions.setSpinnerState(false));
}

export function* linkToPageWatcher({ payload }) {
    const filters = yield select(getFilters);
    const filtersToString = {
        ...filters,
        Operator: payload.operatorDisplayName,
    };
    forwardTo(`${payload.url}?${getQueryString(filtersToString)}`);
}

export default function* watch() {
    yield all([
        yield takeLatest(
            actions.UPDATE_FILTERS_WITH_URL_PARAMS,
            updateFiltersWithURLParams,
        ),
        yield takeLatest(actions.FILTER_UPDATED, filterUpdated),
        yield takeLatest(actions.LINK_TO_PAGE, linkToPageWatcher),
    ]);
}
