import { Component } from 'react';
import PropTypes from 'prop-types';
import bowser from 'bowser';

// See https://github.com/lancedikson/bowser for the full list of browser options
export const BROWSER_TYPES = {
    CHROME: 'chrome',
    FIREFOX: 'firefox',
    MSIE: 'msie',
    MSEDGE: 'msedge',
    SAFARI: 'safari',
};

/**
 * A component to conditionally render content based on the browser type
 */
class BrowserType extends Component {
    render() {
        const { children, renderIfNot } = this.props;
        if (
            renderIfNot &&
            renderIfNot.some((browserType) => bowser[browserType])
        ) {
            return null;
        }
        return children;
    }
}

BrowserType.defaultProps = {};

const browserTypes = Object.keys(BROWSER_TYPES).map(
    (key) => BROWSER_TYPES[key],
);

BrowserType.propTypes = {
    /** Doesn't render children if the browser is one of these types */
    renderIfNot: PropTypes.arrayOf(PropTypes.oneOf(browserTypes)),
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
        PropTypes.element,
    ]),
};

export default BrowserType;
