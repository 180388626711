import namespace from './namespace';
import {
    createSliceWithName as createMapFeatureSlice,
    initialState,
} from '../../Modules/mapFeatures_v2/reducer';
import { createSelectors } from '../../Modules/mapFeatures_v2/selectors';

export const moduleName = 'mapFeatures_v2';
export const selectState = (state: any) => {
    return state[namespace][moduleName];
};

const mapFeatures_v2 = createMapFeatureSlice(
    `${namespace}/${moduleName}`,
    initialState,
);

export const reducer = mapFeatures_v2.reducer;
export const actions = mapFeatures_v2.actions;
export const selectors = createSelectors(selectState);
