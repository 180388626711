import { all, takeLatest, call, put } from 'redux-saga/effects';
import shiftUtils from '@rs/core/utils/shiftUtils';
import * as cyclesummariesPayloadModule from '../ProductionDashboard/Modules/cyclesummariesPayload';
import * as resourceSagas from './resourceSagas';
import * as loadingSpinnerModule from '../ProductionDashboard/Modules/spinner';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import { PAGE_INIT } from '../ProductionDashboard/Actions';

function* fetchPayload() {
    yield put(
        loadingSpinnerModule.actions.setSpinnerState(
            true,
            LOADING_MESSAGES.LOADING__DATA,
        ),
    );

    const endDate = shiftUtils.createMomentInSiteTime();
    const startDate = endDate.clone().subtract(30, 'days');
    const shiftIdRange = shiftUtils.getAllFirstLastFromArray(
        shiftUtils.generateShiftIdRange(
            startDate.format(shiftUtils.DATE_FORMAT__DISPLAY),
            endDate.format(shiftUtils.DATE_FORMAT__DISPLAY),
        ),
    );

    const { response, error } = yield call(
        resourceSagas.getCycleSummariesPayload,
        {
            StartShiftId: shiftIdRange.first,
            EndShiftId: shiftIdRange.last,
        },
    );

    if (error) {
        yield put(
            cyclesummariesPayloadModule.actions.fetchCycleSummariesPayloadError(
                error,
            ),
        );
    } else {
        yield put(
            cyclesummariesPayloadModule.actions.fetchCycleSummariesPayloadSuccess(
                response,
            ),
        );
    }

    yield put(loadingSpinnerModule.actions.setSpinnerState(false));
}
export default function* watch() {
    yield all([takeLatest(PAGE_INIT, fetchPayload)]);
}
