import { objectToArray } from '@rs/core/utils/dataUtils';

export default function createSelectors(statePath) {
    function getActiveDownloads(state) {
        return statePath(state);
    }

    function getActiveDownloadsAsArray(state) {
        return objectToArray(statePath(state));
    }

    return {
        getActiveDownloads,
        getActiveDownloadsAsArray,
    };
}
