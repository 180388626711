import createActions from '../../Modules/fileExport/actions';
import createReducer from '../../Modules/fileExport/reducer';
import createSelectors from '../../Modules/fileExport/selectors';
import namespace from './namespace';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].fileExport;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);
export const reducer = createReducer({
    onRequested: actions.FETCH_EXPORT_RESULTS_REQUESTED,
    onSuccess: actions.FETCH_EXPORT_RESULTS_SUCCESS,
    onError: actions.FETCH_EXPORT_RESULTS_ERROR,
    onCancel: actions.FETCH_EXPORT_RESULTS_CANCEL,
});
export const selectors = createSelectors(statePath);
