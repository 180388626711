import PropTypes from 'prop-types';
import React from 'react';
import {
    LayerGroup,
    LayersControl,
    Marker,
    Pane,
    Tooltip,
} from 'react-leaflet';
import { ZINDEX } from './constants';
import UpdatingGeoJSON from './UpdatingGeoJSON';

// This is offsetting the marker size so the label is where the marker would be pointing
const tooltipOffset = [0, 41];

const Label = ({ position, label }) => (
    <Marker opacity={0} position={position}>
        <Tooltip
            direction="top"
            opacity={1}
            offset={tooltipOffset}
            permanent={true}
            className="RoadLabel"
            interactive={false}
        >
            <span>{label}</span>
        </Tooltip>
    </Marker>
);

Label.propTypes = {
    position: PropTypes.string,
    label: PropTypes.string,
};

class SpeedLimitedZones extends React.PureComponent {
    static propTypes = {
        // The text that will be displayed in the layer control
        name: PropTypes.string.isRequired,
        // The initial state of if to display the layer
        checked: PropTypes.bool.isRequired,
        // The mapFeatures data
        data: PropTypes.arrayOf(
            PropTypes.shape({
                Id: PropTypes.number,
                S3Id: PropTypes.number,
                ShiftId: PropTypes.number,
                AllTimeZoneId: PropTypes.number,
                Name: PropTypes.string,
                SVGPath: PropTypes.string,
                GeoJSON: PropTypes.shape({
                    type: PropTypes.string,
                    coordinates: PropTypes.array,
                }),
                SignPostedSpeedLimitUpper: PropTypes.number,
                SignPostedSpeedLimitLower: PropTypes.number,
                ThresholdSpeedLimitUpper: PropTypes.number,
                ThresholdSpeedLimitLower: PropTypes.number,
                Color: PropTypes.string,
            }),
        ),
        // The order a layer should be rendered in, higher number displays on top
        priority: PropTypes.number.isRequired,
    };

    render() {
        const {
            data,
            name,
            checked,
            priority,
            ...injectedLeafletProps
        } = this.props;

        const zIndex = ZINDEX.MAP_PANE + priority;
        const paneId = 'SpeedLimitedZones';

        if (!data || !Array.isArray(data) || !data.length) {
            return null;
        }
        return (
            <LayersControl.Overlay
                {...injectedLeafletProps}
                checked={checked}
                name={name}
            >
                <LayerGroup>
                    <Pane name={paneId} style={{ zIndex }}>
                        {data.map((row, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <UpdatingGeoJSON
                                        data={row.GeoJSON}
                                        style={{
                                            color: row.Color,
                                            fillOpacity: 0.2,
                                        }}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </Pane>
                </LayerGroup>
            </LayersControl.Overlay>
        );
    }
}

export default SpeedLimitedZones;
