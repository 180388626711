import { extent } from 'd3-array';
import PropTypes from 'prop-types';
import React from 'react';
import {
    LayerGroup,
    LayersControl,
    Marker,
    Pane,
    Tooltip,
} from 'react-leaflet';
import { typeMapFeature } from './types';
import { ZINDEX } from './constants';
import UpdatingGeoJSON from './UpdatingGeoJSON';

// This is offsetting the marker size so the label is where the marker would be pointing
const tooltipOffset = [0, 41];

const Label = ({ position, label }) => (
    <Marker opacity={0} position={position}>
        <Tooltip
            direction="top"
            opacity={1}
            offset={tooltipOffset}
            permanent={true}
            className="RoadLabel"
            interactive={false}
        >
            <span>{label}</span>
        </Tooltip>
    </Marker>
);

Label.propTypes = {
    position: PropTypes.string,
    label: PropTypes.string,
};

const getFeatureCenter = (geoJSON) => {
    const [yMin, yMax] = extent(geoJSON.coordinates[0].map((d) => d[1]));

    const [xMin, xMax] = extent(geoJSON.coordinates[0].map((d) => d[0]));

    const yCenter = yMin + (yMax - yMin) / 2;
    const xCenter = xMin + (xMax - xMin) / 2;

    return [yCenter, xCenter];
};

class WhereOnMinesite extends React.PureComponent {
    static propTypes = {
        // The text that will be displayed in the layer control
        name: PropTypes.string.isRequired,
        // The initial state of if to display the layer
        checked: PropTypes.bool.isRequired,
        // The mapFeatures data
        data: PropTypes.arrayOf(typeMapFeature),
        // The order a layer should be rendered in, higher number displays on top
        priority: PropTypes.number.isRequired,
    };

    render() {
        const {
            data,
            name,
            checked,
            priority,
            ...injectedLeafletProps
        } = this.props;

        const zIndex = ZINDEX.MAP_PANE + priority;
        const paneId = 'WhereOnMinesite';

        if (!data || !Array.isArray(data) || !data.length) {
            return null;
        }
        return (
            <LayersControl.Overlay
                {...injectedLeafletProps}
                checked={checked}
                name={name}
            >
                <LayerGroup>
                    <Pane name={paneId} style={{ zIndex }}>
                        {data
                            .filter(
                                (row) => row.properties && row.properties.womid,
                            )
                            .map((row, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <Label
                                            position={getFeatureCenter(
                                                row.geometry,
                                            )}
                                            label={row.properties.name}
                                        />
                                        <UpdatingGeoJSON
                                            data={row.geometry}
                                            style={{
                                                color: 'black',
                                                fillOpacity: 0,
                                            }}
                                        />
                                    </React.Fragment>
                                );
                            })}
                    </Pane>
                </LayerGroup>
            </LayersControl.Overlay>
        );
    }
}

export default WhereOnMinesite;
