import createActions from '../../../Modules/spinner/actions';
import createReducer from '../../../Modules/spinner/reducer';
import createSelectors from '../../../Modules/spinner/selectors';
import namespace from './namespace';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].spinner;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);
export const reducer = createReducer({
    setSpinnerState: actions.SET_SPINNER_STATE,
});
export const selectors = createSelectors(statePath);
