import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import * as wiki from '../Help/Modules/wiki';
import * as status from '../Help/Modules/status';
import parseConfluenceHTML from '../Components/Modal/parseConfluenceHTML';

const htmlParser = compose(
    parseConfluenceHTML.updateTableClasses,
    parseConfluenceHTML.embedYoutubeLink,
);

const withHelpContent = (WrappedComponent) => {
    class WithHelpContent extends Component {
        static propTypes = {
            contentId: PropTypes.string.isRequired,
            helpPageContent: PropTypes.string,
            fetchHelpContent: PropTypes.func.isRequired,
            isInProgress: PropTypes.bool.isRequired,
            hasError: PropTypes.bool.isRequired,
        };

        componentDidMount() {
            this.fetchHelpContent();
        }

        componentDidUpdate(prevProps) {
            if (prevProps.contentId !== this.props.contentId) {
                this.fetchHelpContent();
            }
        }

        fetchHelpContent = () => {
            this.props.fetchHelpContent(this.props.contentId);
        };

        render() {
            const {
                helpPageContent,
                fetchHelpContent,
                isInProgress,
                hasError,
                ...passthroughProps
            } = this.props;

            return (
                <WrappedComponent
                    helpPageContent={htmlParser(helpPageContent)}
                    fetchHelpContent={fetchHelpContent}
                    isInProgress={isInProgress}
                    hasError={hasError}
                    {...passthroughProps}
                />
            );
        }
    }
    const mapState = (state) => ({
        helpPageContent: wiki.selectors.getAll(state),
        isInProgress: status.selectors.isInProgress(state),
        hasError: status.selectors.hasError(state),
    });

    const mapActions = {
        fetchHelpContent: wiki.actions.fetchWikiRequested,
    };

    return connect(mapState, mapActions)(WithHelpContent);
};

export default withHelpContent;
