import * as React from 'react';
import { Section } from './Section';
import { selectSectionLabels, selectVisibleSections, State } from './reduxUtil';
import { useSelector, RootStateOrAny } from 'react-redux';

export interface LegendPureProps {
    children: React.ReactElement[];
}
export const LegendPure = ({ children }: LegendPureProps) => (
    <div className="Legend__Box__Polygons">{children}</div>
);

export interface LegendProps {
    stateSelector: (state: RootStateOrAny) => State;
}

export const Legend = ({ stateSelector }: LegendProps) => {
    const visibleSectionLabels = useSelector((state) =>
        selectVisibleSections(stateSelector(state)),
    );

    return (
        <LegendPure>
            {visibleSectionLabels
                .map((section) => section.label)
                .map((sectionLabels, i) => (
                    <Section
                        stateSelector={stateSelector}
                        sectionLabel={sectionLabels}
                        key={i}
                    />
                ))}
        </LegendPure>
    );
};
