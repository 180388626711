/**
 * Determine if we should perform a redirect to https.
 *
 * @returns {boolean}
 */
export function browserLocationShouldBeHttps() {
    const isMaxMineURL =
        window.location.hostname.match(/(.*)\.max-mine\.com$/) !== null;
    const isHttps = window.location.protocol === 'https:';
    if (isMaxMineURL && !isHttps) {
        return true;
    }
    return false;
}

/**
 * Redirects to the same location but using https:
 */
export function redirectToHttps() {
    window.location.href = `https:${window.location.href.substring(
        window.location.protocol.length,
    )}`;
}
