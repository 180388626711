import errorLogging from '@rs/core/utils/errorLogging';
import { put, call, fork, takeEvery, join } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { FEATURE_META } from '../../Auth/featureMeta';
import { getItem, AUTH_TOKEN_KEY } from '../../Lib/localStorage';
import generateApiUrl from '@rs/core/utils/generate-api-url';
import { GraphQLClient } from 'graphql-request';
import { getSdk } from '../../Graphql/Services';

export const GRAPHQL_BASE_URL =
    process.env.REACT_APP_GRAPHQL_URL ||
    process.env.REACT_APP_API_URL ||
    generateApiUrl();

export function* fetchAndStoreResponse(module: any, params: any) {
    yield put(module.actions.request());
    const result = yield call(module.request, params);

    const { response, error } = result;

    if (error) {
        const message = `Failed to load data for ${
            module.name
        } with params ${JSON.stringify(params)}`;
        errorLogging.logException(new Error(message), error);
        yield put(module.actions.error(message));
        return;
    }
    yield put(module.actions.success(response));
}

export function* updateRoute(pathname: string, query: string) {
    yield put(
        replace({
            pathname: pathname,
            search: query,
        }),
    );
}

export function getPathnameWithRouteMeta(featureId: number) {
    return featureId === null ? null : FEATURE_META[featureId].path;
}

// Function to search for label based on path
export const getLabelByPath = (path: string): string | undefined => {
    for (const key in FEATURE_META) {
        if (FEATURE_META[key].path === path) {
            return FEATURE_META[key].label;
        }
    }
    return undefined; // Return undefined if no matching entry is found
};

export function takeLeadingWithParam(
    typeOrPattern: any,
    param: any,
    saga: any,
    ...args: any
) {
    const instanceMap = new Map();
    return fork(function* () {
        yield takeEvery(typeOrPattern, function* (action) {
            const key =
                //@ts-ignore
                typeof param === 'function' ? param(action) : action[param];
            if (!instanceMap.get(key)) {
                const task = yield fork(saga, action, ...args);
                instanceMap.set(key, task);
                yield join(task);
                instanceMap.delete(key);
            }
        });
    });
}

// a function to remove url query key from an object key
export const sanitizeURIFromObjectKey = (
    obj: { [key: string]: string },
    objectKey: string,
    queryKeyToRemove: string,
) => {
    if (obj === null) {
        return;
    }
    const key = Object.keys(obj).find((param) => param === objectKey);
    if (key === undefined) return 'Key not found';

    const url = obj[key];

    // split the url and query string
    let urlParts: string[] = url.split('?');

    if (urlParts.length === 1 || url.indexOf(queryKeyToRemove) === -1) {
        // there were no parameters, or the
        // key wasn't present
        return obj;
    } else {
        var keyValues = urlParts[1].split('&');
        // remove the queryKeyToRemove
        let santitizedURI = keyValues
            .filter(
                (keyValuePairs) =>
                    keyValuePairs.indexOf(queryKeyToRemove) !== 0,
            )
            .join('&');

        // replace old url with striped url
        obj[objectKey] = urlParts[0] + '?' + santitizedURI;
    }

    return obj;
};

// this function copies the payload object into the passed object
export const changeKeyValuePair = (
    currentState: { [key: string]: any },
    payload: { [key: string]: any },
) => {
    let clonedState = {
        ...currentState,
    };

    clonedState[payload.name] = payload.value;

    return clonedState;
};

export function getGraphQlClient() {
    const token: string = getItem(AUTH_TOKEN_KEY);
    const client = new GraphQLClient(`${GRAPHQL_BASE_URL}/graphql`);

    if (token == null || token === '') {
        // eep!!!
        throw new Error('No token found');
    }
    client.setHeader('Authorization', token);
    return client;
}

export function getGraphqlSdk() {
    const client: GraphQLClient = getGraphQlClient();
    const sdk = getSdk(client);
    return sdk;
}
