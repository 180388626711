import { createSelector } from '@reduxjs/toolkit';
import { State } from './reducer';

import {
    addColortoSpeedZonesGeojson,
    addColorToMaterialMovementsGeojson,
    addColortoTrackingRegionsPolygon,
} from './helper';

const selectSelf = (state: State) => state;

const selectSpeedZonesGeojson = <T>(state: T) =>
    // @ts-ignore
    createSelector(selectSelf, (state) => {
        return addColortoSpeedZonesGeojson(state.speedZonesGeojson);
    })(state);

const selectWomidsGeojson = <T>(state: T) =>
    // @ts-ignore
    createSelector(selectSelf, (state) => state.womidsGeojson)(state);

const selectTrackingRegionsGeojson = <T>(state: T) =>
    // @ts-ignore
    createSelector(selectSelf, (state) => {
        return addColortoTrackingRegionsPolygon(state.trackingRegionsGeojson);
    })(state);

const selectMaterialMovementsGeojson = <T>(state: T) =>
    // @ts-ignore
    createSelector(selectSelf, (state) => {
        return addColorToMaterialMovementsGeojson(
            state.materialMovementsGeojson,
        );
    })(state);

export const createSelectors = <T>(
    selectState: (wholeState: any) => State,
) => ({
    selectSpeedZonesGeojson: <T>(state: T) =>
        selectSpeedZonesGeojson(selectState(state)),
    selectWomidsGeojson: <T>(state: T) =>
        selectWomidsGeojson(selectState(state)),
    selectTrackingRegionsGeojson: <T>(state: T) =>
        selectTrackingRegionsGeojson(selectState(state)),
    selectMaterialMovementsGeojson: <T>(state: T) =>
        selectMaterialMovementsGeojson(selectState(state)),
});
