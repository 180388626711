import { ParameterCheck, ParameterGroup, GroupArrayIndexes } from './constants';

// Helpers specifically for displaying the parameter checks in a pivot grid

/**
 * Gets a list of all unique equipment from the parameter checks and sorts them
 * alphabetically. Used for the columns in the pivot grid.
 *
 * @param parameterChecks
 */
export function getUniqueEquipmentNamesAndSort(
    parameterChecks: ParameterCheck[],
) {
    return Array.from(
        parameterChecks.reduce((set, row) => {
            return set.add(row.EquipmentSiteName);
        }, new Set()),
    ).sort();
}

/**
 * Helper function to get the information for displaying the parameter
 * groups on the far left of the pivot grid.
 *
 * @param parameterGroups
 */
export function getGroupArrayIndexes(
    parameterGroups: ParameterGroup[],
): Array<GroupArrayIndexes> {
    let sum = 0;
    return parameterGroups.map(({ name, parameters }) => {
        const row = {
            name,
            startIndex: sum,
            endIndex: sum + parameters.length - 1,
        };
        sum += parameters.length;
        return row;
    });
}

/**
 * Converts the ParameterGroups into a reference array that
 * can be used to sort the rows in the pivot grid
 *
 * @param parameterGroups
 */
export function getParameterSortingReference(
    parameterGroups: ParameterGroup[],
): Array<string> {
    return parameterGroups.reduce((flattenedArray, group) => {
        return flattenedArray.concat(
            group.parameters.map((parameter) => `${group.name}|${parameter}`),
        );
    }, []);
}

/**
 * Converts the ParameterChecks array into a format that can be passed
 * to the pivot grid. Uses the sortingRefence to sort the rows
 *
 * @param parameterChecks
 * @param sortingReference
 */
export function getPivotGridRows(
    parameterChecks: ParameterCheck[],
    sortingReference: Array<string>,
): Array<{ [key: string]: ParameterCheck }> {
    return parameterChecks.reduce((collection, parameterCheck) => {
        const { Parameter, DataType, EquipmentSiteName } = parameterCheck;
        const rowIndex = sortingReference.indexOf(`${DataType}|${Parameter}`);
        collection[rowIndex] = collection[rowIndex] || {};
        collection[rowIndex][EquipmentSiteName] = parameterCheck;
        return collection;
    }, []);
}
