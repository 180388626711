import { createReducer } from '../../Lib/reduxUtils';
import { SET_SPINNER_STATE } from '../Actions';

export const initialState = {
    isActive: false,
    message: '',
    faIcon: undefined,
};

export function setSpinnerState(state, spinner) {
    const { isActive, message, faIcon } = spinner;
    return {
        isActive,
        message,
        faIcon,
    };
}

export default createReducer(initialState, {
    [SET_SPINNER_STATE]: setSpinnerState,
});
