import * as React from 'react';

function SvgNoWifi(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <circle cx={512} cy={512} r={498.84} fill="#fff" />
            <defs>
                <path
                    d="M150.41 150.51C1.84 299.31-41.18 520.88 41.61 712.7c69.03 160.8 216.85 276.59 388.18 304.6 163.07 26.51 327.39-26.51 443.95-143.8 129.81-130.29 179.33-312.35 133.56-491.67-32.52-126.79-120.81-243.08-236.61-311.35C678.9 16.46 569.85-7.8 464.55 2.2 346 13.46 235.45 65.73 150.41 150.51zm559.75-25.76c19.01 9.5 57.03 34.01 57.03 36.51 0 1.25-136.31 138.3-303.14 305.1-20.21 20.21-121.25 121.24-303.14 303.11-7.35-10.81-11.43-16.81-12.25-18.01-59.53-88.53-84.29-207.07-65.53-314.36 10-56.77 35.52-120.04 67.53-167.55C242.7 132.75 405.53 58.97 567.85 80.73c47.02 6.25 99.29 22.26 142.31 44.02zm236.36 401.13c-2.25 64.27-15.26 118.29-41.77 173.06-44.27 92.03-111.8 160.06-203.84 204.82-56.28 27.26-109.55 40.27-175.08 42.77-114.55 4-222.35-36.27-312.64-117.29-2.25-2 611.02-617.96 614.53-616.71.75.5 8.5 9 16.75 19 70.04 83.78 105.8 187.07 102.05 294.35z"
                    id="no-wifi_svg__a"
                />
                <path
                    d="M138.65 394.59c-.96.88-5.8 5.3-14.5 13.25l33.01 33.26 33.27 33.27c10.5-9.46 16.34-14.71 17.51-15.76 92.54-81.78 206.34-123.04 324.39-117.29 1.57.08 9.41.5 23.51 1.25 24.01-24.16 37.35-37.58 40.02-40.26 21.76-22.01 39.27-40.52 38.77-41.27-.75-.5-15.26-3.5-32.52-6.5-36.01-6-110.8-8.75-145.06-4.75-120.31 13.25-228.6 62.52-318.4 144.8z"
                    id="no-wifi_svg__b"
                />
                <path
                    d="M255.46 505.38c-1.15 1.08-6.91 6.5-17.26 16.25l33.76 32.51 33.77 32.26c6-4.5 9.34-7 10-7.5 5.51-4.25 46.53-44.01 91.3-88.53 5.42-5.38 32.51-32.31 81.28-80.78-7.5.15-11.67.24-12.5.25-6.76 0-21.51 1.75-32.52 3.75-66.53 11.76-141.06 48.27-187.83 91.79z"
                    id="no-wifi_svg__c"
                />
                <path
                    d="M694.66 377.83c0 1 9.75 6.75 22.01 12.76 29.76 15 62.77 37.01 91.79 61.52 1.63 1.37 9.8 8.2 24.51 20.5l32.51-32.76 32.77-32.51-6-6.75c-18.76-20.01-79.79-65.52-115.31-85.53-.91-.52-5.5-3.1-13.75-7.75l-34.27 34.26c-22.84 23.51-34.26 35.6-34.26 36.26z"
                    id="no-wifi_svg__d"
                />
                <path
                    d="M564.35 506.38c13.2 3.45 20.54 5.36 22.01 5.75 42.52 11.25 82.28 32.26 117.05 62.02 1 .87 6 5.2 15.01 13l33.26-33.26 33.27-33.01c-7.21-6.6-11.21-10.27-12.01-11-17.76-16.26-47.27-37.77-69.78-50.77-23.26-13.25-53.77-27.01-60.28-27.01-2.5 0-20.51 16.01-41.52 37.01-4.93 4.97-17.27 17.39-37.01 37.27z"
                    id="no-wifi_svg__e"
                />
                <path
                    d="M393.27 677.18l12.76 12.76 12.75 12.75c7.2-5.55 11.21-8.63 12.01-9.25 48.77-37.01 113.55-38.01 159.82-2.25.98.77 5.9 4.6 14.76 11.5l32.76-32.76 32.52-32.51c-4.36-4.8-6.77-7.47-7.26-8-4.25-4.25-16.51-13.76-27.51-21.01-35.02-23.26-70.53-35.26-111.3-37.76l-23.76-1.5-53.78 54.02-53.77 54.01z"
                    id="no-wifi_svg__f"
                />
                <path
                    d="M452.8 797.23c5.75 49.26 66.28 70.27 101.29 35.01 4.5-4.25 9.76-11.26 12.01-15.51 5.5-10.75 7.25-30.26 3.5-43.01-6.5-23.51-33.27-44.02-57.53-44.02-6.5 0-16.5 2.5-24.01 6-24.01 11.26-38.26 35.77-35.26 61.53z"
                    id="no-wifi_svg__g"
                />
            </defs>
            <use xlinkHref="#no-wifi_svg__a" />
            <use
                xlinkHref="#no-wifi_svg__a"
                fillOpacity={0}
                stroke="#000"
                strokeOpacity={0}
            />
            <use xlinkHref="#no-wifi_svg__b" />
            <use
                xlinkHref="#no-wifi_svg__b"
                fillOpacity={0}
                stroke="#000"
                strokeOpacity={0}
            />
            <use xlinkHref="#no-wifi_svg__c" />
            <use
                xlinkHref="#no-wifi_svg__c"
                fillOpacity={0}
                stroke="#000"
                strokeOpacity={0}
            />
            <use xlinkHref="#no-wifi_svg__d" />
            <use
                xlinkHref="#no-wifi_svg__d"
                fillOpacity={0}
                stroke="#000"
                strokeOpacity={0}
            />
            <g>
                <use xlinkHref="#no-wifi_svg__e" />
                <use
                    xlinkHref="#no-wifi_svg__e"
                    fillOpacity={0}
                    stroke="#000"
                    strokeOpacity={0}
                />
            </g>
            <g>
                <use xlinkHref="#no-wifi_svg__f" />
                <use
                    xlinkHref="#no-wifi_svg__f"
                    fillOpacity={0}
                    stroke="#000"
                    strokeOpacity={0}
                />
            </g>
            <g>
                <use xlinkHref="#no-wifi_svg__g" />
                <use
                    xlinkHref="#no-wifi_svg__g"
                    fillOpacity={0}
                    stroke="#000"
                    strokeOpacity={0}
                />
            </g>
        </svg>
    );
}

export default SvgNoWifi;
