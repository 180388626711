import moment from 'moment';
import {
    isMoving,
    isStationary,
    getActivityClassification,
} from './activityClassifications';
import {
    DATE_FORMAT,
    Activity,
    ActivitiesByEquipmentName,
    ActivityClassification,
    EquipmentDelay,
} from './constants';

const {
    MOVING,
    STATIONARY,
    UNKNOWN,
    NO_GPS,
    NO_UPDATES,
} = ActivityClassification;

function getTotalActivityDuration(
    activities: Array<Activity>,
    findStatusChange: (Activity) => boolean,
): number {
    const statusChangeIndex = activities.findIndex((activity) =>
        findStatusChange(activity),
    );
    // If no status change at all, sum up all activities
    const validActivities =
        statusChangeIndex !== -1
            ? activities.slice(0, statusChangeIndex)
            : activities;
    const duration = validActivities.reduce(
        (sum, activity) => sum + activity.TotalTime,
        0,
    );
    return duration;
}

export function getTruckStationaryTimes(
    byEquipmentName: ActivitiesByEquipmentName,
    currentTime: moment.Moment,
    timezone: string,
): Array<EquipmentDelay> {
    const results = Object.entries(byEquipmentName).reduce(
        (
            collection: Array<EquipmentDelay>,
            [equipmentName, activities],
        ): Array<EquipmentDelay> => {
            const mostRecentActivity = activities[0];

            // Get the length of time from the most recent activity until right now
            // (We assume that the activity has continued through until right now)
            const timeFromMostRecentActivityUntilNow = moment
                .duration(
                    currentTime.diff(
                        moment.tz(
                            mostRecentActivity.StopTime,
                            DATE_FORMAT,
                            timezone,
                        ),
                    ),
                )
                .asSeconds();
            const mostRecentStatus = getActivityClassification(
                mostRecentActivity,
            );
            switch (mostRecentStatus) {
                case STATIONARY: {
                    const duration = getTotalActivityDuration(
                        activities,
                        (activity) => !isStationary(activity),
                    );
                    collection.push({
                        equipmentSiteName: equipmentName,
                        location:
                            mostRecentActivity.DestinationName ||
                            mostRecentActivity.SourceName,
                        status: STATIONARY,
                        duration: duration + timeFromMostRecentActivityUntilNow,
                    });
                    break;
                }
                case MOVING: {
                    const duration = getTotalActivityDuration(
                        activities,
                        (activity) => !isMoving(activity),
                    );

                    collection.push({
                        equipmentSiteName: equipmentName,
                        location:
                            mostRecentActivity.DestinationName ||
                            mostRecentActivity.SourceName,
                        status: MOVING,
                        duration: duration + timeFromMostRecentActivityUntilNow,
                    });
                    break;
                }
                case NO_GPS:
                case NO_UPDATES: {
                    collection.push({
                        equipmentSiteName: equipmentName,
                        location: '',
                        status: mostRecentStatus,
                        duration: 0,
                        lastUpdated: 0,
                    });
                    break;
                }
                case UNKNOWN:
                default:
                    console.error(
                        'We should never end up here! Not supposed to have Unknown states',
                        mostRecentActivity,
                    );
            }

            return collection;
        },
        [],
    );

    return results;
}
