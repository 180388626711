import {
    createReducer,
    withoutNestedPreviousState,
} from '../../Lib/reduxUtils';
import {
    DateTimeChangedPayload,
    ModeChangedPayload,
    State,
    StateWithoutPreviousState,
} from './types';
import moment from 'moment';

const initialStateWithoutPreviousState: StateWithoutPreviousState = {
    mode: 'single',
    startTime: moment().subtract(1, 'days').unix(),
    endTime: moment().unix(),
    timezone: 'Australia/Adelaide', // get it from the site configuration
};

export const initialState: State = {
    ...initialStateWithoutPreviousState,
    previousState: null,
};

export const syncURLToState = (state: State, params: Object) => {
    // Filter out the keys that this part of state doesn't care about
    const validKeys = Object.keys(state);
    return Object.entries(params)
        .filter(
            ([key, value]) =>
                validKeys.find((vk) => vk === key) && value !== undefined,
        )
        .reduce(
            (agg, [key, value]) => {
                // Let's keep it as same logic with other pages
                switch (key) {
                    case 'startTime':
                        agg.startTime = parseInt(value);
                        break;
                    case 'endTime':
                        agg.endTime = parseInt(value);
                        break;
                    case 'mode':
                        agg.mode = value;
                        break;
                    default:
                        //@ts-ignore
                        agg[key] = Boolean(value);
                }
                return agg;
            },
            {
                ...state,
                previousState: withoutNestedPreviousState(state),
            },
        );
};

export function modeChanged(state: State, payload: ModeChangedPayload) {
    return state;
}

export function dateTimeChanged(state: State, payload: DateTimeChangedPayload) {
    // do nothing?
    return state;
}

export default function createReducers(
    actions: any,
    customActions = {},
    customInitialState: any,
) {
    return createReducer(
        {
            ...initialState,
            ...customInitialState,
        },
        {
            [actions.modeChanged]: modeChanged,
            [actions.dateTimeChanged]: dateTimeChanged,
            [actions.syncURLToState]: syncURLToState,
            /**
             * Ensure any custom action handlers pass along state + any additional arguments needed for the
             * action handlers
             */
            ...customActions,
        },
    );
}
