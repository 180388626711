import React from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import LineChart from './LineChart';

// We use a Higher Order Component to extend the existing LineChart
const WithPlotBands = (WrappedLineChart) => {
    const LineChartWithPlotBands = ({
        plotBands,
        plotLines,
        drawLinesAroundBands,
        options,
        ...props
    }) => {
        // Here we add the outside band values as plot lines as well
        if (drawLinesAroundBands) {
            plotBands.reduce((collection, band) => {
                collection.push({
                    value: band.to,
                    color: band.color,
                });
                collection.push({
                    value: band.from,
                    color: band.color,
                });
                return collection;
            }, plotLines);
        }

        const chartOptions = {
            chart: {
                className: `LineChartWithPlotBands`,
            },
            yAxis: {
                plotBands: plotBands
                    ? addClassNames(plotBands, 'band')
                    : undefined,
                plotLines: plotLines
                    ? addClassNames(plotLines, 'line')
                    : undefined,
            },
        };

        // Merge in any option overrides given to us
        merge(chartOptions, options);

        return <WrappedLineChart options={chartOptions} {...props} />;
    };

    // For now just use the proptypes of the regular LineChart
    LineChartWithPlotBands.propTypes = {
        ...LineChart.propTypes,
        plotBands: PropTypes.arrayOf(PropTypes.shape({})),
        plotLines: PropTypes.arrayOf(PropTypes.shape({})),
        drawLinesAroundBands: PropTypes.bool,
    };
    LineChartWithPlotBands.defaultProps = {
        ...LineChart.defaultProps,
        plotBands: [],
        plotLines: [],
        drawLinesAroundBands: true,
    };

    return LineChartWithPlotBands;
};

// Helper function to add the appropriate class names to the plot bands/lines
const addClassNames = (array, name) =>
    array.map((band) => {
        return {
            ...band,
            className: `PlotBands__${name} PlotBands__${name}--dashed PlotBands__${name}--${band.color}`,
        };
    });

export default WithPlotBands;
