import React from 'react';
import PropTypes from 'prop-types';

const StatusCapacity = ({ maxCapacity, ...props }) => {
    const numberOfDigits = maxCapacity.toString().length;

    // Through trial and error, determined that 1 digit numbers looks good with 600 fontsize,
    // and 2 digit numbers look good with 470 fontsize. Use a simple linear equation to calculate
    const fontSize = -130 * numberOfDigits + 730;

    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <circle cx={512} cy={512} r={498.84} fill="#fff" />
            <path d="M512 50a462.14 462.14 0 0 1 179.82 887.71A462.13 462.13 0 0 1 332.18 86.29 459.06 459.06 0 0 1 512 50m0-50C229.23 0 0 229.23 0 512s229.23 512 512 512 512-229.23 512-512S794.77 0 512 0z" />
            <text x={546} y={707}>
                <tspan
                    x={546}
                    y={704}
                    fontWeight={500}
                    fontSize={fontSize}
                    fontFamily="Encode Sans"
                    textAnchor="middle"
                >
                    {`${maxCapacity}+`}
                </tspan>
            </text>
        </svg>
    );
};

StatusCapacity.propTypes = {
    maxCapacity: PropTypes.number.isRequired,
};

export default StatusCapacity;
