import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_MINESITE_AREAS_TREE_SUCCESS = `${namespace}/FETCH_MINESITE_AREAS_TREE_SUCCESS`;
    const fetchMinesiteAreasTreeSuccess = (resultsArray) =>
        action(FETCH_MINESITE_AREAS_TREE_SUCCESS, resultsArray);

    const FETCH_MINESITE_AREAS_TREE_ERROR = `${namespace}/FETCH_MINESITE_AREAS_TREE_ERROR`;
    const fetchMinesiteAreasTreeError = ({ error }) =>
        action(FETCH_MINESITE_AREAS_TREE_ERROR, { error });

    return {
        FETCH_MINESITE_AREAS_TREE_SUCCESS,
        fetchMinesiteAreasTreeSuccess,
        FETCH_MINESITE_AREAS_TREE_ERROR,
        fetchMinesiteAreasTreeError,
    };
}
