import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_PARAMETER_CHECKS_SUCCESS = `${namespace}/FETCH_PARAMETER_CHECKS_SUCCESS`;
    const fetchParameterChecksSuccess = (resultsArray) =>
        action(FETCH_PARAMETER_CHECKS_SUCCESS, resultsArray);

    const FETCH_PARAMETER_CHECKS_ERROR = `${namespace}/FETCH_PARAMETER_CHECKS_ERROR`;
    const fetchParameterChecksError = ({ error }) =>
        action(FETCH_PARAMETER_CHECKS_ERROR, { error });

    return {
        FETCH_PARAMETER_CHECKS_SUCCESS,
        fetchParameterChecksSuccess,
        FETCH_PARAMETER_CHECKS_ERROR,
        fetchParameterChecksError,
    };
}
