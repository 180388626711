import * as React from 'react';
import Pulse from './Components/FleetPulseIframe';
import { useSelector } from 'react-redux';
import { getRouterState } from '../Modules/router/selectors';
const { Layout } = require('@rs/core/falcon');

const { SubHeader, Content } = Layout;

const SicFleetPulsePage = () => {
    const routerState = useSelector(getRouterState);

    const allQueryParams = routerState.location.query;
    return (
        <>
            <SubHeader>
                <SubHeader.Left>
                    <SubHeader.Title>Fleet Pulse</SubHeader.Title>
                </SubHeader.Left>
            </SubHeader>
            <Content style={{ display: 'flex', padding: '0px 0px 0px 0px' }}>
                <Pulse queryParams={allQueryParams} />
            </Content>
        </>
    );
};

export default SicFleetPulsePage;
