import React from 'react';

type FitProps = {
    minHeight: string;
};

interface ContentRowType {
    [key: string]: React.ReactNode;
}

const ContentRow: ContentRowType = {};
ContentRow.Fixed = (props: any) => (
    <div style={{ flex: '0 1 auto' }} {...props} />
);
ContentRow.Fit = ({ minHeight, ...props }: FitProps) => (
    <div style={{ flex: '1 1 auto', minHeight }} {...props} />
);

export default ContentRow;
