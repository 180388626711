export default function filterByPlotOption(
    plotDisplayOptions,
    loadedOrUnloaded,
) {
    const mapToFilterName = {
        Loaded: 'loaded',
        Unloaded: 'unloaded',
    };
    return plotDisplayOptions[mapToFilterName[loadedOrUnloaded]];
}
