import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { typeChildren } from '../../types';

class GridLinesContainer extends Component {
    static propTypes = {
        children: typeChildren,
        style: PropTypes.shape({}),
    };

    render() {
        const { children, style } = this.props;
        return (
            <div className="GridLinesContainer" style={style}>
                {children}
            </div>
        );
    }
}

export default GridLinesContainer;
