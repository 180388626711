import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_TIMING_EQUIPMENT_UPTIME_SUCCESS = `${namespace}/FETCH_TIMING_EQUIPMENT_UPTIME_SUCCESS`;
    const fetchTimingEquipmentUptimeSuccess = (resultsArray) =>
        action(FETCH_TIMING_EQUIPMENT_UPTIME_SUCCESS, resultsArray);

    const FETCH_TIMING_EQUIPMENT_UPTIME_ERROR = `${namespace}/FETCH_TIMING_EQUIPMENT_UPTIME_ERROR`;
    const fetchTimingEquipmentUptimeError = ({ error }) =>
        action(FETCH_TIMING_EQUIPMENT_UPTIME_ERROR, { error });

    return {
        FETCH_TIMING_EQUIPMENT_UPTIME_SUCCESS,
        fetchTimingEquipmentUptimeSuccess,
        FETCH_TIMING_EQUIPMENT_UPTIME_ERROR,
        fetchTimingEquipmentUptimeError,
    };
}
