import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { typeChildren } from '@rs/core/types';
import {
    getAppInitializedWithError,
    getMinesiteDisplayName,
    getMinesiteId,
    isAppInitialized as isAppInitializedSelector,
} from '../Selectors';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import './AppInit.css';
import '../../styles/index.less';

class AppInit extends Component {
    componentDidUpdate(prevProps) {
        if (prevProps.minesiteDisplayName !== this.props.minesiteDisplayName) {
            this.updateDocumentTitle(this.props.minesiteDisplayName);
        }
    }

    updateDocumentTitle(minesiteDisplayName) {
        if (!minesiteDisplayName) {
            return;
        }
        document.title = `MaxMine - ${minesiteDisplayName}`;
    }

    render() {
        const {
            isAppInitialized,
            errorMessage,
            children,
            minesiteId,
        } = this.props;
        const message = errorMessage || 'App initializing...';
        const faIcon = errorMessage === '' ? undefined : 'exclamation-circle';
        return (
            <div className="AppInit MaxMine">
                {isAppInitialized && !errorMessage ? (
                    <div className={minesiteId}>{children}</div>
                ) : (
                    <LoadingSpinner
                        classNames={{
                            container: 'AppInit__LoadingSpinner',
                            icon: 'AppInit__LoadingSpinnerIcon',
                        }}
                        message={message}
                        isActive={true}
                        faIcon={faIcon}
                    />
                )}
            </div>
        );
    }
}

AppInit.defaultProps = {};

AppInit.propTypes = {
    isAppInitialized: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    minesiteId: PropTypes.string,
    children: typeChildren,
    minesiteDisplayName: PropTypes.string,
};

const mapStateToProps = (state) => ({
    isAppInitialized: isAppInitializedSelector(state),
    errorMessage: getAppInitializedWithError(state),
    minesiteId: getMinesiteId(state),
    minesiteDisplayName: getMinesiteDisplayName(state),
});

export default connect(mapStateToProps)(AppInit);
