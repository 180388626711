import { action } from '../../Lib/reduxUtils';
import { GoogleAnalyticsEventBuilder } from '../../Utils/userAnalytics/googleAnalyticsEventBuilder';

export default function createActions(namespace: any) {
    const FILTER_UPDATED = `${namespace}/FILTER_UPDATED`;
    const filterUpdated = (filterName: string, filterValue: any) =>
        action(
            FILTER_UPDATED,
            { filterName, filterValue },
            {
                analytics: new GoogleAnalyticsEventBuilder(
                    'SubFeatureUsage',
                    'Filter Updated',
                )
                    .setLabel(namespace)
                    .build(),
            },
        );

    const FILTER_UPDATED_BATCH = `${namespace}/FILTER_UPDATED_BATCH`;
    const filterUpdatedBatch = (filterUpdates: any) =>
        action(FILTER_UPDATED_BATCH, filterUpdates);

    const UPDATE_FILTERS_WITH_URL_PARAMS = `${namespace}/UPDATE_FILTERS_WITH_URL_PARAMS`;
    const updateFiltersWithUrlParams = () =>
        action(
            UPDATE_FILTERS_WITH_URL_PARAMS,
            {},
            {
                analytics: new GoogleAnalyticsEventBuilder(
                    'SubFeatureUsage',
                    'Filter Updated',
                )
                    .setLabel(namespace)
                    .build(),
            },
        );

    const SET_FILTERS_WITH_URL_PARAMS = `${namespace}/SET_FILTERS_WITH_URL_PARAMS`;
    const setFiltersWithUrlParams = (filtersFromUrl: any) =>
        action(SET_FILTERS_WITH_URL_PARAMS, filtersFromUrl);

    return {
        FILTER_UPDATED,
        filterUpdated,
        FILTER_UPDATED_BATCH,
        filterUpdatedBatch,
        UPDATE_FILTERS_WITH_URL_PARAMS,
        updateFiltersWithUrlParams,
        SET_FILTERS_WITH_URL_PARAMS,
        setFiltersWithUrlParams,
    };
}
