export default function createSelectors(statePath) {
    function get(state) {
        return statePath(state);
    }

    function getCircuits(state) {
        const slice = statePath(state);
        return slice.minesite.circuits || {};
    }

    function getCircuitById(state, circuitId) {
        const circuits = getCircuits(state);
        return circuits[circuitId];
    }

    function getHoldingStates(state) {
        const slice = statePath(state);
        return slice.minesite['holding-states'] || {};
    }

    function getHoldingStateById(state, holdingStateId) {
        const holdingStates = getHoldingStates(state);
        return holdingStates[holdingStateId];
    }

    function getTruckEquipmentAsObject(state) {
        const slice = statePath(state);
        const equipments = slice.equipment || [];

        return equipments.reduce((collection, equipment) => {
            collection[equipment.id] = {
                ...equipment,
            };
            return collection;
        }, {});
    }

    function getTruckEquipmentById(state, equipmentId) {
        const slice = statePath(state);
        const equipments = slice.equipment || [];
        return equipments.find((equipment) => equipment.id === equipmentId);
    }

    function getRestOfMinesite(state) {
        const slice = statePath(state);
        const restOfMinesite = slice.minesite['rest-of-minesite'] || [];
        const equipmentById = getTruckEquipmentAsObject(state);
        return restOfMinesite.map((equipmentId) => ({
            id: equipmentId,
            ...equipmentById[equipmentId],
        }));
    }

    return {
        get,
        getCircuits,
        getCircuitById,
        getHoldingStates,
        getHoldingStateById,
        getTruckEquipment: getTruckEquipmentAsObject,
        getTruckEquipmentById,
        getRestOfMinesite,
    };
}
