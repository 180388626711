import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_TRUCK_ALERT_EVENTS_SUCCESS = `${namespace}/FETCH_TRUCK_ALERT_EVENTS_SUCCESS`;
    const fetchTruckAlertEventsSuccess = (resultsArray) =>
        action(FETCH_TRUCK_ALERT_EVENTS_SUCCESS, resultsArray);

    const FETCH_TRUCK_ALERT_EVENTS_ERROR = `${namespace}/FETCH_TRUCK_ALERT_EVENTS_ERROR`;
    const fetchTruckAlertEventsError = ({ error }) =>
        action(FETCH_TRUCK_ALERT_EVENTS_ERROR, { error });

    return {
        FETCH_TRUCK_ALERT_EVENTS_SUCCESS,
        fetchTruckAlertEventsSuccess,
        FETCH_TRUCK_ALERT_EVENTS_ERROR,
        fetchTruckAlertEventsError,
    };
}
