import createActions from '../../Modules/networkCoverage/actions';
import createReducer from '../../Modules/networkCoverage/reducer';
import createSelectors from '../../Modules/networkCoverage/selectors';

import namespace from './namespace';
import { NetworkCoverageData } from '../../Modules/networkCoverage/types';
/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state: any): NetworkCoverageData =>
    state[namespace].networkCoverageReport;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);

export const reducer = createReducer(
    {
        onSuccess: actions.FETCH_MAP_DATA_SUCCESS,
        onError: actions.FETCH_MAP_DATA_ERROR,
        syncURLToState: actions.SYNC_URL_TO_STATE,
        h3ResolutionBestIndexChanged: actions.H3_RESOLUTION_BEST_INDEX_CHANGED,
        h3ResolutionIndexChanged: actions.H3_RESOLUTION_INDEX_CHANGED,
        clearData: actions.CLEAR_DATA,
    },
    {},
    {},
);

export const selectors = createSelectors(statePath);
