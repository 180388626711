import React from 'react';
import PropTypes from 'prop-types';
import { truckPerformance } from '../../application';
import cn from 'classnames';
import { getConformanceClassName } from '../ConformanceCell';
import PivotGridCell from './PivotGridCell';

function EnginePerformancePivotGrid({
    dataStatus,
    value,
    conformanceRangeBands,
    hideValue = false,
    className,
    nonAccessedValueClassName = 'Table__Cell--ConformanceNonAccessed',
}) {
    const conformanceClassName =
        getConformanceClassName(conformanceRangeBands, value) ||
        nonAccessedValueClassName;
    const classNames = cn(
        'EnginePerformancePivotGridCell',
        conformanceClassName,
        className,
    );

    switch (dataStatus) {
        case truckPerformance.engineDiagnosticPowerCurveMetrics.DATA_STATUS
            .DATA: {
            return (
                <PivotGridCell className={classNames}>
                    {!hideValue && value}
                </PivotGridCell>
            );
        }
        case truckPerformance.engineDiagnosticPowerCurveMetrics.DATA_STATUS
            .INSUFFICIENT_DATA: {
            return (
                <PivotGridCell
                    className={
                        'EnginePerformancePivotGridCell Table__Cell--NotExpected'
                    }
                />
            );
        }
        case truckPerformance.engineDiagnosticPowerCurveMetrics.DATA_STATUS
            .NO_DATA: {
            return (
                <PivotGridCell
                    className={'EnginePerformancePivotGridCell'}
                    background={'white'}
                >
                    -
                </PivotGridCell>
            );
        }
        case truckPerformance.engineDiagnosticPowerCurveMetrics.DATA_STATUS
            .OFFLINE: {
            return (
                <PivotGridCell
                    className={
                        'EnginePerformancePivotGridCell Table__Cell--EquipmentOffline'
                    }
                />
            );
        }
        default: {
            return <PivotGridCell className={classNames} />;
        }
    }
}

EnginePerformancePivotGrid.propTypes = {
    dataStatus: PropTypes.oneOf(
        Object.values(
            truckPerformance.engineDiagnosticPowerCurveMetrics.DATA_STATUS,
        ),
    ),
    value: PropTypes.number,
    hideValue: PropTypes.bool,
};

export default EnginePerformancePivotGrid;
