import transform from 'lodash/transform';
import groupBy from 'lodash/groupBy';

/**
 * Useful for hashing when the grouping key is a one-to-one relationship
 * @param arr
 * @param key
 * @param [valueSelector]
 * @returns {*}
 */
export function arrayToObject(
    arr: any[],
    key: string,
    valueSelector?: (any) => any,
) {
    if (!arr || !key || !arr.length) {
        return {};
    }
    return arr.reduce((collection, next) => {
        if (typeof valueSelector === 'function') {
            collection[next[key]] = valueSelector(next);
        } else {
            collection[next[key]] = next;
        }
        return collection;
    }, {});
}

/**
 * Useful for hashing when the grouping key is a one-to-many relationship
 * @param arr
 * @param groupingKey
 * @param [pushKey]
 */
export function arrayToGroupedObject(
    arr: any[],
    groupingKey?: string,
    pushKey?: string,
): Object {
    if (!arr || !groupingKey) {
        return {};
    }
    return arr.reduce((collection, b) => {
        collection[b[groupingKey]] = collection[b[groupingKey]] || [];
        if (pushKey) {
            collection[b[groupingKey]].push(b[pushKey]);
        } else {
            collection[b[groupingKey]].push(b);
        }
        return collection;
    }, {});
}

/**
 * Turns an object into an array
 * @param {object} - object
 * @returns {array}
 */
export function objectToArray(object = {}) {
    return Object.keys(object).map((key) => object[key]);
}

/**
 * Returns unique primatives within an array
 * @param item
 * @param index
 * @param arr
 */
export function filterUniqueItems(item, index, arr) {
    return arr.indexOf(item) === index;
}

/**
 * Turns an array into a string
 * @param arr
 */
export function arrayToDelimitedString(arr = []) {
    return arr.reduce((string, item, index) => {
        if (index === 0) {
            return (string += item);
        }
        return `${string}|${item}`;
    }, '');
}

/**
 * Turns a string into an array and filters out empty string values
 * @param string
 */
export function stringDelimetedToArray(string = '', delimeter = '|') {
    return string.split(delimeter).filter((d) => d !== '');
}

/**
 * Turns an array into a nicely formatted string.
 * @param {array} arr
 * @param {string} itemSeperator
 * @param {string} lastItemPrefix
 * @returns {string}
 */
export function arrayToFriendlyString(
    arr = [],
    itemSeperator = '; ',
    lastItemPrefix = 'and',
) {
    if (!arr.length) {
        return '';
    }
    if (arr.length === 1) {
        return `${arr[0]}`;
    }
    const lastItem = arr.pop();
    const arrAsString = arr.join(itemSeperator);
    return `${arrAsString} ${lastItemPrefix} ${lastItem}`;
}

/**
 * Turns a 2 dimensional array into a flat array
 * @param arrayOfArrays {[]array}
 * @returns {array}
 */
export function flattenArray(arrayOfArrays) {
    return arrayOfArrays.reduce((a, b) => [...a, ...b], []);
}

/**
 * Finds the most common occurances within an array
 * eg. mode(['pear', 'apple', 'orange', 'apple']) = ['apple']
 * @param array
 * @return {Array}
 */
export function mode(array) {
    if (!Array.isArray(array)) return [];
    const aggregateInitialState = {
        mode: [],
        greatestFreq: -Infinity,
        itemCount: {},
    };
    const results = array.reduce((aggregate, item) => {
        aggregate.itemCount[item] = (aggregate.itemCount[item] || 0) + 1;
        const itemCount = aggregate.itemCount[item];
        if (itemCount > aggregate.greatestFreq) {
            aggregate.greatestFreq = itemCount;
            aggregate.mode = [item];
        } else if (itemCount === aggregate.greatestFreq) {
            aggregate.mode.push(item);
        }
        return aggregate;
    }, aggregateInitialState);
    return results.mode;
}

/**
 * Remove some boilerplate of checking if a value is valid or returning a different value
 * @param isValidFn {function} function to determinie validity of value
 * @param altValue {*} the returned value if isValidFn fails
 * @return {function(*=)}
 */
export function valueIf(isValidFn, altValue) {
    return (value) => (isValidFn(value) ? value : altValue);
}

/**
 * Returns the indexes for all occurances of a substring with in string
 * @param {string} input The string to search through
 * @param {string} value The search term
 * @return {Array}
 */
export function getAllIndicesOfString(input, value) {
    const searchRange = value.length;
    const indices = [];
    for (let i = 0; i < input.length; i += 1) {
        const substring = input.slice(i, i + searchRange);
        if (substring === value) indices.push(i);
    }
    return indices;
}

/**
 * Groups an array by any number of keys
 *
 * Example:
 multiGroupBy(
 truckAlertEvents,
 'AlertLevel',
 'ManufacturerAlertId',
 'TruckName',
 );
 ->
 {
    [AlertLevel]: {
        [ManufacturerAlertId]: {
            [TruckName]: [{}]
        }
    }
 }
 *
 * @param {array} array The data array
 * @param {string} group The first key to group the data by
 * @param {string} restGroups Any number of additional groupings
 * @return {*}
 */
export function multiGroupBy(array, group, ...restGroups) {
    if (!group) {
        return array;
    }
    const currGrouping = groupBy(array, group);
    if (!restGroups.length) {
        return currGrouping;
    }
    return transform(
        currGrouping,
        (result, value, key) => {
            // Not sure how to write the type for a spread argument
            // @ts-ignore TS2557
            result[key] = multiGroupBy(value, ...restGroups);
        },
        {},
    );
}
