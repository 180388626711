import * as React from 'react';
import { getAuthenticatedToken } from '../../Auth/Selectors/features';
import { useSelector } from 'react-redux';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import determineEnvironmentFromHost from '@rs/core/utils/determineEnvironmentFromHost';

type QueryParams = {
    [key: string]: string;
};

type FleetPulseIframeProp = {
    queryParams: QueryParams;
};

const isQueryParamsSpecify = (queryParams: QueryParams) => {
    return queryParams != null && !!Object.keys(queryParams).length;
};

const constructQueryParamsAsUrlQueryString = (queryParams: QueryParams) => {
    return new URLSearchParams(queryParams).toString();
};

const FleetPulseIframe = ({ queryParams }: FleetPulseIframeProp) => {
    const token = useSelector(getAuthenticatedToken);
    const [contentLoaded, setContentLoaded] = React.useState(false);

    const hostUrl = document.domain || '';
    const currentEnvironment = determineEnvironmentFromHost(hostUrl);

    const iframeUrlParams = `token=${token}${
        isQueryParamsSpecify(queryParams)
            ? '&' + constructQueryParamsAsUrlQueryString(queryParams)
            : ''
    }`;

    return (
        <>
            <LoadingSpinner isActive={!contentLoaded} message={'Loading'} />
            <iframe
                title={'Fleet Pulse Embed'}
                style={{ flex: 1, border: 0 }}
                src={`https://pulse-frontend.${currentEnvironment}.max-mine.com/fleet?${iframeUrlParams}`}
                onLoad={() => setContentLoaded(true)}
            />
        </>
    );
};
export default FleetPulseIframe;
