import React from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import identity from 'lodash/identity';
import FalconChart from '../FalconChart/FalconChart';

const HistogramChart = ({ plotLines, options, ...props }) => {
    // Here we create our plotLine and plotBand arrays to give to HighCharts
    const plot = plotLines.reduce(
        (collection, line) => {
            if (line.isBand) {
                // If drawing a plot band
                collection.bands.push({
                    from: line.min,
                    to: line.max,
                    className: getClassName(line, 'band'),
                });
                // For a plot band, we draw the edges with plot lines
                const edges = line.drawEdges;
                if (edges) {
                    // Sometimes though we only want to draw the min, max or both edges
                    if (edges === 'min' || edges === 'both') {
                        collection.lines.push({
                            value: line.min,
                            className: getClassName(line, 'line'),
                        });
                    }
                    if (edges === 'max' || edges === 'both') {
                        collection.lines.push({
                            value: line.max,
                            className: getClassName(line, 'line'),
                        });
                    }
                }
            } else {
                // If drawing a plot line
                collection.lines.push({
                    value: line.value,
                    className: getClassName(line, 'line'),
                    // Setting a z-index of 5 draws the entire line above the chart
                    zIndex: line.zIndex,
                    // The label can be used to annotate a plot line
                    label: line.label,
                });
            }
            return collection;
        },
        {
            bands: [],
            lines: [],
        },
    );

    // Start building our line chart options...
    const histogramOptions = {
        chart: {
            events: {
                // Add white label backgrounds on chart load and when the chart is redrawn
                redraw: () => {
                    addWhiteBackgroundLabels();
                },
                load: () => {
                    addWhiteBackgroundLabels();
                },
            },
        },
        plotOptions: {
            series: {
                className: 'HistogramChart__series',
            },
        },
        xAxis: {
            // No crosshair for a histogram chart
            crosshair: false,
            plotBands: plot.bands,
            plotLines: plot.lines,
        },
    };

    // Merge in any option overrides given to us
    merge(histogramOptions, options);

    return <FalconChart options={histogramOptions} {...props} />;
};

// This is part of the hack to draw white backgrounds on SVG plot line labels
const addWhiteBackgroundLabels = () => {
    // Retrieve all the labels
    const textElements = document.getElementsByClassName(
        'highcharts-plot-line-label',
    );

    // Remove all previous label backgrounds
    while (
        document.getElementsByClassName('HistogramChart__label-background')
            .length !== 0
    ) {
        const backgrounds = document.getElementsByClassName(
            'HistogramChart__label-background',
        );
        for (let i = 0; i < backgrounds.length; i += 1) {
            backgrounds[i].parentNode.removeChild(backgrounds[i]);
        }
    }

    // Iterate through all text labels
    for (let i = 0; i < textElements.length; i += 1) {
        const text = textElements[i];
        // Get the bounding box
        const bBox = text.getBBox();

        // Create a white rectangle and place it at the same place as the label
        const rect = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'rect',
        );
        rect.setAttribute('x', bBox.x);
        rect.setAttribute('y', bBox.y);
        rect.setAttribute('width', bBox.width);
        rect.setAttribute('height', bBox.height);
        rect.setAttribute('fill', 'white');
        rect.setAttribute('data-z-index', '5');
        rect.setAttribute('class', 'HistogramChart__label-background');

        // Place the new rectangle right before the text label
        text.parentNode.insertBefore(rect, text);
    }
};

// This fuction is used to set the appropriate classnames from the options specified for the plot lines/bands
// TODO: should probably get moved into Falcon chart eventually and be made accessible to all charts
const getClassName = (line, name) => {
    // name is either "band" or "line"
    const baseClass = `PlotBands__${name}`;

    let className = baseClass;
    if (line.colour) {
        className += ` ${baseClass}--${line.colour}`;
    }
    if (line.dashed) {
        className += ` ${baseClass}--dashed`;
    }
    if (line.thick) {
        className += ` ${baseClass}--thick`;
    }
    return className;
};

HistogramChart.propTypes = {
    /**
     * Data for the chart. See https://api.highcharts.com/highcharts/series for more info on how to correctly format the data.
     *
     * You can use the dataFormatter prop to convert this object to the correct format.
     */
    data: PropTypes.arrayOf(PropTypes.any).isRequired,

    /**
     * An array used to set plot lines or plot bands on the chart
     */
    plotLines: PropTypes.arrayOf(
        PropTypes.shape({
            isBand: PropTypes.bool,
            drawEdges: PropTypes.oneOf(['min', 'max', 'both']),
            min: PropTypes.number,
            max: PropTypes.number,
            colour: PropTypes.string,
            dashed: PropTypes.bool,
            thick: PropTypes.bool,
            zIndex: PropTypes.number,
            label: PropTypes.shape({}),
        }),
    ),

    /**
     * A meaningful name for the chart.
     */
    title: PropTypes.string,

    /**
     * Function that takes one argument 'data', which is the input data prop.
     *
     * Use this function to convert the data object into a correct format according to https://api.highcharts.com/highcharts/series.
     */
    dataFormatter: PropTypes.func,

    /**
     * An explicit height for the chart. If a number, the height is given in pixels. If given a percentage string (for example '56%'), the height is given as the percentage of the actual chart width. This allows for preserving the aspect ratio across responsive sizes.
     *
     * By default (when null) the height is calculated from the offset height of the containing element, or 400 pixels if the containing element's height is 0.
     */
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Determines where the legend is placed.
     */
    legendPosition: PropTypes.oneOf(['center', 'right', 'top', 'none']),

    /**
     * Any instance specific options that you want to override (things like stylings etc.)
     *
     * See https://api.highcharts.com/highcharts for a list of chart options
     */
    options: PropTypes.objectOf(PropTypes.any),

    /**
     * The timezone to use when displaying dates and times.
     */
    timezone: PropTypes.string,

    /**
     * The type of X axis to display.
     */
    xAxisType: PropTypes.oneOf([
        'linear',
        'logarithmic',
        'datetime',
        'category',
    ]),

    /**
     * The units string that is appended to each X axis tick value
     */
    xAxisUnits: PropTypes.string,

    /**
     * The X axis label.
     */
    xLabel: PropTypes.string,

    /**
     * The units string that is appended to each Y axis tick value
     */
    yAxisUnits: PropTypes.string,

    /**
     * The Y axis label.
     */
    yLabel: PropTypes.string,

    /**
     * The maximum value for the Y axis
     */
    yMax: PropTypes.number,

    /**
     * The minimum value for the Y axis
     */
    yMin: PropTypes.number,
};

HistogramChart.defaultProps = {
    options: {},
    dataFormatter: identity,
    plotLines: [],
};

export default HistogramChart;
