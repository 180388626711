import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col } from '@rs/core/falcon';
import Modal from './Modal';
import withHelpContent from '../../HOC/withHelpContent';
import HelpPageContent from '../../Help/Components/HelpPageContent';

class HelpModal extends Component {
    static defaultProps = {};
    static propTypes = {
        modalTitle: PropTypes.string,
        closeModal: PropTypes.func.isRequired,
        isOpen: PropTypes.bool.isRequired,
        contentId: PropTypes.string.isRequired,
        helpPageContent: PropTypes.string,
        fetchHelpContent: PropTypes.func.isRequired,
        isInProgress: PropTypes.bool.isRequired,
        hasError: PropTypes.bool.isRequired,
    };

    componentDidMount() {
        this.fetchHelpContent();
    }

    UNSAFE_componentWillUpdate(prevProps) {
        if (prevProps.contentId !== this.props.contentId) {
            this.fetchHelpContent();
        }
    }

    fetchHelpContent = () => {
        this.props.fetchHelpContent(this.props.contentId);
    };

    render() {
        const {
            modalTitle,
            closeModal,
            isOpen,
            contentId,
            helpPageContent,
            isInProgress,
            hasError,
        } = this.props;

        if (!isOpen) return null;
        return (
            <Modal
                modalTitle={({ closeButton }) => {
                    return (
                        <Row justify="space-between">
                            <Col span={20}>
                                <h1>{modalTitle}</h1>
                            </Col>
                            <Col
                                span={1}
                                offset={3}
                                className="modal-header-icons"
                            >
                                <Button.Group>{closeButton}</Button.Group>
                            </Col>
                        </Row>
                    );
                }}
                modalFooter={
                    <React.Fragment>
                        <Button type="primary" onClick={closeModal}>
                            Close
                        </Button>
                    </React.Fragment>
                }
                closeModal={closeModal}
                modalClassName={'UnsupportedFeatureMessage__Modal'}
            >
                <HelpPageContent
                    contentId={contentId}
                    helpPageContent={helpPageContent}
                    isInProgress={isInProgress}
                    hasError={hasError}
                />
            </Modal>
        );
    }
}

export default withHelpContent(HelpModal);
