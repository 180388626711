import React, { ReactNode } from 'react';

interface Props {
    weighting?: number;
    title: ReactNode;
}

const LoadUnitTableConformanceHeader = ({ weighting, title }: Props) => {
    return (
        <div>
            {title}
            <br />
            <div className={'LoadUnitTableConformanceHeader__Weighting'}>
                {isNaN(weighting) ? <br /> : `${weighting * 100}%`}
            </div>
        </div>
    );
};

export default LoadUnitTableConformanceHeader;
