/**
 * These are the Falcon default options for Highcharts. All MaxMine Charts will inherit these options.
 *
 * A list of the default options can be seen in:
 * https://docs.google.com/document/d/1xSVhnLcHcqmbTW_2loLynk7vBfriqMTOxLHa3U8PK-w/edit#
 */
export const defaultOptions = {
    chart: {
        alignTicks: true,
        animation: true,
        backgroundColor: '#FFFFFF',
        borderColor: '#e6e6e6',
        borderRadius: 0,
        borderWidth: 0,
        className: undefined,
        colorCount: 10,
        defaultSeriesType: 'line',
        description: undefined,
        height: null,
        ignoreHiddenSeries: true,
        inverted: false,
        margin: undefined,
        marginBottom: undefined,
        marginLeft: undefined,
        marginRight: undefined,
        marginTop: undefined,
        panKey: undefined,
        panning: false,
        parallelCoordinates: false,
        pinchType: undefined,
        plotBackgroundColor: undefined,
        plotBackgroundImage: undefined,
        plotBorderColor: '#cccccc',
        plotBorderWidth: 0,
        plotShadow: false,
        polar: false,
        reflow: true,
        renderTo: undefined,
        selectionMarkerFill: 'rgba(51, 92, 173, 0.25)',
        shadow: false,
        showAxes: undefined,
        spacing: [10, 10, 15, 10],
        spacingBottom: 15,
        spacingLeft: 10,
        spacingRight: 10,
        spacingTop: 10,
        styledMode: true,
        type: 'line',
        typeDescription: undefined,
        width: null,
        zoomType: undefined,
    },
    title: {
        align: 'center',
        floating: false,
        margin: 15,
        verticalAlign: undefined,
        widthAdjust: -44,
        x: 0,
        //y: undefined,     // Causes the title to clip
    },
    subtitle: {
        align: 'center',
        floating: false,
        verticalAlign: undefined,
        widthAdjust: -44,
        x: 0,
        //y: undefined,
    },
    xAxis: {
        lineColor: '#8C8C8C',
        gridLineColor: '#EBEBEB',
    },
    yAxis: {
        lineColor: '#8C8C8C',
        gridLineColor: '#EBEBEB',
    },
};
