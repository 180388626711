import React from 'react';
import { connect } from 'react-redux';
import { operator } from '@rs/core/application';
import { getOperatorDisplayNameType } from '../../App/Selectors';

interface OperatorDisplayNameProps {
    // The original operator name
    operatorName: string | number;
    // The format the name will be displayed in
    displayFormat: string;
}

const OperatorDisplayName = ({
    operatorName,
    displayFormat,
}: OperatorDisplayNameProps) => {
    if (
        !(
            displayFormat === 'All' ||
            displayFormat === 'Name' ||
            displayFormat === 'Id' ||
            displayFormat === 'Blurred'
        )
    ) {
        throw new Error('display format is unknown type');
    }
    const name = operator.displayName.format(
        /**
         * TO-DO
         * Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'typeof OperatorDisplayFormats'.
         */
        operator.displayName.OperatorDisplayFormats[displayFormat],
        operatorName.toString(),
    );
    return <React.Fragment>{name}</React.Fragment>;
};

const mapState = (state: any) => ({
    displayFormat: getOperatorDisplayNameType(state),
});

export default connect(mapState)(OperatorDisplayName);
