import { action } from '../../Lib/reduxUtils';

export const LOAD_USERS_LIST = 'UsersList/LOAD_USERS_LIST';
export const loadUsersList = (usersList) => {
    return action(LOAD_USERS_LIST, usersList);
};

export const REQUEST_USERS_LIST = 'UsersList/REQUEST_USERS_LIST';
export const requestUsersList = () => action(REQUEST_USERS_LIST);

export const EXPORT_USERS_AS_CSV = 'UsersList/EXPORT_USERS_AS_CSV';
export const exportUsersAsCSV = () => action(EXPORT_USERS_AS_CSV);

export const LINK_TO_PAGE = 'UsersList/LINK_TO_PAGE';
export const linkToPage = ({ url, userData }) =>
    action(LINK_TO_PAGE, { url, userData });

export const ASSIGN_GROUPS = 'UsersList/ASSIGN_GROUPS';
export const assignGroups = ({ url, userData }) =>
    action(ASSIGN_GROUPS, { url, userData });

export const DELETE_USER = 'UsersList/DELETE_USER';

export const submitDeleteUser = ({ userData }) =>
    action(DELETE_USER, { userData });

export const IMPERSONATE_USER = 'UsersList/IMPERSONATE_USER';

export const impersonateUser = ({
    usernameToBeImpersonated,
    currentUserPassword,
}) =>
    action(IMPERSONATE_USER, { usernameToBeImpersonated, currentUserPassword });

export const STOP_IMPERSONATE_USER = 'UsersList/STOP_IMPERSONATE_USER';

export const stopImpersonateUser = () => action(STOP_IMPERSONATE_USER);

export const MANAGE_USER_ERROR = 'UsersList/MANAGE_USER_ERROR';
export const manageUserError = (apiErrorMessage) => {
    return action(MANAGE_USER_ERROR, apiErrorMessage);
};
