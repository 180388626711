import React from 'react';
import PropTypes from 'prop-types';
import { LayerGroup, LayersControl, Pane, Polyline } from 'react-leaflet';
import shouldComponentUpdateUtil from './utils/shouldComponentUpdate';
import { ZINDEX } from './constants';

const colorScale = (bin) => {
    const mapping = {
        0: 'SurfaceRoughness--Lowest',
        1: 'SurfaceRoughness--Low',
        2: 'SurfaceRoughness--Medium',
        3: 'SurfaceRoughness--High',
        4: 'SurfaceRoughness--Highest',
    };
    return mapping[bin];
};

class RoadPath extends React.Component {
    static propTypes = {
        // The text that will be displayed in the layer control
        name: PropTypes.string.isRequired,
        // The initial state of if to display the layer
        checked: PropTypes.bool.isRequired,
        // TODO fill in data type
        data: PropTypes.arrayOf(PropTypes.shape({})),
        shouldComponentUpdate: PropTypes.func,
        // The order a layer should be rendered in, higher number displays on top
        priority: PropTypes.number.isRequired,
    };

    static defaultProps = {};

    shouldComponentUpdate(nextProps) {
        return shouldComponentUpdateUtil(this.props, nextProps);
    }

    render() {
        const {
            data,
            name,
            checked,
            priority,
            shouldComponentUpdate,
            ...leafletInjectedProps
        } = this.props;

        const zIndex = ZINDEX.MAP_PANE + priority;
        const paneId = `SurfaceRoughness-${name}`;

        // It's important to always render the panes as they are the render destinations for leaflet.
        // The content inside can be dynamic
        // This makes sure that:
        // 1. The layer labels render in a consistent order
        // 2. It doesn't loose the layer option when toggling
        const hasInvalidData = !data || !Array.isArray(data) || !data.length;

        return (
            <LayersControl.Overlay
                {...leafletInjectedProps}
                checked={checked}
                name={name}
            >
                <LayerGroup>
                    <Pane name={paneId} style={{ zIndex }}>
                        {!hasInvalidData &&
                            data.map((row) => {
                                return row.lat_longs.map((positions, i) => {
                                    return (
                                        <Polyline
                                            key={i}
                                            positions={positions}
                                            className={colorScale(row.bin)}
                                            weight={9}
                                        />
                                    );
                                });
                            })}
                    </Pane>
                </LayerGroup>
            </LayersControl.Overlay>
        );
    }
}

export default RoadPath;
