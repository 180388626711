import * as React from 'react';

function SvgStatusAvailable(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <circle cx={512} cy={512} r={498.84} fill="#fff" />
            <path d="M512 50a462.14 462.14 0 01179.82 887.71A462.13 462.13 0 01332.18 86.29 459.06 459.06 0 01512 50m0-50C229.23 0 0 229.23 0 512s229.23 512 512 512 512-229.23 512-512S794.77 0 512 0z" />
            <path d="M434.21 730.1L251.15 508.84a10 10 0 011.33-14.08l23.12-19.12a10 10 0 0114.07 1.36l143.77 173.73a10 10 0 0015.53-.15l284.89-358.36a10 10 0 0114-1.6l23.48 18.67a10 10 0 011.61 14L449.75 730a10 10 0 01-15.54.1z" />
        </svg>
    );
}

export default SvgStatusAvailable;
