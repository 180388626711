import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_FEATURES_SUCCESS = `${namespace}/FETCH_FEATURES_SUCCESS`;
    const fetchFeaturesSuccess = (resultsArray) =>
        action(FETCH_FEATURES_SUCCESS, resultsArray);

    const FETCH_FEATURES_ERROR = `${namespace}/FETCH_FEATURES_ERROR`;
    const fetchFeaturessError = ({ error }) =>
        action(FETCH_FEATURES_ERROR, { error });

    return {
        FETCH_FEATURES_SUCCESS,
        fetchFeaturesSuccess,
        FETCH_FEATURES_ERROR,
        fetchFeaturessError,
    };
}
