/**
 * Groups equipmentUptimes into a nested lookup structure
 * INPUT:
 [
    {EquipmentSiteName: 'DT01', Uptime: 1, ShiftId: 1 },
    {EquipmentSiteName: 'DT02', Uptime: 1, ShiftId: 1 },
    {EquipmentSiteName: 'DT03', Uptime: 1, ShiftId: 1 },
 ]
 * OUTPUT:
 {
        1: {
            DT01: {EquipmentSiteName: 'DT01', Uptime: 1, ShiftId: 1 },
            DT02: {EquipmentSiteName: 'DT02', Uptime: 1, ShiftId: 1 },
            DT03: {EquipmentSiteName: 'DT03', Uptime: 1, ShiftId: 1 },
        },
 }
 *
 * @param {array} equipmentUptimes - Array of equipmentUptimes
 * @return {{}}
 */
export function groupByShiftIdEquipmentSiteName(equipmentUptimes) {
    if (!Array.isArray(equipmentUptimes)) {
        throw new Error(
            `groupByShiftIdEquipmentSiteName() - argument equipmentUptimes was not of type Array, got: ${typeof equipmentUptimes}`,
        );
    }
    return equipmentUptimes.reduce((collection, equipmentUptime) => {
        collection[equipmentUptime.ShiftId] =
            collection[equipmentUptime.ShiftId] || {};
        collection[equipmentUptime.ShiftId][
            equipmentUptime.EquipmentSiteName
        ] = equipmentUptime;
        return collection;
    }, {});
}

/**
 * Gets a list of unique truck names
 * @param {array} equipmentUptimes
 * @return {string[]}
 */
export function getUniqueEquipmentSiteNames(equipmentUptimes) {
    if (!Array.isArray(equipmentUptimes)) {
        throw new Error(
            `getUniqueEquipmentSiteNames() - argument equipmentUptimes was not of type Array, got: ${typeof equipmentUptimes}`,
        );
    }
    return Array.from(
        equipmentUptimes.reduce((set, row) => {
            return set.add(row.EquipmentSiteName);
        }, new Set()),
    );
}

/**
 * Totals the Uptime for each equipment
 * @param {array} equipmentUptimes
 * @return {string[]}
 */
export function getTotalSMUHoursByEquipmentName(equipmentUptimes) {
    if (!Array.isArray(equipmentUptimes)) {
        throw new Error(
            `getTotalSMUHoursByEquipmentName() - argument equipmentUptimes was not of type Array, got: ${typeof equipmentUptimes}`,
        );
    }

    return equipmentUptimes.reduce((collection, row) => {
        collection[row.EquipmentSiteName] =
            (collection[row.EquipmentSiteName] || 0) + row.Uptime;
        return collection;
    }, {});
}
