import createActions from '../../Modules/haulRoadConditionSummary/actions';
import createReducer from '../../Modules/haulRoadConditionSummary/reducer';
import createSelectors, {
    makeGetClassificationPercentForAllShifts,
    makeGetClassificationPercentForLastShift,
} from '../../Modules/haulRoadConditionSummary/selectors';
import namespace from './namespace';
import { selectors as minesiteMapSelectors } from './minesiteMap';
import getFilters from '../Selectors/filters/getFilters';
import * as minesiteAreasTreeModule from '../Modules/minesiteAreasTree';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].haulRoadConditionSummaryLatest;

/**
 * Create the module parts
 */
export const actions = createActions(`${namespace}/latest`);

export const reducer = createReducer({
    onSuccess: actions.FETCH_HAUL_ROAD_CONDITION_SUMMARY_SUCCESS,
    onError: actions.FETCH_HAUL_ROAD_CONDITION_SUMMARY_ERROR,
});

const moduleSelectors = createSelectors(statePath);
const getClassificationPercentForAllShifts = makeGetClassificationPercentForAllShifts(
    moduleSelectors.getHaulRoadConditionSummary,
    getFilters,
    (state) => {
        const enabledLoadKeys = minesiteMapSelectors.getEnabledLoadKeys(state);
        // Preserving the old format structure
        return enabledLoadKeys.reduce((agg, key) => {
            agg[key] = true;
            return agg;
        }, {});
    },
    minesiteAreasTreeModule.selectors.getAll,
);

const getClassificationPercentForLastShift = makeGetClassificationPercentForLastShift(
    getClassificationPercentForAllShifts,
);

export const selectors = {
    ...moduleSelectors,
    getClassificationPercentForAllShifts,
    getClassificationPercentForLastShift,
};
