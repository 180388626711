import { put, select, call, takeLatest, all } from 'redux-saga/effects';
import shiftUtils from '@rs/core/utils/shiftUtils';
import {
    actions as filtersActions,
    selectors as filtersSelectors,
} from '../TruckAlertsAnalysis/FleetStatus/Modules/filters';
import { actions as spinnerActions } from '../TruckAlertsAnalysis/FleetStatus/Modules/spinner';
import { actions as truckAlertEventsByTruckNameActions } from '../TruckAlertsAnalysis/FleetStatus/Modules/truckAlertEventsByTruckName';
import { actions as timingEquipmentUptimeActions } from '../TruckAlertsAnalysis/FleetStatus/Modules/timingEquipmentUptime';
import { actions as maxMineAlertCategoriesActions } from '../TruckAlertsAnalysis/FleetStatus/Modules/maxMineAlertCategories';
import { getMapFiltersToAPIParams } from '../TruckAlertsAnalysis/FleetStatus/Selectors/filters';
import {
    convertURLQueryStringToObject,
    updateURLQueryString,
} from '../Lib/queryStringUtils';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import * as resourceSagas from './resourceSagas';
import { API_ARRAY_DELIMITER, checkRequestsForErrors } from '../Api';

export function* filterUpdated(action) {
    const filters = yield select(filtersSelectors.getFilters);
    // If dates are invalid go back to waiting for FILTER_UPDATED
    if (
        !shiftUtils.isDateStringValid(
            filters.Date,
            shiftUtils.DATE_FORMAT__VALIDATE,
        ) ||
        (filters.rangeSelected &&
            !shiftUtils.isDateStringValid(
                filters.EndDate,
                shiftUtils.DATE_FORMAT__VALIDATE,
            ))
    ) {
        return;
    }
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    yield call(getTruckAlertEvents, action);
    updateURLQueryString(filters);
    yield put(spinnerActions.setSpinnerState(false));
}

export function* getTruckAlertEvents(triggeringAction) {
    const filters = yield select(filtersSelectors.getFilters);
    const {
        shiftIdsSelectedShifts,
        InWorkshop,
        MaxMineAlertCategory,
    } = yield select(getMapFiltersToAPIParams);
    let shiftIds = shiftIdsSelectedShifts.all.join(API_ARRAY_DELIMITER);
    if (
        (triggeringAction.type ===
            filtersActions.UPDATE_FILTERS_WITH_URL_PARAMS &&
            filters.DateShortCut === 'Last Shift') ||
        (triggeringAction.type === filtersActions.FILTER_UPDATED &&
            triggeringAction.payload.filterName === 'DateShortCut' &&
            triggeringAction.payload.filterValue === 'Last Shift')
    ) {
        const { response, error } = yield call(resourceSagas.getLatestShiftId);
        if (error) {
            yield put(
                truckAlertEventsByTruckNameActions.fetchTruckAlertEventsByTruckNameError(
                    { error: 'Failed to load truck alert events.' },
                ),
            );
        }
        const shiftId = response[0].ShiftId;
        const date = shiftUtils
            .createMomentInSiteTime(
                shiftId,
                shiftUtils.DATE_FORMAT__SHIFT_ID_DATE,
            )
            .format(shiftUtils.DATE_FORMAT__DISPLAY);
        yield put(
            filtersActions.filterUpdatedBatch([
                { filterName: 'rangeSelected', filterValue: false },
                { filterName: 'Date', filterValue: date },
                {
                    filterName: 'Shift',
                    filterValue: shiftUtils.shiftIdToShortName(shiftId),
                },
            ]),
        );
        shiftIds = response[0].ShiftId;
    }
    const truckAlertParams = {
        ShiftId: shiftIds,
        InWorkshop,
    };
    if (MaxMineAlertCategory) {
        truckAlertParams.MaxMineAlertCategory = MaxMineAlertCategory;
    }
    const results = yield all([
        // This is the results
        call(resourceSagas.getTruckAlertEventsByTruckName, truckAlertParams),
        // This is to populate the maxmine alert categories
        call(resourceSagas.getTruckAlertEventsByTruckName, {
            ShiftId: shiftIds,
            InWorkshop,
        }),
        call(resourceSagas.getTimingEquipmentUptime, {
            ShiftId: shiftIds,
            IsEquipmentOn: 1,
        }),
    ]);

    if (checkRequestsForErrors(results)) {
        yield put(
            truckAlertEventsByTruckNameActions.fetchTruckAlertEventsByTruckNameError(
                { error: 'Failed to load truck alert events.' },
            ),
        );
        return;
    }
    const [truckAlerts, maxMineCategories, truckUptime] = results;
    yield put(
        truckAlertEventsByTruckNameActions.fetchTruckAlertEventsByTruckNameSuccess(
            truckAlerts.response,
        ),
    );
    yield put(
        timingEquipmentUptimeActions.fetchTimingEquipmentUptimeSuccess(
            truckUptime.response,
        ),
    );
    yield put(
        maxMineAlertCategoriesActions.fetchTruckAlertEventsByTruckNameSuccess(
            maxMineCategories.response,
        ),
    );
}

export function* updateFiltersWithURLParams(action) {
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const urlFilterValues = convertURLQueryStringToObject();
    yield put(filtersActions.setFiltersWithUrlParams(urlFilterValues));
    yield call(getTruckAlertEvents, action);
    yield put(spinnerActions.setSpinnerState(false));
}

export default function* watch() {
    yield all([
        takeLatest(
            filtersActions.UPDATE_FILTERS_WITH_URL_PARAMS,
            updateFiltersWithURLParams,
        ),
        takeLatest(filtersActions.FILTER_UPDATED, filterUpdated),
    ]);
}
