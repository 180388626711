import { takeLatest, put, select, call, all } from 'redux-saga/effects';
import shiftUtils from '@rs/core/utils/shiftUtils';
import errorLogging from '@rs/core/utils/errorLogging';
import { stateSelectors, actions } from '../OperatorFeedback';
import { stateSelectors as authStateSelectors } from '../Auth/Reducers';
import {
    convertURLQueryStringToObject,
    updateURLQueryString,
} from '../Lib/queryStringUtils';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import * as resourceSagas from './resourceSagas';
import { API_ARRAY_DELIMITER, checkRequestsForErrors } from '../Api';
import { ROLE__OPERATOR_TRUCK } from '../Auth/roleIds';

export function* getTyresAndSafetyAndProductivityData() {
    // Get all the params to submit
    yield put(actions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA));
    const state = yield select();
    const filters = stateSelectors.getFilters(state);
    const { shiftIdsAllShifts } = stateSelectors.getDefinedFilters(state);
    const params = {
        UniqueDriverId: authStateSelectors.getUniqueDriverId(state),
    };

    if (
        authStateSelectors.hasRole(state, ROLE__OPERATOR_TRUCK) &&
        !params.UniqueDriverId
    ) {
        errorLogging.logException(
            `User is logged in as a Truck Operator but does not have a driver_id got:${params.UniqueDriverId}. It is likely that the OperatorFeedback page is broken!`,
        );
    }

    // Check if Date is valid
    if (
        !shiftUtils.isDateStringValid(
            filters.Date,
            shiftUtils.DATE_FORMAT__VALIDATE,
        )
    ) {
        yield put(actions.setSpinnerState(false));
        return;
    }

    // Load the drivers first because there might not be any results for that driver
    const drivers = yield resourceSagas.getDrivers({
        ...params,
        ShiftId: shiftIdsAllShifts.all.join(API_ARRAY_DELIMITER),
    });

    // Store the driver data
    yield put(actions.loadDrivers(drivers.response));
    if (!drivers.response || !drivers.response.length) {
        // No results so set an error message and stop spinner
        updateURLQueryString(filters);
        yield put(actions.setErrorMessage('There are no results for this day'));
        yield put(actions.setSpinnerState(false));
        return;
    }

    // There are results so clear any old error message
    yield put(actions.setErrorMessage(null));

    // Fetch the new state since we want to query the drivers
    // to find which shift the driver was on.
    const stateAfterDrivers = yield select();
    params.Shift = stateSelectors.getDriversShift(stateAfterDrivers);
    params.ShiftId = shiftUtils.getShiftIdFromDateString(
        filters.Date,
        params.Shift,
    );
    const shiftIdParams = { ShiftId: params.ShiftId };

    // Fetch all the data required
    const results = yield all([
        call(resourceSagas.getMapEvents, { ...params, IsConformant: 0 }),
        call(resourceSagas.getTopClusters, { ...params, IsConformant: 0 }),
        call(resourceSagas.getWKTAggregate, { FirstShiftId: params.ShiftId }),
        call(resourceSagas.getMapLabels, shiftIdParams),
        call(resourceSagas.getSpeedLimitedZones, shiftIdParams),
        call(resourceSagas.getSpeedingEvents, { ...params, IsConformant: 0 }),
        call(resourceSagas.getRampEvents, { ...params, IsConformant: 0 }),
        call(resourceSagas.getShiftProductivityEvents, shiftIdParams),
        call(resourceSagas.getDriverShiftProductivityEvents, {
            ...shiftIdParams,
            UniqueDriverId: params.UniqueDriverId,
        }),
        call(resourceSagas.getCornerLeague, params),
        call(
            resourceSagas.getS3Download,
            `${params.ShiftId}/mapFeatures.geo.json`,
        ),
        call(resourceSagas.getMinesiteAreasTree),
    ]);

    // Check for errors
    if (checkRequestsForErrors(results)) {
        yield put(actions.setErrorMessage('Failed to load data for this day'));
        yield put(actions.setSpinnerState(false));
        return;
    }

    const [
        mapEvents,
        topClusters,
        wktData,
        mapLabels,
        speedLimitedZones,
        speedingEvents,
        rampEvents,
        shiftProductivityEvents,
        driverProductivityEvents,
        cornerLeague,
        mapFeatures,
        minesiteAreasTree,
    ] = results;

    // Put the data into state
    yield put(
        actions.loadTyresAndSafetyProductivity({
            mapEvents: mapEvents.response || undefined,
            topClusters: topClusters.response || undefined,
            wktData: wktData.response || undefined,
            mapLabels: mapLabels.response || undefined,
            speedLimitedZones: speedLimitedZones.response || undefined,
            speedingEvents: speedingEvents.response || undefined,
            rampEvents: rampEvents.response || undefined,
            shiftProductivityEvents:
                shiftProductivityEvents.response || undefined,
            driverProductivityEvents:
                driverProductivityEvents.response || undefined,
            cornerLeague: cornerLeague.response || undefined,
            mapFeatures: mapFeatures.response,
            minesiteAreasTree: minesiteAreasTree.response,
        }),
    );

    yield loadDriverEventJSONs();

    // Reset spinner & update URL params
    yield put(actions.setSpinnerState(false));
    updateURLQueryString(filters);
}

export function* loadDriverEventJSONs() {
    const state = yield select();
    const filters = stateSelectors.getFilters(state);
    const shiftId = shiftUtils.getShiftIdFromDateString(
        filters.Date,
        stateSelectors.getDriversShift(state),
    );

    // Get the top 2 worst drivers
    const worstDriverEvents = stateSelectors.getTopEventsByLostTime(state, 2);

    // get all the driver events for each event as a nested array for each ShiftProductivityEventId
    const driverEvents = worstDriverEvents.map((driverEvent) =>
        stateSelectors.getDriverEventsByShiftProductivityEventId(
            state,
            driverEvent.ShiftProductivityEventId,
        ),
    );

    // Reduce collection of promises into flat array
    const s3DownloadPromises = driverEvents.reduce(
        (collection, eventsForShiftProductivityId) => {
            // Construct the s3 bucket urls to query
            const promises = eventsForShiftProductivityId
                .map((driverEvent) => {
                    const {
                        UniqueDriverId,
                        EquipmentId,
                        DataFileStartOffset,
                        DataFileEndOffset,
                        ShiftProductivityEventId,
                    } = driverEvent;
                    return {
                        path: `${shiftId}/fleet/by-class/haultruckfleet/Productivity/${UniqueDriverId}/${EquipmentId}/Events.json`,
                        dataFileStartOffset: DataFileStartOffset,
                        dataFileEndOffset: DataFileEndOffset,
                        ShiftProductivityEventId,
                    };
                })
                .map((downloadConfig) =>
                    resourceSagas.getS3Download(
                        downloadConfig.path,
                        downloadConfig.dataFileStartOffset,
                        downloadConfig.dataFileEndOffset,
                    ),
                );
            return [...collection, ...promises];
        },
        [],
    );

    // wait for array of promises to resolve
    const driverShiftProductivityJsons = yield all(s3DownloadPromises);

    // Check for errors
    if (checkRequestsForErrors(driverShiftProductivityJsons)) {
        yield put(
            actions.setErrorMessage('Failed to load driver data for this day'),
        );
        yield put(actions.setSpinnerState(false));
        return;
    }

    // Turn the driverResults into a flat array so we can link the API response back to the
    // correct ShiftProductivityEventId based on index
    const driverEventsToFlatArray = driverEvents.reduce(
        (collection, driverEvent) => [...collection, ...driverEvent],
        [],
    );

    // Group and Extract the data from responses
    const driverShiftProductivityResults = driverShiftProductivityJsons.reduce(
        (collection, data, index) => {
            const shiftProductivityEventId =
                driverEventsToFlatArray[index].ShiftProductivityEventId;
            collection[shiftProductivityEventId] =
                collection[shiftProductivityEventId] || [];
            collection[shiftProductivityEventId].push(data.response);
            return collection;
        },
        {},
    );

    // Store the driver JSON
    yield put(
        actions.setDriverShiftProductivityJsons(driverShiftProductivityResults),
    );
}

export function* updateFiltersWithURLParams() {
    const urlFilterValues = convertURLQueryStringToObject();
    yield put(actions.setFiltersWithUrlParams(urlFilterValues));
    yield call(getTyresAndSafetyAndProductivityData);
}

export default function* watch() {
    yield all([
        takeLatest(
            actions.UPDATE_FILTERS_WITH_URL_PARAMS,
            updateFiltersWithURLParams,
        ),
        takeLatest(
            actions.FILTER_UPDATED,
            getTyresAndSafetyAndProductivityData,
        ),
    ]);
}
