import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_SHIFT_LOGGER_DEPLOYMENTS_SUCCESS = `${namespace}/FETCH_SHIFT_LOGGER_DEPLOYMENTS_SUCCESS`;
    const fetchShiftLoggerDeploymentsSuccess = (resultsArray) =>
        action(FETCH_SHIFT_LOGGER_DEPLOYMENTS_SUCCESS, resultsArray);

    const FETCH_SHIFT_LOGGER_DEPLOYMENTS_ERROR = `${namespace}/FETCH_SHIFT_LOGGER_DEPLOYMENTS_ERROR`;
    const fetchShiftLoggerDeploymentsError = ({ error }) =>
        action(FETCH_SHIFT_LOGGER_DEPLOYMENTS_ERROR, { error });

    return {
        FETCH_SHIFT_LOGGER_DEPLOYMENTS_SUCCESS,
        fetchShiftLoggerDeploymentsSuccess,
        FETCH_SHIFT_LOGGER_DEPLOYMENTS_ERROR,
        fetchShiftLoggerDeploymentsError,
    };
}
