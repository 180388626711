import { createSelector } from 'reselect';
import { operator } from '@rs/core/application';
import shiftUtils from '@rs/core/utils/shiftUtils';
import { SELECT_STRING_DELIMITER } from '../../Components/Select';
import { selectors as filtersSelectors } from '../Modules/filters';

const getMapFiltersToParams = createSelector(
    filtersSelectors.getFilters,
    (filters) => {
        const params = {};
        if (filters.Shift.length > 0) {
            params.Shift = filters.Shift;
        }
        if (filters.Worker.length > 0) {
            const workersAsArray =
                filters.Worker.split(SELECT_STRING_DELIMITER) || [];
            params.UniqueDriverId = operator
                .operatorNamesToUniqueId(workersAsArray)
                .join(SELECT_STRING_DELIMITER);
        }
        if (filters.Crew.length > 0) {
            params.Crew = filters.Crew;
        }

        const shiftIdsAllShifts = shiftUtils.generateShiftIdRange(
            filters.Date,
            filters.EndDate,
        );
        const shiftIdsSelectedShifts = shiftUtils.generateShiftIdRange(
            filters.Date,
            filters.EndDate,
            filters.Shift.split(SELECT_STRING_DELIMITER),
        );

        params.shiftIdsAllShifts = shiftUtils.getAllFirstLastFromArray(
            shiftIdsAllShifts,
        );
        params.shiftIdsSelectedShifts = shiftUtils.getAllFirstLastFromArray(
            shiftIdsSelectedShifts,
        );

        const endDate = filters.rangeSelected ? filters.EndDate : filters.Date;
        const startDate = shiftUtils
            .createMomentInSiteTime(endDate)
            .subtract(3, 'months')
            .format(shiftUtils.DATE_FORMAT__DISPLAY);
        const conformanceStartShift = shiftUtils.getShiftIdFromDateString(
            startDate,
            filters.Shift,
        );
        params.shiftIdsConformance = shiftUtils.getAllFirstLastFromArray([
            conformanceStartShift,
            params.shiftIdsSelectedShifts.last,
        ]);
        return params;
    },
);

export default getMapFiltersToParams;
