import shiftUtils from '@rs/core/utils/shiftUtils';
import createActions from '../../Modules/filters/actions';
import {
    FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS,
    APP_INIT_SUCCESS,
} from '../../App/Actions';
import createReducer, { filterUpdated } from '../../Modules/filters/reducer';
import createSelectors from '../../Modules/filters/selectors';
import namespace from './namespace';

const fetchPrivateSiteConfigurationSuccess = (state) => {
    const defaultDate = shiftUtils.newDefaultFilterDate();
    return {
        ...state,
        Date: defaultDate,
    };
};

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].filters;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);

function appInitSuccess(state, payload) {
    const defaultFilterValue =
        payload.component.O4ROperator.defaultFilters.MaxMineAlertId;
    if (!defaultFilterValue) {
        return state;
    }
    return {
        ...state,
        MaxMineAlertId: defaultFilterValue,
    };
}

export const reducer = createReducer(
    {
        filterUpdated: actions.FILTER_UPDATED,
        filterUpdatedBatch: actions.FILTER_UPDATED_BATCH,
        setFiltersWithUrlParams: actions.SET_FILTERS_WITH_URL_PARAMS,
    },
    {
        [actions.FILTER_UPDATED]: (state, { filterName, filterValue }) => {
            const nextState = filterUpdated(state, { filterName, filterValue });
            if (filterName !== 'DateShortcut') {
                return nextState;
            }
            switch (filterValue) {
                case 'Last Day':
                    return {
                        ...nextState,
                        Date: shiftUtils.newDefaultFilterDate(),
                        rangeSelected: false,
                        EndDate: undefined,
                        Shift: '',
                    };
                case 'Last Week':
                    return {
                        ...nextState,
                        Date: shiftUtils
                            .createMomentInSiteTime()
                            .subtract(7, 'days')
                            .format(shiftUtils.DATE_FORMAT__DISPLAY),
                        rangeSelected: true,
                        EndDate: shiftUtils
                            .createMomentInSiteTime()
                            .format(shiftUtils.DATE_FORMAT__DISPLAY),
                        Shift: '',
                    };
                case 'Last Month':
                    return {
                        ...nextState,
                        Date: shiftUtils
                            .createMomentInSiteTime()
                            .subtract(1, 'month')
                            .format(shiftUtils.DATE_FORMAT__DISPLAY),
                        rangeSelected: true,
                        EndDate: shiftUtils
                            .createMomentInSiteTime()
                            .format(shiftUtils.DATE_FORMAT__DISPLAY),
                        Shift: '',
                    };
                default:
                    return nextState;
            }
        },
        [APP_INIT_SUCCESS]: appInitSuccess,
        [FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS]: fetchPrivateSiteConfigurationSuccess,
    },
    {
        Date: undefined,
        EndDate: undefined,
        Shift: '',
        Crews: '',
        rangeSelected: false,
        InWorkshop: '',
        MaxMineAlertId: '',
        DateShortcut: '',
    },
);

export const selectors = createSelectors(statePath);
