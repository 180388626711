import { combineReducers } from 'redux';
import { reducer, sliceSelectors } from './reducer';
import { featuresList } from './featuresList';
import { reducer as spinner } from '../Modules/spinner';

const REDUCER_KEY = 'CreateNewGroup';
const stateSelectors = {
    getApiErrorMessage: (state) =>
        sliceSelectors.getApiErrorMessage(state[REDUCER_KEY]),
    getCreateGroupForm: (state) =>
        sliceSelectors.getCreateGroupForm(state[REDUCER_KEY]),
};

export { stateSelectors, sliceSelectors, REDUCER_KEY };

export default combineReducers({
    reducer,
    spinner,
    featuresList,
});
