import { combineReducers } from 'redux';
import { reducer as spinner } from '../Modules/spinner';
import { reducer as groups } from '../Modules/groups';
import { reducer as features } from '../Modules/features';
import { reducer as editForm } from '../Modules/editForm';

export const REDUCER_KEY = 'EditGroup';
export default combineReducers({
    editForm,
    spinner,
    groups,
    features,
});
