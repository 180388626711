import React from 'react';
import PropTypes from 'prop-types';

// TODO - Later this can be replaced with the Truck card / icons
const TruckPlaceholder = (props) => (
    <div className={'TruckPlaceholder'} {...props} />
);

TruckPlaceholder.propTypes = {
    className: PropTypes.string,
};

export default TruckPlaceholder;
