import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { range } from 'd3-array';

class GridLinesHorizontal extends Component {
    static defaultProps = {};
    static propTypes = {
        rowCount: PropTypes.number.isRequired,
        left: PropTypes.func.isRequired,
        top: PropTypes.func.isRequired,
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
    };

    render() {
        const { rowCount, left, top, height, width } = this.props;
        return range(0, rowCount).map((col, i) => {
            return (
                <div
                    key={i}
                    className={`GridLinesHorizontal-${i}`}
                    style={{
                        position: 'absolute',
                        height,
                        width,
                        left: left({ index: i }),
                        top: top({ index: i }),
                        borderBottom: '1px solid #E8E8E8',
                    }}
                />
            );
        });
    }
}

export default GridLinesHorizontal;
