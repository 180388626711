import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Equipment } from '../../falcon';

const { StatusTypes } = Equipment;

const LiveMapLegend = ({ allowedStatuses }) => {
    const rows = allowedStatuses.map((status) => {
        const statusType = StatusTypes[status];
        return (
            <div
                className={'MinesiteMap__Legend__Row'}
                key={`MinesiteMap__Legend__Row_${status}`}
            >
                <div className={'MinesiteMap__Legend__Icon'}>
                    <Equipment.StatusIcon type={statusType} />
                </div>
                <div className={'MinesiteMap__Legend__Text'}>
                    {statusType.tooltip}
                </div>
            </div>
        );
    });
    return <div className="MinesiteMap__Layer__Container">{rows}</div>;
};

LiveMapLegend.propTypes = {
    // Array of allowed statuses that can be displayed
    allowedStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default LiveMapLegend;
