import * as React from 'react';

function SvgStatusTooLongAtQueue(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <circle cx={512} cy={512} r={498.84} fill="#fff" />
            <path d="M512 50a462.14 462.14 0 01179.82 887.71A462.13 462.13 0 01332.18 86.29 459.06 459.06 0 01512 50m0-50C229.23 0 0 229.23 0 512s229.23 512 512 512 512-229.23 512-512S794.77 0 512 0z" />
            <path d="M341 363.47A148.54 148.54 0 11236 407a147.52 147.52 0 01105-43.51m0-50c-109.64 0-198.53 88.89-198.53 198.53S231.38 710.53 341 710.53 539.54 621.64 539.54 512 450.66 313.47 341 313.47z" />
            <path d="M373.22 605.5l-62.62-44a10 10 0 01-4.25-8.18V443.2a10 10 0 0110-10h30a10 10 0 0110 10v84.12a10 10 0 004.25 8.18l41.4 29.1a10 10 0 012.42 13.93l-17.25 24.54a10 10 0 01-13.95 2.43zM891 502.44L755.33 364.92c-5.06-5.1-12.47-.5-12.47 7.75v43.44a10 10 0 002.88 7L824 502.44a11.1 11.1 0 010 15.49l-78.25 79.31a10 10 0 00-2.88 7v43.46c0 8.25 7.41 12.86 12.47 7.75L891 517.93c3.9-3.93 3.9-11.55 0-15.49z" />
            <path d="M613.22 364.92c-5.06-5.1-12.47-.5-12.47 7.75v275c0 8.25 7.41 12.86 12.47 7.75L748.9 517.93c3.89-3.93 3.89-11.55 0-15.49z" />
        </svg>
    );
}

export default SvgStatusTooLongAtQueue;
