import createActions from '../../../Modules/APIArrayResponse/actions';
import createReducer from '../../../Modules/APIArrayResponse/reducer';
import createSelectors from '../../../Modules/APIArrayResponse/selectors';
import { getEquipmentOperatorAlertEvents } from '../../../Sagas/resourceSagas';
import namespace from './namespace';

export const name = 'equipmentOperatorAlertEvents';
export const request = getEquipmentOperatorAlertEvents;

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].equipmentOperatorAlertEvents;

/**
 * Create the module parts
 */
export const actions = createActions(
    namespace,
    'EQUIPMENT_OPERATOR_ALERT_EVENTS',
);
export const reducer = createReducer({
    onRequest: actions.API_REQUESTED,
    onError: actions.API_ERROR,
    onSuccess: actions.API_SUCCESS,
});
export const selectors = createSelectors(statePath);
