import React from 'react';
import PropTypes from 'prop-types';
import { schemeCategory10, scaleOrdinal } from 'd3-scale';
import { connect } from 'react-redux';
import { GearLockdown } from '@rs/core/components';
import * as types from '@rs/core/types';
import { FEATURE_IDS } from '@rs/core/features';
import { roundTo } from '@rs/core/utils/mathHelpers';
import {
    MinesiteMap,
    MAP_LAYER_LABELS,
    getMapExtentBounds,
    Row,
    Col,
    Layout,
    Equipment,
} from '@rs/core/falcon';
import { wktAggregate } from '@rs/core/application';
import ProductivityEventTable from './ProductivityEventTable';
import ProductivityFilters from './ProductivityFilters';
import { ProductivityCharts } from '../../Components/ProductivityCharts';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import * as actions from '../Actions';
import { stateSelectors } from '../Reducers';
import {
    getComponentConfig,
    getTopLevelWhereOnMinesiteIdDepth,
    getPageTitle,
} from '../../App/Selectors';
import { HasFeature } from '../../Components/HasFeature';
import OperatorDisplayName from '../../Components/OperatorDisplayName/OperatorDisplayName';
import DigitalGlobeBackground from '../../Components/DigitalGlobeBackground/DigitalGlobeBackground';
import { normaliseString } from '@rs/core/utils';
import camelCase from 'lodash/camelCase';
import { selectors as productivitySelectors } from '../Modules/mapFeatures';
import {
    selectState as selectMapLegendState,
    selectors as mapLegendSelectors,
} from '../Modules/mapLegends';
import { MapControlWithPopup } from '@rs/core/falcon/components/MinesiteMap/MapControl_v2';
import { Legend } from '@rs/core/falcon/components/NewLegend';

const { getWKTResponseGeoJSON } = wktAggregate;

const colorScale = scaleOrdinal(schemeCategory10);
const TABLE_ROW_COUNT = 5;
const { SubHeader, Content } = Layout;

/**
 * Selects the correct opacity based on if the event has been hovered or is selected
 * @param event
 * @param highlightedEventPathIndex
 * @param selectedShiftProductivityEventId
 * @param index
 * @return {number}
 */
function getEventOpacity(
    event,
    highlightedEventPathIndex,
    selectedShiftProductivityEventId,
    index,
) {
    let opacity = 1;
    // When there's no event selected, use the hovered table row index
    if (
        highlightedEventPathIndex !== null &&
        index !== highlightedEventPathIndex
    ) {
        opacity = 0.3;
    }

    // When there is an filters.Event selected use that instead
    if (
        selectedShiftProductivityEventId !== '' &&
        event.ShiftProductivityEventId !==
            parseInt(selectedShiftProductivityEventId, 10)
    ) {
        opacity = 0.3;
    }
    return opacity;
}

function createNodesFromDriverEvents(
    clusters,
    highlightedEventPathIndex,
    selectedShiftProductivityEventId,
) {
    if (!clusters) {
        return {
            nodes: [],
        };
    }

    const nodes = [];

    clusters.forEach((cluster, i) => {
        const color = colorScale(i);
        const opacity = getEventOpacity(
            cluster,
            highlightedEventPathIndex,
            selectedShiftProductivityEventId,
            i,
        );

        nodes.push({
            rank: i,
            position: { lat: cluster.Latitude, lng: cluster.Longitude },
            color,
            opacity,
        });
    });
    return nodes;
}

class Productivity extends React.PureComponent {
    constructor() {
        super();
        this.chartHover = this.chartHover.bind(this);
        this.state = {
            truckLocation: {
                latitude: null,
                longitude: null,
            },
            showPopup: false,
            geofenceToggle: true,
        };
    }

    onCloseClick = () => {
        this.setState((state) => ({
            ...state,
            showPopup: false,
        }));
    };
    onContentClick = () => {
        this.setState((state) => ({
            ...state,
            showPopup: true,
        }));
    };

    BottomLeft = () => <div id={'legend'}>Geofence Legend</div>;
    componentDidMount() {
        this.props.updateFiltersWithUrlParams();
    }

    // Resets the truck location if the filters changes
    wrapOnFilterChange = (filterName, filterValue) => {
        this.setState((state) => ({
            ...state,
            truckLocation: {
                latitude: null,
                longitude: null,
            },
        }));
        this.props.onFilterChange(filterName, filterValue);
    };

    mapWorstDriverEventsToForceSimulation() {
        const {
            topEventsByLostTime,
            highlightedEventPathIndex,
            filters,
        } = this.props;
        return topEventsByLostTime.map((event, i) => {
            const opacity = getEventOpacity(
                event,
                highlightedEventPathIndex,
                filters.Event,
                i,
            );
            const color = colorScale(i);

            const halfOfPathLength = Math.round(
                event.GeoJSON.coordinates.length / 2,
            );

            return {
                ...event,
                X: event.LeaderLineX,
                Y: event.LeaderLineY,
                Latitude: event.GeoJSON.coordinates[halfOfPathLength][0],
                Longitude: event.GeoJSON.coordinates[halfOfPathLength][1],
                color,
                opacity,
            };
        });
    }

    // This is called when the charts are hovered
    chartHover(point) {
        if (point) {
            this.setState((state) => ({
                ...state,
                truckLocation: {
                    latitude: point.latitude,
                    longitude: point.longitude,
                },
            }));
        }
    }

    handleOverlayAdd = (checked, name) => {
        const layerName = normaliseString.stripHTMLTags(name);
        const formattedLayerName = camelCase(layerName);
        this.props.onMapFilterChange(formattedLayerName, checked);
        //----------handles legend layer flipping -------------------
        if (checked) {
            //make the section as visible
            this.props.flipLegendLayer(layerName, 'VISIBLE');
        } else {
            //make the section as hidden
            this.props.flipLegendLayer(layerName, 'HIDDEN');
        }
        //------handles turning on/offgeofenceLabels-------------------
        if (formattedLayerName === 'geofenceLabels') {
            // this.props.geofenceLabelsToggle(layerName, checked)
            this.setState({ geofenceToggle: checked });
        }
    };

    componentWillUnmount() {
        // initialse the redux state when the component unmount
        this.props.init();
    }
    render() {
        const {
            formattedAveragedResults,
            topEventsByLostTime,
            onTableRowHover,
            wktData,
            filters,
            filterOptions,
            spinner,
            topFiveDrivers,
            bottomFiveDrivers,
            driverEventJsonError,
            gearLockdownShifts,
            minesiteMapConfig,
            highlightedEventPathIndex,
            title,
            minesiteMap,
            onViewportChange,
            speedZonesGeojson,
            materialMovement,
            mineFeatures,
            mineRegions,
            mapLegendSections,
        } = this.props;
        const { truckLocation } = this.state;

        const eventIsSelected = !!filters.Event;
        const eventInt = parseInt(filters.Event, 10);
        const eventIndex = topEventsByLostTime.findIndex(
            (row) => row.ShiftProductivityEventId === eventInt,
        );

        // If there's a hovered event use that
        // otherwise if an event is selected in the filters
        // otherwise nothing is selected
        // This controls the styling of EventPaths
        let selectedEventIndex = null;
        if (highlightedEventPathIndex !== null) {
            selectedEventIndex = highlightedEventPathIndex;
        } else if (eventIsSelected && eventIndex > -1) {
            selectedEventIndex = eventIndex;
        }

        const forceSimulation = createNodesFromDriverEvents(
            this.mapWorstDriverEventsToForceSimulation(),
            highlightedEventPathIndex,
            filters.Event,
        );

        const mainProdEventsTableCols = [
            {
                header: 'Rank',
                renderCell: (item, index) => index + 1,
                classNames: {},
            },
            {
                header: 'Section Opportunity',
                renderCell: (item) => `${item.sectionOpportunity}%`,
                classNames: {
                    header: 'Table__Cell--Number',
                    cell: 'Table__Cell--Number',
                },
            },
            {
                header: 'Lost Time (s)',
                renderCell: (item) => item.roundedTotalLostTime,
                classNames: {
                    header: 'Table__Cell--Number',
                    cell: 'Table__Cell--Number',
                },
            },
        ];

        const topFiveDriversTableCols = [
            {
                header: topFiveDrivers.isSingleTableMode
                    ? 'All Operators'
                    : 'Top Operators',
                renderCell: (item) => (
                    <OperatorDisplayName operatorName={item.DriverName} />
                ),
                classNames: {},
            },
            {
                header: 'Lost Time per Pass (s)',
                renderCell: (item) => roundTo(item.totalLostTime, 0),
                classNames: {
                    header: 'Table__Cell--Number',
                    cell: 'Table__Cell--Number',
                },
            },
        ];

        const bottomFiveDriversTableCols = [
            {
                header: 'Bottom Operators',
                renderCell: (item) => (
                    <OperatorDisplayName operatorName={item.DriverName} />
                ),
                classNames: {},
            },
            {
                header: 'Lost Time per Pass (s)',
                renderCell: (item) => roundTo(item.totalLostTime, 0),
                classNames: {
                    header: 'Table__Cell--Number',
                    cell: 'Table__Cell--Number',
                },
            },
        ];

        const bounds = getMapExtentBounds(minesiteMapConfig.bounds);

        const customLayerGrouping = [
            {
                groupName: 'Menu universal',
                layerNames: [
                    MAP_LAYER_LABELS.background,
                    MAP_LAYER_LABELS.backgroundClientProvided,
                    MAP_LAYER_LABELS.roadPath,
                ],
            },
            {
                groupName: 'Haul Efficiency',
                layerNames: [
                    MAP_LAYER_LABELS.focusAreaLabels,
                    MAP_LAYER_LABELS.eventPaths,
                ],
            },
            {
                groupName: 'Geofence Menu',
                layerNames: [
                    MAP_LAYER_LABELS.speedLimitedZones,
                    MAP_LAYER_LABELS.materialMovement,
                    MAP_LAYER_LABELS.trackingRegions,
                    MAP_LAYER_LABELS.mineRegions,
                    MAP_LAYER_LABELS.geofenceLabels,
                ],
            },
        ];

        return (
            <React.Fragment>
                <SubHeader>
                    <SubHeader.Left>
                        <SubHeader.Title>{title}</SubHeader.Title>
                    </SubHeader.Left>
                </SubHeader>
                <Content>
                    <LoadingSpinner
                        isActive={spinner.isActive}
                        message={spinner.message}
                    />
                    <Row>
                        <Col span={24}>
                            <ProductivityFilters
                                filters={filters}
                                filterOptions={filterOptions}
                                onFilterChange={this.wrapOnFilterChange}
                            />
                        </Col>
                    </Row>
                    {!topEventsByLostTime.length && !spinner.isActive && (
                        <Row>
                            <Col span={24}>
                                There are no results available for this query.
                            </Col>
                        </Row>
                    )}
                    {topEventsByLostTime.length > 0 && (
                        <div>
                            <Row className="Productivity">
                                <Col xs={24} lg={16}>
                                    <MinesiteMap
                                        maxBounds={bounds}
                                        style={{ width: '100%', height: 570 }}
                                        viewport={
                                            minesiteMap.isViewportValid &&
                                            minesiteMap
                                        }
                                        onViewportChanged={onViewportChange}
                                    >
                                        <MinesiteMap.CustomLayersControl
                                            collapsed={true}
                                            position="topright"
                                            customLayerGrouping={
                                                customLayerGrouping
                                            }
                                            onOverlayAdd={this.handleOverlayAdd}
                                        >
                                            <DigitalGlobeBackground
                                                checked={minesiteMap.satellite}
                                            />
                                            <MinesiteMap.Background
                                                name={
                                                    MAP_LAYER_LABELS.backgroundClientProvided
                                                }
                                                checked={minesiteMap.aerial}
                                                url={
                                                    minesiteMapConfig.mapLayers
                                                        .clientProvided.url
                                                }
                                                options={
                                                    minesiteMapConfig.mapLayers
                                                        .clientProvided.options
                                                }
                                            />
                                            <MinesiteMap.RoadPath
                                                priority={1}
                                                checked={minesiteMap.roadPath}
                                                name={MAP_LAYER_LABELS.roadPath}
                                                data={getWKTResponseGeoJSON(
                                                    wktData.WKTLocuses,
                                                )}
                                            />
                                            <MinesiteMap.EventPaths
                                                priority={700}
                                                checked={minesiteMap.eventPaths}
                                                name={
                                                    MAP_LAYER_LABELS.eventPaths
                                                }
                                                data={topEventsByLostTime}
                                                highlightIndex={
                                                    selectedEventIndex
                                                }
                                                colorPicker={colorScale}
                                            />

                                            {truckLocation.longitude &&
                                                truckLocation.latitude && (
                                                    <MinesiteMap.MarkerPin
                                                        priority={4}
                                                        key={selectedEventIndex}
                                                        fill={{
                                                            outer: colorScale(
                                                                selectedEventIndex,
                                                            ),
                                                            inner: 'white',
                                                        }}
                                                        position={[
                                                            truckLocation.latitude,
                                                            truckLocation.longitude,
                                                        ]}
                                                        icon={
                                                            <Equipment.Icon
                                                                type={
                                                                    Equipment
                                                                        .Types
                                                                        .HaulTruck
                                                                }
                                                            />
                                                        }
                                                        animatePosition={false}
                                                    />
                                                )}
                                            <MinesiteMap.FocusAreaLabels
                                                priority={500}
                                                checked={
                                                    minesiteMap.focusAreaLabels
                                                }
                                                name={
                                                    MAP_LAYER_LABELS.focusAreaLabels
                                                }
                                                labels={forceSimulation}
                                            />
                                            <MinesiteMap.Polygons
                                                priority={1}
                                                checked={
                                                    minesiteMap.mineRegions
                                                }
                                                name={
                                                    MAP_LAYER_LABELS.mineRegions
                                                }
                                                borderColor={`#000000`}
                                                data={
                                                    mineRegions &&
                                                    mineRegions.features
                                                }
                                                fillOpacity={0}
                                                showLabel={
                                                    minesiteMap.geofenceLabels
                                                }
                                            />
                                            <MinesiteMap.Polygons
                                                priority={3}
                                                checked={
                                                    minesiteMap.materialMovement
                                                }
                                                name={
                                                    MAP_LAYER_LABELS.materialMovement
                                                }
                                                data={
                                                    materialMovement &&
                                                    materialMovement.features
                                                }
                                                fillOpacity={0.2}
                                                showLabel={
                                                    minesiteMap.geofenceLabels
                                                }
                                            />
                                            <MinesiteMap.Polygons
                                                priority={7}
                                                checked={
                                                    minesiteMap.speedRestrictedZones
                                                }
                                                name={
                                                    MAP_LAYER_LABELS.speedLimitedZones
                                                }
                                                data={
                                                    speedZonesGeojson &&
                                                    speedZonesGeojson.features
                                                }
                                                fillOpacity={0.2}
                                                showLabel={
                                                    minesiteMap.geofenceLabels
                                                }
                                            />
                                            <MinesiteMap.Polygons
                                                priority={15}
                                                checked={
                                                    minesiteMap.mineFeatures
                                                }
                                                name={
                                                    MAP_LAYER_LABELS.trackingRegions
                                                }
                                                data={
                                                    mineFeatures &&
                                                    mineFeatures.features
                                                }
                                                fillOpacity={0.2}
                                                showLabel={
                                                    minesiteMap.geofenceLabels
                                                }
                                            />
                                            <MinesiteMap.LayersControl.Overlay
                                                name={
                                                    MAP_LAYER_LABELS.geofenceLabels
                                                }
                                                checked={
                                                    minesiteMap.geofenceLabels
                                                }
                                            >
                                                <MinesiteMap.LayerGroup />
                                            </MinesiteMap.LayersControl.Overlay>
                                        </MinesiteMap.CustomLayersControl>

                                        <MapControlWithPopup
                                            hide={
                                                mapLegendSections.length === 0
                                            }
                                            onCloseClick={this.onCloseClick}
                                            onContentClick={this.onContentClick}
                                            popupVisible={this.state.showPopup}
                                            position={'bottomleft'}
                                            popupContent={() => (
                                                <Legend
                                                    stateSelector={
                                                        selectMapLegendState
                                                    }
                                                />
                                            )}
                                            content={this.BottomLeft}
                                        />
                                    </MinesiteMap>
                                    <Row>
                                        <Col md={16}>
                                            <HasFeature
                                                featureId={
                                                    FEATURE_IDS.LEAGUE__TABLE__SHOW_GEAR_LOCKDOWN
                                                }
                                            >
                                                <GearLockdown
                                                    shifts={gearLockdownShifts}
                                                />
                                            </HasFeature>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} lg={8}>
                                    {driverEventJsonError}
                                    {!eventIsSelected && (
                                        <ProductivityEventTable
                                            rows={topEventsByLostTime}
                                            cols={mainProdEventsTableCols}
                                            onTableRowHover={onTableRowHover}
                                        />
                                    )}
                                    {highlightedEventPathIndex !== null && (
                                        <div>
                                            <ProductivityEventTable
                                                cols={topFiveDriversTableCols}
                                                rows={topFiveDrivers.data}
                                                tableClassName="ProductivityEventTable--2Columns"
                                            />
                                            {!bottomFiveDrivers.isSingleTableMode && (
                                                <ProductivityEventTable
                                                    cols={
                                                        bottomFiveDriversTableCols
                                                    }
                                                    rows={
                                                        bottomFiveDrivers.data
                                                    }
                                                    tableClassName="ProductivityEventTable--2Columns"
                                                />
                                            )}
                                        </div>
                                    )}
                                    {!driverEventJsonError &&
                                        eventIsSelected &&
                                        formattedAveragedResults &&
                                        Object.keys(formattedAveragedResults)
                                            .length > 0 && (
                                            <div className="ProductivityCharts__container">
                                                <ProductivityCharts
                                                    data={
                                                        formattedAveragedResults
                                                    }
                                                    onHover={this.chartHover}
                                                    chartHeight={180}
                                                />
                                            </div>
                                        )}
                                </Col>
                            </Row>
                        </div>
                    )}
                </Content>
            </React.Fragment>
        );
    }
}

Productivity.propTypes = {
    formattedAveragedResults: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.shape({
                average: PropTypes.number,
                category: PropTypes.string,
                dCOMP_XY_Dist: PropTypes.number,
                displacement: PropTypes.number,
                frank: PropTypes.number,
                x: PropTypes.number,
                y: PropTypes.number,
                chartName: PropTypes.string,
                ticks: PropTypes.number,
                yExtent: PropTypes.arrayOf(PropTypes.number),
            }),
        ),
    ),
    topEventsByLostTime: PropTypes.arrayOf(
        PropTypes.shape({
            Heading: PropTypes.number,
            HeadingArrowCenterX: PropTypes.number,
            HeadingArrowCenterY: PropTypes.number,
            LeaderLinePos: PropTypes.string,
            LeaderLineX: PropTypes.number,
            LeaderLineY: PropTypes.number,
            ShiftId: PropTypes.number,
            ShiftProductivityEventId: PropTypes.number,
            TotalLostTime: PropTypes.number,
            WKTLoci: PropTypes.string,
            Id: PropTypes.number,
            sectionOpportunity: PropTypes.number,
        }),
    ),
    wktData: types.typeWktData,
    highlightedEventPathIndex: PropTypes.number,
    filters: PropTypes.shape({
        Date: PropTypes.string,
        Shift: PropTypes.string.isRequired,
        Worker: PropTypes.string.isRequired,
        Event: PropTypes.string.isRequired,
    }).isRequired,
    filterOptions: PropTypes.shape({
        shifts: PropTypes.array.isRequired,
        workers: PropTypes.array.isRequired,
        events: PropTypes.array.isRequired,
    }).isRequired,
    spinner: PropTypes.shape({
        isActive: PropTypes.bool.isRequired,
        message: PropTypes.string.isRequired,
    }).isRequired,
    updateFiltersWithUrlParams: PropTypes.func.isRequired,
    topFiveDrivers: PropTypes.shape({
        isSingleTableMode: PropTypes.bool.isRequired,
        data: PropTypes.arrayOf(
            types.typeShiftProductivityEvent,
            types.typeDriverShiftProductivityEvent,
        ),
    }),
    bottomFiveDrivers: PropTypes.shape({
        isSingleTableMode: PropTypes.bool.isRequired,
        data: PropTypes.arrayOf(
            types.typeShiftProductivityEvent,
            types.typeDriverShiftProductivityEvent,
        ),
    }),
    driverEventJsonError: PropTypes.string,
    speedLimitedZones: PropTypes.arrayOf(types.typeSpeedLimitedZoneRow),
    minesiteAreasTree: types.typeMinesiteAreaTree,
    topLevelWhereOnMinesiteIdDepth: PropTypes.number,
    gearLockdownShifts: PropTypes.arrayOf(types.typeGearLockdown),
    minesiteMapConfig: types.typeMinesiteMapConfig,
    title: PropTypes.string,
    // redux actions
    onFilterChange: PropTypes.func.isRequired,
    onTableRowHover: PropTypes.func.isRequired,
    init: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    filters: stateSelectors.getFilters(state),
    minesiteMap: stateSelectors.getMinesiteMapWithViewPort(state),
    filterOptions: stateSelectors.getFilterOptions(state),
    spinner: stateSelectors.getSpinnerState(state),
    wktData: stateSelectors.getWktData(state),
    topEventsByLostTime: stateSelectors.getTopEventsByLostTime(state, 5),
    formattedAveragedResults: stateSelectors.getFormattedAveragedResults(state),
    highlightedEventPathIndex: stateSelectors.getHighlightedEventPathIndex(
        state,
    ),
    topFiveDrivers: stateSelectors.getTopDriversForShiftProductivityEventId(
        state,
        TABLE_ROW_COUNT,
    ),
    bottomFiveDrivers: stateSelectors.getTopDriversForShiftProductivityEventId(
        state,
        -TABLE_ROW_COUNT,
    ),
    driverEventJsonError: stateSelectors.getDriverEventJsonError(state),
    speedLimitedZones: stateSelectors.getSpeedLimitedZones(state),
    minesiteAreasTree: stateSelectors.getMinesiteAreasTree(state),
    topLevelWhereOnMinesiteIdDepth: getTopLevelWhereOnMinesiteIdDepth(state),
    gearLockdownShifts: stateSelectors.getGearLockdown(state),
    minesiteMapConfig: getComponentConfig(state, 'MinesiteMap'),
    title: getPageTitle(state, FEATURE_IDS.TAB_ID__OVERALL_PRODUCTIVITY_EVENTS),
    speedZonesGeojson: productivitySelectors.selectSpeedZonesGeojson(state),
    materialMovement: productivitySelectors.selectMaterialMovementsGeojson(
        state,
    ),
    mineFeatures: productivitySelectors.selectTrackingRegionsGeojson(state),
    mineRegions: productivitySelectors.selectWomidsGeojson(state),
    mapLegendSections: mapLegendSelectors.selectVisibleSections(state),
});

const mapActionsToDispatch = {
    onFilterChange: actions.filterUpdated,
    onTableRowHover: actions.setHighlightedEventPathIndex,
    updateFiltersWithUrlParams: actions.updateFiltersWithUrlParams,
    onViewportChange: actions.mapViewportChanged,
    onMapFilterChange: actions.onMapLayerUpdated,
    flipLegendLayer: actions.mapLegendLayerFlip,
    init: actions.init,
};

const enhance = connect(mapStateToProps, mapActionsToDispatch);

export default enhance(Productivity);

// This page was originally called productivity but now called Haul Effeciency or Operator Effeciency
