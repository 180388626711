import * as React from 'react';

function SvgStatusUnexpectedLocation(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <circle cx={512} cy={512} r={498.84} fill="#fff" />
            <path d="M512 50a462.14 462.14 0 01179.82 887.71A462.13 462.13 0 01332.18 86.29 459.06 459.06 0 01512 50m0-50C229.23 0 0 229.23 0 512s229.23 512 512 512 512-229.23 512-512S794.77 0 512 0z" />
            <path d="M512 256a176.37 176.37 0 00-176.37 176.41c0 84 99.91 195.58 154.39 323.87 8.36 19.71 36.16 19.82 44.63.16 55.93-129.79 153.72-239.8 153.72-324A176.37 176.37 0 00512 256zm60.89 232.75a83.56 83.56 0 1121.8-52.63 83.15 83.15 0 01-21.8 52.67z" />
        </svg>
    );
}

export default SvgStatusUnexpectedLocation;
