import shiftUtils from '@rs/core/utils/shiftUtils';
import createActions from '../../Modules/filters/actions';
import { FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS } from '../../App/Actions';
import createReducer from '../../Modules/filters/reducer';
import createSelectors from '../../Modules/filters/selectors';
import namespace from './namespace';

const fetchPrivateSiteConfigurationSuccess = (state) => {
    const defaultDate = shiftUtils.newDefaultFilterDate();
    return {
        ...state,
        Date: defaultDate,
        EndDate: defaultDate,
    };
};

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].filters;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);
export const reducer = createReducer(
    {
        filterUpdated: actions.FILTER_UPDATED,
        setFiltersWithUrlParams: actions.SET_FILTERS_WITH_URL_PARAMS,
    },
    {
        [FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS]: fetchPrivateSiteConfigurationSuccess,
    },
    {
        Date: undefined,
        EndDate: undefined,
        Shift: '',
        rangeSelected: true,
    },
);
export const selectors = createSelectors(statePath);
