import * as React from 'react';

import markerHRCEvent from '../../images/markers/marker_hrc_event.png';
import { Row, Col } from '@rs/core/falcon';

export const ExistingHrcLegends = () => (
    <Col>
        <Row justify="start" align="middle">
            <Col>
                <div className="MinesiteMap__Layer__Container__RowIcon MinesiteMap__Legend__RowIcon--SurfaceRoughness-highest" />
            </Col>
            <Col>Extremely Rough</Col>
        </Row>
        <Row justify="start" align="middle">
            <Col>
                <div className="MinesiteMap__Layer__Container__RowIcon MinesiteMap__Legend__RowIcon--SurfaceRoughness-high" />
            </Col>
            <Col>Rough</Col>
        </Row>
        <Row justify="start" align="middle">
            <Col>
                <div className="MinesiteMap__Layer__Container__RowIcon MinesiteMap__Legend__RowIcon--SurfaceRoughness-medium" />
            </Col>
            <Col>
                <div>Moderate</div>
            </Col>
        </Row>

        <Row justify="start" align="middle">
            <Col>
                <div className="MinesiteMap__Layer__Container__RowIcon MinesiteMap__Legend__RowIcon--SurfaceRoughness-low" />
            </Col>
            <Col>
                <div>Light</div>
            </Col>
        </Row>

        <Row justify="start" align="middle">
            <Col>
                <div className="MinesiteMap__Layer__Container__RowIcon MinesiteMap__Legend__RowIcon--SurfaceRoughness-lowest" />
            </Col>
            <Col>
                <div>Smooth</div>
            </Col>
        </Row>
        <Row justify="start" align="middle">
            <Col>
                <img
                    className="MinesiteMap__Layer__Container__RowIcon"
                    src={markerHRCEvent}
                    alt="HRC Event"
                />
            </Col>
            <Col>
                <div>HRC Event</div>
            </Col>
        </Row>
    </Col>
);

export const Legends = () => {
    return (
        <div id="legend-section">
            <Row>
                <Col>
                    <ExistingHrcLegends />
                </Col>
            </Row>
        </div>
    );
};
