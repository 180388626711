/**
 * Returns the className for the matching band that the value fits in
 * @param {array} rangeBands
 * @param {number} value
 * @return {string}
 */
function getBandForValue(rangeBands, value) {
    if (!Array.isArray(rangeBands)) {
        console.log(
            `rangeBands should be of type Array, got: ${typeof rangeBands}`,
        );
        return '';
    }
    if (value === null) return '';
    return rangeBands.find(({ min, max }, index) => {
        return (
            value >= min &&
            (value < max || (value === max && index === rangeBands.length - 1))
        );
    });
}

/**
 * Returns the className for the matching band that the value fits in
 * @param {array} rangeBands
 * @param {number} value
 * @return {string}
 */
export const getConformanceClassName = (rangeBands, value) => {
    const rangeBand = getBandForValue(rangeBands, value);
    return (rangeBand && rangeBand.className) || '';
};

/**
 * Returns the className for the matching band that the value fits in
 * @param {array} rangeBands
 * @param {number} value
 * @return {string}
 */
export const getWallboardConformanceClassName = (rangeBands, value) => {
    const rangeBand = getBandForValue(rangeBands, value);
    return (rangeBand && rangeBand.wallboardClassName) || '';
};
