import { action } from '../../Lib/reduxUtils';
import { GoogleAnalyticsEventBuilder } from '../../Utils/userAnalytics/googleAnalyticsEventBuilder';

const NAMESPACE = 'LoaderLeague';

//TO-DO Define actions payload types
export const LOAD_LOADER_OPERATOR_LOGS = `${NAMESPACE}/LOAD_LOADER_OPERATOR_LOGS`;
export const loadLoaderOperatorLogs = (loaderOperatorLogs: any) =>
    action(LOAD_LOADER_OPERATOR_LOGS, loaderOperatorLogs);

export const LOAD_LOADING_EVENTS_CONFORMANCE_REPORT = `${NAMESPACE}/LOAD_LOADING_EVENTS_CONFORMANCE_REPORT`;
export const loadLoadingEvents = (loadingEvents: any) =>
    action(LOAD_LOADING_EVENTS_CONFORMANCE_REPORT, loadingEvents);

export const FETCH_LOAD_UNIT_CONFORMANCE_WEIGHTINGS_SUCCESS = `${NAMESPACE}/FETCH_LOAD_UNIT_CONFORMANCE_WEIGHTINGS_SUCCESS`;
export const fetchLoadUnitConformanceWeightingsSuccess = (
    conformanceWeightings: any,
) =>
    action(
        FETCH_LOAD_UNIT_CONFORMANCE_WEIGHTINGS_SUCCESS,
        conformanceWeightings,
    );

export const FILTER_UPDATED = `${NAMESPACE}/FILTER_UPDATED`;
export const filterUpdated = (filterName: any, filterValue: any) =>
    action(
        FILTER_UPDATED,
        { filterName, filterValue },
        {
            analytics: new GoogleAnalyticsEventBuilder(
                'SubFeatureUsage',
                'Filter Updated',
            )
                .setLabel(NAMESPACE)
                .build(),
        },
    );

export const SORT_TABLE = `${NAMESPACE}/SORT_TABLE`;
export const sortTable = (sortingKey: any) =>
    action(SORT_TABLE, { sortingKey });

export const UPDATE_FILTERS_WITH_URL_PARAMS = `${NAMESPACE}/UPDATE_FILTERS_WITH_URL_PARAMS`;
export const updateFiltersWithUrlParams = () =>
    action(
        UPDATE_FILTERS_WITH_URL_PARAMS,
        {},
        {
            analytics: new GoogleAnalyticsEventBuilder(
                'SubFeatureUsage',
                'Filter Updated',
            )
                .setLabel(NAMESPACE)
                .build(),
        },
    );

export const SET_FILTERS_WITH_URL_PARAMS = `${NAMESPACE}/SET_FILTERS_WITH_URL_PARAMS`;
export const setFiltersWithUrlParams = (filtersFromUrl: any) =>
    action(SET_FILTERS_WITH_URL_PARAMS, filtersFromUrl);

export const SET_SPINNER_STATE = `${NAMESPACE}/SET_SPINNER_STATE`;
export const setSpinnerState = (isActive = false, message = '', faIcon: any) =>
    action(SET_SPINNER_STATE, { isActive, message, faIcon });

export const LINK_TO_PAGE = `${NAMESPACE}/LINK_TO_PAGE`;
export const linkToPage = (payload: any) => action(LINK_TO_PAGE, payload);
