import React from 'react';
import PropTypes from 'prop-types';
import { roundTo } from '../../utils/mathHelpers';
import { typeConformanceLegendRangeBands } from '../../types';
import { getConformanceClassName } from './getConformanceClassName';

const ConformanceCell = ({
    conformanceValue,
    rangeBands,
    roundToPlace,
    className,
    nonAccessedValuePlaceholder,
    nonAccessedValueClassName,
    WrapperComponent,
    render,
}) => {
    const isConformanceValueNaN = Number.isFinite(conformanceValue);
    const isValueRounded = Number.isFinite(roundToPlace);

    const conformanceClassName =
        getConformanceClassName(rangeBands, conformanceValue) ||
        nonAccessedValueClassName;
    const classNames = `${conformanceClassName} ${className}`.trim();

    let formattedValue = conformanceValue;
    if (!isConformanceValueNaN) {
        formattedValue = nonAccessedValuePlaceholder;
    } else if (isValueRounded) {
        formattedValue = roundTo(conformanceValue, roundToPlace);
    }

    if (typeof render === 'function') {
        return render({
            WrapperComponent,
            formattedValue,
            conformanceValue,
            conformanceClassName,
        });
    }

    return (
        <WrapperComponent className={classNames}>
            {formattedValue}
        </WrapperComponent>
    );
};

ConformanceCell.defaultProps = {
    className: '',
    nonAccessedValuePlaceholder: '-',
    nonAccessedValueClassName: 'Table__Cell--ConformanceNonAccessed',
    WrapperComponent: 'td',
};

ConformanceCell.propTypes = {
    /** The value to display */
    conformanceValue: PropTypes.number,
    /** The value is tested against the supplied ranges to find a matching range */
    rangeBands: typeConformanceLegendRangeBands,
    /** Round value to this precision */
    roundToPlace: PropTypes.number,
    className: PropTypes.string,
    /** To render something custom */
    render: PropTypes.func,
    /** What to display if the value is not a number */
    nonAccessedValuePlaceholder: PropTypes.string,
    /** The class to apply when the value is not a number */
    nonAccessedValueClassName: PropTypes.string,
    /** Render the wrapping component as a particular tag or CustomComponent */
    WrapperComponent: PropTypes.oneOfType([
        // NOTE: if using string it must be a valid HTML type, eg. 'div'
        PropTypes.string,
        PropTypes.node,
        PropTypes.element,
    ]),
};

export default ConformanceCell;
