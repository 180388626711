import flattenTree from './flattenTree';

/**
 * Finds all the decendents of a set of WOMIDs
 *
 * eg. getChildrenOfNode(["BODDNGTN"]) = ["BODDNGTN", "NORTH000", "SOUTH000"]
 {
   "MinesiteAreas": {
     "WhereOnMinesiteId": "GLOBAL00",
     "Name": "Global",
     "Depth": 0,
     "Children": [
       {
         "WhereOnMinesiteId": "BODDNGTN",
         "Name": "Boddington",
         "Depth": 1,
         "Children": [
           {
             "WhereOnMinesiteId": "NORTH000",
             "Name": "North Pit",
             "Depth": 2,
             "Children": []
           },
           {
             "WhereOnMinesiteId": "SOUTH000",
             "Name": "South Pit",
             "Depth": 2,
             "Children": []
           }
         ]
       }
     ]
   }
 }
 *
 * @param {array} whereOnMinsiteIds - The list of WOMIDs to find their children
 * @param {object} minesiteAreasTree - The whole tree or a piece
 * @param {Function} outputFn - Format the value to end up in the result, by default the value is the WhereOnMinesiteId
 * @return {array}
 */
export default function getChildrenOfNode(
    whereOnMinsiteIds,
    minesiteAreasTree,
    outputFn = (row) => row.WhereOnMinesiteId,
) {
    const minesiteAreas = flattenTree(minesiteAreasTree, (node) => node);
    const results = whereOnMinsiteIds.reduce(
        (collectedWOMIDs, selectedWOMID) => {
            const selection = minesiteAreas.find(
                (row) => row.WhereOnMinesiteId === selectedWOMID,
            );
            if (!selection) return collectedWOMIDs;
            flattenTree(selection, (node) =>
                collectedWOMIDs.add(outputFn(node)),
            );
            return collectedWOMIDs;
        },
        new Set(),
    );
    return Array.from(results);
}
