/* eslint-disable */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '@rs/core/falcon';
import { Select } from '../../Components/Select';
import { DatepickerInputWithSiteConfig } from '../../Components/Datepicker';
import { FEATURE_IDS } from '../../Auth/featureIds';
import { HasFeature } from '../../Components/HasFeature';
import { connect } from 'react-redux';
import * as actions from '../Actions';
import getFilters from '../Selectors/filters/getFilters';
import { getFilterOptions } from '../Selectors/filters';
import arePropsEqual from '@rs/core/falcon/utils/arePropsEqual';

class LoaderReportsFilters extends React.PureComponent {
    constructor(props) {
        super(props);

        this.handleFilterChangeShift = this.props.onFilterChange.bind(
            null,
            'Shift',
        );
        this.handleFilterChangeWhereOnMinesiteId = this.props.onFilterChange.bind(
            null,
            'WhereOnMinesiteId',
        );
    }

    render() {
        const {
            handleFilterChangeShift,
            handleFilterChangeWhereOnMinesiteId,
        } = this;
        const { filters, filterOptions, onFilterChange } = this.props;

        return (
            <Row>
                <Col span={24}>
                    <Row>
                        <Col xs={24} md={12} lg={8}>
                            <DatepickerInputWithSiteConfig
                                startDate={filters.Date}
                                onFilterChange={onFilterChange}
                                label={'Date'}
                                allowModeSwitch={false}
                                rangeSelected={false}
                            />
                        </Col>
                        <Col xs={24} md={12} lg={8}>
                            <label htmlFor="filterShift">Shift</label>
                            <Select
                                id="filterShift"
                                name="Shift"
                                value={filters.Shift}
                                options={filterOptions.shifts}
                                onChange={handleFilterChangeShift}
                                onRemove={handleFilterChangeShift}
                                simpleValue={true}
                                multi={false}
                                clearable={false}
                                deleteRemoves={false}
                                backspaceRemoves={false}
                            />
                        </Col>
                        <HasFeature
                            featureId={
                                FEATURE_IDS.HAUL_ROAD_CONDITION__FILTER_WHERE_ON_MINESITE_ID
                            }
                        >
                            <Col xs={24} md={12} lg={8}>
                                <label htmlFor="filterShift">
                                    Areas to include in analysis
                                </label>
                                <Select
                                    id="filterWhereOnMinesiteId"
                                    name="WhereOnMinesiteId"
                                    value={filters.WhereOnMinesiteId}
                                    options={filterOptions.whereOnMinesiteIds}
                                    onChange={
                                        handleFilterChangeWhereOnMinesiteId
                                    }
                                    onRemove={
                                        handleFilterChangeWhereOnMinesiteId
                                    }
                                    simpleValue={true}
                                    multi={false}
                                />
                            </Col>
                        </HasFeature>
                    </Row>
                </Col>
            </Row>
        );
    }
}

LoaderReportsFilters.propTypes = {
    filters: PropTypes.shape({
        Date: PropTypes.string,
        Shift: PropTypes.string,
        IsLoaded: PropTypes.string,
        WhereOnMinesiteId: PropTypes.string,
    }).isRequired,
    filterOptions: PropTypes.shape({
        shifts: PropTypes.array.isRequired,
        isLoaded: PropTypes.array.isRequired,
        whereOnMinesiteIds: PropTypes.array.isRequired,
    }).isRequired,
    onFilterChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        filters: getFilters(state),
        filterOptions: getFilterOptions(state),
    };
};

const mapActionsToProps = {
    onFilterChange: actions.filterUpdated,
};

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(memo(LoaderReportsFilters, arePropsEqual));
