import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '@rs/core/falcon';
import { DatepickerInputWithSiteConfig } from '../../Components/Datepicker';
import { Select } from '../../Components/Select';

class O4ROperatorFilters extends Component {
    constructor(props) {
        super(props);

        this.handleFilterChangeShift = this.props.onFilterChange.bind(
            null,
            'Shift',
        );
        this.handleFilterChangeCrew = this.props.onFilterChange.bind(
            null,
            'Crews',
        );
        this.handleFilterChangeInWorkshop = this.props.onFilterChange.bind(
            null,
            'InWorkshop',
        );
        this.handleFilterChangeMaxMineAlertId = this.props.onFilterChange.bind(
            null,
            'MaxMineAlertId',
        );
        this.handleFilterChangeDateShortcut = this.props.onFilterChange.bind(
            null,
            'DateShortcut',
        );
    }

    render() {
        const { onFilterChange, filters, filterOptions } = this.props;

        return (
            <Row>
                <Col span={24}>
                    <Row>
                        <Col xs={24} md={8}>
                            <label htmlFor="filterDateShortcut">
                                Date Shortcuts
                            </label>
                            <Select
                                id="filterDateShortcut"
                                name="DateShortcut"
                                value={filters.DateShortcut}
                                options={filterOptions.DateShortcuts}
                                onChange={this.handleFilterChangeDateShortcut}
                                onRemove={this.handleFilterChangeDateShortcut}
                                simpleValue={true}
                                multi={false}
                            />
                        </Col>
                        <Col xs={24} md={8}>
                            <DatepickerInputWithSiteConfig
                                startDate={filters.Date}
                                endDate={filters.EndDate}
                                onFilterChange={onFilterChange}
                                label={'Date'}
                                allowModeSwitch={true}
                                rangeSelected={filters.rangeSelected}
                            />
                        </Col>
                        <Col xs={24} md={8}>
                            <label htmlFor="filterShift">Shift</label>
                            <Select
                                id="filterShift"
                                name="Shift"
                                value={filters.Shift}
                                options={filterOptions.Shifts}
                                onChange={this.handleFilterChangeShift}
                                onRemove={this.handleFilterChangeShift}
                                simpleValue={true}
                                multi={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} md={8}>
                            <label htmlFor="filterInWorkshop">
                                Workshop Filter
                            </label>
                            <Select
                                id="filterInWorkshop"
                                name="InWorkshop"
                                value={filters.InWorkshop}
                                options={filterOptions.InWorkshop}
                                onChange={this.handleFilterChangeInWorkshop}
                                onRemove={this.handleFilterChangeInWorkshop}
                                simpleValue={true}
                                multi={false}
                            />
                        </Col>
                        <Col xs={24} md={8}>
                            <label htmlFor="filterMaxMineAlertId">
                                Operator for Reliability Alert Code
                            </label>
                            <Select
                                id="filterMaxMineAlertId"
                                name="MaxMineAlertId"
                                value={filters.MaxMineAlertId}
                                options={filterOptions.MaxMineAlertIds}
                                onChange={this.handleFilterChangeMaxMineAlertId}
                                onRemove={this.handleFilterChangeMaxMineAlertId}
                                simpleValue={true}
                                multi={true}
                            />
                        </Col>
                        <Col xs={24} md={8}>
                            <label htmlFor="filterCrew">Crew</label>
                            <Select
                                id="filterCrew"
                                name="Crew"
                                value={filters.Crews}
                                options={filterOptions.Crews}
                                onChange={this.handleFilterChangeCrew}
                                onRemove={this.handleFilterChangeCrew}
                                simpleValue={true}
                                multi={true}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

O4ROperatorFilters.propTypes = {
    filters: PropTypes.shape({
        Date: PropTypes.string,
        EndDate: PropTypes.string,
        Shift: PropTypes.string,
        Crews: PropTypes.string,
        rangeSelected: PropTypes.bool,
        MaxMineAlertId: PropTypes.string,
        DateShortcut: PropTypes.string,
        InWorkshop: PropTypes.string,
    }).isRequired,
    filterOptions: PropTypes.shape({
        Shifts: PropTypes.array.isRequired,
        Crews: PropTypes.array.isRequired,
        MaxMineAlertIds: PropTypes.array.isRequired,
        InWorkshop: PropTypes.array.isRequired,
        DateShortcuts: PropTypes.array.isRequired,
    }).isRequired,
    onFilterChange: PropTypes.func.isRequired,
};

export default O4ROperatorFilters;
