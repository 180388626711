import { createReducer } from '../../Lib/reduxUtils';
import { LOAD_LOADING_EVENTS, FILTER_UPDATED } from '../Actions';

export const initialState = [];

export const setLoaderResults = (state, rows = []) => rows;

export const filterUpdated = () => [];

export default createReducer(initialState, {
    [LOAD_LOADING_EVENTS]: setLoaderResults,
    [FILTER_UPDATED]: filterUpdated,
});
