import React from 'react';
import { Select as AntSelect } from 'antd';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Select = ({ block, className, loading, ...props }) => {
    return (
        <AntSelect
            // Better aria support https://www.w3.org/TR/wai-aria-1.0/states_and_properties#aria-busy
            // Helps with automated testing
            aria-busy={loading}
            loading={loading}
            className={cn(
                {
                    'Select--Block': block,
                },
                className,
            )}
            {...props}
        />
    );
};

Select.propTypes = {
    ...AntSelect.propTypes,
    // Makes the select full width
    block: PropTypes.bool,
};

Select.Option = AntSelect.Option;

export default Select;
