import * as React from 'react';
import {
    selectBorderColorBySectionLabelAndItemLabel,
    selectFillColorBySectionLabelAndItemLabel,
    State,
} from './reduxUtil';
import { useSelector, RootStateOrAny } from 'react-redux';

export interface ItemPureProps {
    label: string;
    fillColor: string;
    borderColor: string;
    onClick?: () => void;
}
export const ItemPure = ({
    label,
    fillColor,
    borderColor,
    onClick,
}: ItemPureProps) => {
    return (
        <div className="Legend__Box__Item">
            <li>
                <span
                    onClick={onClick}
                    style={{
                        background: fillColor,
                        borderColor: borderColor,
                    }}
                />
                {label}
            </li>
        </div>
    );
};

export interface ItemProps {
    sectionLabel: string;
    itemLabel: string;
    stateSelector: (state: RootStateOrAny) => State;
}

export const Item = ({ stateSelector, sectionLabel, itemLabel }: ItemProps) => {
    const fillColor = useSelector((state) =>
        selectFillColorBySectionLabelAndItemLabel(
            sectionLabel,
            itemLabel,
        )(stateSelector(state)),
    );
    const borderColor = useSelector((state) =>
        selectBorderColorBySectionLabelAndItemLabel(
            sectionLabel,
            itemLabel,
        )(stateSelector(state)),
    );
    return (
        <ItemPure
            fillColor={fillColor}
            label={itemLabel}
            borderColor={borderColor}
        />
    );
};
