import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import {
    convertURLQueryStringToObject,
    updateURLQueryString,
} from '../Lib/queryStringUtils';
import { getMapFiltersToAPIParams } from '../MaxmineDiagnostics/DriverOnKeyOn/Selectors/filters';
import * as equipmentOperatorAlertEventsModule from '../MaxmineDiagnostics/DriverOnKeyOn/Modules/equipmentOperatorAlertEvents';
import * as equipmentActivityBasicModule from '../MaxmineDiagnostics/DriverOnKeyOn/Modules/equipmentActivityBasic';
import * as operatorsModule from '../MaxmineDiagnostics/DriverOnKeyOn/Modules/operators';
import * as equipmentIdentifiersModule from '../MaxmineDiagnostics/DriverOnKeyOn/Modules/equipmentIdentifiers';
import * as filtersModule from '../MaxmineDiagnostics/DriverOnKeyOn/Modules/filters';
import * as spinnerModule from '../MaxmineDiagnostics/DriverOnKeyOn/Modules/spinner';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import { fetchAndStoreResponse } from './utils';

export function* filterUpdated({ payload }) {
    const filters = yield select(filtersModule.selectors.getFilters);
    updateURLQueryString(filters);
    if (
        payload.filterName === 'EquipmentSiteName' ||
        payload.filterName === 'EquipmentOperatorName'
    ) {
        return;
    }

    yield put(
        spinnerModule.actions.setSpinnerState(
            true,
            LOADING_MESSAGES.LOADING__DATA,
        ),
    );
    yield call(fetchData);
    yield put(spinnerModule.actions.setSpinnerState(false));
}

export function* updateFiltersWithURLParams() {
    yield put(
        spinnerModule.actions.setSpinnerState(
            true,
            LOADING_MESSAGES.LOADING__DATA,
        ),
    );
    const urlFilterValues = convertURLQueryStringToObject();
    yield put(filtersModule.actions.setFiltersWithUrlParams(urlFilterValues));
    yield call(fetchData);
    yield put(spinnerModule.actions.setSpinnerState(false));
}

export function* fetchData() {
    const params = yield select(getMapFiltersToAPIParams);
    yield all([
        call(fetchAndStoreResponse, equipmentOperatorAlertEventsModule, params),
        call(fetchAndStoreResponse, equipmentActivityBasicModule, params),
        call(fetchAndStoreResponse, operatorsModule, params),
        call(fetchAndStoreResponse, equipmentIdentifiersModule, {
            ShiftId: params.shiftId,
        }),
    ]);
}

export default function* watch() {
    yield all([
        takeLatest(
            filtersModule.actions.UPDATE_FILTERS_WITH_URL_PARAMS,
            updateFiltersWithURLParams,
        ),
        takeLatest(filtersModule.actions.FILTER_UPDATED, filterUpdated),
    ]);
}
