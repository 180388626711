import { Row, State } from '../redux/redux';
import _ from 'lodash';

export const selectSelectedEquipmentClasses = (
    stateSelector: (state: any) => State,
) => (state: State) => stateSelector(state).selectedClasses;
export const selectSelectedEquipmentSubClasses = (
    stateSelector: (state: any) => State,
) => (state: State) => stateSelector(state).selectedSubClasses;
export const selectSelectedEquipmentModels = (
    stateSelector: (state: any) => State,
) => (state: State) => stateSelector(state).selectedModels;
export const selectSelectedRows = (
    stateSelector: (state: any) => State,
    dataSelector: (state: any) => Row[],
) => (globalState: any) => {
    const state = stateSelector(globalState);
    const data = dataSelector(globalState);
    return selectFilteredRows(state, data);
};

export const selectEquipmentModels = (
    stateSelector: (state) => State,
    dataSelector: (state: any) => Row[],
) => (globalState) => {
    let data = dataSelector(globalState);
    const selectedClasses = selectSelectedEquipmentClasses(stateSelector)(
        globalState,
    );
    if (selectedClasses.length !== 0) {
        data = data.filter((r) => selectedClasses.includes(r.EquipmentClass));
    }
    const selectedSubClasses = selectSelectedEquipmentSubClasses(stateSelector)(
        globalState,
    );
    if (selectedSubClasses.length !== 0) {
        data = data.filter((r) =>
            selectedSubClasses.includes(r.EquipmentSubClass),
        );
    }
    return _.uniq(data.map((r) => r.EquipmentModel)).map((r) => ({
        value: r,
        label: r,
    }));
};

export const selectEquipmentSubClasses = (
    stateSelector: (state) => State,
    dataSelector: (state: any) => Row[],
) => (globalState) => {
    let data = dataSelector(globalState);
    const selectedClasses = selectSelectedEquipmentClasses(stateSelector)(
        globalState,
    );
    if (selectedClasses.length !== 0) {
        data = data.filter((r) => selectedClasses.includes(r.EquipmentClass));
    }
    const selectedModels = selectSelectedEquipmentModels(stateSelector)(
        globalState,
    );
    if (selectedModels.length !== 0) {
        // restrict to only the valid sub classes for these models
        data = data.filter((r) => selectedModels.includes(r.EquipmentModel));
    }
    return _.uniq(data.map((r) => r.EquipmentSubClass))
        .map((r) => ({
            value: r,
            label: r,
        }))
        .filter((e) => e.value !== '' && e.label !== '');
};

export const selectEquipmentClasses = (
    stateSelector: (state) => State,
    dataSelector: (state: any) => Row[],
) => (globalState) => {
    let data = dataSelector(globalState);
    const selectedSubClasses = selectSelectedEquipmentSubClasses(stateSelector)(
        globalState,
    );
    if (selectedSubClasses.length !== 0) {
        data = data.filter((r) =>
            selectedSubClasses.includes(r.EquipmentSubClass),
        );
    }
    const selectedModels = selectSelectedEquipmentModels(stateSelector)(
        globalState,
    );
    if (selectedModels.length !== 0) {
        // restrict to only the valid sub classes for these models
        data = data.filter((r) => selectedModels.includes(r.EquipmentModel));
    }
    return _.uniq(data.map((r) => r.EquipmentClass)).map((r) => ({
        value: r,
        label: r,
    }));
};

export const selectFilteredRows = (state: State, allRows: Row[]) => {
    return allRows.filter((r) => {
        return (
            (state.selectedClasses.length === 0 ||
                state.selectedClasses.includes(r.EquipmentClass)) &&
            (state.selectedSubClasses.length === 0 ||
                state.selectedSubClasses.includes(r.EquipmentSubClass)) &&
            (state.selectedModels.length === 0 ||
                state.selectedModels.includes(r.EquipmentModel))
        );
    });
};
