import * as React from 'react';

function SvgStatusAlert(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <circle cx={512} cy={512} r={498.84} fill="#fff" />
            <path d="M512 50a462.14 462.14 0 01179.82 887.71A462.13 462.13 0 01332.18 86.29 459.06 459.06 0 01512 50m0-50C229.23 0 0 229.23 0 512s229.23 512 512 512 512-229.23 512-512S794.77 0 512 0z" />
            <rect x={477} y={226.22} width={70} height={383.21} rx={10} />
            <circle cx={512} cy={757.38} r={65.01} />
        </svg>
    );
}

export default SvgStatusAlert;
