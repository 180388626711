import * as React from 'react';

function SvgLoadUnitSymbolPayloadUnderload(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <path
                d="M154.791 759.558c7.48-3.995 19.264-6.81 37.783-7.946 7.559-13.423 39.725-24.034 80.415-26.528 8.685-15.399 49.696-27.084 98.83-27.084 26.697 0 51.827 3.541 70.363 9.616 37.071-12.15 103.655-12.15 140.726 0 18.536-6.075 43.665-9.616 70.363-9.616 49.133 0 90.145 11.685 98.83 27.084 40.69 2.494 72.856 13.105 80.415 26.528 16.773 1.03 27.881 3.435 35.23 6.848C891.85 688.352 927.57 572.865 974.904 412a20.077 20.077 0 0116.124 8.117c3.78 5.063 4.946 11.693 3.136 17.76L873.538 839.695c-2.533 8.479-10.374 14.305-19.26 14.305H170.73c-8.886 0-16.727-5.826-19.26-14.305L30.844 437.877c-1.81-6.067-.684-12.657 3.136-17.76 3.78-5.144 9.77-8.117 16.124-8.117 47.11 161.708 82.006 277.56 104.687 347.558zm19.786 57.875c5.577 14.842 9.176 22.262 10.8 22.262h647.305c2.464 0 6.789-7.267 12.974-21.803-59.042 2.54-578.64-.024-671.079-.459z"
                stroke="#000"
                strokeWidth={42.667}
                fill="#000"
                fillRule="nonzero"
            />
        </svg>
    );
}

export default SvgLoadUnitSymbolPayloadUnderload;
