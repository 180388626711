import { action } from '../Lib/reduxUtils';
import namespace from './Modules/namespace';

export const DAS_DATE_RANGE_CHANGED = `${namespace}/DATE_RANGE_CHANGED`;
export const DAS_TRACK_POSITION_CHANGED = `${namespace}/TRACK_POSITION_CHANGED`;
export const DAS_PLAYBACK_SPEED_CHANGED = `${namespace}/PLAYBACK_SPEED_CHANGED`;
export const DAS_PLAYBACK_START = `${namespace}/PLAYBACK_START`;
export const DAS_PLAYBACK_PAUSE = `${namespace}/PLAYBACK_PAUSE`;
export const DAS_SET_MACHINE_DATA = `${namespace}/SET_MACHINE_DATA`;
export const DAS_LOAD_MACHINE_DATA = `${namespace}/LOAD_MACHINE_DATA`;
export const DAS_SET_SELECTED_MACHINES = `${namespace}/SET_SELECTED_MACHINES`;
export const DAS_SET_HIGHLIGHTED_MACHINE = `${namespace}/SET_HIGHLIGHTED_MACHINE`;
export const DAS_LOAD_CHART_DATA = `${namespace}/LOAD_CHART_DATA`;
export const DAS_SET_AUTH_TOKEN = `${namespace}/SET_AUTH_TOKEN`;
export const DAS_DATA_LOADING = `${namespace}/DATA_LOADING`;
export const DAS_SET_DATA_INTERVAL = `${namespace}/SET_DATA_INTERVAL`;
export const DAS_SET_CATEGORIES = `${namespace}/SET_CATEGORIES`;
export const DAS_SET_PARAMETERS = `${namespace}/SET_PARAMETERS`;
export const DAS_LOAD_PARAMETERS = `${namespace}/LOAD_PARAMETERS`;
export const DAS_LOAD_LOCUS_DATA = `${namespace}/LOAD_LOCUS_DATA`;

export const DAS_PLAYBACK_ZOOM_IN = 'DAS_PLAYBACK_ZOOM_IN';
export const DAS_PLAYBACK_ZOOM_OUT = 'DAS_PLAYBACK_ZOOM_OUT';

export const DAS_ZOOMED_DATA_LOADING = `${namespace}/ZOOMED_DATA_LOADING`;
export const DAS_LOAD_ZOOMED_MACHINE_DATA = `${namespace}/LOAD_ZOOMED_MACHINE_DATA`;
export const DAS_LOAD_ZOOMED_CHART_DATA = `${namespace}/LOAD_ZOOMED_CHART_DATA`;
export const DAS_SET_ZOOMED_MACHINE_DATA = `${namespace}/SET_ZOOMED_MACHINE_DATA`;
export const DAS_SET_LOOP = `${namespace}/SET_LOOP`;
export const DAS_SET_ZOOM_RANGE = `${namespace}/SET_ZOOM_RANGE`;
export const DAS_SET_METRICS = `${namespace}/SET_METRICS`;
export const DAS_COLLAPSE_SIDER = `${namespace}/COLLAPSE_SIDER`;
export const DAS_SYNC_URL_TO_STATE = `${namespace}/SYNC_URL_TO_STATE`;
export const DAS_CLEAR_URL_PARAMS = `${namespace}/CLEAR_URL_PARAMS`;
export const DAS_FETCH_MAP_FEATURES = `${namespace}/DAS_FETCH_MAP_FEATURES`;
export const DAS_MAP_LEGEND_LAYER_FLIP = `${namespace}/MAP_LEGEND_LAYER_FLIP`;
export const mapLegendLayerFlip = (section, checked) => {
    return action(DAS_MAP_LEGEND_LAYER_FLIP, {
        sectionLabel: section,
        visibility: checked,
    });
};

export function dasClearUrlParams() {
    return action(DAS_CLEAR_URL_PARAMS);
}
export function dasFetchMapFeatures() {
    return action(DAS_FETCH_MAP_FEATURES);
}
export function dasDateRangeChanged(startDate, endDate) {
    return action(DAS_DATE_RANGE_CHANGED, {
        dateRange: {
            startDate,
            endDate,
        },
    });
}

export function dasTrackPositionChanged(trackPosition) {
    return action(DAS_TRACK_POSITION_CHANGED, { trackPosition });
}

export function dasPlaybackSpeedChanged(playbackSpeed) {
    return action(DAS_PLAYBACK_SPEED_CHANGED, { playbackSpeed });
}

export function dasSetMachineData(machines, interval) {
    return action(DAS_SET_MACHINE_DATA, {
        machines,
        interval,
    });
}

export function dasSetDataInterval(interval) {
    return action(DAS_SET_DATA_INTERVAL, {
        interval,
    });
}

export function dasLoadMachineData() {
    return action(DAS_LOAD_MACHINE_DATA);
}

export function dasLoadChartData(metric) {
    return action(DAS_LOAD_CHART_DATA, {
        metric,
    });
}

export function dasSetAuthToken(authToken) {
    return action(DAS_SET_AUTH_TOKEN, {
        authToken,
    });
}

export function dasPlaybackStart() {
    return action(DAS_PLAYBACK_START);
}

export function dasPlaybackPause() {
    return action(DAS_PLAYBACK_PAUSE);
}

export function dasDataLoading(loading) {
    return action(DAS_DATA_LOADING, { loading });
}

export function dasPlaybackZoomOut() {
    return action(DAS_PLAYBACK_ZOOM_OUT);
}

export function dasPlaybackZoomIn() {
    return action(DAS_PLAYBACK_ZOOM_IN);
}

export function dasSetCategories(categories) {
    return action(DAS_SET_CATEGORIES, {
        categories,
    });
}

export function dasSetParameters(parameters) {
    return action(DAS_SET_PARAMETERS, {
        parameters,
    });
}

export function dasLoadParameters() {
    return action(DAS_LOAD_PARAMETERS);
}

export function dasSyncUrlToState() {
    return action(DAS_SYNC_URL_TO_STATE);
}

export function dasSetSelectedMachines(selectedMachines) {
    return action(DAS_SET_SELECTED_MACHINES, {
        selectedMachines,
    });
}

export function dasSetHighlightedMachine(highlightedMachineId) {
    return action(DAS_SET_HIGHLIGHTED_MACHINE, {
        highlightedMachineId,
    });
}

export function dasZoomedDataLoading(loading, loaded) {
    return action(DAS_ZOOMED_DATA_LOADING, { loading, loaded });
}

export function dasLoadZoomedMachineData() {
    return action(DAS_LOAD_ZOOMED_MACHINE_DATA, {
        zoomed: true,
    });
}

export function dasLoadZoomedChartData() {
    return action(DAS_LOAD_ZOOMED_CHART_DATA, {
        zoomed: true,
    });
}

export function dasSetZoomedMachineData(machines, loaded) {
    return action(DAS_SET_ZOOMED_MACHINE_DATA, {
        machines,
        loaded,
    });
}

export function dasSetLoop(loop) {
    return action(DAS_SET_LOOP, {
        loop,
    });
}

export function dasSetZoomRange(zoomStart, zoomEnd) {
    return action(DAS_SET_ZOOM_RANGE, {
        zoomStart,
        zoomEnd,
    });
}

export function dasSetMetrics(metrics) {
    return action(DAS_SET_METRICS, {
        metrics,
    });
}

export function dasLoadLocusData() {
    return action(DAS_LOAD_LOCUS_DATA);
}

export function dasCollapseSider(collapsed) {
    return action(DAS_COLLAPSE_SIDER, { collapsed });
}
