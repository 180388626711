import { createSelector } from 'reselect';
import shiftUtils from '@rs/core/utils/shiftUtils';
import { getFilters } from './getFilters';
import { API_ARRAY_DELIMITER } from '../../../Api';

export const getMapFiltersToAPIParams = createSelector(
    getFilters,
    (filters) => {
        const params = {};
        const shiftIdsAllShifts = shiftUtils.generateShiftIdRange(
            filters.Date,
            filters.EndDate,
        );
        params.ShiftId = shiftIdsAllShifts.join(API_ARRAY_DELIMITER);
        return params;
    },
);
