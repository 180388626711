import { combineReducers } from 'redux';
import { reducer as filters } from '../Modules/filters';
import { reducer as spinner } from '../Modules/spinner';
import { reducer as parameterChecks } from '../Modules/parameterChecks';
import namespace from '../Modules/namespace';
import {
    moduleName as equipmentFilterName,
    reducer as equipmentFilterReducer,
} from '../Modules/equipmentFilters';

export const REDUCER_KEY = namespace;
export default combineReducers({
    filters,
    spinner,
    parameterChecks,
    [equipmentFilterName]: equipmentFilterReducer,
});
