import React from 'react';
import { Empty, Table as AntTable } from 'antd';

// Provide some default locale messages as this is no longer a default
// when Empty component was introduced
// https://github.com/ant-design/ant-design/commit/2164c581980bbf8749c38bcd888d9338e0242e33#diff-758e451768022f33b283c459e87ea46cL20
const Table = (props) => {
    return (
        <AntTable
            locale={{
                emptyText: <Empty />,
            }}
            {...props}
        />
    );
};

Table.propTypes = AntTable.propTypes;

export default Table;
