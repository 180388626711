import React from 'react';
import { connect } from 'react-redux';
import { getComponentConfig } from '../App/Selectors';

function withComponentConfig(componentConfigName, WrappedComponent) {
    const WithComponentConfig = (props) => <WrappedComponent {...props} />;

    WithComponentConfig.propTypes = WrappedComponent.propTypes;

    const mapStateToProps = (state) => {
        const config = getComponentConfig(state, componentConfigName);
        return { ...config };
    };

    return connect(mapStateToProps)(WithComponentConfig);
}

export default withComponentConfig;
