import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { FEATURE_IDS } from '../Auth/featureIds';
import { FEATURE_META } from '../Auth/featureMeta';
import appHistory from './createHistory';
import { LoadingSpinner } from '../Components/LoadingSpinner';
import Loadable from 'react-loadable';
import SicFleetPlanPage from '../Sic_FleetPlan/SicFleetPlanPage';
import SicFleetPulsePage from '../Sic_Fleet_Pulse/SicFleetPulsePage';
import { Layout } from '@rs/core/falcon';

// Enhancer function to create the react-loadable component with a loading spinner
function CreateLoadable(loader) {
    return Loadable({
        loader: () => loader,
        loading: () => <LoadingSpinner isActive={true} />,
    });
}

const Login = CreateLoadable(import('../Auth/Components/Login'));
const TyresAndSafety = CreateLoadable(
    import('../TyresAndSafety/Components/TyresAndSafety'),
);
const Productivity = CreateLoadable(
    import('../Productivity/Components/Productivity'),
);
const EnginePerformancePage = CreateLoadable(
    import('../EnginePerformance/Components/EnginePerformancePage'),
);
const EngineDiagnosticsPage = CreateLoadable(
    import('../EngineDiagnostics/Components/EngineDiagnosticsPage'),
);
const LeagueTablePage = CreateLoadable(
    import('../LeagueTable/Components/LeagueTablePage'),
);
const PrivateRoute = CreateLoadable(import('../Auth/Components/PrivateRoute'));
const PublicRoute = CreateLoadable(import('../Auth/Components/PublicRoute'));
const AccountSetupForm = CreateLoadable(
    import('../Auth/Components/AccountSetupForm'),
);
const O4ROperator = CreateLoadable(
    import('../O4ROperator/Components/O4ROperator'),
);
const LoaderLeagueTablePage = CreateLoadable(
    import('../LoaderLeague/Components/LoaderLeagueTablePage'),
);
const LoadsInRangeDetail = CreateLoadable(
    import('../LoadsInRangeDetail/Components/LoadsInRangeDetail'),
);
const BiasLoadPage = CreateLoadable(
    import('../BiasLoad/Components/BiasLoadPage'),
);
const BiasLoadDetailPage = CreateLoadable(
    import('../BiasLoad/Components/BiasLoadDetailPage'),
);
const MaxmineDiagnostics = CreateLoadable(
    import('../MaxmineDiagnostics/Components/MaxmineDiagnosticsPage'),
);
const HaulRoadCondition = CreateLoadable(
    import('../HaulRoadCondition/Components/HaulRoadCondition'),
);
const HaulTruckFillFactor = CreateLoadable(
    import('../HaulTruckFillFactor/Components/HaulTruckFillFactor'),
);
const LoadingTimeDetail = CreateLoadable(
    import('../LoadingTimeDetail/Components/LoadingTimeDetailPage'),
);
const Spreadsheets = CreateLoadable(
    import('../Downloads/Components/SpreadsheetsPage'),
);
const AppLanding = CreateLoadable(import('./Components/AppLanding'));
const TruckAlertsAnalysisPage = CreateLoadable(
    import('../TruckAlertsAnalysis/Components/TruckAlertsAnalysisPage'),
);
const AppPrivateConfig = CreateLoadable(
    import('./Components/AppPrivateConfig'),
);
const DataHealth = CreateLoadable(
    import('../DataHealth/Components/DataHealth'),
);
const ProductionDashboard = CreateLoadable(
    import('../ProductionDashboard/Components/ProductionDashboardPage'),
);
const ResultsDelay = CreateLoadable(
    import('../ResultsDelay/Components/ResultsDelayPage'),
);
const SiteGeofencesAndRulesPage = CreateLoadable(
    import('../SiteGeofencesAndRules'),
);
const MaxHubLiveMapPage = CreateLoadable(import('../MaxHubLiveMap'));
const ConfigurationServicePage = CreateLoadable(
    import('../ConfigurationService'),
);
const HardwareMonitoringPage = CreateLoadable(import('../HardwareMonitoring'));
const CreateUserPage = CreateLoadable(
    import('../CreateUser/Components/CreateUserPage'),
);
const EditUserPage = CreateLoadable(
    import('../EditUser/Components/EditUserPage'),
);
const ManageUserPage = CreateLoadable(
    import('../ManageUser/Components/ManageUserPage'),
);
const CreateGroupPage = CreateLoadable(
    import('../CreateGroup/Components/CreateGroupPage'),
);
const ManageGroupPage = CreateLoadable(
    import('../ManageGroups/Components/ManageGroupPage'),
);
const EditGroupPage = CreateLoadable(
    import('../EditGroup/Components/EditGroupPage'),
);
const PageLayout = CreateLoadable(import('../Components/Layout/PageLayout'));
const AssignGroupsPage = CreateLoadable(
    import('../AssignGroups/Components/AssignGroupsPage'),
);
const ResetPassword = CreateLoadable(
    import('../Auth/Components/ResetPassword'),
);

const ChooseNewPassword = CreateLoadable(
    import('../Auth/Components/ChooseNewPassword'),
);

const ControlRoomPage = CreateLoadable(
    import('../ActivityManagement/ControlRoom/ControlRoomPage'),
);

const EditCircuitsPage = CreateLoadable(
    import('../ActivityManagement/EditCircuits/Components/EditCircuitsPage'),
);
const EditHoldingStatesPage = CreateLoadable(
    import('../ActivityManagement/EditHoldingStates/EditHoldingStatesPage'),
);
const DASPage = CreateLoadable(import('../DAS/DASPage'));
const NetworkCoveragePage = CreateLoadable(
    import('../NetworkCoverage/NetworkCoveragePage'),
);
const PowerBiReportPage = CreateLoadable(
    import('../PowerBi/PowerBiReportsPage'),
);

const EditPowerBiUser = CreateLoadable(
    import('../PowerBi/Components/EditPowerBiForm'),
);
const SicMaterialMovementPage = CreateLoadable(
    import('../Sic_MaterialMovement/SicMaterialMovementPage'),
);
const SicTimeAccountingPage = CreateLoadable(
    import('../Sic_TimeAccounting/SicTimeAccountingPage'),
);
const SicPulsePage = CreateLoadable(import('../Sic_Pulse/SicPulsePage'));
const Result = CreateLoadable(import('../Components/Result/Result'));

const { SubLayout } = Layout;

// Pages that still have existing code but no longer exist.
const retiredPages = [
    FEATURE_IDS.TAB_ID__BIAS_LOAD,
    FEATURE_IDS.TAB_ID__LOADER_REPORTS,
];

// Export so we can unit test properly
export const privateRoutes = [
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__PRODUCTION_DASHBOARD].path,
        component: ProductionDashboard,
        featureId: FEATURE_IDS.TAB_ID__PRODUCTION_DASHBOARD,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__RESULTS_DELAY].path,
        component: ResultsDelay,
        featureId: FEATURE_IDS.TAB_ID__RESULTS_DELAY,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__TYRES_AND_SAFETY].path,
        component: TyresAndSafety,
        featureId: FEATURE_IDS.TAB_ID__TYRES_AND_SAFETY,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__LEAGUE_TABLE].path,
        component: LeagueTablePage,
        featureId: FEATURE_IDS.TAB_ID__LEAGUE_TABLE,
    },
    {
        path:
            FEATURE_META[FEATURE_IDS.TAB_ID__OVERALL_PRODUCTIVITY_EVENTS].path,
        component: Productivity,
        featureId: FEATURE_IDS.TAB_ID__OVERALL_PRODUCTIVITY_EVENTS,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__TRUCK_PERFORMANCE].path,
        component: EnginePerformancePage,
        featureId: FEATURE_IDS.TAB_ID__TRUCK_PERFORMANCE,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__TRUCK_DIAGNOSTICS].path,
        component: EngineDiagnosticsPage,
        featureId: FEATURE_IDS.TAB_ID__TRUCK_DIAGNOSTICS,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__O4R_OPERATOR].path,
        component: O4ROperator,
        featureId: FEATURE_IDS.TAB_ID__O4R_OPERATOR,
    },
    {
        path:
            FEATURE_META[FEATURE_IDS.TAB_ID__LOAD_UNIT_OPERATOR_LEAGUE_TABLE]
                .path,
        component: LoaderLeagueTablePage,
        featureId: FEATURE_IDS.TAB_ID__LOAD_UNIT_OPERATOR_LEAGUE_TABLE,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__LOADS_IN_RANGE_DETAIL].path,
        component: LoadsInRangeDetail,
        featureId: FEATURE_IDS.TAB_ID__LOADS_IN_RANGE_DETAIL,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__BIAS_LOAD].path,
        component: BiasLoadPage,
        featureId: FEATURE_IDS.TAB_ID__BIAS_LOAD,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__BIAS_LOAD_DETAIL].path,
        component: BiasLoadDetailPage,
        featureId: FEATURE_IDS.TAB_ID__BIAS_LOAD_DETAIL,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__MAXMINE_DIAGNOSTICS].path,
        component: MaxmineDiagnostics,
        featureId: FEATURE_IDS.TAB_ID__MAXMINE_DIAGNOSTICS,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__HAUL_ROAD_CONDITION].path,
        component: HaulRoadCondition,
        featureId: FEATURE_IDS.TAB_ID__HAUL_ROAD_CONDITION,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__HAUL_TRUCK_FILL_FACTOR].path,
        component: HaulTruckFillFactor,
        featureId: FEATURE_IDS.TAB_ID__HAUL_TRUCK_FILL_FACTOR,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__LOAD_UNIT_TIME_DETAIL].path,
        component: LoadingTimeDetail,
        featureId: FEATURE_IDS.TAB_ID__LOAD_UNIT_TIME_DETAIL,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__SELF_SERVICE].path,
        component: Spreadsheets,
        featureId: FEATURE_IDS.TAB_ID__SELF_SERVICE,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__TRUCK_ALERTS_ANALYSIS].path,
        component: TruckAlertsAnalysisPage,
        featureId: FEATURE_IDS.TAB_ID__TRUCK_ALERTS_ANALYSIS,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__DATA_HEALTH].path,
        component: DataHealth,
        featureId: FEATURE_IDS.TAB_ID__DATA_HEALTH,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__SITE_GEOFENCES_AND_RULES].path,
        component: SiteGeofencesAndRulesPage,
        featureId: FEATURE_IDS.TAB_ID__SITE_GEOFENCES_AND_RULES,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__MAX_HUB_LIVE_MAP].path,
        component: MaxHubLiveMapPage,
        featureId: FEATURE_IDS.TAB_ID__MAX_HUB_LIVE_MAP,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__CREATE_USER].path,
        component: CreateUserPage,
        featureId: FEATURE_IDS.TAB_ID__CREATE_USER,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__EDIT_DELETE_USER].path,
        component: EditUserPage,
        featureId: FEATURE_IDS.TAB_ID__EDIT_DELETE_USER,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__MANAGE_USER].path,
        component: ManageUserPage,
        featureId: FEATURE_IDS.TAB_ID__MANAGE_USER,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__CREATE_GROUP].path,
        component: CreateGroupPage,
        featureId: FEATURE_IDS.TAB_ID__CREATE_GROUP,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__MANAGE_GROUP].path,
        component: ManageGroupPage,
        featureId: FEATURE_IDS.TAB_ID__MANAGE_GROUP,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__EDIT_GROUP].path,
        component: EditGroupPage,
        featureId: FEATURE_IDS.TAB_ID__EDIT_GROUP,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__ASSIGN_GROUP].path,
        component: AssignGroupsPage,
        featureId: FEATURE_IDS.TAB_ID__ASSIGN_GROUP,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__TRUCK_ALLOCATION].path,
        component: ControlRoomPage,
        featureId: FEATURE_IDS.TAB_ID__TRUCK_ALLOCATION,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__LIVE_MAP].path,
        component: () => (
            <Redirect
                to={FEATURE_META[FEATURE_IDS.TAB_ID__MAX_HUB_LIVE_MAP].path}
            />
        ),
        featureId: FEATURE_IDS.TAB_ID__LIVE_MAP,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__CONFIGURATION_TOOL].path,
        component: ConfigurationServicePage,
        featureId: FEATURE_IDS.TAB_ID__CONFIGURATION_TOOL,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__HARDWARE_MONITORING].path,
        component: HardwareMonitoringPage,
        featureId: FEATURE_IDS.TAB_ID__HARDWARE_MONITORING,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__EDIT_CIRCUITS].path,
        component: EditCircuitsPage,
        featureId: FEATURE_IDS.TAB_ID__EDIT_CIRCUITS,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__EDIT_HOLDING_STATES].path,
        component: EditHoldingStatesPage,
        featureId: FEATURE_IDS.TAB_ID__EDIT_HOLDING_STATES,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__DAS].path,
        component: DASPage,
        featureId: FEATURE_IDS.TAB_ID__DAS,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__NETWORK_COVERAGE].path,
        component: NetworkCoveragePage,
        featureId: FEATURE_IDS.TAB_ID__NETWORK_COVERAGE,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__POWER_BI_REPORT_1].path,
        component: PowerBiReportPage,
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_1,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__POWER_BI_REPORT_2].path,
        component: PowerBiReportPage,
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_2,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__POWER_BI_REPORT_3].path,
        component: PowerBiReportPage,
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_3,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__POWER_BI_REPORT_4].path,
        component: PowerBiReportPage,
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_4,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__POWER_BI_REPORT_5].path,
        component: PowerBiReportPage,
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_5,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__POWER_BI_REPORT_6].path,
        component: PowerBiReportPage,
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_6,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__POWER_BI_REPORT_7].path,
        component: PowerBiReportPage,
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_7,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__POWER_BI_REPORT_8].path,
        component: PowerBiReportPage,
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_8,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__POWER_BI_REPORT_9].path,
        component: PowerBiReportPage,
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_9,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__POWER_BI_REPORT_10].path,
        component: PowerBiReportPage,
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_10,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__POWER_BI_REPORT_11].path,
        component: PowerBiReportPage,
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_11,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__POWER_BI_REPORT_12].path,
        component: PowerBiReportPage,
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_12,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__POWER_BI_REPORT_13].path,
        component: PowerBiReportPage,
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_13,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__POWER_BI_REPORT_14].path,
        component: PowerBiReportPage,
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_14,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__POWER_BI_REPORT_15].path,
        component: PowerBiReportPage,
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_15,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__POWER_BI_REPORT_16].path,
        component: PowerBiReportPage,
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_16,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__POWER_BI_REPORT_17].path,
        component: PowerBiReportPage,
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_17,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__POWER_BI_REPORT_18].path,
        component: PowerBiReportPage,
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_18,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__POWER_BI_REPORT_19].path,
        component: PowerBiReportPage,
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_19,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__POWER_BI_REPORT_20].path,
        component: PowerBiReportPage,
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_20,
    },
    {
        path: `${
            FEATURE_META[FEATURE_IDS.POWER_BI_REPORTS__ADMIN].path
        }/:id([1-9]|1[0-9]|20)`,
        component: EditPowerBiUser,
        featureId: FEATURE_IDS.POWER_BI_REPORTS__ADMIN,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__PULSE_TARGETS_UPLOAD].path,
        component: SicMaterialMovementPage,
        featureId: FEATURE_IDS.TAB_ID__PULSE_TARGETS_UPLOAD,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__TIME_ACCOUNTING].path,
        component: SicTimeAccountingPage,
        featureId: FEATURE_IDS.TAB_ID__TIME_ACCOUNTING,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__CIRCUIT_PULSE].path,
        component: SicPulsePage,
        featureId: FEATURE_IDS.TAB_ID__CIRCUIT_PULSE,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__FLEET_PULSE].path,
        component: SicFleetPulsePage,
        featureId: FEATURE_IDS.TAB_ID__FLEET_PULSE,
    },
    {
        path: FEATURE_META[FEATURE_IDS.TAB_ID__FLEET_PLAN_READ_ONLY].path,
        component: SicFleetPlanPage,
        featureId: FEATURE_IDS.TAB_ID__FLEET_PLAN_READ_ONLY,
    },
].filter((route) => retiredPages.indexOf(route.featureId) < 0);

const privateRouteComponents = privateRoutes.map((route) => (
    <PrivateRoute
        key={route.featureId}
        path={route.path}
        component={route.component}
        featureId={route.featureId}
    />
));

const Routes = () => (
    <Switch>
        <PublicRoute exact={true} path="/" component={Login} />
        <PublicRoute
            path={FEATURE_META[FEATURE_IDS.TAB_ID__LOGIN].path}
            component={Login}
        />
        <PublicRoute
            path={FEATURE_META[FEATURE_IDS.TAB_ID__APP_LANDING].path}
            component={AppLanding}
        />
        <PublicRoute path="/setup" component={AccountSetupForm} />
        <PublicRoute path="/reset-password" component={ResetPassword} />
        <PublicRoute
            path="/choose-new-password"
            component={ChooseNewPassword}
        />
        <AppPrivateConfig>
            <PageLayout>
                <SubLayout className={'Page__Background'}>
                    <SubLayout className={'Page__Content Drop__Shadow'}>
                        <Switch>
                            {[
                                ...privateRouteComponents,
                                <Route key="unauthorised" path="/unauthorised">
                                    <Result
                                        status="403"
                                        title="Unauthorised"
                                        subtitle="Sorry, you do not have access to this page"
                                    />
                                </Route>,
                                <Route key="notfound">
                                    <Result
                                        status="404"
                                        title="Not Found"
                                        subtitle="Sorry, the page you visited does not exist."
                                    />
                                </Route>,
                            ]}
                        </Switch>
                    </SubLayout>
                </SubLayout>
            </PageLayout>
        </AppPrivateConfig>
    </Switch>
);

export function withConnectedRouter(RoutesComponent, history) {
    return () => (
        <ConnectedRouter history={history}>
            <RoutesComponent />
        </ConnectedRouter>
    );
}

export default withConnectedRouter(Routes, appHistory);
