import React from 'react';
import { MAP_LAYER_LABELS, MinesiteMap } from '@rs/core/falcon';
import { connect } from 'react-redux';
import { getComponentConfig, getMinesiteId } from '../../App/Selectors';
import { MapLayer } from '../../Modules/minesiteMap/types';
import { getAuthenticatedUserId } from '../../Auth/Selectors/features';

// Duplicated site config types, not sure how the best way to export all the types
// is as they can conflict with each other
interface MinesiteMapProps {
    mapLayers?: MapLayers;
    defaultEnabledMapLayers: string[];
    allowedStatuses?: string[];
    bounds?: Bounds;
}

interface Bounds {
    LatitudeDomain: number[];
    LongitudeDomain: number[];
}

interface MapLayers {
    digitalGlobeGBM: DigitalGlobe;
    digitalGlobeVivid: DigitalGlobe;
    clientProvided: ClientProvided;
}

interface ClientProvided {
    url: string;
    options: {};
}

interface DigitalGlobe {
    url: string;
    options: DigitalGlobeGBMOptions;
}

interface DigitalGlobeGBMOptions {
    accessToken: string;
    format: string;
    featureProfile: string;
    layer: string;
    service: string;
    request: string;
    version: string;
    tileMatrixSet: string;
}

interface DigitalGlobeBackgroundProps {
    mapConfig: MinesiteMapProps;
    checked?: boolean;
    name?: string;
    priority?: number;
    shouldComponentUpdate?: () => boolean;
    minesiteId: string;
    userId: number;
}

const DigitalGlobeBackground = ({
    mapConfig,
    checked,
    name = MAP_LAYER_LABELS.background,
    priority,
    shouldComponentUpdate,
    minesiteId,
    userId,
    ...injectedProps
}: DigitalGlobeBackgroundProps) => {
    const backgroundIsGBM = mapConfig.defaultEnabledMapLayers.includes(
        MapLayer.DigitalGlobeGBM,
    );

    const commonProps = {
        ...injectedProps,
        priority,
        name,
        checked,
        url: mapConfig.mapLayers?.digitalGlobeGBM.url,
        shouldComponentUpdate,
    };

    const usageDetails = {
        mmAppId: 'reporting',
        mmMinesiteId: minesiteId,
        mmUserId: userId,
    };

    if (backgroundIsGBM) {
        return (
            <MinesiteMap.Background
                {...commonProps}
                url={mapConfig.mapLayers?.digitalGlobeGBM.url}
                options={{
                    ...mapConfig.mapLayers?.digitalGlobeGBM.options,
                    ...usageDetails,
                }}
            />
        );
    }

    return (
        <MinesiteMap.Background
            {...commonProps}
            url={mapConfig.mapLayers?.digitalGlobeVivid.url}
            options={{
                ...mapConfig.mapLayers?.digitalGlobeVivid.options,
                ...usageDetails,
            }}
        />
    );
};

const mapState = (state: any) => {
    return {
        mapConfig: getComponentConfig(state, 'MinesiteMap'),
        minesiteId: getMinesiteId(state),
        userId: getAuthenticatedUserId(state),
    };
};

export default connect(mapState)(DigitalGlobeBackground);
