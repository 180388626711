import { createSelector } from 'reselect';
import * as networkCoverageReportModule from '../Modules/networkCoverageReport';

const getError = createSelector(
    networkCoverageReportModule.selectors.getAll,
    (netWorkCoverageState) => {
        const { error } = netWorkCoverageState;
        return error;
    },
);
export default getError;
