import { IGoogleAnalyticsEvent, TAnalyticsCategories } from './events';
import { isActionNameValid, TActionNames } from './events';

/**
 * Builder class for creating Google Analytics events.
 * @class
 */
export class GoogleAnalyticsEventBuilder<T extends TAnalyticsCategories> {
    /**
     * @private
     * @type {IGoogleAnalyticsEvent}
     */
    private event: IGoogleAnalyticsEvent;

    /**
     * Creates an instance of GoogleAnalyticsEventBuilder.
     * @constructor
     * @param {TAnalyticsCategories} category - The event category.
     * @param {string} action - The event action.
     */
    constructor(category: T, action: TActionNames<T>) {
        {
            if (!isActionNameValid(category, action)) {
                throw new Error(
                    `Invalid action name '${action}' for category '${category}'.`,
                );
            }
        }
        this.event = {
            category,
            action,
        };
    }

    /**
     * Sets the label for the event.
     * @param {string} label - The event label, example is the feature name
     * @returns {this} The current instance for method chaining.
     */
    setLabel(label: string): this {
        this.event.label = label;
        return this;
    }

    /**
     * Sets the value for the event.
     * @param {number} value - The event value.
     * @returns {this} The current instance for method chaining.
     */
    setValue(value: number): this {
        this.event.value = value;
        return this;
    }

    /**
     * Sets whether the event is non-interaction.
     * @param {boolean} nonInteraction - Whether the event is non-interaction.
     * @returns {this} The current instance for method chaining.
     */
    setNonInteraction(nonInteraction: boolean): this {
        this.event.nonInteraction = nonInteraction;
        return this;
    }

    /**
     * Sets the transport method for the event.
     *
     * @param {'xhr' | 'beacon' | 'image'} transport - The transport method.
     * @returns {this} The current instance for method chaining.
     */
    setTransport(transport: 'xhr' | 'beacon' | 'image'): this {
        this.event.transport = transport;
        return this;
    }

    /**
     * Builds the final Google Analytics event object.
     * @returns {IGoogleAnalyticsEvent} The constructed event object.
     */
    build(): IGoogleAnalyticsEvent {
        return this.event;
    }
}
