import flattenTree from './flattenTree';

/**
 * Gets all the results that are descendants of the supplied level depth including the level depth
 * @param {object} minesiteAreasTree - The response from minesiteAreasTree
 * @param {number} topLevelWhereOnMinesiteIdDepth - The level to filter results from
 * @return {array}
 */
export default function filterOutTopLevelWOMIDs(
    minesiteAreasTree,
    topLevelWhereOnMinesiteIdDepth,
) {
    const flatMinesiteAreasArray = flattenTree(minesiteAreasTree);
    return flatMinesiteAreasArray.filter(
        (row) => row.Depth >= topLevelWhereOnMinesiteIdDepth,
    );
}
