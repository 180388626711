import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_WORST_CORNERS_SUCCESS = `${namespace}/FETCH_WORST_CORNERS_SUCCESS`;
    const fetchWorstCornersSuccess = (resultsArray) =>
        action(FETCH_WORST_CORNERS_SUCCESS, resultsArray);

    const FETCH_WORST_CORNERS_ERROR = `${namespace}/FETCH_WORST_CORNERS_ERROR`;
    const fetchWorstCornersError = ({ error }) =>
        action(FETCH_WORST_CORNERS_ERROR, { error });

    return {
        FETCH_WORST_CORNERS_SUCCESS,
        fetchWorstCornersSuccess,
        FETCH_WORST_CORNERS_ERROR,
        fetchWorstCornersError,
    };
}
