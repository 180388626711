import { put, select, call, takeLatest, all } from 'redux-saga/effects';
import shiftUtils from '@rs/core/utils/shiftUtils';
import {
    actions as filtersActions,
    selectors as filtersSelectors,
} from '../DataHealth/Modules/filters';
import { actions as spinnerActions } from '../DataHealth/Modules/spinner';
import { actions as driversActions } from '../DataHealth/Modules/drivers';
import {
    convertURLQueryStringToObject,
    updateURLQueryString,
} from '../Lib/queryStringUtils';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import * as resourceSagas from './resourceSagas';

export function* getDrivers(shiftId) {
    const { response, error } = yield resourceSagas.getDrivers({
        ShiftId: shiftId,
    });
    if (error) {
        yield put(driversActions.fetchDriversSuccess([]));
        return;
    }

    // Dispatch results
    yield put(driversActions.fetchDriversSuccess(response));
}

export function* filterUpdated({ payload }) {
    const filters = yield select(filtersSelectors.getFilters);
    // If dates are invalid go back to waiting for FILTER_UPDATED
    if (
        !shiftUtils.isDateStringValid(
            filters.Date,
            shiftUtils.DATE_FORMAT__VALIDATE,
        )
    ) {
        return;
    }

    const shiftId = shiftUtils.getShiftIdFromDateString(
        filters.Date,
        filters.Shift,
    );

    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    if (payload.filterName === 'Date' || payload.filterName === 'Shift') {
        yield call(getDrivers, shiftId);
    }
    updateURLQueryString(filters);
    yield put(spinnerActions.setSpinnerState(false));
}

export function* updateFiltersWithURLParams() {
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const urlFilterValues = convertURLQueryStringToObject();
    yield put(filtersActions.setFiltersWithUrlParams(urlFilterValues));
    const state = yield select();
    const filters = filtersSelectors.getFilters(state);
    const shiftId = shiftUtils.getShiftIdFromDateString(
        filters.Date,
        filters.Shift,
    );
    yield call(getDrivers, shiftId);
    yield put(spinnerActions.setSpinnerState(false));
}

export default function* watch() {
    yield all([
        takeLatest(
            filtersActions.UPDATE_FILTERS_WITH_URL_PARAMS,
            updateFiltersWithURLParams,
        ),
        takeLatest(filtersActions.FILTER_UPDATED, filterUpdated),
    ]);
}
