import { AUTH_REDUCER_KEY } from '../Reducers';
import { FEATURE_META } from '../featureMeta';
import { FEATURE_CATEGORY_IDS } from '../featureIds';
import { ROLE__ADMINSTRATOR } from '../roleIds';

/**
 * Selects the features array from state
 * @param {object} state
 * @return {Array}
 */
export const getFeaturesState = (state) =>
    state[AUTH_REDUCER_KEY].decodedToken &&
    state[AUTH_REDUCER_KEY].decodedToken.features
        ? state[AUTH_REDUCER_KEY].decodedToken.features
        : [];

/**
 * Gets the logged in user's available navbar options
 * @param {object} state
 * @return {array}
 */
export const getNavbarTabs = (state) => {
    const featureIds = getFeaturesState(state);
    return featureIds
        .map((featureId) => FEATURE_META[featureId])
        .filter(
            (feature) =>
                feature && feature.categoryId === FEATURE_CATEGORY_IDS.NAVBAR,
        );
};

/**
 * Determines if the logged in user can access a feature
 * @param {object} state
 * @param {number} featureId
 * @return {boolean}
 */
export const canAccessFeature = (state, featureId) => {
    // TODO temp working around some selectors expecting global/local state
    if (state.features) {
        return state.features.indexOf(featureId) > -1;
    } else if (state.decodedToken && state.decodedToken.features) {
        return state.decodedToken.features.indexOf(featureId) > -1;
    }
    const featureIds = getFeaturesState(state);
    return featureIds.indexOf(featureId) > -1;
};

/**
 * Returns the users first and last name as a string
 * @param {object} state
 * @return {string}
 */
export const getUserFullName = (state) => {
    return `${state[AUTH_REDUCER_KEY].decodedToken.site_details.FirstName} ${state[AUTH_REDUCER_KEY].decodedToken.site_details.LastName}`;
};

export const getAuthenticatedUserId = (state) => {
    return state[AUTH_REDUCER_KEY].decodedToken.user_id;
};

export const getAuthenticatedToken = (state) => {
    return state[AUTH_REDUCER_KEY].token;
};

export const getAuthenticatedRefreshToken = (state) => {
    return state[AUTH_REDUCER_KEY].refreshToken;
};

export const selectCanImpersonate = (state) => {
    return (
        state[AUTH_REDUCER_KEY].decodedToken.role_ids.indexOf(
            ROLE__ADMINSTRATOR,
        ) !== -1
    );
};

export const selectIsImpersonating = (state) => {
    return (
        state[AUTH_REDUCER_KEY].decodedToken.sudo === true &&
        state[AUTH_REDUCER_KEY].decodedToken.sudoer != null
    );
};

export const selectCurrentImpersonatingUser = (state) => {
    return state[AUTH_REDUCER_KEY].decodedToken.email;
};

export const selectCurrentImpersonatingCaller = (state) => {
    return state[AUTH_REDUCER_KEY].decodedToken.sudoer;
};
