import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { schemeCategory10, scaleOrdinal } from 'd3-scale';
import * as types from '@rs/core/types';
import { ConformanceCell } from '@rs/core/components';
import {
    Row,
    Col,
    MinesiteMap,
    MAP_LAYER_LABELS,
    getMapExtentBounds,
    CONSTANTS,
} from '@rs/core/falcon';
import { wktAggregate } from '@rs/core/application';
import { connect } from 'react-redux';
import OperatorFeedbackProductivityModal from './OperatorFeedbackProductivityModal';
import OperatorFeedbackProductivityEventButton from './OperatorFeedbackProductivityEventButton';
import withComponentConfig from '../../HOC/withComponentConfig';
import { getComponentConfig } from '../../App/Selectors';
import DigitalGlobeBackground from '../../Components/DigitalGlobeBackground/DigitalGlobeBackground';
import { getEnabledBackgroundLayers } from '../../Modules/minesiteMap/utils';

const { getWKTResponseGeoJSON } = wktAggregate;

const ConformanceCellWithConfig = withComponentConfig(
    'ConformanceDefault',
    ConformanceCell,
);

class OperatorFeedbackProductivity extends Component {
    constructor(props) {
        super(props);
        this.colorScale = scaleOrdinal(schemeCategory10);
        this.state = {
            highlightedEventIndex: null,
            isModalOpen: false,
            selectedEvent: 0,
        };

        this.handleEventButtonHover = this.handleEventButtonHover.bind(this);
        this.openModal = this.toggleIsisModalOpen.bind(this, true);
        this.closeModal = this.toggleIsisModalOpen.bind(this, false);
        this.setSelectedEvent = this.setSelectedEvent.bind(this);
    }

    setSelectedEvent(eventIndex) {
        this.setState({
            ...this.state,
            selectedEvent: eventIndex,
        });
    }

    toggleIsisModalOpen(isOpen) {
        const selectedEventIndex = this.state.highlightedEventIndex || 0;
        this.setState({
            ...this.state,
            isModalOpen: isOpen,
            selectedEvent: selectedEventIndex,
        });
    }

    handleEventButtonHover(eventIndex) {
        this.setState({
            highlightedEventIndex: eventIndex,
        });
    }

    render() {
        const {
            colorScale,
            handleEventButtonHover,
            closeModal,
            openModal,
            setSelectedEvent,
        } = this;

        const {
            wktData,
            worstDriverEvents,
            driverEventJsons,
            errorMessage,
            tyresAndSafety,
            mapFeatures,
            minesiteAreasTree,
            topLevelWhereOnMinesiteIdDepth,
            minesiteMapConfig,
        } = this.props;

        const {
            highlightedEventIndex,
            isModalOpen,
            selectedEvent,
        } = this.state;

        if (!worstDriverEvents.length || errorMessage) return null;
        const [worstDriverEvent, secondWorstDriverEvent] = worstDriverEvents;

        const bounds = getMapExtentBounds(minesiteMapConfig.bounds);

        return (
            <React.Fragment>
                <Row style={{ fontSize: '2rem' }}>
                    <Col span={24}>
                        <ConformanceCellWithConfig
                            conformanceValue={
                                tyresAndSafety &&
                                tyresAndSafety.ProductivityConformance
                            }
                            roundToPlace={1}
                            render={({
                                formattedValue,
                                conformanceClassName,
                            }) => (
                                <div
                                    className={`OperatorFeedback__ComformanceCell ${conformanceClassName}`}
                                >
                                    &nbsp;Operator Efficiency {formattedValue}%
                                </div>
                            )}
                        />
                    </Col>
                </Row>
                <Row vgutter={CONSTANTS.spacing.md}>
                    <Col span={24}>
                        <div className="MineSiteMapContainer">
                            <MinesiteMap
                                maxBounds={bounds}
                                style={{ width: '100%', height: 570 }}
                                onOverlayAdd={this.handleOverlayAdd}
                                onOverlayRemove={this.handleOverlayRemove}
                            >
                                <MinesiteMap.LayersControl
                                    collapsed={true}
                                    position="topright"
                                >
                                    <DigitalGlobeBackground
                                        checked={
                                            getEnabledBackgroundLayers(
                                                minesiteMapConfig,
                                            ).background
                                        }
                                    />
                                    <MinesiteMap.Background
                                        name={
                                            MAP_LAYER_LABELS.backgroundClientProvided
                                        }
                                        checked={
                                            getEnabledBackgroundLayers(
                                                minesiteMapConfig,
                                            ).backgroundClientProvided
                                        }
                                        url={
                                            minesiteMapConfig.mapLayers
                                                .clientProvided.url
                                        }
                                        options={
                                            minesiteMapConfig.mapLayers
                                                .clientProvided.options
                                        }
                                    />
                                    <MinesiteMap.RoadPath
                                        priority={1}
                                        checked={true}
                                        name={MAP_LAYER_LABELS.roadPath}
                                        data={getWKTResponseGeoJSON(
                                            wktData.WKTLocuses,
                                        )}
                                    />
                                    <MinesiteMap.EventPaths
                                        priority={3}
                                        checked={true}
                                        name={MAP_LAYER_LABELS.eventPaths}
                                        data={worstDriverEvents}
                                        highlightIndex={highlightedEventIndex}
                                        colorPicker={colorScale}
                                    />
                                </MinesiteMap.LayersControl>
                            </MinesiteMap>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        <OperatorFeedbackProductivityEventButton
                            eventIndex={0}
                            onClick={openModal}
                            onMouseEnter={handleEventButtonHover}
                            onMouseLeave={handleEventButtonHover}
                            buttonColor={colorScale(0)}
                            totalLostTime={worstDriverEvent.TotalLostTime}
                            totalPasses={worstDriverEvent.PassCount}
                        />
                    </Col>
                    <Col xl={12}>
                        <OperatorFeedbackProductivityEventButton
                            eventIndex={1}
                            onClick={openModal}
                            onMouseEnter={handleEventButtonHover}
                            onMouseLeave={handleEventButtonHover}
                            buttonColor={colorScale(1)}
                            totalLostTime={secondWorstDriverEvent.TotalLostTime}
                            totalPasses={secondWorstDriverEvent.PassCount}
                        />
                    </Col>
                </Row>
                <OperatorFeedbackProductivityModal
                    wktData={wktData}
                    isModalOpen={isModalOpen}
                    onModalClose={closeModal}
                    worstDriverEvents={worstDriverEvents}
                    driverEventJsons={driverEventJsons}
                    selectedEventIndex={selectedEvent}
                    onEventSelected={setSelectedEvent}
                    mapFeatures={mapFeatures}
                    minesiteAreasTree={minesiteAreasTree}
                    topLevelWhereOnMinesiteIdDepth={
                        topLevelWhereOnMinesiteIdDepth
                    }
                    minesiteMapConfig={minesiteMapConfig}
                />
            </React.Fragment>
        );
    }
}

OperatorFeedbackProductivity.defaultProps = {};

OperatorFeedbackProductivity.propTypes = {
    tyresAndSafety: types.tyresAndSafetyRow,
    worstDriverEvents: PropTypes.arrayOf(PropTypes.shape({})),
    wktData: types.typeWktData,
    driverEventJsons: PropTypes.shape({}),
    errorMessage: PropTypes.string,
    mapFeatures: PropTypes.arrayOf(types.typeMapFeature),
    minesiteAreasTree: types.typeMinesiteAreaTree,
    topLevelWhereOnMinesiteIdDepth: PropTypes.number,
    minesiteMapConfig: types.typeMinesiteMapConfig,
};

const mapState = (state) => ({
    overrides: getComponentConfig(state, 'OperatorFeedbackProductivity'),
});

export default connect(mapState)(OperatorFeedbackProductivity);
