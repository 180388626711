import React from 'react';
import PropTypes from 'prop-types';
import TableChevron from '../../TableChevron';
import { typeTableColumn } from './tablePropTypes';

const LeagueTableHeaderCell = ({
    column,
    index,
    predicate,
    currentOrder,
    onColumnHeaderClick,
}) => (
    <th
        key={`${index}-${column.key}`}
        onClick={
            onColumnHeaderClick
                ? onColumnHeaderClick.bind(null, column.key)
                : null
        }
        className={column.className || ''}
        data-rh={column.tooltip}
    >
        {column.label}
        {onColumnHeaderClick && (
            <TableChevron
                match={column.key}
                predicate={predicate}
                currentOrder={currentOrder}
            />
        )}
        {!isNaN(column.weighting) && (
            <span>
                <br />
                {`${Math.round(column.weighting)}%`}
            </span>
        )}
    </th>
);

LeagueTableHeaderCell.propTypes = {
    column: typeTableColumn,
    index: PropTypes.number.isRequired,
    predicate: PropTypes.bool.isRequired,
    currentOrder: PropTypes.string.isRequired,
    onColumnHeaderClick: PropTypes.func.isRequired,
};

export default LeagueTableHeaderCell;
