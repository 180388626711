import React, { Component, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    typeLastShiftClassificationTotals,
    typePDFConfig,
    typeSpinner,
} from '@rs/core/types';
import { Layout, Row, Col, Button } from '@rs/core/falcon';
import { FEATURE_IDS } from '@rs/core/features';
import PrinterIcon from '@ant-design/icons/PrinterOutlined';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import HaulRoadConditionFilters from './HaulRoadConditionFilters';
import { getSpinnerState } from '../Selectors/spinner';
import * as actions from '../Actions';
import { getErrorState } from '../Selectors/ui';
import HaulRoadConditionLastShift from './HaulRoadConditionLastShift';
import { selectors as haulRoadConditionSummaryLatestSelectors } from '../Modules/haulRoadConditionSummaryLatest';
import { actions as minesiteMapActions } from '../Modules/minesiteMap';
import HRCMap from './HRCMap';
import HRCChart from './HRCChart';
import { getPageTitle, getPDFSiteConfig } from '../../App/Selectors';
import PageControlsContainer from '../../Components/PageControlsContainer';
import { Legends } from './HRCMapLegends';
import {
    actions as mapFeatureActions,
    selectors as mapFeatureSelectors,
} from '../Modules/mapFeatures';
import arePropsEqual from '@rs/core/falcon/utils/arePropsEqual';

const { SubHeader, Content } = Layout;

/* eslint-disable react/prop-types */
class HaulRoadCondition extends Component {
    componentDidMount() {
        this.props.updateFiltersWithUrlParams();
    }

    componentWillUnmount() {
        this.props.resetMapSettings();
    }

    handlePDFButtonClick = () => {
        const { printPDFMap, pdfConfig } = this.props;
        printPDFMap({
            endpoint: pdfConfig.renderEndpoint,
            params: {
                url: window.location.href,
            },
        });
    };

    render() {
        const {
            spinner,
            errors,
            lastShiftClassificationTotals,
            title,
        } = this.props;

        const isButtonDisabled =
            spinner.isActive || (!spinner.isActive && errors.hasErrors);

        return (
            <React.Fragment>
                <SubHeader>
                    <SubHeader.Left>
                        <SubHeader.Title>{title}</SubHeader.Title>
                    </SubHeader.Left>
                    <SubHeader.Right>
                        <PageControlsContainer>
                            <Button
                                disabled={isButtonDisabled}
                                onClick={this.handlePDFButtonClick}
                                icon={<PrinterIcon />}
                            >
                                PDF
                            </Button>
                        </PageControlsContainer>
                    </SubHeader.Right>
                </SubHeader>
                <Content>
                    <LoadingSpinner
                        isActive={spinner.isActive}
                        message={spinner.message}
                    />
                    <HaulRoadConditionFilters />
                    <Row>
                        <Col xs={24} xl={14}>
                            {!spinner.isActive &&
                                errors &&
                                errors.errors.map((error) => (
                                    <div key={error.id}>{error.message}</div>
                                ))}
                            {!errors.hasError && (
                                <div className="MineSiteMapContainer">
                                    <HRCMap />
                                    <Legends />
                                </div>
                            )}
                        </Col>
                        <Col xs={24} xl={10}>
                            <div className="HaulRoadCondition__LastShiftContainer">
                                <HaulRoadConditionLastShift
                                    classifications={
                                        lastShiftClassificationTotals
                                    }
                                />
                            </div>
                            <HRCChart />
                        </Col>
                    </Row>
                </Content>
            </React.Fragment>
        );
    }
}

HaulRoadCondition.defaultProps = {};

HaulRoadCondition.propTypes = {
    spinner: typeSpinner,
    errors: PropTypes.shape({
        hasErrors: PropTypes.bool.isRequired,
        errors: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                message: PropTypes.string,
            }),
        ),
    }),
    lastShiftClassificationTotals: typeLastShiftClassificationTotals,
    updateFiltersWithUrlParams: PropTypes.func,
    resetMapSettings: PropTypes.func,
    title: PropTypes.string,
    printPDFMap: PropTypes.func.isRequired,
    pdfConfig: typePDFConfig,
};

const mapStateToProps = (state) => ({
    spinner: getSpinnerState(state),
    errors: getErrorState(state),
    lastShiftClassificationTotals: haulRoadConditionSummaryLatestSelectors.getClassificationPercentForLastShift(
        state,
    ),
    title: getPageTitle(state, FEATURE_IDS.TAB_ID__HAUL_ROAD_CONDITION),
    pdfConfig: getPDFSiteConfig(state),
    speedZonesGeojson: mapFeatureSelectors.selectSpeedZonesGeojson(state),
});

const mapActionsToProps = {
    updateFiltersWithUrlParams: actions.updateFiltersWithUrlParams,
    resetMapSettings: minesiteMapActions.resetMapSettings,
    printPDFMap: actions.printPDFMap,
    prepareToFetchFeatures: mapFeatureActions.prepareToFetchFeatures,
};

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(memo(HaulRoadCondition, arePropsEqual));
