import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_MAP_FEATURES_SUCCESS = `${namespace}/FETCH_MAP_FEATURES_SUCCESS`;
    const fetchMapFeaturesSuccess = (resultsArray) =>
        action(FETCH_MAP_FEATURES_SUCCESS, resultsArray);

    const FETCH_MAP_FEATURES_ERROR = `${namespace}/FETCH_MAP_FEATURES_ERROR`;
    const fetchMapFeaturesError = ({ error }) =>
        action(FETCH_MAP_FEATURES_ERROR, { error });

    return {
        FETCH_MAP_FEATURES_SUCCESS,
        fetchMapFeaturesSuccess,
        FETCH_MAP_FEATURES_ERROR,
        fetchMapFeaturesError,
    };
}
