import React, { Component, memo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Radio } from '@rs/core/falcon';
import { Select } from '../../Components/Select';
import { DatepickerInputWithSiteConfig } from '../../Components/Datepicker';
import arePropsEqual from '@rs/core/falcon/utils/arePropsEqual';

const formatProductivityLabel = (label) => {
    const parts = label.split(' ');
    const regularText1 = parts.slice(0, 4).join(' ');
    const specialText = parts[4];
    const regularText2 = parts.slice(5, parts.length).join(' ');
    return (
        <div>
            <span>{`${regularText1} `}</span>
            <span className="ProductivityFilters__SelectLabel--StrongText">
                {specialText}
            </span>
            <span>{` ${regularText2}`}</span>
        </div>
    );
};

class ProductivityFilters extends Component {
    constructor(props) {
        super(props);

        this.handleFilterChangeWorker = this.props.onFilterChange.bind(
            null,
            'Worker',
        );
        this.handleFilterChangeEvent = this.props.onFilterChange.bind(
            null,
            'Event',
        );
        this.handleFilterChangeCrew = this.props.onFilterChange.bind(
            null,
            'Crew',
        );
        this.handleFilterChangeShift = this.handleFilterChangeShift.bind(this);
    }

    handleFilterChangeShift(event) {
        const { value } = event.target;
        this.props.onFilterChange('Shift', value);
    }

    render() {
        const {
            handleFilterChangeShift,
            handleFilterChangeWorker,
            handleFilterChangeEvent,
            handleFilterChangeCrew,
        } = this;
        const { filters, filterOptions, onFilterChange } = this.props;

        const workerValues = filters.Worker;
        const workerOptions = filterOptions.workers;

        const shiftValues = filters.Shift;
        const shiftOptions = filterOptions.shifts;

        const eventValues = filters.Event;
        const eventOptions = filterOptions.events;

        const crewValues = filters.Crew;
        const crewOptions = filterOptions.crews;

        return (
            <Row>
                <Col span={24}>
                    <Row>
                        <Col xs={12}>
                            <DatepickerInputWithSiteConfig
                                startDate={filters.Date}
                                onFilterChange={onFilterChange}
                                label={'Date'}
                                allowModeSwitch={false}
                                rangeSelected={false}
                            />
                        </Col>
                        <Col xs={12}>
                            <label>Shift</label>
                            <div>
                                <Radio.Group
                                    options={shiftOptions}
                                    onChange={handleFilterChangeShift}
                                    value={shiftValues}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row>
                        <Col xs={24} md={8}>
                            <label htmlFor="filterCrew">Crews</label>
                            <Select
                                id="filterCrew"
                                name="Crew"
                                value={crewValues}
                                options={crewOptions}
                                onChange={handleFilterChangeCrew}
                                onRemove={handleFilterChangeCrew}
                                simpleValue={true}
                                multi={false}
                            />
                        </Col>
                        <Col xs={24} md={8}>
                            <label htmlFor="filterWorker">Drivers</label>
                            <Select
                                id="filterWorker"
                                name="Worker"
                                value={workerValues}
                                options={workerOptions}
                                onChange={handleFilterChangeWorker}
                                onRemove={handleFilterChangeWorker}
                                simpleValue={true}
                                multi={false}
                            />
                        </Col>
                        <Col xs={24} md={8}>
                            <label htmlFor="filterEvent">Events</label>
                            <Select
                                id="filterEvent"
                                name="Event"
                                value={eventValues}
                                options={eventOptions}
                                onChange={handleFilterChangeEvent}
                                onRemove={handleFilterChangeEvent}
                                simpleValue={true}
                                multi={false}
                                optionRenderer={(option) => {
                                    if (!option.label) return null;
                                    return formatProductivityLabel(
                                        option.label,
                                    );
                                }}
                                valueRenderer={(option) => {
                                    if (!option.label) return null;
                                    return formatProductivityLabel(
                                        option.label,
                                    );
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

ProductivityFilters.propTypes = {
    filters: PropTypes.shape({
        Date: PropTypes.string,
        Shift: PropTypes.string.isRequired,
        Worker: PropTypes.string.isRequired,
        Event: PropTypes.string.isRequired,
        Crew: PropTypes.string.isRequired,
    }).isRequired,
    filterOptions: PropTypes.shape({
        shifts: PropTypes.array.isRequired,
        workers: PropTypes.array.isRequired,
        events: PropTypes.array.isRequired,
        crews: PropTypes.array.isRequired,
    }).isRequired,
    onFilterChange: PropTypes.func.isRequired,
};

export default memo(ProductivityFilters, arePropsEqual);
