import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_MINESITE_SUCCESS = `${namespace}/FETCH_MINESITE_SUCCESS`;
    const fetchMinesiteSuccess = (results) =>
        action(FETCH_MINESITE_SUCCESS, results);

    const FETCH_MINESITE_ERROR = `${namespace}/FETCH_MINESITE_ERROR`;
    const fetchMinesiteError = ({ error }) =>
        action(FETCH_MINESITE_ERROR, { error });

    return {
        FETCH_MINESITE_SUCCESS,
        fetchMinesiteSuccess,
        FETCH_MINESITE_ERROR,
        fetchMinesiteError,
    };
}
