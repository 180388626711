import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@vx/tooltip';
import { localPoint } from '@vx/event';
import * as types from '@rs/core/types';
import { ConformanceCell } from '@rs/core/components';
import {
    Row,
    Col,
    MinesiteMap,
    MAP_LAYER_LABELS,
    getMapExtentBounds,
} from '@rs/core/falcon';
import { wktAggregate } from '@rs/core/application';
import OperatorFeedbackConformanceSummary from './OperatorFeedbackConformanceSummary';
import {
    SpeedLimitedZonesLegend,
    createSpeedLimitedZonesLegend,
} from '../../Components/SpeedLimitedZonesLegend';
import withComponentConfig from '../../HOC/withComponentConfig';
import AlertBox from '../../Components/AlertBox/AlertBox';
import DigitalGlobeBackground from '../../Components/DigitalGlobeBackground/DigitalGlobeBackground';
import { getEnabledBackgroundLayers } from '../../Modules/minesiteMap/utils';

const { getWKTResponseGeoJSON } = wktAggregate;

const ConformanceCellWithConfig = withComponentConfig(
    'ConformanceDefault',
    ConformanceCell,
);
class OperatorFeedbackConformance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {
                ShowOverspeedCorners: true,
                ShowSpeedingInZones: true,
                ShowRampEvents: true,
            },
            tooltipData: null,
        };

        this.handleCornerEventMouseover = this.handleCornerEventMouseover.bind(
            this,
        );
    }

    handleCornerEventMouseover(e, data) {
        if (data === null) {
            this.setState((prevState) => ({
                ...prevState,
                tooltipData: null,
            }));
            return;
        }

        const { ownerSVGElement } = e.target;
        const { x, y } = localPoint(ownerSVGElement, e);
        this.setState((prevState) => ({
            ...prevState,
            tooltipData: {
                data,
                x,
                y,
            },
        }));
    }

    render() {
        const { tooltipData } = this.state;
        const {
            wktData,
            cornerEvents,
            speedingEvents,
            speedLimitedZones,
            rampEvents,
            cornerLeagueTotal,
            errorMessage,
            conformanceSummaryBadEvents,
            minesiteMapConfig,
        } = this.props;

        if (errorMessage) {
            return (
                <Row>
                    <Col span={24}>
                        <AlertBox>{errorMessage}</AlertBox>
                    </Col>
                </Row>
            );
        }

        const speedZoneLegendItems = createSpeedLimitedZonesLegend(
            speedLimitedZones,
        );

        const bounds = getMapExtentBounds(minesiteMapConfig.bounds);

        return (
            <Row>
                <Col span={24} style={{ fontSize: '2rem' }}>
                    <ConformanceCellWithConfig
                        conformanceValue={
                            cornerLeagueTotal.OverspeedConformance
                        }
                        roundToPlace={1}
                        render={({ formattedValue, conformanceClassName }) => (
                            <div
                                className={`OperatorFeedback__ComformanceCell ${conformanceClassName}`}
                            >
                                &nbsp;Corner Conformance {formattedValue}%
                            </div>
                        )}
                    />
                </Col>
                <Col span={24}>
                    <div className="MineSiteMapContainer">
                        <MinesiteMap
                            maxBounds={bounds}
                            style={{ width: '100%', height: 570 }}
                            onOverlayAdd={this.handleOverlayAdd}
                            onOverlayRemove={this.handleOverlayRemove}
                        >
                            <MinesiteMap.LayersControl
                                collapsed={true}
                                position="topright"
                            >
                                <DigitalGlobeBackground
                                    checked={
                                        getEnabledBackgroundLayers(
                                            minesiteMapConfig,
                                        ).background
                                    }
                                />
                                <MinesiteMap.Background
                                    name={
                                        MAP_LAYER_LABELS.backgroundClientProvided
                                    }
                                    checked={
                                        getEnabledBackgroundLayers(
                                            minesiteMapConfig,
                                        ).backgroundClientProvided
                                    }
                                    url={
                                        minesiteMapConfig.mapLayers
                                            .clientProvided.url
                                    }
                                    options={
                                        minesiteMapConfig.mapLayers
                                            .clientProvided.options
                                    }
                                />
                                <MinesiteMap.RoadPath
                                    priority={1}
                                    checked={true}
                                    name={MAP_LAYER_LABELS.roadPath}
                                    data={getWKTResponseGeoJSON(
                                        wktData.WKTLocuses,
                                    )}
                                />
                                <MinesiteMap.SpeedLimitedZones
                                    priority={2}
                                    checked={true}
                                    name={MAP_LAYER_LABELS.speedLimitedZones}
                                    data={speedLimitedZones}
                                />
                                <MinesiteMap.CornerEvents
                                    priority={4}
                                    checked={true}
                                    name={MAP_LAYER_LABELS.cornerEvents}
                                    data={cornerEvents}
                                />
                                <MinesiteMap.RampEvents
                                    priority={5}
                                    checked={true}
                                    name={MAP_LAYER_LABELS.rampEvents}
                                    data={rampEvents}
                                />
                                <MinesiteMap.SpeedingInZones
                                    priority={6}
                                    checked={true}
                                    name={MAP_LAYER_LABELS.speedingInZone}
                                    data={speedingEvents}
                                />
                            </MinesiteMap.LayersControl>
                        </MinesiteMap>
                    </div>
                    <Col xl={8}>
                        <SpeedLimitedZonesLegend
                            legendItems={speedZoneLegendItems}
                        />
                    </Col>

                    {tooltipData && (
                        <Tooltip top={tooltipData.y} left={tooltipData.x}>
                            <div>
                                <strong>{tooltipData.data.Label}</strong>
                            </div>
                        </Tooltip>
                    )}
                </Col>
                <Col span={24}>
                    <OperatorFeedbackConformanceSummary
                        conformanceSummaryBadEvents={
                            conformanceSummaryBadEvents
                        }
                        cornerLeagueTotal={cornerLeagueTotal}
                        cornerEvents={cornerEvents}
                    />
                </Col>
            </Row>
        );
    }
}

OperatorFeedbackConformance.defaultProps = {};

OperatorFeedbackConformance.propTypes = {
    cornerLeagueTotal: types.typeCornerLeagueTotal,
    wktData: types.typeWktData,
    cornerEvents: PropTypes.arrayOf(types.typeCornerEventRow),
    speedingEvents: PropTypes.arrayOf(types.typeSpeedingEventsRow),
    speedLimitedZones: PropTypes.arrayOf(types.typeSpeedLimitedZoneRow),
    clusters: PropTypes.arrayOf(types.typeTopClusterRow),
    rampEvents: PropTypes.arrayOf(types.typeRampEventRow),
    mapLabels: PropTypes.arrayOf(types.typeMapLabelRow),
    errorMessage: PropTypes.string,
    conformanceSummaryBadEvents: PropTypes.shape({
        corner: PropTypes.number,
        zone: PropTypes.number,
        ramp: PropTypes.number,
    }),
    mapFeatures: PropTypes.arrayOf(types.typeMapFeature),
    minesiteAreasTree: types.typeMinesiteAreaTree,
    topLevelWhereOnMinesiteIdDepth: PropTypes.number,
    minesiteMapConfig: types.typeMinesiteMapConfig,
};

export default OperatorFeedbackConformance;
