import { put, all, takeLeading, select, call } from 'redux-saga/effects';
import { actions as PowerBiReportsAction } from '../PowerBi/Modules/powerBiReports';
import {
    getEditReport,
    isCurrentEditReportHasAllKeys,
} from '../PowerBi/Selectors';
import validator from 'validator';
import * as resourceSagas from './resourceSagas';
import { checkRequestsForErrors } from '../Api';
import * as loadingSpinnerModule from '../PowerBi/Modules/spinner';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import { sanitizeURIFromObjectKey, changeKeyValuePair } from './utils';
import { replace } from 'connected-react-router';

export function* submitReportInputField({ payload }) {
    // get the state current value
    let currentState = yield select(getEditReport);
    // replace the key in the currentEditReport with payload
    let cloneCurrentState = changeKeyValuePair(currentState, payload);
    // only sanitize if the ReportEmbedUrl has changed
    if (payload.name === 'reportEmbedUrl') {
        let sanitizedCurrentstate = sanitizeURIFromObjectKey(
            cloneCurrentState,
            'reportEmbedUrl',
            'autoAuth',
        );
        cloneCurrentState = sanitizedCurrentstate;
    }

    // fire an action to store it in the redux which has cloneCurrentState
    yield put(PowerBiReportsAction.setEditReportInputField(cloneCurrentState));

    // check if the form has any errors
    const errors = checkFormValidation(cloneCurrentState);

    // check if the currentEditReport has all the keys
    const hasAllKeys = yield select(isCurrentEditReportHasAllKeys);
    if (Object.keys(errors).length !== 0 || !hasAllKeys) {
        // fire the action to disbale the toggle button
        yield put(PowerBiReportsAction.previewButtonToggle('DISABLE'));
        // fire action to store the error in the redux state
        yield put(PowerBiReportsAction.setEditReportFieldError(errors));
    } else {
        yield put(PowerBiReportsAction.previewButtonToggle('ENABLE'));
    }
}

const checkFormValidation = (clonedState) => {
    const errors = {};

    const errorMessages = {
        reportName: {
            required: 'This field is required',
        },

        reportEmbedUrl: {
            required: 'This field is required',
            validation: 'Invalid embedded url',
        },
    };

    // Check which fields are empty
    Object.keys(clonedState).forEach((key) => {
        let value = clonedState[key];
        const embedUrlRegex = /https:\/\/app\.powerbi\.com\/reportEmbed[?&](ctid|reportId)=([^&=#]*)(&?)[?&](ctid|reportId)=([^&=#]*)(&?)/;
        errors[key] = [];
        switch (key) {
            case 'reportName':
                if (validator.isEmpty(value)) {
                    errors[key].push({
                        message: errorMessages[key].required,
                    });
                }
                break;
            case 'reportEmbedUrl':
                if (validator.isEmpty(value)) {
                    errors[key].push({
                        message: errorMessages[key].required,
                    });
                } else if (!embedUrlRegex.test(value)) {
                    errors[key].push({
                        message: errorMessages[key].validation,
                    });
                }
                break;
            default:
                break;
        }
    });

    return Object.keys(errors).some((val) => errors[val].length > 0)
        ? errors
        : {};
};

export function* fetchTokenForPreviewedReport() {
    try {
        yield put(
            loadingSpinnerModule.actions.setSpinnerState(
                true,
                LOADING_MESSAGES.LOADING__DATA,
            ),
        );
        // fetch the input fields from the state
        let currentInputFields = yield select(getEditReport);

        const requestBody = {
            ReportName: currentInputFields.reportName,
            ReportEmbedUrl: currentInputFields.reportEmbedUrl,
            Notes: '',
        };

        // send the data to the backend to fetch the token
        const apiResponse = yield call(
            resourceSagas.previewPowerBiReportToken,
            requestBody,
        );
        // check request for the errors
        if (checkRequestsForErrors([apiResponse])) {
            const errorMessage = apiResponse.error || 'Error to add report';
            yield put(
                PowerBiReportsAction.fetchReportError({
                    message: errorMessage,
                }),
            );
            yield put(loadingSpinnerModule.actions.setSpinnerState(false));
            return;
        }
        // fire an action to store the token in the redux state
        yield put(
            PowerBiReportsAction.previewTokenReceived(
                apiResponse.response.AccessToken,
            ),
        );
        yield put(loadingSpinnerModule.actions.setSpinnerState(false));
        yield put(PowerBiReportsAction.previewModalStatus('SHOWN'));
    } catch (e) {
        console.error(e);
    }
}

export function* handleSubmitCreateReportRequest({ payload }) {
    const powerBiReport = yield call(
        resourceSagas.submitCreatePowerBiReport,
        payload,
    );

    if (checkRequestsForErrors([powerBiReport])) {
        yield put(
            PowerBiReportsAction.fetchReportError({
                message: 'Request Failed! Please try again.',
            }),
        );
        return;
    }
    yield put(PowerBiReportsAction.prepareToFetchAllReports());
    yield put(PowerBiReportsAction.previewModalStatus('HIDDEN'));
    const currentEditReport = yield select(getEditReport);
    yield put(
        replace({
            pathname: `/custom-report/${currentEditReport.id}`,
        }),
    );
}

export function* handleSubmitEditReportRequest({ payload }) {
    const powerBiReport = yield call(
        resourceSagas.submitEditPowerBiReport,
        payload,
    );

    if (checkRequestsForErrors([powerBiReport])) {
        yield put(
            PowerBiReportsAction.fetchReportError({
                message: 'Request Failed! Please try again.',
            }),
        );
        return;
    }
    yield put(PowerBiReportsAction.prepareToFetchAllReports());
    yield put(PowerBiReportsAction.previewModalStatus('HIDDEN'));
    const currentEditReport = yield select(getEditReport);
    yield put(
        replace({
            pathname: `/custom-report/${currentEditReport.id}`,
        }),
    );
}

export function* clearData() {
    yield put(PowerBiReportsAction.previewButtonToggle('DISABLE'));
}

export default function* watch() {
    try {
        yield all([
            takeLeading(
                PowerBiReportsAction.SUBMIT_EDIT_REPORT_INPUT_FIELD,
                submitReportInputField,
            ),
            takeLeading(
                PowerBiReportsAction.PREVIEW_CLICKED,
                fetchTokenForPreviewedReport,
            ),
            takeLeading(
                PowerBiReportsAction.SUBMIT_CREATE_REPORT_REQUEST,
                handleSubmitCreateReportRequest,
            ),
            takeLeading(
                PowerBiReportsAction.SUBMIT_EDIT_REPORT_REQUEST,
                handleSubmitEditReportRequest,
            ),
            takeLeading(PowerBiReportsAction.CLEAR_DATA, clearData),
        ]);
    } catch (e) {
        console.error(e);
    }
}
