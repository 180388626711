import {
    DAS_COLLAPSE_SIDER,
    DAS_DATA_LOADING,
    DAS_DATE_RANGE_CHANGED,
    DAS_PLAYBACK_PAUSE,
    DAS_PLAYBACK_SPEED_CHANGED,
    DAS_PLAYBACK_START,
    DAS_PLAYBACK_ZOOM_IN,
    DAS_PLAYBACK_ZOOM_OUT,
    DAS_SET_AUTH_TOKEN,
    DAS_SET_CATEGORIES,
    DAS_SET_DATA_INTERVAL,
    DAS_SET_HIGHLIGHTED_MACHINE,
    DAS_SET_LOOP,
    DAS_SET_MACHINE_DATA,
    DAS_SET_METRICS,
    DAS_SET_PARAMETERS,
    DAS_SET_SELECTED_MACHINES,
    DAS_SET_ZOOM_RANGE,
    DAS_SET_ZOOMED_MACHINE_DATA,
    DAS_TRACK_POSITION_CHANGED,
    DAS_ZOOMED_DATA_LOADING,
} from './dasActions';
import * as WKTAggregateModule from './Modules/WKTAggregate';
import * as minesiteMapModule from './Modules/minesiteMap';
import * as spinnerModule from './Modules/spinner';

const initialState = {
    startDate: null,
    endDate: null,
    zoomStart: null,
    zoomEnd: null,
    geoJSONData: null,
    chartData: null,
    trackPosition: 0,
    playbackSpeed: 1,
    playing: false,
    machines: [],
    selectedMachines: [],
    highlightedMachine: '',
    zoomedMachines: [],
    zoomLoading: false,
    inZoom: false,
    zoomLoaded: false,
    interval: 30000,
    loading: false,
    loop: false,
    zoom: 0,
    collapsed: true,
    authToken: '',
    parameters: [],
    categories: [],
    metrics: [],
    // The WKTAggregateReducer initial state
    WKTAggregate: {},
    // The spinner initial state
    spinner: {
        isActive: false,
    },
    // The minesiteMap initial state
    minesiteMap: minesiteMapModule.initialState,
};

const ZOOM_PERCENT = 0.2;

function calculateZoom(state) {
    const totalTime = state.endDate - state.startDate;
    let zoomStart = state.startDate;
    let zoomEnd = state.endDate;

    if (state.zoom > 0) {
        const timeBuffer =
            Math.round(
                (totalTime * (ZOOM_PERCENT / state.zoom)) / state.interval,
            ) * state.interval;
        zoomStart = state.trackPosition - timeBuffer;
        zoomEnd = state.trackPosition + timeBuffer;
    }

    if (zoomStart < state.startDate) {
        zoomStart = state.startDate;
    }

    if (zoomEnd > state.endDate) {
        zoomEnd = state.endDate;
    }
    return { zoomStart, zoomEnd };
}

function isInsideZoomSection(state) {
    if (state.zoomLoaded) {
        console.log(state);
        if (
            state.trackPosition * 1000 >= state.zoomStart &&
            state.trackPosition * 1000 <= state.zoomEnd
        ) {
            return true;
        }
    }
    return false;
}

export default function dasReducer(state = initialState, action) {
    const zoomOut = state.zoom > 0 ? state.zoom - 1 : 0;
    const zoomIn = state.zoom < 7 ? state.zoom + 1 : 7;

    let newState = {};
    switch (action.type) {
        case DAS_DATE_RANGE_CHANGED:
            {
                const { startDate, endDate } = action.payload.dateRange;
                if (startDate && endDate) {
                    newState = {
                        machines: [],
                        zoomedMachines: [],
                        startDate,
                        endDate,
                        zoomStart: startDate * 1000,
                        zoomEnd: endDate * 1000,
                        zoom: 0,
                        trackPosition: startDate,
                    };
                }
            }
            break;
        case DAS_TRACK_POSITION_CHANGED:
            newState = {
                trackPosition: action.payload.trackPosition,
            };
            newState.inZoom = isInsideZoomSection({ ...state, ...newState });
            break;
        case DAS_PLAYBACK_SPEED_CHANGED:
            newState = {
                playbackSpeed: action.payload.playbackSpeed,
            };
            break;
        case DAS_PLAYBACK_START:
            newState = {
                playing: true,
            };
            break;
        case DAS_PLAYBACK_PAUSE:
            newState = {
                playing: false,
            };
            break;
        case DAS_SET_MACHINE_DATA:
            newState = {
                machines: [...action.payload.machines],
            };
            break;
        case DAS_SET_DATA_INTERVAL:
            newState = {
                interval: action.payload.interval,
            };
            break;
        case DAS_DATA_LOADING:
            newState = {
                loading: action.payload.loading,
            };
            break;
        case DAS_SET_AUTH_TOKEN:
            newState = {
                authToken: action.payload.authToken,
            };
            break;
        case DAS_SET_ZOOM_RANGE:
            newState = {
                zoomStart: action.payload.zoomStart,
                zoomEnd: action.payload.zoomEnd,
                zoomedData: [],
                zoomLoading: false,
                zoomLoaded: false,
                inZoom: false,
            };
            break;
        case DAS_PLAYBACK_ZOOM_OUT:
            newState = {
                ...calculateZoom({ ...state, zoom: zoomOut }),
                zoom: zoomOut,
            };
            newState.inZoom = isInsideZoomSection(newState);
            break;
        case DAS_PLAYBACK_ZOOM_IN:
            newState = {
                ...calculateZoom({ ...state, zoom: zoomIn }),
                zoom: zoomIn,
            };
            newState.inZoom = isInsideZoomSection(newState);
            break;
        case DAS_SET_CATEGORIES:
            newState = {
                categories: [...action.payload.categories],
            };
            break;
        case DAS_SET_PARAMETERS:
            newState = {
                parameters: [...action.payload.parameters],
            };
            break;
        case DAS_SET_SELECTED_MACHINES:
            newState = {
                selectedMachines: action.payload.selectedMachines.map(
                    (machine) => machine,
                ),
            };
            break;
        case DAS_SET_HIGHLIGHTED_MACHINE:
            newState = {
                highlightedMachine: action.payload.highlightedMachineId,
            };
            break;
        case DAS_SET_ZOOMED_MACHINE_DATA:
            newState = {
                zoomedMachines: [...action.payload.machines],
                zoomLoaded: action.payload.loaded,
            };
            newState.inZoom = isInsideZoomSection({ ...state, ...newState });
            break;
        case DAS_ZOOMED_DATA_LOADING:
            newState = {
                zoomLoading: action.payload.loading,
                zoomLoaded: action.payload.loaded,
            };
            newState.inZoom = isInsideZoomSection({ ...state, ...newState });
            break;
        case DAS_SET_LOOP:
            newState = {
                loop: action.payload.loop,
            };
            break;
        case DAS_SET_METRICS:
            newState = {
                metrics: [...action.payload.metrics],
            };
            break;
        case DAS_COLLAPSE_SIDER:
            newState = {
                collapsed: action.payload.collapsed,
            };
            break;
        case WKTAggregateModule.actions.FETCH_WKT_AGGREGATE_SUCCESS: {
            newState = {
                WKTAggregate: WKTAggregateModule.reducer(
                    state.WKTAggregate,
                    action,
                ),
            };
            break;
        }
        default:
            break;
    }
    // This is a manual way of doing "combineReducers" without messing up the
    // current das state structure
    newState.minesiteMap = minesiteMapModule.reducer(state.minesiteMap, action);
    newState.spinner = spinnerModule.reducer(state.spinner, action);

    newState = { ...state, ...newState };

    return newState;
}
