import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const GET_CIRCUIT_NODES_REQUESTED = `${namespace}/GET_CIRCUIT_NODES_REQUESTED`;
    const getCircuitNodesRequested = () => action(GET_CIRCUIT_NODES_REQUESTED);

    const GET_CIRCUIT_NODES_SUCCESS = `${namespace}/GET_CIRCUIT_NODES_SUCCESS`;
    const getCircuitNodesSuccess = (results) =>
        action(GET_CIRCUIT_NODES_SUCCESS, results);

    const GET_CIRCUIT_NODES_ERROR = `${namespace}/GET_CIRCUIT_NODES_ERROR`;
    const getCircuitNodesError = ({ error }) =>
        action(GET_CIRCUIT_NODES_ERROR, { error });

    return {
        GET_CIRCUIT_NODES_REQUESTED,
        getCircuitNodesRequested,
        GET_CIRCUIT_NODES_SUCCESS,
        getCircuitNodesSuccess,
        GET_CIRCUIT_NODES_ERROR,
        getCircuitNodesError,
    };
}
