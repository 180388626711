import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode, createPortal } from 'react-dom';
import { Row, Col, Button } from '@rs/core/falcon';
import { isNodeInRoot, measureScrollbar } from '../../Lib/elementUtils';
import CloseIcon from '@ant-design/icons/CloseOutlined';

class Modal extends Component {
    constructor(props) {
        super(props);
        this.modalContent = null;
        this.handleMouseClickOutside = this.handleMouseClickOutside.bind(this);
        this.scrollbarWidth = 0;
    }

    UNSAFE_componentWillMount() {
        this.getScrollbarWidth();
    }

    componentDidMount() {
        const body = document.body;
        body.classList.add('modal-open');
        document.addEventListener('mousedown', this.handleMouseClickOutside);
    }

    componentWillUnmount() {
        const body = document.body;
        body.classList.remove('modal-open');
        document.removeEventListener('mousedown', this.handleMouseClickOutside);
    }

    /**
     * Returns the scrollbar width. If unknown creates a div to get the size
     * @returns {number}
     */
    getScrollbarWidth() {
        return (this.scrollbarWidth =
            this.scrollbarWidth || measureScrollbar());
    }

    handleMouseClickOutside(e) {
        // Check if use has clicked the scrollbar
        if (window.innerWidth - e.pageX <= this.getScrollbarWidth()) {
            return;
        }
        if (isNodeInRoot(e.target, findDOMNode(this.modalContent))) {
            return;
        }
        e.stopPropagation();
        this.props.closeModal();
    }

    render() {
        const {
            children,
            closeModal,
            modalClassName,
            modalTitle,
            modalFooter,
        } = this.props;

        const closeButton = (
            <Button onClick={closeModal} icon={<CloseIcon />} />
        );

        const content = (
            <div>
                <div className="modal-overlay" />
                <div className="modal" tabIndex="-1">
                    <div className={`modal-dialog ${modalClassName}`}>
                        <div
                            className="modal-content"
                            ref={(el) => (this.modalContent = el)}
                        >
                            {modalTitle && (
                                <div className="modal-header">
                                    {typeof modalTitle === 'string' && (
                                        <Row justify="space-between">
                                            <Col span={12}>
                                                <h2 className="modal-title">
                                                    {modalTitle}
                                                </h2>
                                            </Col>
                                            <Col span={12}>
                                                <div className="modal-header-icons">
                                                    {closeButton}
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                    {typeof modalTitle === 'function' &&
                                        modalTitle({
                                            closeButton,
                                        })}
                                </div>
                            )}
                            <div className="modal-body">{children}</div>
                            {modalFooter && (
                                <div className="modal-footer">
                                    {modalFooter}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
        return createPortal(content, document.getElementById('root'));
    }
}

Modal.defaultProps = {
    modalClassName: 'modal-lg',
};

Modal.propTypes = {
    children: PropTypes.element,
    closeModal: PropTypes.func.isRequired,
    modalClassName: PropTypes.string,
    modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    modalFooter: PropTypes.node,
};

export default Modal;
