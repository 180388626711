import * as React from 'react';

function SvgRopeShovel(props) {
    return (
        <svg
            width="442"
            height="345"
            viewBox="0 0 442 345"
            fill="none"
            {...props}
        >
            <path d="M134.063 45H48V205H134.063L134.063 45Z" fill="#FFF200" />
            <path
                d="M442 240C442 226.193 430.807 215 417 215H365C351.193 215 340 226.193 340 240V283H442V240Z"
                fill="black"
            />
            <path
                d="M309 321C309 294.49 287.51 273 261 273H48C21.4903 273 3.8147e-06 294.49 3.8147e-06 321V321C3.8147e-06 334.255 10.7452 345 24 345H285C298.255 345 309 334.255 309 321V321Z"
                fill="black"
            />
            <rect
                width="95"
                height="34"
                transform="matrix(-1 0 0 1 202 239)"
                fill="#FFF200"
            />
            <path
                d="M372.935 25.0864C365.289 19.6445 354.679 21.4315 349.237 29.0779L213.001 220.5L240.691 240.207L376.927 48.7848C382.369 41.1385 380.582 30.5283 372.935 25.0864V25.0864Z"
                fill="black"
            />
            <path
                d="M249.643 62.1469C244.587 66.8628 244.099 74.7081 248.531 80.0144L362.123 216.005L373.682 216.005L362.123 200.858L380.151 195.683L397.403 216.005L410.959 216.005L268.045 62.7872C263.14 57.5289 254.901 57.2423 249.643 62.1469V62.1469Z"
                fill="black"
            />
            <rect
                width="192"
                height="34"
                transform="matrix(-1 0 0 1 240 205)"
                fill="#FFF200"
            />
            <rect
                width="192"
                height="109"
                transform="matrix(-1 0 0 1 240 96)"
                fill="#FFF200"
            />
            <rect
                width="86"
                height="13"
                transform="matrix(0 -1 -1 0 240 185)"
                fill="black"
            />
            <rect
                width="179"
                height="13"
                transform="matrix(-1 0 0 1 240 86)"
                fill="black"
            />
            <rect
                width="54"
                height="13"
                transform="matrix(0 -1 -1 0 140 99)"
                fill="black"
            />
            <rect
                width="92"
                height="13"
                transform="matrix(-1 0 0 1 140 40)"
                fill="black"
            />
            <rect
                width="48"
                height="13"
                transform="matrix(-1 0 0 1 240 227)"
                fill="black"
            />
            <rect
                width="185"
                height="13"
                transform="matrix(-1 0 0 1 233 227)"
                fill="black"
            />
            <rect
                width="73"
                height="13"
                transform="matrix(0 -1 -1 0 121 300)"
                fill="black"
            />
            <rect
                width="182"
                height="13"
                transform="matrix(0 -1 -1 0 61 227)"
                fill="black"
            />
            <rect
                width="64"
                height="13"
                transform="matrix(0 -1 -1 0 202 291)"
                fill="black"
            />
            <ellipse
                rx="28.5"
                ry="29"
                transform="matrix(-1 0 0 1 368.5 29)"
                fill="black"
            />
            <circle r="25" transform="matrix(-1 0 0 1 159 65)" fill="black" />
            <line
                y1="-2.5"
                x2="217.175"
                y2="-2.5"
                transform="matrix(-0.98538 0.170369 0.170369 0.98538 373 28)"
                stroke="black"
                stroke-width="5"
            />
            <line
                y1="-2.5"
                x2="204.749"
                y2="-2.5"
                transform="matrix(0.190477 0.981692 0.981692 -0.190477 372 21)"
                stroke="black"
                stroke-width="5"
            />
        </svg>
    );
}

export default SvgRopeShovel;
