import { select, takeEvery } from 'redux-saga/effects';
import papaparse from 'papaparse';
import * as actions from '../App/Actions';
import { selectValue } from '../Lib/sortingUtils';
import downloadFileFromBlob from '../Utils/files/downloadFile';
import { isFeatureEnabled } from '../App/Selectors';
import { addExtensionToFilename } from '../Utils/files';

export function* processCSVFile(action) {
    const {
        payload: { data, fileKeyConfig, filename: reportName },
    } = action;
    const state = yield select();

    let validData = data;
    let columns = [];
    let csv;

    function isColumnEnabled(featureId) {
        // featureId is optional on the column definition
        if (!featureId) {
            return true;
        }
        return featureId && isFeatureEnabled(state, featureId);
    }

    if (fileKeyConfig) {
        // Collect all the valid column names so they will be output
        // even when there is no data
        columns = fileKeyConfig
            .filter((rule) => isColumnEnabled(rule.featureId))
            .map((rule) => rule.CSVDisplayName);

        // Filter out all the data that shouldn't be shown to clients
        validData = data.map((datum) => {
            const row = fileKeyConfig.reduce((results, rule) => {
                // Column shouldn't be displayed, skip
                if (!isColumnEnabled(rule.featureId)) {
                    return results;
                }
                results[rule.CSVDisplayName] = selectValue(
                    datum,
                    rule.accessor,
                );
                return results;
            }, {});
            return row;
        });

        // Explicitly set data & fields so that headers will always be displayed
        csv = papaparse.unparse({ data: validData, fields: columns });
    } else {
        // Preserve the old behaviour, not sure if there is any actions that don't submit fileKeyConfig
        csv = papaparse.unparse(validData);
    }

    downloadFileFromBlob(csv, addExtensionToFilename(reportName, 'csv'));
}

export default function* watch() {
    yield takeEvery(actions.EXPORT_TO_CSV, processCSVFile);
}
