import * as React from 'react';

function SvgDozer(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <path
                fill="#fff200"
                d="M338.06 463.54l-44-65.29H133.75v206.41h568.62l-37.19-224.09-171.47-1.35-43.94 84.32H338.06z"
            />
            <path d="M1024 880l-90.25-258.27V499.06h-54.18v214.1h-53A138.83 138.83 0 00723.4 606.85l-57.5-276-41-.38V228.66l-59.43-59.43-35.36 35.35 44.79 44.79V330l-77.24-.72-45.05-129.89a96.36 96.36 0 00-87-55.38H174.14v204.24h-70.39v259.19A138.67 138.67 0 000 741.53C0 817.88 62 880 138.28 880h553c68.9 0 126.19-50.72 136.59-116.83h51.71V880zM224.14 194h141.48a46.19 46.19 0 0141.69 26.54l43.43 133-31.29 60h-54.64l-44-66.6h-96.67zm-70.39 204.25h140.31l36.5 54.16 7.36 11.13h111.85l43.94-84.32 131.47 1.35 44.19 224.09H153.75zM691.27 800h-553c-50 0-58.34-42.56-58.34-58.46 0-15.12 8.38-58.46 58.34-58.46h553c46.34 0 58.34 43.42 58.34 58.46 0 11.77-7.87 58.46-58.34 58.46z" />
        </svg>
    );
}

export default SvgDozer;
