import React from 'react';
import PropTypes from 'prop-types';
import { LayersControl, Pane } from 'react-leaflet';
import ClusteredArcs from './ClusteredArcs';
import { ZINDEX } from './constants';

class CornerEvents extends React.PureComponent {
    static propTypes = {
        // TODO data shape
        data: PropTypes.arrayOf(
            PropTypes.shape({
                ArcCenterLatitude: PropTypes.number,
                ArcCenterLongitude: PropTypes.number,
                ArcCenterX: PropTypes.number,
                ArcCenterY: PropTypes.number,
                ArcLength: PropTypes.number,
                CircleOriginLatitude: PropTypes.number,
                CircleOriginLongitude: PropTypes.number,
                CircleOriginX: PropTypes.number,
                CircleOriginY: PropTypes.number,
                CircleRadius: PropTypes.number,
                ClusterId: PropTypes.number,
                CornerAngle: PropTypes.number,
                Curvature: PropTypes.number,
                HeadingFromCircleOriginToArcCenter: PropTypes.number,
                Label: PropTypes.string,
                Speed: PropTypes.number,
                SpeedLimitLower: PropTypes.number,
                SpeedLimitUpper: PropTypes.number,
            }),
        ),
        labels: PropTypes.arrayOf(PropTypes.shape({})),
        // The text that will be displayed in the layer control
        name: PropTypes.string.isRequired,
        // The order a layer should be rendered in, higher number displays on top
        priority: PropTypes.number.isRequired,
        // If the layer is enabled or not
        checked: PropTypes.bool,
    };

    render() {
        const {
            data,
            name,
            checked,
            priority,
            ...leafletInjectedProps
        } = this.props;

        const zIndex = ZINDEX.MAP_PANE + priority;
        const paneId = 'CornerEvents';

        if (!data || !Array.isArray(data) || !data.length) {
            return null;
        }

        return (
            <LayersControl.Overlay
                {...leafletInjectedProps}
                checked={checked}
                name={name}
            >
                <Pane name={paneId} style={{ zIndex }}>
                    <ClusteredArcs
                        paneId={paneId}
                        data={data}
                        minSize={10}
                        markerCluster={true}
                    />
                </Pane>
            </LayersControl.Overlay>
        );
    }
}

export default CornerEvents;
