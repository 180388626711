import createActions from '../../Modules/WKTAggregate/actions';
import createReducer from '../../Modules/WKTAggregate/reducer';
import createSelectors from '../../Modules/WKTAggregate/selectors';
import namespace from './namespace';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].WKTAggregate;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);
export const reducer = createReducer({
    onRequest: actions.FETCH_WKT_AGGREGATE_REQUESTED,
    onSuccess: actions.FETCH_WKT_AGGREGATE_SUCCESS,
    onError: actions.FETCH_WKT_AGGREGATE_ERROR,
});
export const selectors = createSelectors(statePath);
