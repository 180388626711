import move from 'lodash-move';
import { createReducer, action } from '../../Lib/reduxUtils';
import namespace from './namespace';
import * as mineSnapshotModule from './mineSnapshot';
import { sortAlphabetically } from '../../Utils/Filters/filters';

function createActions(ns) {
    const HOLDING_STATE_MOVED = `${ns}/HOLDING_STATE_MOVED`;
    const holdingStateMoved = (sourceHoldingState, destHoldingState) =>
        action(HOLDING_STATE_MOVED, { sourceHoldingState, destHoldingState });

    return {
        HOLDING_STATE_MOVED,
        holdingStateMoved,
    };
}

function createSelectors(statePath) {
    function get(state) {
        return statePath(state);
    }

    return {
        get,
    };
}

export const actions = createActions(namespace);
export const selectors = createSelectors(
    (state) => state[namespace].holdingStatesOrder,
);

export const initialState = [];
export const onHoldingStateMoved = (
    state,
    { sourceHoldingState, destHoldingState },
) => {
    const findIndex = (id) => state.findIndex((cid) => cid === id);

    const sourceIndex = findIndex(sourceHoldingState);
    const destIndex = findIndex(destHoldingState);

    return move(state, sourceIndex, destIndex);
};

export const onMineSnapshotUpdated = (state, { minesite }) => {
    const allIds = Object.keys(minesite['holding-states']);
    // Sort alphabetically - This Module is probably but needed but leaving it in
    // so we can switch user configured sorting easily if needed
    return allIds.sort((a, b) => sortAlphabetically(a, b));
};

export const reducer = createReducer(initialState, {
    [actions.HOLDING_STATE_MOVED]: onHoldingStateMoved,
    [mineSnapshotModule.actions.FETCH_MINESITE_SUCCESS]: onMineSnapshotUpdated,
});
