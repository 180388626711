import { CANCEL } from 'redux-saga';
import generateAPIUrl from '@rs/core/utils/generate-api-url';
import { getQueryString } from '../Lib/queryStringUtils';
import * as storage from '../Lib/localStorage';

export const BASE_URL = process.env.REACT_APP_API_URL || generateAPIUrl();

export const HEADERS = {
    CONTENT_TYPE: 'content-type',
    CONTENT_TYPE__JSON: 'application/json',
    AUTHORIZATION: 'Authorization',
};

export const REQUEST_OBJECT_KEY = {
    METHOD: 'method',
    HEADERS: 'headers',
    BODY: 'body',
};

export const METHODS = {
    POST: 'post',
    GET: 'get',
    PATCH: 'PATCH',
    PUT: 'put',
    DELETE: 'delete',
};

/**
 * Resolves 'successful' status codes. Rejects 'error' codes.
 * @param response - the response from window.fetch.
 * @returns {Promise}
 */
async function resolveResponseByStatusCode(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    const responseBody = await response.text();

    let errorMessage;
    try {
        const responseJSON = JSON.parse(responseBody);
        // Check for .Error key? not sure if it always comes back as Error
        errorMessage = responseJSON.Error;
    } catch (error) {
        // Assume the error is text or if no body fallback to basic error
        errorMessage = responseBody || response.statusText;
    }

    const error = new Error(errorMessage);
    error.statusCode = response.status;
    return Promise.reject(error);
}

/**
 * Parses response based on the content-type.
 * @param response
 */
function parseResponse(response) {
    const contentTypeString = response.headers.get('content-type');
    const resysUser = response.headers.get('x-res-sys-user-email');
    const newAuthToken = response.headers.get('Authorization');
    if (resysUser && newAuthToken) {
        storage.setItem(storage.AUTH_TOKEN_KEY, newAuthToken);
    }

    if (
        (contentTypeString &&
            contentTypeString.indexOf('application/pdf') > -1) ||
        (contentTypeString && contentTypeString.indexOf('text/csv') > -1)
    ) {
        return response.blob();
    }
    return response.json();
}

/**
 * Creates a requestObject with the defaultOptions that get overriden by user supplied options
 * @param {string} url - url that will be modified if there is a body and method is 'GET' | 'DELETE'
 * @param {object} options - Request options to be submitted to the API
 * @returns {object}
 */
export function createRequestObject(url, options, useBaseURL = true) {
    const baseUrl = (useBaseURL && BASE_URL) || '';
    const controller = new AbortController();
    const signal = controller.signal;
    const { headers: customHeaders, ...otherOptions } = options;
    const defaultHeaders = {
        [HEADERS.CONTENT_TYPE]: HEADERS.CONTENT_TYPE__JSON,
        ...customHeaders,
    };
    const defaultOptions = {
        [REQUEST_OBJECT_KEY.METHOD]: null,
        [REQUEST_OBJECT_KEY.HEADERS]: defaultHeaders,
    };
    const newRequestObj = Object.assign(defaultOptions, otherOptions, {
        signal,
    });
    const method = newRequestObj[REQUEST_OBJECT_KEY.METHOD];
    let newUrl;
    if (method === METHODS.GET || method === METHODS.DELETE) {
        const qs = getQueryString(newRequestObj[REQUEST_OBJECT_KEY.BODY]);
        newUrl = `${baseUrl}${url}${qs === '' ? '' : '?' + qs}`;
        delete newRequestObj[REQUEST_OBJECT_KEY.BODY];
    } else {
        if (
            newRequestObj[REQUEST_OBJECT_KEY.BODY] &&
            newRequestObj.isJson !== false
        ) {
            newRequestObj[REQUEST_OBJECT_KEY.BODY] = JSON.stringify(
                newRequestObj[REQUEST_OBJECT_KEY.BODY],
            );
        }
        newUrl = `${baseUrl}${url}`;
    }
    if (newRequestObj.withoutContentTypeHeader === true) {
        delete newRequestObj[REQUEST_OBJECT_KEY.HEADERS][HEADERS.CONTENT_TYPE];
    }

    return {
        url: newUrl,
        options: newRequestObj,
        controller,
    };
}

/**
 * Performs a network request
 * @param requestUrl
 * @param requestOptions
 * @param useBaseURL
 * @returns {*|Promise|Promise.<T>|Promise<U>}
 */
export function request(requestUrl, requestOptions, useBaseURL = true) {
    const { url, options, controller } = createRequestObject(
        requestUrl,
        requestOptions,
        useBaseURL,
    );
    const promise = fetch(url, options)
        .then(resolveResponseByStatusCode)
        .then(parseResponse)
        .then((response) => ({ response }))
        .catch((error) => ({ error }));
    promise[CANCEL] = () => controller.abort();
    return promise;
}

/**
 * Checks an array of yields for any responses that have an error property
 * Initially wanted to return responses as {response, error} and check in code
 * const {response, error} = request(somerequest);
 * if(error){
 *   // do something with error
 * }
 * However this seems kind of awkward when yielding arrays. Might be better with try / catch blocks & rejecting the promises
 * so it gets caught in the try / catch instead of checking for error property existance
 * @param arr
 * @returns {*|boolean}
 */
export function checkRequestsForErrors(arr) {
    return arr.some((response) => response.error);
}
