import { operator } from '@rs/core/application';

export default function addOperatorSiteId(loadingEventsConformanceReport) {
    return loadingEventsConformanceReport.map((row) => ({
        ...row,
        OperatorSiteId: parseInt(
            operator.operatorNameToUniqueId(row.OperatorDisplayName),
            10,
        ),
    }));
}
