import { createReducer } from '../../Lib/reduxUtils';
import {
    LOAD_LOADING_EVENTS_BIAS_LOAD_REPORT,
    FILTER_UPDATED,
} from '../Actions';

export const initialState = {
    rows: [],
    total: {},
};

export const setBiasLoadResults = (state, { rows, total }) => ({
    rows,
    total,
});

export const filterUpdated = () => ({ ...initialState });

export default createReducer(initialState, {
    [LOAD_LOADING_EVENTS_BIAS_LOAD_REPORT]: setBiasLoadResults,
    [FILTER_UPDATED]: filterUpdated,
});
