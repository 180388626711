import moment from 'moment';
import 'moment-timezone';
import { isUnknown } from './activityClassifications';
import {
    DATE_FORMAT,
    ActivityClassification,
    ActivitiesByEquipmentName,
    Activity,
    EquipmentDelay,
} from './constants';

const { NO_UPDATES, NO_GPS, UNKNOWN } = ActivityClassification;

/**
 * Checks for the scenario where we have NL data but no LL data.
 * This can happen when a truck has been down for maintenance or parked up for a
 * long period of time, and because the truck is off we get no LL data.
 * Basically NL is saying "I know this equipment exists, but I haven't seen it for a while"
 *
 * Algorithm:
 *    1. Check if the equipment has results in NL but not LL
 *    2. Check if the results in NL are all "Unknown" activities
 *    3. If yes, set those results to "No Updates"
 *
 * @param {ActivitiesByEquipmentName} nearLive NL results keyed by Equipment Name
 * @param {ActivitiesByEquipmentName} liveLive LL results keyed by Equipment Name
 * @returns {ActivitiesByEquipmentName} NL results with the NO_UPDATES status injected where necessary
 */
export function checkForEquipmentWithNoUpdates(
    nearLive: ActivitiesByEquipmentName,
    liveLive: ActivitiesByEquipmentName,
): ActivitiesByEquipmentName {
    const result = {};
    Object.entries(nearLive).forEach(([equipment, activities]) => {
        result[equipment] = activities;

        // If NL equipment does not exist in LL...
        if (!liveLive[equipment]) {
            // If NL data for that equipment is all unknown...
            const isAllUnknown = activities.every((activity) =>
                isUnknown(activity),
            );
            if (isAllUnknown) {
                // Assign NO_UPDATES to the NL activities for that equipment
                result[equipment] = activities.map((activity) => ({
                    ...activity,
                    Status: NO_UPDATES,
                }));
            }
        }
    });
    return result;
}

/**
 * Checks for equipment that don't have a GPS signal.
 *
 * Algorithm:
 *    1. Iterate through LL results for all equipment
 *    2. If the LL result doesn't have Lat/Long, set the result as "No GPS"
 *
 * @param {ActivitiesByEquipmentName} liveLive LL results keyed by Equipment Name
 * @returns {ActivitiesByEquipmentName} LL results with the NO_GPS status injected where necessary
 */
export function checkForEquipmentWithNoGPS(
    liveLive: ActivitiesByEquipmentName,
): ActivitiesByEquipmentName {
    const result = {};
    Object.entries(liveLive).forEach(([equipment, activities]) => {
        result[equipment] = activities.map((activity) => {
            // If we have no Lat/Long...
            if (
                !Number.isFinite(activity.SourceLatitude) &&
                !Number.isFinite(activity.SourceLongitude) &&
                !Number.isFinite(activity.DestinationLatitude) &&
                !Number.isFinite(activity.DestinationLongitude)
            ) {
                // Return the result as No GPS
                return {
                    ...activity,
                    Status: NO_GPS,
                };
            }
            return { ...activity };
        });
    });
    return result;
}

/**
 * The last received LL activity does not have a StopTime. This function sets the
 * last equipment value to have a StopTime of the passed "currentTime" argument. Does not
 * mutate the passed array.
 *
 * Assumes that the LL data is sorted so that the activity array
 * for each equipment has the first element as the "latest".
 *
 * @param {ActivitiesByEquipmentName} liveLive LL results keyed by Equipment Name
 * @param {moment.Moment} currentTime A moment object representing the current time
 * @param {string} timezone The timezone with which to calculate the duration
 * @returns {ActivitiesByEquipmentName} LL results with the last equipment StopTime set
 */
export function setStopTimeForLastLiveLiveActivity(
    liveLive: ActivitiesByEquipmentName,
    currentTime: moment.Moment,
    timezone: string,
): ActivitiesByEquipmentName {
    const result = {};
    Object.entries(liveLive).forEach(([equipment, activities]) => {
        result[equipment] = activities.map((activity, index) => {
            // If the latest activity
            if (index === 0) {
                return {
                    ...activity,
                    // Replace the last StopTime
                    StopTime: currentTime.format(DATE_FORMAT),
                    // Adjust the TotalTime appropriately
                    TotalTime: currentTime.diff(
                        moment.tz(activity.StartTime, DATE_FORMAT, timezone),
                        'seconds',
                    ),
                };
            }
            return activity;
        });
    });
    return result;
}

/**
 * To make sure we fill in as much NL data as possible, we need to inject an extra
 * "Unknown" block from the last result until "now" at the end of each NL equipment activities.
 *
 * We also need to inject a similar block before the start of NL data if LL data exists before
 *
 * This is to help make the LL and NL merging algorithm work better.
 *
 * Assumes that the NL data is sorted so that the activity array
 * for each equipment has the first element as the "latest".
 *
 * @param nearLive NL results keyed by Equipment Name
 * @param liveLive LL results keyed by Equipment Name
 * @param currentTime A moment object representing the current time
 * @param timezone The timezone with which to calculate the duration
 * @returns nearLive NL results with the extra unknown activities injected
 */
export function injectUnknownActivitiesIntoNearLive(
    nearLive: ActivitiesByEquipmentName,
    liveLive: ActivitiesByEquipmentName,
    currentTime: moment.Moment,
    timezone: string,
): ActivitiesByEquipmentName {
    const result = {};
    Object.entries(nearLive).forEach(([equipment, activities]) => {
        const lastActivity = activities[0];
        // This is the activity from the latest NL -> Now
        const injectedPostActivity: Activity = {
            ...lastActivity,
            // Set the injected activity to unknown
            Status: UNKNOWN,
            // Set the appropriate start/stop/total times
            StartTime: lastActivity.StopTime,
            StopTime: currentTime.format(DATE_FORMAT),
            TotalTime: currentTime.diff(
                moment.tz(lastActivity.StopTime, DATE_FORMAT, timezone),
                'seconds',
            ),
        };

        // Check whether LL starts before NL
        const liveLiveActivities = liveLive[equipment];
        const injectedPreActivty = [];
        // If we have LL results...
        if (liveLiveActivities && liveLiveActivities.length > 0) {
            const firstLiveLiveActivity =
                liveLiveActivities[liveLiveActivities.length - 1];
            const startOfLiveLive = moment.tz(
                firstLiveLiveActivity.StartTime,
                DATE_FORMAT,
                timezone,
            );
            const firstNearLiveActivity = activities[activities.length - 1];
            const startOfNearLive = moment.tz(
                firstNearLiveActivity.StartTime,
                DATE_FORMAT,
                timezone,
            );
            if (startOfLiveLive.isBefore(startOfNearLive)) {
                injectedPreActivty.push({
                    ...firstNearLiveActivity,
                    // Set the injected activity to unknown
                    Status: UNKNOWN,
                    // Set the appropriate start/stop/total times
                    StartTime: firstLiveLiveActivity.StartTime,
                    StopTime: firstNearLiveActivity.StartTime,
                    TotalTime: startOfNearLive.diff(startOfLiveLive, 'seconds'),
                });
            }
        }

        // Add the new unknown activities at either ends of the array
        result[equipment] = [injectedPostActivity]
            .concat(activities)
            .concat(injectedPreActivty);
    });
    return result;
}

/**
 * Sets the last updated time to be the StartTime of the most recent Live Live activity
 */
export function setLastUpdatedTime(
    equipmentDelays: Array<EquipmentDelay>,
    liveLiveByEquipmentName: ActivitiesByEquipmentName,
    currentTime: moment.Moment,
    timezone: string,
): Array<EquipmentDelay> {
    return equipmentDelays.map((equipment) => {
        let lastUpdated = null;
        const liveLiveForEquipment =
            liveLiveByEquipmentName[equipment.equipmentSiteName];
        // If there is live live for that equipment...
        if (liveLiveForEquipment) {
            const lastLiveLiveActivity = liveLiveForEquipment[0];
            lastUpdated = moment
                .duration(
                    currentTime.diff(
                        moment.tz(
                            lastLiveLiveActivity.StartTime,
                            DATE_FORMAT,
                            timezone,
                        ),
                    ),
                )
                .asSeconds();
        }
        return { ...equipment, lastUpdated };
    });
}
