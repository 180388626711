import { createReducer } from '../../Lib/reduxUtils';
import { LOAD_FEATURES_LIST } from '../Actions';

export const initialState = {
    rows: [],
};

export const setLoadFeaturesListResults = (state, { rows }) => ({
    ...state,
    rows,
});

export const featuresList = createReducer(initialState, {
    [LOAD_FEATURES_LIST]: setLoadFeaturesListResults,
});
