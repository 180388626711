import { MapLayer } from './types';

export function getEnabledBackgroundLayers(mapConfig: any) {
    // bit of annoying empty value been passed somewhere
    // can use Optional Chaining from ES2020 in the future
    const clientIsDefault =
        mapConfig &&
        mapConfig.defaultEnabledMapLayers &&
        mapConfig.defaultEnabledMapLayers.includes(MapLayer.ClientProvided);

    if (clientIsDefault) {
        return {
            aerial: true, // name changed from background-clientProvided
            satellite: false, // name changed from background
        };
    }

    return {
        satellite: true,
    };
}
