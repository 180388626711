import { combineReducers } from 'redux';
import filters from './filters';
import spinner from './spinner';
import loaderOperatorLogs from './loaderOperatorLogs';
import tableOrder from './tableOrder';
import loaderEvents from './loaderEvents';
import biasLoad from './biasLoad';

export const REDUCER_KEY = 'BiasLoad';
export default combineReducers({
    loaderOperatorLogs,
    tableOrder,
    filters,
    spinner,
    loaderEvents,
    biasLoad,
});
