import React from 'react';
import PropTypes from 'prop-types';
import { LayerGroup, LayersControl, Pane } from 'react-leaflet';
import DecoratedPath from './createDecoratedPath';
import { ZINDEX } from './constants';

class EventPaths extends React.Component {
    static propTypes = {
        // The text that will be displayed in the layer control
        name: PropTypes.string.isRequired,
        // The initial state of if to display the layer
        checked: PropTypes.bool.isRequired,

        data: PropTypes.arrayOf(
            PropTypes.shape({
                Id: PropTypes.number,
                S3Id: PropTypes.number,
                ShiftId: PropTypes.number,
                EquipmentId: PropTypes.number,
                UniqueDriverId: PropTypes.string,
                ShiftProductivityEventId: PropTypes.number,
                RoadSegmentId: PropTypes.number,
                TruckType: PropTypes.string,
                TruckName: PropTypes.string,
                TotalLostTime: PropTypes.number,
                TotalTime: PropTypes.number,
                PassCount: PropTypes.number,
                DataFileStartOffset: PropTypes.number,
                DataFileEndOffset: PropTypes.number,
                WKTLoci: PropTypes.string,
                Heading: PropTypes.number,
                HeadingArrowCenterX: PropTypes.number,
                HeadingArrowCenterY: PropTypes.number,
                LeaderLineX: PropTypes.number,
                LeaderLineY: PropTypes.number,
                GeoJSON: PropTypes.shape({}),
            }),
        ),
        highlightIndex: PropTypes.number,
        // The order a layer should be rendered in, higher number displays on top
        priority: PropTypes.number.isRequired,
        colorPicker: PropTypes.func.isRequired,
    };

    render() {
        const {
            data,
            checked,
            name,
            highlightIndex,
            priority,
            colorPicker,
            ...leafletInjectedProps
        } = this.props;

        const zIndex = ZINDEX.MAP_PANE + priority;

        if (!data || !Array.isArray(data) || !data.length) {
            return null;
        }

        return (
            <LayersControl.Overlay
                {...leafletInjectedProps}
                checked={checked}
                name={name}
            >
                <LayerGroup>
                    <Pane name="EventPath" style={{ zIndex }}>
                        {data.map((d, i) => {
                            const options = {
                                innerColor: colorPicker(i),
                                opacity:
                                    highlightIndex === i ||
                                    highlightIndex === null
                                        ? 1
                                        : 0.3,
                            };
                            return (
                                <DecoratedPath
                                    key={i}
                                    positions={d.GeoJSON.coordinates}
                                    zIndex={zIndex}
                                    options={options}
                                />
                            );
                        })}
                    </Pane>
                </LayerGroup>
            </LayersControl.Overlay>
        );
    }
}

export default EventPaths;
