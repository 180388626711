import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import ReactHintFactory from 'react-hint';
import Routes from './Routes';
import AppInit from './Components/AppInit';
import { QueryClient, QueryClientProvider } from 'react-query';

const ReactHint = ReactHintFactory(React);
const queryClient = new QueryClient();

const Root = ({ store }) => (
    <Provider store={store}>
        <div id="react-root">
            <AppInit>
                <QueryClientProvider client={queryClient}>
                    <Routes />
                    <ReactHint events={true} />
                    <ReduxToastr
                        timeOut={5000}
                        newestOnTop={false}
                        preventDuplicates={true}
                        position="top-right"
                        transitionIn="fadeIn"
                        transitionOut="fadeOut"
                        progressBar={true}
                    />
                </QueryClientProvider>
            </AppInit>
        </div>
    </Provider>
);

Root.propTypes = {
    store: PropTypes.shape({}).isRequired,
};

export default Root;
