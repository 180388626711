import { combineReducers } from 'redux';
import { reducer as filters } from '../Modules/filters';
import { reducer as spinner } from '../Modules/spinner';
import { reducer as truckAlertEventsByShiftId } from '../Modules/truckAlertEventsByShiftId';
import { reducer as timingEquipmentUptime } from '../Modules/timingEquipmentUptime';
import { reducer as maxMineAlertCategories } from '../Modules/maxMineAlertCategories';
import namespace from '../Modules/namespace';

export const REDUCER_KEY = namespace;
export default combineReducers({
    filters,
    spinner,
    truckAlertEventsByShiftId,
    timingEquipmentUptime,
    maxMineAlertCategories,
});
