import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as types from '@rs/core/types';
import { Layout, Row, Col } from '@rs/core/falcon';
import { FEATURE_IDS } from '@rs/core/features';
import * as actions from '../Actions';
import { stateSelectors } from '../Reducers';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import OperatorFeedbackFilters from './OperatorFeedbackFilters';
import OperatorFeedbackConformance from './OperatorFeedbackConformance';
import OperatorFeedbackProductivity from './OperatorFeedbackProductivity';
import {
    getComponentConfig,
    getTopLevelWhereOnMinesiteIdDepth,
    getPageTitle,
} from '../../App/Selectors';

const { SubHeader, Content } = Layout;

class OperatorFeedback extends Component {
    componentDidMount() {
        this.props.updateFiltersWithUrlParams();
    }

    render() {
        const {
            spinner,
            filters,
            onFilterChange,
            driverShift,
            cornerLeagueTotal,
            wktData,
            tyresAndSafetyData,
            conformanceSummaryBadEvents,
            driverEventJsons,
            worstDriverEvents,
            errorMessage,
            tyresAndSafety,
            mapFeatures,
            minesiteAreasTree,
            topLevelWhereOnMinesiteIdDepth,
            minesiteMapConfig,
            title,
        } = this.props;

        return (
            <React.Fragment>
                <SubHeader>
                    <SubHeader.Left>
                        <SubHeader.Title>{title}</SubHeader.Title>
                    </SubHeader.Left>
                </SubHeader>
                <Content>
                    <LoadingSpinner
                        isActive={spinner.isActive}
                        message={spinner.message}
                    />
                    <Row>
                        <Col span={24}>
                            <OperatorFeedbackFilters
                                filters={filters}
                                onDateFilterChange={onFilterChange}
                                driverShift={driverShift}
                            />
                        </Col>
                        <Col xl={12}>
                            <OperatorFeedbackConformance
                                cornerLeagueTotal={cornerLeagueTotal}
                                wktData={wktData}
                                cornerEvents={tyresAndSafetyData.mapEvents}
                                speedingEvents={
                                    tyresAndSafetyData.speedingEvents
                                }
                                speedLimitedZones={
                                    tyresAndSafetyData.speedLimitedZones
                                }
                                clusters={tyresAndSafetyData.topClusters}
                                rampEvents={tyresAndSafetyData.rampEvents}
                                mapLabels={tyresAndSafetyData.mapLabels}
                                errorMessage={errorMessage}
                                conformanceSummaryBadEvents={
                                    conformanceSummaryBadEvents
                                }
                                mapFeatures={mapFeatures}
                                minesiteAreasTree={minesiteAreasTree}
                                topLevelWhereOnMinesiteIdDepth={
                                    topLevelWhereOnMinesiteIdDepth
                                }
                                minesiteMapConfig={minesiteMapConfig}
                            />
                        </Col>
                        <Col xl={12}>
                            <OperatorFeedbackProductivity
                                driverEventJsons={driverEventJsons}
                                worstDriverEvents={worstDriverEvents}
                                wktData={wktData}
                                errorMessage={errorMessage}
                                tyresAndSafety={tyresAndSafety}
                                mapFeatures={mapFeatures}
                                minesiteAreasTree={minesiteAreasTree}
                                topLevelWhereOnMinesiteIdDepth={
                                    topLevelWhereOnMinesiteIdDepth
                                }
                                minesiteMapConfig={minesiteMapConfig}
                            />
                        </Col>
                    </Row>
                </Content>
            </React.Fragment>
        );
    }
}

OperatorFeedback.propTypes = {
    spinner: types.typeSpinner,
    filters: PropTypes.shape({
        Date: PropTypes.string.isRequired,
    }).isRequired,
    onFilterChange: PropTypes.func.isRequired,
    driverShift: PropTypes.shape({}),
    cornerLeagueTotal: types.typeCornerLeagueTotal,
    wktData: types.typeWktData,
    tyresAndSafetyData: PropTypes.shape({
        mapEvents: PropTypes.arrayOf(types.typeCornerEventRow),
        speedingEvents: PropTypes.arrayOf(types.typeSpeedingEventsRow),
        speedLimitedZones: PropTypes.arrayOf(types.typeSpeedLimitedZoneRow),
        clusters: PropTypes.arrayOf(types.typeTopClusterRow),
        rampEvents: PropTypes.arrayOf(types.typeRampEventRow),
        mapLabels: PropTypes.arrayOf(types.typeMapLabelRow),
        topClusters: PropTypes.arrayOf(types.typeTopClusterRow),
    }),
    errorMessage: PropTypes.string,
    conformanceSummaryBadEvents: PropTypes.shape({
        corner: PropTypes.number,
        zone: PropTypes.number,
        ramp: PropTypes.number,
    }),
    driverEventJsons: PropTypes.shape({}),
    updateFiltersWithUrlParams: PropTypes.func.isRequired,
    worstDriverEvents: PropTypes.arrayOf(PropTypes.shape({})),
    tyresAndSafety: PropTypes.shape({}),
    mapFeatures: PropTypes.arrayOf(types.typeMapFeature),
    minesiteAreasTree: types.typeMinesiteAreaTree,
    topLevelWhereOnMinesiteIdDepth: PropTypes.number,
    minesiteMapConfig: types.typeMinesiteMapConfig,
    title: PropTypes.string,
};

const mapStateToProps = (state) => ({
    spinner: stateSelectors.getSpinnerState(state),
    filters: stateSelectors.getFilters(state),
    filterOptions: stateSelectors.getFilterOptions(state),
    tyresAndSafetyData: stateSelectors.getTyresAndSafetyData(state),
    wktData: stateSelectors.getWktData(state),
    cornerLeagueTotal: stateSelectors.getCornerLeagueTotal(state),
    worstDriverEvents: stateSelectors.getTopEventsByLostTime(state, 2),
    driverEventJsons: stateSelectors.getDriverEventJsons(state),
    errorMessage: stateSelectors.getErrorMessage(state),
    driverShift: stateSelectors.getAllDrivers(state)[0],
    conformanceSummaryBadEvents: stateSelectors.getConformanceSummaryBadEvents(
        state,
    ),
    tyresAndSafety: state.OperatorFeedback.tyresAndSafety,
    mapFeatures: stateSelectors.getMapFeatures(state),
    minesiteAreasTree: stateSelectors.getMinesiteAreasTree(state),
    topLevelWhereOnMinesiteIdDepth: getTopLevelWhereOnMinesiteIdDepth(state),
    minesiteMapConfig: getComponentConfig(state, 'MinesiteMap'),
    title: getPageTitle(state, FEATURE_IDS.TAB_ID__OPERATOR_FEEDBACK),
});

const mapActionsToDispatch = (dispatch) => ({
    onFilterChange(filterName, filterValue) {
        dispatch(actions.filterUpdated(filterName, filterValue));
    },
    updateFiltersWithUrlParams() {
        dispatch(actions.updateFiltersWithUrlParams());
    },
});

const enhance = connect(mapStateToProps, mapActionsToDispatch);

export default enhance(OperatorFeedback);
