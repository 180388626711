import React from 'react';
import cn from 'classnames';
import { LoadUnitImprovementSymbolProps } from './types';
import LoadUnitSymbolPayloadInconsistent from '../../assets/js/LoadUnitSymbolPayloadInconsistent';

const PayloadImproveConsistencySymbol = ({
    className,
}: LoadUnitImprovementSymbolProps) => (
    <LoadUnitSymbolPayloadInconsistent
        className={cn('LoadUnitImprovementSymbol__Icon', className)}
    />
);

export default PayloadImproveConsistencySymbol;
