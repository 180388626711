import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as types from '@rs/core/types';
import { ConformanceCell } from '@rs/core/components';
import { FEATURE_IDS } from '@rs/core/features';
import { Row, Col } from '@rs/core/falcon';
import { mode, arrayToFriendlyString } from '@rs/core/utils/dataUtils';
import withComponentConfig from '../../HOC/withComponentConfig';
import { HasFeature } from '../../Components/HasFeature';

const ConformanceCellWithConfig = withComponentConfig(
    'ConformanceDefault',
    ConformanceCell,
);

class OperatorFeedbackConformanceSummary extends Component {
    conformanceCellRenderer({
        WrapperComponent,
        formattedValue,
        conformanceClassName,
    }) {
        return (
            <WrapperComponent
                className={`Table__Cell--Number ${conformanceClassName}`}
            >
                {formattedValue}%
            </WrapperComponent>
        );
    }

    render() {
        const {
            cornerLeagueTotal,
            conformanceSummaryBadEvents,
            cornerEvents,
        } = this.props;
        if (
            !Object.keys(cornerLeagueTotal).length ||
            !Object.keys(conformanceSummaryBadEvents).length
        )
            return null;
        const {
            OverspeedConformance,
            RampConformance,
            StraightConformance,
            TotalConformance,
            ProductivityConformance,
        } = cornerLeagueTotal;

        const { corner, zone, ramp } = conformanceSummaryBadEvents;

        const mostCommonLabels = mode(cornerEvents.map((event) => event.Label));

        return (
            <Row>
                <Col span={24}>Conformance Summary</Col>
                <table className="Table--Default OperatorFeedbackConformanceSummaryTable">
                    <thead>
                        <tr>
                            <th aria-label={'Empty Cell'} />
                            <th className="Table__Cell--Number">Corner</th>
                            <th className="Table__Cell--Number">Zone</th>
                            <th className="Table__Cell--Number">Ramp</th>
                            <HasFeature
                                featureId={
                                    FEATURE_IDS.LEAGUE__TABLE_SHOW_PRODUCTIVITY_COLUMN
                                }
                            >
                                <th className="Table__Cell--Number">
                                    Operator Efficiency
                                </th>
                            </HasFeature>
                            <th className="Table__Cell--Number">Overall</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Improvement Events</th>
                            <td className="Table__Cell--Number">
                                {corner}
                                <br />
                                {arrayToFriendlyString(mostCommonLabels)}
                            </td>
                            <td className="Table__Cell--Number">{zone}</td>
                            <td className="Table__Cell--Number">{ramp}</td>
                            <HasFeature
                                featureId={
                                    FEATURE_IDS.LEAGUE__TABLE_SHOW_PRODUCTIVITY_COLUMN
                                }
                            >
                                <td />
                            </HasFeature>
                            <td />
                        </tr>
                        <tr>
                            <th>Conformance</th>
                            <ConformanceCellWithConfig
                                conformanceValue={OverspeedConformance}
                                roundToPlace={1}
                                render={this.conformanceCellRenderer}
                            />
                            <ConformanceCellWithConfig
                                conformanceValue={StraightConformance}
                                roundToPlace={1}
                                render={this.conformanceCellRenderer}
                            />
                            <ConformanceCellWithConfig
                                conformanceValue={RampConformance}
                                roundToPlace={1}
                                render={this.conformanceCellRenderer}
                            />
                            <HasFeature
                                featureId={
                                    FEATURE_IDS.LEAGUE__TABLE_SHOW_PRODUCTIVITY_COLUMN
                                }
                            >
                                <ConformanceCellWithConfig
                                    conformanceValue={ProductivityConformance}
                                    roundToPlace={1}
                                    render={this.conformanceCellRenderer}
                                />
                            </HasFeature>
                            <ConformanceCellWithConfig
                                conformanceValue={TotalConformance}
                                roundToPlace={1}
                                render={this.conformanceCellRenderer}
                            />
                        </tr>
                    </tbody>
                </table>
            </Row>
        );
    }
}

OperatorFeedbackConformanceSummary.propTypes = {
    cornerLeagueTotal: types.typeCornerLeagueTotal,
    conformanceSummaryBadEvents: PropTypes.shape({
        corner: PropTypes.number,
        zone: PropTypes.number,
        ramp: PropTypes.number,
    }),
    cornerEvents: PropTypes.arrayOf(types.typeCornerEventRow),
};

export default OperatorFeedbackConformanceSummary;
