import PropTypes from 'prop-types';
import * as PublicSiteConfig from './publicSiteConfig';
import * as PrivateSiteConfig from './privateSiteConfig';

export { PublicSiteConfig };
export { PrivateSiteConfig };

export const typeEquipmentLatency = PropTypes.shape({
    CalculationTimestamp: PropTypes.number,
    EquipmentClass: PropTypes.string,
    EquipmentId: PropTypes.number,
    EquipmentModel: PropTypes.string,
    EquipmentSiteName: PropTypes.string,
    IsValidLatency: PropTypes.number,
    LastKnownReading: PropTypes.number,
    Latitude: PropTypes.number,
    Location: PropTypes.string,
    LoggerId: PropTypes.number,
    Longitude: PropTypes.number,
});

// This should be related to App/models/RampEvent.go:RampEvent_RecordForFilter_ResponseRow
export const typeRampEventRow = PropTypes.shape({
    // Id
    // S3Id
    // EquipmentId
    // DriverId
    // EventTime
    GearLimitUpper: PropTypes.number,
    SpeedLimitUpper: PropTypes.number,
    RampMidX: PropTypes.number,
    RampMidY: PropTypes.number,
    RampDistance: PropTypes.number,
    RampAverageGrade: PropTypes.number,
    HighestGear: PropTypes.number,
    // NonConformantDistance
    MedianNonConformantX: PropTypes.number,
    MedianNonConformantY: PropTypes.number,
    // MedianNonConformantGrade
    Payload: PropTypes.number,
    MaxSpeed: PropTypes.number,
});

export const typeChildren = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.element,
]);

export const typeHtmlFor = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
]);

export const typeCornerLeagueRow = PropTypes.shape({
    Driver: PropTypes.string,
    Crew: PropTypes.string,
    TotalConformance: PropTypes.number,
    UnderspeedConformance: PropTypes.number,
    OverspeedConformance: PropTypes.number,
    StraightConformance: PropTypes.number,
    RampConformance: PropTypes.number,
    TotalCorners: PropTypes.number,
    TotalRegionEvents: PropTypes.number,
    TotalRampEvents: PropTypes.number,
    DrivingDuration: PropTypes.number,
});

// TODO: Replace typeCornerLeagueRow with tyresAndSafetyRow
export const tyresAndSafetyRow = PropTypes.shape({
    Driver: PropTypes.string,
    Crew: PropTypes.string,
    TotalConformance: PropTypes.number,
    UnderspeedConformance: PropTypes.number,
    OverspeedConformance: PropTypes.number,
    StraightConformance: PropTypes.number,
    RampConformance: PropTypes.number,
    ProductivityConformance: PropTypes.number,
    TotalCorners: PropTypes.number,
    TotalRegionEvents: PropTypes.number,
    TotalRampEvents: PropTypes.number,
});

export const typeCornerLeagueTotal = PropTypes.shape({
    TotalConformance: PropTypes.number,
    UnderspeedConformance: PropTypes.number,
    OverspeedConformance: PropTypes.number,
    StraightConformance: PropTypes.number,
    RampConformance: PropTypes.number,
    TotalCorners: PropTypes.number,
    TotalRegionEvents: PropTypes.number,
    TotalRampEvents: PropTypes.number,
    DrivingDuration: PropTypes.number,
});

export const typeSVGPath = PropTypes.string;

export interface TypeWKTLocusGeoJSON {
    coordinates: any;
    type: string;
}

export interface TypeWKTLocus {
    SVGPath: string;
    GeoJSON: TypeWKTLocusGeoJSON;
}

export interface TypeWktData {
    WKTLocuses: TypeWKTLocus[];
    XMeterDomain: number[];
    YMeterDomain: number[];
}

export const typeWktData = PropTypes.shape({
    WKTLocuses: PropTypes.arrayOf(
        PropTypes.shape({
            SVGPath: typeSVGPath,
        }),
    ),
    XMeterDomain: PropTypes.arrayOf(PropTypes.number),
    YMeterDomain: PropTypes.arrayOf(PropTypes.number),
});

export const typeSpeedLimitedZoneRow = PropTypes.shape({
    AllTimeZoneId: PropTypes.number,
    Name: PropTypes.string,
    SVGPath: typeSVGPath,
    SignPostedSpeedLimitUpper: PropTypes.number,
    SignPostedSpeedLimitedLower: PropTypes.number,
    ThresholdSpeedLimitUpper: PropTypes.number,
    ThresholdSpeedLimitLower: PropTypes.number,
    Color: PropTypes.string,
});

export const typeConformanceRow = PropTypes.shape({
    Date: PropTypes.number,
    Conformance: PropTypes.number,
});

export const typeSpeedingEventsRow = PropTypes.shape({
    X: PropTypes.number,
    Y: PropTypes.number,
    Speed: PropTypes.number,
    SpeedLimitUpper: PropTypes.number,
    Payload: PropTypes.number,
});

export const typeSpinner = PropTypes.shape({
    isActive: PropTypes.bool,
    message: PropTypes.string,
    faIcon: PropTypes.string,
});

export interface TypeSpinner {
    isActive: boolean;
    message: string;
    faIcon: string | undefined;
}

export interface TypeMapLabel {
    DisplayText: string;
    EndShiftId: number;
    Id: number;
    LabelAngle: number;
    StartShiftId: number;
    X: number;
    Y: number;
}

export const typeMapLabelRow = PropTypes.shape({
    DisplayText: PropTypes.string,
    EndShiftId: PropTypes.number,
    Id: PropTypes.number,
    LabelAngle: PropTypes.number,
    StartShiftId: PropTypes.number,
    X: PropTypes.number,
    Y: PropTypes.number,
});

export const typeTopClusterRow = PropTypes.shape({
    Rank: PropTypes.number,
    ClusterId: PropTypes.number,
    UnderSpeed: PropTypes.number,
    OverSpeed: PropTypes.number,
    Corners: PropTypes.number,
    Min: PropTypes.number,
    Avg: PropTypes.number,
    Max: PropTypes.number,
    Conformance: PropTypes.number,
    X: PropTypes.number,
    Y: PropTypes.number,
});

export const typeCornerEventRow = PropTypes.shape({
    CircleOriginX: PropTypes.number,
    CircleOriginY: PropTypes.number,
    CircleRadius: PropTypes.number,
    Curvature: PropTypes.number,
    CornerAngle: PropTypes.number,
    ArcCenterX: PropTypes.number,
    ArcCenterY: PropTypes.number,
    ArcLength: PropTypes.number,
    HeadingFromCircleOriginToArcCenter: PropTypes.number,
    SpeedLimitUpper: PropTypes.number,
    SpeedLimitLower: PropTypes.number,
    ClusterId: PropTypes.number,
    Label: PropTypes.string,
});

export const typeDriverShiftProductivityEvent = PropTypes.shape({
    DataFileEndOffset: PropTypes.number,
    DataFileStartOffset: PropTypes.number,
    Id: PropTypes.number,
    EquipmentId: PropTypes.number,
    PassCount: PropTypes.number,
    S3Id: PropTypes.number,
    ShiftId: PropTypes.number,
    ShiftProductivityEventId: PropTypes.number,
    TotalLostTime: PropTypes.number,
    TotalTime: PropTypes.number,
    TruckName: PropTypes.string,
    TruckType: PropTypes.string,
    UniqueDriverId: PropTypes.string,
});

export const typeShiftProductivityEvent = PropTypes.shape({
    Heading: PropTypes.number,
    HeadingArrowCenterX: PropTypes.number,
    HeadingArrowCenterY: PropTypes.number,
    Id: PropTypes.number,
    LeaderLinePos: PropTypes.string,
    ShiftId: PropTypes.number,
    ShiftProductivityEventId: PropTypes.number,
    TotalLostTime: PropTypes.number,
    WKTLoci: PropTypes.string,
});

export const typeLoadingEvents = PropTypes.shape({
    HaulTruckSiteName: PropTypes.string,
    Id: PropTypes.number,
    LoadUnitSiteName: PropTypes.string,
    Location: PropTypes.string,
    EquipmentId: PropTypes.number,
    NormalForceFront: PropTypes.number,
    NormalForceRear: PropTypes.number,
    OperatorDisplayName: PropTypes.string,
    PVTX: PropTypes.number,
    PVTY: PropTypes.number,
    Payload: PropTypes.number,
    PayloadCGLateralOffset: PropTypes.number,
    PayloadCGOffsetFromRearAxle: PropTypes.number,
    VehicleCGPercentLateralOffsetFromIdeal: PropTypes.number,
    VehicleCGPercentLongitudinalOffsetFromIdeal: PropTypes.number,
    S3Id: PropTypes.number,
    ShiftId: PropTypes.number,
    StartTime: PropTypes.number,
    StopTime: PropTypes.number,
    TruckType: PropTypes.string,
});

export const typeLoadingEventConformanceReportRow = PropTypes.shape({
    QueueTimeAvg: PropTypes.number,
    EventTimeAvg: PropTypes.number,
    EventTimeSum: PropTypes.number,
    PayloadScoreAvg: PropTypes.number,
    TimeScoreAvg: PropTypes.number,
    BiasScoreAvg: PropTypes.number,
    LoadAvg: PropTypes.number,
    LoadCount: PropTypes.number,
    LoadSum: PropTypes.number,
    OperatorDisplayName: PropTypes.string,
});

export const typeSurfaceRoughness = PropTypes.shape({
    xLF: PropTypes.arrayOf(PropTypes.number),
    yLF: PropTypes.arrayOf(PropTypes.number),
    Mean_LF_Force_rms_loaded: PropTypes.arrayOf(PropTypes.number),
    Mean_LF_Force_rms_unloaded: PropTypes.arrayOf(PropTypes.number),
    xRF: PropTypes.arrayOf(PropTypes.number),
    yRF: PropTypes.arrayOf(PropTypes.number),
    Mean_RF_Force_rms_loaded: PropTypes.arrayOf(PropTypes.number),
    Mean_RF_Force_rms_unloaded: PropTypes.arrayOf(PropTypes.number),
});

export const typeFormattedSurfaceRoughness = PropTypes.shape({
    bin: PropTypes.number.isRequired,
    bins: PropTypes.arrayOf(
        PropTypes.shape({
            xLF: PropTypes.number,
            yLF: PropTypes.number,
            Mean_LF_Force_rms_loaded: PropTypes.number,
            Mean_LF_Force_rms_unloaded: PropTypes.number,
            xRF: PropTypes.number,
            yRF: PropTypes.number,
            Mean_RF_Force_rms_loaded: PropTypes.number,
            Mean_RF_Force_rms_unloaded: PropTypes.number,
        }),
    ),
    xKey: PropTypes.string.isRequired,
    yKey: PropTypes.string.isRequired,
});

export const typeHaulTruckFillFactor = PropTypes.shape({
    CycleSummariesShiftDataId: PropTypes.number,
    HaulTruckSiteName: PropTypes.string,
    HaulTruckType: PropTypes.string,
    PayloadReported: PropTypes.number,
    Shift: PropTypes.string,
    ShiftId: PropTypes.number,
    PayloadTarget: PropTypes.number,
    OperatorSiteId: PropTypes.number,
    EventTime: PropTypes.number,
    IsBadPayload: PropTypes.number,
    LoadUnitSiteName: PropTypes.string,
    LoadUnitType: PropTypes.string,
    OperatorDisplayName: PropTypes.string,
    payloadDelta: PropTypes.number,
});

export const typeMapFeature = PropTypes.shape({
    type: PropTypes.string,
    properties: PropTypes.shape({
        category: PropTypes.string,
        name: PropTypes.string,
        firstshift: PropTypes.number,
        lastshift: PropTypes.number,
        iteration: PropTypes.number,
        material: PropTypes.array,
    }),
    geometry: PropTypes.shape({
        type: PropTypes.string,
        coordinates: PropTypes.arrayOf(
            PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
        ),
    }),
});

export const typeLastShiftClassificationTotals = PropTypes.shape({
    totals: PropTypes.shape({
        ExtremelyRough: PropTypes.number,
        Light: PropTypes.number,
        Moderate: PropTypes.number,
        Rough: PropTypes.number,
        Smooth: PropTypes.number,
    }),
    shiftId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const typeLoadUnitOperatorLogs = PropTypes.shape({
    Id: PropTypes.number,
    LoadUnitSiteName: PropTypes.string,
    LoadUnitType: PropTypes.string,
    OperatorCrew: PropTypes.string,
    OperatorDisplayName: PropTypes.string,
    OperatorSiteId: PropTypes.number,
    S3Id: PropTypes.number,
    ShiftId: PropTypes.number,
    TimestampOff: PropTypes.number,
    TimestampOn: PropTypes.number,
});

export const typeFileExportDropdownConfig = PropTypes.shape({
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
        }),
    ),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    onChange: PropTypes.func.isRequired,
});

export const typeMinesiteAreaTree = PropTypes.shape({
    // Children: PropTypes.arrayOf(PropTypes.shape({})),
    Depth: PropTypes.number,
    Name: PropTypes.string,
    WhereOnMinesiteId: PropTypes.string,
});
// Children is a recursive array of the same object.
// @ts-ignore
typeMinesiteAreaTree.Children = PropTypes.arrayOf(
    typeMinesiteAreaTree,
).isRequired;

export const typeHaulRoadConditionEvent = PropTypes.shape({
    Level: PropTypes.oneOf(['High', 'Medium', 'Low']),
    EquipmentId: PropTypes.number,
    ShiftId: PropTypes.number,
    Time: PropTypes.number,
    Type: PropTypes.string,
    X: PropTypes.number,
    Y: PropTypes.number,
});

export const typeShiftConfiguration = PropTypes.shape({
    timezoneAtMinesite: PropTypes.string,
    primaryShiftPatterns: PropTypes.arrayOf(
        PropTypes.shape({
            crewCadences: PropTypes.arrayOf(
                PropTypes.shape({
                    Cadence: PropTypes.string.isRequired,
                    Crew: PropTypes.string.isRequired,
                }).isRequired,
            ).isRequired,
            shifts: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    shortName: PropTypes.string.isRequired,
                    startTime: PropTypes.string.isRequired,
                }).isRequired,
            ).isRequired,
            startDate: PropTypes.string.isRequired,
        }),
    ),
});

export const typeNavbarConfig = PropTypes.shape({
    groupOrder: PropTypes.arrayOf(PropTypes.number),
    groups: PropTypes.shape({
        // <GroupId> : PropTypes.shape({
        //     groupId: PropTypes.string,
        //     label: PropTypes.string,
        //     linkIds: PropTypes.ArrayOf(PropTypes.number),
        // }),
    }),
    links: PropTypes.shape({
        // <LinkId>: PropTypes.shape({
        //     path: PropTypes.string.isRequired,
        //     label: PropTypes.string.isRequired,
        //     featureId: PropTypes.number.isRequired,
        //     categoryId: PropTypes.number.isRequired,
        // })
    }),
});

export const typeGearLockdown = PropTypes.shape({
    GearLimitUpper: PropTypes.number,
    ShiftId: PropTypes.number.isRequired,
    SpeedLimitUpper: PropTypes.number,
    StartTime: PropTypes.number.isRequired,
    StopTime: PropTypes.number.isRequired,
});

export interface TypeFilterOptions {
    label: string;
    value: string | number;
    clearableValue?: boolean;
    disabled?: boolean;
}

export const typeFilterOptions = PropTypes.arrayOf(
    PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
);

export const typeConformanceLegendRangeBands = PropTypes.arrayOf(
    PropTypes.shape({
        min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        className: PropTypes.string,
        wallboardClassName: PropTypes.string,
        text: PropTypes.string,
    }),
).isRequired;

export interface TypeConformanceSymbologyWallboardReport {
    OperatorDisplayName: string;
    TotalConformance: number | null;
    PayloadScoreAvg: number | null;
    PayloadLoadCount: number;
    BiasLoadCount: number | null;
    LoadSum: number;
    TimeScoreAvg: number | null;
    BiasScoreAvg: number | null;
    LoadsInRangeSymbol: Array<string | null>;
    BiasLoadsSymbol: Array<string | null>;
    ArrowDirection: number | null;
    EventTimeCount: number | null;
    EventTimeAvg: number | null;
    LoadUnitCategory: string | null;
    LoadUnitCategoryDisplayName: string | null;
    LoadUnitSiteName: Array<string | null>;
    EventCountsPerTruck: {
        EventTimeCount: number;
        EventTimeSum: number;
        PayloadTarget: number;
        TruckType: string;
    }[];
}

export const typeConformanceSymbologyWallboardReport = PropTypes.shape({
    ArrowDirection: PropTypes.number,
    BiasLoadCount: PropTypes.number,
    BiasLoadsSymbol: PropTypes.arrayOf(PropTypes.string),
    BiasScoreAvg: PropTypes.number,
    EventCountsPerTruck: PropTypes.arrayOf(
        PropTypes.shape({
            EventTimeCount: PropTypes.number,
            EventTimeSum: PropTypes.number,
            PayloadTarget: PropTypes.number,
            TruckType: PropTypes.string,
        }),
    ),
    EventTimeAvg: PropTypes.number,
    EventTimeCount: PropTypes.number,
    LoadSum: PropTypes.number,
    LoadUnitCategory: PropTypes.string,
    LoadUnitCategoryDisplayName: PropTypes.string,
    LoadUnitSiteName: PropTypes.arrayOf(PropTypes.string),
    LoadsInRangeSymbol: PropTypes.arrayOf(PropTypes.string),
    OperatorDisplayName: PropTypes.string,
    PayloadLoadCount: PropTypes.number,
    PayloadScoreAvg: PropTypes.number,
    TimeScoreAvg: PropTypes.number,
    loadUnitCategoryLastXDaysAvgEventTime: PropTypes.number,
});

export const typePrivateSiteConfig = PropTypes.shape({
    collectionCustomSpatialReferenceId: PropTypes.string,
    companyAPIUrl: PropTypes.string,
    cribRoom: PropTypes.shape({
        driverNameCharacterLimit: PropTypes.number,
        onlyShowDriverId: PropTypes.bool,
        shift: PropTypes.shape({
            crewCadences: PropTypes.arrayOf(
                PropTypes.shape({
                    Cadence: PropTypes.string,
                    Crew: PropTypes.string,
                }),
            ),
            referenceDate: PropTypes.string,
        }),
        showProductivityColumn: PropTypes.bool,
    }),
    filtering: PropTypes.shape({
        activities: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string,
            }),
        ),
        materialTypes: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string,
            }),
        ),
    }),
    interfaceSettings: PropTypes.shape({
        mapExtents: PropTypes.shape({
            XMeterDomain: PropTypes.arrayOf(PropTypes.number),
            YMeterDomain: PropTypes.arrayOf(PropTypes.number),
        }),
    }),
    primaryShiftPatterns: PropTypes.arrayOf(
        PropTypes.shape({
            crewCadences: PropTypes.arrayOf(
                PropTypes.shape({
                    Cadence: PropTypes.string,
                    Crew: PropTypes.string,
                }),
            ),
            referenceDate: PropTypes.string,
            shifts: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    shortName: PropTypes.string,
                    startTime: PropTypes.string,
                }),
            ),
            startDate: PropTypes.string,
        }),
    ),
    s3Buckets: PropTypes.shape({
        media: PropTypes.shape({
            accessMethods: PropTypes.shape({
                download: PropTypes.shape({
                    enabled: PropTypes.bool,
                    keyExpirySeconds: PropTypes.number,
                }),
                list: PropTypes.shape({
                    enabled: PropTypes.bool,
                }),
                upload: PropTypes.shape({
                    enabled: PropTypes.bool,
                    keyExpirySeconds: PropTypes.number,
                }),
            }),
            bucketName: PropTypes.string,
            regionName: PropTypes.string,
            resultsKeyPrefix: PropTypes.string,
        }),
        s3ResultsBucket: PropTypes.shape({
            accessMethods: PropTypes.shape({
                download: PropTypes.shape({
                    enabled: PropTypes.bool,
                }),
                list: PropTypes.shape({
                    enabled: PropTypes.bool,
                }),
            }),
            bucketName: PropTypes.string,
            regionName: PropTypes.string,
            resultsKeyPrefix: PropTypes.string,
        }),
    }),
    siteRules: PropTypes.shape({
        globalSpeedLimit: PropTypes.number,
        preShiftCribScreeningOffset: PropTypes.shape({
            hours: PropTypes.number,
            minutes: PropTypes.number,
        }),
        shiftEndTime: PropTypes.shape({
            hours: PropTypes.number,
            minutes: PropTypes.number,
        }),
        shiftStartTime: PropTypes.shape({
            hours: PropTypes.number,
            minutes: PropTypes.number,
        }),
    }),
});

export const typePublicSiteConfig = PropTypes.shape({
    minesiteId: PropTypes.string.isRequired,
    minesiteDisplayName: PropTypes.string.isRequired,
    featureToggles: PropTypes.shape({}),
    analytics: PropTypes.shape({
        google: PropTypes.shape({
            'tracking-id': PropTypes.string,
        }),
    }),
    timezoneAtMinesite: PropTypes.string,
    component: PropTypes.shape({
        AppLanding: PropTypes.shape({
            supportEmailAddress: PropTypes.string,
        }),
        LoadUnitCharts: PropTypes.shape({
            payloadFromTargetMin: PropTypes.number,
            payloadFromTargetMax: PropTypes.number,
        }),
        LoadUnitTable: PropTypes.shape({
            style: PropTypes.shape({
                height: PropTypes.number,
            }),
            minRows: PropTypes.number,
        }),
        ConformanceDefault: PropTypes.shape({
            rangeBands: PropTypes.arrayOf(
                PropTypes.shape({
                    min: PropTypes.oneOfType([
                        PropTypes.number,
                        PropTypes.string,
                    ]),
                    max: PropTypes.oneOfType([
                        PropTypes.number,
                        PropTypes.string,
                    ]),
                    className: PropTypes.string,
                    wallboardClassName: PropTypes.string,
                }),
            ),
        }),
        ConformanceLoadUnits: PropTypes.shape({
            rangeBands: PropTypes.arrayOf(
                PropTypes.shape({
                    min: PropTypes.oneOfType([
                        PropTypes.number,
                        PropTypes.string,
                    ]),
                    max: PropTypes.oneOfType([
                        PropTypes.number,
                        PropTypes.string,
                    ]),
                    className: PropTypes.string,
                    wallboardClassName: PropTypes.string,
                }),
            ),
        }),
        MovementStatus: PropTypes.arrayOf(
            PropTypes.shape({
                min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                units: PropTypes.string,
                wallboardClassName: PropTypes.string,
            }),
        ),
        HaulTruckLeagueTrend: PropTypes.shape({
            numDaysToCompare: PropTypes.number,
            numOperatorsToCompare: PropTypes.number,
            minZonePassesToBeConsidered: PropTypes.number,
            minRampPassesToBeConsidered: PropTypes.number,
        }),
    }),
});

export const typeMinesiteMapConfig = PropTypes.shape({
    accessToken: PropTypes.string,
    bounds: PropTypes.shape({
        LatitudeDomain: PropTypes.arrayOf(PropTypes.number),
        LongitudeDomain: PropTypes.arrayOf(PropTypes.number),
    }),
});

export interface TypeTruckAlertEvent {
    AlertLevel: number;
    AlertLevelIncrease: number;
    AlertSource: string;
    AlertType: string;
    EngineSpeedTimeWindowedMax: number;
    EventTime: number;
    FailureModeIdentifier: number;
    InWorkshop: number;
    Latitude: number;
    EquipmentId: number;
    Longitude: number;
    ManufacturerAlertId: string;
    ManufacturerSensorCode: number;
    MaxMineAlertCategory: string;
    MaxMineAlertId: string;
    Payload: number;
    S3Id: number;
    ShiftId: number;
    TruckName: string;
    UniqueDriverId: string;
    X: number;
    Y: number;
}

export const typeTruckAlertEvent = PropTypes.shape({
    AlertLevel: PropTypes.number,
    AlertLevelIncrease: PropTypes.number,
    AlertSource: PropTypes.string,
    AlertType: PropTypes.string,
    EngineSpeedTimeWindowedMax: PropTypes.number,
    EventTime: PropTypes.number,
    FailureModeIdentifier: PropTypes.number,
    InWorkshop: PropTypes.number,
    Latitude: PropTypes.number,
    EquipmentId: PropTypes.number,
    Longitude: PropTypes.number,
    ManufacturerAlertId: PropTypes.string,
    ManufacturerSensorCode: PropTypes.number,
    MaxMineAlertCategory: PropTypes.string,
    MaxMineAlertId: PropTypes.string,
    Payload: PropTypes.number,
    S3Id: PropTypes.number,
    ShiftId: PropTypes.number,
    TruckName: PropTypes.string,
    UniqueDriverId: PropTypes.string,
    X: PropTypes.number,
    Y: PropTypes.number,
});

export const typeConformanceWeighting = PropTypes.shape({
    CornerWeighting: PropTypes.number,
    DumpWeighting: PropTypes.number,
    ProductivityWeighting: PropTypes.number,
    RampWeighting: PropTypes.number,
    ShiftId: PropTypes.number,
    SpottingWeighting: PropTypes.number,
    ZoneWeighting: PropTypes.number,
});

export const typeMinesiteMap = PropTypes.shape({
    background: PropTypes.bool,
    backgroundClientProvided: PropTypes.bool,
    roadPath: PropTypes.bool,
    speedLimitedZones: PropTypes.bool,
    roadLabels: PropTypes.bool,
    cornerEvents: PropTypes.bool,
    speedingInZones: PropTypes.bool,
    rampEvents: PropTypes.bool,
    focusAreaLabels: PropTypes.bool,
    center: PropTypes.arrayOf(PropTypes.number),
    zoom: PropTypes.number,
    isViewportValid: PropTypes.bool,
});

export const typePDFConfig = PropTypes.shape({
    baseURL: PropTypes.string,
    renderEndpoint: PropTypes.string,
});

export interface TypeTruckAllocationConfig {
    featureType: string;
    regions: {
        Name: string;
        WhereOnMinesiteId: string;
    }[];
}
export const typeTruckAllocationConfig = PropTypes.shape({
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            Name: PropTypes.string,
            WhereOnMinesiteId: PropTypes.string,
        }),
    ),
});

export const typeMinesiteNode = PropTypes.shape({
    equipments: PropTypes.arrayOf(PropTypes.string),
    'maximum-occupants': PropTypes.number,
    'maximum-queue-length': PropTypes.number,
    'maximum-queue-time': PropTypes.number,
    'maximum-time': PropTypes.number,
    name: PropTypes.string,
    'next-node': PropTypes.string,
    node_id: PropTypes.string,
    'queued-equipments': PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.string,
    type: PropTypes.string,
});

export const typeMinesiteSection = PropTypes.shape({
    'default-entry-node': PropTypes.string,
    'default-exit-nodes': PropTypes.array,
    'destination-node': PropTypes.number,
    enabled: PropTypes.bool.isRequired,
    'last-request-num': PropTypes.number,
    name: PropTypes.string,
    'source-node': PropTypes.number,
    womid: PropTypes.string,
});

export const typeMinesiteCircuits = PropTypes.shape({
    ...typeMinesiteSection,
    nodes: PropTypes.shape({
        dump: PropTypes.arrayOf(typeMinesiteNode),
        haul: PropTypes.arrayOf(typeMinesiteNode),
        load: PropTypes.arrayOf(typeMinesiteNode),
        return: PropTypes.arrayOf(typeMinesiteNode),
    }),
});

export const typeMinesiteEquipment = PropTypes.shape({
    activity: PropTypes.string,
    'actual-node': PropTypes.string,
    assignments: PropTypes.arrayOf(
        PropTypes.shape({
            'current-node': PropTypes.string,
            'node-entry-time': PropTypes.number,
            sequence: PropTypes.string,
        }),
    ),
    class: PropTypes.string,
    id: PropTypes.string,
    'last-request-num': PropTypes.number,
    model: PropTypes.string,
    operator: PropTypes.string,
    position: PropTypes.shape({
        elevation: PropTypes.number,
        'last-update': PropTypes.number,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
    }),
    'site-name': PropTypes.string,
    status: PropTypes.string,
    subclass: PropTypes.string,
    submodel: PropTypes.string,
});

export const typeMinesite = PropTypes.shape({
    equipment: PropTypes.arrayOf(typeMinesiteEquipment),
    minesite: PropTypes.shape({
        circuits: PropTypes.objectOf(typeMinesiteCircuits),
        'holding-states': PropTypes.objectOf({
            ...typeMinesiteSection,
            // @ts-ignore
            ancillary: PropTypes.arrayOf(typeMinesiteNode),
        }),
        'last-request-num': PropTypes.shape({}),
    }),
    'rest-of-minesite': PropTypes.arrayOf(PropTypes.string),
});

export const typeCircuitNodeType = PropTypes.shape({
    'expected-time': PropTypes.number,
    'map-region': PropTypes.shape({
        type: PropTypes.string,
        geometry: PropTypes.shape({}),
        properties: PropTypes.shape({}),
    }),
    'maximum-occupants': PropTypes.number,
    'maximum-queue-length': PropTypes.number,
    'maximum-queue-time': PropTypes.number,
    'maximum-time': PropTypes.number,
    name: PropTypes.string,
    'next-node': PropTypes.string,
    node_id: PropTypes.string,
    queue: PropTypes.bool,
    subtype: PropTypes.string,
    type: PropTypes.string,
});

export const typeCircuitNodes = PropTypes.shape({
    sources: PropTypes.arrayOf(typeCircuitNodeType),
    destinations: PropTypes.arrayOf(typeCircuitNodeType),
});

export const typeFleetHistoryConfig = PropTypes.shape({
    alertThresholds: PropTypes.shape({
        domain: PropTypes.arrayOf(PropTypes.number),
        range: PropTypes.arrayOf(PropTypes.string),
    }),
});

export const typeEquipmentUptime = PropTypes.shape({
    ShiftId: PropTypes.number,
    EquipmentClass: PropTypes.string,
    EquipmentSubClass: PropTypes.string,
    EquipmentSiteName: PropTypes.string,
    EquipmentModel: PropTypes.string,
    EquipmentSubModel: PropTypes.string,
    Uptime: PropTypes.number,
});

export const typeEngineDiagnosticCurvesByEquipmentName = PropTypes.shape({
    EngineFuelPressure: PropTypes.number,
    EngineInjectorMeteringRail1Pressure: PropTypes.number,
    EngineIntakeManifold1Temperature: PropTypes.number,
    EngineOilPressure: PropTypes.number,
    EnginePower: PropTypes.number,
    EngineSpeed: PropTypes.number,
    EquipmentId: PropTypes.number,
    EquipmentModel: PropTypes.string,
    EquipmentSiteName: PropTypes.string,
    ExhaustGasTemp1: PropTypes.number,
    ExhaustGasTemp2: PropTypes.number,
    FuelRate: PropTypes.number,
    S3Id: PropTypes.number,
    ShiftId: PropTypes.number,
    TorqueAtEngine: PropTypes.number,
    TurboBoostPressure: PropTypes.number,
});

export const typeEquipmentOperatorAlertEvents = PropTypes.shape({
    AlertType: PropTypes.string,
    Equipments: PropTypes.arrayOf(
        PropTypes.shape({
            equipmentSiteName: PropTypes.string,
            equipmentCategory: PropTypes.string,
            startTime: PropTypes.number,
        }),
    ),
    Operators: PropTypes.arrayOf(
        PropTypes.shape({
            operatorDisplayName: PropTypes.string,
            startTime: PropTypes.number,
            stopTime: PropTypes.number,
        }),
    ),
    ShiftId: PropTypes.number,
    StartTime: PropTypes.number,
    StopTime: PropTypes.number,
    TimeThreshold: PropTypes.number,
});

export const typeEquipmentActivityBasic = PropTypes.shape({
    DestinationLatitude: PropTypes.number,
    DestinationLongitude: PropTypes.number,
    DestinationName: PropTypes.string,
    EngineRunning: PropTypes.string,
    EquipmentSiteName: PropTypes.string,
    KeyOn: PropTypes.string,
    MachineMoving: PropTypes.string,
    ShiftDate: PropTypes.string,
    ShiftType: PropTypes.string,
    SourceLatitude: PropTypes.number,
    SourceLongitude: PropTypes.number,
    SourceName: PropTypes.string,
    StartTime: PropTypes.string,
    StopTime: PropTypes.string,
    TotalTime: PropTypes.number,
});

export const typeClientReportsMainOperators = PropTypes.shape({
    EquipmentCategory: PropTypes.string,
    EquipmentModel: PropTypes.string,
    EquipmentOperationalCrew: PropTypes.string,
    EquipmentOperatorCrew: PropTypes.string,
    EquipmentOperatorId: PropTypes.string,
    EquipmentOperatorName: PropTypes.string,
    EquipmentSiteName: PropTypes.string,
    ShiftDate: PropTypes.string,
    ShiftType: PropTypes.string,
    Source: PropTypes.string,
    StartTime: PropTypes.string,
    StopTime: PropTypes.string,
});

export const typeEquipmentIdentifiers = PropTypes.shape({
    EndTime: PropTypes.number,
    EquipmentClass: PropTypes.string,
    EquipmentId: PropTypes.number,
    EquipmentModel: PropTypes.string,
    EquipmentSiteName: PropTypes.string,
    EquipmentSubClass: PropTypes.string,
    EquipmentSubModel: PropTypes.string,
    LoggerId: PropTypes.number,
    StartTime: PropTypes.number,
});

export const typeEngineDiagnosticPowerCurveMetrics = PropTypes.shape({
    AreaUnderCurve: PropTypes.number,
    EquipmentClass: PropTypes.string,
    EquipmentSubClass: PropTypes.string,
    EquipmentSiteName: PropTypes.string,
    EquipmentModel: PropTypes.string,
    EquipmentSubModel: PropTypes.string,
    ShiftId: PropTypes.number,
});

export const typeEngineDiagnosticPowerCurveMetricsByEquipmentName = PropTypes.shape(
    {
        ShiftId: PropTypes.number,
        EquipmentSiteName: PropTypes.string,
        EquipmentClass: PropTypes.string,
        EquipmentSubClass: PropTypes.string,
        EquipmentModel: PropTypes.string,
        EquipmentSubModel: PropTypes.string,
        AreaUnderCurve: PropTypes.number,
        // FE created
        RelativeEfficiency: PropTypes.number,
    },
);

export const typeGeoJSON = PropTypes.shape({
    type: PropTypes.string,
    geometry: PropTypes.shape({
        type: PropTypes.string,
        coordinates: PropTypes.arrayOf(PropTypes.number),
    }),
    properties: PropTypes.shape({
        name: PropTypes.string,
    }),
});

export interface TypeLoadUnitConformanceWeightings {
    ShiftId: number;
    BiasWeighting: number;
    TimeWeighting: number;
    InRangeWeighting: number;
}
