import { createSelector } from 'reselect';
import shiftUtils from '@rs/core/utils/shiftUtils';
import { filterShiftOptionsToReactSelectFormat } from '../../Utils/Filters/filterOptionsToReactSelectFormat';
import { REDUCER_KEY } from '../reducer';
import { API_ARRAY_DELIMITER } from '../../Api';
import { SELECT_STRING_DELIMITER } from '../../Components/Select';

export const getFilters = (state) => state[REDUCER_KEY].filters;

export const getFilterOptions = createSelector(getFilters, (filters) => {
    const shifts = shiftUtils.getAllShiftShortNamesForDate(filters.Date);

    return {
        shifts: filterShiftOptionsToReactSelectFormat(
            shifts.rawShiftsInDay,
            shifts.shiftShortNames,
        ),
    };
});

export const getMapFiltersToAPIParams = createSelector(
    getFilters,
    (filters) => {
        const shiftIdsSelectedShifts = shiftUtils.generateShiftIdRange(
            filters.Date,
            filters.EndDate,
            filters.Shift.split(SELECT_STRING_DELIMITER),
        );

        return {
            shiftId: shiftIdsSelectedShifts.join(API_ARRAY_DELIMITER),
        };
    },
);
