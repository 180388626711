import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_SPEED_LIMITED_ZONES_SUCCESS = `${namespace}/FETCH_SPEED_LIMITED_ZONES_SUCCESS`;
    const fetchSpeedLimitedZonesSuccess = (resultsArray) =>
        action(FETCH_SPEED_LIMITED_ZONES_SUCCESS, resultsArray);

    const FETCH_SPEED_LIMITED_ZONES_ERROR = `${namespace}/FETCH_SPEED_LIMITED_ZONES_ERROR`;
    const fetchSpeedLimitedZonesError = ({ error }) =>
        action(FETCH_SPEED_LIMITED_ZONES_ERROR, { error });

    return {
        FETCH_SPEED_LIMITED_ZONES_SUCCESS,
        fetchSpeedLimitedZonesSuccess,
        FETCH_SPEED_LIMITED_ZONES_ERROR,
        fetchSpeedLimitedZonesError,
    };
}
