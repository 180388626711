import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_FMS_TRUCK_ALLOCATION_EQUIPMENT_SUCCESS = `${namespace}/FETCH_FMS_TRUCK_ALLOCATION_EQUIPMENT_SUCCESS`;
    const fetchFMSTruckALlocationEquipmentSuccess = (results) =>
        action(FETCH_FMS_TRUCK_ALLOCATION_EQUIPMENT_SUCCESS, results);

    const FETCH_FMS_TRUCK_ALLOCATION_EQUIPMENT_ERROR = `${namespace}/FETCH_FMS_TRUCK_ALLOCATION_EQUIPMENT_ERROR`;
    const fetchFMSTruckALlocationEquipmentError = ({ error }) =>
        action(FETCH_FMS_TRUCK_ALLOCATION_EQUIPMENT_ERROR, { error });

    return {
        FETCH_FMS_TRUCK_ALLOCATION_EQUIPMENT_SUCCESS,
        fetchFMSTruckALlocationEquipmentSuccess,
        FETCH_FMS_TRUCK_ALLOCATION_EQUIPMENT_ERROR,
        fetchFMSTruckALlocationEquipmentError,
    };
}
