import React from 'react';
import PropTypes from 'prop-types';
import identity from 'lodash/identity';
import { typeTableColumn, typeTableValue } from './tablePropTypes';

const LeagueTableNonConformanceCell = ({
    column,
    index,
    value,
    hasCellTooltip,
}) => {
    const formatter =
        (column.customProps && column.customProps.valueFormatter) || identity;
    // This would be nicer if we measured the character length in a div offscreen to
    // test if it will fit. For now we'll just use an guestimated length.
    // This is roughly the amount of characters that will fit on 2 lines
    const maxCharacterLength = 22;
    const originalValue = formatter(value);
    let displayValue = formatter(value);
    let shouldDisplayTooltip = false;
    if (
        hasCellTooltip &&
        originalValue &&
        originalValue.length > maxCharacterLength
    ) {
        shouldDisplayTooltip = true;
        displayValue = `${originalValue.slice(0, maxCharacterLength - 3)}...`;
    }

    return (
        <td
            key={`${index}-${column.key}`}
            className="Table__Cell--Number LeagueTable--NonConformanceCell"
            data-rh={shouldDisplayTooltip ? originalValue : undefined}
        >
            {displayValue}
        </td>
    );
};

LeagueTableNonConformanceCell.propTypes = {
    column: typeTableColumn,
    index: PropTypes.bool.isRequired,
    value: typeTableValue,
    hasCellTooltip: PropTypes.bool,
};

export default LeagueTableNonConformanceCell;
