import validator from 'validator';

export function hasErrors(errors) {
    return Object.keys(errors)
        .filter((key) => Array.isArray(errors[key]))
        .some((key) => errors[key].length > 0);
}

const durationValidator = () => ({
    validatorName: 'duration',
    message: () => 'Value should be in the format of hh:mm:ss',
    isValid: (value) => {
        if (value === null) {
            return true;
        }
        return validator.isNumeric(value.toString()) && value >= 0;
    },
});

const capacityValidator = ({ minValue = 1, maxValue = 100 } = {}) => ({
    validatorName: 'capcity',
    message: () => `Value should be a Number from ${minValue}-${maxValue}`,
    isValid: (value) => {
        if (value === null || value === '') {
            return true;
        }
        return (
            validator.isNumeric(value.toString()) &&
            value >= minValue &&
            value <= maxValue
        );
    },
});

const boolValidator = () => ({
    validatorName: 'duration',
    message: () => 'Value should be in the format of hh:mm:ss',
    isValid: (value) => {
        if (value === null) {
            return true;
        }
        return validator.isBoolean(value.toString());
    },
});

/**
 * Validates all the fields within a form
 * @param {object} values - The form values
 * @return {object}
 */
export function validateFields(values) {
    const validators = {
        source: [
            {
                validatorName: 'required',
                message: () => 'Source is required',
                isValid: (value) => {
                    if (!value) {
                        return false;
                    }
                    return true;
                },
            },
        ],
        destination: [
            {
                validatorName: 'required',
                message: () => 'Destination is required',
                isValid: (value) => {
                    if (!value) {
                        return false;
                    }
                    return true;
                },
            },
        ],
        totalCircuitDuration: [
            {
                validatorName: 'duration',
                message: () => 'Value should be in the format of hh:mm:ss',
                isValid: (value) => {
                    if (value === null) {
                        return true;
                    }
                    return validator.isNumeric(value.toString()) && value >= 0;
                },
            },
        ],
        transitMaxDuration: [durationValidator()],
        loadMaxDuration: [durationValidator()],
        loadEnableQueue: [boolValidator()],
        loadMaxQueueDuration: [durationValidator()],
        loadMaxQueueCapacity: [capacityValidator()],
        loadMaxCapacity: [capacityValidator()],
        dumpMaxDuration: [durationValidator()],
        dumpEnableQueue: [boolValidator()],
        dumpMaxQueueDuration: [durationValidator()],
        dumpMaxCapacity: [capacityValidator()],
        dumpMaxQueueCapacity: [capacityValidator()],
        haulMaxDuration: [durationValidator()],
        haulMaxCapacity: [capacityValidator()],
        returnMaxDuration: [durationValidator()],
        returnMaxCapacity: [capacityValidator()],
    };

    return Object.keys(validators).reduce((errors, fieldName) => {
        const value = values[fieldName];
        const fieldValidators = validators[fieldName];
        const fieldErrors = fieldValidators
            .filter((fieldValidator) => !fieldValidator.isValid(value))
            .map((fieldValidator) => ({ message: fieldValidator.message() }));
        if (fieldErrors.length) {
            errors[fieldName] = fieldErrors;
        }
        return errors;
    }, {});
}

export function splitTotalCircuitDurationBetweenNodes(values) {
    const timeMinusLoadDumpNodes =
        values.totalCircuitDuration -
        values.loadMaxDuration -
        values.dumpMaxDuration;
    return {
        haulMaxDuration: timeMinusLoadDumpNodes / 2,
        returnMaxDuration: timeMinusLoadDumpNodes / 2,
    };
}
