import React from 'react';
import cn from 'classnames';
import { LoadUnitImprovementSymbolProps } from './types';

const PayloadImproveConsistencySymbol = ({
    className,
    size,
}: LoadUnitImprovementSymbolProps) => (
    <i
        className={cn(
            'fa fa-exchange PayloadImproveConsistencySymbol',
            size,
            className,
        )}
    />
);

PayloadImproveConsistencySymbol.defaultProps = {
    size: 'fa-2x',
};

export default PayloadImproveConsistencySymbol;
