import React from 'react';

export interface FeatureMessageProps {
    type: 'beta' | 'trial' | 'production';
    supportEmailAddress: string;
}

const FeatureMessage = ({ type, supportEmailAddress }: FeatureMessageProps) => {
    switch (type) {
        case 'beta': {
            return (
                <div className={'FeatureMessage'}>
                    <div className={'FeatureMessageBody'}>
                        We are currently upgrading this feature. If you have any
                        feedback or experience any issues please get in contact
                        with the MaxMine team at{' '}
                        <a href={`mailto:${supportEmailAddress}`}>
                            {supportEmailAddress}
                        </a>
                    </div>
                </div>
            );
        }
        case 'trial': {
            return (
                <div className={'FeatureMessage'}>
                    <div className={'FeatureMessageBody'}>
                        This is a new feature currently under trial. If you have
                        any feedback or experience any issues, please get in
                        contact with the MaxMine team at{' '}
                        <a href={`mailto:${supportEmailAddress}`}>
                            {supportEmailAddress}
                        </a>
                    </div>
                </div>
            );
        }
        case 'production':
        default: {
            return null;
        }
    }
};

export default FeatureMessage;
