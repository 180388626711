import React from 'react';
import DatePicker from 'react-datepicker';
import moment, { Moment } from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

interface Props {
    date: Moment;
    onDateChange: (Moment: Moment) => void;
    maxDate: Moment;
    minDate?: Moment;
}

/**
 * If we're using a minDate or a maxDate in the calendar, we also want to restrict
 * the times in the timepicker as well.
 *
 * If we have a min date of 07/01/2019 9:30am AND the currently selected date is 07/01/2019:
 *  -> return a minTime of 9:31am (so 9:30am and all times before can't be selected)
 * If we have a max date of 12/01/2019 1:00pm AND the currently selected date is 12/01/2019:
 *  -> return a maxTime of 12:59pm (so 1:00pm onwards can't be selected)
 *
 * @param currentSelection
 * @param minDate
 * @param maxDate
 */
export function calculateMinMaxTimes(
    currentSelection: Moment,
    minDate: Moment,
    maxDate: Moment,
): [Date, Date] {
    const minTime = new Date();
    const maxTime = new Date();

    // Default the time range to the whole day
    minTime.setHours(0);
    minTime.setMinutes(0);
    maxTime.setHours(23);
    maxTime.setMinutes(59);
    if (minDate && minDate.isSame(currentSelection, 'day')) {
        minTime.setHours(minDate.hours());
        minTime.setMinutes(minDate.minutes() + 1);
    }
    if (maxDate && maxDate.isSame(currentSelection, 'day')) {
        maxTime.setHours(maxDate.hours());
        maxTime.setMinutes(maxDate.minutes() - 1);
    }
    return [minTime, maxTime];
}

const DateTimePicker = ({
    date,
    onDateChange,
    minDate,
    maxDate,
    ...props
}: Props) => {
    const [minTime, maxTime] = calculateMinMaxTimes(date, minDate, maxDate);

    return (
        <span className={'DateTimePicker'}>
            <DatePicker
                selected={date && date.toDate()}
                onChange={(newDate) => {
                    onDateChange(moment(newDate));
                }}
                dateFormat={'d/MM/yyyy H:mm'}
                timeFormat={'H:mm'}
                showTimeSelect={true}
                showMonthDropdown={true}
                showYearDropdown={true}
                dropdownMode={'select'}
                timeIntervals={10}
                // This doesn't behave very when using
                // min and max times, disabling for now
                disabledKeyboardNavigation={true}
                maxDate={maxDate && maxDate.toDate()}
                minDate={minDate && minDate.toDate()}
                maxTime={maxTime}
                minTime={minTime}
                todayButton={'Today'}
                {...props}
            />
        </span>
    );
};

export default DateTimePicker;
