import { dataUtils, mathHelpers } from '../../utils';
import { operator } from '../operator';

const { sumNumbers, getAverageOfArray, roundTo, nullRoundTo } = mathHelpers;
const { filterUniqueItems, arrayToGroupedObject } = dataUtils;

function getRowsCount(array, key) {
    const filteredRows = array.filter((r) => r[key] !== null);
    return filteredRows.length;
}

function nullOrRowsAverage(rows, key) {
    const rowCount = getRowsCount(rows, key);
    if (rowCount === 0) {
        return null;
    }
    return getAverageOfArray(rows, rowCount, key);
}

function calculateRowValues(rows) {
    const deltaPayloads = rows.map(({ PayloadTarget, Payload }) => ({
        deltaPayload: Payload - PayloadTarget,
    }));

    return {
        PayloadScoreAvg: nullRoundTo(
            nullOrRowsAverage(rows, 'PayloadScore'),
            1,
        ),
        TimeScoreAvg: nullRoundTo(nullOrRowsAverage(rows, 'TimeScore'), 1),
        BiasScoreAvg: nullRoundTo(nullOrRowsAverage(rows, 'BiasScore'), 1),
        EventTimeAvg: nullRoundTo(nullOrRowsAverage(rows, 'EventTimeSum'), 1),
        EventTimeSum: sumNumbers(rows, 'EventTimeSum'),
        LoadAvg: roundTo(
            getAverageOfArray(rows, getRowsCount(rows, 'Payload'), 'Payload'),
            1,
        ),
        LoadCount: rows.length,
        LoadSum: sumNumbers(rows, 'Payload'),
        PayloadFromTargetAvg: roundTo(
            getAverageOfArray(
                deltaPayloads,
                deltaPayloads.length,
                'deltaPayload',
            ),
            1,
        ),
    };
}

/**
 * The loadingEvents endpoint returns results grouped by
 * OperatorDisplayName, LoadUnitType, LoadUnitSiteName, PayloadTarget, Time Target
 * This is so we can aggregate the data on the FE depending on what filters are selected
 */
export function aggregateRowsByOperatorSiteId(loaderEvents) {
    const eventsWithOperatorSiteId = loaderEvents.map((row) => ({
        ...row,
        OperatorSiteId: operator.operatorNameToUniqueId(
            row.OperatorDisplayName,
        ),
    }));
    const rowsByOperatorSiteId = arrayToGroupedObject(
        eventsWithOperatorSiteId,
        'OperatorDisplayName',
    );
    return Object.keys(rowsByOperatorSiteId).map((operatorSiteId) => {
        const rows = rowsByOperatorSiteId[operatorSiteId];
        const rowsByLoadUnitType = arrayToGroupedObject(rows, 'LoadUnitType');
        const byLoadUnitType = Object.keys(rowsByLoadUnitType).reduce(
            (collection, loadUnitType) => {
                const r = rowsByLoadUnitType[loadUnitType];
                return [
                    ...collection,
                    {
                        loadUnitType,
                        loadUnitTypeShortName:
                            r.length && r[0].LoadUnitTypeShortName,
                        ...calculateRowValues(r),
                    },
                ];
            },
            [],
        );
        return {
            ...calculateRowValues(rows),
            LoadUnitSiteName: rows
                .map((row) => row.LoadUnitSiteName)
                .filter(filterUniqueItems),
            LoadUnitType: rows
                .map((row) => row.LoadUnitType)
                .filter(filterUniqueItems),
            LoadUnitTypeShortName: rows
                .map((row) => row.LoadUnitTypeShortName)
                .filter(filterUniqueItems),
            HaulTruckType: rows
                .map((row) => row.HaulTruckType)
                .filter(filterUniqueItems),
            PayloadTargets: rows
                .map((row) => row.PayloadTarget)
                .filter(filterUniqueItems),
            TimeTargets: rows
                .map((row) => row.TimeTarget)
                .filter(filterUniqueItems),
            OperatorDisplayName: rows[0].OperatorDisplayName,
            OperatorSiteId: rows[0].OperatorSiteId,
            byLoadUnitType,
        };
    });
}

/**
 * Calulates the totals based on the loaderEvents.rows
 */
export function calculateTotalRow(loaderEvents) {
    const rowsByLoadUnitType = arrayToGroupedObject(
        loaderEvents,
        'LoadUnitType',
    );
    const byLoadUnitType = Object.keys(rowsByLoadUnitType).reduce(
        (collection, loadUnitType) => [
            ...collection,
            {
                loadUnitType,
                ...calculateRowValues(rowsByLoadUnitType[loadUnitType]),
            },
        ],
        [],
    );
    return {
        ...calculateRowValues(loaderEvents),
        byLoadUnitType,
    };
}
