import React, { ReactNode } from 'react';
import { Tooltip } from 'antd';
import PayloadGoodJobSymbol from './PayloadGoodJobSymbol';
import PayloadIncreasePayloadsSymbol from './PayloadIncreasePayloadsSymbol';
import PayloadDecreasePayloadsSymbol from './PayloadDecreasePayloadsSymbol';
import PayloadImproveConsistencySymbol from './PayloadImproveConsistencySymbol';
import PayloadBiasGoodJobSymbol from './PayloadBiasGoodJobSymbol';
import PayloadBiasImproveLongitudinalConsistencySymbol from './PayloadBiasImproveLongitudinalConsistencySymbol';
import PayloadBiasImproveLateralConsistencySymbol from './PayloadBiasImproveLateralConsistencySymbol';
import PayloadBiasDirectionSymbol from './PayloadBiasDirectionSymbol';
import { SymbolAPIName } from './types';

function arrowDirectionToFriendlyName(direction) {
    const map = {
        0: 'Right',
        1: 'Front-Right',
        2: 'Front',
        3: 'Front-Left',
        4: 'Left',
        5: 'Rear-Left',
        6: 'Rear',
        7: 'Rear-Right',
        8: 'Right',
    };
    return map[direction] || '';
}

interface IconProps {
    children?: ReactNode;
    tooltip?: string;
}

const PayloadIcon = ({ children, tooltip }: IconProps) => {
    return (
        <Tooltip title={tooltip}>
            <span className="LoadUnitImprovementSymbol__IconWrapper">
                {children}
            </span>
        </Tooltip>
    );
};

const BiasLoadIcon = ({ children, tooltip }: IconProps) => (
    <Tooltip title={tooltip}>
        <span className="LoadUnitImprovementSymbol__IconWrapper">
            {children}
        </span>
    </Tooltip>
);

export function getSymbolTooltip(symbols) {
    const emptyFunction = () => '';
    const firstSymbol = (Array.isArray(symbols) && symbols[0]) || '';
    const tooltipFn = symbolTooltips[firstSymbol];
    if (typeof tooltipFn === 'function') {
        return tooltipFn;
    }
    return emptyFunction;
}

const symbolTooltips = {
    payload_increase_payloads_symbol: () => 'increase payload',
    payload_decrease_payloads_symbol: () => 'Decrease payload',
    payload_improve_consistency_symbol: () => 'Improve payload consistency',
    payload_good_job_symbol: () => 'Good job!',
    payload_bias_good_job_symbol: () => 'Good job!',
    payload_bias_improve_longitudinal_consistency_symbol: () =>
        'Improve longitudinal payload accuracy',
    payload_bias_improve_lateral_consistency_symbol: () =>
        'Improve lateral payload accuracy',
    payload_bias_direction_symbol: ({ arrowDirection }) =>
        `Improve payload accuracy ${arrowDirectionToFriendlyName(
            arrowDirection,
        )}`,
};

function RenderSymbol({ symbol, arrowDirection, enableTooltip }) {
    const tooltip = getSymbolTooltip([symbol])({ arrowDirection });

    const symbolMap = {
        payload_increase_payloads_symbol: (
            <PayloadIcon tooltip={enableTooltip && tooltip}>
                <PayloadIncreasePayloadsSymbol />
            </PayloadIcon>
        ),
        payload_decrease_payloads_symbol: (
            <PayloadIcon tooltip={enableTooltip && tooltip}>
                <PayloadDecreasePayloadsSymbol />
            </PayloadIcon>
        ),
        payload_improve_consistency_symbol: (
            <PayloadIcon tooltip={enableTooltip && tooltip}>
                <PayloadImproveConsistencySymbol />
            </PayloadIcon>
        ),
        payload_good_job_symbol: (
            <PayloadIcon tooltip={enableTooltip && tooltip}>
                <PayloadGoodJobSymbol className="LoadingEventSymbologySymbol__Icon--GoodJob" />
            </PayloadIcon>
        ),
        payload_bias_good_job_symbol: (
            <BiasLoadIcon tooltip={enableTooltip && tooltip}>
                <PayloadBiasGoodJobSymbol className="LoadingEventSymbologySymbol__Icon--GoodJob" />
            </BiasLoadIcon>
        ),
        payload_bias_improve_longitudinal_consistency_symbol: (
            <BiasLoadIcon tooltip={enableTooltip && tooltip}>
                <PayloadBiasImproveLongitudinalConsistencySymbol />
            </BiasLoadIcon>
        ),
        payload_bias_improve_lateral_consistency_symbol: (
            <BiasLoadIcon tooltip={enableTooltip && tooltip}>
                <PayloadBiasImproveLateralConsistencySymbol className="LoadingEventSymbologySymbol__PayloadBiasImproveLateralConsistencySymbolDirectionIcon" />
            </BiasLoadIcon>
        ),
        payload_bias_direction_symbol: (
            <BiasLoadIcon tooltip={enableTooltip && tooltip}>
                <PayloadBiasDirectionSymbol arrowDirection={arrowDirection} />
            </BiasLoadIcon>
        ),
    };
    const icon = symbolMap[symbol];
    if (!icon) {
        return '';
    }
    return icon;
}

interface LoadingEventSymbologySymbolProps {
    symbols: SymbolAPIName[] | null;
    arrowDirection: number;
    enableTooltip: boolean;
}

const LoadUnitImprovementSymbol = ({
    symbols = [],
    arrowDirection,
    enableTooltip = false,
}: LoadingEventSymbologySymbolProps) => {
    if (!Array.isArray(symbols)) return null;
    const children = symbols.map((symbol, index) => (
        <RenderSymbol
            key={index}
            symbol={symbol}
            arrowDirection={arrowDirection}
            enableTooltip={enableTooltip}
        />
    ));
    // NOTE: this is wrapped in a fragment because it fixes these type errors
    // JSX element type 'Element[]' is not a constructor function for JSX elements.  TS2605
    // Type 'Element[]' is missing the following properties from type 'Element': type, props, key  TS2605
    return <React.Fragment>{children}</React.Fragment>;
};

export default LoadUnitImprovementSymbol;
