import { combineReducers } from 'redux';
import reducer from './groupsList';
import { reducer as spinner } from '../Modules/spinner';

export const REDUCER_KEY = 'GroupsList';

export default combineReducers({
    reducer,
    spinner,
});
