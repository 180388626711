import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { LoadUnitImprovementSymbolProps } from './types';

const PayloadGoodJobSymbol = ({
    className,
}: LoadUnitImprovementSymbolProps) => (
    <FontAwesomeIcon
        className={cn('LoadUnitImprovementSymbol__Icon', className)}
        icon="check"
    />
);

export default PayloadGoodJobSymbol;
