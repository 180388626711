import React from 'react';
import cn from 'classnames';
import LoadUnitSymbolPayloadOverload from '../../assets/js/LoadUnitSymbolPayloadOverload';
import { LoadUnitImprovementSymbolProps } from './types';

const PayloadDecreasePayloadsSymbol = ({
    className,
}: LoadUnitImprovementSymbolProps) => (
    <LoadUnitSymbolPayloadOverload
        className={cn('LoadUnitImprovementSymbol__Icon', className)}
    />
);

export default PayloadDecreasePayloadsSymbol;
