import React, { Fragment, ReactNode } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ConformanceLegend } from '@rs/core/components';
import bowser from 'bowser';
import cn from 'classnames';
import { TypeSpinner } from '@rs/core/types';
import withComponentConfig from '../../HOC/withComponentConfig';
import { TableData } from '../types';
import { compose, withProps, branch, renderComponent } from 'recompose';

const ConformanceLegendWithConfig = withComponentConfig(
    'ConformanceLoadUnits',
    ConformanceLegend,
);

interface Props {
    loadingEventsConformanceReport: TableData;
    spinner: TypeSpinner;
    columns: {
        id?: string;
        Header: ReactNode;
        CSVDisplayName: string;
        accessor: string;
        minWidth?: number;
        Cell: Function;
        getFooterProps?: Function;
        Footer: Function;
        featureId?: number;
    }[];
}

const DataTable = ({ data, pageSize, columns }: any) => {
    // Bowser's api has changed, this is the way to get the browser name and do a conditional boolean
    const browser = bowser.getParser(window.navigator.userAgent);
    const mac = browser.getBrowserName() === 'safari' ? true : false;
    const msie = browser.getBrowserName() === 'ie' ? true : false;

    return (
        <ReactTable
            data={data}
            noDataText="No data"
            minRows={9}
            pageSize={pageSize}
            defaultSorted={[
                {
                    id: 'TotalConformance',
                    desc: true,
                },
            ]}
            className={cn(
                'ReactTable--Default ReactTable--Scrollable -striped LoaderLeagueTable Printing--Hide',
                {
                    'ReactTable--Safari': mac,
                    'ReactTable--IE': msie,
                },
            )}
            showPagination={false}
            columns={columns}
        />
    );
};
const DataTableWithLoading = branch(
    (props: any) => props.spinner.isActive,
    renderComponent((props: any) => (
        <ReactTable loading={true} columns={props.columns} />
    )),
);
const DataTableEnhance = compose(
    DataTableWithLoading,
    withProps((props: any) => {
        return {
            data: props.loadingEventsConformanceReport.rows,
            noDataText: 'No data',
            pageSize: props.loadingEventsConformanceReport.rows.length,
            columns: props.columns,
        };
    }),
);
const EnhancedDataTable = DataTableEnhance(DataTable);

const LoaderLeagueTable = ({
    loadingEventsConformanceReport,
    spinner,
    columns,
}: Props) => {
    return (
        <Fragment>
            {/* THIS TABLE only show in the print function */}
            <table
                className="LeagueTablePrint Printing--Show"
                style={{ width: '90%' }}
            >
                <thead className="LeagueTablePrint__Thead">
                    <tr>
                        {columns.map((column, index) => (
                            <td key={index}>{column.Header}</td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {loadingEventsConformanceReport.rows.map(
                        (row: any, rowIndex) => (
                            <tr key={rowIndex} style={{ height: 40 }}>
                                {columns.map((column, colIndex) =>
                                    column.Cell({
                                        column,
                                        rowIndex,
                                        colIndex,
                                        value: row[column.accessor],
                                        isForPrinting: true,
                                        original: row,
                                    }),
                                )}
                            </tr>
                        ),
                    )}
                </tbody>
                <tbody>
                    <tr
                        className="LeagueTablePrint__Tfoot__Tr"
                        style={{ height: 40 }}
                    >
                        {columns.map((column, index) =>
                            column.Footer({
                                isForPrinting: true,
                                rowIndex:
                                    loadingEventsConformanceReport.rows.length +
                                    1,
                                colIndex: index,
                            }),
                        )}
                    </tr>
                </tbody>
            </table>
            {/* THIS show in webpage */}
            <EnhancedDataTable
                //TO-DO - solve this error -> Property 'spinner' does not exist on type 'IntrinsicAttributes & IntrinsicClassAttributes
                //@ts-ignore
                spinner={spinner}
                loadingEventsConformanceReport={loadingEventsConformanceReport}
                columns={columns}
            />
            <ConformanceLegendWithConfig />
        </Fragment>
    );
};

export default LoaderLeagueTable;
