import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withLeaflet, Pane, Polyline } from 'react-leaflet';
import { ZINDEX } from './constants';
import arePropsEqual from '../../utils/arePropsEqual';

class TruckPath extends React.Component {
    static propTypes = {
        pastPositions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
        futurePositions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
        smoothFactor: PropTypes.number,
        color: PropTypes.string,
        weight: PropTypes.number,
        opacity: PropTypes.number,
        name: PropTypes.string,
        pathClassName: PropTypes.string,
        futurePathClassName: PropTypes.string,
        priority: PropTypes.number,
    };

    static defaultProps = {
        color: 'lime',
        smoothFactor: 1.0,
        weight: 4.0,
        opacity: 1.0,
    };

    render() {
        const {
            name,
            pastPositions,
            futurePositions,
            color,
            smoothFactor,
            weight,
            opacity,
            futurePathClassName,
            pathClassName,
            priority,
            ...props
        } = this.props;

        const zIndex = ZINDEX.MAP_PANE + priority;

        return (
            <Pane paneId={name} style={{ zIndex }} {...props}>
                <Polyline
                    weight={weight}
                    opacity={opacity}
                    className={pathClassName}
                    color={color}
                    smoothFactor={smoothFactor}
                    positions={pastPositions}
                    interactive={false}
                />
                <Polyline
                    weight={weight}
                    opacity={0.3}
                    className={futurePathClassName}
                    color={color}
                    smoothFactor={smoothFactor}
                    positions={futurePositions}
                    interactive={false}
                />
            </Pane>
        );
    }
}

export default memo(withLeaflet(TruckPath), arePropsEqual);
