import * as React from 'react';

function SvgExcavator(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <path
                fill="#fff200"
                d="M493.184 379.238l115.69-187.616 235.74 212.114v-51.582L584.339 75.06 258.736 481.732h133.017V379.238zM670.704 615.14H391.753v-86.519H214.557v187.52h456.147z"
            />
            <path d="M718.59 567.964v1.58c.163.824.067.268 0-1.58z" />
            <path d="M1011.898 514.409l-94.603-62.663-24.795-13.408V334.283L583.342 1 196.696 481.77h-30.024v234.236C97.439 720.24 42.39 777.702 42.39 847.76c0 72.785 59.378 132.02 132.432 132.02h548.767c73.016 0 132.432-59.225 132.432-132.02 0-72.796-59.378-132.011-132.432-132.011h-5.009V571.43c-.43-2.356-2.777-15.592-12.986-42.408-35.225-101.938-168.816-134.156-168.816-134.156l78.437-130.037 227.82 204.317 6.704 181.276-45.204 67.633 92.993-24.709 111.027-66.58c21.721-35.263 20.936-78.14 3.343-112.358zM722.67 792.144c47.071 0 56.83 40.875 56.83 55.605 0 15.419-10.697 55.614-55.873 55.614H174.86c-45.472 0-55.882-41.181-55.882-55.614 0-12.718 8.543-55.605 54.924-55.605zm-51.937-76.406H214.557v-186.16h177.176v86.52h278.971zm-.335-147.487h-230.76V427.085h55.911S634.8 447.656 670.37 568.26zm-61.523-376.667L493.183 379.238h-101.43V481.77H258.736L584.357 75.098l260.258 277.056v51.591z" />
        </svg>
    );
}

export default SvgExcavator;
