import { reducer, sliceSelectors } from './reducer';
import { BASE_STATE_NAME } from './reducer';

const PRODUCTIVITY_REDUCER_KEY = 'Productivity';
const stateSelectors = {
    getFilters: (state) =>
        sliceSelectors.getFilters(
            state[PRODUCTIVITY_REDUCER_KEY][BASE_STATE_NAME],
        ),
    getMineSiteLayers: (state) =>
        sliceSelectors.getMineSiteLayers(
            state[PRODUCTIVITY_REDUCER_KEY][BASE_STATE_NAME],
        ),
    getMinesiteMapWithViewPort: (state) =>
        sliceSelectors.getMinesiteMapWithViewPort(
            state[PRODUCTIVITY_REDUCER_KEY][BASE_STATE_NAME],
            state,
        ),
    getFilterOptions: (state) =>
        sliceSelectors.getFilterOptions(
            state[PRODUCTIVITY_REDUCER_KEY][BASE_STATE_NAME],
            state,
        ),
    getDefinedFilters: (state) =>
        sliceSelectors.getDefinedFilters(
            state[PRODUCTIVITY_REDUCER_KEY][BASE_STATE_NAME],
        ),
    getSpinnerState: (state) =>
        sliceSelectors.getSpinnerState(
            state[PRODUCTIVITY_REDUCER_KEY][BASE_STATE_NAME],
        ),
    getWktData: (state) =>
        sliceSelectors.getWktData(
            state[PRODUCTIVITY_REDUCER_KEY][BASE_STATE_NAME],
        ),
    getTopEventsByLostTime: (state, numberOfEventsToReturn) =>
        sliceSelectors.getTopEventsByLostTime(
            state[PRODUCTIVITY_REDUCER_KEY][BASE_STATE_NAME],
            numberOfEventsToReturn,
        ),
    getFormattedAveragedResults: (state) =>
        sliceSelectors.getFormattedAveragedResults(
            state[PRODUCTIVITY_REDUCER_KEY][BASE_STATE_NAME],
        ),
    getHighlightedEventPathIndex: (state) =>
        sliceSelectors.getHighlightedEventPathIndex(
            state[PRODUCTIVITY_REDUCER_KEY][BASE_STATE_NAME],
        ),
    getDriverEventJsonPaths: (state) =>
        sliceSelectors.getDriverEventJsonPaths(
            state[PRODUCTIVITY_REDUCER_KEY][BASE_STATE_NAME],
        ),
    getTopDriversForShiftProductivityEventId: (state, numberOfEvents) =>
        sliceSelectors.getTopDriversForShiftProductivityEventId(
            state[PRODUCTIVITY_REDUCER_KEY][BASE_STATE_NAME],
            numberOfEvents,
        ),
    getDriverEventJsonError: (state) =>
        sliceSelectors.getDriverEventJsonError(
            state[PRODUCTIVITY_REDUCER_KEY][BASE_STATE_NAME],
        ),
    getSpeedLimitedZones: (state) =>
        sliceSelectors.getSpeedLimitedZones(
            state[PRODUCTIVITY_REDUCER_KEY][BASE_STATE_NAME],
        ),
    getMapFeatures: (state) =>
        sliceSelectors.getMapFeatures(
            state[PRODUCTIVITY_REDUCER_KEY][BASE_STATE_NAME],
        ),
    getMinesiteAreasTree: (state) =>
        sliceSelectors.getMinesiteAreasTree(
            state[PRODUCTIVITY_REDUCER_KEY][BASE_STATE_NAME],
        ),
    getGearLockdown: (state) =>
        sliceSelectors.getGearLockdown(
            state[PRODUCTIVITY_REDUCER_KEY][BASE_STATE_NAME],
        ),
    getPolygonLayers: (state) =>
        sliceSelectors.getPolygonsLayerState(
            state[PRODUCTIVITY_REDUCER_KEY][BASE_STATE_NAME],
        ),
    getGeofenceLabels: (state) =>
        sliceSelectors.getGeofenceLabels(
            state[PRODUCTIVITY_REDUCER_KEY][BASE_STATE_NAME],
        ),
};

export { stateSelectors, sliceSelectors, PRODUCTIVITY_REDUCER_KEY };

export default reducer;
