import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { LayerGroup, LayersControl, Marker, Pane } from 'react-leaflet';
import * as L from 'leaflet';
import { createIconMarker, markers } from './createMarker';
import { ZINDEX } from './constants';
import arePropsEqual from '../../utils/arePropsEqual';

class TruckMarker extends React.Component {
    static propTypes = {
        // The text that will be displayed in the layer control
        name: PropTypes.string.isRequired,
        // The initial state of if to display the layer
        checked: PropTypes.bool.isRequired,
        // Position of the marker as a dictionary
        position: PropTypes.shape({
            latitude: PropTypes.number,
            longitude: PropTypes.number,
        }).isRequired,
        // The order a layer should be rendered in, higher number displays on top
        priority: PropTypes.number.isRequired,
    };

    render() {
        const {
            checked,
            name,
            position,
            priority,
            ...leafletInjectedProps
        } = this.props;

        const markerLocation = L.latLng(position.latitude, position.longitude);
        const zIndex = ZINDEX.MAP_PANE + priority;
        const paneId = 'TruckMarker';

        return (
            <LayersControl.Overlay
                {...leafletInjectedProps}
                checked={checked}
                name={name}
            >
                <LayerGroup>
                    <Pane name={paneId} style={{ zIndex }}>
                        <Marker
                            position={markerLocation}
                            icon={createIconMarker(markers.truck)}
                        />
                    </Pane>
                </LayerGroup>
            </LayersControl.Overlay>
        );
    }
}

export default memo(TruckMarker, arePropsEqual);
