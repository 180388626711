import { all, takeLatest, put, select, call } from 'redux-saga/effects';
import shiftUtils from '@rs/core/utils/shiftUtils';
import {
    actions as filterActions,
    selectors as filterSelectors,
} from '../EngineDiagnostics/Modules/filters';
import { actions as spinnerActions } from '../EngineDiagnostics/Modules/spinner';
import { actions as loggerDeploymentActions } from '../EngineDiagnostics/Modules/shiftLoggerDeployments';
import { actions as engineDiagnosticCurveActions } from '../EngineDiagnostics/Modules/engineDiagnosticCurves';
import {
    convertURLQueryStringToObject,
    updateURLQueryString,
} from '../Lib/queryStringUtils';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import * as resourceSagas from './resourceSagas';
import { checkRequestsForErrors } from '../Api';

export function* filterUpdated({ payload }) {
    const filters = yield select(filterSelectors.getFilters);
    const shiftId = shiftUtils.getShiftIdFromDateString(
        filters.Date,
        filters.Shift,
    );
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    if (payload.filterName === 'Date' || payload.filterName === 'Shift') {
        yield call(getLoggerIdsForShift, shiftId);
    }

    updateURLQueryString(filters);

    if (filters.Equipment !== '') {
        yield call(getEngineDiagnosticCurves, shiftId, filters.Equipment);
    }

    yield put(spinnerActions.setSpinnerState(false));
}

export function* getLoggerIdsForShift(shiftId) {
    const shiftLoggerDeployments = yield call(
        resourceSagas.getShiftLoggerDeployments,
        {
            ShiftId: shiftId,
            // Haul Truck filter
            EquipmentClass: 'HaulTruck',
        },
    );

    if (checkRequestsForErrors([shiftLoggerDeployments])) {
        yield put(
            loggerDeploymentActions.fetchShiftLoggerDeploymentsError({
                error: `Failed to load logger deployments for shift ${shiftId}`,
            }),
        );
        return;
    }

    yield put(
        loggerDeploymentActions.fetchShiftLoggerDeploymentsSuccess(
            shiftLoggerDeployments.response,
        ),
    );
}

export function* getEngineDiagnosticCurves(shiftId, equipmentName) {
    if (!shiftId || !equipmentName) {
        return;
    }

    const engineDiagnosticCurves = yield call(
        resourceSagas.getEngineDiagnosticCurvesByTruck,
        {
            ShiftId: shiftId,
            EquipmentSiteName: equipmentName,
        },
    );

    if (checkRequestsForErrors([engineDiagnosticCurves])) {
        yield put(
            engineDiagnosticCurveActions.fetchEngineDiagnosticCurvesError({
                error: `Failed to load engine diagnostic curves for shift ${shiftId} and equipment ${equipmentName}`,
            }),
        );
        return;
    }

    yield put(
        engineDiagnosticCurveActions.fetchEngineDiagnosticCurvesSuccess(
            engineDiagnosticCurves.response,
        ),
    );
}

export function* updateFiltersWithURLParams() {
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const urlFilterValues = convertURLQueryStringToObject();
    yield put(filterActions.setFiltersWithUrlParams(urlFilterValues));
    const filters = yield select(filterSelectors.getFilters);
    const shiftId = shiftUtils.getShiftIdFromDateString(
        filters.Date,
        filters.Shift,
    );
    yield call(getLoggerIdsForShift, shiftId);
    yield call(getEngineDiagnosticCurves, shiftId, filters.Equipment);
    yield put(spinnerActions.setSpinnerState(false));
}

export default function* watch() {
    yield all([
        takeLatest(
            filterActions.UPDATE_FILTERS_WITH_URL_PARAMS,
            updateFiltersWithURLParams,
        ),
        takeLatest(filterActions.FILTER_UPDATED, filterUpdated),
    ]);
}
