import { createReducer } from '../../Lib/reduxUtils';

export const initialState = {
    data: {},
    isLoading: false,
    hasError: false,
    error: '',
};

export const onRequest = (state) => {
    return {
        ...state,
        isLoading: true,
        hasError: false,
        error: '',
    };
};

export const onSuccess = (state, objectResults) => {
    return {
        data: objectResults,
        isLoading: false,
        hasError: false,
        error: '',
    };
};

export const onError = (state, error) => {
    return {
        ...state,
        data: {},
        isLoading: false,
        hasError: true,
        error,
    };
};

/**
 * Creates a reducer for this module. It has some default behaviour but also allows custom actions to be supplied
 * @param {object} actions {<internalActionName>: <ACTION_TYPE>} maps an internalActionName to an ACTION_TYPE. The handler for this ACTION_TYPE is the default behaviour
 * @param {object} customActions {<ACTION_TYPE>: actionHandler} maps an ACTION_TYPE to an action handler
 * @return {function(*=, {type: *, payload?: *})}
 */
export default function createReducers(actions, customActions = {}) {
    return createReducer(initialState, {
        [actions.onRequest]: onRequest,
        [actions.onSuccess]: onSuccess,
        [actions.onError]: onError,
        /**
         * Ensure any custom action handlers pass along state + any additional arguments needed for the
         * action handlers
         */
        ...customActions,
    });
}
