import uuid from 'uuid/v4';
import errorLogging from '@rs/core/utils/errorLogging';

const SEPERATOR = '/';

/**
 * Gets the prefix of a FQID. This represents the section the id belongs to
 * eg. 'circuit/1234'
 * @param {string} id
 * @return {string}
 */
export function getFQIDPrefix(id) {
    if (id === null || id === undefined) {
        errorLogging.logException(
            `attempting to get the FQID prefix of a null type value`,
        );
        return '';
    }
    const asString = id.toString();
    return asString.split(SEPERATOR)[0];
}

/**
 * Checks if the id belongs to an equipment
 * @param {string|number} id
 * @return {boolean}
 */
export function isEquipmentId(id) {
    return /^equipment\/\d+$/.test(id);
}

/**
 * Checks if the id belongs to a circuit
 * @param {string|number} id
 * @return {boolean}
 */
export function isCircuitId(id) {
    return /^circuit\/\w+$/.test(id);
}

/**
 * Checks if the id belongs to a holding state
 * @param {string|number} id
 * @return {boolean}
 */
export function isHoldingStateId(id) {
    return /^holding-state\/[\w ]+$/.test(id);
}

/**
 * Creates a new full qualified id, eg. 'circuit/1234'
 * @param {string} prefix
 * @return {string}
 */
export function createNewFQID(prefix = '') {
    return `${prefix}${SEPERATOR}${uuid()}`;
}
