import shiftUtils from '@rs/core/utils/shiftUtils';
import createActions from '../../../Modules/filters/actions';
import { FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS } from '../../../App/Actions';
import createReducer from '../../../Modules/filters/reducer';
import createSelectors from '../../../Modules/filters/selectors';
import namespace from './namespace';
import * as maxMineAlertCategoriesModule from './maxMineAlertCategories';
import { SELECT_STRING_DELIMITER } from '../../../Components/Select';

const fetchPrivateSiteConfigurationSuccess = (state) => {
    const defaultDate = shiftUtils.newDefaultFilterDate();
    return {
        ...state,
        Date: defaultDate,
    };
};

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].filters;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);
export const reducer = createReducer(
    {
        filterUpdated: actions.FILTER_UPDATED,
        filterUpdatedBatch: actions.FILTER_UPDATED_BATCH,
        setFiltersWithUrlParams: actions.SET_FILTERS_WITH_URL_PARAMS,
    },
    {
        [FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS]: fetchPrivateSiteConfigurationSuccess,
        [maxMineAlertCategoriesModule.actions
            .FETCH_MAX_MINE_ALERT_CATEGORIES_SUCCESS]: (state, payload) => {
            // If the user has already picked options then leave it alone
            if (state.MaxMineAlertCategory.length > 0) {
                return state;
            }
            const uniqueMaxMineAlertCategories = maxMineAlertCategoriesModule.utils.getUniqueMaxMineAlertCategories(
                payload,
            );
            const defaultCategories = uniqueMaxMineAlertCategories.filter(
                (category) => category !== 'CurrentlyUnusedSensor',
            );
            return {
                ...state,
                MaxMineAlertCategory: defaultCategories.join(
                    SELECT_STRING_DELIMITER,
                ),
            };
        },
    },
    {
        Date: undefined,
        Shift: '',
        InWorkshop: '',
        AlertSeverity: '',
        MaxMineAlertCategory: '',
    },
);
export const selectors = createSelectors(statePath);
