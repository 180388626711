import shiftUtils from '@rs/core/utils/shiftUtils';
import createActions from '../../../Modules/filters/actions';
import createReducer from '../../../Modules/filters/reducer';
import createSelectors from '../../../Modules/filters/selectors';
import namespace from './namespace';
import { FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS } from '../../../App/Actions';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].filters;

function filterUpdated(state, payload) {
    const { filterName, filterValue } = payload;
    let newState;
    if (filterName === 'Date' || filterName === 'Shift') {
        newState = {
            ...state,
            [filterName]: filterValue === null ? '' : filterValue,
            EquipmentSiteName: '',
            EquipmentOperatorName: '',
        };
    } else if (filterName === 'EquipmentSiteName') {
        newState = {
            ...state,
            [filterName]: filterValue === null ? '' : filterValue,
            EquipmentOperatorName: '',
        };
    } else {
        newState = {
            ...state,
            [filterName]: filterValue === null ? '' : filterValue,
        };
    }

    return newState;
}
/**
 * Create the module parts
 */
export const actions = createActions(namespace);
export const reducer = createReducer(
    {
        filterUpdated: actions.FILTER_UPDATED,
        setFiltersWithUrlParams: actions.SET_FILTERS_WITH_URL_PARAMS,
    },
    {
        [actions.FILTER_UPDATED]: filterUpdated,
        [FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS]: (state) => {
            if (state.Shift) {
                return state;
            }
            const defaultDate = shiftUtils.newDefaultFilterDate();
            const firstShift = shiftUtils.getFirstShiftFromDate(defaultDate);
            return {
                ...state,
                Date: defaultDate,
                Shift: firstShift.shortName,
            };
        },
    },
    {
        Date: shiftUtils.newDefaultFilterDate(),
        Shift: '',
        EquipmentSiteName: '',
        EquipmentOperatorName: '',
    },
);
export const selectors = createSelectors(statePath);
