import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
    { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
    { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
    BigInt: any;
    /** Integers that will have a value of 0 or more. */
    UnsignedInt: any;
};

export type CoachinganalyticsV1DeleteFileRequestInput = {
    fileId?: Maybe<Scalars['UnsignedInt']>;
};

export type CoachinganalyticsV1DeleteFileResponse = {
    __typename?: 'CoachinganalyticsV1DeleteFileResponse';
    filesWrapper?: Maybe<CoachinganalyticsV1FilesWrapper>;
};

export type CoachinganalyticsV1File = {
    __typename?: 'CoachinganalyticsV1File';
    fileId?: Maybe<Scalars['UnsignedInt']>;
    fileKey?: Maybe<Scalars['String']>;
    fileName?: Maybe<Scalars['String']>;
    uploadedAt?: Maybe<Scalars['BigInt']>;
    uploadedByUserId?: Maybe<Scalars['String']>;
    uploadedByUserName?: Maybe<Scalars['String']>;
};

export type CoachinganalyticsV1FilesWrapper = {
    __typename?: 'CoachinganalyticsV1FilesWrapper';
    files?: Maybe<Array<Maybe<CoachinganalyticsV1File>>>;
};

export type CoachinganalyticsV1GetFilesRequestInput = {
    _?: Maybe<Scalars['Boolean']>;
};

export type CoachinganalyticsV1GetFilesResponse = {
    __typename?: 'CoachinganalyticsV1GetFilesResponse';
    filesWrapper?: Maybe<CoachinganalyticsV1FilesWrapper>;
};

export type CoachinganalyticsV1ShiftUploadCompleteRequestInput = {
    shiftId?: Maybe<Scalars['UnsignedInt']>;
};

export type CoachinganalyticsV1ShiftUploadCompleteResponse = {
    __typename?: 'CoachinganalyticsV1ShiftUploadCompleteResponse';
    _?: Maybe<Scalars['Boolean']>;
};

export type CoachinganalyticsV1UploadFileRequestInput = {
    fileId?: Maybe<Scalars['UnsignedInt']>;
};

export type CoachinganalyticsV1UploadFileResponse = {
    __typename?: 'CoachinganalyticsV1UploadFileResponse';
    filesWrapper?: Maybe<CoachinganalyticsV1FilesWrapper>;
};

export type HelloworldV1SayHelloRequestInput = {
    name?: Maybe<Scalars['String']>;
};

export type HelloworldV1SayHelloResponse = {
    __typename?: 'HelloworldV1SayHelloResponse';
    message?: Maybe<Scalars['String']>;
};

export type MapsV1FeaturesByShiftId = {
    __typename?: 'MapsV1FeaturesByShiftId';
    shiftId?: Maybe<Scalars['String']>;
    trackingRegionsGeojson?: Maybe<Scalars['String']>;
    speedZonesGeojson?: Maybe<Scalars['String']>;
    materialMovementsGeojson?: Maybe<Scalars['String']>;
    womidsGeojson?: Maybe<Scalars['String']>;
};

export type MapsV1GetFeaturesRequestInput = {
    shiftIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MapsV1GetFeaturesResponse = {
    __typename?: 'MapsV1GetFeaturesResponse';
    featuresByShiftId?: Maybe<Array<Maybe<MapsV1FeaturesByShiftId>>>;
};

export type Mutation = {
    __typename?: 'Mutation';
    helloworldV1GreeterServiceSayHello?: Maybe<HelloworldV1SayHelloResponse>;
    coachinganalyticsV1UploadFile?: Maybe<
        CoachinganalyticsV1UploadFileResponse
    >;
    coachinganalyticsV1DeleteFile?: Maybe<
        CoachinganalyticsV1DeleteFileResponse
    >;
    coachinganalyticsV1ShiftUploadComplete?: Maybe<
        CoachinganalyticsV1ShiftUploadCompleteResponse
    >;
};

export type MutationHelloworldV1GreeterServiceSayHelloArgs = {
    input?: Maybe<HelloworldV1SayHelloRequestInput>;
};

export type MutationCoachinganalyticsV1UploadFileArgs = {
    input?: Maybe<CoachinganalyticsV1UploadFileRequestInput>;
};

export type MutationCoachinganalyticsV1DeleteFileArgs = {
    input?: Maybe<CoachinganalyticsV1DeleteFileRequestInput>;
};

export type MutationCoachinganalyticsV1ShiftUploadCompleteArgs = {
    input?: Maybe<CoachinganalyticsV1ShiftUploadCompleteRequestInput>;
};

export type Query = {
    __typename?: 'Query';
    helloworldV1GreeterServicePing?: Maybe<ServerStatus>;
    mapsV1GetFeatures?: Maybe<MapsV1GetFeaturesResponse>;
    mapsV1Ping?: Maybe<ServerStatus>;
    coachinganalyticsV1GetFiles?: Maybe<CoachinganalyticsV1GetFilesResponse>;
    coachinganalyticsV1Ping?: Maybe<ServerStatus>;
};

export type QueryMapsV1GetFeaturesArgs = {
    input?: Maybe<MapsV1GetFeaturesRequestInput>;
};

export type QueryCoachinganalyticsV1GetFilesArgs = {
    input?: Maybe<CoachinganalyticsV1GetFilesRequestInput>;
};

/** status of the server */
export type ServerStatus = {
    __typename?: 'ServerStatus';
    status?: Maybe<Scalars['String']>;
};

export type DeleteAnalyticsFileMutationVariables = Exact<{
    input?: Maybe<CoachinganalyticsV1DeleteFileRequestInput>;
}>;

export type DeleteAnalyticsFileMutation = { __typename?: 'Mutation' } & {
    deleteFile?: Maybe<
        { __typename?: 'CoachinganalyticsV1DeleteFileResponse' } & {
            filesWrapper?: Maybe<
                { __typename?: 'CoachinganalyticsV1FilesWrapper' } & {
                    files?: Maybe<
                        Array<
                            Maybe<
                                {
                                    __typename?: 'CoachinganalyticsV1File';
                                } & Pick<
                                    CoachinganalyticsV1File,
                                    | 'fileKey'
                                    | 'fileId'
                                    | 'fileName'
                                    | 'uploadedAt'
                                    | 'uploadedByUserId'
                                    | 'uploadedByUserName'
                                >
                            >
                        >
                    >;
                }
            >;
        }
    >;
};

export type GetFilesQueryVariables = Exact<{
    input?: Maybe<CoachinganalyticsV1GetFilesRequestInput>;
}>;

export type GetFilesQuery = { __typename?: 'Query' } & {
    getFiles?: Maybe<
        { __typename?: 'CoachinganalyticsV1GetFilesResponse' } & {
            filesWrapper?: Maybe<
                { __typename?: 'CoachinganalyticsV1FilesWrapper' } & {
                    files?: Maybe<
                        Array<
                            Maybe<
                                {
                                    __typename?: 'CoachinganalyticsV1File';
                                } & Pick<
                                    CoachinganalyticsV1File,
                                    | 'fileId'
                                    | 'fileKey'
                                    | 'fileName'
                                    | 'uploadedAt'
                                    | 'uploadedByUserId'
                                    | 'uploadedByUserName'
                                >
                            >
                        >
                    >;
                }
            >;
        }
    >;
};

export type GetMapFeaturesQueryVariables = Exact<{
    input?: Maybe<MapsV1GetFeaturesRequestInput>;
}>;

export type GetMapFeaturesQuery = { __typename?: 'Query' } & {
    getMapFeatures?: Maybe<
        { __typename?: 'MapsV1GetFeaturesResponse' } & {
            featuresByShiftId?: Maybe<
                Array<
                    Maybe<
                        { __typename?: 'MapsV1FeaturesByShiftId' } & Pick<
                            MapsV1FeaturesByShiftId,
                            | 'shiftId'
                            | 'speedZonesGeojson'
                            | 'womidsGeojson'
                            | 'trackingRegionsGeojson'
                            | 'materialMovementsGeojson'
                        >
                    >
                >
            >;
        }
    >;
};

export type UploadFileKeyMutationVariables = Exact<{
    input?: Maybe<CoachinganalyticsV1UploadFileRequestInput>;
}>;

export type UploadFileKeyMutation = { __typename?: 'Mutation' } & {
    uploadFileKey?: Maybe<
        { __typename?: 'CoachinganalyticsV1UploadFileResponse' } & {
            filesWrapper?: Maybe<
                { __typename?: 'CoachinganalyticsV1FilesWrapper' } & {
                    files?: Maybe<
                        Array<
                            Maybe<
                                {
                                    __typename?: 'CoachinganalyticsV1File';
                                } & Pick<
                                    CoachinganalyticsV1File,
                                    | 'fileKey'
                                    | 'fileId'
                                    | 'fileName'
                                    | 'uploadedAt'
                                    | 'uploadedByUserId'
                                    | 'uploadedByUserName'
                                >
                            >
                        >
                    >;
                }
            >;
        }
    >;
};

export const DeleteAnalyticsFileDocument = gql`
    mutation DeleteAnalyticsFile(
        $input: CoachinganalyticsV1DeleteFileRequestInput
    ) {
        deleteFile: coachinganalyticsV1DeleteFile(input: $input) {
            filesWrapper {
                files {
                    fileKey
                    fileId
                    fileName
                    uploadedAt
                    uploadedByUserId
                    uploadedByUserName
                }
            }
        }
    }
`;
export const GetFilesDocument = gql`
    query GetFiles($input: CoachinganalyticsV1GetFilesRequestInput) {
        getFiles: coachinganalyticsV1GetFiles(input: $input) {
            filesWrapper {
                files {
                    fileId
                    fileKey
                    fileName
                    uploadedAt
                    uploadedByUserId
                    uploadedByUserName
                }
            }
        }
    }
`;
export const GetMapFeaturesDocument = gql`
    query GetMapFeatures($input: MapsV1GetFeaturesRequestInput) {
        getMapFeatures: mapsV1GetFeatures(input: $input) {
            featuresByShiftId {
                shiftId
                speedZonesGeojson
                womidsGeojson
                trackingRegionsGeojson
                materialMovementsGeojson
            }
        }
    }
`;
export const UploadFileKeyDocument = gql`
    mutation UploadFileKey($input: CoachinganalyticsV1UploadFileRequestInput) {
        uploadFileKey: coachinganalyticsV1UploadFile(input: $input) {
            filesWrapper {
                files {
                    fileKey
                    fileId
                    fileName
                    uploadedAt
                    uploadedByUserId
                    uploadedByUserName
                }
            }
        }
    }
`;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (sdkFunction) => sdkFunction();

export function getSdk(
    client: GraphQLClient,
    withWrapper: SdkFunctionWrapper = defaultWrapper,
) {
    return {
        DeleteAnalyticsFile(
            variables?: DeleteAnalyticsFileMutationVariables,
            requestHeaders?: Dom.RequestInit['headers'],
        ): Promise<DeleteAnalyticsFileMutation> {
            return withWrapper(() =>
                client.request<DeleteAnalyticsFileMutation>(
                    DeleteAnalyticsFileDocument,
                    variables,
                    requestHeaders,
                ),
            );
        },
        GetFiles(
            variables?: GetFilesQueryVariables,
            requestHeaders?: Dom.RequestInit['headers'],
        ): Promise<GetFilesQuery> {
            return withWrapper(() =>
                client.request<GetFilesQuery>(
                    GetFilesDocument,
                    variables,
                    requestHeaders,
                ),
            );
        },
        GetMapFeatures(
            variables?: GetMapFeaturesQueryVariables,
            requestHeaders?: Dom.RequestInit['headers'],
        ): Promise<GetMapFeaturesQuery> {
            return withWrapper(() =>
                client.request<GetMapFeaturesQuery>(
                    GetMapFeaturesDocument,
                    variables,
                    requestHeaders,
                ),
            );
        },
        UploadFileKey(
            variables?: UploadFileKeyMutationVariables,
            requestHeaders?: Dom.RequestInit['headers'],
        ): Promise<UploadFileKeyMutation> {
            return withWrapper(() =>
                client.request<UploadFileKeyMutation>(
                    UploadFileKeyDocument,
                    variables,
                    requestHeaders,
                ),
            );
        },
    };
}
export type Sdk = ReturnType<typeof getSdk>;
