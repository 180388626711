import React, { Children, cloneElement } from 'react';
import ReactDOM from 'react-dom';
import { Control, DomUtil } from 'leaflet';
import { withLeaflet, MapControl } from 'react-leaflet';
import { PicRightOutlined } from '@ant-design/icons';
import { GroupPure } from '../LayerSelector/Group';
import { ItemPure } from '../LayerSelector/Item';

class CustomLayersControl extends MapControl {
    constructor(props, context) {
        super(props);
        this.controlProps = {
            removeLayer: this.removeLayer.bind(this),
            leaflet: props.leaflet,
            addOverlay: this.addOverlay.bind(this),
            removeLayerControl: this.removeLayerControl.bind(this),
        };

        this.state = {
            menuOpen: false,
            layers: {},
            menus: [],
            addClass: true,
            customLayerGrouping: props.customLayerGrouping,
        };
    }
    // This function is called by the Overlay.componentWillUnmount
    removeLayerControl = () => {};
    // This is called when where a new layer is added to the map in the first render. It is called every time a layer gets added to the maps
    addOverlay = (layer, name, checked) => {
        let group;
        let customGroups = this.state.customLayerGrouping;
        /// traverse each object in the props.customLayeringGroup and check if the layerName has the name key
        if (
            customGroups &&
            name &&
            this.state.customLayerGrouping !== undefined
        ) {
            customGroups.forEach((eachObject) => {
                if (eachObject.layerNames.indexOf(name) > -1) {
                    group = eachObject.groupName;
                }
            });
        } else {
            group = 'default';
        }
        if (checked && this.props.leaflet.map != null) {
            this.props.leaflet.map.addLayer(layer);
        }
        if (group == null) return;

        this.setState((prevState, props) => {
            let currentLayers = { ...prevState.layers };
            let currentGroup = currentLayers[group];

            currentGroup = currentGroup
                ? [
                      ...currentGroup.filter((x) => x.name !== name),
                      { layer, name, checked, group },
                  ]
                : [{ layer, name, checked, group }];
            currentLayers[group] = currentGroup;
            return {
                layers: currentLayers,
            };
        });
    };

    // This function removes the actual layer from the leaflet map
    removeLayer(layer) {
        if (this.props.leaflet.map != null) {
            this.props.leaflet.map.removeLayer(layer);
        }
    }

    //create and return a leaflet object you want to extend
    createLeafletElement(props) {
        // extend control from leaflet
        const MyControl = Control.extend({
            // there are only two options we can have here for extending a control
            // https://leafletjs.com/reference-1.5.0.html#control

            // Should return the container DOM element for the control and add listeners on relevant map events
            onAdd: (map) => {
                this.container = DomUtil.create('div');
                this.map = map;
                return this.container;
            },
        });

        // return new instance of our control and pass it all the props
        return new MyControl(props);
    }

    updateLeafletElement(fromProps, toProps) {
        super.updateLeafletElement(fromProps, toProps);
    }

    componentDidMount(props) {
        super.componentDidMount();
        this.forceUpdate();
    }

    toggleLayer = (layerInput) => {
        const { layer, name, group, checked } = layerInput;
        // Fire the action to bind the toggle layer
        this.props.onOverlayAdd(!checked, name);
        let layers = { ...this.state.layers };
        layers[group] = layers[group].map((l) => {
            if (l.name === name) {
                l.checked = !l.checked;

                l.checked
                    ? this.props.leaflet.map.addLayer(layer)
                    : this.removeLayer(layer);
            }
            return l;
        });

        this.setState({
            layers,
        });
    };

    openMenu = () => {
        this.setState({ menuOpen: true, addClass: false });
    };
    closeMenu = () => {
        this.setState({ menuOpen: false, addClass: true });
    };
    render() {
        if (!this.leafletElement || !this.leafletElement.getContainer()) {
            return null;
        }
        const styleClassOnHover = {
            background: 'white',
            padding: '0 5px',
        };
        const styleClassOnLeave = {
            background: 'white',
            padding: 0,
        };
        return (
            <React.Fragment>
                {ReactDOM.createPortal(
                    <div
                        onMouseEnter={this.openMenu}
                        onMouseLeave={this.closeMenu}
                        style={
                            this.state.addClass
                                ? styleClassOnHover
                                : styleClassOnLeave
                        }
                        id="custom-layer-selector"
                    >
                        {this.state.menuOpen && (
                            <div
                                style={{
                                    background: 'white',
                                    padding: 0,
                                    borderRadius: 0,
                                }}
                            >
                                {Object.keys(this.state.layers).map((g) => {
                                    return (
                                        <GroupPure label={g} key={g}>
                                            {this.state.layers[g].map((l) => {
                                                return (
                                                    <div
                                                        id={`custom-layer-selector-${l.name
                                                            .split(' ')
                                                            .join('-')
                                                            .toLowerCase()}`}
                                                    >
                                                        <ItemPure
                                                            label={l.name}
                                                            onClick={() =>
                                                                this.toggleLayer(
                                                                    l,
                                                                )
                                                            }
                                                            checked={l.checked}
                                                            key={l.name}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </GroupPure>
                                    );
                                })}
                            </div>
                        )}
                        {!this.state.menuOpen && (
                            <div
                                style={{
                                    fontSize: '25px',
                                }}
                            >
                                <PicRightOutlined />
                            </div>
                        )}
                    </div>,
                    this.leafletElement.getContainer(),
                )}
                {Children.map(this.props.children, (child) => {
                    return child
                        ? cloneElement(child, this.controlProps)
                        : null;
                })}
            </React.Fragment>
        );
    }
}
export default withLeaflet(CustomLayersControl);
