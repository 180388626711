import React from 'react';
import PropTypes from 'prop-types';
import Control from 'react-leaflet-control';
import { Button } from 'antd';

class Legend extends React.PureComponent {
    static propTypes = {
        // Function that should return JSX to display, passes an object with isVisible to handle toggling
        renderLegend: PropTypes.func.isRequired,
        // Whether to use a button to show/hide a legend
        useButton: PropTypes.bool,
    };
    static defaultProps = {
        useButton: true,
    };

    state = {
        isVisible: false,
    };

    // hacky work around to make the legend display on page load
    // https://github.com/LiveBy/react-leaflet-control/issues/27#issuecomment-430564722
    componentDidMount() {
        this.forceUpdate();
    }

    toggleVisibility = () => {
        this.setState((prevState) => ({
            isVisible: !prevState.isVisible,
        }));
    };

    render() {
        const { renderLegend, useButton } = this.props;
        const { isVisible } = this.state;

        if (useButton) {
            return (
                <Control position="bottomright" {...this.props}>
                    <div className="MinesiteMap__Legend__Container">
                        {renderLegend({ isVisible })}
                        <Button
                            className="MinesiteMap__Legend__Trigger"
                            onClick={this.toggleVisibility}
                        >
                            Legend
                        </Button>
                    </div>
                </Control>
            );
        }
        // Don't render with a button and always show the legend
        return (
            <Control position="bottomright" {...this.props}>
                <div className="MinesiteMap__Legend__Container">
                    {renderLegend({ isVisible: true })}
                </div>
            </Control>
        );
    }
}

export default Legend;
