/**
 * Duplicates alerts for each equipment that is a part of the alert
 * eg. INPUT
 * [{
 *     AlertType: 'LateLogin',
 *     Equipments: [
 *         {EquipmentSiteName: DT01},
 *         {EquipmentSiteName: DT02},
 *     ]
 * }]
 *
 * OUTPUT
 * {
 *     AlertType: 'LateLogin',
 *     EquipmentSiteName: 'DT01',
 *     Equipments: [
 *         {EquipmentSiteName: DT01},
 *         {EquipmentSiteName: DT02},
 *     ]
 * },
 * {
 *     AlertType: 'LateLogin',
 *     EquipmentSiteName: 'DT02',
 *     Equipments: [
 *         {EquipmentSiteName: DT01},
 *         {EquipmentSiteName: DT02},
 *     ]
 * }
 *
 * @param {{}[]} operatorAlerts
 * @return {{}[]}
 */
export default (operatorAlerts) => {
    return operatorAlerts.reduce((c, row) => {
        const seenEquipment = [];
        row.Equipments.forEach((equipment) => {
            if (seenEquipment.includes(equipment.equipmentSiteName)) {
                return;
            }
            seenEquipment.push(equipment.equipmentSiteName);
            c.push({
                EquipmentSiteName: equipment.equipmentSiteName,
                equipment,
                ...row,
            });
        });
        return c;
    }, []);
};
