import combineReducers from 'combine-reducers-global-state';
import namespace from '../Modules/namespace';
import {
    moduleName as mapLegendsName,
    reducer as mapLegends,
} from '../Modules/mapLegends';

import {
    moduleName as mapFeaturesName,
    reducer as mapFeatures_v2,
} from '../Modules/mapFeatures';

import baseReducer from './baseReducer';

export const BASE_STATE_NAME = 'base';
export const REDUCER_KEY = namespace;

export const reducer = combineReducers({
    [BASE_STATE_NAME]: baseReducer,
    [mapLegendsName]: mapLegends,
    [mapFeaturesName]: mapFeatures_v2,
});
