import PropTypes from 'prop-types';

export interface TypeTableColumn {
    key: string;
    className?: string;
    label?: string;
    Header: () => any;
    Cell: () => any;
    Footer: () => any;
    featureId?: string;
    customProps?: any;
}
export const typeTableColumn = PropTypes.shape({
    key: PropTypes.string.isRequired,
    className: PropTypes.string,
    label: PropTypes.string,
    Header: PropTypes.func.isRequired,
    Cell: PropTypes.func.isRequired,
    Footer: PropTypes.func.isRequired,
    featureId: PropTypes.string,
    customProps: PropTypes.shape({}),
});

export type TypeTableValue = number | string;
export const typeTableValue = PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
]);
