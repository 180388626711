import isEqual from 'lodash/isEqual';
import {
    put,
    select,
    call,
    all,
    takeLeading,
    delay,
    fork,
} from 'redux-saga/effects';
import { getRouterState } from '../Modules/router/selectors';
import {
    getApiParams as SelectNetworkCoverageReportApiParams,
    getPreviousApiParams as SelectNetworkCoverageReportPreviousApiParams,
    getPageParams as SelectNetworkCoverageReportPageParams,
    getAvailableH3ResolutionIndex as SelectNetworkCoverageReportGetAvailableH3ResolutionIndex,
    getH3DataInMap as SelectNetworkCoverageReportGetH3DataInMap,
    getError as SelectNetworkCoverageReportGetError,
    getCurrentH3Index as SelectNetworkCoverageReportGetCurrentH3Index,
} from '../NetworkCoverage/Selectors';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import * as loadingSpinnerModule from '../NetworkCoverage/Modules/spinner';
import * as componentModule from '../NetworkCoverage/Modules/meta';
import { actions as NetworkCoverageReportAction } from '../NetworkCoverage/Modules/networkCoverageReport';
import { actions as MineSiteMapAction } from '../NetworkCoverage/Modules/minesiteMap';
import * as resourceSagas from './resourceSagas';
import { checkRequestsForErrors } from '../Api';
import * as L from 'leaflet';
import qs from 'querystring';
import {
    getPathnameWithRouteMeta,
    takeLeadingWithParam,
    updateRoute,
} from './utils';
import { smartHexagonFilter } from '@rs/core/falcon/components/MinesiteMap/Hexagons';
import * as WKTAggregateModule from '../NetworkCoverage/Modules/WKTAggregate';
import * as datePickerModule from '../NetworkCoverage/Modules/datePicker';
import shiftUtils from '@rs/core/utils/shiftUtils';

function* fetchRoadPathData() {
    const startTime = yield select(datePickerModule.selectors.getStartTime);
    const shiftId = shiftUtils.unixMillisecondsToShiftId(startTime * 1000);
    const { response, error } = yield call(resourceSagas.getWKTAggregate, {
        FirstShiftId: shiftId,
    });
    if (error) {
        yield put(
            WKTAggregateModule.actions.fetchWKTAggregateError({
                error: `Failed to fetch locus data`,
            }),
        );
        return;
    }
    yield put(WKTAggregateModule.actions.fetchWKTAggregateSuccess(response));
}
// check if the two time stamps are greater than 7 days
const isGreaterThanSevenDays = (startTime, endTime) =>
    endTime - startTime > 7 * 60 * 60 * 24;

export function* fetchMapData() {
    try {
        // check if the end date and the start date is more than 7 days in range mode
        const mode = yield select(datePickerModule.selectors.getMode);
        const startTime = yield select(datePickerModule.selectors.getStartTime);
        const endTime = yield select(datePickerModule.selectors.getEndTime);
        if (mode === 'range' && isGreaterThanSevenDays(startTime, endTime)) {
            yield put(
                NetworkCoverageReportAction.fetchMapDataError({
                    message: 'Date Range Exceeds Limit.',
                    description:
                        'You cannot select a date range longer than seven days',
                }),
            );

            // clear the data but don't call the api
            yield put(NetworkCoverageReportAction.clearData());
            return;
        }

        const apiParams = yield select(SelectNetworkCoverageReportApiParams);
        const previousApiParams = yield select(
            SelectNetworkCoverageReportPreviousApiParams,
        );
        // check if the api params changed skip if not
        // e.g. changing the zoom level triggered the location change pattern
        //      but api does not need to be called
        //      And check if there are data already
        const apiParamsEqual = isEqual(apiParams, previousApiParams);

        if (apiParamsEqual) return;

        yield put(
            loadingSpinnerModule.actions.setSpinnerState(
                true,
                LOADING_MESSAGES.LOADING__DATA,
            ),
        );

        yield fork(fetchRoadPathData);
        const [networkCoverageReport] = yield all([
            call(resourceSagas.getNetworkCoverageReport, {
                ...apiParams,
            }),
        ]);

        if (
            checkRequestsForErrors([networkCoverageReport]) ||
            !networkCoverageReport?.response?.metrics[0]?.data?.length
        ) {
            yield put(
                NetworkCoverageReportAction.fetchMapDataError({
                    message: 'There are no results for this query.',
                }),
            );
            yield put(loadingSpinnerModule.actions.setSpinnerState(false));
            return;
        }
        yield put(
            NetworkCoverageReportAction.fetchMapDataSuccess({
                ...networkCoverageReport.response,
            }),
        );

        yield put(loadingSpinnerModule.actions.setSpinnerState(false));
        // debounce it
        yield delay(500);
    } catch (e) {
        console.error(e);
    }
}

export function* preFetchMapDataOfIndex(action) {
    const index = action.payload;
    try {
        const currentH3Index = yield select(
            SelectNetworkCoverageReportGetCurrentH3Index,
        );
        if (index === currentH3Index) {
            yield put(
                loadingSpinnerModule.actions.setSpinnerState(
                    true,
                    LOADING_MESSAGES.LOADING__DATA,
                ),
            );
        }

        const error = yield select(SelectNetworkCoverageReportGetError);
        if (error) {
            return;
        }
        const apiParams = yield select(SelectNetworkCoverageReportApiParams);

        const [networkCoverageReport] = yield all([
            call(resourceSagas.getNetworkCoverageReport, {
                ...apiParams,
                H3Resolution: index,
            }),
        ]);

        if (
            checkRequestsForErrors([networkCoverageReport]) ||
            !networkCoverageReport?.response?.metrics?.length
        ) {
            yield put(
                NetworkCoverageReportAction.fetchMapDataError({
                    message: 'There are no results for this query.',
                }),
            );
            return;
        }
        if (index === apiParams.H3Resolution) {
            // if the index prefetched is the one intended to be displayed
            yield put(loadingSpinnerModule.actions.setSpinnerState(false));
        }
        yield put(
            NetworkCoverageReportAction.fetchMapDataSuccess({
                ...networkCoverageReport.response,
            }),
        );
    } catch (e) {
        console.error(e);
    }
}

export function* prepareToFetch() {
    try {
        const { query, pathname } = (yield select(getRouterState)).location;

        const pageParams = yield select(SelectNetworkCoverageReportPageParams);
        // sanitise all url query params
        // match it up with the default page params
        // remove any unmatched
        const sanitisedPageQueryParams = Object.keys(query)
            .filter((key) => Object.keys(pageParams).includes(key))
            .reduce((obj, key) => {
                return {
                    ...obj,
                    [key]: query[key],
                };
            }, {});

        // Add any missing/ default params to the URL
        yield updateRoute(
            pathname,
            qs.stringify(Object.assign(pageParams, sanitisedPageQueryParams)),
        );

        yield put(NetworkCoverageReportAction.syncURLToState(query));
        yield call(fetchMapData);

        // also warming up other data index
        const allAvailableH3Index = yield select(
            SelectNetworkCoverageReportGetAvailableH3ResolutionIndex,
        );
        const currentH3Index = yield select(
            SelectNetworkCoverageReportGetCurrentH3Index,
        );
        const currentDataInMap = yield select(
            SelectNetworkCoverageReportGetH3DataInMap,
        );

        for (const index in allAvailableH3Index) {
            const h3Index = allAvailableH3Index[index];
            if (
                h3Index !== currentH3Index ||
                currentDataInMap[h3Index] === null
            ) {
                yield put(
                    NetworkCoverageReportAction.preFetchMapDataOfIndex(h3Index),
                );
            }
        }
        yield delay(1000);
    } catch (e) {
        console.error(e);
    }
}

export function* routeChanged() {
    try {
        const { pathname } = (yield select(getRouterState)).location;

        const currentComponentFeatureId = yield select(
            componentModule.selectors.getFeatureId,
        );
        const activeComponentPathname = getPathnameWithRouteMeta(
            currentComponentFeatureId,
        );

        // check if the component is active
        if (activeComponentPathname === pathname) {
            yield put(NetworkCoverageReportAction.prepareToFetchData());
        }

        // debounce it
        yield delay(500);
    } catch (e) {
        console.error(e);
    }
}

export function* findTheBestH3ResolutionIndex() {
    try {
        // use the helper function to determine the best resolution index
        const currentParams = yield select(
            SelectNetworkCoverageReportPageParams,
        );
        const availableH3Index = yield select(
            SelectNetworkCoverageReportGetAvailableH3ResolutionIndex,
        );

        const bounds = currentParams.bounds
            .split(',')
            .map((ll) => parseFloat(ll));
        const LBounds = L.latLngBounds(
            L.latLng(bounds[0], bounds[1]),
            L.latLng(bounds[2], bounds[3]),
        );

        const { bestResolution } = smartHexagonFilter(
            LBounds,
            availableH3Index,
            200,
        );

        const currentDataInMap = yield select(
            SelectNetworkCoverageReportGetH3DataInMap,
        );
        // pre fetch the data first
        if (currentDataInMap[bestResolution] === null) {
            // no data yet for the selected resolution
            yield put(
                NetworkCoverageReportAction.preFetchMapDataOfIndex(
                    bestResolution,
                ),
            );
        }

        yield put(
            NetworkCoverageReportAction.h3ResolutionBestIndexChanged(
                bestResolution,
            ),
        );

        // if the best resolution is == to the data index we just retrieved
        // Mutate the h3 resolution index to the best one we are targeting
        const currentH3Index = yield select(
            SelectNetworkCoverageReportGetCurrentH3Index,
        );
        const needToUpdateH3Resolution = bestResolution !== currentH3Index;

        // Are we ready to display best data
        if (
            needToUpdateH3Resolution &&
            currentDataInMap[bestResolution] !== null
        ) {
            yield put(
                NetworkCoverageReportAction.h3ResolutionIndexChanged(
                    bestResolution,
                ),
            );
        }

        yield delay(500);
    } catch (e) {
        console.error(e);
    }
}

export default function* watch() {
    try {
        yield all([
            // prevent calling saga until the route changed action execute as it will fire a route change event instantly
            // we can't use debounce or throttle here as they wouldn't block listening for the pattern again
            // take leading is listening for the location change pattern ONLY when routeChanged doesn't run
            takeLeading('@@router/LOCATION_CHANGE', routeChanged),
            takeLeading(
                NetworkCoverageReportAction.PREPARE_TO_FETCH_DATA,
                prepareToFetch,
            ),
            takeLeadingWithParam(
                NetworkCoverageReportAction.PRE_FETCH_MAP_DATA_OF_INDEX,
                (action) => action.payload,
                preFetchMapDataOfIndex,
            ),
            takeLeading(
                MineSiteMapAction.ON_BOUNDS_CHANGE,
                findTheBestH3ResolutionIndex,
            ),
        ]);
    } catch (e) {
        console.error(e);
    }
}
