import { createSelector } from 'reselect';
import shiftUtils from '@rs/core/utils/shiftUtils';
import { filterUniqueItems } from '@rs/core/utils/dataUtils';
import { compareString } from '@rs/core/utils/sortingUtils';
import { operator } from '@rs/core/application';
import {
    filterOptionsToReactSelectFormat,
    filterShiftOptionsToReactSelectFormat,
} from '../../../Utils/Filters/filterOptionsToReactSelectFormat';
import * as equipmentIdentifiersModule from '../Modules/equipmentIdentifiers';
import * as operatorModule from '../Modules/operators';
import * as filtersModule from '../Modules/filters';
import {
    filterRecordByFilter,
    operatorOptionsToReactSelectFormat,
} from '../../../Utils/Filters/filters';
import { SELECT_STRING_DELIMITER } from '../../../Components/Select';
import { API_ARRAY_DELIMITER } from '../../../Api';
import { getOperatorDisplayNameType } from '../../../App/Selectors';

export const getFilterOptions = createSelector(
    filtersModule.selectors.getFilters,
    equipmentIdentifiersModule.selectors.get,
    operatorModule.selectors.get,
    getOperatorDisplayNameType,
    (filters, equipmentIdentifiers, operators, operatorDisplayNameType) => {
        const shifts = shiftUtils.getAllShiftShortNamesForDate(filters.Date);

        const uniqueEquipmentSiteNames = equipmentIdentifiers
            .map((row) => row.EquipmentSiteName)
            .filter(filterUniqueItems)
            .sort((a, b) => compareString(b, a));
        const equipmentOptions = filterOptionsToReactSelectFormat(
            uniqueEquipmentSiteNames,
        );

        const uniqueEquipmentOperators = operators
            .filter((row) =>
                filterRecordByFilter(
                    filters.EquipmentSiteName,
                    row.EquipmentSiteName,
                ),
            )
            .map((row) => row.EquipmentOperatorName)
            .filter(filterUniqueItems)
            .sort((a, b) => compareString(b, a));
        const operatorOptions = operatorOptionsToReactSelectFormat(
            uniqueEquipmentOperators,
            operator.displayName.OperatorDisplayFormats[
                operatorDisplayNameType
            ],
        );

        return {
            shifts: filterShiftOptionsToReactSelectFormat(
                shifts.rawShiftsInDay,
                shifts.shiftShortNames,
            ),
            operators: operatorOptions,
            equipmentSiteNames: equipmentOptions,
        };
    },
);

export const getMapFiltersToAPIParams = createSelector(
    filtersModule.selectors.getFilters,
    (filters) => {
        const shiftId = shiftUtils
            .generateShiftIdRange(
                filters.Date,
                filters.EndDate,
                filters.Shift.split(SELECT_STRING_DELIMITER),
            )
            .join(API_ARRAY_DELIMITER);
        return {
            shiftId,
        };
    },
);
