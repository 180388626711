import { createReducer } from '../../Lib/reduxUtils';
import {
    setEndDateAfterDateSelected,
    setEndDateAfterRangeSelected,
} from '../../Utils/Filters/filters';

const initialState = {};

export function filterUpdated(state: any, payload: any) {
    const { filterName, filterValue } = payload;
    const newState = {
        ...state,
        [filterName]: filterValue === null ? '' : filterValue,
    };
    // TODO change these methods to not mutate the passed in state
    // Handle toggling rangeSelected
    setEndDateAfterDateSelected(newState, filterName);

    // Handle setting the EndDate to the same as Date if EndDate is before start Date
    setEndDateAfterRangeSelected(newState, filterName);

    return newState;
}

export function setFiltersWithUrlParams(state: any, filtersFromUrl: any) {
    return {
        ...state,
        ...filtersFromUrl,
    };
}

export function filterUpdatedBatch(state: any, payload: any) {
    return payload.reduce(
        (nextState: any, { filterName, filterValue }: any) => {
            return filterUpdated(nextState, { filterName, filterValue });
        },
        state,
    );
}

export function syncURLToState(state: any, params: any) {
    const validKeys = Object.keys(state);
    const validParamKeys = Object.keys(params).filter((key) =>
        validKeys.find((vk) => vk === key),
    );
    return validParamKeys.reduce(
        (agg, key) => {
            agg[key] = params[key];
            return agg;
        },
        { ...state },
    );
}

/**
 * Creates a reducer for this module. It has some default behaviour but also allows custom actions to be supplied
 * @param {object} actions {<internalActionName>: <ACTION_TYPE>} maps an internalActionName to an ACTION_TYPE. The handler for this ACTION_TYPE is the default behaviour
 * @param {object} customActions {<ACTION_TYPE>: actionHandler} maps an ACTION_TYPE to an action handler
 * @return {function(*=, {type: *, payload?: *})}
 */
export default function createReducers(
    actions: any,
    customActions = {},
    customInitialState = {},
) {
    return createReducer(
        {
            ...initialState,
            ...customInitialState,
        },
        {
            [actions.filterUpdated]: filterUpdated,
            [actions.filterUpdatedBatch]: filterUpdatedBatch,
            [actions.setFiltersWithUrlParams]: setFiltersWithUrlParams,
            /**
             * Ensure any custom action handlers pass along state + any additional arguments needed for the
             * action handlers
             */
            ...customActions,
        },
    );
}
