import { REDUCER_KEY } from '../Reducers/index';

export const getErrorState = (state) => {
    const errorState = state[REDUCER_KEY].ui.errors;
    const errorKeys = Object.keys(errorState);
    const hasErrors = errorKeys.some((key) => errorState[key] !== null);
    const errors = errorKeys.map((key) => ({
        id: key,
        message: errorState[key],
    }));
    return {
        hasErrors,
        errors,
    };
};
