import shiftUtils from '@rs/core/utils/shiftUtils';
import { createReducer } from '../../Lib/reduxUtils';
import { FILTER_UPDATED, SYNC_URL_TO_STATE } from '../Actions';
import { actions as haulRoadConditionSummaryActions } from '../Modules/haulRoadConditionSummary';
import { FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS } from '../../App/Actions';
import { syncURLToState } from '../../Modules/filters/reducer';

export const initialState = {
    Date: undefined,
    Shift: '',
    WhereOnMinesiteId: '',
    MapShiftId: null,
};

/**
 * Sets the MapShiftId property if it doesn't exist on the object
 * @param state - the local state
 * @return object - returns a new state object with the MapShiftId property
 */
function withMapShiftId(state) {
    if (state.MapShiftId) {
        return state;
    }

    const firstShiftForDate = shiftUtils.getFirstShiftFromDate(state.Date);

    const nextState = {
        ...state,
        Shift: state.Shift || firstShiftForDate.shortName,
    };

    const mapShiftId = shiftUtils.getShiftIdFromDateString(
        nextState.Date,
        nextState.Shift,
    );

    return {
        ...state,
        MapShiftId: parseInt(mapShiftId, 10),
    };
}

export function filterUpdated(state, { filterName, filterValue }) {
    return withMapShiftId({
        ...state,
        [filterName]: filterValue || '',
    });
}

export function setFiltersWithUrlParams(state, filtersFromUrl) {
    const nextState = syncURLToState(state, filtersFromUrl);
    return withMapShiftId({
        ...state,
        ...nextState,
    });
}

export function fetchHaulRoadConditionSummarySuccess(
    state,
    haulRoadConditionSummary,
) {
    const allShiftIds = haulRoadConditionSummary.map((row) => row.ShiftId);
    const uniqueDescendingShiftIds = Array.from(new Set(allShiftIds)).sort(
        (a, b) => b - a,
    );
    if (uniqueDescendingShiftIds.indexOf(state.MapShiftId) === -1) {
        return {
            ...state,
            MapShiftId: uniqueDescendingShiftIds[0],
        };
    }
    return state;
}

export function fetchPrivateSiteConfigurationSuccess(state) {
    if (state.Shift) {
        return state;
    }
    const defaultDate = shiftUtils.newDefaultFilterDate();
    const firstShift = shiftUtils.getFirstShiftFromDate(defaultDate);
    return {
        ...state,
        Date: defaultDate,
        Shift: firstShift.shortName,
    };
}

export default createReducer(initialState, {
    [FILTER_UPDATED]: filterUpdated,
    [SYNC_URL_TO_STATE]: setFiltersWithUrlParams,
    [haulRoadConditionSummaryActions.FETCH_HAUL_ROAD_CONDITION_SUMMARY_SUCCESS]: fetchHaulRoadConditionSummarySuccess,
    [FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS]: fetchPrivateSiteConfigurationSuccess,
});
