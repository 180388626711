import namespace from '../../DAS/Modules/namespace';

// TODO delete all this code once API is equipment id based
/**
 *
 * @param {array} allRecords - SLD records
 * @param {number} timestamp - the time to check against
 * @param {number} equipmentId - the equipment to find
 * @return {*}
 */
export function getTruckNameForTimestamp(allRecords, timestamp, equipmentId) {
    return allRecords.find((row) => {
        return (
            row.EquipmentId === equipmentId &&
            timestamp >= row.StartTime &&
            (timestamp <= row.EndTime || row.EndTime === null)
        );
    });
}

export default function createSelectors(statePath) {
    function getAll(state) {
        return statePath(state);
    }

    // TODO delete all this code once API is equipment id based
    function getTruckNameForTimestampFromState(state, equipmentId) {
        const currPlaybackTimestamp = state[namespace].trackPosition;
        return getTruckNameForTimestamp(
            getAll(state),
            currPlaybackTimestamp,
            equipmentId,
        );
    }

    return {
        getAll,
        getTruckNameForTimestamp: getTruckNameForTimestampFromState,
    };
}
