import React, { memo } from 'react';
import PropTypes from 'prop-types';
import shiftUtils from '@rs/core/utils/shiftUtils';
import { connect } from 'react-redux';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { typeLastShiftClassificationTotals } from '@rs/core/types';
import { PercentageAreaChart } from '@rs/core/falcon';
import getFilters from '../Selectors/filters/getFilters';
import { selectors as haulRoadConditionSummarySelectors } from '../Modules/haulRoadConditionSummary';
import * as actions from '../Actions';
import arePropsEqual from '@rs/core/falcon/utils/arePropsEqual';

class HRCChart extends React.PureComponent {
    static defaultProps = {};
    static propTypes = {
        filters: PropTypes.shape({
            Date: PropTypes.string,
            Shift: PropTypes.string,
            IsLoaded: PropTypes.string,
            WhereOnMinesiteId: PropTypes.string,
            MapShiftId: PropTypes.number,
        }).isRequired,
        filterUpdated: PropTypes.func.isRequired,
        lastXShiftsClassificationTotals: typeLastShiftClassificationTotals,
    };
    onChartColumnClick = (xValue) => {
        const shiftId = parseInt(
            shiftUtils.unixMillisecondsToShiftId(xValue),
            10,
        );
        this.props.filterUpdated('MapShiftId', shiftId);
    };

    // Format the data array to a format that HighCharts can work with
    formatHaulRoadConditionSummaryData = (data) => {
        // xSeries is the name of the property containing our x axis data
        const xSeries = 'shiftId';
        // ySeriesArray contains the names of the property(s) containing the y axis data
        const ySeriesArray = [
            'Extremely Rough',
            'Rough',
            'Moderate',
            'Light',
            'Smooth',
        ];

        // Filter out the data points where all KPIs for a date are 0
        const filteredData = filter(data, (value) => {
            let sum = 0;
            // If the sum of all the conditions is 0, consider it invalid
            Object.keys(value).forEach((key) => {
                if (key !== xSeries) {
                    sum += value[key];
                }
            });
            return sum !== 0;
        });

        // Start building up the data array...
        const chartData = ySeriesArray.map((ySeries) => {
            const seriesData = map(filteredData, (value) => {
                // Remove whitespace from the y series name
                const trimmedYSeries = ySeries.replace(/\s/g, '');

                const shiftId = value[xSeries];
                const yValue = value[trimmedYSeries];

                return {
                    x: shiftUtils.shiftIdToUnixMilliseconds(shiftId),
                    name: shiftUtils.shiftIdToDateAndDay(shiftId),
                    y: yValue,
                };
            });
            return {
                name: ySeries,
                data: seriesData,
            };
        });
        return chartData;
    };

    render() {
        const { filters, lastXShiftsClassificationTotals } = this.props;

        return (
            <PercentageAreaChart
                title={'Shift Trend For Last Month'}
                yLabel={'Key Performance Indicators'}
                xAxisType={'datetime'}
                timezone={shiftUtils.timezoneAtMinesite}
                data={lastXShiftsClassificationTotals}
                dataFormatter={(hrcData) =>
                    this.formatHaulRoadConditionSummaryData(hrcData)
                }
                selectedValue={shiftUtils.shiftIdToUnixMilliseconds(
                    filters.MapShiftId,
                )}
                onClick={this.onChartColumnClick}
                legendPosition={'none'}
                // Stops bug where the chart height slowly grows on render
                height={500}
                options={{
                    chart: {
                        // Add class so we can apply some styles
                        className: 'HaulRoadConditionChart',
                    },
                }}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        filters: getFilters(state),
        lastXShiftsClassificationTotals: haulRoadConditionSummarySelectors.getClassificationPercentForAllShifts(
            state,
        ),
    };
};

const mapActionsToProps = {
    filterUpdated: actions.filterUpdated,
};

export default memo(
    connect(mapStateToProps, mapActionsToProps)(HRCChart),
    arePropsEqual,
);
