import * as React from 'react';
import { LatLngBounds, Layer, LatLng, LeafletMouseEvent } from 'leaflet';
import { Polygon } from 'geojson';
import { Pane } from 'react-leaflet';
import boundsToLatLngArray from './utils/boundsToLatLngArray';
import GeoJSON from './UpdatingGeoJSON';
import {
    h3ToGeoBoundary,
    h3SetToMultiPolygon,
    H3Index,
    polyfill,
    hexArea,
} from 'h3-js';
import { intersection } from 'lodash';
import { ZINDEX } from './constants';

export interface HrcH3 {
    hrcValue: string;
    h3Set: H3Set[];
}

export interface H3Set {
    resolution: number;
    h3Indexes: H3Index[];
}

interface HexagonsProps {
    h3Indexes: H3Index[];
    /** Use 'individual' to draw each hexagon as a unique polygon
     * Use 'grouped' to group adjacent hexagons into one polygon */
    mode: 'individual' | 'grouped';
    className: string;
    onClickHexagon?: (latlng: LatLng) => void;
    priority?: number;
}
// we can use this to add meta to the GeoJSON
type Properties = {};

export function Hexagons({
    h3Indexes,
    mode = 'grouped',
    className,
    onClickHexagon,
    priority = 10,
}: HexagonsProps) {
    let geoJSON:
        | GeoJSON.Feature<Polygon, Properties>
        | GeoJSON.FeatureCollection<Polygon, Properties>;
    if (mode === 'individual') {
        const coordinates = h3Indexes.map((index) =>
            h3ToGeoBoundary(index, true),
        );
        geoJSON = {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates,
            },
            properties: {},
        };
    } else if (mode === 'grouped') {
        const polygons = h3SetToMultiPolygon(h3Indexes, true);
        geoJSON = {
            type: 'FeatureCollection',
            features: polygons.map((coordinates) => ({
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates,
                },
                properties: {},
            })),
        };
    } else {
        return null;
    }

    const zIndex = ZINDEX.MAP_PANE + priority;

    return (
        <Pane style={{ zIndex }}>
            <GeoJSON
                data={geoJSON}
                className={`Hexagon ${className}`}
                onEachFeature={makeOnClickHexagon(onClickHexagon)}
            />
        </Pane>
    );
}

const makeOnClickHexagon = (onClick?: (latlng: LatLng) => void) => (
    {},
    layer: Layer,
) => {
    layer.on('click', (e: LeafletMouseEvent) => {
        if (onClick) {
            // only run onClick if it is passed s a parameter
            onClick(e.latlng);
        }
        // to access Properties set in the feature, use this: (e.target.feature as GeoJSON.Feature<Polygon,Properties>).properties)
    });
};
interface FilterableHexagonsProps extends HexagonsProps {
    h3Indexes: H3Index[];
    allH3IndexesForBounds: H3Index[];
    className: string;
}

/**
 * Takes a set of hexagons to display
 *
 *                   XX    XX
 *                    XX  XX
 *                      XX
 *                     XX
 *          +----------+
 *      XX  |    XXXX  |
 *        XX|  XX      |  <- what the user is looking at
 *          |XX        |
 *          +----------+
 *            X
 *        XXXXX
 *       XX
 *
 * And applies a given hexagon mask:
 *
 *          +----------+
 *          |XXXXXXXXXX|
 *          |XXXXXXXXXX|
 *          |XXXXXXXXXX|
 *          +----------+
 *
 * It's just the intersection of 2 arrays, displaying a filtered set of hexagons:
 *
 *          +----------+
 *          |    XXXX  |
 *          |  XX      |
 *          |XX        |
 *          +----------+
 *
 * @param h3Indexes The full set of hexagons to be filtered
 * @param allH3IndexesForBounds The desired number of hexagons that should fit within the bounds
 */
function FilterableHexagons({
    h3Indexes,
    allH3IndexesForBounds,
    ...props
}: FilterableHexagonsProps) {
    const filteredH3Indexes = intersection(allH3IndexesForBounds, h3Indexes);
    return <Hexagons h3Indexes={filteredH3Indexes} {...props} />;
}

/**
 * Custom react hook to improve the performance of drawing hexagons.
 *
 * If we have a map that looks like this:
 *
 *                   XX    XX
 *                    XX  XX
 *                      XX
 *                     XX
 *          +----------+
 *      XX  |    XXXX  |
 *        XX|  XX      |  <- what the user is looking at
 *          |XX        |
 *          +----------+
 *            X
 *        XXXXX
 *       XX
 *
 * We only want to show what's inside their viewport. This hook takes viewport bounds and
 * available H3 resolutions (see here: https://h3geo.org/docs/core-library/restable)
 * to figure out the best possible resolution to display.
 *
 * It returns the best H3 resolution to show and an array of all the H3 hexagons to
 * fit in that viewport:
 *          +----------+
 *          |XXXXXXXXXX|
 *          |XXXXXXXXXX|
 *          |XXXXXXXXXX|
 *          +----------+
 * This can then be used as a intersection mask to filter down the hexagons to be shown
 *
 * @param bounds The lat/long bounds for which to crop the hexagons. Must be a rectangle
 * @param threshold The desired number of hexagons that should fit within the bounds
 * @param availableResolutions The available H3 resolutions that we have to display the hexagons
 */
export function smartHexagonFilter(
    bounds: LatLngBounds,
    availableResolutions: number[],
    threshold: number = 6000,
): {
    /** All hexagons that fit inside the given bounds */
    allH3IndexesForBounds: H3Index[];
    /** The best resolution to display out of the given availableResolutions */
    bestResolution: number;
    /** Flag that is set true if there are too many hexagons to try and filter*/
    filterNotApplied: boolean;
} {
    // We'll draw hexagons about 15% larger than the given bounds, which gives
    // us a bit of wiggle room when a user moves the map slightly
    const extendedBounds = bounds.pad(0.15);
    const latLngArray = boundsToLatLngArray(extendedBounds);

    // Calculate the desired area for each hexagon
    const width =
        extendedBounds
            .getNorthWest()
            .distanceTo(extendedBounds.getNorthEast()) / 1000;
    const height =
        extendedBounds
            .getNorthEast()
            .distanceTo(extendedBounds.getSouthEast()) / 1000;
    const area = width * height; // km^2
    const desiredHexArea = area / threshold; // km^2

    // Find the closest resolution that gives us our desired hexagon area
    const bestResolution = availableResolutions.reduce((prev, curr) => {
        const prevHexArea = hexArea(prev, 'km2');
        const currHexArea = hexArea(curr, 'km2');
        return Math.abs(currHexArea - desiredHexArea) <
            Math.abs(prevHexArea - desiredHexArea)
            ? curr
            : prev;
    });

    // If we've got a huge set of bounds and we only have teeny tiny hexagons to fit to
    // it, we need to optimise a bit to improve performance.
    //
    // If the largest resolution we can fit is too much smaller than the ideal hexagon size,
    // screw our fancy bound filter calculation and just display them all
    if (desiredHexArea / hexArea(bestResolution, 'km2') > 10) {
        // don't do the chunky polyfill
        const allH3IndexesForBounds: H3Index[] = [];
        const filterNotApplied = true;
        return { allH3IndexesForBounds, bestResolution, filterNotApplied };
    }

    const allH3IndexesForBounds = polyfill(latLngArray, bestResolution);
    const filterNotApplied = false;
    return { allH3IndexesForBounds, bestResolution, filterNotApplied };
}

interface HrcHexagonsProps {
    hrcData: HrcH3[];
    availableResolutions: number[];
    bounds: LatLngBounds;
    mode: 'individual' | 'grouped';
    threshold: number;
}

export function HrcHexagons({
    hrcData,
    availableResolutions,
    bounds,
    mode,
    threshold,
}: HrcHexagonsProps) {
    const {
        allH3IndexesForBounds,
        bestResolution,
        filterNotApplied,
    } = smartHexagonFilter(bounds, availableResolutions, threshold);

    // This will change with new DP data
    const severities: any = {
        '1': 'Lowest',
        '2': 'Low',
        '3': 'Medium',
        '4': 'High',
        '5': 'Highest',
    };

    return (
        <>
            {hrcData.map((data) => {
                const h3Set = data.h3Set.filter(
                    (set) => set.resolution === bestResolution,
                )[0];

                // No data - display nothing
                if (!h3Set) {
                    return null;
                }

                // Peformance optimisation, don't do any fancy filtering
                if (filterNotApplied) {
                    return (
                        <Hexagons
                            h3Indexes={h3Set.h3Indexes}
                            mode={mode}
                            className={`Hexagon__HRC--${
                                severities[data.hrcValue]
                            }`}
                        />
                    );
                }
                // Return our smartly filtered hexagons
                return (
                    <FilterableHexagons
                        allH3IndexesForBounds={allH3IndexesForBounds}
                        h3Indexes={h3Set.h3Indexes}
                        mode={mode}
                        className={`Hexagon__HRC--${severities[data.hrcValue]}`}
                    />
                );
            })}
        </>
    );
}
