import React from 'react';

type GroupPureProps = {
    label: string;
    children: React.ReactNode;
};

export const GroupPure = ({ label, children }: GroupPureProps) => {
    return (
        <div>
            <div className="Layer__Selector__Container__Title">{label}</div>
            <div className="Layer__Selector__Container__Scale">
                <ul className="Layer__Selector__Container__Labels">
                    {children}
                </ul>
            </div>
        </div>
    );
};
