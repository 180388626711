import * as displayName from './displayName';
import {
    operatorNamesToUniqueId,
    operatorNameToUniqueId,
} from './operatorNameToUniqueId';

export const operator = {
    displayName,
    operatorNamesToUniqueId,
    operatorNameToUniqueId,
};
