import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const NumberLabel = ({ nextLabel, currLabel }) => {
    if (!nextLabel) {
        return `${currLabel.text}+`;
    }
    return `${currLabel.text} - ${parseInt(nextLabel.text, 10) - 1}`;
};

NumberLabel.propTypes = {
    currLabel: PropTypes.shape({ text: PropTypes.number }),
    nextLabel: PropTypes.shape({ text: PropTypes.number }),
};

const LegendShape = ({
    shape = 'rect',
    width = 25,
    height = 25,
    rx = 4,
    index,
    ...props
}) => {
    if (typeof shape === 'string') {
        let innerShape = null;
        switch (shape) {
            case 'triangle': {
                // prettier-ignore
                const d = [
                    'M', width / 2, 0,
                    'L', width, height,
                    'L', 0, height,
                    'L', width / 2, 0,
                    'z',
                ].join(' ');
                innerShape = <path d={d} {...props} />;
                break;
            }
            case 'rect':
            default: {
                innerShape = (
                    <rect width={width} height={height} rx={rx} {...props} />
                );
            }
        }
        return (
            <svg
                /*  geometry is set here to support IE/Firefox */
                width={width}
                height={height}
                className={`Legend__Item--${index}`}
            >
                {innerShape}
            </svg>
        );
    }
    const forwardedProps = { width, height, rx, ...props };
    if (React.isValidElement(shape)) {
        return React.cloneElement(shape, forwardedProps);
    }
    if (typeof shape === 'function') {
        return shape(forwardedProps);
    }
    return null;
};

LegendShape.propTypes = {
    shape: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.node,
        PropTypes.string,
    ]),
    index: PropTypes.number.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    rx: PropTypes.number,
};

/*
 * This legend is to be used alongside league tables / charts or anything
 * else that needs some sort of colour key explanation.
 *
 * There are 2 ways to style the items:
 * 1. This is the preferred method
 * The actual colours have to be set in LESS and must be done each use. For example:
 *   .Legend {
 *        &__Item--0 {
 *            fill: red;
 *        }
 *        &__Item--1 {
 *            fill: yellow;
 *        }
 *        &__Item--2 {
 *            fill: green;
 *        }
 *   }
 *
 * 2. Add shapeProps to the rows. This is useful when your working with a scale and the colours may be dynamic
 const rows = [
    {
        ....otherStuff...
        shapeProps: {
            fill: 'purple'
        }
    }
 ]
 *
 */
const Legend = ({
    className,
    rows,
    direction,
    itemDirection,
    title,
    justifyTitle,
}) => {
    return (
        <div
            className={cn(
                'Legend',
                {
                    'Legend--Direction--Row': direction === 'row',
                    'Legend--Direction--RowReverse':
                        direction === 'row-reverse',
                    'Legend--Direction--Column': direction === 'column',
                    'Legend--Direction--ColumnReverse':
                        direction === 'column-reverse',
                },
                className,
            )}
        >
            {title && (
                <div
                    className={cn('Legend__Title', {
                        'Legend__Title--FlexStart':
                            justifyTitle === 'flex-start',
                        'Legend__Title--FlexEnd': justifyTitle === 'flex-end',
                        'Legend__Title--Center': justifyTitle === 'center',
                        'Legend__Title--ItemDirection--Row':
                            itemDirection === 'row',
                        'Legend__Title--ItemDirection--RowReverse':
                            itemDirection === 'row-reverse',
                        'Legend__Title--ItemDirection--Column':
                            itemDirection === 'column',
                        'Legend__Title--ItemDirection--ColumnReverse':
                            itemDirection === 'column-reverse',
                        'Legend__Title--Direction--Row': direction === 'row',
                        'Legend__Title--Direction--RowReverse':
                            direction === 'row-reverse',
                        'Legend__Title--Direction--Column':
                            direction === 'column',
                        'Legend__Title--Direction--ColumnReverse':
                            direction === 'column-reverse',
                    })}
                >
                    {title}
                </div>
            )}
            {rows.map((row, index) => (
                <div
                    key={index}
                    className={cn(
                        'Legend__Row',
                        `Legend__Row--${index}`,
                        {
                            'Legend__Row--ItemDirection--Row':
                                itemDirection === 'row',
                            'Legend__Row--ItemDirection--RowReverse':
                                itemDirection === 'row-reverse',
                            'Legend__Row--ItemDirection--Column':
                                itemDirection === 'column',
                            'Legend__Row--ItemDirection--ColumnReverse':
                                itemDirection === 'column-reverse',
                        },
                        row.className,
                    )}
                >
                    <LegendShape index={index} {...row.shapeProps} />
                    <div
                        className={cn('Legend__Label', {
                            'Legend__Label--ItemDirection--Row':
                                itemDirection === 'row',
                            'Legend__Label--ItemDirection--RowReverse':
                                itemDirection === 'row-reverse',
                            'Legend__Label--ItemDirection--Column':
                                itemDirection === 'column',
                            'Legend__Label--ItemDirection--ColumnReverse':
                                itemDirection === 'column-reverse',
                        })}
                    >
                        {row.text}
                    </div>
                </div>
            ))}
        </div>
    );
};

Legend.defaultProps = {
    direction: 'row',
    itemDirection: 'row',
    justifyTitle: 'flex-start',
};

Legend.propTypes = {
    // Optional className
    className: PropTypes.string,
    // The items to display
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            shapeProps: PropTypes.shape({
                width: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string,
                ]),
                height: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string,
                ]),
                rx: PropTypes.number,
                shape: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
            }),
            className: PropTypes.string,
            text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
        }),
    ),
    // The primary direction of the legend, accepts flex-box type strings
    direction: PropTypes.string.isRequired,
    // The direction of each row of the legend, accepts flex-box type strings
    itemDirection: PropTypes.string.isRequired,
    // The legend title
    title: PropTypes.string,
    // The alignment of the title, accepts flex-box type strings
    // NOTE: only works when direction is 'column' or 'column-reverse'
    justifyTitle: PropTypes.string,
};

Legend.NumberLabel = NumberLabel;

export default Legend;
