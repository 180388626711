import createActions from '../../../Modules/mapLabels/actions';
import createReducer from '../../../Modules/mapLabels/reducer';
import createSelectors from '../../../Modules/mapLabels/selectors';
import namespace from './namespace';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].mapLabels;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);

export const reducer = createReducer({
    onSuccess: actions.FETCH_MAP_LABELS_SUCCESS,
    onError: actions.FETCH_MAP_LABELS_ERROR,
});

export const selectors = createSelectors(statePath);
