import React from 'react';
import { LayersControl, TileLayer } from 'react-leaflet';
import PropTypes from 'prop-types';
import shouldComponentUpdateUtil from './utils/shouldComponentUpdate';

class Background extends React.Component {
    shouldComponentUpdate(nextProps) {
        return shouldComponentUpdateUtil(this.props, nextProps);
    }

    render() {
        const {
            name,
            checked,
            shouldComponentUpdate,
            url,
            options,
            ...leafletInjectedProps
        } = this.props;

        return (
            <LayersControl.Overlay
                {...leafletInjectedProps}
                checked={checked}
                name={name}
            >
                <TileLayer url={url} {...options} />
            </LayersControl.Overlay>
        );
    }
}

Background.propTypes = {
    options: PropTypes.shape({
        // The auth required for the tiling provider
        accessToken: PropTypes.string.isRequired,
    }).isRequired,
    // The URL to fetch, all provided options must be represented in the URL string matching the placeholder name
    url: PropTypes.string.isRequired,
    // The text that will be displayed in the layer control
    name: PropTypes.string.isRequired,
    // The initial state of if to display the layer
    checked: PropTypes.bool.isRequired,
    // Controls if the component should update
    shouldComponentUpdate: PropTypes.func,
};

export default Background;
