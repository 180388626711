import React from 'react';
import shiftUtils from '@rs/core/utils/shiftUtils';
import DatepickerWithInput from './DatepickerWithInput';

const DatepickerInputWithSiteConfig = (props: any) => {
    const earliestShiftPattern = shiftUtils.getEarliestShiftPattern();
    return (
        <DatepickerWithInput
            earliestDay={earliestShiftPattern.startDateItem._date}
            {...props}
        />
    );
};

export default DatepickerInputWithSiteConfig;
