import createActions from '../../Modules/worstCorners/actions';
import createReducer from '../../Modules/worstCorners/reducer';
import createSelectors from '../../Modules/worstCorners/selectors';
import namespace from './namespace';
import { LOAD_RESULTS_SUCCESS } from '../Actions';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].topClusters;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);
export const reducer = createReducer(
    {
        onSuccess: actions.FETCH_DRIVERS_SUCCESS,
        onError: actions.FETCH_DRIVERS_ERROR,
    },
    {
        [LOAD_RESULTS_SUCCESS]: (state, { topClusters }) => {
            const orderedTopClusters = topClusters.sort(
                (a, b) => a.Rank - b.Rank,
            );
            const maxResults = 5;
            const topClusterResults = orderedTopClusters.slice(0, maxResults);
            return topClusterResults;
        },
    },
);
export const selectors = createSelectors(statePath);
