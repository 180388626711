import { createReducer } from '../../Lib/reduxUtils';
import { SUBMIT_CREATE_GROUP, CREATE_GROUP_ERROR } from '../Actions';

const createGroupFormInitialState = {
    name: '',
    description: '',
};

export const initialState = {
    apiErrorMessage: '',
    createGroupForm: createGroupFormInitialState,
};

// //////////////////////////////////////////////////////////////////////
//  SELECTORS
// //////////////////////////////////////////////////////////////////////

const getCreateGroupForm = (state) => state.reducer.createGroupForm;
const getApiErrorMessage = (state) => state.reducer.apiErrorMessage;

export const sliceSelectors = {
    getCreateGroupForm,
    getApiErrorMessage,
};

// //////////////////////////////////////////////////////////////////////
//  REDUCERS
// //////////////////////////////////////////////////////////////////////

export const createGroupError = (state, apiErrorMessage) => ({
    ...state,
    apiErrorMessage,
});

export const reducer = createReducer(initialState, {
    [CREATE_GROUP_ERROR]: createGroupError,
    [SUBMIT_CREATE_GROUP]: (state) => ({
        ...state,
        createGroupForm: {
            ...state.createGroupForm,
        },
    }),
});
