import { action } from '../../Lib/reduxUtils';
import { GoogleAnalyticsEventBuilder } from '../../Utils/userAnalytics/googleAnalyticsEventBuilder';

const NAMESPACE = 'HaulTruckFillFactor';

export const FILTER_UPDATED = `${NAMESPACE}/FILTER_UPDATED`;
export const filterUpdated = (filterName, filterValue) =>
    action(
        FILTER_UPDATED,
        { filterName, filterValue },
        {
            analytics: new GoogleAnalyticsEventBuilder(
                'SubFeatureUsage',
                'Filter Updated',
            )
                .setLabel(NAMESPACE)
                .build(),
        },
    );

export const UPDATE_FILTERS_WITH_URL_PARAMS = `${NAMESPACE}/UPDATE_FILTERS_WITH_URL_PARAMS`;
export const updateFiltersWithUrlParams = () =>
    action(UPDATE_FILTERS_WITH_URL_PARAMS);

export const SET_FILTERS_WITH_URL_PARAMS = `${NAMESPACE}/SET_FILTERS_WITH_URL_PARAMS`;
export const setFiltersWithUrlParams = (filtersFromUrl) =>
    action(SET_FILTERS_WITH_URL_PARAMS, filtersFromUrl);

export const SET_SPINNER_STATE = `${NAMESPACE}/SET_SPINNER_STATE`;
export const setSpinnerState = (isActive = false, message = '', faIcon) =>
    action(SET_SPINNER_STATE, {
        isActive,
        message,
        faIcon,
    });

export const FETCH_HAUL_TRUCK_FILL_FACTOR_SUCCESS = `${NAMESPACE}/FETCH_HAUL_TRUCK_FILL_FACTOR_SUCCESS`;
export const fetchHaulTruckFillFactorSuccess = (resultsArray) =>
    action(FETCH_HAUL_TRUCK_FILL_FACTOR_SUCCESS, resultsArray);

export const FETCH_HAUL_TRUCK_FILL_FACTOR_ERROR = `${NAMESPACE}/FETCH_HAUL_TRUCK_FILL_FACTOR_ERROR`;
export const fetchHaulTruckFillFactorError = ({ error }) =>
    action(FETCH_HAUL_TRUCK_FILL_FACTOR_ERROR, { error });
