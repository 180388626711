import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DownIcon from '@ant-design/icons/DownOutlined';
import UpIcon from '@ant-design/icons/UpOutlined';

class TableChevron extends Component {
    getChevronDirection() {
        return this.props.predicate ? 'up' : 'down';
    }

    shouldChevronBeDisplayed() {
        return this.props.currentOrder === this.props.match;
    }

    render() {
        if (!this.shouldChevronBeDisplayed()) return null;
        const direction = this.getChevronDirection();
        return direction === 'up' ? <UpIcon /> : <DownIcon />;
    }
}

/**
 * @param match - The key to compare to currentOrder. If they are the same key chevron will display.
 * @param predicate - The direction the list is sorted by A-Z=false or Z-A=true. Controls which direction chevron is pointing.
 * @param currentOrder - The key the list is currently sorted by.
 */
TableChevron.propTypes = {
    match: PropTypes.string.isRequired,
    predicate: PropTypes.bool.isRequired,
    currentOrder: PropTypes.string.isRequired,
};

export default TableChevron;
