import { action } from '../../Lib/reduxUtils';
import {
    DateTimeChangedPayload,
    ModeChangedPayload,
    SyncUrlToStatePayload,
} from './types';
import { updateExistingURLQueryString } from '../../Lib/queryStringUtils';

export default function createActions(ns: string) {
    const SYNC_URL_TO_STATE = `${ns}/SYNC_URL_TO_STATE`;
    const syncURLToState = (payload: SyncUrlToStatePayload) => {
        return action(SYNC_URL_TO_STATE, payload);
    };

    const DATE_TIME_CHANGED = `${ns}/DATE_TIME_CHANGED`;
    const dateTimeChanged = (payload: DateTimeChangedPayload) => {
        const startTime = payload.startTime;
        const endTime = payload.endTime
            ? payload.endTime
            : payload.startTime + 24 * 60 * 60;
        const newParams = { startTime, endTime };
        updateExistingURLQueryString(newParams, {}, 'replace');
        return action(DATE_TIME_CHANGED, payload);
    };

    const MODE_CHANGED = `${ns}/MODE_CHANGED`;
    const modeChanged = (payload: ModeChangedPayload) => {
        const mode = payload;
        updateExistingURLQueryString({ mode }, {}, 'replace');
        return action(DATE_TIME_CHANGED, payload);
    };

    return {
        DATE_TIME_CHANGED,
        dateTimeChanged,
        SYNC_URL_TO_STATE,
        syncURLToState,
        MODE_CHANGED,
        modeChanged,
    };
}
