/**
 * This helper function is used to determine whether a space should be applied
 * to a given unit
 *
 * @returns {String}
 */
export const getUnitsString = (axisUnits) => {
    // If null / undefined / empty string - just return blank
    if (!axisUnits) {
        return '';
    }

    switch (axisUnits) {
        case '%':
            // No space
            return '%';
        case '°':
            // No space
            return '°';
        default:
            // Default is a space
            return ` ${axisUnits}`;
    }
};
