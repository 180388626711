import combineReducers from 'combine-reducers-global-state';
import namespace from '../Modules/namespace';
import { reducer as spinner } from '../Modules/spinner';
import { reducer as meta } from '../Modules/meta';
import { reducer as powerBiReports } from '../Modules/powerBiReports';

export const REDUCER_KEY = namespace;

export default combineReducers({
    spinner,
    meta,
    powerBiReports,
});
