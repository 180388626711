class QueryString {
    constructor() {
        this.history = null;
    }

    /**
     * Sets up the QueryString service with history
     * @param history - The history object from the 'history' package.. not HTML5 history
     */
    init(history) {
        this.history = history;
    }

    _isInitialised() {
        if (this.history === null) {
            throw new Error(
                'QueryString must be initialised with the history module first, QueryString.init(history)',
            );
        }
    }

    /**
     * Gets the query string and returns it as an object
     * @returns {*}
     */
    convertURLQueryStringToObject() {
        this._isInitialised();
        const parts = this.history.location.search.split('?');
        if (parts.length < 2) {
            return {};
        }
        const queryString = parts[1];
        return this.decodeQueryString(queryString);
    }

    /**
     * Decodes a queryString into an object
     * @param {string} - queryString
     * @returns {object}
     */
    decodeQueryString(queryString) {
        if (!queryString) return {};
        const decoded = decodeURIComponent(queryString);
        const parts = decoded.split('&');
        const params = parts.reduce((collection, b) => {
            const [key, value] = b.split('=');
            const typedValue = this.convertStringToType(value);
            if (collection[key]) {
                collection[key] += `|${typedValue}`;
            } else {
                collection[key] = typedValue;
            }
            return collection;
        }, {});
        return params;
    }

    /**
     * Convert a string value into the correct type
     * @param value
     * @returns {*}
     */
    convertStringToType(value) {
        if (value === 'undefined') return undefined;
        if (value === 'null') return null;
        if (value === 'true') return true;
        if (value === 'false') return false;
        if (value === '') return '';
        const v = Number(value);
        return isNaN(v) ? value : v;
    }

    /**
     * Encodes an object into a serialized string.
     * It filters out any nullish value
     *
     *
     * @example const qs = queryString.getQueryString({
     *                         ShiftId: 'a_shift_id',
     *                         WOMID: 'A_ID',
     *                     });
     *                    -> 'shiftId=a_shift_id&WOMIT=A_ID'
     *
     * @example const qs = queryString.getQueryString({
     *                         ShiftId: 'a_shift_id',
     *                         WOMID: undefined,
     *                     });
     *                    -> 'shiftId=a_shift_id'
     *
     * @param params
     * @returns {string} The query string with all values
     */
    getQueryString(params) {
        if (!params || !Object.keys(params).length) return '';
        const esc = encodeURIComponent;
        const groupedByKey = Object.keys(params).reduce((collection, key) => {
            const value = params[key];
            if (collection[key]) {
                collection[key] += `|${value}`;
            } else {
                collection[key] = value;
            }
            return collection;
        }, {});
        return Object.keys(groupedByKey)
            .filter((key) => groupedByKey[key] != null)
            .map((key) => `${esc(key)}=${esc(groupedByKey[key])}`)
            .join('&');
    }

    /**
     * Updates the URL using history
     * @param {object} params the values to use as the search string
     * @param {object} overridingOptions override any of the history options
     * @param {string} pushOrReplace which method history should use to update the history stack
     */
    updateURLQueryString(
        params,
        overridingOptions = {},
        pushOrReplace = 'replace',
    ) {
        this._isInitialised();
        const qs = this.getQueryString(params);
        this.history[pushOrReplace]({
            pathname: this.history.location.pathname,
            search: `?${qs}`,
            ...overridingOptions,
        });
    }
}

export default new QueryString();
