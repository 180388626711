import { FEATURE_IDS, FEATURE_CATEGORY_IDS } from './featureIds';

export const FEATURE_META = {
    [FEATURE_IDS.TAB_ID__PRODUCTION_DASHBOARD]: {
        path: '/production-dashboard',
        label: 'Production Dashboard',
        featureId: FEATURE_IDS.TAB_ID__PRODUCTION_DASHBOARD,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__RESULTS_DELAY]: {
        path: '/results-delay',
        label: 'Results Delay',
        featureId: FEATURE_IDS.TAB_ID__RESULTS_DELAY,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__LEAGUE_TABLE]: {
        path: '/league-table',
        label: 'Haul Truck League',
        featureId: FEATURE_IDS.TAB_ID__LEAGUE_TABLE,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__TYRES_AND_SAFETY]: {
        path: '/reports',
        label: 'Tyres And Safety',
        featureId: FEATURE_IDS.TAB_ID__TYRES_AND_SAFETY,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__OVERALL_PRODUCTIVITY_EVENTS]: {
        path: '/overallProductivity',
        label: 'Operator Efficiency',
        featureId: FEATURE_IDS.TAB_ID__OVERALL_PRODUCTIVITY_EVENTS,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__OPERATOR_FEEDBACK]: {
        path: '/operator-feedback',
        label: 'Operator Feedback',
        featureId: FEATURE_IDS.TAB_ID__OPERATOR_FEEDBACK,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__O4R_OPERATOR]: {
        path: '/o4r-operator',
        label: 'O4R Operator',
        featureId: FEATURE_IDS.TAB_ID__O4R_OPERATOR,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__LOAD_UNIT_OPERATOR_LEAGUE_TABLE]: {
        path: '/load-unit-operator-league-table',
        label: 'Loader League',
        featureId: FEATURE_IDS.TAB_ID__LOAD_UNIT_OPERATOR_LEAGUE_TABLE,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__LOADS_IN_RANGE_DETAIL]: {
        path: '/loads-in-range-detail',
        label: 'Loads In Range Detail',
        featureId: FEATURE_IDS.TAB_ID__LOADS_IN_RANGE_DETAIL,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__LOAD_UNIT_TIME_DETAIL]: {
        path: '/load-unit-time-detail',
        label: 'Loading Time Detail',
        featureId: FEATURE_IDS.TAB_ID__LOAD_UNIT_TIME_DETAIL,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__BIAS_LOAD_DETAIL]: {
        path: '/bias-load-detail',
        label: 'Bias Loads Detail',
        featureId: FEATURE_IDS.TAB_ID__BIAS_LOAD_DETAIL,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__HAUL_TRUCK_FILL_FACTOR]: {
        path: '/haul-truck-fill-factor',
        label: 'Fill Factor',
        featureId: FEATURE_IDS.TAB_ID__HAUL_TRUCK_FILL_FACTOR,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__HAUL_ROAD_CONDITION]: {
        path: '/haul-road-condition',
        label: 'Haul Road Condition',
        featureId: FEATURE_IDS.TAB_ID__HAUL_ROAD_CONDITION,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__MAXMINE_DIAGNOSTICS]: {
        path: '/maxmine-diagnostics',
        label: 'Diagnostics',
        featureId: FEATURE_IDS.TAB_ID__MAXMINE_DIAGNOSTICS,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__TRUCK_PERFORMANCE]: {
        path: '/engine-performance',
        label: 'Engine Performance',
        featureId: FEATURE_IDS.TAB_ID__TRUCK_PERFORMANCE,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__TRUCK_DIAGNOSTICS]: {
        path: '/engine-diagnostics',
        label: 'Engine Diagnostics',
        featureId: FEATURE_IDS.TAB_ID__TRUCK_DIAGNOSTICS,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__TRUCK_ALERTS_ANALYSIS]: {
        path: '/truck-alerts-analysis',
        label: 'Truck Alerts Analysis',
        featureId: FEATURE_IDS.TAB_ID__TRUCK_ALERTS_ANALYSIS,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },

    [FEATURE_IDS.TAB_ID__SELF_SERVICE]: {
        path: '/self-service',
        label: 'Spreadsheets',
        featureId: FEATURE_IDS.TAB_ID__SELF_SERVICE,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__ENGINEERING]: {
        path: '/engineering',
        label: 'Engineering',
        featureId: FEATURE_IDS.TAB_ID__ENGINEERING,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__CRIB_WALLBOARD]: {
        path: '/react-wallboard',
        label: 'Wallboard',
        featureId: FEATURE_IDS.TAB_ID__CRIB_WALLBOARD,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__LOGIN]: {
        path: '/login',
        featureId: FEATURE_IDS.TAB_ID__LOGIN,
    },
    [FEATURE_IDS.TAB_ID__APP_LANDING]: {
        path: '/landing',
        featureId: FEATURE_IDS.TAB_ID__APP_LANDING,
    },
    [FEATURE_IDS.TAB_ID__DATA_HEALTH]: {
        path: '/data-health',
        label: 'Data Health',
        featureId: FEATURE_IDS.TAB_ID__DATA_HEALTH,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__SITE_GEOFENCES_AND_RULES]: {
        path: '/site-geofences-and-rules',
        label: 'Site Geofences and Rules',
        featureId: FEATURE_IDS.TAB_ID__SITE_GEOFENCES_AND_RULES,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__MAX_HUB_LIVE_MAP]: {
        path: '/max-hub/live-map',
        label: 'Live Map',
        featureId: FEATURE_IDS.TAB_ID__MAX_HUB_LIVE_MAP,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__CONFIGURATION_TOOL]: {
        path: '/max-hub/configuration',
        label: 'Configuration',
        featureId: FEATURE_IDS.TAB_ID__CONFIGURATION_TOOL,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__HARDWARE_MONITORING]: {
        path: '/max-hub/hardware-monitoring',
        label: 'Hardware Monitoring',
        featureId: FEATURE_IDS.TAB_ID__HARDWARE_MONITORING,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__CREATE_USER]: {
        path: '/create-user',
        label: 'Create User',
        featureId: FEATURE_IDS.TAB_ID__CREATE_USER,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__EDIT_DELETE_USER]: {
        path: '/edit-user',
        label: 'Edit User',
        featureId: FEATURE_IDS.TAB_ID__EDIT_DELETE_USER,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__MANAGE_USER]: {
        path: '/manage-user',
        label: 'Manage Users',
        featureId: FEATURE_IDS.TAB_ID__MANAGE_USER,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__DELETE_USER]: {
        path: '/delete-user',
        label: 'Delete User',
        featureId: FEATURE_IDS.TAB_ID__DELETE_USER,
    },
    [FEATURE_IDS.TAB_ID__CREATE_GROUP]: {
        path: '/create-group',
        label: 'Create Group',
        featureId: FEATURE_IDS.TAB_ID__CREATE_GROUP,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__EDIT_GROUP]: {
        path: '/edit-group',
        label: 'Edit Group',
        featureId: FEATURE_IDS.TAB_ID__EDIT_GROUP,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__MANAGE_GROUP]: {
        path: '/manage-group',
        label: 'Manage Groups',
        featureId: FEATURE_IDS.TAB_ID__MANAGE_GROUP,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__ASSIGN_GROUP]: {
        path: '/assign-group',
        label: 'Assign Groups',
        featureId: FEATURE_IDS.TAB_ID__ASSIGN_GROUP,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__TRUCK_ALLOCATION]: {
        path: '/:womid/control-room',
        label: 'Control Room',
        featureId: FEATURE_IDS.TAB_ID__TRUCK_ALLOCATION,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    // this page was deprecated on 1/6/2023 it should be redirected to the new live map page
    [FEATURE_IDS.TAB_ID__LIVE_MAP]: {
        path: '/:womid/live-map',
        label: 'Live Map',
        featureId: FEATURE_IDS.TAB_ID__LIVE_MAP,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__EDIT_CIRCUITS]: {
        path: '/:womid/edit-circuits',
        label: 'Edit Circuits',
        featureId: FEATURE_IDS.TAB_ID__EDIT_CIRCUITS,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__EDIT_HOLDING_STATES]: {
        path: '/:womid/edit-holding-states',
        label: 'Edit Holding States',
        featureId: FEATURE_IDS.TAB_ID__EDIT_HOLDING_STATES,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__DAS]: {
        path: '/shift-replay-tool',
        label: 'Shift Replay Tool',
        featureId: FEATURE_IDS.TAB_ID__DAS,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__NETWORK_COVERAGE]: {
        path: '/network-coverage-heatmap',
        label: 'Network Coverage Heatmap',
        featureId: FEATURE_IDS.TAB_ID__NETWORK_COVERAGE,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__POWER_BI_REPORT_1]: {
        path: '/custom-report/1',
        label: 'Custom Report 1',
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_1,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__POWER_BI_REPORT_2]: {
        path: '/custom-report/2',
        label: 'Custom Report 2',
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_2,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__POWER_BI_REPORT_3]: {
        path: '/custom-report/3',
        label: 'Custom Report 3',
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_3,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__POWER_BI_REPORT_4]: {
        path: '/custom-report/4',
        label: 'Custom Report 4',
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_4,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__POWER_BI_REPORT_5]: {
        path: '/custom-report/5',
        label: 'Custom Report 5',
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_5,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__POWER_BI_REPORT_6]: {
        path: '/custom-report/6',
        label: 'Custom Report 6',
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_6,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__POWER_BI_REPORT_7]: {
        path: '/custom-report/7',
        label: 'Custom Report 7',
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_7,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__POWER_BI_REPORT_8]: {
        path: '/custom-report/8',
        label: 'Custom Report 8',
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_8,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__POWER_BI_REPORT_9]: {
        path: '/custom-report/9',
        label: 'Custom Report 9',
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_9,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__POWER_BI_REPORT_10]: {
        path: '/custom-report/10',
        label: 'Custom Report 10',
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_10,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__POWER_BI_REPORT_11]: {
        path: '/custom-report/11',
        label: 'Custom Report 11',
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_11,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__POWER_BI_REPORT_12]: {
        path: '/custom-report/12',
        label: 'Custom Report 12',
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_12,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__POWER_BI_REPORT_13]: {
        path: '/custom-report/13',
        label: 'Custom Report 13',
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_13,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__POWER_BI_REPORT_14]: {
        path: '/custom-report/14',
        label: 'Custom Report 14',
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_14,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__POWER_BI_REPORT_15]: {
        path: '/custom-report/15',
        label: 'Custom Report 15',
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_15,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__POWER_BI_REPORT_16]: {
        path: '/custom-report/16',
        label: 'Custom Report 16',
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_16,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__POWER_BI_REPORT_17]: {
        path: '/custom-report/17',
        label: 'Custom Report 17',
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_17,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__POWER_BI_REPORT_18]: {
        path: '/custom-report/18',
        label: 'Custom Report 18',
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_18,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__POWER_BI_REPORT_19]: {
        path: '/custom-report/19',
        label: 'Custom Report 19',
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_19,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__POWER_BI_REPORT_20]: {
        path: '/custom-report/20',
        label: 'Custom Report 20',
        featureId: FEATURE_IDS.TAB_ID__POWER_BI_REPORT_20,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.POWER_BI_REPORTS__ADMIN]: {
        path: '/edit-power-bi-report',
        label: 'Custom Report',
        featureId: FEATURE_IDS.POWER_BI_REPORTS__ADMIN,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__LOADER_REPORTS]: {},
    [FEATURE_IDS.TAB_ID__BIAS_LOAD]: {},
    [FEATURE_IDS.TAB_ID__PULSE_TARGETS_UPLOAD]: {
        path: '/pulse-targets-upload',
        label: 'Pulse Targets Upload',
        featureId: FEATURE_IDS.TAB_ID__PULSE_TARGETS_UPLOAD,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__TIME_ACCOUNTING]: {
        path: '/short-interval-control-time-accounting',
        label: 'Time Accounting',
        featureId: FEATURE_IDS.TAB_ID__TIME_ACCOUNTING,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__CIRCUIT_PULSE]: {
        path: '/short-interval-control-pulse',
        label: 'Circuit Pulse',
        featureId: FEATURE_IDS.TAB_ID__CIRCUIT_PULSE,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__FLEET_PULSE]: {
        path: '/short-interval-control-fleet-pulse',
        label: 'Fleet Pulse',
        featureId: FEATURE_IDS.TAB_ID__FLEET_PULSE,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TAB_ID__FLEET_PLAN_READ_ONLY]: {
        path: '/short-interval-control-fleet-plan',
        label: 'Fleet Targets',
        featureId: FEATURE_IDS.TAB_ID__FLEET_PLAN_READ_ONLY,
        categoryId: FEATURE_CATEGORY_IDS.NAVBAR,
    },
    [FEATURE_IDS.TYRES_AND_SAFETY__SHOW_PRINT_PDF_BUTTON]: {},
    [FEATURE_IDS.LEAGUE__TABLE_SHOW_PRODUCTIVITY_COLUMN]: {},
    [FEATURE_IDS.LEAGUE__TABLE_SHOW_PRODUCTIVITY_LINK]: {},
    [FEATURE_IDS.SELF_SERVICE__CSV_EXPORT_BUTTON_CYCLESUMMARIES]: {},
    [FEATURE_IDS.SELF_SERVICE__CSV_EXPORT_BUTTON_TIMING_SUMMARY]: {},
    [FEATURE_IDS.SELF_SERVICE__CSV_EXPORT_BUTTON_TRUCK_ALERT_EVENTS]: {},
    [FEATURE_IDS.HAUL_ROAD_CONDITION__FILTER_WHERE_ON_MINESITE_ID]: {},
    [FEATURE_IDS.HAUL_ROAD_CONDITION__MAP_FEATURES]: {},
    [FEATURE_IDS.LOADS_IN_RANGE__SHOW_LOAD_UNIT_BAND_CONTAINER]: {},
    [FEATURE_IDS.LOADS_UNIT_DETAIL__FILTER_CREW]: {},
    [FEATURE_IDS.LOADER_LEAGUE__TABLE_LINK__LOAD_UNIT_TIME_DETAIL]: {},
    [FEATURE_IDS.LOADER_LEAGUE__TABLE__TIME_SCORE_COLUMN]: {},
    [FEATURE_IDS.LEAGUE__TABLE_SHOW_DRIVING_DURATION_COLUMN]: {},
    [FEATURE_IDS.DEV_FEATURE_1]: {},
    [FEATURE_IDS.DEV_FEATURE_2]: {},
    [FEATURE_IDS.DEV_FEATURE_3]: {},
};
