import shiftUtils from '@rs/core/utils/shiftUtils';
import { createSelector } from 'reselect';
import createActions from '../../../Modules/filters/actions';
import createReducer from '../../../Modules/filters/reducer';
import createSelectors from '../../../Modules/filters/selectors';
import namespace from './namespace';
import { FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS } from '../../../App/Actions';
import { filterShiftOptionsToReactSelectFormat } from '../../../Utils/Filters/filterOptionsToReactSelectFormat';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].filters;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);

const defaultReducer = createReducer(
    {
        filterUpdated: actions.FILTER_UPDATED,
        setFiltersWithUrlParams: actions.SET_FILTERS_WITH_URL_PARAMS,
    },
    {},
    {
        Date: shiftUtils.newDefaultFilterDate(),
        Shift: '',
    },
);

function setInitialShift(state) {
    if (state.Shift) {
        return state;
    }
    const defaultDate = shiftUtils.newDefaultFilterDate();
    const firstShift = shiftUtils.getFirstShiftFromDate(defaultDate);
    return {
        ...state,
        Date: defaultDate,
        Shift: firstShift.shortName,
    };
}

export const reducer = (state, action) => {
    let nextState = state;
    if (action.type === FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS) {
        nextState = setInitialShift(state);
    }
    return defaultReducer(nextState, action);
};

const defaultSelectors = createSelectors(statePath);
const getFilterOptions = createSelector(
    defaultSelectors.getFilters,
    (filters) => {
        const shifts = shiftUtils.getAllShiftShortNamesForDate(filters.Date);

        return {
            shifts: filterShiftOptionsToReactSelectFormat(
                shifts.rawShiftsInDay,
                shifts.shiftShortNames,
            ),
        };
    },
);

export const selectors = {
    ...defaultSelectors,
    getFilterOptions,
};
