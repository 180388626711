import React, { ReactNode } from 'react';
import {
    PayloadGoodJobSymbol,
    PayloadIncreasePayloadsSymbol,
    PayloadDecreasePayloadsSymbol,
    PayloadImproveConsistencySymbol,
    PayloadBiasGoodJobSymbol,
    PayloadBiasImproveLongitudinalConsistencySymbol,
    PayloadBiasImproveLateralConsistencySymbol,
    PayloadBiasDirectionSymbol,
} from '../../components';

function arrowDirectionToFriendlyName(direction) {
    const map = {
        0: 'Right',
        1: 'Front-Right',
        2: 'Front',
        3: 'Front-Left',
        4: 'Left',
        5: 'Rear-Left',
        6: 'Rear',
        7: 'Rear-Right',
        8: 'Right',
    };
    return map[direction] || '';
}

interface IconProps {
    children?: ReactNode;
    tooltip?: string;
}

const PayloadIcon = ({ children, tooltip }: IconProps) => (
    <span
        data-rh={tooltip}
        className="LoadingEventSymbologySymbol__IconWrapper"
    >
        {children}
    </span>
);

const BiasLoadIcon = ({ children, tooltip }: IconProps) => (
    <span
        data-rh={tooltip}
        className="LoadingEventSymbologySymbol__IconWrapper"
    >
        {children}
    </span>
);

function RenderSymbol({ symbol, arrowDirection, size }) {
    const symbolMap = {
        payload_increase_payloads_symbol: (
            <PayloadIcon tooltip="Increase payload">
                <PayloadIncreasePayloadsSymbol size={size} />
            </PayloadIcon>
        ),
        payload_decrease_payloads_symbol: (
            <PayloadIcon tooltip="Decrease payload">
                <PayloadDecreasePayloadsSymbol size={size} />
            </PayloadIcon>
        ),
        payload_improve_consistency_symbol: (
            <PayloadIcon tooltip="Improve payload consistency">
                <PayloadImproveConsistencySymbol size={size} />
            </PayloadIcon>
        ),
        payload_good_job_symbol: (
            <PayloadIcon tooltip="Good job!">
                <PayloadGoodJobSymbol
                    className="LoadingEventSymbologySymbol__Icon--GoodJob"
                    size={size}
                />
            </PayloadIcon>
        ),
        payload_bias_good_job_symbol: (
            <BiasLoadIcon tooltip="Good job!">
                <PayloadBiasGoodJobSymbol
                    className="LoadingEventSymbologySymbol__Icon--GoodJob"
                    size={size}
                />
            </BiasLoadIcon>
        ),
        payload_bias_improve_longitudinal_consistency_symbol: (
            <BiasLoadIcon tooltip="Improve longitudinal payload accuracy">
                <PayloadBiasImproveLongitudinalConsistencySymbol size={size} />
            </BiasLoadIcon>
        ),
        payload_bias_improve_lateral_consistency_symbol: (
            <BiasLoadIcon tooltip="Improve lateral payload accuracy">
                <PayloadBiasImproveLateralConsistencySymbol
                    className="LoadingEventSymbologySymbol__PayloadBiasImproveLateralConsistencySymbolDirectionIcon"
                    size={size}
                />
            </BiasLoadIcon>
        ),
        payload_bias_direction_symbol: (
            <BiasLoadIcon
                tooltip={`Improve payload accuracy ${arrowDirectionToFriendlyName(
                    arrowDirection,
                )}`}
            >
                <PayloadBiasDirectionSymbol
                    arrowDirection={arrowDirection}
                    size={size}
                />
            </BiasLoadIcon>
        ),
    };
    const icon = symbolMap[symbol];
    if (!icon) {
        return '';
    }
    return icon;
}

interface LoadingEventSymbologySymbolProps {
    symbols: string[] | null;
    arrowDirection: number;
    size: string;
}

const LoadingEventSymbologySymbol = ({
    symbols = [],
    arrowDirection,
    size,
}: LoadingEventSymbologySymbolProps) => {
    if (!Array.isArray(symbols)) return null;
    const children = symbols.map((symbol, index) => (
        <span key={index}>
            <RenderSymbol
                symbol={symbol}
                arrowDirection={arrowDirection}
                size={size}
            />
        </span>
    ));
    // NOTE: this is wrapped in a fragment because it fixes these type errors
    // JSX element type 'Element[]' is not a constructor function for JSX elements.  TS2605
    // Type 'Element[]' is missing the following properties from type 'Element': type, props, key  TS2605
    return <React.Fragment>{children}</React.Fragment>;
};

LoadingEventSymbologySymbol.defaultProps = {
    size: 'fa-2x',
};

export default LoadingEventSymbologySymbol;
