import { createReducer } from '../../Lib/reduxUtils';
import {
    FETCH_SURFACE_ROUGHNESS_SUCCESS,
    FETCH_SURFACE_ROUGHNESS_ERROR,
} from '../Actions';

export const initialState = {
    byShiftId: {},
};

export const fetchSuccess = (state, surfaceRoughness) => {
    const byShiftId = surfaceRoughness.reduce(
        (collection, { response, shiftId }) => {
            collection[shiftId] = response;
            return collection;
        },
        {},
    );
    return {
        ...state,
        byShiftId,
    };
};
export const fetchError = () => initialState;

export default createReducer(initialState, {
    [FETCH_SURFACE_ROUGHNESS_SUCCESS]: fetchSuccess,
    [FETCH_SURFACE_ROUGHNESS_ERROR]: fetchError,
});
