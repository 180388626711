import { sumNumbers, roundTo } from '@rs/core/utils/mathHelpers';
import shiftUtils from '@rs/core/utils/shiftUtils';
import {
    objectToArray,
    filterUniqueItems,
    arrayToObject,
    arrayToGroupedObject,
} from '@rs/core/utils/dataUtils';
import { createReducer } from '../../Lib/reduxUtils';
import {
    SET_SPINNER_STATE,
    FILTER_UPDATED,
    LOAD_DRIVERS,
    LOAD_TYRES_AND_SAFETY_PRODUCTIVITY,
    SET_DRIVER_SHIFT_PRODUCTIVITY_JSONS,
    SET_FILTERS_WITH_URL_PARAMS,
    SET_ERROR_MESSAGE,
} from '../Actions';
import { FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS } from '../../App/Actions';
import {
    formatDriverEventJSON,
    calculateDriverEventJSONAverages,
    formatChartData,
} from '../driverEventJson';

export const initialState = {
    driversByUniqueDriverId: {},
    mapEvents: [],
    topClusters: [],
    wktData: {},
    mapLabels: [],
    speedLimitedZones: [],
    speedingEvents: [],
    conformanceSummaryBadEvents: {},
    cornerLeagueTotal: {},
    shiftProductivityEventsById: {},
    driverShiftProductivityEventsById: {},
    driverShiftProductivityEventsCombinedById: {},
    driverProductivityEventsRanking: [],
    driverEventJSON: {
        averagedResults: [],
        categoriesByXPos: {},
    },
    errorMessage: null,
    filters: {
        Date: undefined,
        Worker: '',
    },
    spinner: {
        isActive: false,
        message: '',
        faIcon: undefined,
    },
    mapFeatures: [],
    minesiteAreasTree: {},
};

const fetchPrivateSiteConfigurationSuccess = (state) => {
    const defaultDate = shiftUtils.newDefaultFilterDate();
    return {
        ...state,
        filters: {
            ...state.filters,
            Date: defaultDate,
        },
    };
};

const getFilters = (state) => state.filters;
const getAllDrivers = (state) => objectToArray(state.driversByUniqueDriverId);
const getFilterOptions = (state) => {
    const driverList = getAllDrivers(state);
    const drivers = driverList.map((d) => d.Worker).filter(filterUniqueItems);
    return {
        drivers,
    };
};
const getSpinnerState = (state) => state.spinner;
const getDefinedFilters = (state) => {
    const filters = sliceSelectors.getFilters(state);
    if (!filters.Date) {
        return {};
    }
    return {
        shiftIdsAllShifts: shiftUtils.getAllFirstLastFromArray(
            shiftUtils.generateShiftIdRange(filters.Date, filters.Date),
        ),
    };
};
const getWktData = (state) => state.wktData;
const getTyresAndSafetyData = (state) => ({
    mapEvents: state.mapEvents,
    topClusters: state.topClusters,
    mapLabels: state.mapLabels,
    speedLimitedZones: state.speedLimitedZones,
    speedingEvents: state.speedingEvents,
    rampEvents: state.rampEvents,
});

const getCornerLeagueTotal = (state) => state.cornerLeagueTotal;
const getDriverProductivityEvent = (state, eventId) =>
    state.driverProductivityEventsByEventId[eventId];
const getShiftProductivityEvent = (state, id) =>
    state.shiftProductivityEventsById[id];
const getShiftProductivityEventsById = (state, shiftProductivityEventId) =>
    state.shiftProductivityEventsById[shiftProductivityEventId];
const getDriverEventsByShiftProductivityEventId = (
    state,
    shiftProductivityEventId,
) => state.driverShiftProductivityEventsById[shiftProductivityEventId];
const getDriverEvents = (state) =>
    objectToArray(state.driverShiftProductivityEventsCombinedById);
const getTopEventsByLostTime = (state, numberOfEventsToReturn = 2) => {
    const worstEvents = state.driverProductivityEventsRanking.slice(
        0,
        numberOfEventsToReturn,
    );
    const driverEvents = getDriverEvents(state);
    if (!worstEvents) return [];
    const worstShiftProductivityEvents = worstEvents.map((id) => {
        const driverEvent = driverEvents.find(
            (d) => d.ShiftProductivityEventId === id,
        );
        const shiftProdEvent = getShiftProductivityEventsById(
            state,
            driverEvent.ShiftProductivityEventId,
        );
        if (shiftProdEvent) {
            driverEvent.WKTLoci = shiftProdEvent.WKTLoci;
            driverEvent.Heading = shiftProdEvent.Heading;
            driverEvent.HeadingArrowCenterX =
                shiftProdEvent.HeadingArrowCenterX;
            driverEvent.HeadingArrowCenterY =
                shiftProdEvent.HeadingArrowCenterY;
            driverEvent.LeaderLineX = shiftProdEvent.LeaderLineX;
            driverEvent.LeaderLineY = shiftProdEvent.LeaderLineY;
            driverEvent.GeoJSON = shiftProdEvent.GeoJSON;
        }
        return driverEvent;
    });

    return worstShiftProductivityEvents;
};

const getDriverEventJsons = (state) => formatChartData(state.driverEventJSON);
const getErrorMessage = (state) => state.errorMessage;
const getConformanceSummaryBadEvents = (state) =>
    state.conformanceSummaryBadEvents;
const getDriversShift = (state) => {
    const drivers = getAllDrivers(state);
    const firstShift = shiftUtils.getFirstShiftFromDate(state.filters.Date);
    const driverShift =
        drivers.length && drivers.length > 0
            ? drivers[0].Shift
            : firstShift.shortName;
    return driverShift;
};
const getMapFeatures = (state) => state.mapFeatures;
const getMinesiteAreasTree = (state) => state.minesiteAreasTree;

export const sliceSelectors = {
    getAllDrivers,
    getFilterOptions,
    getSpinnerState,
    getFilters,
    getDefinedFilters,
    getWktData,
    getTyresAndSafetyData,
    getCornerLeagueTotal,
    getDriverProductivityEvent,
    getTopEventsByLostTime,
    getShiftProductivityEvent,
    getDriverEventJsons,
    getErrorMessage,
    getConformanceSummaryBadEvents,
    getDriverEventsByShiftProductivityEventId,
    getDriversShift,
    getMapFeatures,
    getMinesiteAreasTree,
};

// TODO It probably would make more sense to have this as it's own top level reducer and share it between pages
export function setSpinnerState(state, spinner) {
    const { isActive, message, faIcon } = spinner;
    return {
        ...state,
        spinner: {
            isActive,
            message,
            faIcon,
        },
    };
}

export function filterUpdated(state, payload) {
    const { filterName, filterValue } = payload;
    const newState = {
        ...state,
        filters: {
            ...state.filters,
            [filterName]: filterValue,
        },
    };
    return newState;
}

export function setDrivers(state, driversArray) {
    const driversByUniqueDriverId = arrayToObject(
        driversArray,
        'UniqueDriverId',
    );
    return {
        ...state,
        driversByUniqueDriverId,
    };
}

export function loadTyresAndSafetyProductivity(
    state,
    {
        cornerLeague = {},
        driverProductivityEvents = [],
        mapLabels = [],
        rampEvents = [],
        mapEvents = [],
        speedingEvents = [],
        shiftProductivityEvents = [],
        speedLimitedZones = [],
        topClusters = [],
        wktData = {},
        mapFeatures,
        minesiteAreasTree,
    },
) {
    const orderedTopClusters = topClusters.sort((a, b) => a.Rank - b.Rank);
    const maxResults =
        orderedTopClusters.length > 2 ? 2 : orderedTopClusters.length;
    const topClusterResults = orderedTopClusters.slice(0, maxResults);
    const { Total } = cornerLeague;
    const shiftProductivityEventsById = arrayToObject(
        shiftProductivityEvents,
        'ShiftProductivityEventId',
    );

    const groupedDriverShiftProductivityEvents = arrayToGroupedObject(
        driverProductivityEvents,
        'ShiftProductivityEventId',
    );
    const combinedDriverShiftProductivityEvents = Object.keys(
        groupedDriverShiftProductivityEvents,
    ).reduce((collection, shiftProductivityEventId) => {
        const group =
            groupedDriverShiftProductivityEvents[shiftProductivityEventId];
        collection[shiftProductivityEventId] = {
            ...groupedDriverShiftProductivityEvents[
                shiftProductivityEventId
            ][0],
            TotalLostTime: roundTo(sumNumbers(group, 'TotalLostTime'), 0),
            PassCount: Math.ceil(sumNumbers(group, 'PassCount')),
        };
        return collection;
    }, {});

    const driverProductivityEventsRanking = objectToArray(
        combinedDriverShiftProductivityEvents,
    )
        .sort((a, b) => b.TotalLostTime - a.TotalLostTime)
        .map((event) => event.ShiftProductivityEventId);

    return {
        ...state,
        topClusters: topClusterResults,
        driverShiftProductivityEventsById: groupedDriverShiftProductivityEvents,
        driverShiftProductivityEventsCombinedById: combinedDriverShiftProductivityEvents,
        driverProductivityEventsRanking,
        shiftProductivityEventsById,
        conformanceSummaryBadEvents: {
            corner: mapEvents.length,
            zone: speedingEvents.length,
            ramp: rampEvents.length,
        },
        cornerLeagueTotal: Total,
        mapLabels,
        rampEvents,
        mapEvents,
        speedingEvents,
        wktData,
        speedLimitedZones,
        tyresAndSafety: cornerLeague.CornerLeague[0],
        mapFeatures: (mapFeatures && mapFeatures.features) || [],
        minesiteAreasTree,
    };
}

export function setDriverShiftProductivityJsons(state, driverEventJSONs) {
    const results = Object.keys(driverEventJSONs).reduce(
        (collection, shiftProductivityEventId) => {
            const averagedDriverEventJSON = calculateDriverEventJSONAverages(
                driverEventJSONs[shiftProductivityEventId],
            );
            collection[shiftProductivityEventId] = formatDriverEventJSON(
                averagedDriverEventJSON,
            );
            return collection;
        },
        {},
    );
    return {
        ...state,
        driverEventJSON: results,
    };
}

export function setFiltersWithUrlParams(state, filtersFromUrl) {
    return {
        ...state,
        filters: {
            ...state.filters,
            ...filtersFromUrl,
        },
    };
}

export function setErrorMessage(state, errorMessage) {
    return {
        ...state,
        errorMessage,
    };
}

export const reducer = createReducer(initialState, {
    [SET_SPINNER_STATE]: setSpinnerState,
    [FILTER_UPDATED]: filterUpdated,
    [LOAD_DRIVERS]: setDrivers,
    [LOAD_TYRES_AND_SAFETY_PRODUCTIVITY]: loadTyresAndSafetyProductivity,
    [SET_DRIVER_SHIFT_PRODUCTIVITY_JSONS]: setDriverShiftProductivityJsons,
    [SET_FILTERS_WITH_URL_PARAMS]: setFiltersWithUrlParams,
    [SET_ERROR_MESSAGE]: setErrorMessage,
    [FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS]: fetchPrivateSiteConfigurationSuccess,
});
