import { combineReducers } from 'redux';
import { reducer as filters } from '../Modules/filters';
import { reducer as spinner } from '../Modules/spinner';
import { reducer as timingEquipmentUptime } from '../Modules/timingEquipmentUptime';
import { reducer as truckAlertEvents } from '../Modules/truckAlertEvents';
import namespace from '../Modules/namespace';

export const REDUCER_KEY = namespace;
export default combineReducers({
    filters,
    spinner,
    timingEquipmentUptime,
    truckAlertEvents,
});
