import * as React from 'react';
import { createPortal } from 'react-dom';
import { DivIcon, marker, latLng } from 'leaflet';
import { withLeaflet, LeafletProvider, MapLayer } from 'react-leaflet';
import difference from 'lodash/difference';
import { animateMarker } from './utils/animateMarker';

/**
 * Works the same as a regular React leaflet marker except React components
 * can be used to render the content instead of dealing with the leaflet
 * divIcon construct directly
 */
class ReactMarker extends MapLayer {
    leafletElement;
    contextValue;

    createLeafletElement(props) {
        const {
            map,
            layerContainer,
            position,
            children,
            leaflet,
            ...rest
        } = props;

        const size = { x: 32, y: 48 };
        const icon = new DivIcon({
            ...rest,
            iconSize: [size.x, size.y],
            iconAnchor: [size.x / 2, size.y],
            tooltipAnchor: [size.x / 2, -size.y],
        });

        const el = marker(position, { icon, ...rest });
        this.contextValue = { ...props.leaflet, popupContainer: el };
        return el;
    }

    updateLeafletElement(fromProps, toProps) {
        const {
            position: fromPosition,
            zIndexOffset: fromZIndexOffset,
            opacity: fromOpacity,
            draggable: fromDraggable,
            className: fromClassName,
        } = fromProps;
        const {
            position: toPosition,
            zIndexOffset: toZIndexOffset,
            toOpacity,
            draggable: toDraggable,
            className: toClassName,
            animatePosition: toAnimatePosition,
            animateProgressIncrement: toAnimateProgressIncrement,
        } = toProps;

        if (toPosition !== fromPosition) {
            if (toAnimatePosition) {
                animateMarker(
                    this.leafletElement,
                    this.leafletElement.getLatLng(),
                    latLng(toPosition[0], toPosition[1]),
                    toAnimateProgressIncrement,
                );
            } else {
                this.leafletElement.setLatLng(toPosition);
            }
        }
        if (toZIndexOffset !== fromZIndexOffset) {
            this.leafletElement.setZIndexOffset(toZIndexOffset);
        }
        if (toOpacity !== fromOpacity) {
            this.leafletElement.setOpacity(toOpacity);
        }
        if (toDraggable !== fromDraggable) {
            if (toDraggable) {
                this.leafletElement.dragging.enable();
            } else {
                this.leafletElement.dragging.disable();
            }
        }

        if (toClassName !== fromClassName) {
            const fromClasses = fromClassName.split(' ');
            const toClasses = toClassName.split(' ');
            this.leafletElement._icon.classList.remove(
                ...difference(fromClasses, toClasses),
            );
            this.leafletElement._icon.classList.add(
                ...difference(toClasses, fromClasses),
            );
        }
    }

    UNSAFE_componentWillMount() {
        if (super.UNSAFE_componentWillMount) {
            super.UNSAFE_componentWillMount();
        }
        this.leafletElement = this.createLeafletElement(this.props);
        this.leafletElement.on('add', () => this.forceUpdate());
    }

    componentDidUpdate(fromProps) {
        this.updateLeafletElement(fromProps, this.props);
    }

    render() {
        const { children } = this.props;
        const container = this.leafletElement._icon;

        if (!container) {
            return null;
        }

        const portal = createPortal(children, container);

        return children == null ||
            portal == null ||
            this.contextValue == null ? null : (
            <LeafletProvider value={this.contextValue}>
                {portal}
            </LeafletProvider>
        );
    }
}

ReactMarker.displayName = 'ReactMarker';

export default withLeaflet(ReactMarker);
