import React from 'react';
import { Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import { selectLayerGroupSelectorItem } from './redux';

type ItemProps = {
    label: string;
    onClick: (e) => void;
    checked: boolean;
};

// a checkbox with a label
export const ItemPure = ({ label, checked, onClick }: ItemProps) => {
    return (
        <Checkbox
            checked={checked}
            style={{ margin: '5px' }}
            onChange={onClick}
        >
            {label}
        </Checkbox>
    );
};

export const Item = ({ groupLabel, checked, onClick }) => {
    // get all the items for a group label
    const itemLabels = useSelector(selectLayerGroupSelectorItem(groupLabel));
    return (
        <>
            {itemLabels.map((i) => (
                <ItemPure label={i} checked={checked} onClick={onClick} />
            ))}
        </>
    );
};
