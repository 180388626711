import createActions from '../../Modules/groups/actions';
import createReducer, { reset } from '../../Modules/groups/reducer';
import createSelectors from '../../Modules/groups/selectors';
import namespace from './namespace';
import * as editFormModule from '../Modules/editForm';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].groups;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);
export const reducer = createReducer(
    {
        onSuccess: [actions.FETCH_GROUPS_SUCCESS],
        onError: [actions.FETCH_GROUPS_ERROR],
    },
    {
        [editFormModule.actions.EDIT_FORM_RESET]: reset,
    },
);
export const selectors = createSelectors(statePath);
