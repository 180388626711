import { createReducer } from '../../Lib/reduxUtils';
import {
    FETCH_MAP_DATA_SUCCESS,
    FILTER_UPDATED,
    FETCH_MAP_DATA_ERROR,
    FETCH_SURFACE_ROUGHNESS_SUCCESS,
    FETCH_SURFACE_ROUGHNESS_ERROR,
} from '../Actions';

export const initialState = {
    errors: {
        fetchMapData: null,
        fetchSurfaceRoughness: null,
    },
};

function withErrorForKey(state, key, errorMessageOrNull) {
    return {
        ...state,
        errors: {
            ...state.errors,
            [key]: errorMessageOrNull,
        },
    };
}

export const fetchMapDataSuccess = (state) =>
    withErrorForKey(state, 'fetchMapData', null);
export const fetchMapDataError = (state, { error }) =>
    withErrorForKey(state, 'fetchMapData', error);

export const fetchSurfaceRoughnessSuccess = (state) =>
    withErrorForKey(state, 'fetchSurfaceRoughness', null);
export const fetchSurfaceRoughnessError = (state, { error }) =>
    withErrorForKey(state, 'fetchSurfaceRoughness', error);

export const filterUpdated = (state, { filterName }) => {
    if (filterName === 'IsLoaded') {
        return state;
    }
    return {
        ...state,
        error: null,
    };
};

export default createReducer(initialState, {
    [FETCH_MAP_DATA_SUCCESS]: fetchMapDataSuccess,
    [FETCH_MAP_DATA_ERROR]: fetchMapDataError,
    [FETCH_SURFACE_ROUGHNESS_SUCCESS]: fetchSurfaceRoughnessSuccess,
    [FETCH_SURFACE_ROUGHNESS_ERROR]: fetchSurfaceRoughnessError,
    [FILTER_UPDATED]: filterUpdated,
});
