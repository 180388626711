/* eslint-disable */
import moment from 'moment';
import 'moment-timezone';
import shiftUtils from './shiftUtils';

// TODO use the real version however that is going to get generated

/* global ressys_dashboard */
export default (function () {
    'use strict';

    let maxDaysInMonths = {
        1: 31, // Jan
        2: 29, // Feb
        3: 31, // Mar
        4: 30, // Apr
        5: 31, // May
        6: 30, // Jun
        7: 31, // Jul
        8: 31, // Aug
        9: 30, // Sep
        10: 31, // Oct
        11: 30, // Nov
        12: 31, // Dec
    };

    let DateItem = function (y, m, d) {
        // We'll treat a date item as being at mid-day..
        // Incrementing by 1 day (24 hours) over a timezone change should still increment the day/month/year correctly.
        if (
            y < 2000 ||
            y > 2099 ||
            m < 1 ||
            m > 12 ||
            d < 1 ||
            d > maxDaysInMonths[m]
        ) {
            this._isValid = false;
            this._date = null;
        } else {
            this._isValid = true;
            this._date = new Date(y, m - 1, d, 12, 0, 0, 0);
        }
    };

    DateItem.prototype.isValid = function () {
        return this._isValid;
    };

    DateItem.prototype.isoWeekday = function () {
        return this._date.getDay() === 0 ? 7 : this._date.getDay();
    };

    DateItem.prototype.addDays = function (value) {
        this._date.setDate(this._date.getDate() + value);
        return this;
    };

    DateItem.prototype.clone = function () {
        return new DateItem(
            this._date.getFullYear(),
            this._date.getMonth() + 1,
            this._date.getDate(),
        );
    };

    DateItem.prototype.getYYYYMMDDInt = function () {
        return (
            this._date.getFullYear() * 10000 +
            (this._date.getMonth() + 1) * 100 +
            this._date.getDate()
        );
    };

    DateItem.prototype.getYYYYMMDDString = function () {
        return this.getYYYYMMDDInt().toString();
    };

    DateItem.prototype.isBefore = function (otherDateItem) {
        return this.getYYYYMMDDInt() < otherDateItem.getYYYYMMDDInt();
    };

    DateItem.prototype.isSameOrBefore = function (otherDateItem) {
        return this.getYYYYMMDDInt() <= otherDateItem.getYYYYMMDDInt();
    };

    DateItem.prototype.isSameAs = function (otherDateItem) {
        return this.getYYYYMMDDInt() === otherDateItem.getYYYYMMDDInt();
    };

    DateItem.prototype.isSameOrAfter = function (otherDateItem) {
        return this.getYYYYMMDDInt() >= otherDateItem.getYYYYMMDDInt();
    };

    DateItem.prototype.isAfter = function (otherDateItem) {
        return this.getYYYYMMDDInt() > otherDateItem.getYYYYMMDDInt();
    };

    DateItem.prototype.getThisOrMostRecentMonday = function () {
        let newDateItem = this.clone();
        let deltaDays = 0;

        if (newDateItem._date.getDay() === 0) {
            // Sunday
            deltaDays = 6;
        } else {
            // Monday - Saturday == 1 =>
            deltaDays = newDateItem._date.getDay() - 1;
        }

        newDateItem._date.setDate(newDateItem._date.getDate() - deltaDays);

        return newDateItem;
    };

    DateItem.prototype.getNextMonday = function () {
        let newDateItem = this.clone();
        let deltaDays = 0;

        // Sunday
        if (newDateItem._date.getDay() === 0) {
            deltaDays = 1;
        } else {
            deltaDays = 7 - (newDateItem._date.getDay() - 1);
        }

        newDateItem._date.setDate(newDateItem._date.getDate() + deltaDays);

        return newDateItem;
    };

    let MAX_DATE_ITEM = new DateItem(2099, 12, 31);

    let ShiftIdItem = function (dateItem, inDayIndex) {
        this.dateItem = dateItem.clone();
        this.inDayIndex = inDayIndex;
    };

    ShiftIdItem.prototype.shiftIdInt = function () {
        return this.dateItem.getYYYYMMDDInt() * 10 + this.inDayIndex;
    };

    ShiftIdItem.prototype.shiftId = function () {
        return this.shiftIdInt().toString();
    };

    /**
     * Determine if this shiftId Item is less than or equal to another.
     *
     * @param {ShiftIdItem} otherShiftIdItem
     * @returns {Boolean}
     */
    ShiftIdItem.prototype.isLessThanOrEqualTo = function (otherShiftIdItem) {
        return this.shiftIdInt() <= otherShiftIdItem.shiftIdInt();
    };

    ShiftIdItem.prototype.isBefore = function (otherShiftIdItem) {
        return this.shiftIdInt() < otherShiftIdItem.shiftIdInt();
    };

    ShiftIdItem.prototype.isGreaterThanOrEqualTo = function (otherShiftIdItem) {
        return this.shiftIdInt() >= otherShiftIdItem.shiftIdInt();
    };

    /**
     *
     * @param {moment} dateItem
     * @param {integer} inDayIndex
     * @param {ShiftPattern} shiftPattern
     * @returns {shiftCalculatingshiftCalculating.DetailedShiftIdItem}
     */
    let DetailedShiftIdItem = function (dateItem, inDayIndex, shiftPattern) {
        this.dateItem = dateItem.clone();
        this.inDayIndex = inDayIndex;
        this.shiftPattern = shiftPattern;
    };

    // I'm sure there's a better way to do this!
    DetailedShiftIdItem.prototype.shiftIdInt = ShiftIdItem.prototype.shiftIdInt;
    DetailedShiftIdItem.prototype.shiftId = ShiftIdItem.prototype.shiftId;
    DetailedShiftIdItem.prototype.isBefore = ShiftIdItem.prototype.isBefore;
    DetailedShiftIdItem.prototype.isLessThanOrEqualTo =
        ShiftIdItem.prototype.isLessThanOrEqualTo;
    DetailedShiftIdItem.prototype.isGreaterThanOrEqualTo =
        ShiftIdItem.prototype.isGreaterThanOrEqualTo;

    DetailedShiftIdItem.prototype.clone = function () {
        return new DetailedShiftIdItem(
            this.dateItem.clone(),
            this.inDayIndex,
            this.shiftPattern,
        );
    };

    DetailedShiftIdItem.prototype.getFirstShiftOfThisWeek = function () {
        return this.shiftPattern.shiftPatternsCalculator.obtainDetailedShiftIdItem(
            new ShiftIdItem(this.dateItem.getThisOrMostRecentMonday(), 0),
        );
    };

    DetailedShiftIdItem.prototype.getFirstShiftOfLastWeek = function () {
        return this.shiftPattern.shiftPatternsCalculator.obtainDetailedShiftIdItem(
            new ShiftIdItem(
                this.dateItem.getThisOrMostRecentMonday().addDays(-7),
                0,
            ),
        );
    };

    DetailedShiftIdItem.prototype.getFirstShiftOfNextWeek = function () {
        return this.shiftPattern.shiftPatternsCalculator.obtainDetailedShiftIdItem(
            new ShiftIdItem(
                this.dateItem.getThisOrMostRecentMonday().addDays(7),
                0,
            ),
        );
    };

    DetailedShiftIdItem.prototype.addDays = function (numberOfDays) {
        let absNumberOfDays = Math.abs(numberOfDays);
        if (numberOfDays > 0) {
            for (var d = 0; d < absNumberOfDays; d++) {
                this.dateItem.addDays(1);
                if (this.dateItem.isAfter(this.shiftPattern.endDateItem)) {
                    this.shiftPattern = this.shiftPattern.nextShiftPattern;
                }
            }
        } else {
            for (var d = 0; d < absNumberOfDays; d++) {
                this.dateItem.addDays(-1);
                if (this.dateItem.isBefore(this.shiftPattern.startDateItem)) {
                    this.shiftPattern = this.shiftPattern.previousShiftPattern;
                }
            }
        }
    };
    DetailedShiftIdItem.prototype.isLastShiftOfTheDay = function () {
        return this.inDayIndex === this.shiftPattern.rawShiftsInDay.length - 1;
    };

    DetailedShiftIdItem.prototype.getStartTime = function () {
        const shiftDate = moment.tz(
            this.dateItem.getYYYYMMDDString(),
            'YYYYMMDD',
            this.shiftPattern.minesiteTimezoneStr,
        );
        const currentShiftIndex = this.inDayIndex;
        const prevShiftStartIndex =
            currentShiftIndex === 0
                ? this.shiftPattern.rawShiftsInDay.length - 1
                : currentShiftIndex - 1;

        const currentShift = this.shiftPattern.rawShiftsInDay[
            currentShiftIndex
        ];
        const [
            currentShiftHours,
            currentShiftMinutes,
        ] = currentShift.startTime.split(':');

        const prevShift = this.shiftPattern.rawShiftsInDay[prevShiftStartIndex];
        const [prevShiftHours] = prevShift.startTime.split(':');

        // Handle when there are many shifts in a day and the shifts go past midnight on the current day
        const goesPastMidnight =
            prevShiftStartIndex < currentShiftIndex &&
            parseInt(prevShiftHours, 10) > parseInt(currentShiftHours, 10);

        if (goesPastMidnight) {
            shiftDate.add(1, 'day');
        }

        return shiftDate.hours(currentShiftHours).minutes(currentShiftMinutes);
    };

    DetailedShiftIdItem.prototype.getEndTime = function () {
        const shiftDate = moment.tz(
            this.dateItem.getYYYYMMDDString(),
            'YYYYMMDD',
            this.shiftPattern.minesiteTimezoneStr,
        );
        const currentShiftIndex = this.inDayIndex;
        const isNextShiftOutsideCurrentPattern =
            this.inDayIndex + 1 > this.shiftPattern.rawShiftsInDay.length - 1;
        const nextShiftIndex = isNextShiftOutsideCurrentPattern
            ? 0
            : this.inDayIndex + 1;

        const firstShift = this.shiftPattern.rawShiftsInDay[0];
        const [firstShiftHours] = firstShift.startTime.split(':');

        const currentShift = this.shiftPattern.rawShiftsInDay[
            currentShiftIndex
        ];
        const [currentShiftHours] = currentShift.startTime.split(':');

        let nextShift = this.shiftPattern.rawShiftsInDay[nextShiftIndex];
        let [nextShiftHours, nextShiftMinutes] = nextShift.startTime.split(':');

        // When the next shift is greater than the length of the array of shifts
        if (
            this.shiftPattern.nextShiftPattern &&
            isNextShiftOutsideCurrentPattern
        ) {
            const nextShiftPattern = this.shiftPattern.nextShiftPattern;
            const nextShiftNextPatternShift =
                nextShiftPattern.rawShiftsInDay[nextShiftIndex];
            const [
                nextShiftNextPatternShiftHours,
                nextShiftNextPatternShiftMinutes,
            ] = nextShiftNextPatternShift.startTime.split(':');

            const nextShiftCurrentPatternStartDate = shiftDate
                .clone()
                .add(1, 'day')
                .hours(nextShiftHours)
                .minutes(nextShiftMinutes);

            const nextShiftNextPatternStartDate = moment
                .tz(
                    nextShiftPattern.startDateItem.getYYYYMMDDString(),
                    'YYYYMMDD',
                    this.shiftPattern.minesiteTimezoneStr,
                )
                .hours(nextShiftNextPatternShiftHours)
                .minutes(nextShiftNextPatternShiftMinutes);

            // When the next shift overlaps with a new shiftPattern
            if (
                nextShiftCurrentPatternStartDate.isAfter(
                    nextShiftNextPatternStartDate,
                )
            ) {
                nextShift = nextShiftPattern.rawShiftsInDay[nextShiftIndex];
                nextShiftHours = nextShiftNextPatternShiftHours;
                nextShiftMinutes = nextShiftNextPatternShiftMinutes;
            }
        }

        const goesPastMidnight =
            parseInt(nextShiftHours, 10) < parseInt(currentShiftHours, 10);
        const shiftStartsAfterMidnight =
            parseInt(currentShiftHours, 10) < parseInt(firstShiftHours, 10);
        if (goesPastMidnight || shiftStartsAfterMidnight) {
            shiftDate.add(1, 'day');
        }

        return shiftDate.hours(nextShiftHours).minutes(nextShiftMinutes);
    };

    DetailedShiftIdItem.prototype.incrementShiftId = function () {
        this.inDayIndex = this.inDayIndex + 1;
        if (this.inDayIndex > this.shiftPattern.rawShiftsInDay.length - 1) {
            this.inDayIndex = 0;
            this.dateItem.addDays(1);

            if (this.dateItem.isAfter(this.shiftPattern.endDateItem)) {
                this.shiftPattern = this.shiftPattern.nextShiftPattern;
            }
        }
        return this;
    };

    let getShiftIdItem = function (shiftId) {
        let shiftIdStr = shiftId.toString();
        if (shiftIdStr.length !== 9) {
            throw new Error(
                'Obtained a shift id (`' +
                    shiftIdStr +
                    '`) that is not 9 characters',
            );
        }

        let dateItem = new DateItem(
            parseInt(shiftIdStr.substr(0, 4)),
            parseInt(shiftIdStr.substr(4, 2)),
            parseInt(shiftIdStr.substr(6, 2)),
        );

        let inDayIndex = parseInt(shiftIdStr.substr(8, 1));
        return new ShiftIdItem(dateItem, inDayIndex);
    };

    let ShiftPattern = function (
        shiftPatternDefinition,
        previousShiftPattern,
        minesiteTimezoneStr,
        shiftPatternsCalculator,
    ) {
        this._initial_shiftPatternDefinition = shiftPatternDefinition;

        // ToDo - Actually map these to more useful data types.
        this.rawShiftsInDay = shiftPatternDefinition.shifts;

        this.previousShiftPattern = previousShiftPattern;
        this.minesiteTimezoneStr = minesiteTimezoneStr;
        this.shiftPatternsCalculator = shiftPatternsCalculator;

        // convert YYYY-MM-DD => [YYYY, MM, DD]
        let YMDParts = shiftPatternDefinition.startDate
            .split('-')
            .map((v) => parseInt(v));

        this.startDateItem = new DateItem(
            YMDParts[0],
            YMDParts[1],
            YMDParts[2],
        );

        if (!this.startDateItem.isValid()) {
            throw new Error(
                `Invalid start date (value of ${shiftPatternDefinition.startDate}) in pattern.`,
            );
        }

        this.nextShiftPattern = null;
        this.endDateItem = MAX_DATE_ITEM;

        // Over-ride the previous's shift pattern.
        if (this.previousShiftPattern !== null) {
            this.previousShiftPattern.nextShiftPattern = this;
            this.previousShiftPattern.endDateItem = this.startDateItem
                .clone()
                .addDays(-1);
        }
    };

    /**
     * Constructor for the PrimaryShiftPatternsCalculator
     *
     * @param {object} shiftPatternsDefinition
     * @param {string} minesiteTimezoneStr
     *
     * @returns {PrimaryShiftPatternsCalculator}
     */
    let PrimaryShiftPatternsCalculator = function (
        shiftPatternsDefinition,
        minesiteTimezoneStr,
    ) {
        this._initial_shiftPatternsDefinition = shiftPatternsDefinition;
        this.minesiteTimezoneStr = minesiteTimezoneStr;

        if (!Array.isArray(shiftPatternsDefinition)) {
            throw new Error('The shiftPatternsDefinition must be a list');
        }

        if (shiftPatternsDefinition.length === 0) {
            throw new Error('Unexpected empty shiftPatternsDefinition list.');
        }

        this.shiftPatterns = [];
        let previousShiftPattern = null;

        shiftPatternsDefinition.forEach((shiftPatternDefinition) => {
            let thisShiftPattern = new ShiftPattern(
                shiftPatternDefinition,
                previousShiftPattern,
                this.minesiteTimezoneStr,
                this,
            );
            this.shiftPatterns.push(thisShiftPattern);
            previousShiftPattern = thisShiftPattern;
        });

        this.startDateItem = this.shiftPatterns[0].startDateItem;
    };

    /**
     *
     * @param {ressys_dashboard.shiftCalculating.ShiftIdItem} shiftIdItem
     */
    PrimaryShiftPatternsCalculator.prototype.getPatternForShiftIdItem = function (
        shiftIdItem,
        returnIsValid = false,
    ) {
        let theShiftPattern = this.shiftPatterns.find(
            (shiftPattern) =>
                shiftIdItem.dateItem.isSameOrAfter(
                    shiftPattern.startDateItem,
                ) &&
                shiftIdItem.dateItem.isSameOrBefore(shiftPattern.endDateItem),
        );

        if (!theShiftPattern) {
            theShiftPattern = this.getEarliestShiftPattern();
        }

        if (
            shiftIdItem.inDayIndex >
            theShiftPattern.rawShiftsInDay.length - 1
        ) {
            if (returnIsValid) return false;
            throw new Error(
                'The shift id has an index greater than that allowed in the pattern',
            );
        }

        if (returnIsValid) return true;
        return theShiftPattern;
    };

    /**
     *
     * @param {ressys_dashboard.shiftCalculating.ShiftIdItem} shiftIdItem
     * Note: Custom function that differs that isn't in the code gen'd version
     */
    PrimaryShiftPatternsCalculator.prototype.getPatternForDate = function (
        dateItem,
    ) {
        let theShiftPattern = this.shiftPatterns.find(
            (shiftPattern) =>
                dateItem.isSameOrAfter(shiftPattern.startDateItem) &&
                dateItem.isSameOrBefore(shiftPattern.endDateItem),
        );
        // If there's no shiftPattern it was before the dateItem is before earliest shiftPattern
        if (!theShiftPattern) {
            theShiftPattern = this.getEarliestShiftPattern();
        }
        return theShiftPattern;
    };

    /**
     *
     * @param {ShiftIdItem} shiftIdItem
     * @returns {DetailedShiftIdItem}
     */
    PrimaryShiftPatternsCalculator.prototype.obtainDetailedShiftIdItem = function (
        shiftIdItem,
    ) {
        if (
            typeof shiftIdItem === 'number' ||
            typeof shiftIdItem === 'string'
        ) {
            shiftIdItem = getShiftIdItem(shiftIdItem);
        }

        return new DetailedShiftIdItem(
            shiftIdItem.dateItem.clone(),
            shiftIdItem.inDayIndex,
            this.getPatternForShiftIdItem(shiftIdItem),
        );
    };

    PrimaryShiftPatternsCalculator.prototype.isValidShiftIdItem = function (
        shiftIdItem,
    ) {
        return this.getPatternForShiftIdItem(shiftIdItem, true);
    };

    PrimaryShiftPatternsCalculator.prototype.isValidShiftId = function (
        shiftId,
    ) {
        return this.isValidShiftIdItem(getShiftIdItem(shiftId));
    };

    /**
     * Creates an array of shift id's.
     *
     * @param {int|string} firstShiftId
     * @param {int|string} lastShiftId
     *
     * @returns {Array} An array of integers or strings.
     */
    PrimaryShiftPatternsCalculator.prototype.generateShiftIdRange = function (
        firstShiftId,
        lastShiftId,
        shiftIdIndexes = [],
    ) {
        if (parseInt(lastShiftId) < parseInt(firstShiftId)) {
            throw new Error(
                'The lastShiftId `' +
                    lastShiftId.toString() +
                    '` is before the first_shift_id `' +
                    firstShiftId.toString() +
                    '`',
            );
        }
        let firstShiftIdItem = getShiftIdItem(firstShiftId);
        let lastShiftIdItem = getShiftIdItem(lastShiftId);

        let detailedFirstShiftIdItem = this.obtainDetailedShiftIdItem(
            firstShiftIdItem,
        );

        let shiftIds = [];
        let runningShiftIdItem = detailedFirstShiftIdItem.clone();

        let loopCount = 0;

        /**
         * Note: This function is customised slightly from the code gen'd version to allow a shiftId range of shiftIdIndexes
         */
        while (runningShiftIdItem.isLessThanOrEqualTo(lastShiftIdItem)) {
            if (
                !shiftIdIndexes.length ||
                (shiftIdIndexes.length &&
                    shiftIdIndexes.indexOf(runningShiftIdItem.inDayIndex)) > -1
            ) {
                shiftIds.push(runningShiftIdItem.shiftId());
            }
            runningShiftIdItem.incrementShiftId();

            /**
             * This number is considered a sufficiently unrealistic request:
             * e.g. 10 years of 6 shifts per day = 6 * 365 * 10 = 21900
             */
            loopCount += 1;
            if (loopCount > 30000) {
                throw new Error(
                    'Too many shifts got created. This algorithm must be wrong.',
                );
            }
        }

        return shiftIds;
    };

    /**
     *
     * @return {DetailedShiftIdItem}
     */
    PrimaryShiftPatternsCalculator.prototype.getFirstDetailedShiftIdItem = function () {
        return new DetailedShiftIdItem(
            this.shiftPatterns[0].startDateItem,
            0,
            this.shiftPatterns[0],
        );
    };

    /**
     * Gets the relevant shift pattern for a given date / time.
     * @param date_m The datetime object (with tzinfo set)
     * @return {string}
     */
    PrimaryShiftPatternsCalculator.prototype.getShiftPatternForMoment = function (
        date_m,
        returnIsValid = false,
    ) {
        const firstShiftPattern = this.shiftPatterns[0];
        const firstShift = firstShiftPattern.rawShiftsInDay[0];
        const [firstShiftHours, firstShiftMinutes] = firstShift.startTime.split(
            ':',
        );
        const startDateItem_m = moment
            .tz(
                firstShiftPattern.startDateItem.getYYYYMMDDString(),
                'YYYYMMDD',
                this.minesiteTimezoneStr,
            )
            .hours(firstShiftHours)
            .minutes(firstShiftMinutes);

        if (date_m.isBefore(startDateItem_m)) {
            throw new Error('The shift id item has a date preceeding');
        }

        let theShiftPattern = this.shiftPatterns.find((shiftPattern) => {
            const shift = shiftPattern.rawShiftsInDay[0];
            const [shiftHours, shiftMinutes] = shift.startTime.split(':');
            return date_m.isSameOrAfter(
                moment
                    .tz(
                        shiftPattern.startDateItem.getYYYYMMDDString(),
                        'YYYYMMDD',
                        this.minesiteTimezoneStr,
                    )
                    .hours(shiftHours)
                    .minutes(shiftMinutes),
            );
        });

        if (theShiftPattern === undefined) {
            if (returnIsValid) {
                return false;
            }
            throw new Error(
                'Could not find a pattern that the shift is within!',
            );
        }

        if (returnIsValid) {
            return true;
        }
        return theShiftPattern;
    };

    /**
     * @typedef {Object} Shift
     * @property {string} startTime
     * @property {string} endTime
     */

    /**
     * @typedef {Object} ShiftPattern
     * @property {Shift[]} rawShiftsInDay
     */

    /**
     * @typedef {Object} ShiftForMomentResult
     * @property {Shift} shift
     * @property {ShiftPattern} shiftPattern
     * @property {number} shiftIndex
     * @property {number} addDays
     */

    /**
     * Gets the relevant shift for a given date / time.
     * @param reference_m The datetime object (with tzinfo set)
     * @return {ShiftForMomentResult}
     */
    PrimaryShiftPatternsCalculator.prototype.getShiftForMoment = function (
        reference_m,
        testing,
    ) {
        const shiftPattern = this.getShiftPatternForMoment(reference_m);
        for (let i = shiftPattern.rawShiftsInDay.length - 1; i >= 0; i--) {
            const firstShift = shiftPattern.rawShiftsInDay[0];
            const [firstShiftHours] = firstShift.startTime.split(':');

            const shift = shiftPattern.rawShiftsInDay[i];
            const [shiftStartHours, shiftStartMinutes] = shift.startTime.split(
                ':',
            );

            const shiftStartHHMM = moment({
                h: shiftStartHours,
                m: shiftStartMinutes,
            });

            const refHHMM = moment({
                h: reference_m.hours(),
                m: reference_m.minutes(),
            });

            const shiftStartsAfterMidnight =
                parseInt(shiftStartHours, 10) < parseInt(firstShiftHours, 10);
            if (shiftStartsAfterMidnight) {
                shiftStartHHMM.add(1, 'day');
            }

            if (refHHMM.isBefore(shiftStartHHMM)) {
                if (i === 0) {
                    // If it's before the first shift, then it's the previous 'Night' shift
                    if (shiftPattern.previousShiftPattern) {
                        const prevShifts =
                            shiftPattern.previousShiftPattern.rawShiftsInDay;
                        return {
                            shift: prevShifts[prevShifts.length - 1],
                            shiftIndex: prevShifts.length - 1,
                            shiftPattern: shiftPattern.previousShiftPattern,
                            addDays: shiftStartsAfterMidnight ? 1 : 0,
                        };
                    }
                    // TODO If there are no other shiftPatterns, fallback to the same pattern or should we throw?
                    // throw new Error(`Current time is before the first shift pattern !!!!`)
                    // Sun Oct 03 2010 14:31:00 GMT+1030
                    return {
                        shift:
                            shiftPattern.rawShiftsInDay[
                                shiftPattern.rawShiftsInDay.length - 1
                            ],
                        shiftIndex: shiftPattern.rawShiftsInDay.length - 1,
                        shiftPattern,
                        addDays: -1,
                    };
                }
                continue;
            }
            return {
                shift,
                shiftIndex: i,
                shiftPattern,
                addDays: shiftStartsAfterMidnight ? 1 : 0,
            };
        }
    };

    /**
     * Gets the last time a crew worked based on the 'currentTimeAtMinesite'
     * @param {moment} currentTimeAtMinesite - The time at the minesite, could be 'now' or a moment
     * @param {object} preShiftCribScreeningOffset - The hours, minutes to offset the 'currentTimeAtMinesite' by. This is useful for the wallboard displays
     * @return {{crew: (*|void), shift: *, shiftIndex: number, shiftDate: *, shiftId: string}}
     */
    PrimaryShiftPatternsCalculator.prototype.getLastWorkedShiftIdForCurrentShift = function (
        currentTimeAtMinesite = moment.tz(moment(), this.minesiteTimezoneStr),
        preShiftCribScreeningOffset = { hours: 0, minutes: 0 },
    ) {
        const currentTimeAtMinesiteWithOffset = currentTimeAtMinesite
            .clone()
            .add(preShiftCribScreeningOffset.hours, 'hours')
            .add(preShiftCribScreeningOffset.minutes, 'minutes');
        const minesiteTimeZone = this.minesiteTimezoneStr;
        const currentShift = this.getShiftForMoment(
            currentTimeAtMinesiteWithOffset,
        );
        const currentShiftPattern = currentShift.shiftPattern;
        const crewCadences =
            currentShiftPattern._initial_shiftPatternDefinition.crewCadences;
        const referenceDate = moment.tz(
            currentShiftPattern._initial_shiftPatternDefinition.referenceDate,
            'YYYY-MM-DD',
            minesiteTimeZone,
        );

        const [
            firstShiftStartHours,
            firstShiftStartMinutes,
        ] = currentShiftPattern.rawShiftsInDay[0].startTime.split(':');
        const firstShift_m = currentTimeAtMinesiteWithOffset
            .clone()
            .hours(firstShiftStartHours)
            .minutes(firstShiftStartMinutes)
            .seconds(0)
            .milliseconds(0);

        // If the current time is before the start of today's day shift, the shift must be
        // yesterday's night shift
        if (currentTimeAtMinesiteWithOffset.isBefore(firstShift_m)) {
            currentTimeAtMinesiteWithOffset.subtract(1, 'day');
        }

        // Get crew that is working today
        const getCrewOnDay = (crewCadences, referenceDate, date, shift) => {
            // Get number of days since reference Date
            let deltaDays = date.diff(referenceDate, 'days');

            // Get the number of days of the crew cadence
            // ASSUMPTION: all crew cadence lengths are all the same
            let crewCadenceLength = crewCadences[0].Cadence.length;

            // Find where the date lies within the crewCadence
            let dayPosition = Math.abs(deltaDays % crewCadenceLength);

            // Find which crew is working the 'shift' defined above
            for (let i = 0; i < crewCadences.length; i++) {
                // Look at cadence, does their day match with shift
                if (
                    parseInt(
                        crewCadences[i].Cadence.charAt(dayPosition),
                        10,
                    ) === shift
                ) {
                    return crewCadences[i].Crew;
                }
            }
        };

        const currentShift__FOR_LOGGING_ONLY = this.getShiftForMoment(
            currentTimeAtMinesite,
        );
        const referenceDate__FOR_LOGGING_ONLY = moment.tz(
            currentShift__FOR_LOGGING_ONLY.shiftPattern
                ._initial_shiftPatternDefinition.referenceDate,
            'YYYY-MM-DD',
            minesiteTimeZone,
        );
        const todaysCurrentCrew__FOR_LOGGING_ONLY = getCrewOnDay(
            crewCadences,
            referenceDate__FOR_LOGGING_ONLY,
            currentTimeAtMinesite,
            currentShift__FOR_LOGGING_ONLY.shiftIndex,
        );
        const todaysOffsetCrew = getCrewOnDay(
            crewCadences,
            referenceDate,
            currentTimeAtMinesiteWithOffset,
            currentShift.shiftIndex,
        );

        // Look as many days back as length in the crew cadence until we get the same crew
        let m_previousMinesiteTime = null;
        // Number of days in a cadence. Example: "DDNNXXXX" = 8
        const crewCadenceLength = crewCadences[0].Cadence.length;

        let abortLoop = false;
        for (let dayIndex = 1; dayIndex < crewCadenceLength; dayIndex++) {
            // Make a temp date so we dont overwrite a date to look back
            const m_tmpDate = currentTimeAtMinesiteWithOffset
                .clone()
                .add(-dayIndex, 'days');

            // Go backwards in time dayIndex times, returning the crews
            for (
                let shiftIndex = 0;
                shiftIndex <= currentShiftPattern.rawShiftsInDay.length - 1;
                shiftIndex++
            ) {
                const maybeTodaysCrew = getCrewOnDay(
                    crewCadences,
                    referenceDate,
                    m_tmpDate,
                    shiftIndex,
                );
                const shift = currentShiftPattern.rawShiftsInDay[shiftIndex];
                const [shiftHours, shiftMinutes] = shift.startTime.split(':');
                if (todaysOffsetCrew === maybeTodaysCrew) {
                    m_previousMinesiteTime = m_tmpDate
                        .hours(shiftHours)
                        .minutes(shiftMinutes);
                    abortLoop = true;
                    break;
                }
            }
            if (abortLoop) {
                break;
            }
        }

        const previousShift = this.getShiftForMoment(m_previousMinesiteTime);
        const lastShiftId = `${m_previousMinesiteTime.format('YYYYMMDD')}${
            previousShift.shiftIndex
        }`;

        return {
            crew: todaysOffsetCrew,
            shift: previousShift.shift,
            shiftIndex: previousShift.shiftIndex,
            shiftDate: m_previousMinesiteTime.format('YYYYMMDD'),
            shiftId: lastShiftId,
        };
    };

    /**
     * Gets the yesterday shift
     * @param {moment} currentTimeAtMinesite - The time at the minesite, could be 'now' or a moment
     * @param {object} preShiftCribScreeningOffset - The hours, minutes to offset the 'currentTimeAtMinesite' by. This is useful for the wallboard displays
     * @return {{crew: (*|void), shift: *, shiftIndex: number, shiftDate: *, shiftId: string}}
     */
    PrimaryShiftPatternsCalculator.prototype.getLastWorkedShiftIdForYesterday = function (
        currentTimeAtMinesite = moment.tz(moment(), this.minesiteTimezoneStr),
        preShiftCribScreeningOffset = { hours: 0, minutes: 0 },
    ) {
        const yesterdayShiftParamInMinutes =
            24 * 60 - preShiftCribScreeningOffset.minutes;

        const now = shiftUtils.createMomentInSiteTime();
        const yesterdayTimeWithOffset = now
            .clone()
            .subtract(yesterdayShiftParamInMinutes, 'minutes');
        const yesterdayShift = this.getShiftForMoment(yesterdayTimeWithOffset);

        const shiftDate = yesterdayTimeWithOffset
            .add(yesterdayShift.addDays, 'days')
            .format('YYYYMMDD');

        return {
            shift: yesterdayShift.shift,
            shiftIndex: yesterdayShift.shiftIndex,
            shiftDate: shiftDate,
            shiftId: `${shiftDate}${yesterdayShift.shiftIndex}`,
        };
    };

    /**
     * Finds the earliest shift pattern in the PrimaryShiftPatterns
     * @return {*}
     */
    PrimaryShiftPatternsCalculator.prototype.getEarliestShiftPattern = function () {
        return this.shiftPatterns.find(
            (pattern) => pattern.previousShiftPattern === null,
        );
    };

    return {
        DateItem: DateItem,
        getShiftIdItem: getShiftIdItem,
        PrimaryShiftPatternsCalculator: PrimaryShiftPatternsCalculator,
    };
})();
