import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_MAP_LABELS_SUCCESS = `${namespace}/FETCH_MAP_LABELS_SUCCESS`;
    const fetchMapLabelsSuccess = (resultsArray) =>
        action(FETCH_MAP_LABELS_SUCCESS, resultsArray);

    const FETCH_MAP_LABELS_ERROR = `${namespace}/FETCH_MAP_LABELS_ERROR`;
    const fetchMapLabelsError = ({ error }) =>
        action(FETCH_MAP_LABELS_ERROR, { error });

    return {
        FETCH_MAP_LABELS_SUCCESS,
        fetchMapLabelsSuccess,
        FETCH_MAP_LABELS_ERROR,
        fetchMapLabelsError,
    };
}
