import { objectToArray } from '@rs/core/utils/dataUtils';

export default function createSelectors(statePath) {
    function getAll(state) {
        const slice = statePath(state);
        return slice.byEmail;
    }

    function getAllAsArray(state) {
        const slice = statePath(state);
        return objectToArray(slice.byEmail);
    }

    function getByEmail(state, email = '') {
        const slice = statePath(state);

        return slice.byEmail[email.toLowerCase()];
    }

    return {
        getAll,
        getAllAsArray,
        getByEmail,
    };
}
