import * as React from 'react';

function SvgLoadUnitSymbolPayloadOverload(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <path
                d="M854.278 874H170.73c-8.886 0-16.727-5.826-19.26-14.305L30.844 457.877c-1.81-6.067-.684-12.657 3.136-17.76 3.78-5.144 9.77-8.117 16.124-8.117 83.068 285.13 128.158 427.695 135.272 427.695h647.306c10.914 0 58.322-142.565 142.222-427.695a20.077 20.077 0 0116.124 8.117c3.78 5.063 4.946 11.693 3.136 17.76L873.538 859.695c-2.533 8.479-10.374 14.305-19.26 14.305zm-21.366-34.79c0 11.1-628.728 12.79-639.824 12.79C181.993 852 111 503.52 111 492.42c0-49.147 35.416-90.17 82.088-98.857 7.558-40.701 39.718-72.876 80.4-80.437C282.172 266.432 323.176 231 372.3 231c26.693 0 51.818 10.738 70.35 29.158 37.064-36.84 103.636-36.84 140.7 0C601.882 241.738 627.007 231 653.7 231c49.124 0 90.128 35.432 98.812 82.126 40.682 7.561 72.842 39.736 80.4 80.437C879.584 402.25 915 443.273 915 492.42c0 11.1-70.993 346.79-82.088 346.79z"
                stroke="#000"
                strokeWidth={42.667}
                fill="#000"
                fillRule="nonzero"
            />
        </svg>
    );
}

export default SvgLoadUnitSymbolPayloadOverload;
