import { createReducer } from '../../Lib/reduxUtils';
import {
    SUBMIT_CREATE_USER,
    CREATE_USER_ERROR,
    CREATE_USER_SUCCESS,
    RESET_CREATE_USER_FORM,
} from '../Actions';

const createUserFormInitialState = {
    driverId: '',
    firstName: '',
    lastName: '',
    contactNumber: '',
    employeeId: '',
    userName: '',
    email: '',
    password: '',
};

export const initialState = {
    apiErrorMessage: '',
    apiSuccessMessage: '',
    createUserForm: createUserFormInitialState,
};

function reset() {
    return initialState;
}

// //////////////////////////////////////////////////////////////////////
//  SELECTORS
// //////////////////////////////////////////////////////////////////////

const getCreateUserForm = (state) => state.reducer.createUserForm;
const getApiErrorMessage = (state) => state.reducer.apiErrorMessage;
const getApiSuccessMessage = (state) => state.reducer.apiSuccessMessage;
export const sliceSelectors = {
    getCreateUserForm,
    getApiErrorMessage,
    getApiSuccessMessage,
};

// //////////////////////////////////////////////////////////////////////
//  REDUCERS
// //////////////////////////////////////////////////////////////////////

export const createUserError = (state, apiErrorMessage) => ({
    ...state,
    apiSuccessMessage: '',
    apiErrorMessage,
});

export const createUserSuccess = (state, apiSuccessMessage) => ({
    ...state,
    apiErrorMessage: '',
    apiSuccessMessage,
});

export const reducer = createReducer(initialState, {
    [RESET_CREATE_USER_FORM]: reset,
    [CREATE_USER_ERROR]: createUserError,
    [CREATE_USER_SUCCESS]: createUserSuccess,
    [SUBMIT_CREATE_USER]: (state) => ({
        ...state,
        createUserForm: {
            ...state.createUserForm,
        },
    }),
});
