import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { roundTo } from '@rs/core/utils/mathHelpers';
import { Button } from '@rs/core/falcon';

class OperatorFeedbackProductivityEventButton extends Component {
    constructor(props) {
        super(props);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleButtonClick() {
        const { eventIndex, onClick } = this.props;
        onClick(eventIndex);
    }

    handleMouseEnter() {
        const { eventIndex, onMouseEnter } = this.props;
        onMouseEnter(eventIndex);
    }

    handleMouseLeave() {
        const { onMouseLeave } = this.props;
        onMouseLeave(null);
    }

    render() {
        const { handleButtonClick, handleMouseEnter, handleMouseLeave } = this;

        const {
            buttonColor,
            totalLostTime,
            totalPasses,
            eventIndex,
        } = this.props;

        return (
            <Button
                onClick={handleButtonClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                block={true}
                style={{
                    height: 100,
                    backgroundColor: buttonColor,
                    borderColor: buttonColor,
                }}
                type={'primary'}
            >
                <strong>
                    Event {eventIndex + 1}
                    <br />
                    <br />
                    Total Lost Time:
                    <br />
                    {`${roundTo(totalLostTime, 0)}s over ${roundTo(
                        totalPasses,
                        0,
                    )} passes`}
                </strong>
            </Button>
        );
    }
}

OperatorFeedbackProductivityEventButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
    buttonColor: PropTypes.string.isRequired,
    totalLostTime: PropTypes.number,
    totalPasses: PropTypes.number,
    eventIndex: PropTypes.number.isRequired,
};

export default OperatorFeedbackProductivityEventButton;
