import React, { Fragment } from 'react';
import DownIcon from '@ant-design/icons/DownOutlined';
import SaveIcon from '@ant-design/icons/SaveOutlined';

const ExportCSVLabel = () => (
    <Fragment>
        <SaveIcon style={{ marginRight: '0.5rem' }} />
        {'Export CSV'}
        <DownIcon style={{ fontSize: '1rem', marginLeft: '0.5rem' }} />
    </Fragment>
);

ExportCSVLabel.defaultProps = {};

ExportCSVLabel.propTypes = {};

export default ExportCSVLabel;
