import { createSelector } from 'reselect';
import { selectors as minesiteMapSelectors } from '../Modules/minesiteMap';

/**
 * This should return a serialisable object that will be encoded in the url
 * query params
 */
const getSyncStateToURL = createSelector(
    minesiteMapSelectors.get,
    (minesiteMap) => {
        return {
            ...minesiteMap,
        };
    },
);

export default getSyncStateToURL;
