import { createReducer } from '../../Lib/reduxUtils';
import {
    FETCH_HAUL_TRUCK_FILL_FACTOR_ERROR,
    FETCH_HAUL_TRUCK_FILL_FACTOR_SUCCESS,
} from '../Actions';

export const initialState = [];

export function fetchHaulTruckFillFactorSuccess(state, results) {
    return results;
}

export function fetchHaulTruckFillFactorError() {
    return initialState;
}

export default createReducer(initialState, {
    [FETCH_HAUL_TRUCK_FILL_FACTOR_ERROR]: fetchHaulTruckFillFactorError,
    [FETCH_HAUL_TRUCK_FILL_FACTOR_SUCCESS]: fetchHaulTruckFillFactorSuccess,
});
