import * as React from 'react';

function SvgGrader(props) {
    return (
        <svg viewBox="-386.404 -386.404 1024 1024" {...props}>
            <path d="M-236.603 51.943h28.818v-92.118L-246.7-66.881l-16.305 23.761 26.402 18.119z" />
            <path
                fill="#FFF200"
                d="M467.493 197.072H156.27v57.219h35.386v-25.934h270.686z"
            />
            <path d="M149.066 189.867H475.98l-7.523 45.693H198.86v25.934h-49.794v-71.627zm35.386 31.286h271.775l2.778-16.876h-295.53v42.811h20.978v-25.935z" />
            <path
                fill="#FFEC00"
                d="M-379.201 88.03l250.996-75.15.148-117.119h233.123L68.778 69.87h324.406l220.494 88.112v140.735H474.329l7.492-128.234-109.857-41.785H68.778v125.119l-447.979 2.429z"
            />
            <path d="M474.321 175.337L370.64 135.902H75.982v125.08l-462.387 2.508V82.666l251.001-75.152.15-118.957h249.179L77.639 62.666H394.57l226.313 90.436v152.819H466.691l7.63-130.584zm132.153-12.476L391.798 77.074H59.918L96.205-97.035h-217.067l-.146 115.28-250.99 75.146v155.611l433.571-2.353V121.493h311.714l116.033 44.134-7.355 125.886h124.508V162.861z" />
            <circle fill="#FFF" cx={535.917} cy={253.753} r={101.678} />
            <path d="M535.918 200.105c29.582 0 53.649 24.066 53.649 53.648s-24.067 53.649-53.649 53.649c-29.583 0-53.65-24.067-53.65-53.649s24.067-53.648 53.65-53.648m0-48.029c-56.155 0-101.679 45.522-101.679 101.677 0 56.155 45.523 101.678 101.679 101.678 56.154 0 101.678-45.522 101.678-101.678 0-56.154-45.524-101.677-101.678-101.677z" />
            <circle fill="#FFF" cx={-45.255} cy={253.753} r={101.678} />
            <path d="M-45.253 200.105c29.582 0 53.648 24.066 53.648 53.648s-24.066 53.649-53.648 53.649c-29.583 0-53.651-24.067-53.651-53.649s24.067-53.648 53.651-53.648m0-48.029c-56.156 0-101.679 45.523-101.679 101.677 0 56.155 45.523 101.678 101.679 101.678 56.155 0 101.676-45.522 101.676-101.678 0-56.153-45.521-101.677-101.676-101.677z" />
            <g>
                <circle fill="#FFF" cx={-262.382} cy={253.753} r={101.678} />
                <path d="M-262.382 200.105c29.583 0 53.65 24.066 53.65 53.648s-24.067 53.649-53.65 53.649c-29.582 0-53.649-24.067-53.649-53.649s24.067-53.648 53.649-53.648m0-48.029c-56.154 0-101.678 45.523-101.678 101.677 0 56.155 45.523 101.678 101.678 101.678s101.678-45.522 101.678-101.678c0-56.153-45.524-101.677-101.678-101.677z" />
            </g>
            <g>
                <path
                    fill="#FFF"
                    d="M60.875-73.486L34.418 77.315h-132.28V-73.486z"
                />
                <path d="M-83.454-59.079H43.719L22.318 62.906H-83.454V-59.079m-14.408-14.407V77.315h132.28L60.875-73.486H-97.862z" />
            </g>
            <g>
                <path
                    fill="#FFF200"
                    d="M366.625 251.147c-45.525 53.322 37.47 95.284 37.47 95.284H124.769s-60.18-54.752-16.652-95.284h258.508z"
                />
                <path d="M119.92 351.759c-1.413-1.285-34.614-31.886-34.813-66.289-.086-15.009 6.002-28.331 18.1-39.596l2.074-1.933h276.967l-10.144 11.882c-9.894 11.588-13.575 22.868-11.254 34.485 5.73 28.693 46.095 49.489 46.496 49.692l26.966 13.634h-312.33l-2.062-1.875zm226.809-58.585c-2.375-11.834-.349-23.501 6.023-34.823H111.048c-7.803 8.01-11.582 16.866-11.533 26.997.114 23.832 21.541 47.211 28.174 53.879h252.08c-13.458-10.422-29.082-26.329-33.04-46.053z" />
            </g>
        </svg>
    );
}

export default SvgGrader;
