import * as React from 'react';

function SvgDrill(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <g>
                <polygon
                    points="151.11 685.04 44.83 696.4 44.83 786.59 257.39 786.59 278.2 581.6 158.36 575.3 151.11 685.04"
                    fill="#fff200"
                />
                <rect
                    x="646.36"
                    y="638.37"
                    width="333.2"
                    height="140.02"
                    fill="#fff200"
                />
                <polygon
                    points="278.2 581.6 278.2 778.39 414.75 778.39 414.75 80.65 278.2 80.65 278.2 581.6"
                    fill="#fff200"
                />
            </g>
            <path
                d="M990.17,803.39h14.39v-190.02h-383.2v98.12l-181.61-181.61V55.65H253.2v496.63H61.04l-39.3,140.13v62.75h0v48.23h54.06v161.32H247.27v-161.32h130.48c-10.54,16.32-16.66,35.73-16.66,56.55,0,57.77,47.15,104.78,105.1,104.78h435.53c57.95,0,105.1-47,105.1-104.78,0-20.81-6.12-40.22-16.66-56.55Zm-550.42-50v-152.8l152.8,152.8h-152.8Zm231.61-90.02h283.2v90.02h-283.2v-90.02Zm-526.64-59.96v56.64h-57.6l16.56-56.82,41.04,.18Zm52.55,311.31H125.8v-111.32h71.47v111.32Zm-120.42-165.64v-39.03h117.87v-106.42l52.78,.23-.23,145.21H76.84ZM303.2,105.65h86.55V753.39h-86.55V105.65ZM901.72,904.07H466.19c-36.08,0-44.35-32.68-44.35-44.14,0-10.1,6.78-44.14,43.59-44.14h435.53c37.36,0,45.11,32.44,45.11,44.14s-8.49,44.14-44.35,44.14Z"
                fill="#231f20"
            />
        </svg>
    );
}

export default SvgDrill;
