import React from 'react';
import PropTypes from 'prop-types';
import { Col, Typography } from 'antd';
import cn from 'classnames';
import Row from '../Row/Row';

const { Title: AntTitle } = Typography;

const TruckHoldingState = React.forwardRef(
    ({ children, className, ...props }, ref) => (
        <div
            ref={ref}
            className={cn('TruckHoldingState', className)}
            {...props}
        >
            {children}
        </div>
    ),
);

const Title = ({ children, className, ...props }) => (
    <h4 className={cn('TruckHoldingState__Title', className)} {...props}>
        {children}
    </h4>
);

const SubTitle = ({ children, className, ...props }) => (
    <h5 className={cn('TruckHoldingState__SubTitle', className)} {...props}>
        {children}
    </h5>
);

const Container = ({ className, ...props }) => (
    <Row
        className={cn('TruckHoldingState__Container', className)}
        style={{ position: 'relative' }}
        {...props}
    />
);

const ContainerLeft = ({ className, ...props }) => (
    <Col
        className={cn('TruckHoldingState__ContainerLeft', className)}
        span={12}
        {...props}
    />
);

const ContainerRight = ({ isDestination = true, className, ...props }) => (
    <Col
        className={cn('TruckHoldingState__ContainerRight', className, {
            'TruckHoldingState__ContainerRight--grey': isDestination,
        })}
        span={12}
        {...props}
    />
);

TruckHoldingState.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

Title.propTypes = AntTitle.propTypes;

SubTitle.propTypes = {
    children: PropTypes.node,
    ...Col.propTypes,
};

Container.propTypes = Row.propTypes;
ContainerLeft.propTypes = Col.propTypes;
ContainerRight.propTypes = {
    isDestination: PropTypes.bool,
    ...Col.propTypes,
};

TruckHoldingState.Title = Title;
TruckHoldingState.Title.displayName = 'TruckHoldingState.Title';
TruckHoldingState.SubTitle = SubTitle;
TruckHoldingState.SubTitle.displayName = 'TruckHoldingState.SubTitle';
TruckHoldingState.Container = Container;
TruckHoldingState.Container.displayName = 'TruckHoldingState.Container';
TruckHoldingState.ContainerLeft = ContainerLeft;
TruckHoldingState.ContainerLeft.displayName = 'TruckHoldingState.ContainerLeft';
TruckHoldingState.ContainerRight = ContainerRight;
TruckHoldingState.ContainerRight.displayName =
    'TruckHoldingState.ContainerRight';

export default TruckHoldingState;
