import { createSelector } from 'reselect';
import { selectors as mapFeatureSelectors } from '../Modules/mapFeatures';

const getPolygonGeojson = createSelector(
    mapFeatureSelectors.selectMaterialMovementsGeojson,
    mapFeatureSelectors.selectSpeedZonesGeojson,
    (parsedMaterialMovementsGeojson, parsedSpeedZonesGeojson) => {
        return { parsedMaterialMovementsGeojson, parsedSpeedZonesGeojson };
    },
);

export default getPolygonGeojson;
