import { sumNumbers } from '../../utils/mathHelpers';
import { compareString, createMultiKeySortFn } from '../../utils/sortingUtils';
import { objectToArray } from '../../utils/dataUtils';

export const DATA_STATUS = {
    DATA: 'Data',
    NO_DATA: 'No data',
    OFFLINE: 'Offline',
};

/**
 * Totals the truck alerts grouped by shift id
 * @param {array} truckAlerts
 * @return {Object}
 */
export function totalTruckAlertsByShiftId(truckAlerts) {
    return truckAlerts.reduce((collection, d) => {
        if (!collection[d.ShiftId]) {
            collection[d.ShiftId] = {};
        }
        d.AlertsPerTruck.forEach(({ EquipmentSiteName, EventCount }) => {
            collection[d.ShiftId][EquipmentSiteName] =
                (collection[d.ShiftId][EquipmentSiteName] || 0) + EventCount;
        });
        return collection;
    }, {});
}

/**
 * Makes the data set more complete by filling in any missing holes
 * For example data that looks like below for a query
 * all possible shifts = [1,2,3]
 * all possible trucks = [DT01, DT02, DT03]
 *
 * INPUT:
 {
        1: { DT01: 12, DT02: 12 },
        2: { DT02: 5 },
 }
 * OUTPUT:
 {
        1: {
            DT01: { eventCount: 12, status: 'data' },
            DT02: { eventCount: 12, status: 'data' },
            DT03: { eventCount: 0, status: 'no data' }
        },
        2: {
            DT01: { eventCount: 0, status: 'no data' },
            DT02: { eventCount: 5, status: 'data' },
            DT03: { eventCount: 0, status: 'no data' }
        },
        3: {
            DT01: { eventCount: 0, status: 'no data' },
            DT02: { eventCount: 0, status: 'no data' },
            DT03: { eventCount: 0, status: 'no data' }
        },
 }
 *
 * @param {array} allShifts
 * @param {array} allTrucks
 * @param {Object} truckAlertsByShiftId
 * @param {Object} truckUptimesByShiftId
 * @return {Object}
 */
export function padTruckAlertResults(
    allShifts,
    allTrucks,
    truckAlertsByShiftId,
    truckUptimesByShiftId,
) {
    return allShifts.reduce((collection, shiftId) => {
        collection[shiftId] = allTrucks.reduce((trucksForShiftId, truck) => {
            const count =
                truckAlertsByShiftId[shiftId] &&
                truckAlertsByShiftId[shiftId][truck];

            // No alerts for the truck
            if (!Number.isFinite(count)) {
                // Check if truck was off
                const currTruck =
                    truckUptimesByShiftId[shiftId] &&
                    truckUptimesByShiftId[shiftId][truck];
                // No Alerts & no truck uptime, 'No Data'
                if (currTruck === undefined) {
                    trucksForShiftId[truck] = {
                        eventCount: 0,
                        status: DATA_STATUS.NO_DATA,
                    };
                    // Offline trucks have an uptime of zero
                } else if (currTruck.Uptime === 0) {
                    trucksForShiftId[truck] = {
                        eventCount: 0,
                        status: DATA_STATUS.OFFLINE,
                    };
                } else {
                    // Truck was online but didn't have any alerts
                    trucksForShiftId[truck] = {
                        eventCount: 0,
                        status: DATA_STATUS.DATA,
                    };
                }
            } else {
                trucksForShiftId[truck] = {
                    eventCount: count,
                    status: DATA_STATUS.DATA,
                };
            }
            return trucksForShiftId;
        }, {});
        return collection;
    }, {});
}

/**
 * Restructures the data so it's easier to use in the view
 * Transposes the map so it's organised by truck name rather than shiftId the object map so a 'row' is all the shift results for a truck
 * INPUT:
 {
        1: { DT01: 12,   DT02: 12,   DT03: null },
        2: { DT01: null, DT02: 5,    DT03: null },
        3: { DT01: null, DT02: null, DT03: null },
    }
 * OUTPUT:
 {
        DT01: {
            1: { truckName: 'DT01', eventCount: 12, status: 'data' },
            2: { truckName: 'DT01', eventCount: 0, status: 'offline' },
            3: { truckName: 'DT01', eventCount: 0, status: 'offline' },
        },
        DT02: {
            1: { truckName: 'DT02', eventCount: 12, status: 'data' },
            2: { truckName: 'DT02', eventCount: 5, status: 'data' },
            3: { truckName: 'DT02', eventCount: 0, status: 'offline' },
        },
        DT03: {
            1: { truckName: 'DT03', eventCount: 0, status: 'offline' },
            2: { truckName: 'DT03', eventCount: 0, status: 'offline' },
            3: { truckName: 'DT03', eventCount: 0, status: 'offline' },
        },
    }
 *
 * @param {Object} data
 * @return {Object}
 */
export function formatResults(data) {
    return Object.keys(data).reduce((collection, shiftId) => {
        const row = data[shiftId];
        Object.entries(row).forEach(([truckName, { eventCount, status }]) => {
            collection[truckName] = collection[truckName] || {};
            collection[truckName][shiftId] = {
                truckName,
                eventCount,
                status,
            };
        });
        return collection;
    }, {});
}

/**
 * Calculates the totals per shift & overal
 * @param {object} formattedData - has been padded and formatted
 * @return {{totalEvents: number, totalPerShiftId: {}, totalPerTruck: {}}}
 */
export function calculateTotalEvents(formattedData) {
    const totalPerShiftId = objectToArray(formattedData).reduce(
        (collection, truckRow) => {
            Object.entries(truckRow).forEach(([shiftId, row]) => {
                collection[shiftId] =
                    (collection[shiftId] || 0) + (row.eventCount || 0);
            });
            return collection;
        },
        {},
    );

    const totalPerTruck = Object.keys(formattedData).reduce(
        (collection, truckName) => {
            const tableRow = formattedData[truckName];
            const eventCounts = Object.values(tableRow)
                .filter((row) => Number.isFinite(row.eventCount))
                .map((row) => row.eventCount);
            collection[truckName] = sumNumbers(eventCounts);
            return collection;
        },
        {},
    );

    const totalEvents = Object.values(totalPerTruck).reduce((sum, count) => {
        return sum + count;
    }, 0);

    return { totalEvents, totalPerShiftId, totalPerTruck };
}

export const getFormattedTruckAlerts = (
    truckAlerts,
    truckUptimesByShiftId,
    allShiftIds,
    allTrucks,
) => {
    if (!truckAlerts) {
        return {
            data: {},
        };
    }

    const trucksByShiftId = totalTruckAlertsByShiftId(truckAlerts);
    const paddedResults = padTruckAlertResults(
        allShiftIds,
        allTrucks,
        trucksByShiftId,
        truckUptimesByShiftId,
    );
    const formattedResults = formatResults(paddedResults);
    return {
        data: formattedResults,
    };
};

/**
 * Sorts formatted truck data
 * @param {Object} data - The data results from calling getFormattedTruckAlerts()
 * @param {Object} totalPerTruck - The totalsPerTruck from calling calculateTotalEvents()
 * @param {{'truckName'|'total'}} sortBy - The string to sort the data by
 * @param {boolean} ascending - Direction to sort the data
 * @return {*[]|unknown[]|*}
 */
export function sortFormattedTruckAlerts(
    data,
    totalPerTruck,
    sortBy,
    ascending,
) {
    if (sortBy === 'truckName') {
        const sortedKeys = Object.keys(data).sort((a, b) => {
            return ascending ? compareString(b, a) : compareString(a, b);
        });
        return sortedKeys.map((key) => data[key]);
    }
    if (sortBy === 'total') {
        const sortFn = createMultiKeySortFn([
            { name: 'eventCount', reverse: !ascending },
            { name: 'truckName', reverse: !ascending },
        ]);
        const mappedValues = Object.entries(totalPerTruck).map(
            ([key, value]) => {
                return {
                    truckName: key,
                    eventCount: value,
                };
            },
        );
        const sortedKeys = mappedValues.sort(sortFn);
        return sortedKeys.map((row) => data[row.truckName]);
    }
    throw new Error(
        `Invalid sortByKey provided to sortFormattedTruckAlerts(), got: ${sortBy}`,
    );
}
