import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { selectFilteredRows } from '../selectors/selectors';

export type State = {
    selectedClasses: string[];
    selectedSubClasses: string[];
    selectedModels: string[];
};

export type SelectedModels = Array<Row>;
export type SelectedSubClasses = Omit<Row, 'equipmentModel'>;
export type SelectedClasses = string;

export type Row = {
    EquipmentClass: string;
    EquipmentSubClass: string;
    EquipmentModel: string;
};

export type Actions = ReturnType<typeof createCustomSlice>['actions'];

export const createCustomSlice = (name: string) =>
    createSlice({
        name,
        initialState: {
            selectedClasses: [],
            selectedModels: [],
            selectedSubClasses: [],
        } as State,
        reducers: {
            clearAll(state) {
                state.selectedClasses = [];
                state.selectedModels = [];
                state.selectedSubClasses = [];
            },
            setAllFilters(
                state,
                action: PayloadAction<{
                    selectedClasses: string[];
                    selectedSubClasses: string[];
                    selectedModels: string[];
                }>,
            ) {
                state.selectedClasses = action.payload.selectedClasses;
                state.selectedSubClasses = action.payload.selectedSubClasses;
                state.selectedModels = action.payload.selectedModels;
            },
            setSelectedClasses(
                state,
                action: PayloadAction<{
                    allRows: Row[];
                    selectedClasses: Array<string>;
                }>,
            ) {
                state.selectedClasses = action.payload.selectedClasses;
                if (action.payload.selectedClasses.length === 0) {
                    return;
                }

                const filteredRows = selectFilteredRows(
                    state,
                    action.payload.allRows,
                );

                // ensure valid sub classes
                const filteredSubClasses = filteredRows.map(
                    (r) => r.EquipmentSubClass,
                );
                state.selectedSubClasses = state.selectedSubClasses.filter(
                    (m) => filteredSubClasses.includes(m),
                );

                // ensure valid models
                const filteredModels = filteredRows.map(
                    (r) => r.EquipmentModel,
                );
                state.selectedModels = state.selectedModels.filter((m) =>
                    filteredModels.includes(m),
                );
            },
            setSelectedSubClasses(
                state,
                action: PayloadAction<{
                    allRows: Row[];
                    selectedSubClasses: Array<string>;
                }>,
            ) {
                state.selectedSubClasses = action.payload.selectedSubClasses;
                if (action.payload.selectedSubClasses.length === 0) {
                    return;
                }

                // ensure valid models
                const filteredRows = selectFilteredRows(
                    state,
                    action.payload.allRows,
                );
                const filteredModels = filteredRows.map(
                    (r) => r.EquipmentModel,
                );
                state.selectedModels = state.selectedModels.filter((m) =>
                    filteredModels.includes(m),
                );
            },
            setSelectedModels(
                state,
                action: PayloadAction<{
                    allRows: Row[];
                    selectedModels: Array<string>;
                }>,
            ) {
                state.selectedModels = action.payload.selectedModels;
            },
        },
    });
