import React from 'react';
import PropTypes from 'prop-types';

const SpeedLimitedZonesLegend = (props) => {
    const { legendItems } = props;
    if (!legendItems) return null;
    function renderLegendItems() {
        return legendItems.map((item, index) => (
            <li key={index}>
                <i className="fa fa-circle" style={{ color: item.color }} />
                &nbsp;
                {item.limit} kph zone
            </li>
        ));
    }

    return <ul className="SpeedLimitedZonesLegend">{renderLegendItems()}</ul>;
};

SpeedLimitedZonesLegend.propTypes = {
    legendItems: PropTypes.arrayOf(
        PropTypes.shape({
            color: PropTypes.string.isRequired,
            limit: PropTypes.string.isRequired,
        }),
    ),
};

export default SpeedLimitedZonesLegend;
