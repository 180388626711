import { createReducer } from '../../Lib/reduxUtils';

export const initialState = {
    center: undefined,
    zoom: 13,
};

export function viewportChanged(state: any, viewport: any) {
    return {
        ...state,
        center: viewport.center,
        zoom: viewport.zoom,
    };
}

export const syncURLToState = (state: any, params: Object) => {
    // Filter out the keys that this part of state doesn't care about
    const validKeys = Object.keys(state);
    return Object.entries(params)
        .filter(
            ([key, value]) =>
                validKeys.find((vk) => vk === key) && value !== undefined,
        )
        .reduce(
            (agg, [key, value]) => {
                // Format into the correct types
                switch (key) {
                    case 'center':
                        agg.center = value
                            .split(',')
                            .map((v: any) => parseFloat(v));
                        break;
                    case 'zoom':
                        agg.zoom = Math.round(value * 10) / 10;
                        break;
                    default:
                        agg[key] = Boolean(value);
                }
                return agg;
            },
            { ...state },
        );
};

export function setMapFilterUpdated(
    state: any,
    { filterName, filterValue }: any,
) {
    return {
        ...state,
        [filterName]: filterValue,
    };
}

export default function createReducers(
    actions: any,
    customActions = {},
    customInitialState: any,
) {
    return createReducer(
        {
            ...initialState,
            ...customInitialState,
        },
        {
            [actions.mapFilterUpdated]: setMapFilterUpdated,
            [actions.mapViewportChanged]: viewportChanged,
            /**
             * Ensure any custom action handlers pass along state + any additional arguments needed for the
             * action handlers
             */
            ...customActions,
        },
    );
}
