import createActions from '../../Modules/circuitNodes/actions';
import createReducer from '../../Modules/circuitNodes/reducer';
import createSelectors from '../../Modules/circuitNodes/selectors';
import namespace from './namespace';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].circuitNodes;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);
export const reducer = createReducer({
    onRequest: actions.GET_CIRCUIT_NODES_REQUESTED,
    onSuccess: actions.GET_CIRCUIT_NODES_SUCCESS,
    onError: actions.GET_CIRCUIT_NODES_ERROR,
});
export const selectors = createSelectors(statePath);
