import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class IFrame extends PureComponent {
    static propTypes = {
        src: PropTypes.string.isRequired,
        onLoad: PropTypes.func,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        style: PropTypes.shape({}),
        frameBorder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string.isRequired,
        scrolling: PropTypes.string,
        className: PropTypes.string,
    };

    static defaultProps = {
        width: '100%',
        height: '100%',
        frameBorder: 0,
        title: 'iframe-title',
    };

    render() {
        const {
            width,
            height,
            style,
            frameBorder,
            title,
            src,
            scrolling,
            onLoad,
            className,
        } = this.props;

        return (
            <iframe
                src={src}
                title={title}
                frameBorder={frameBorder}
                width={width}
                height={height}
                style={style}
                scrolling={scrolling}
                onLoad={onLoad}
                className={className}
            />
        );
    }
}

export default IFrame;
