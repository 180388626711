import { combineReducers } from 'redux';
import filters from './filters';
import spinner from './spinner';
import haulTruckFillFactor from './haulTruckFillFactor';

export const REDUCER_KEY = 'HaulTruckFillFactor';
export default combineReducers({
    filters,
    spinner,
    haulTruckFillFactor,
});
