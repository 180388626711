export default function createSelectors(statePath: any) {
    function getAll(state: any) {
        const slice = statePath(state);
        return {
            ...slice,
        };
    }

    function getMode(state: any) {
        const slice = getAll(state);
        return slice.mode;
    }

    function getStartTime(state: any) {
        const slice = getAll(state);
        return slice.startTime;
    }

    function getEndTime(state: any) {
        const slice = getAll(state);
        return slice.endTime;
    }

    function getTimezone(state: any) {
        const slice = getAll(state);
        return slice.timezone;
    }

    function getPreviousState(state: any) {
        const slice = getAll(state);
        return slice.previousState;
    }

    return {
        getAll,
        getMode,
        getStartTime,
        getEndTime,
        getTimezone,
        getPreviousState,
    };
}
