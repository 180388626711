import createActions from '../../Modules/datePicker/actions';
import createReducer from '../../Modules/datePicker/reducer';
import createSelectors from '../../Modules/datePicker/selectors';
import namespace from './namespace';
/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state: any) => state[namespace].datePicker;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);

export const reducer = createReducer(
    {
        modeChanged: actions.MODE_CHANGED,
        dateTimeChanged: actions.DATE_TIME_CHANGED,
        syncURLToState: actions.SYNC_URL_TO_STATE,
    },
    {},
    {},
);

export const selectors = createSelectors(statePath);
