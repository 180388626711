import { action } from '../../../Lib/reduxUtils';
import namespace from '../namespace';

function createActions(ns) {
    const ON_FIELD_CHANGE = `${ns}/ON_FIELD_CHANGE`;
    const onFieldChange = (fieldName, fieldValue) =>
        action(`${ns}/ON_FIELD_CHANGE`, { fieldName, fieldValue });

    const RESET_FORM = `${ns}/RESET_FORM`;
    const resetForm = () => action(`${ns}/RESET_FORM`);

    const CREATE_CIRCUIT = `${ns}/CREATE_CIRCUIT`;
    const createCircuit = (circuitId) =>
        action(`${ns}/CREATE_CIRCUIT`, { circuitId });

    const CUSTOMISE_CIRCUIT = `${ns}/CUSTOMISE_CIRCUIT`;
    const customiseCircuit = (isEnabled) =>
        action(`${ns}/CUSTOMISE_CIRCUIT`, { isEnabled });

    const SUBMIT_CIRCUIT = `${ns}/SUBMIT_CIRCUIT`;
    const submitCircuit = () => action(`${ns}/SUBMIT_CIRCUIT`);

    const EDIT_CIRCUIT = `${ns}/EDIT_CIRCUIT`;
    const editCircuit = (circuitId) =>
        action(`${ns}/EDIT_CIRCUIT`, { circuitId });

    return {
        ON_FIELD_CHANGE,
        onFieldChange,
        RESET_FORM,
        resetForm,
        CREATE_CIRCUIT,
        createCircuit,
        CUSTOMISE_CIRCUIT,
        customiseCircuit,
        SUBMIT_CIRCUIT,
        submitCircuit,
        EDIT_CIRCUIT,
        editCircuit,
    };
}

export default createActions(namespace);
