import { createSelector } from 'reselect';
import { selectors as spinnerSelectors } from '../Modules/spinner';

const getSpinnerstate = createSelector(
    spinnerSelectors.getSpinnerState,
    (spinner) => {
        return spinner;
    },
);

export default getSpinnerstate;
