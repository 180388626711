import * as React from 'react';

function SvgLoadUnitSymbolPayloadInconsistent(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <path
                d="M964.923 827.308h39.385v-275.91h-39.385v275.91zM807.385 709.045v118.263h39.384V709.045h-39.384zM649.846 196.692v630.616h39.385V196.692h-39.385zM492.308 709.045v118.263h39.384V709.045h-39.384zM334.769 196.692v630.616h39.385V196.692h-39.385zM177.231 709.045v118.263h39.384V709.045h-39.384zM19.692 827.308h39.385v-275.91H19.692v275.91z"
                stroke="#000"
                strokeWidth={39.385}
                fill="#FFF"
                fillRule="nonzero"
            />
        </svg>
    );
}

export default SvgLoadUnitSymbolPayloadInconsistent;
