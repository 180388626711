import { createReducer } from '../../Lib/reduxUtils';

const initialState = {
    featureId: null,
};

// it set the latest meta data of the component mounted
export function setFeatureId(state, featureId) {
    return {
        ...state,
        featureId,
    };
}

export function unsetFeatureId(state, originFeatureId) {
    // if the component still remain on the same page then reset it to null
    if (state.featureId !== null && state.featureId === originFeatureId) {
        return {
            ...state,
            unsetFeatureId: null,
        };
    }
}

/**
 * Creates a reducer for this module. It has some default behaviour but also allows custom actions to be supplied
 * @param {object} actions {<internalActionName>: <ACTION_TYPE>} maps an internalActionName to an ACTION_TYPE. The handler for this ACTION_TYPE is the default behaviour
 * @param {object} customActions {<ACTION_TYPE>: actionHandler} maps an ACTION_TYPE to an action handler
 * @return {function(*=, {type: *, payload?: *})}
 */
export default function createReducers(actions, customActions = {}) {
    return createReducer(initialState, {
        [actions.setFeatureId]: setFeatureId,
        [actions.unsetFeatureId]: unsetFeatureId,
        /**
         * Ensure any custom action handlers pass along state + any additional arguments needed for the
         * action handlers
         */
        ...customActions,
    });
}
