import { createSelector } from 'reselect';
import { getAverageOfArray } from '@rs/core/utils/mathHelpers';
import calculateClassificationPercents from './utils/calculateClassificationPercents';
import shiftHasData from './utils/shiftHasData';
import filterByPlotOption from './utils/filterByPlotOption';
import getChildrenOfNode from '../../Modules/minesiteAreasTree/utils/getChildrenOfNode';
import flattenTree from '../../Modules/minesiteAreasTree/utils/flattenTree';

// TODO not heaps happy with this, I don't want to tie modules together like this but would like to reuse the selector
export function makeGetClassificationPercentForAllShifts(
    getHaulRoadConditionSummary,
    getFilters,
    getPlotDisplayOptions,
    getMinesiteAreasTree,
) {
    return createSelector(
        getHaulRoadConditionSummary,
        getFilters,
        getPlotDisplayOptions,
        getMinesiteAreasTree,
        (
            haulRoadConditionSummary,
            filters,
            plotDisplayOptions,
            minesiteTree,
        ) => {
            let validMinesiteTreeIds = flattenTree(
                minesiteTree,
                (d) => d.WhereOnMinesiteId,
            );
            if (filters.WhereOnMinesiteId) {
                validMinesiteTreeIds = getChildrenOfNode(
                    filters.WhereOnMinesiteId.split('|'),
                    minesiteTree,
                );
            }

            const totalsPerShift = haulRoadConditionSummary.shiftIdAscending
                .map((shiftId) => {
                    const classifications =
                        haulRoadConditionSummary.byShiftId[shiftId];
                    if (!classifications) return null;
                    const filteredClassifications = classifications.filter(
                        (classification) =>
                            validMinesiteTreeIds.indexOf(
                                classification.WhereOnMinesiteId,
                            ) > -1 &&
                            filterByPlotOption(
                                plotDisplayOptions,
                                classification.MeasurementType,
                            ),
                    );

                    return {
                        shiftId,
                        ...calculateClassificationPercents(
                            filteredClassifications,
                        ),
                    };
                })
                .filter((value) => value !== null);

            return totalsPerShift;
        },
    );
}

export function makeGetClassificationPercentForLastShift(
    getClassificationPercentForLastXShifts,
) {
    return createSelector(
        getClassificationPercentForLastXShifts,
        (lastXShiftsClassifications) => {
            const shiftsWithData = lastXShiftsClassifications.filter(
                shiftHasData,
            );

            if (!shiftsWithData.length) {
                return {
                    shiftId: '',
                    totals: {},
                    lastXShiftsAverage: {},
                };
            }

            const lastShiftWithData = shiftsWithData[shiftsWithData.length - 1];
            // The 6 shifts prior to the last shift
            const lastXShifts = shiftsWithData.slice(-7, -1);
            const lastXShiftsByClassification = lastXShifts.reduce(
                (collection, shiftResult) => {
                    const { shiftId, ...classifications } = shiftResult;
                    Object.keys(classifications).forEach((classification) => {
                        collection[classification] =
                            collection[classification] || [];
                        collection[classification].push(
                            shiftResult[classification],
                        );
                    });
                    return collection;
                },
                {},
            );

            const averageLastXShiftsClassifications = Object.keys(
                lastXShiftsByClassification,
            ).reduce((avg, classification) => {
                const results = lastXShiftsByClassification[classification];
                avg[classification] = getAverageOfArray(
                    results,
                    results.length,
                );
                return avg;
            }, {});

            const { shiftId, ...totals } = lastShiftWithData;
            return {
                shiftId,
                totals,
                lastXShiftsAverage: averageLastXShiftsClassifications,
            };
        },
    );
}

export default function createSelectors(statePath) {
    function getHaulRoadConditionSummary(state) {
        return statePath(state);
    }

    function getHaulRoadConditionSummaryByShiftId(state, shiftId) {
        const localState = getHaulRoadConditionSummary(state);
        return localState.byShiftId[shiftId] || [];
    }

    return {
        getHaulRoadConditionSummary,
        getHaulRoadConditionSummaryByShiftId,
    };
}
