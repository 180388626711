import { roundTo } from './mathHelpers';

/**
 * Convert seconds to a friendlier display format, eg. hh:mm:ss
 * @param {number} totalSeconds - the total seconds to convert
 * @param {string} formatString - the output format for the string
 * @returns {string}
 **/
export default function secondsToFormattedString(
    totalSeconds,
    formatString = 'hh:mm:ss',
) {
    if (!Number.isFinite(totalSeconds)) {
        return '';
    }
    const roundedTotalSeconds = roundTo(totalSeconds, 0);

    const hours = formatString.includes('hh')
        ? Math.floor(roundedTotalSeconds / 3600)
        : 0;

    const minutes = formatString.includes('mm')
        ? Math.floor((roundedTotalSeconds - hours * 3600) / 60)
        : 0;

    const seconds = formatString.includes('ss')
        ? roundedTotalSeconds - hours * 3600 - minutes * 60
        : 0;

    const results = {
        hh: pad(hours, 10),
        mm: pad(minutes, 10),
        ss: pad(seconds, 10),
    };

    return formatString.replace(/hh|mm|ss/gi, (matched) => results[matched]);

    function pad(value, minValue) {
        if (value < minValue) {
            return `0${value}`;
        }
        return value.toString();
    }
}
