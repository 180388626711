import * as React from 'react';

function SvgFloatTruck(props) {
    return (
        <svg viewBox="0 0 545 300" {...props}>
            <g>
                <g stroke="null">
                    <g stroke="null">
                        <path
                            stroke="null"
                            fill="#fff200"
                            d="m36.34704,119.26955l-29.37375,0l0,148.82106l106.62358,0l0,-36.38196c0,-62.10199 -34.58643,-112.4391 -77.24446,-112.4391l-0.00537,0z"
                        />
                        <rect
                            stroke="null"
                            fill="#fff200"
                            height="148.78977"
                            width="217.56778"
                            y="119.26955"
                            x="303.84374"
                        />
                        <rect
                            stroke="null"
                            fill="#fff200"
                            height="78.32564"
                            width="176.36071"
                            y="164.31872"
                            x="114.60179"
                        />
                    </g>
                    <g stroke="null">
                        <circle
                            stroke="null"
                            r="47.42468"
                            cy="227.46109"
                            cx="49.67969"
                        />
                        <circle
                            stroke="null"
                            r="47.42467"
                            cy="227.46109"
                            cx="359.86667"
                        />
                        <circle
                            stroke="null"
                            r="47.42467"
                            cy="227.46109"
                            cx="464.39443"
                        />
                        <path
                            stroke="null"
                            d="m506.9826,91.92246l-37.85914,-93.35246l-88.06213,0l0,93.35246l-27.18657,0c-27.42302,0 -51.50885,21.15176 -64.97585,52.84028l-177.56446,0c-13.96677,-22.12956 -34.00068,-36.02213 -56.20564,-36.02213l-48.15553,0l0,39.11197l48.15553,0c27.24031,0 49.43452,32.25956 49.48289,71.93474l0.1021,48.3424l199.68344,0l0,-65.05886c0,-39.7143 22.19421,-72.02861 49.48289,-72.02861l163.99536,0l0,136.25047l26.86951,0l0,-175.36245l-37.75703,0l-0.00537,-0.00782zm-229.46023,111.14059l0,25.94688l-146.02503,0l-0.0215,-9.32429c-0.01612,-12.52365 -1.4617,-24.57014 -4.11641,-35.81092l151.30758,0c-0.74697,6.23445 -1.14464,12.64881 -1.14464,19.18833zm130.40847,-111.14059l0,-54.24049l45.8555,0l21.99538,54.24049l-67.85088,0z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgFloatTruck;
