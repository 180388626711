import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_GROUPS_REQUESTED = `${namespace}/FETCH_GROUPS_REQUESTED`;
    const fetchGroupsRequested = () => action(FETCH_GROUPS_REQUESTED);

    const FETCH_GROUPS_SUCCESS = `${namespace}/FETCH_GROUPS_SUCCESS`;
    const fetchGroupsSuccess = (resultsArray) =>
        action(FETCH_GROUPS_SUCCESS, resultsArray);

    const FETCH_GROUPS_ERROR = `${namespace}/FETCH_GROUPS_ERROR`;
    const fetchGroupsError = ({ error }) =>
        action(FETCH_GROUPS_ERROR, { error });

    return {
        FETCH_GROUPS_SUCCESS,
        fetchGroupsSuccess,
        FETCH_GROUPS_ERROR,
        fetchGroupsError,
        FETCH_GROUPS_REQUESTED,
        fetchGroupsRequested,
    };
}
