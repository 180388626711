import {
    request,
    BASE_URL,
    HEADERS,
    METHODS,
    checkRequestsForErrors,
    REQUEST_OBJECT_KEY,
} from './Api';

const RESOURCE_PATHS = {
    DRIVERS: '/drivers',
    CYCLE_SUMMARIES_PAYLOAD: '/cyclesummaries/payload',
    DRIVERSHIFTPRODUCTIVITYEVENTS_UNIQUEDRIVERS:
        '/drivershiftproductivityevents/uniqueDrivers',
    DRIVERSHIFTPRODUCTIVITYEVENTS: '/drivershiftproductivityevents',
    SHIFTPRODUCTIVITYEVENTS: '/shiftproductivityevents',
    REPORTS_CORNERLEAGUE: '/reports/cornerleague',
    REPORTS_WORSTCORNERS: '/reports/worstcorners',
    REPORTS_CONFORMANCE: '/reports/conformance',
    REPORTS_CORNERLEAGUE_PDF: '/reports/cornerleague/pdf',
    WKTSHIFTAGGREGATELOCUSES: '/wktshiftaggregatelocuses',
    CORNEREVENTS: '/cornerevents',
    MAPLABELS_ALL: '/maplabels/all',
    SPEEDLIMITEDZONES: '/speedlimitedzones',
    SPEEDINGEVENTS: '/speedingevents',
    RAMP_EVENTS: '/rampevents',
    PRODUCTIVITY_LIST: '/productivity/list', // /:ShiftId/Productivity/:DriverId
    S3_LIST: '/s3/list', // /:ShiftId/:Productivity/:DriverId'
    S3_DOWNLOAD: '/s3/download', // /:S3Path,
    TRUCKPOWERPERFORMANCESFROMPOWERCURVES:
        '/truckpowerperformancesfrompowercurves',
    O4ROPERATOR: '/o4roperator',
    AUTHENTICATE: '/authenticate',
    REFRESH_TOKEN: '/authenticate/v2/authenticate/refresh-token',
    AUTHENTICATE_METHOD: '/authenticate/method',
    AUTHENTICATE_FEDERATEDTOKEN: '/authenticate/federated-token',
    LOADUNITOPERATORLOGS: '/loadunitoperatorlogs',
    LOADINGEVENTS: '/loadingevents',
    LOADINGEVENTS_CONFORMANCEREPORT: '/loadingevents/conformancereport',
    LOADINGEVENTS_CONFORMANCESYMBOLOGYREPORT:
        '/loadingevents/conformancesymbologyreport',
    PRODUCTIVITY_DOWNLOADS_BATCH: '/productivity/download/batch',
    LOADINGEVENTS_BIASLOADREPORT: '/loadingevents/biasloadreport',
    REPORTS_CYCLESUMMARIES_HAULTRUCKFILLFACTOR:
        '/reports/cyclesummaries/haultruckfillfactor',
    DISCOVERY_SITECONFIGURATION: '/discovery/public/site-configuration.json',
    CLIENTREPORTS_MAIN_CYCLESUMMARIES: '/client-reports/main/cyclesummaries',
    CLIENTREPORTS_MAIN_TIMINGSUMMARY: '/client-reports/main/timingsummary',
    S3SHIFTKEYPATHS_LATEST: '/s3shiftkeypaths/latest',
    HAULROADCONDITIONSUMMARY: '/haulroadconditionsummary',
    LOADINGEVENTOPERATORTARGETS: '/loadingeventoperatortargets',
    TRUCK_ALERT_EVENTS: '/truckalertevents',
    HAULROADCONDITIONSUMMARYLATEST: '/haulroadconditionsummary/latest',
    REPORTS_CYCLESUMMARIES_HAULTRUCKFILLFACTORPAYLOAD:
        '/reports/cyclesummaries/haultruckfillfactorpayload',
    MINESITEAREAS_TREE: '/minesiteareas/tree',
    HAULROADCONDITIONEVENTS: '/haulroadconditionevents',
    TRUCKALERTEVENTS: '/truckalertevents',
    TRUCKALERTEVENTS_BYTRUCKNAME: '/truckalertevents/bytruckname',
    TRUCKALERTEVENTS_BYSHIFTID: '/truckalertevents/byshiftid',
    TIMING_EQUIPMENT_UPTIME: '/timing/equipmentuptime',
    CONDITIONALLIMITEVENTS: '/conditionallimitevents',
    WIKI_BYCONTENTID: '/wiki/bycontentid',
    CLIENTREPORTS_MAIN_SPEEDINGEVENTS: '/client-reports/main/speedingevents',
    CLIENTREPORTS_MAIN_RAMPEVENTS: '/client-reports/main/rampevents',
    CLIENTREPORTS_MAIN_CORNEREVENTS: '/client-reports/main/cornerevents',
    CLIENTREPORTS_MAIN_EQUIPMENT_ALERTS: '/client-reports/main/equipmentalerts',
    CLIENTREPORTS_MAIN_TRUCKALERTEVENTS:
        '/client-reports/main/truckalertevents',
    CLIENTREPORTS_MAIN_HAULTRUCKFILLFACTOR:
        '/client-reports/main/haultruckfillfactor',
    CLIENTREPORTS_MAIN_HAULROADCONDITIONEVENTS:
        '/client-reports/main/haulroadconditionevents',
    CLIENTREPORTS_MAIN_QUEUEING: '/client-reports/main/queuing',
    CLIENTREPORTS_MAIN_IDLE_TIME: '/client-reports/main/idletimedetails',
    CLIENTREPORTS_MAIN_ACTIVITY_DETAIL: '/client-reports/main/activitydetail',
    CLIENTREPORTS_MAIN_SHIFT_ACTIVITY_SUMMARY:
        '/client-reports/main/shiftactivitysummary',
    CLIENTREPORTS_MAIN_OPERATORS_IDENTIFICATION_LOGS:
        '/client-reports/main/operatoridentificationlogs',
    CLIENTREPORTS_MAIN_RAMP_EVENT_SUMMARIES:
        '/client-reports/main/rampeventsummaries',
    CLIENTREPORTS_MAIN_RAMP_EVENT_DETAILS:
        '/client-reports/main/rampeventdetails',
    CLIENTREPORTS_MAIN_HAUL_TRUCK_OPERATOR_LEAGUE:
        '/client-reports/main/haultruckoperatorleague',
    CLIENTREPORTS_MAIN_LOAD_UNIT_OPERATOR_LEAGUE:
        '/client-reports/main/loadunitoperatorleague',
    SITE_CONFIGURATION_SUMMARY: '/site-configuration',
    CREATE_USER: '/createuser',
    EDIT_USER: '/updateuser',
    DELETE_USER: '/deleteuser',
    FETCH_USER: '/authenticate/v2/usersdetails',
    IMPERSONATE_USER: '/authenticate/v2/authenticate/impersonate',
    FETCH_PROFILES: '/usersdetails/profiles',
    CREATE_GROUP: '/creategroup',
    EDIT_GROUP: '/updategroup',
    FETCH_GROUP: '/groupsdetails/all',
    DELETE_GROUP: '/deletegroup',
    FETCH_FEATURE: '/features/all',
    ASSIGN_GROUP: '/assigngroup',
    AUTHENTICATE_RESET_PASSWORD: '/authenticate/resetpassword',
    CLIENTREPORTS_MAIN_EQUIPMENT_ACTIVITY_BASIC:
        '/client-reports/main/equipmentactivitybasic',
    EQUIPMENT_LATENCY: '/regular-status/equipment-latency',
    EQUIPMENT_LATENCY_HISTORIC: '/regular-status/equipment-latency/historic',
    FMS_TRUCK_ALLOCATION_MINESITE: '/fms/truck-allocation/minesite',
    FMS_TRUCK_ALLOCATION: '/fms/truck-allocation',
    FMS_CIRCUIT_SUGGEST: '/fms/circuit/suggest',
    FMS_CIRCUIT_NODES: '/fms/circuit/nodes',
    FMS_TRUCK_ALLOCATION_EQUIPMENT: '/fms/truck-allocation/equipment',
    SHIFT_LOGGER_DEPLOYMENTS: '/equipmentidentifiers',
    ENGINE_DIAGNOSTIC_CURVES: '/enginediagnosticcurves/byequipmentname',
    ENGINE_DIAGNOSTIC_POWER_CURVE_METRICS:
        '/enginediagnosticpowercurvemetrics/byequipmentname',
    PARAMETER_CHECKS: '/parameterchecks',
    EQUIPMENT_OPERATOR_ALERT_EVENTS: '/equipmentoperatoralertevents',
    LOAD_UNIT_CONFORMANCE_WEIGHTINGS: '/loadunitconformanceweightings',
    LOADING_EVENTS_AGGREGATE: '/aggregateloadingevents',
    LOADING_EVENTS_HISTOGRAM: '/histogramloadingevents',
    NETWORK_COVERAGE_REPORT:
        '/custombase/spatial/absolute/metric/network_coverage',
    EQUIPMENT_ALERTS: '/equipmentalerts',
    POWER_BI_REPORT: '/powerbi/reports',
    USER_UPLOAD_FILES: '/user/file',
    TIME_ACCOUNTING_ACTIVITIES: '/client-reports/live/v1/time-accounting',
};

// NOTE: If this is updated, also update utils.js with new delimiter
const API_ARRAY_DELIMITER = '|';

export {
    request,
    BASE_URL,
    HEADERS,
    METHODS,
    REQUEST_OBJECT_KEY,
    RESOURCE_PATHS,
    checkRequestsForErrors,
    API_ARRAY_DELIMITER,
};
