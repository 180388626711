import * as React from 'react';

function SvgWaterTruck(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <rect
                x={152.74}
                y={92.27}
                width={718.53}
                height={698.16}
                rx={86.82}
                fill="#fff200"
            />
            <path d="M603.66 610.5c-47.92 56.76-135.4 56.76-183.32 0a120 120 0 01-22.55-114.08l108-262.29a6.47 6.47 0 0112.32 0l108.1 262.29a120 120 0 01-22.55 114.08z" />
            <path d="M784.44 67.27H239.56a111.94 111.94 0 00-111.82 111.82v524.52a111.94 111.94 0 00111.82 111.82h59.19a110.1 110.1 0 10147 0h132.51a110.1 110.1 0 10147 0h59.19a111.94 111.94 0 00111.81-111.82V179.09A111.94 111.94 0 00784.44 67.27zM372.25 940a42.55 42.55 0 1142.55-42.6 42.55 42.55 0 01-42.55 42.6zm279.5 0a42.55 42.55 0 1142.55-42.6 42.55 42.55 0 01-42.55 42.6zm194.51-236.39a61.89 61.89 0 01-61.82 61.82H239.56a61.89 61.89 0 01-61.82-61.82V179.09a61.89 61.89 0 0161.82-61.82h544.88a61.89 61.89 0 0161.82 61.82z" />
        </svg>
    );
}

export default SvgWaterTruck;
