/**
 * Adds an extension to a filename
 * @param {string} reportName The name of the report, eg. CycleSummaries
 * @param {string} fileExtension The file type, eg. csv
 * @return {string}
 */
export function addExtensionToFilename(reportName = 'MaxMine', fileExtension) {
    return `${reportName}.${fileExtension.replace(/\./g, '')}`;
}

/**
 * Downloads a file
 * @param data {Blob} - the binary data
 * @param filename {string} - The complete filename including extension
 */
export default function downloadFileFromBlob(data, filename) {
    let blob = data;
    if (!(data instanceof Blob)) {
        blob = new Blob([data]);
    }
    blob.name = filename;
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE version
        window.navigator.msSaveBlob(blob, filename);
    } else {
        // Modern browsers
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        link.parentNode.removeChild(link);
    }
}
