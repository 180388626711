import shiftUtils from '@rs/core/utils/shiftUtils';
import { createSelector } from 'reselect';
import createActions from '../../Modules/filters/actions';
import createReducer from '../../Modules/filters/reducer';
import createSelectors from '../../Modules/filters/selectors';
import namespace from './namespace';
import { FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS } from '../../App/Actions';
import { filterShiftOptionsToReactSelectFormat } from '../../Utils/Filters/filterOptionsToReactSelectFormat';
import { selectors as shiftLoggerDeploymentSelectors } from './shiftLoggerDeployments';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].filters;

const initialState = {
    Date: undefined,
    Shift: '',
    Equipment: '',
};

const fetchPrivateSiteConfigurationSuccess = (state) => {
    const defaultDate = shiftUtils.newDefaultFilterDate();
    const firstShift = shiftUtils.getFirstShiftFromDate(defaultDate);
    return {
        ...state,
        Date: defaultDate,
        Shift: firstShift.shortName,
    };
};

/**
 * Create the module parts
 */
export const actions = createActions(namespace);
export const reducer = createReducer(
    {
        filterUpdated: actions.FILTER_UPDATED,
        setFiltersWithUrlParams: actions.SET_FILTERS_WITH_URL_PARAMS,
    },
    {
        [FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS]: fetchPrivateSiteConfigurationSuccess,
    },
    initialState,
);

const defaultSelectors = createSelectors(statePath);
const getFilterOptions = createSelector(
    defaultSelectors.getFilters,
    shiftLoggerDeploymentSelectors.getAll,
    (filters, loggerEquipment) => {
        const shifts = shiftUtils.getAllShiftShortNamesForDate(filters.Date);

        const equipmentOptions = loggerEquipment
            .map((item) => ({
                label: item.EquipmentSiteName,
                value: `${item.EquipmentSiteName.toString()}`,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));

        return {
            equipment: equipmentOptions,
            shifts: filterShiftOptionsToReactSelectFormat(
                shifts.rawShiftsInDay,
                shifts.shiftShortNames,
            ),
        };
    },
);

export const selectors = {
    ...defaultSelectors,
    getFilterOptions,
};
