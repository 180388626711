import { createSelector } from 'reselect';
import * as networkCoverageReportModule from '../Modules/networkCoverageReport';
import * as datePickerReport from '../Modules/datePicker';

const getPreviousApiParams = createSelector(
    networkCoverageReportModule.selectors.getPreviousState,
    datePickerReport.selectors.getPreviousState,
    (netWorkCoverageState, datePickerState) => {
        const { h3Resolution } = { ...netWorkCoverageState };
        const { startTime, endTime } = { ...datePickerState };
        // map the api params if necessary
        return {
            //network coverage state
            StartTime: startTime,
            EndTime: endTime,
            H3Resolution: h3Resolution,
        };
    },
);
export default getPreviousApiParams;
