import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '@rs/core/falcon';
import { Select } from '../../Components/Select';
import { DatepickerInputWithSiteConfig } from '../../Components/Datepicker';
import { FEATURE_IDS } from '../../Auth/featureIds';
import { HasFeature } from '../../Components/HasFeature';

// TODO I've enabled linting again in this file, but I don't want to change these things as
// part the styling changes. Anyone who sees this in the future can tidy up this error.
// react/no-multi-comp react/prop-types
/* eslint-disable */
class PrintLeagueFilter extends React.Component {
    render() {
        const { filters } = this.props;
        return (
            <Row className="whenPrintingShow">
                <Col span={24} style={{ paddingTop: 10 }}>
                    <span>{`Date Start: ${filters.Date}`}</span>
                </Col>
                <Col span={24} style={{ paddingTop: 10 }}>
                    <span>
                        {filters.EndDate ? `Date End: ${filters.EndDate}` : ''}
                    </span>
                </Col>
                <Col span={24} style={{ paddingTop: 10 }}>
                    <span>
                        {filters.Loader ? `Loader: ${filters.Loader}` : ''}
                    </span>
                </Col>
                <Col span={24} style={{ paddingTop: 10 }}>
                    <span>
                        {filters.Operator
                            ? `Operator: ${filters.Operator}`
                            : ''}
                    </span>
                </Col>
                <Col span={24} style={{ paddingTop: 10 }}>
                    <span>
                        {filters.Shift ? `Shift: ${filters.Shift}` : ''}
                    </span>
                </Col>
            </Row>
        );
    }
}

class LeagueTableFilters extends Component {
    constructor(props) {
        super(props);

        this.handleFilterChangeShift = this.props.onFilterChange.bind(
            null,
            'Shift',
        );
        this.handleFilterChangeWorker = this.props.onFilterChange.bind(
            null,
            'Worker',
        );
        this.handleFilterChangeCrew = this.props.onFilterChange.bind(
            null,
            'Crew',
        );
        this.handleFilterChangeArea = this.props.onFilterChange.bind(
            null,
            'Area',
        );
        this.handleFilterChangeActivity = this.props.onFilterChange.bind(
            null,
            'Activity',
        );
        this.handleFilterChangeMaterial = this.props.onFilterChange.bind(
            null,
            'Material',
        );
    }

    render() {
        const {
            handleFilterChangeCrew,
            handleFilterChangeShift,
            handleFilterChangeWorker,
        } = this;
        const { filters, filterOptions, onDateFilterChange } = this.props;

        const crewValues = filters.Crew;
        const crewOptions = filterOptions.crews;

        const driverValues = filters.Worker;
        const driverOptions = filterOptions.drivers;

        const shiftValues = filters.Shift;
        const shiftOptions = filterOptions.shifts;

        const hasAreaFilter = filterOptions.areas?.length > 0;

        return (
            <Row className="whenPrintingHide">
                <PrintLeagueFilter filters={filters} />
                <Col xl={24} className="whenPrintingHide">
                    <Row>
                        <Col xs={24} md={12}>
                            <DatepickerInputWithSiteConfig
                                startDate={filters.Date}
                                endDate={filters.EndDate}
                                onFilterChange={onDateFilterChange}
                                label={'Date'}
                                allowModeSwitch={true}
                                rangeSelected={filters.rangeSelected}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <label htmlFor="filterShift">Shift</label>
                            <Select
                                id="filterShift"
                                name="Shift"
                                value={shiftValues}
                                options={shiftOptions}
                                onChange={handleFilterChangeShift}
                                onRemove={handleFilterChangeShift}
                                simpleValue={true}
                                multi={true}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xl={24} className="whenPrintingHide">
                    <Row>
                        <HasFeature
                            featureId={
                                FEATURE_IDS.LEAGUE__TABLE_FILTER_ACTIVITY
                            }
                        >
                            <Col xs={24} md={12} xl={8}>
                                <label htmlFor="filterCrew">Activity</label>
                                <Select
                                    id="filterActivity"
                                    name="Activity"
                                    value={filters.Activity}
                                    options={filterOptions.activities}
                                    onChange={this.handleFilterChangeActivity}
                                    onRemove={this.handleFilterChangeActivity}
                                    simpleValue={true}
                                    multi={true}
                                />
                            </Col>
                        </HasFeature>
                        <HasFeature
                            featureId={
                                FEATURE_IDS.LEAGUE__TABLE_FILTER_MATERIAL
                            }
                        >
                            <Col xs={24} md={12} xl={8}>
                                <label htmlFor="filterMaterial">Material</label>
                                <Select
                                    id="filterMaterial"
                                    name="Material"
                                    value={filters.Material}
                                    options={filterOptions.materials}
                                    onChange={this.handleFilterChangeMaterial}
                                    onRemove={this.handleFilterChangeMaterial}
                                    simpleValue={true}
                                    multi={true}
                                />
                            </Col>
                        </HasFeature>
                        <Col xs={24} md={12} xl={8}>
                            <label htmlFor="filterCrew">Crew</label>
                            <Select
                                id="filterCrew"
                                name="Crew"
                                value={crewValues}
                                options={crewOptions}
                                onChange={handleFilterChangeCrew}
                                onRemove={handleFilterChangeCrew}
                                simpleValue={true}
                                multi={true}
                            />
                        </Col>
                        <Col xs={24} md={12} xl={8}>
                            <label htmlFor="filterDriver">Drivers</label>
                            <Select
                                id="filterDriver"
                                name="Driver"
                                value={driverValues}
                                options={driverOptions}
                                onChange={handleFilterChangeWorker}
                                onRemove={handleFilterChangeWorker}
                                simpleValue={true}
                                multi={true}
                            />
                        </Col>
                        {hasAreaFilter && (
                            <Col xs={24} md={12} xl={8}>
                                <label htmlFor="filterArea">Mine region</label>
                                <Select
                                    id="filterArea"
                                    name="Mine region"
                                    value={filters.Area}
                                    options={filterOptions.areas}
                                    onChange={this.handleFilterChangeArea}
                                    onRemove={this.handleFilterChangeArea}
                                    simpleValue={true}
                                    multi={false}
                                />
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        );
    }
}

LeagueTableFilters.propTypes = {
    filters: PropTypes.shape({
        Date: PropTypes.string,
        EndDate: PropTypes.string,
        Shift: PropTypes.string.isRequired,
        Crew: PropTypes.string.isRequired,
        Worker: PropTypes.string.isRequired,
        Area: PropTypes.string.isRequired,
        Activity: PropTypes.string.isRequired,
        Material: PropTypes.string.isRequired,
    }).isRequired,
    filterOptions: PropTypes.shape({
        shifts: PropTypes.array.isRequired,
        drivers: PropTypes.array.isRequired,
        crews: PropTypes.array.isRequired,
        areas: PropTypes.array.isRequired,
        activities: PropTypes.array.isRequired,
        materials: PropTypes.array.isRequired,
    }).isRequired,
    onDateFilterChange: PropTypes.func.isRequired,
    onFilterChange: PropTypes.func.isRequired,
};

export default LeagueTableFilters;
