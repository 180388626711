import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_ENGINE_DIAGNOSTIC_CURVES_SUCCESS = `${namespace}/FETCH_ENGINE_DIAGNOSTIC_CURVES_SUCCESS`;
    const fetchEngineDiagnosticCurvesSuccess = (resultsArray) =>
        action(FETCH_ENGINE_DIAGNOSTIC_CURVES_SUCCESS, resultsArray);

    const FETCH_ENGINE_DIAGNOSTIC_CURVES_ERROR = `${namespace}/FETCH_ENGINE_DIAGNOSTIC_CURVES_ERROR`;
    const fetchEngineDiagnosticCurvesError = ({ error }) =>
        action(FETCH_ENGINE_DIAGNOSTIC_CURVES_ERROR, { error });

    return {
        FETCH_ENGINE_DIAGNOSTIC_CURVES_SUCCESS,
        fetchEngineDiagnosticCurvesSuccess,
        FETCH_ENGINE_DIAGNOSTIC_CURVES_ERROR,
        fetchEngineDiagnosticCurvesError,
    };
}
