import * as React from 'react';

function SvgStatusLoading(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <circle cx={512} cy={512} r={498.84} fill="#fff" />
            <path d="M512 50a462.14 462.14 0 01179.82 887.71A462.13 462.13 0 01332.18 86.29 459.06 459.06 0 01512 50m0-50C229.23 0 0 229.23 0 512s229.23 512 512 512 512-229.23 512-512S794.77 0 512 0z" />
            <path d="M698.07 792.46H320.75a9.31 9.31 0 01-8.45-5.75l-78.37-179.16a10.32 10.32 0 014.49-13.3l25.33-12.74a9.08 9.08 0 0112.41 4.81l65.78 150.39a9.31 9.31 0 008.44 5.75h318.88a9.27 9.27 0 008.34-5.52l70.66-151.31a9.05 9.05 0 0112.52-4.47l25 13.44A10.34 10.34 0 01790 608l-83.59 178.94a9.28 9.28 0 01-8.34 5.52z" />
            <path d="M505.33 630.1a9.67 9.67 0 0015.26 0l135.66-171.62a9.66 9.66 0 00-7.63-15.62h-89.51V153a9.72 9.72 0 00-9.69-9.69h-72.67a9.72 9.72 0 00-9.69 9.69v289.74h-89.75a9.66 9.66 0 00-7.63 15.62z" />
        </svg>
    );
}

export default SvgStatusLoading;
