import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '@rs/core/falcon';
import { DatepickerInputWithSiteConfig } from '../../Components/Datepicker';

class OperatorFeedbackFilters extends Component {
    render() {
        const { filters, driverShift, onDateFilterChange } = this.props;
        const shiftOptions = {
            D: 'Day',
            N: 'Night',
        };

        const shiftLabel = driverShift
            ? `${shiftOptions[driverShift.Shift]} Shift`
            : '';

        return (
            <Row>
                <Col xl={18}>
                    <Row>
                        <Col xl={8}>
                            <DatepickerInputWithSiteConfig
                                startDate={filters.Date}
                                onFilterChange={onDateFilterChange}
                                label={'Date'}
                                allowModeSwitch={false}
                                rangeSelected={false}
                            />
                        </Col>
                        <Col xl={8}>
                            <label htmlFor="filterShift">&nbsp;</label>
                            <span style={{ fontSize: '1.2rem' }}>
                                {shiftLabel}
                            </span>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

OperatorFeedbackFilters.propTypes = {
    filters: PropTypes.shape({
        Date: PropTypes.string.isRequired,
    }).isRequired,
    onDateFilterChange: PropTypes.func.isRequired,
    driverShift: PropTypes.shape({
        Shift: PropTypes.string,
    }),
};

export default OperatorFeedbackFilters;
