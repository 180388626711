import errorLogging from '@rs/core/utils/errorLogging';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { getNodeByType, NODE_TYPES } from './nodes';

function mergeLoadNodeWithFieldValues(node, fieldValues) {
    const nodeWithFieldValues = { ...node };

    if (fieldValues.loadMaxDuration) {
        nodeWithFieldValues['maximum-time'] = fieldValues.loadMaxDuration;
    }

    if (fieldValues.loadMaxCapacity) {
        nodeWithFieldValues['maximum-occupants'] = fieldValues.loadMaxCapacity;
    }

    if (fieldValues.loadEnableQueue) {
        nodeWithFieldValues.queue = fieldValues.loadEnableQueue;
    }

    if (fieldValues.loadMaxQueueDuration) {
        nodeWithFieldValues['maximum-queue-time'] =
            fieldValues.loadMaxQueueDuration;
    }

    if (fieldValues.loadMaxQueueCapacity) {
        nodeWithFieldValues['maximum-queue-length'] =
            fieldValues.loadMaxQueueCapacity;
    }

    return nodeWithFieldValues;
}

function mergeDumpNodeWithFieldValues(node, fieldValues) {
    const nodeWithFieldValues = { ...node };

    if (fieldValues.dumpMaxDuration) {
        nodeWithFieldValues['maximum-time'] = fieldValues.dumpMaxDuration;
    }

    if (fieldValues.dumpMaxCapacity) {
        nodeWithFieldValues['maximum-occupants'] = fieldValues.dumpMaxCapacity;
    }

    if (fieldValues.dumpEnableQueue) {
        nodeWithFieldValues.queue = fieldValues.dumpEnableQueue;
    }

    if (fieldValues.dumpMaxQueueDuration) {
        nodeWithFieldValues['maximum-queue-time'] =
            fieldValues.dumpMaxQueueDuration;
    }

    if (fieldValues.dumpMaxQueueCapacity) {
        nodeWithFieldValues['maximum-queue-length'] =
            fieldValues.dumpMaxQueueCapacity;
    }

    return nodeWithFieldValues;
}

function mergeHaulNodeWithFieldValues(node, fieldValues) {
    const nodeWithFieldValues = { ...node };

    if (fieldValues.haulMaxDuration) {
        nodeWithFieldValues['maximum-time'] = fieldValues.haulMaxDuration;
    }

    if (fieldValues.haulMaxCapacity) {
        nodeWithFieldValues['maximum-occupants'] = fieldValues.haulMaxCapacity;
    }

    return nodeWithFieldValues;
}

function mergeReturnNodeWithFieldValues(node, fieldValues) {
    const nodeWithFieldValues = { ...node };

    if (fieldValues.returnMaxDuration) {
        nodeWithFieldValues['maximum-time'] = fieldValues.returnMaxDuration;
    }

    if (fieldValues.returnMaxCapacity) {
        nodeWithFieldValues['maximum-occupants'] =
            fieldValues.returnMaxCapacity;
    }

    return nodeWithFieldValues;
}

export function parseCircuitFormToAPIModel(formValues, nodeConfig) {
    if (isEmpty(nodeConfig)) {
        const msg =
            'Attempting to parse edit circuit form into an API model failed. No node configuration was supplied';
        errorLogging.logException(msg);
        throw new Error(msg);
    }

    const loadNode = getNodeByType(NODE_TYPES.LOAD, nodeConfig);
    const haulNode = getNodeByType(NODE_TYPES.HAUL, nodeConfig);
    const returnNode = getNodeByType(NODE_TYPES.RETURN, nodeConfig);
    const dumpNode = getNodeByType(NODE_TYPES.DUMP, nodeConfig);

    return {
        // Ignore the transit-nodes field
        ...omit(nodeConfig, ['transit-nodes']),
        'transit-maximum-time': formValues.transitMaxDuration || null,
        nodes: [
            mergeLoadNodeWithFieldValues(loadNode, formValues),
            mergeHaulNodeWithFieldValues(haulNode, formValues),
            mergeDumpNodeWithFieldValues(dumpNode, formValues),
            mergeReturnNodeWithFieldValues(returnNode, formValues),
        ],
    };
}
