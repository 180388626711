import { combineReducers } from 'redux';
import { reducer as spinner } from '../Modules/spinner';
import { reducer as groups } from '../Modules/groups';
import { reducer as users } from '../Modules/users';
import { reducer as editForm } from '../Modules/editForm';

export const REDUCER_KEY = 'AssignGroups';
export default combineReducers({
    editForm,
    spinner,
    groups,
    users,
});
