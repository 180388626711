import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_TRUCK_ALERT_EVENTS_BY_TRUCK_NAME_SUCCESS = `${namespace}/FETCH_TRUCK_ALERT_EVENTS_BY_TRUCK_NAME_SUCCESS`;
    const fetchTruckAlertEventsByTruckNameSuccess = (resultsArray) =>
        action(FETCH_TRUCK_ALERT_EVENTS_BY_TRUCK_NAME_SUCCESS, resultsArray);

    const FETCH_TRUCK_ALERT_EVENTS_BY_TRUCK_NAME_ERROR = `${namespace}/FETCH_TRUCK_ALERT_EVENTS_BY_TRUCK_NAME_ERROR`;
    const fetchTruckAlertEventsByTruckNameError = ({ error }) =>
        action(FETCH_TRUCK_ALERT_EVENTS_BY_TRUCK_NAME_ERROR, { error });

    return {
        FETCH_TRUCK_ALERT_EVENTS_BY_TRUCK_NAME_SUCCESS,
        fetchTruckAlertEventsByTruckNameSuccess,
        FETCH_TRUCK_ALERT_EVENTS_BY_TRUCK_NAME_ERROR,
        fetchTruckAlertEventsByTruckNameError,
    };
}
