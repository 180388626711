import jwtDecode from 'jwt-decode';

/**
 * Parses a JWT into the underlying values
 * @param {string} jwt A string conforming to the JWT standards <string>.<string>.<string>
 * @return {object}
 */
export function decodeClaim(jwt) {
    try {
        return jwtDecode(jwt);
    } catch (e) {
        throw new Error(`decodeClaim: Unable to parse jwt, got: ${jwt}`);
    }
}
