import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import { Button } from '@rs/core/falcon';
import PrinterIcon from '@ant-design/icons/PrinterOutlined';

class PageControlPrint extends Component {
    static defaultProps = {
        trigger: () => <Button icon={<PrinterIcon />}>Print</Button>,
    };
    static propTypes = {
        trigger: PropTypes.func.isRequired,
        content: PropTypes.func.isRequired,
    };

    render() {
        const { trigger, content } = this.props;
        return <ReactToPrint trigger={trigger} content={content} />;
    }
}

export default PageControlPrint;
