import { combineReducers } from 'redux';
import { reducer, sliceSelectors } from './reducer';
import { reducer as spinner } from '../Modules/spinner';

const REDUCER_KEY = 'CreateNewUser';
const stateSelectors = {
    getApiErrorMessage: (state) =>
        sliceSelectors.getApiErrorMessage(state[REDUCER_KEY]),
    getCreateUserForm: (state) =>
        sliceSelectors.getCreateUserForm(state[REDUCER_KEY]),
    getApiSuccessMessage: (state) =>
        sliceSelectors.getApiSuccessMessage(state[REDUCER_KEY]),
};

export { stateSelectors, sliceSelectors, REDUCER_KEY };

export default combineReducers({
    reducer,
    spinner,
});
