import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MinesiteMap, Equipment } from '../../falcon';
import { typeMinesiteEquipment } from '../../types';

const { Types, StatusTypes } = Equipment;

function normaliseString(maybeString) {
    const str = typeof maybeString === 'string' ? maybeString : '';
    return str.replace(' ', '');
}

/**
 * Takes the class and subclass from an equipment entry in the Simulator
 * and returns the appropriate Equipment Type
 *
 * @param {string} primaryClass - The class of the equipment
 * @param {string} subClass - The subclass of the equipment
 * @returns {Object} One of Equipment.Types
 */
export const getEquipmentType = (primaryClass = '', subClass = '') => {
    const normalisedPrimaryClass = normaliseString(primaryClass);
    const normalisedSubClass = normaliseString(subClass);
    switch (normalisedPrimaryClass) {
        case 'HaulTruck':
            return Types.HaulTruck;
        case 'Grader':
            return Types.Grader;
        case 'Dozer':
            return Types.Dozer;
        case 'ServiceTruck':
            return Types.ServiceTruck;
        case 'WaterTruck':
            return Types.WaterTruck;
        case 'LightVehicle':
            return Types.LightVehicle;
        case 'FloatTruck':
            return Types.FloatTruck;
        case 'BellyDumper':
            return Types.BellyDumper;
        case 'Drill':
            return Types.Drill;
        case 'SoilCompactor':
            return Types.SoilCompactor;
        case 'LoadUnit':
            switch (normalisedSubClass) {
                case 'Excavator':
                    return Types.Excavator;
                case 'Shovel':
                    return Types.Shovel;
                case 'WheelLoader':
                    return Types.WheelLoader;
                case 'RopeShovel':
                    return Types.RopeShovel;
                default:
                    return Types.GenericVehicle;
            }
        case 'MobileEquipment':
        default:
            return Types.GenericVehicle;
    }
};

/**
 * Takes the activity and status from an equipment entry in the Simulator
 * and returns the appropriate Status type
 *
 * @param {string} activity - The activity of the equipment
 * @param {string} status - The status of the equipment
 * @param {Array.<string>} allowedStatuses - Array of statuses that can be shown
 * @returns {Object} One of Equipment.StatusTypes
 */
const getStatusType = (activity, status, allowedStatuses) => {
    switch (activity) {
        case 'Off':
            return StatusTypes.Off;
        default:
            // Don't show an icon if not allowed
            if (allowedStatuses.indexOf(status) === -1) {
                return null;
            }
            return StatusTypes[status];
    }
};

const EquipmentMarker = ({ equipment, fill, allowedStatuses, highlight }) => {
    const [hovered, setHovered] = useState(false);
    const { position } = equipment;
    const siteName = equipment['site-name'];
    const equipmentType = getEquipmentType(equipment.class, equipment.subclass);
    const statusType = getStatusType(
        equipment.activity,
        equipment.status,
        allowedStatuses,
    );
    return (
        <MinesiteMap.MarkerPin
            key={siteName}
            fill={fill}
            position={[position.latitude, position.longitude]}
            label={siteName}
            icon={<Equipment.Icon type={equipmentType} />}
            status={<Equipment.StatusIcon type={statusType} />}
            animatePosition={false}
            onmouseover={() => setHovered(true)}
            onmouseout={() => setHovered(false)}
            // Highlight the marker if specifically requested or hovered over
            highlight={highlight || hovered}
        />
    );
};

EquipmentMarker.propTypes = {
    equipment: typeMinesiteEquipment.isRequired,
    fill: PropTypes.shape({
        // Sets the outline and arrow part of the marker
        outer: PropTypes.string,
        // Sets the circle fill colour
        inner: PropTypes.string,
    }).isRequired,
    // Array of allowed statuses that can be displayed
    allowedStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
    // Determine if equipment should be highlighted. Default to false.
    highlight: PropTypes.bool,
};

export default EquipmentMarker;
