import { createReducer } from '../../../Lib/reduxUtils';
import * as circuitSuggestModule from '../circuitSuggest';
import { calculateTotalCycleDuration } from '../../Utils/nodes';
import * as mineSnapshot from '../mineSnapshot';
import { parseMinesiteCircuitIntoFormModel } from '../../Utils/parseMinesiteCircuitIntoFormModel';
import {
    hasErrors,
    splitTotalCircuitDurationBetweenNodes,
    validateFields,
} from '../../Utils/editCircuitFormUtils';
import actions from './actions';

export const FORM_TYPES = {
    CREATE: 'create',
    EDIT: 'edit',
};

export const FORM_STEPS = {
    LOCATION: 'location',
    IN_TRANSIT: 'inTransit',
    LOAD: 'load',
    HAUL: 'haul',
    DUMP: 'dump',
    RETURN: 'return',
};

export const initialState = {
    values: {
        id: '',
        source: '',
        destination: '',
        totalCircuitDuration: 0,
        transitMaxDuration: 0,
        loadMaxDuration: 0,
        loadEnableQueue: true,
        loadMaxQueueDuration: 0,
        loadMaxQueueCapacity: 1,
        loadMaxCapacity: 1,
        dumpMaxDuration: 0,
        dumpEnableQueue: true,
        dumpMaxQueueDuration: 0,
        dumpMaxCapacity: 1,
        dumpMaxQueueCapacity: 1,
        haulMaxDuration: 0,
        haulMaxCapacity: 1,
        returnMaxDuration: 0,
        returnMaxCapacity: 1,
    },
    errors: {},
    touched: {},
    form: {
        isPristine: true,
        isValid: true,
        title: '',
        isLoading: false,
        step: FORM_STEPS.LOCATION,
        mode: null,
    },
};

const resetForm = () => initialState;

const onFieldChange = (state, { fieldName, fieldValue }) => {
    const nextState = {
        ...state,
        values: {
            ...state.values,
            [fieldName]: fieldValue,
        },
        touched: {
            ...state.touched,
            [fieldName]: true,
        },
    };
    // Recalculate the node durations if a custom time has been entered
    if (fieldName === 'totalCircuitDuration') {
        nextState.values = {
            ...nextState.values,
            ...splitTotalCircuitDurationBetweenNodes(nextState.values),
        };
    }
    nextState.errors = validateFields(nextState.values);
    nextState.form = {
        ...nextState.form,
        isValid: !hasErrors(nextState.errors),
    };
    return nextState;
};

const createCircuit = (state, { circuitId }) => {
    const nextState = {
        ...state,
        values: {
            ...state.values,
            id: circuitId,
        },
    };
    nextState.errors = validateFields(nextState.values);
    nextState.form = {
        ...nextState.form,
        mode: FORM_TYPES.CREATE,
        title: 'Create Circuit',
        isValid: !hasErrors(nextState.errors),
    };
    return nextState;
};

const onCircuitSuggestSuccess = (state, payload) => {
    const formValues = parseMinesiteCircuitIntoFormModel(state.values, {
        id: state.values.id,
        ...payload,
    });
    const nextState = {
        ...state,
        values: {
            ...formValues,
            totalCircuitDuration: calculateTotalCycleDuration(payload),
        },
    };
    nextState.errors = validateFields(nextState.values);
    return nextState;
};

const customiseCircuit = (state, { isEnabled }) => {
    const errors = validateFields(state.values);
    const sourceAndDestIsValid = !errors.source && !errors.destination;
    const nextStep = isEnabled ? FORM_STEPS.IN_TRANSIT : FORM_STEPS.LOCATION;
    const step = sourceAndDestIsValid ? nextStep : state.form.step;
    return {
        ...state,
        form: {
            ...state.form,
            step,
            isPristine: false,
            isValid: !hasErrors(errors),
        },
        errors,
    };
};

const editCircuit = (state, { circuitId }, globalState) => {
    const circuit = mineSnapshot.selectors.getCircuitById(
        globalState,
        circuitId,
    );
    const formValues = parseMinesiteCircuitIntoFormModel(state.values, circuit);
    return {
        ...state,
        values: formValues,
        form: {
            ...state.form,
            step: FORM_STEPS.IN_TRANSIT,
            mode: FORM_TYPES.EDIT,
            title: 'Modify Circuit',
        },
    };
};

const submitCircuit = (state) => {
    const errors = validateFields(state.values);
    return {
        ...state,
        errors,
        form: {
            ...state.form,
            isValid: !hasErrors(errors),
            isPristine: false,
        },
    };
};

export const reducer = createReducer(initialState, {
    [actions.ON_FIELD_CHANGE]: onFieldChange,
    [actions.RESET_FORM]: resetForm,
    [actions.CREATE_CIRCUIT]: createCircuit,
    [actions.EDIT_CIRCUIT]: editCircuit,
    [actions.SUBMIT_CIRCUIT]: submitCircuit,
    [actions.CUSTOMISE_CIRCUIT]: customiseCircuit,
    [circuitSuggestModule.actions
        .GET_CIRCUIT_SUGGEST_SUCCESS]: onCircuitSuggestSuccess,
});
