import createActions from '../../Modules/mapFeatures/actions';
import createReducer from '../../Modules/mapFeatures/reducer';
import createSelectors from '../../Modules/mapFeatures/selectors';
import namespace from './namespace';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].mapFeatures;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);

export const reducer = createReducer({
    onSuccess: actions.FETCH_MAP_FEATURES_SUCCESS,
    onError: actions.FETCH_MAP_FEATURES_ERROR,
});

export const selectors = createSelectors(statePath);
