import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_SPEEDING_EVENTS_SUCCESS = `${namespace}/FETCH_SPEEDING_EVENTS_SUCCESS`;
    const fetchSpeedingEventsSuccess = (resultsArray) =>
        action(FETCH_SPEEDING_EVENTS_SUCCESS, resultsArray);

    const FETCH_SPEEDING_EVENTS_ERROR = `${namespace}/FETCH_SPEEDING_EVENTS_ERROR`;
    const fetchSpeedingEventsError = ({ error }) =>
        action(FETCH_SPEEDING_EVENTS_ERROR, { error });

    return {
        FETCH_SPEEDING_EVENTS_SUCCESS,
        fetchSpeedingEventsSuccess,
        FETCH_SPEEDING_EVENTS_ERROR,
        fetchSpeedingEventsError,
    };
}
