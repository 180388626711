import groupBy from 'lodash/groupBy';
import {
    Activity,
    ActivityClassification,
    ActivitiesByEquipmentName,
} from './constants';

const { MOVING, STATIONARY, UNKNOWN } = ActivityClassification;

export function getActivityClassification(
    activity: Activity,
): ActivityClassification {
    // If the status has already been set (i.e NO_GPS) just return that
    if (activity.Status) {
        return activity.Status;
    }
    // Otherwise calculate the classification
    const { MachineMoving } = activity;
    if (MachineMoving === 'Yes') {
        return MOVING;
    }
    if (MachineMoving === 'No') {
        return STATIONARY;
    }
    return UNKNOWN;
}

export function isMoving(activity: Activity): boolean {
    return getActivityClassification(activity) === MOVING;
}

export function isStationary(activity: Activity): boolean {
    return getActivityClassification(activity) === STATIONARY;
}

export function isUnknown(activity: Activity): boolean {
    return getActivityClassification(activity) === UNKNOWN;
}

export function activitySort(a: Activity, b: Activity): number {
    // Sort by recent data first
    const comparison: number =
        new Date(b.StartTime).getTime() - new Date(a.StartTime).getTime();
    // If the StartTimes are the same...
    if (comparison === 0) {
        // The last entry of live live has a StopTime of '',
        // therefore consider it as "later"
        if (a.StopTime === '') {
            return -1;
        }
        if (b.StopTime === '') {
            return 1;
        }
        // If neither, just return them as equal...
    }
    return comparison;
}

export function groupEquipmentNameAndSortLatestFirst(
    data: Array<Activity>,
): ActivitiesByEquipmentName {
    const byEquipmentName = groupBy(data, 'EquipmentSiteName');

    Object.keys(byEquipmentName).forEach((equipmentName) => {
        byEquipmentName[equipmentName].sort(activitySort);
    });

    return byEquipmentName;
}
