import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import 'moment-timezone';
import shiftUtils from '../../utils/shiftUtils';
import ReactHintFactory from 'react-hint';

const ReactHint = ReactHintFactory(React);

class GearLockdown extends Component {
    static defaultProps = {
        labelNoLockdown: 'No 4th Gear Lock Down',
        labelLockdown: '4th Gear Lock Down',
        maxShiftsToDisplay: 0,
        withBorder: true,
    };
    static propTypes = {
        labelNoLockdown: PropTypes.string.isRequired,
        labelLockdown: PropTypes.string.isRequired,
        shifts: PropTypes.arrayOf(
            PropTypes.shape({
                ShiftId: PropTypes.number.isRequired,
                StartTime: PropTypes.number.isRequired,
                StopTime: PropTypes.number.isRequired,
            }),
        ),
        maxShiftsToDisplay: PropTypes.number.isRequired,
        withBorder: PropTypes.bool,
    };

    render() {
        const {
            labelLockdown,
            labelNoLockdown,
            shifts,
            maxShiftsToDisplay,
            withBorder,
        } = this.props;

        const hasLockdown = shifts && shifts.length > 0;
        let allShifts = [];
        if (hasLockdown) {
            const hasMultipleRows = shifts.length > 1;
            allShifts = shifts.map((shift) => {
                const diff = moment.duration(
                    moment
                        .unix(shift.StopTime)
                        .diff(moment.unix(shift.StartTime)),
                );
                const format = 'hh:mma';
                const shiftStart = shiftUtils.epochToTZFormatted(
                    shift.StartTime,
                    undefined,
                    'DD/MM/YYYY',
                );
                const start = shiftUtils.epochToTZFormatted(
                    shift.StartTime,
                    undefined,
                    format,
                );
                const stop = shiftUtils.epochToTZFormatted(
                    shift.StopTime,
                    undefined,
                    format,
                );
                return (
                    <div key={shift.ShiftId} className="GearLockdown__Row">
                        {`${
                            hasMultipleRows ? shiftStart : ''
                        } ${diff.hours()} hours: ${start}-${stop}`}
                    </div>
                );
            });
        }

        const hasMore = hasLockdown && shifts.length > maxShiftsToDisplay;
        const hasMoreLabel =
            hasMore &&
            `(${allShifts.length} Shift${allShifts.length > 1 ? 's' : ''})`;
        const hasTooltip = hasMore ? { 'data-gearlockdown': true } : {};

        return (
            <div>
                <ReactHint
                    events={true}
                    attribute="data-gearlockdown"
                    persist={false}
                    onRenderContent={() => (
                        <div className="react-hint__content">{allShifts}</div>
                    )}
                />
                <div
                    {...hasTooltip}
                    className={cn('GearLockdown', {
                        'GearLockdown--Active': hasLockdown,
                        'GearLockdown--Border': withBorder,
                    })}
                >
                    <div className="GearLockdown__Header">
                        {hasLockdown ? labelLockdown : labelNoLockdown}{' '}
                        {hasMoreLabel && hasMoreLabel}
                    </div>
                    {hasLockdown &&
                        allShifts.filter((_, i) => i < maxShiftsToDisplay)}
                </div>
            </div>
        );
    }
}

export default GearLockdown;
