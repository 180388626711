import { createReducer } from '../../Lib/reduxUtils';
import { LOAD_USERS_LIST, MANAGE_USER_ERROR } from '../Actions';

export const initialState = {
    rows: [],
    apiErrorMessage: '',
};

const getApiErrorMessage = (state) => state.reducer.apiErrorMessage;

export const sliceSelectors = {
    getApiErrorMessage,
};

export const manageUserError = (state, apiErrorMessage) => ({
    ...state,
    apiErrorMessage,
});

export const setLoadUsersListResults = (state, { rows }) => ({
    ...state,
    rows,
});

export const reducer = createReducer(initialState, {
    [MANAGE_USER_ERROR]: manageUserError,
    [LOAD_USERS_LIST]: setLoadUsersListResults,
});
