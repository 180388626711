import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_CORNER_LEAGUE_SUCCESS = `${namespace}/FETCH_CORNER_LEAGUE_SUCCESS`;
    const fetchCornerLeagueSuccess = (resultsObject) =>
        action(FETCH_CORNER_LEAGUE_SUCCESS, resultsObject);

    const FETCH_CORNER_LEAGUE_ERROR = `${namespace}/FETCH_CORNER_LEAGUE_ERROR`;
    const fetchCornerLeagueError = ({ error }) =>
        action(FETCH_CORNER_LEAGUE_ERROR, { error });

    return {
        FETCH_CORNER_LEAGUE_SUCCESS,
        fetchCornerLeagueSuccess,
        FETCH_CORNER_LEAGUE_ERROR,
        fetchCornerLeagueError,
    };
}
