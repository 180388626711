import React from 'react';
import { TypeFilterOptions } from '@rs/core/types';
import { API_ARRAY_DELIMITER } from '../../Api';
//TO-DO import Select from "react-select" had a problem with onChange
const Select = require('react-select');

export const SELECT_STRING_DELIMITER = API_ARRAY_DELIMITER;

interface Props {
    name: string;
    value: string | number;
    options: TypeFilterOptions[] | string[];
    onChange: Function;
    multi?: boolean;
    isLoading?: boolean;
    simpleValue?: boolean;
    // Allow spreading any other props
    [x: string]: any;
}

const SelectWrapper = (props: Props) => {
    const {
        name,
        value,
        options,
        onChange,
        multi,
        isLoading,
        simpleValue,
        ...otherProps
    } = props;
    let optionsList: any[] = options || [];
    // If options is an array of strings, convert it to TypeFilterOptions
    if (optionsList.every((option) => typeof option === 'string')) {
        optionsList = (optionsList as string[]).map((option) => ({
            label: option,
            value: option,
        }));
    }

    return (
        <Select
            style={{
                marginBottom: '16px',
                borderRadius: 0,
            }}
            name={name}
            value={value}
            options={optionsList}
            onChange={onChange}
            multi={multi}
            isLoading={isLoading}
            simpleValue={simpleValue}
            delimiter={SELECT_STRING_DELIMITER}
            {...otherProps}
        />
    );
};

export default SelectWrapper;
