import { action } from '../../Lib/reduxUtils';
import { GoogleAnalyticsEventBuilder } from '../../Utils/userAnalytics/googleAnalyticsEventBuilder';

export const APP_INIT_REQUEST = 'App/APP_INIT_REQUEST';
export const appInitRequest = (preloadedState) =>
    action(APP_INIT_REQUEST, preloadedState);

export const APP_INIT_SUCCESS = 'App/APP_INIT_SUCCESS';
export const appInitSuccess = (siteConfiguration) =>
    action(APP_INIT_SUCCESS, siteConfiguration);

export const APP_INIT_ERROR = 'App/APP_INIT_ERROR';
export const appInitError = ({ error }) => action(APP_INIT_ERROR, { error });

export const EXPORT_TO_CSV = 'App/EXPORT_TO_CSV';
export const exportToCSV = ({ data, fileKeyConfig, filename }) =>
    action(
        EXPORT_TO_CSV,
        {
            data,
            fileKeyConfig,
            filename,
        },
        {
            analytics: new GoogleAnalyticsEventBuilder(
                'SubFeatureUsage',
                'Export CSV',
            )
                .setLabel(filename)
                .build(),
        },
    );

export const FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS =
    'App/FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS';
export const fetchPrivateSiteConfigurationSuccess = (siteConfiguration) =>
    action(FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS, siteConfiguration);

export const FETCH_PRIVATE_SITE_CONFIGURATION_ERROR =
    'App/FETCH_PRIVATE_SITE_CONFIGURATION_ERROR';
export const fetchPrivateSiteConfigurationError = (siteConfiguration) =>
    action(FETCH_PRIVATE_SITE_CONFIGURATION_ERROR, siteConfiguration);

export const FETCH_USER_INFORMATION_SUCCESS =
    'App/FETCH_USER_INFORMATION_SUCCESS';
export const fetchUserInformationSuccess = (userInformation) =>
    action(FETCH_USER_INFORMATION_SUCCESS, userInformation);

export const FETCH_USER_INFORMATION_ERROR = 'App/FETCH_USER_INFORMATION_ERROR';
export const fetchUserInformationError = (userInformation) =>
    action(FETCH_USER_INFORMATION_ERROR, userInformation);
