import { reducer, sliceSelectors } from './reducer';

export const LEAGUE_TABLE_REDUCER_KEY = 'LeagueTable';
export const stateSelectors = {
    getFilters: (state: any) =>
        sliceSelectors.getFilters(state[LEAGUE_TABLE_REDUCER_KEY]),
    getAllDrivers: (state: any) =>
        sliceSelectors.getAllDrivers(state[LEAGUE_TABLE_REDUCER_KEY]),
    getFilterOptions: (state: any) =>
        sliceSelectors.getFilterOptions(state[LEAGUE_TABLE_REDUCER_KEY], state),
    getDefinedFilters: (state: any) =>
        sliceSelectors.getDefinedFilters(state[LEAGUE_TABLE_REDUCER_KEY]),
    getCornerLeague: (state: any) =>
        sliceSelectors.getCornerLeague(state[LEAGUE_TABLE_REDUCER_KEY]),
    getCornerLeagueTotal: (state: any) =>
        sliceSelectors.getCornerLeagueTotal(state[LEAGUE_TABLE_REDUCER_KEY]),
    getCornerLeagueWeightings: (state: any) =>
        sliceSelectors.getCornerLeagueWeightings(
            state[LEAGUE_TABLE_REDUCER_KEY],
        ),
    getCornerLeagueOrder: (state: any) =>
        sliceSelectors.getCornerLeagueOrder(state[LEAGUE_TABLE_REDUCER_KEY]),
    getCornerLeaguePredicate: (state: any) =>
        sliceSelectors.getCornerLeaguePredicate(
            state[LEAGUE_TABLE_REDUCER_KEY],
        ),
    getSpinnerState: (state: any) =>
        sliceSelectors.getSpinnerState(state[LEAGUE_TABLE_REDUCER_KEY]),
    getGearLockdown: (state: any) =>
        sliceSelectors.getGearLockdown(state[LEAGUE_TABLE_REDUCER_KEY]),
};
export { sliceSelectors };

const rootReducer = reducer;

export default rootReducer;
