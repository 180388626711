import React from 'react';
import { GeoJSON, GeoJSONProps } from 'react-leaflet';

/**
 * This is a replica of the react-leaflet GeoJSON component
 * with the exception that this component will rerender
 * if the data source has been changed
 */
const UpdatingGeoJSON = ({ data, ...props }: GeoJSONProps) => {
    return <GeoJSON key={JSON.stringify(data)} data={data} {...props} />;
};

export default UpdatingGeoJSON;
