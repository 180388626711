import moment from 'moment';
import 'moment-timezone';
import shiftCalculating from './shiftCalculating';

/**
 * This class wraps the universal shiftCalculating class with some methods that cut out the boilerplate required to calculate shifts.
 * shiftCalculating should do as much of the heavy lifting where possible and ShiftUtils should just provide friendlier abstractions where needed.
 */
class ShiftUtils {
    constructor() {
        this.primaryShiftPatterns = null;
        this.timezoneAtMinesite = null;
        this.DATE_FORMAT__DISPLAY = 'DD/MM/YYYY';
        this.DATE_FORMAT__DISPLAY_TIME = 'HH:mma';
        this.DATE_FORMAT__VALIDATE = 'D/M/YYYY';
        this.DATE_FORMAT__WITHOUT_TIMEZONE = 'YYYY-MM-DDTHH:mm:ss.SSS';
        this.DATE_FORMAT__WITH_TIMEZONE = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
        this.DATE_FORMAT__SHIFT_ID_DATE = 'YYYYMMDD';
        this.DATE_FORMAT__WALLBOARD_DISPLAY = 'YYYY-MM-DD';
    }

    /**
     * Checks the class has been configured before using any methods that require setup
     * @private
     */
    _hasRequiredFieldsSet() {
        if (!this.primaryShiftPatterns) {
            throw new Error(
                `shiftUtils.primaryShiftPatterns is required, got: ${this.primaryShiftPatterns}. Try setting shiftUtils.setShiftConfiguration before using`,
            );
        }
        if (!this.timezoneAtMinesite) {
            throw new Error(
                `shiftUtils.timezoneAtMinesite is required, got: ${this.timezoneAtMinesite}. Try setting shiftUtils.setShiftConfiguration before using`,
            );
        }
    }

    /**
     * Sets up the class with the required shift configuration. Must be done before trying to calculate any shiftIds
     * @param {Object[]} primaryShiftPatterns
     * @param {string} timezoneAtMinesite
     */
    setShiftConfiguration(primaryShiftPatterns, timezoneAtMinesite) {
        this.primaryShiftPatterns = primaryShiftPatterns;
        this.timezoneAtMinesite = timezoneAtMinesite;
    }

    /**
     * Creates a new instance of the PrimaryShiftPatternsCalculator
     * @return {PrimaryShiftPatternsCalculator}
     */
    newShiftCalculator() {
        this._hasRequiredFieldsSet();
        return new shiftCalculating.PrimaryShiftPatternsCalculator(
            this.primaryShiftPatterns,
            this.timezoneAtMinesite,
        );
    }

    /**
     * Gets a shiftId from a date / shiftShortName. Defaults to first available shift if shift is not defined
     * @param {string} date
     * @param {string} shiftShortName
     * @return {string}
     */
    getShiftIdFromDateString(date, shiftShortName) {
        const shiftCalculator = this.newShiftCalculator();
        const shiftPattern = shiftCalculator.getPatternForDate(
            this.dateStringToDateItem(date),
        );
        const shiftIdIndex = this.getShiftIdIndexFromShortName(
            shiftPattern.rawShiftsInDay,
            shiftShortName,
        );
        return this.formatIntoShiftId(date, shiftIdIndex);
    }

    /**
     * Gets a single shiftIdIndex from the corresponding shiftShortName
     * @param {Object[]} rawShiftsInDay - The list of shiftsPatterns to get the shiftIdIndex from
     * @param {string} shiftShortName - A shortName to find the shiftIdIndex of
     * @return {number}
     */
    getShiftIdIndexFromShortName(rawShiftsInDay, shiftShortName) {
        const match = rawShiftsInDay.findIndex((row) => {
            return row.shortName === shiftShortName;
        });
        if (match > -1) {
            return match;
        }
        return 0;
    }

    /**
     * Formats a date and shiftIdIndex into a shiftId
     * @param {string} date
     * @param {number} shiftIdIndex
     * @return {string}
     */
    formatIntoShiftId(date, shiftIdIndex) {
        const formattedDate = moment(date, this.DATE_FORMAT__VALIDATE).format(
            this.DATE_FORMAT__SHIFT_ID_DATE,
        );
        return formattedDate + shiftIdIndex;
    }

    /**
     * Gets the shiftIds for a start/endDate
     * @param {string} startDate - The start date filter
     * @param {string} endDate - The end date filter, will default to be the startDate if endDate is not defined
     * @return {{startShiftId: ShiftIdItem, endShiftId: ShiftIdItem, shiftCalculator: *, shiftPattern: ShiftPattern}}
     */
    getStartEndShiftIdsFromDates(startDate, endDate) {
        const shiftCalculator = this.newShiftCalculator();
        const shiftPattern = shiftCalculator.getPatternForDate(
            this.dateStringToDateItem(startDate),
        );

        const startShiftId = shiftCalculating.getShiftIdItem(
            this.formatIntoShiftId(
                startDate,
                this.getShiftIdIndexFromShortName(
                    shiftPattern.rawShiftsInDay,
                    shiftPattern.rawShiftsInDay[0].shortName,
                ),
            ),
        );

        const endShiftId = shiftCalculating.getShiftIdItem(
            this.formatIntoShiftId(
                endDate || startDate,
                this.getShiftIdIndexFromShortName(
                    shiftPattern.rawShiftsInDay,
                    shiftPattern.rawShiftsInDay[
                        shiftPattern.rawShiftsInDay.length - 1
                    ].shortName,
                ),
            ),
        );

        return {
            startShiftId,
            endShiftId,
            shiftCalculator,
            shiftPattern,
        };
    }

    /**
     * Creates a range of shiftIds
     * @param {string} startDate - The start date filter
     * @param {string} endDate - The end date filter, will default to be the startDate if endDate is not defined
     * @param {string[]} [shiftShortNames] - An optional array of shifts to filter the shiftId generation
     */
    generateShiftIdRange(startDate, endDate, shiftShortNames) {
        if (typeof shiftShortNames === 'string') {
            throw new Error(
                `shiftShortNames should be an array, if this was a value from a filter maybe it needs to be split into an array. eg. filterValue.split(...), got: ${shiftShortNames}`,
            );
        }
        const {
            shiftCalculator,
            shiftPattern,
            startShiftId,
            endShiftId,
        } = this.getStartEndShiftIdsFromDates(startDate, endDate);

        const selectedShiftIdIndexes = this.getShiftIdIndexesFromShortNames(
            shiftPattern.rawShiftsInDay,
            shiftShortNames,
        );

        return shiftCalculator.generateShiftIdRange(
            startShiftId.shiftId(),
            endShiftId.shiftId(),
            selectedShiftIdIndexes,
        );
    }

    /**
     * Finds the shiftIdIndexes for a list of shiftShortNames
     * @param {Object[]} rawShiftsInDay - The list of shiftsPatterns to get the shiftIdIndex from
     * @param {string[]} shiftShortNames - A list of shortNames to find their corresponding shiftIdIndex
     * @return {number[]}
     */
    getShiftIdIndexesFromShortNames(rawShiftsInDay = [], shiftShortNames = []) {
        return shiftShortNames.reduce((result, shift) => {
            const match = rawShiftsInDay.findIndex((row) => {
                return row.shortName === shift;
            });
            if (match > -1) {
                result.push(match);
            }
            return result;
        }, []);
    }

    /**
     * Finds the matching record in the shiftPattern shifts
     * @param {Object[]} rawShiftsInDay
     * @param {string} shortName
     * @return {Object|undefined}
     */
    getRawShiftInDayFromShortName(rawShiftsInDay, shortName) {
        return rawShiftsInDay.find((row) => {
            return row.shortName === shortName;
        });
    }

    /**
     * Gets a list of the shift shortNames from the shiftPattern shifts
     * @param {Object[]} rawShiftsInDay
     * @return {*}
     */
    getShortNamesFromRawShiftsInDay(rawShiftsInDay) {
        return rawShiftsInDay.map((row) => row.shortName);
    }

    /**
     * Returns the first, all, last values of an array
     * @param {array} shiftIds
     * @return {{all: *, first: *, last: *}}
     */
    getAllFirstLastFromArray(shiftIds) {
        return {
            all: shiftIds,
            first: shiftIds[0],
            last: shiftIds[shiftIds.length - 1],
        };
    }

    /**
     * Gets a list of all the shift shortNames for a date
     * @param date
     * @return {{shiftShortNames: *, rawShiftsInDay: *}}
     */
    getAllShiftShortNamesForDate(date) {
        const shiftCalculator = this.newShiftCalculator();
        const shiftPattern = shiftCalculator.getPatternForDate(
            this.dateStringToDateItem(date),
        );
        const shiftShortNames = this.getShortNamesFromRawShiftsInDay(
            shiftPattern.rawShiftsInDay,
        );
        return {
            shiftShortNames,
            rawShiftsInDay: shiftPattern.rawShiftsInDay || [],
        };
    }

    /**
     * Converts a shiftIdIndex into the corresponding shift shortName
     * @param {string} date
     * @param {string|number} shiftIdIndex
     * @return {string}
     */
    shiftIdIndexToShortName(date, shiftIdIndex) {
        const shiftCalculator = this.newShiftCalculator();
        const shiftPattern = shiftCalculator.getPatternForDate(
            this.dateStringToDateItem(date),
        );
        const shift =
            shiftPattern.rawShiftsInDay[shiftIdIndex] ||
            shiftPattern.rawShiftsInDay[0];
        return shift.shortName;
    }

    /**
     * Converts a shiftId into the corresponding shift shortName
     * @param {string} shiftId
     * @return {string}
     */
    shiftIdToShortName(shiftId) {
        const { shiftIdDate, shiftIdIndex } = this._shiftIdToParts(shiftId);
        return this.shiftIdIndexToShortName(
            moment(shiftIdDate, this.DATE_FORMAT__SHIFT_ID_DATE).format(
                this.DATE_FORMAT__VALIDATE,
            ),
            shiftIdIndex,
        );
    }

    /**
     * Separates a shiftId into the date + shift
     * @param {string} shiftId
     * @private
     * @return {{shiftIdDate: string, shiftIdIndex: string}}
     */
    _shiftIdToParts(shiftId) {
        const shiftIdString = shiftId.toString();
        const shiftIdDate = shiftIdString.slice(0, -1);
        const shiftIdIndex = shiftIdString.slice(-1);
        return {
            shiftIdDate,
            shiftIdIndex,
        };
    }

    /**
     * Formats a shiftId into a friendly string for display using DATE_FORMAT__DISPLAY.
     * @param {number|string} shiftId
     * @param {Boolean} useShiftShortName
     * @return {string}
     */
    shiftIdToDateAndDay(shiftId = '', useShiftShortName = false) {
        const { shiftIdIndex } = this._shiftIdToParts(shiftId);
        const shiftCalculator = this.newShiftCalculator();
        const formattedDate = this.createMomentInSiteTime(
            shiftId,
            this.DATE_FORMAT__SHIFT_ID_DATE,
        ).format(this.DATE_FORMAT__DISPLAY);
        const shiftPattern = shiftCalculator.getPatternForDate(
            this.dateStringToDateItem(formattedDate),
        );
        const shift = shiftPattern.rawShiftsInDay[shiftIdIndex] || {};
        return `${formattedDate} ${
            useShiftShortName ? shift.shortName : shift.name
        }`;
    }

    /**
     * Formats a shiftId into a friendly string for display the day
     * @param {number|string} shiftId
     * @param {Boolean} useShiftShortName
     * @return {string}
     */
    shiftIdToDay(shiftId = '', useShiftShortName = false) {
        const { shiftIdIndex } = this._shiftIdToParts(shiftId);
        const shiftCalculator = this.newShiftCalculator();
        const formattedDate = this.createMomentInSiteTime(
            shiftId,
            this.DATE_FORMAT__SHIFT_ID_DATE,
        ).format(this.DATE_FORMAT__DISPLAY);
        const shiftPattern = shiftCalculator.getPatternForDate(
            this.dateStringToDateItem(formattedDate),
        );
        const shift = shiftPattern.rawShiftsInDay[shiftIdIndex] || {};
        return useShiftShortName ? shift.shortName : shift.name;
    }

    /**
     * Converts a shiftId into the corresponding shift shortName
     * @param {string} shiftId
     * @return {string}
     */
    shiftIdToDate(shiftId) {
        const formattedDate = this.createMomentInSiteTime(
            shiftId,
            this.DATE_FORMAT__SHIFT_ID_DATE,
        ).format(this.DATE_FORMAT__DISPLAY);

        return formattedDate;
    }

    /**
     * Finds the first shift record for the date
     * @param {string} date
     * @return {Object}
     */
    getFirstShiftFromDate(date) {
        const shiftCalculator = this.newShiftCalculator();
        const shiftPattern = shiftCalculator.getPatternForDate(
            this.dateStringToDateItem(date),
        );
        return shiftPattern.rawShiftsInDay[0];
    }

    /**
     * Creates the default date filter value
     * @return {string}
     */
    newDefaultFilterDate() {
        return this.createMomentInSiteTime()
            .subtract(1, 'day')
            .format(this.DATE_FORMAT__DISPLAY);
    }

    /**
     * Tests if a date string is a valid date
     * @param dateString
     * @param validationString
     * @return {boolean}
     */
    isDateStringValid(
        dateString,
        validationString = this.DATE_FORMAT__VALIDATE,
    ) {
        const m = moment(dateString, validationString, true);
        return m.isValid();
    }

    /**
     * Creates a moment in the minesite timezone from a date
     * @param {string} [date]
     * @param {string} [dateFormat=this.DATE_FORMAT__DISPLAY]
     * @return {*}
     */
    createMomentInSiteTime(date, dateFormat = this.DATE_FORMAT__DISPLAY) {
        if (!date) {
            return moment.tz(this.timezoneAtMinesite);
        }
        if (dateFormat) {
            return moment.tz(date, dateFormat, this.timezoneAtMinesite);
        }
        return moment.tz(date, this.timezoneAtMinesite);
    }

    /**
     * Return the timezone offset per minesite timezone
     * @return the timezone offset in seconds
     */
    getSiteTimeZoneOffset() {
        /*
            [
              { zone: 'Africa/Abidjan', offset: '+00:00' },
              { zone: 'Africa/Accra', offset: '+00:00' },
              ....
         */
        const zones = moment.tz
            .names()
            .map((zone) => ({ zone, offset: moment.tz(zone).utcOffset() }));
        return zones.find((zone) => this.timezoneAtMinesite === zone.zone)
            .offset;
    }

    getOffsetInSecondsBetweenSiteAndBrowser(time) {
        // the timezone offset between client browser and minesite
        // if the browser timezone in ACST
        // and the minesite timezone in AEST
        // there is 30 minutes difference in time
        return (
            (this.getBrowserTimeZoneOffset(time) -
                this.getSiteTimeZoneOffset()) *
            60
        );
    }

    /**
     * Return the timezone offset per client browser
     * @return the timezone offset in seconds
     * */
    getBrowserTimeZoneOffset(time) {
        /*
            [
              { zone: 'Africa/Abidjan', offset: '+00:00' },
              { zone: 'Africa/Accra', offset: '+00:00' },
              ....
         */
        // determine the utc offset correlate to the time, so that it takes day light saving in account
        return moment.unix(time).utcOffset();
    }

    /**
     * Tests if a datepicker day should be disabled for the end date picker.
     * @param {string} startDateString
     * @return {function(*=): *}
     */
    endDatepickerIsDayDisabled(startDateString) {
        const filter = this.createMomentInSiteTime(
            startDateString,
            this.DATE_FORMAT__DISPLAY,
        );
        return (day) => {
            const d = this.createMomentInSiteTime(day);
            return d.isBefore(filter);
        };
    }

    /**
     * Converts epoch timestamp to a datestring in a timezone
     * @param {number|string} seconds
     * @param {string} tz
     * @param {string} format
     * @return {string}
     */
    epochToTZFormatted(seconds, tz = this.timezoneAtMinesite, format) {
        return moment.unix(seconds).tz(tz).format(format);
    }

    /**
     * Takes a shiftId and returns the number of milliseconds since unix epoch for that
     * exact shift and date in the minesite timezone. Useful for date series in Highcharts
     * as their API accepts datetimes as milliseconds since unix epoch.
     *
     * Returns null if the shiftId is null
     *
     * @param {string} shiftId
     * @returns {number} unixMilliseconds
     */
    shiftIdToUnixMilliseconds(shiftId) {
        if (!shiftId) {
            return null;
        }
        const shiftCalculator = this.newShiftCalculator();
        const detailedShiftIdItem = shiftCalculator.obtainDetailedShiftIdItem(
            shiftId,
        );
        return detailedShiftIdItem.getStartTime().valueOf();
    }

    /**
     * Takes a number of milliseconds since unix epoch and returns the closest shiftId
     * in the minesite timezone. Useful for date series in Highcharts
     * as their API accepts datetimes as milliseconds since unix epoch.
     *
     * Returns null if unixMilliseconds is null
     *
     * @param {number} unixMilliseconds
     * @returns {string} shiftId
     */
    unixMillisecondsToShiftId(unixMilliseconds) {
        if (!unixMilliseconds) {
            return null;
        }
        const date = moment(unixMilliseconds).tz(this.timezoneAtMinesite);
        const shiftCalculator = this.newShiftCalculator();
        const shiftDetails = shiftCalculator.getShiftForMoment(date);
        const shiftId = this.formatIntoShiftId(date, shiftDetails.shiftIndex);
        return shiftId;
    }

    /**
     * Gets the shift display name from a shift short name
     * @param {string} date
     * @param {string} shiftShortName
     */
    getShiftNameFromShiftShortName(date, shiftShortName) {
        const shiftCalculator = this.newShiftCalculator();
        const shiftPattern = shiftCalculator.getPatternForDate(
            this.dateStringToDateItem(date),
        );
        const shift = this.getRawShiftInDayFromShortName(
            shiftPattern.rawShiftsInDay,
            shiftShortName,
        );
        return shift.name;
    }

    /**
     * Parses a dateString into a DateItem
     * @param {string} dateString - in the format of DATE_FORMAT__DISPLAY
     * @return {DateItem}
     */
    dateStringToDateItem(dateString) {
        // TODO probably need something more sophisticated to parse different dateString formats
        const date = dateString || '';
        const [datePartsDay, datePartsMonth, datePartsYear] = date.split('/');
        const dateItem = new shiftCalculating.DateItem(
            parseInt(datePartsYear, 10),
            parseInt(datePartsMonth, 10),
            parseInt(datePartsDay, 10),
        );
        return dateItem;
    }

    /**
     * Returns the earliest shiftId, this is useful to compare the latest uploaded shiftId with what the user has selected
     * @param {string|number} latestUploadedShiftId
     * @param {string|number} selectedShiftId
     * @return {number}
     */
    pickLatestUploadedShiftId(latestUploadedShiftId, selectedShiftId) {
        const isSameDay = moment(latestUploadedShiftId, 'YYYYMMDD').isSame(
            moment(selectedShiftId, 'YYYYMMDD'),
            'day',
        );
        // If they aren't on the same day then don't compare, eg. selected date might be in the future, or it might be missing data.
        if (!isSameDay) {
            return selectedShiftId;
        }
        return Math.min(
            parseInt(latestUploadedShiftId, 10),
            parseInt(selectedShiftId, 10),
        );
    }

    /**
     * Returns the day of a shiftId as a moment object adjusted to the minesite timezone
     * @param {string|number} shiftId
     * @return {moment}
     */
    getMomentOfShiftIdAdjustedByTimezone(shiftId) {
        return moment.tz(shiftId, 'YYYYMMDD', this.timezoneAtMinesite);
    }

    /**
     * Returns a string of the YYYYMMDD format of a shiftId
     * @param {string|number} shiftId
     * @return {string}
     */
    shiftIdToDayString(shiftId) {
        return String(shiftId).substr(0, 8);
    }

    /**
     * Finds the earliest shift pattern in the PrimaryShiftPatterns
     * @return {*}
     */
    getEarliestShiftPattern = function () {
        const shiftCalculator = this.newShiftCalculator();
        return shiftCalculator.getEarliestShiftPattern();
    };

    /**
     * Returns the total length of the shift
     * @param {number} shiftId
     * @return {number}
     */
    getShiftLength(shiftId) {
        const shiftCalculator = this.newShiftCalculator();
        const shiftIdItem = shiftCalculating.getShiftIdItem(shiftId);
        const shiftPattern = shiftCalculator.getPatternForShiftIdItem(
            shiftIdItem,
        );

        const nowShift = shiftPattern.rawShiftsInDay[shiftIdItem.inDayIndex];
        const nextShift =
            shiftPattern.rawShiftsInDay[shiftIdItem.inDayIndex + 1] ||
            shiftPattern.rawShiftsInDay[0];

        const shiftLength =
            moment(nowShift.startTime, 'HH:mm').unix() -
            moment(nextShift.startTime, 'HH:mm').unix();
        return Math.abs(shiftLength);
    }
}

export default new ShiftUtils();
