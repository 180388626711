import * as React from 'react';

function SvgStatusUnavailable(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <circle cx={512} cy={512} r={498.84} fill="#fff" />
            <path d="M512 50a462.14 462.14 0 01179.82 887.71A462.13 462.13 0 01332.18 86.29 459.06 459.06 0 01512 50m0-50C229.23 0 0 229.23 0 512s229.23 512 512 512 512-229.23 512-512S794.77 0 512 0z" />
            <path d="M549 512l185.92-221.65a5.63 5.63 0 00-4.32-9.28h-56.53a11.56 11.56 0 00-8.71 4L512 467.94 358.64 285.11a11.32 11.32 0 00-8.71-4H293.4a5.63 5.63 0 00-4.32 9.28L475 512 289.08 733.65a5.63 5.63 0 004.32 9.28h56.53a11.56 11.56 0 008.71-4L512 556.06l153.36 182.83a11.32 11.32 0 008.71 4h56.53a5.63 5.63 0 004.32-9.28z" />
        </svg>
    );
}

export default SvgStatusUnavailable;
