import { all } from 'redux-saga/effects';
import tyresAndSafetyWatchers from './tyresAndSafetyWatchers';
import operatorFeedbackWatchers from './operatorFeedbackWatchers';
import productivityWatchers from './productivityWatchers';
import enginePerformanceWatchers from './enginePerformanceWatchers';
import engineDiagnosticsWatchers from './engineDiagnosticsWatchers';
import o4rOperatorWatchers from './o4rOperatorWatchers';
import leagueTableWatchers from './leagueTableWatchers';
import loaderLeagueWatchers from './loaderLeagueWatchers';
import biasLoadWatchers from './biasLoadWatchers';
import criticalParamsWatchers from './criticalParamsWatchers';
import haulRoadConditionWatchers from './haulRoadConditionWatchers';
import haulTruckFillFactorWatchers from './haulTruckFillFactorWatchers';
import loadUnitDetailWatchers from './loadUnitDetailWatchers';
import loadUnitTimeDetailWatchers from './loadUnitTimeDetailWatchers';
import downloadsWatchers from './downloadsWatchers';
import authWatchers from './authWatchers';
import fileExportWatchers from './fileExportWatchers';
import truckHistoryWatchers from './truckHistoryWatchers';
import fleetStatusWatchers from './fleetStatusWatchers';
import analyticsWatchers from './analyticsWatchers';
import truckAlertsMapViewWatchers from './truckAlertsMapViewWatchers';
import dataHealthWatchers from './dataHealthWatchers';
import createUserWatchers from './createUserWatchers';
import editUserWatchers from './editUserWatchers';
import manageUserWatchers from './manageUserWatchers';
import productionDashboardWatchers from './productionDashboardWatchers';
import equipmentLatencyWatchers from './equipmentLatencyWatchers';
import manageGroupWatchers from './manageGroupWatchers';
import createGroupWatchers from './createGroupWatchers';
import editGroupWatchers from './editGroupWatchers';
import assignGroupsWatchers from './assignGroupsWatchers';
import activityManagementWatchers from './activityManagementWatchers';
import dasSagas from './dasSagas';
import helpWatchers from './helpWatchers';
import fleetHistoryWatchers from './fleetHistoryWatchers';
import driverOnKeyOnWatchers from './driverOnKeyOnWatchers';
import networkCoverageWatchers from './networkCoverageWatchers';
import powerBiWatchers from './powerBiWatchers';
import EditPowerBiReportWatchers from './editPowerBiReportWatchers';
// Start all the sagas
function* rootSaga() {
    yield all([
        analyticsWatchers(),
        authWatchers(),
        biasLoadWatchers(),
        fileExportWatchers(),
        fleetStatusWatchers(),
        equipmentLatencyWatchers(),
        haulRoadConditionWatchers(),
        haulTruckFillFactorWatchers(),
        leagueTableWatchers(),
        loadUnitDetailWatchers(),
        loadUnitTimeDetailWatchers(),
        loaderLeagueWatchers(),
        criticalParamsWatchers(),
        o4rOperatorWatchers(),
        operatorFeedbackWatchers(),
        productivityWatchers(),
        downloadsWatchers(),
        truckHistoryWatchers(),
        enginePerformanceWatchers(),
        engineDiagnosticsWatchers(),
        tyresAndSafetyWatchers(),
        truckAlertsMapViewWatchers(),
        dataHealthWatchers(),
        createUserWatchers(),
        editUserWatchers(),
        manageUserWatchers(),
        productionDashboardWatchers(),
        manageGroupWatchers(),
        createGroupWatchers(),
        editGroupWatchers(),
        assignGroupsWatchers(),
        activityManagementWatchers(),
        dasSagas(),
        helpWatchers(),
        fleetHistoryWatchers(),
        driverOnKeyOnWatchers(),
        networkCoverageWatchers(),
        powerBiWatchers(),
        EditPowerBiReportWatchers(),
    ]);
}

export default rootSaga;
