import { all, takeLatest, put, select, call } from 'redux-saga/effects';
import {
    actions as filterActions,
    selectors as filterSelectors,
} from '../EnginePerformance/Modules/filters';
import { actions as spinnerActions } from '../EnginePerformance/Modules/spinner';
import { actions as engineDiagnosticPowerCurveMetricsActions } from '../EnginePerformance/Modules/engineDiagnosticPowerCurveMetrics';
import { actions as equipmentUptimeActions } from '../EnginePerformance/Modules/equipmentUptime';
import {
    convertURLQueryStringToObject,
    updateURLQueryString,
} from '../Lib/queryStringUtils';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import * as resourceSagas from './resourceSagas';
import { checkRequestsForErrors, API_ARRAY_DELIMITER } from '../Api';

export function* filterUpdated(action) {
    const filters = yield select(filterSelectors.getFilters);
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    yield call(getPageData, action);
    updateURLQueryString(filters);
    yield put(spinnerActions.setSpinnerState(false));
}

export function* getPageData() {
    const { shiftIdsSelectedShifts } = yield select(
        filterSelectors.getMapFiltersToAPIParams,
    );
    const shiftIds = shiftIdsSelectedShifts.all.join(API_ARRAY_DELIMITER);

    const [
        engineDiagnosticPowerCurveMetrics,
        timingEquipmentUptime,
    ] = yield all([
        call(resourceSagas.getEngineDiagnosticPowerCurveMetrics, {
            ShiftId: shiftIds,
            // Haul Truck filter
            EquipmentClass: 'HaulTruck',
        }),
        call(resourceSagas.getTimingEquipmentUptime, {
            ShiftId: shiftIds,
            EquipmentClass: 'HaulTruck',
        }),
    ]);

    if (
        checkRequestsForErrors([
            engineDiagnosticPowerCurveMetrics,
            timingEquipmentUptime,
        ])
    ) {
        yield put(
            engineDiagnosticPowerCurveMetricsActions.fetchEngineDiagnosticPowerCurveMetricsError(
                {
                    error: `Failed to load engine diagnostic power curve metrics for shifts ${shiftIds}`,
                },
            ),
        );
        return;
    }

    yield all([
        put(
            engineDiagnosticPowerCurveMetricsActions.fetchEngineDiagnosticPowerCurveMetricsSuccess(
                engineDiagnosticPowerCurveMetrics.response,
            ),
        ),
        put(
            equipmentUptimeActions.fetchTimingEquipmentUptimeSuccess(
                timingEquipmentUptime.response,
            ),
        ),
    ]);
}

export function* updateFiltersWithURLParams(action) {
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const urlFilterValues = convertURLQueryStringToObject();
    yield put(filterActions.setFiltersWithUrlParams(urlFilterValues));
    yield call(getPageData, action);
    yield put(spinnerActions.setSpinnerState(false));
}

export default function* watch() {
    yield all([
        takeLatest(
            filterActions.UPDATE_FILTERS_WITH_URL_PARAMS,
            updateFiltersWithURLParams,
        ),
        takeLatest(filterActions.FILTER_UPDATED, filterUpdated),
    ]);
}
