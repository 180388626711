import React from 'react';
import { Dropdown as AntDropdown } from 'antd';

// The default trigger to open the dropdown is 'hover', change it to 'click'
const Dropdown = ({ ...props }) => (
    <AntDropdown trigger={['click']} {...props} />
);

Dropdown.propTypes = AntDropdown.propTypes;

export default Dropdown;
