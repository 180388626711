import { createReducer } from '../../Lib/reduxUtils';

const initialState = {};

export function onRequested(state, payload) {
    return {
        ...state,
        [payload.requestId]: {
            ...payload,
            error: null,
        },
    };
}

export function onSuccess(state, { requestId }) {
    const nextState = { ...state };
    delete nextState[requestId];
    return nextState;
}

export function onError(state, { requestId, error }) {
    return {
        ...state,
        [requestId]: {
            ...state[requestId],
            error,
        },
    };
}

/**
 * Creates a reducer for this module. It has some default behaviour but also allows custom actions to be supplied
 * @param {object} actions {<internalActionName>: <ACTION_TYPE>} maps an internalActionName to an ACTION_TYPE. The handler for this ACTION_TYPE is the default behaviour
 * @param {object} customActions {<ACTION_TYPE>: actionHandler} maps an ACTION_TYPE to an action handler
 * @return {function(*=, {type: *, payload?: *})}
 */
export default function createReducers(actions, customActions = {}) {
    return createReducer(initialState, {
        [actions.onRequested]: onRequested,
        [actions.onSuccess]: onSuccess,
        [actions.onError]: onError,
        [actions.onCancel]: onSuccess,
        /**
         * Ensure any custom action handlers pass along state + any additional arguments needed for the
         * action handlers
         */
        ...customActions,
    });
}
