import React from 'react';
import { Button as AntButton } from 'antd';

const Button = ({ loading = false, ...props }) => {
    return (
        <AntButton
            // Better aria support https://www.w3.org/TR/wai-aria-1.0/states_and_properties#aria-busy
            // Helps with automated testing
            aria-busy={loading}
            loading={loading}
            {...props}
        />
    );
};

Button.propTypes = {
    ...AntButton.propTypes,
};

Button.Group = AntButton.Group;

export default Button;
