import shiftUtils from '@rs/core/utils/shiftUtils';
import { createSelector } from 'reselect';
import createActions from '../../Modules/filters/actions';
import createReducer from '../../Modules/filters/reducer';
import createSelectors from '../../Modules/filters/selectors';
import namespace from './namespace';
import { FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS } from '../../App/Actions';
import { filterShiftOptionsToReactSelectFormat } from '../../Utils/Filters/filterOptionsToReactSelectFormat';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].filters;

const initialState = {
    Date: undefined,
    Shift: '',
};

const fetchPrivateSiteConfigurationSuccess = (state) => {
    const defaultDate = shiftUtils.newDefaultFilterDate();
    const firstShift = shiftUtils.getFirstShiftFromDate(defaultDate);
    return {
        ...state,
        Date: defaultDate,
        Shift: firstShift.shortName,
    };
};

/**
 * Create the module parts
 */
export const actions = createActions(namespace);
export const reducer = createReducer(
    {
        filterUpdated: actions.FILTER_UPDATED,
        setFiltersWithUrlParams: actions.SET_FILTERS_WITH_URL_PARAMS,
    },
    {
        [FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS]: fetchPrivateSiteConfigurationSuccess,
    },
    initialState,
);

const defaultSelectors = createSelectors(statePath);
const getFilterOptions = createSelector(
    defaultSelectors.getFilters,
    (filters) => {
        const shifts = shiftUtils.getAllShiftShortNamesForDate(filters.Date);
        return {
            shifts: filterShiftOptionsToReactSelectFormat(
                shifts.rawShiftsInDay,
                shifts.shiftShortNames,
            ),
        };
    },
);
const getMapFiltersToAPIParams = createSelector(
    defaultSelectors.getFilters,
    (filters) => {
        // Gets 30 days worth of data
        const endDate = shiftUtils.createMomentInSiteTime(
            filters.Date,
            shiftUtils.DATE_FORMAT__VALIDATE,
        );
        const startDate = endDate.clone().subtract(30, 'days');
        const shiftIdsSelectedShifts = shiftUtils.generateShiftIdRange(
            startDate.format(shiftUtils.DATE_FORMAT__DISPLAY),
            endDate.format(shiftUtils.DATE_FORMAT__DISPLAY),
            [],
        );
        return {
            shiftIdsSelectedShifts: shiftUtils.getAllFirstLastFromArray(
                shiftIdsSelectedShifts,
            ),
        };
    },
);

export const selectors = {
    ...defaultSelectors,
    getFilterOptions,
    getMapFiltersToAPIParams,
};
