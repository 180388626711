import React from 'react';
import PropTypes from 'prop-types';

const HaulRoadConditionKpiDirection = ({
    goodClassName,
    badClassName,
    value,
}) => {
    if (value === 0 || Number.isNaN(value)) return null;
    const iconDirection = value > 0 ? 'up' : 'down';
    const className = value > 0 ? goodClassName : badClassName;
    return <i className={`fa fa-arrow-${iconDirection} ${className}`} />;
};

HaulRoadConditionKpiDirection.defaultProps = {};

HaulRoadConditionKpiDirection.propTypes = {
    goodClassName: PropTypes.string.isRequired,
    badClassName: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
};

export default HaulRoadConditionKpiDirection;
