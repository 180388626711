import combineReducers from 'combine-reducers-global-state';
import { reducer as mineSnapshot } from '../Modules/mineSnapshot';
import { reducer as circuitsOrder } from '../Modules/circuitsOrder';
import { reducer as holdingStatesOrder } from '../Modules/holdingStatesOrder';
import { reducer as pendingTransactions } from '../Modules/pendingTransactions';
import { reducer as editCircuitForm } from '../Modules/editCircuitForm';
import { reducer as circuitSuggest } from '../Modules/circuitSuggest';
import { reducer as circuitNodes } from '../Modules/circuitNodes';
import { reducer as searchBox } from '../Modules/searchBox';
import { reducer as fmsTruckAllocationEquipment } from '../Modules/fmsTruckAllocationEquipment';
import { reducer as WKTAggregate } from '../Modules/WKTAggregate';
import { reducer as minesiteMap } from '../Modules/minesiteMap';
import { reducer as spinner } from '../Modules/spinner';
import { reducer as equipmentSearch } from '../Modules/equipmentSearch';
import {
    moduleName as mapFeaturesName,
    reducer as mapFeatures_v2,
} from '../Modules/mapFeatures_v2';
import {
    moduleName as mapLegendsName,
    reducer as mapLegends,
} from '../Modules/mapLegends';

export default combineReducers({
    mineSnapshot,
    circuitsOrder,
    holdingStatesOrder,
    pendingTransactions,
    editCircuitForm,
    circuitSuggest,
    circuitNodes,
    searchBox,
    fmsTruckAllocationEquipment,
    WKTAggregate,
    minesiteMap,
    spinner,
    [mapFeaturesName]: mapFeatures_v2,
    [mapLegendsName]: mapLegends,
    equipmentSearch
});
