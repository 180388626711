import { combineReducers } from 'redux';
import init from './init';
import publicSiteConfiguration from './publicSiteConfiguration';
import privateSiteConfiguration from './privateSiteConfiguration';
import userInformation from './userInformation';

export const REDUCER_KEY = 'App';
export default combineReducers({
    init,
    publicSiteConfiguration,
    privateSiteConfiguration,
    userInformation,
});
