import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const SET_SPINNER_STATE = `${namespace}/SET_SPINNER_STATE`;

    const setSpinnerState = (isActive = false, message = '', faIcon) =>
        action(SET_SPINNER_STATE, {
            isActive,
            message,
            faIcon,
        });

    return {
        SET_SPINNER_STATE,
        setSpinnerState,
    };
}
