/**
 * A helper function to remove some of the boiler plate when writing reducer functions
 * eg.
 * const reducer = createReducer({}, {
 *      [AN_ACTION_TO_RESPOND_TO]: someFunction(state, payload){
 *          // return new state
 *      }
 * })
 * @param initialState
 * @param fnMap
 * @returns {function(*=, {type: *, payload?: *})}
 */
export function createReducer(
    initialState: any,
    fnMap: any,
): typeof initialState {
    return (
        state: any = initialState,
        { type, payload }: any,
        parentState: any,
    ) => {
        const handler = fnMap[type];
        return handler ? handler(state, payload, parentState) : state;
    };
}

// Action type
export type Action = ReturnType<typeof action>;

/**
 * Helper to reduce the boilerplate of creating action creators
 * eg.
 * const loadDrivers = payload => action(LOAD_DRIVERS_ACTION, payload);
 * @param type - The string name of the action
 * @param payload - the body of the action
 * @param meta - This is a special object that should only be read inside of middlewares (or sagas in our case)
 * Do not use for general use, use payload instead.
 * A valid use case would be analytics values
 */
export function action(type: string, payload = {}, meta = {}) {
    return {
        type,
        payload,
        meta,
    };
}

export function withoutNestedPreviousState(state: any) {
    const { previousState, ...withoutNestedPreviousState } = state;
    return withoutNestedPreviousState;
}
