import { combineReducers } from 'redux';
import { createReducer } from '../../Lib/reduxUtils';
import {
    FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS,
    FETCH_PRIVATE_SITE_CONFIGURATION_ERROR,
} from '../Actions';
import { LOGIN_ERROR, LOGOUT } from '../../Auth/Actions';
import createInitReducer from '../../Modules/init/reducer';

const initialState = {
    filtering: {
        activities: [],
        materialTypes: [],
    },
};

const onSuccess = (state, siteConfiguration) => ({
    ...state,
    ...siteConfiguration,
});

const reset = () => initialState;

const privateSiteConfigReducer = createReducer(initialState, {
    [LOGOUT]: reset,
    [LOGIN_ERROR]: reset,
    [FETCH_PRIVATE_SITE_CONFIGURATION_ERROR]: reset,
    [FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS]: onSuccess,
});

const initReducer = createInitReducer({
    onSuccess: FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS,
    onError: FETCH_PRIVATE_SITE_CONFIGURATION_ERROR,
});

export default combineReducers({
    config: privateSiteConfigReducer,
    init: initReducer,
});
