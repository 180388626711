import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { typeChildren } from '../../types';

class CellContainer extends Component {
    static defaultProps = {};
    static propTypes = {
        className: PropTypes.string,
        style: PropTypes.shape({}).isRequired,
        children: typeChildren,
    };

    render() {
        const { className, style, children, ...otherProps } = this.props;
        return (
            <div className={className} style={style} {...otherProps}>
                {children}
            </div>
        );
    }
}

export default CellContainer;
