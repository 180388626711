import React, { Component } from 'react';
import { typeChildren } from '../../types';
import cn from 'classnames';
import PropTypes from 'prop-types';

class PivotGridCell extends Component {
    static defaultProps = {
        style: {},
    };
    static propTypes = {
        children: typeChildren,
        rotated: PropTypes.bool,
        background: PropTypes.string,
        ellipsis: PropTypes.bool,
        className: PropTypes.string,
        style: PropTypes.shape({}),
    };

    render() {
        const {
            rotated,
            background,
            className,
            ellipsis,
            style,
            ...rest
        } = this.props;
        return (
            <div
                className={cn(
                    {
                        'PivotGridCell--Rotated': rotated,
                        'PivotGridCell--Background': background,
                        'PivotGridCell--Ellipsis': ellipsis,
                    },
                    className,
                )}
                style={{ ...style, background }}
                {...rest}
            >
                {this.props.children}
            </div>
        );
    }
}

export default PivotGridCell;
