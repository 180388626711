import { createSelector } from 'reselect';
import { roundTo } from '@rs/core/utils/mathHelpers';
import { createMultiKeySortFn } from '../../Lib/sortingUtils';
import { getTableOrder } from './tableOrder';
import { REDUCER_KEY } from '../Reducers/index';

export const getBiasLoads = (state) => state[REDUCER_KEY].biasLoad;

/**
 * Returns the loader results that have been sorted
 */
export const getSortedBiasLoad = createSelector(
    getTableOrder,
    getBiasLoads,
    (tableOrder, loaderEvents) => {
        const sortFn = createMultiKeySortFn([
            { name: tableOrder.sortBy, reverse: tableOrder.reverse },
            // Sort by a second key incase of matching records on previous sort condition
            { name: 'OperatorDisplayName', reverse: false },
        ]);

        const sortedEvents = loaderEvents.rows
            .map((row) => ({
                OperatorDisplayName: row.OperatorDisplayName,
                LoadCount: row.LoadCount,
                RedConf: roundTo(
                    row.FrontLeftConf +
                        row.FrontRightConf +
                        row.RearLeftConf +
                        row.RearRightConf,
                    1,
                ),
                YellowConf: roundTo(
                    row.FrontMiddleConf +
                        row.MiddleLeftConf +
                        row.MiddleRightConf +
                        row.RearMiddleConf,
                    1,
                ),
                BangConf: row.BangConf,
            }))
            .sort(sortFn);

        const total = {
            OperatorDisplayName: 'Total',
            LoadCount: loaderEvents.total.LoadCount,
            RedConf: roundTo(
                loaderEvents.total.FrontLeftConf +
                    loaderEvents.total.FrontRightConf +
                    loaderEvents.total.RearLeftConf +
                    loaderEvents.total.RearRightConf,
                1,
            ),
            YellowConf: roundTo(
                loaderEvents.total.FrontMiddleConf +
                    loaderEvents.total.MiddleLeftConf +
                    loaderEvents.total.MiddleRightConf +
                    loaderEvents.total.RearMiddleConf,
                1,
            ),
            BangConf: loaderEvents.total.BangConf,
        };

        return {
            rows: sortedEvents,
            total,
        };
    },
);
