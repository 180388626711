import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
    MinesiteMap,
    MAP_LAYER_LABELS,
    getMapExtentBounds,
} from '@rs/core/falcon';
import { connect } from 'react-redux';
import { FEATURE_IDS } from '@rs/core/features';
import {
    typeHaulRoadConditionEvent,
    typeMapFeature,
    typeMapLabelRow,
    typeMinesiteAreaTree,
    typeMinesiteMap,
    typeMinesiteMapConfig,
    typeSurfaceRoughness,
    typeWktData,
} from '@rs/core/types';
import { normaliseString } from '@rs/core/utils';
import camelCase from 'lodash/camelCase';
import { HasFeature } from '../../Components/HasFeature';
import { selectors as haulRoadConditionEventsSelectors } from '../Modules/haulRoadConditionEvents';
import { getFilteredSurfaceRoughness } from '../Selectors/surfaceRoughness';
import { getWktData } from '../Selectors/wktData';
import { getMapFeatures } from '../Selectors/mapFeatures';
import { selectors as minesiteAreasTreeSelectors } from '../Modules/minesiteAreasTree';
import {
    getComponentConfig,
    getTopLevelWhereOnMinesiteIdDepth,
} from '../../App/Selectors';
import getFilters from '../Selectors/filters/getFilters';
import * as minesiteMapModule from '../Modules/minesiteMap';
import DigitalGlobeBackground from '../../Components/DigitalGlobeBackground/DigitalGlobeBackground';
import { selectors as mapFeatureSelectors } from '../Modules/mapFeatures';
import { MapControlWithPopup } from '@rs/core/falcon/components/MinesiteMap/MapControl_v2';
import { selectState as selectMapLegendState } from '../Modules/mapLegends';
import { Legend } from '@rs/core/falcon/components/NewLegend';
import { selectors as mapLegendSelectors } from '../Modules/mapLegends';
import * as HRCactions from '../Actions';
import arePropsEqual from '@rs/core/falcon/utils/arePropsEqual';
class HrcMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
        };
        this.handleOverlayAdd = this.handleOverlayToggle(true);
        this.handleOverlayRemove = this.handleOverlayToggle(false);
    }

    handleOverlayToggle = () => (checked, name) => {
        const layerName = normaliseString.stripHTMLTags(name);
        const formattedLayerName = camelCase(layerName);
        this.props.onMapLayerToggle(formattedLayerName, checked);

        //----------handles legend layer flipping -------------------
        if (checked) {
            //make the section as visible
            this.props.flipLegendLayer(layerName, 'VISIBLE');
        } else {
            //make the section as hidden
            this.props.flipLegendLayer(layerName, 'HIDDEN');
        }
    };

    onCloseClick = () => {
        this.setState((state) => ({
            ...state,
            showPopup: false,
        }));
    };
    onContentClick = () => {
        this.setState((state) => ({
            ...state,
            showPopup: true,
        }));
    };

    bottomLeft = () => <div id={'legend'}>Geofence Legend</div>;

    HRCEventsShouldComponentUpdate(currProps, nextProps) {
        const nextShiftId =
            nextProps.data && nextProps.data[0] && nextProps.data[0].ShiftId;

        const currShiftId =
            currProps.data && currProps.data[0] && currProps.data[0].ShiftId;

        return nextShiftId !== currShiftId;
    }

    render() {
        const {
            wktData,
            surfaceRoughnessLoaded,
            surfaceRoughnessUnloaded,
            hrcEventsLow,
            hrcEventsMedium,
            hrcEventsHigh,
            minesiteMapConfig,
            onMapViewportChange,
            minesiteMap,
            speedZonesGeojson,
            materialMovement,
            mineFeatures,
            mineRegions,
            mapLegendSections,
        } = this.props;
        const bounds = getMapExtentBounds(minesiteMapConfig.bounds);
        if (!wktData.WKTLocuses || !bounds) {
            return null;
        }
        const customLayerGrouping = [
            {
                groupName: 'Menu universal',
                layerNames: [
                    MAP_LAYER_LABELS.background,
                    MAP_LAYER_LABELS.backgroundClientProvided,
                    MAP_LAYER_LABELS.roadPath,
                ],
            },
            {
                groupName: 'Haul Road Condition',
                layerNames: [
                    MAP_LAYER_LABELS.hrcLoaded,
                    MAP_LAYER_LABELS.hrcUnloaded,
                    MAP_LAYER_LABELS.hrcEventsLow,
                    MAP_LAYER_LABELS.hrcEventsMed,
                    MAP_LAYER_LABELS.hrcEventsHigh,
                ],
            },
            {
                groupName: 'Geofence Menu',
                layerNames: [
                    MAP_LAYER_LABELS.speedLimitedZones,
                    MAP_LAYER_LABELS.materialMovement,
                    MAP_LAYER_LABELS.trackingRegions,
                    MAP_LAYER_LABELS.mineRegions,
                    MAP_LAYER_LABELS.geofenceLabels,
                ],
            },
        ];

        return (
            <div className="MineSiteMapContainer PDF__Container">
                <MinesiteMap
                    viewport={minesiteMap.isViewportValid && minesiteMap}
                    maxBounds={bounds}
                    style={{ height: 670 }}
                    onViewportChanged={onMapViewportChange}
                >
                    <MinesiteMap.CustomLayersControl
                        collapsed={true}
                        position="topright"
                        customLayerGrouping={customLayerGrouping}
                        onOverlayAdd={this.handleOverlayAdd}
                        onOverlayRemove={this.handleOverlayRemove}
                    >
                        <DigitalGlobeBackground
                            checked={minesiteMap.satellite}
                            name={MAP_LAYER_LABELS.background}
                        />
                        <MinesiteMap.Background
                            checked={minesiteMap.aerial}
                            name={MAP_LAYER_LABELS.backgroundClientProvided}
                            url={minesiteMapConfig.mapLayers.clientProvided.url}
                            options={
                                minesiteMapConfig.mapLayers.clientProvided
                                    .options
                            }
                        />
                        <MinesiteMap.SurfaceRoughness
                            priority={500}
                            checked={minesiteMap.hrcUnloaded}
                            name={MAP_LAYER_LABELS.hrcUnloaded}
                            data={surfaceRoughnessUnloaded}
                        />
                        <MinesiteMap.SurfaceRoughness
                            priority={600}
                            checked={minesiteMap.hrcLoaded}
                            name={MAP_LAYER_LABELS.hrcLoaded}
                            data={surfaceRoughnessLoaded}
                        />
                        <HasFeature
                            featureId={
                                FEATURE_IDS.HAUL_ROAD_CONDITION__SHOW_HRC_MAP_EVENTS
                            }
                        >
                            <MinesiteMap.HRCEvents
                                priority={700}
                                shouldComponentUpdate={
                                    this.HRCEventsShouldComponentUpdate
                                }
                                name={MAP_LAYER_LABELS.hrcEventsLow}
                                severity={'Low'}
                                checked={minesiteMap.hrcEventsLow}
                                data={hrcEventsLow}
                            />
                            <MinesiteMap.HRCEvents
                                priority={800}
                                shouldComponentUpdate={
                                    this.HRCEventsShouldComponentUpdate
                                }
                                name={MAP_LAYER_LABELS.hrcEventsMed}
                                severity={'Medium'}
                                checked={minesiteMap.hrcEventsMedium}
                                data={hrcEventsMedium}
                            />
                            <MinesiteMap.HRCEvents
                                priority={900}
                                shouldComponentUpdate={
                                    this.HRCEventsShouldComponentUpdate
                                }
                                name={MAP_LAYER_LABELS.hrcEventsHigh}
                                severity={'High'}
                                checked={minesiteMap.hrcEventsHigh}
                                data={hrcEventsHigh}
                            />
                        </HasFeature>
                        <MinesiteMap.RoadPath
                            priority={1}
                            checked={minesiteMap.roadPath}
                            name={MAP_LAYER_LABELS.roadPath}
                            data={wktData.WKTLocuses[0].GeoJSON}
                            shouldComponentUpdate={(currProps, nextProps) => {
                                return currProps.data !== nextProps.data;
                            }}
                        />
                        <MinesiteMap.Polygons
                            priority={1}
                            checked={minesiteMap.mineRegions}
                            name={MAP_LAYER_LABELS.mineRegions}
                            data={mineRegions && mineRegions.features}
                            borderColor={`#000000`} // it is fixed
                            fillOpacity={0}
                            showLabel={minesiteMap.geofenceLabels}
                        />
                        <MinesiteMap.Polygons
                            priority={7}
                            checked={minesiteMap.speedRestrictedZones}
                            name={MAP_LAYER_LABELS.speedLimitedZones}
                            data={
                                speedZonesGeojson && speedZonesGeojson.features
                            }
                            fillOpacity={0.2}
                            showLabel={minesiteMap.geofenceLabels}
                        />
                        <MinesiteMap.Polygons
                            priority={3}
                            checked={minesiteMap.materialMovement}
                            name={MAP_LAYER_LABELS.materialMovement}
                            data={materialMovement && materialMovement.features}
                            fillOpacity={0.2}
                            showLabel={minesiteMap.geofenceLabels}
                        />

                        <MinesiteMap.Polygons
                            priority={10}
                            checked={minesiteMap.mineFeatures}
                            name={MAP_LAYER_LABELS.trackingRegions}
                            data={mineFeatures && mineFeatures.features}
                            fillOpacity={0.2}
                            showLabel={minesiteMap.geofenceLabels}
                        />
                        <MinesiteMap.LayersControl.Overlay
                            name={MAP_LAYER_LABELS.geofenceLabels}
                            checked={minesiteMap.geofenceLabels}
                        >
                            <MinesiteMap.LayerGroup />
                        </MinesiteMap.LayersControl.Overlay>
                    </MinesiteMap.CustomLayersControl>
                    <MapControlWithPopup
                        hide={mapLegendSections.length === 0}
                        onCloseClick={this.onCloseClick}
                        onContentClick={this.onContentClick}
                        popupVisible={this.state.showPopup}
                        position={'bottomleft'}
                        popupContent={() => (
                            <Legend stateSelector={selectMapLegendState} />
                        )}
                        content={this.bottomLeft}
                    />
                </MinesiteMap>
            </div>
        );
    }
}

HrcMap.propTypes = {
    wktData: typeWktData,
    surfaceRoughnessLoaded: PropTypes.arrayOf(typeSurfaceRoughness),
    surfaceRoughnessUnloaded: PropTypes.arrayOf(typeSurfaceRoughness),
    mapFeatures: PropTypes.arrayOf(typeMapFeature),
    minesiteAreasTree: typeMinesiteAreaTree,
    topLevelWhereOnMinesiteIdDepth: PropTypes.number.isRequired,
    hrcEventsLow: PropTypes.arrayOf(typeHaulRoadConditionEvent),
    hrcEventsMedium: PropTypes.arrayOf(typeHaulRoadConditionEvent),
    hrcEventsHigh: PropTypes.arrayOf(typeHaulRoadConditionEvent),
    minesiteMapConfig: typeMinesiteMapConfig,
    minesiteMap: typeMinesiteMap,
    onMapLayerToggle: PropTypes.func,
    onMapViewportChange: PropTypes.func,
};

const mapStateToProps = (state) => {
    const haulRoadConditionEvents = haulRoadConditionEventsSelectors.getAll(
        state,
    );
    const hrcEvents = haulRoadConditionEvents.reduce(
        (agg, row) => {
            if (row.Level === 'Low') {
                agg.low.push(row);
            } else if (row.Level === 'Medium') {
                agg.medium.push(row);
            } else if (row.Level === 'High') {
                agg.high.push(row);
            }
            return agg;
        },
        { low: [], medium: [], high: [] },
    );

    const surfaceRoughness = getFilteredSurfaceRoughness(state);

    const surfaceRoughnessPaths = surfaceRoughness.paths.reduce(
        (agg, row) => {
            agg[row.grouping].push(row);
            return agg;
        },
        { unloaded: [], loaded: [] },
    );

    return {
        filters: getFilters(state),
        surfaceRoughnessUnloaded: surfaceRoughnessPaths.unloaded,
        surfaceRoughnessLoaded: surfaceRoughnessPaths.loaded,
        wktData: getWktData(state),
        mapFeatures: getMapFeatures(state),
        minesiteAreasTree: minesiteAreasTreeSelectors.getAll(state),
        topLevelWhereOnMinesiteIdDepth: getTopLevelWhereOnMinesiteIdDepth(
            state,
        ),
        hrcEventsLow: hrcEvents.low,
        hrcEventsMedium: hrcEvents.medium,
        hrcEventsHigh: hrcEvents.high,
        minesiteMapConfig: getComponentConfig(state, 'MinesiteMap'),
        minesiteMap: minesiteMapModule.selectors.get(state),
        speedZonesGeojson: mapFeatureSelectors.selectSpeedZonesGeojson(state),
        materialMovement: mapFeatureSelectors.selectMaterialMovementsGeojson(
            state,
        ),
        mineFeatures: mapFeatureSelectors.selectTrackingRegionsGeojson(state),
        mineRegions: mapFeatureSelectors.selectWomidsGeojson(state),
        mapLegendSections: mapLegendSelectors.selectVisibleSections(state),
    };
};

const mapActions = {
    onMapViewportChange: minesiteMapModule.actions.mapViewportChanged,
    onMapLayerToggle: minesiteMapModule.actions.mapFilterUpdated,
    flipLegendLayer: HRCactions.mapLegendLayerFlip,
};

export default memo(
    connect(mapStateToProps, mapActions)(HrcMap, arePropsEqual),
    arePropsEqual,
);
