import * as networkCoverageReportModule from '../Modules/networkCoverageReport';
import { smartHexagonFilter } from '@rs/core/falcon/components/MinesiteMap/Hexagons';
import * as minesiteMapModule from '../Modules/minesiteMap';
import intersectionBy from 'lodash/intersectionBy';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';

// create a "selector creator" that uses lodash.isEqual instead of ===
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);
const getCurrentH3Data = createDeepEqualSelector(
    networkCoverageReportModule.selectors.getAll,
    minesiteMapModule.selectors.getBounds,
    (netWorkCoverageState, minesiteMapBounds) => {
        const {
            dataInMap,
            h3Resolution,
            h3ResolutionBest,
        } = netWorkCoverageState;

        const targetH3Resolution =
            dataInMap[h3ResolutionBest] !== null
                ? h3ResolutionBest
                : h3Resolution;
        const targetData = dataInMap[targetH3Resolution];

        // if there is noNetworkCoveragePage data yet
        if (!targetData) return [];

        const { allH3IndexesForBounds } = smartHexagonFilter(
            minesiteMapBounds,
            [Number(targetH3Resolution)],
            200,
        );

        let intersectedData = targetData;
        if (allH3IndexesForBounds.length > 0) {
            intersectedData = intersectionBy(
                targetData,
                allH3IndexesForBounds.map((h3geoindex) => ({
                    h3geoindex,
                })),
                'h3geoindex',
            );
        }

        return intersectedData;
    },
);
export default getCurrentH3Data;
