import { latLngBounds, LatLngBounds } from 'leaflet';

/**
 * Creates a leaflet latLngBounds from the map domain
 */
export default function getMapExtentBounds(wktData: any): LatLngBounds {
    return latLngBounds(
        [wktData.LatitudeDomain[0], wktData.LongitudeDomain[0]],
        [wktData.LatitudeDomain[1], wktData.LongitudeDomain[1]],
    );
}
