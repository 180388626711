import { createReducer } from '../../Lib/reduxUtils';
import { FETCH_LOAD_UNIT_CONFORMANCE_WEIGHTINGS_SUCCESS } from '../Actions';

const initialState = {};

export function onSuccess(state: any, conformanceWeightings: any) {
    return conformanceWeightings.reduce(
        (latestWeighting: any, shiftlyWeighting: any) => {
            if (
                !latestWeighting.ShiftId ||
                shiftlyWeighting.ShiftId > latestWeighting.ShiftId
            ) {
                return shiftlyWeighting;
            }
            return latestWeighting;
        },
        {},
    );
}

export default createReducer(initialState, {
    [FETCH_LOAD_UNIT_CONFORMANCE_WEIGHTINGS_SUCCESS]: onSuccess,
});
