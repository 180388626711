/**
 * Caution: This file is automatically generated from the script,
 *          public/packages/web/scripts/generate_features.js > public/packages/core/features/index.ts
 */
export const FEATURE_IDS = {
    BASE_API_ACCESS: 70,
    DEV_FEATURE_1: 35,
    DEV_FEATURE_2: 36,
    DEV_FEATURE_3: 37,
    DEV_FEATURE_4: 10025,
    DEV_FEATURE_5: 10026,
    DEV_FEATURE_6: 10027,
    DISABLED: 9999,
    HAUL_ROAD_CONDITION__FILTER_WHERE_ON_MINESITE_ID: 28,
    HAUL_ROAD_CONDITION__MAP_FEATURES: 29,
    HAUL_ROAD_CONDITION__SHOW_HRC_MAP_EVENTS: 52,
    LEAGUE__TABLE__SHOW_GEAR_LOCKDOWN: 49,
    LEAGUE__TABLE_FILTER_ACTIVITY: 48,
    LEAGUE__TABLE_FILTER_MATERIAL: 47,
    LEAGUE__TABLE_FILTER_WOMID: 46,
    LEAGUE__TABLE_SHOW_CORNER_CONFORMANCE_COLUMN: 54,
    LEAGUE__TABLE_SHOW_CORNER_COUNT: 58,
    LEAGUE__TABLE_SHOW_DRIVING_DURATION_COLUMN: 34,
    LEAGUE__TABLE_SHOW_DUMP_COLUMN: 71,
    LEAGUE__TABLE_SHOW_PRODUCTIVITY_COLUMN: 23,
    LEAGUE__TABLE_SHOW_PRODUCTIVITY_LINK: 24,
    LEAGUE__TABLE_SHOW_SPOT_COLUMN: 72,
    LEAGUE__TABLE_SHOW_TOTAL_RAMPS_COLUMN: 75,
    LEAGUE__TABLE_SHOW_TOTAL_ZONES_COLUMN: 74,
    LOADER_LEAGUE__TABLE__SHOW_TOTAL_COLUMN: 106,
    LOADER_LEAGUE__TABLE__TIME_SCORE_COLUMN: 33,
    LOADER_LEAGUE__TABLE_LINK__LOAD_UNIT_TIME_DETAIL: 32,
    LOADS_IN_RANGE__SHOW_LOAD_UNIT_BAND_CONTAINER: 30,
    LOADS_UNIT_DETAIL__FILTER_CREW: 31,
    MEDIA_BUCKET_UPLOAD: 53,
    PAGE_CONTROL__EXPORT_RESULTS: 39,
    POWER_BI_REPORTS__ADMIN: 125,
    REGULAR_UPLOAD: 81,
    SELF_SERVICE__CSV_EXPORT_BUTTON_ACTIVITY_DETAIL: 95,
    SELF_SERVICE__CSV_EXPORT_BUTTON_CORNER_EVENTS_REPORT: 68,
    SELF_SERVICE__CSV_EXPORT_BUTTON_CYCLESUMMARIES: 25,
    SELF_SERVICE__CSV_EXPORT_BUTTON_EQUIPMENT_ACTIVITY_BASIC_REPORT: 89,
    SELF_SERVICE__CSV_EXPORT_BUTTON_EQUIPMENT_ALERTS: 123,
    SELF_SERVICE__CSV_EXPORT_BUTTON_HAUL_ROAD_CONDITION_EVENTS: 57,
    SELF_SERVICE__CSV_EXPORT_BUTTON_HAUL_TRUCK_FILL_FACTOR: 44,
    SELF_SERVICE__CSV_EXPORT_BUTTON_HAUL_TRUCK_OPERATOR_LEAGUE: 142,
    SELF_SERVICE__CSV_EXPORT_BUTTON_IDLE_TIME: 96,
    SELF_SERVICE__CSV_EXPORT_BUTTON_LOAD_UNIT_OPERATOR_LEAGUE: 143,
    SELF_SERVICE__CSV_EXPORT_BUTTON_OPERATOR_IDENTIFICATION_LOGS: 99,
    SELF_SERVICE__CSV_EXPORT_BUTTON_QUEUEING_REPORT: 76,
    SELF_SERVICE__CSV_EXPORT_BUTTON_RAMP_EVENT_DETAILS: 103,
    SELF_SERVICE__CSV_EXPORT_BUTTON_RAMP_EVENT_SUMMARIES: 102,
    SELF_SERVICE__CSV_EXPORT_BUTTON_RAMP_EVENTS_REPORT: 67,
    SELF_SERVICE__CSV_EXPORT_BUTTON_SHIFT_ACTIVITY_SUMMARY: 98,
    SELF_SERVICE__CSV_EXPORT_BUTTON_SPEEDING_EVENTS_REPORT: 69,
    SELF_SERVICE__CSV_EXPORT_BUTTON_TIME_ACCOUNTING_ACTIVITIES: 10001,
    SELF_SERVICE__CSV_EXPORT_BUTTON_TIMING_SUMMARY: 26,
    SELF_SERVICE__CSV_EXPORT_BUTTON_TRUCK_ALERT_EVENTS: 27,
    SHIFT_REPLAY_TOOL__ENABLE_METRIC__auto_retarder_state: 107,
    SHIFT_REPLAY_TOOL__ENABLE_METRIC__brake_cooling_pump: 108,
    SHIFT_REPLAY_TOOL__ENABLE_METRIC__front_brake_oil_temperature: 109,
    SHIFT_REPLAY_TOOL__ENABLE_METRIC__l_f_brake_oil_temperature: 110,
    SHIFT_REPLAY_TOOL__ENABLE_METRIC__l_r_brake_oil_temperature: 111,
    SHIFT_REPLAY_TOOL__ENABLE_METRIC__manual_retarder: 113,
    SHIFT_REPLAY_TOOL__ENABLE_METRIC__manual_retarder_state: 112,
    SHIFT_REPLAY_TOOL__ENABLE_METRIC__park_brake: 114,
    SHIFT_REPLAY_TOOL__ENABLE_METRIC__r_f_brake_oil_temperature: 115,
    SHIFT_REPLAY_TOOL__ENABLE_METRIC__r_r_brake_oil_temperature: 116,
    SHIFT_REPLAY_TOOL__ENABLE_METRIC__rear_brake_oil_temperature: 117,
    SHIFT_UPLOAD: 51,
    SHOW_NON_HUMAN_ACCOUNTS: 100,
    TAB_ID__APP_LANDING: 38,
    TAB_ID__ASSIGN_GROUP: 87,
    TAB_ID__BIAS_LOAD: 1,
    TAB_ID__BIAS_LOAD_DETAIL: 2,
    TAB_ID__CIRCUIT_PULSE: 10004,
    TAB_ID__CONFIGURATION_TOOL: 10022,
    TAB_ID__CONFIGURATION_TOOL_WRITE: 10021,
    TAB_ID__CREATE_GROUP: 84,
    TAB_ID__CREATE_USER: 77,
    TAB_ID__CRIB_WALLBOARD: 3,
    TAB_ID__DAS: 97,
    TAB_ID__DATA_HEALTH: 50,
    TAB_ID__DELETE_GROUP: 86,
    TAB_ID__DELETE_USER: 82,
    TAB_ID__EDIT_CIRCUITS: 91,
    TAB_ID__EDIT_DELETE_USER: 78,
    TAB_ID__EDIT_GROUP: 85,
    TAB_ID__EDIT_HOLDING_STATES: 92,
    TAB_ID__ENGINEERING: 4,
    TAB_ID__FLEET_PLAN_EDIT: 10016,
    TAB_ID__FLEET_PLAN_READ_ONLY: 10015,
    TAB_ID__FLEET_PULSE: 10017,
    TAB_ID__HARDWARE_MONITORING: 10024,
    TAB_ID__HAUL_ROAD_CONDITION: 5,
    TAB_ID__HAUL_TRUCK_FILL_FACTOR: 6,
    TAB_ID__HELP: 56,
    TAB_ID__LEAGUE_TABLE: 7,
    TAB_ID__LIVE_EQUIPMENT_STATUS: 134,
    TAB_ID__LIVE_MAP: 94,
    TAB_ID__LOAD_UNIT_OPERATOR_LEAGUE_TABLE: 10,
    TAB_ID__LOAD_UNIT_TIME_DETAIL: 11,
    TAB_ID__LOADER_REPORTS: 8,
    TAB_ID__LOADS_IN_RANGE_DETAIL: 9,
    TAB_ID__LOGIN: 12,
    TAB_ID__MANAGE_GROUP: 83,
    TAB_ID__MANAGE_USER: 80,
    TAB_ID__MAX_HUB_LIVE_MAP: 10019,
    TAB_ID__MAXMINE_DIAGNOSTICS: 13,
    TAB_ID__NETWORK_COVERAGE: 122,
    TAB_ID__O4R_OPERATOR: 14,
    TAB_ID__O4R_RACK: 15,
    TAB_ID__OPERATOR_KPI: 17,
    TAB_ID__OVERALL_PRODUCTIVITY_EVENTS: 18,
    TAB_ID__POWER_BI_REPORT_1: 128,
    TAB_ID__POWER_BI_REPORT_10: 140,
    TAB_ID__POWER_BI_REPORT_11: 10005,
    TAB_ID__POWER_BI_REPORT_12: 10006,
    TAB_ID__POWER_BI_REPORT_13: 10007,
    TAB_ID__POWER_BI_REPORT_14: 10008,
    TAB_ID__POWER_BI_REPORT_15: 10009,
    TAB_ID__POWER_BI_REPORT_16: 10010,
    TAB_ID__POWER_BI_REPORT_17: 10011,
    TAB_ID__POWER_BI_REPORT_18: 10012,
    TAB_ID__POWER_BI_REPORT_19: 10013,
    TAB_ID__POWER_BI_REPORT_2: 129,
    TAB_ID__POWER_BI_REPORT_20: 10014,
    TAB_ID__POWER_BI_REPORT_3: 130,
    TAB_ID__POWER_BI_REPORT_4: 131,
    TAB_ID__POWER_BI_REPORT_5: 132,
    TAB_ID__POWER_BI_REPORT_6: 136,
    TAB_ID__POWER_BI_REPORT_7: 137,
    TAB_ID__POWER_BI_REPORT_8: 138,
    TAB_ID__POWER_BI_REPORT_9: 139,
    TAB_ID__POWER_BI_REPORTS: 124,
    TAB_ID__POWER_BI_UPLOAD_FILES: 135,
    TAB_ID__PRODUCTION_DASHBOARD: 79,
    TAB_ID__PULSE_TARGETS_UPLOAD: 10020,
    TAB_ID__RESET_PASSWORD: 90,
    TAB_ID__RESULTS_DELAY: 88,
    TAB_ID__SELF_SERVICE: 19,
    TAB_ID__SIC_MATERIAL_MOVEMENT: 144,
    TAB_ID__SITE_GEOFENCES_AND_RULES: 133,
    TAB_ID__TIME_ACCOUNTING: 10002,
    TAB_ID__TIME_ACCOUNTING_WRITE: 10003,
    TAB_ID__TIME_USAGE: 40,
    TAB_ID__TRUCK_ALERTS_ANALYSIS: 45,
    TAB_ID__TRUCK_ALLOCATION: 93,
    TAB_ID__TRUCK_DIAGNOSTICS: 101,
    TAB_ID__TRUCK_PERFORMANCE: 20,
    TAB_ID__TYRES_AND_SAFETY: 21,
    TOUCHSCREEN__CLOCK: 10018,
    TOUCHSCREEN__DELAY_CAPTURE: 121,
    TOUCHSCREEN__EQUIPMENT_INSTALLATION: 120,
    TOUCHSCREEN__LOGGER_DOWN_DIALOG: 126,
    TOUCHSCREEN__MAINTENANCE_ICONS: 127,
    TOUCHSCREEN__MAINTENANCE_MODE: 141,
    TOUCHSCREEN__MATERIAL_GRADE_CONTROL: 119,
    TOUCHSCREEN__USER_PROFILES: 118,
    TYRES_AND_SAFETY__SHOW_PRINT_PDF_BUTTON: 22,
    WALLBOARD__HAUL_TRUCK_LEAGUE__SHOW_CORNER_CONFORMANCE: 63,
    WALLBOARD__HAUL_TRUCK_LEAGUE__SHOW_CORNER_COUNT: 59,
    WALLBOARD__HAUL_TRUCK_LEAGUE__SHOW_DUMP_CONFORMANCE: 10028,
    WALLBOARD__HAUL_TRUCK_LEAGUE__SHOW_PRODUCTIVITY_CONFORMANCE: 66,
    WALLBOARD__HAUL_TRUCK_LEAGUE__SHOW_PRODUCTIVITY_COUNT: 62,
    WALLBOARD__HAUL_TRUCK_LEAGUE__SHOW_RAMPS_CONFORMANCE: 65,
    WALLBOARD__HAUL_TRUCK_LEAGUE__SHOW_RAMPS_COUNT: 61,
    WALLBOARD__HAUL_TRUCK_LEAGUE__SHOW_ZONES_CONFORMANCE: 64,
    WALLBOARD__HAUL_TRUCK_LEAGUE__SHOW_ZONES_COUNT: 60,
    WALLBOARD__LOADER_LEAGUE__HAS_MULTIPLE_LOAD_UNIT_COLS: 55,
    WALLBOARD__LOADER_LEAGUE__SHOW_TOTAL_COLUMN: 104,
    WALLBOARD__LOADER_LEAGUE_TREND__SHOW_TOTAL_COLUMN: 105,
};
export const GROUP_IDS = {
    ADMIN: 2,
    NON_HUMAN_USER: 8,
};

export const featureTogglesByIdToByString = (idKeyedFeatureToggles) => {
    const featureKeysById = Object.entries(FEATURE_IDS).reduce((o, v) => {
        o[String(v[1])] = v[0];
        return o;
    }, {});
    const featureIdEntries = Object.entries(idKeyedFeatureToggles);
    return featureIdEntries.reduce((o, kv) => {
        o[featureKeysById[kv[0]]] = kv[1];
        return o;
    }, {});
};

export const featureTogglesByStringToById = (stringKeyedFeatureToggles) => {
    const featureStringEntries = Object.entries(stringKeyedFeatureToggles);
    return featureStringEntries.reduce((o, kv) => {
        o[FEATURE_IDS[kv[0]]] = kv[1];
        return o;
    }, {});
};
