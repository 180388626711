import { createSelector } from 'reselect';
import shiftUtils from '@rs/core/utils/shiftUtils';
import { timing as timingApp } from '@rs/core/application';
import { getMissingFilterResults } from '../../../Utils/Filters/filters';
import { filterShiftOptionsToReactSelectFormat } from '../../../Utils/Filters/filterOptionsToReactSelectFormat';
import { selectors as timingEquipmentUptimeSelectors } from '../Modules/timingEquipmentUptime';
import { selectors as filtersSelectors } from '../Modules/filters';
import { createMultiKeySortFn } from '../../../Lib/sortingUtils';
import { SELECT_STRING_DELIMITER } from '../../../Components/Select';

const sortTruckOptions = createMultiKeySortFn([
    { name: 'label', reverse: false },
]);

export const getFilterOptions = createSelector(
    filtersSelectors.getFilters,
    timingEquipmentUptimeSelectors.getAll,
    (filters, trucks) => {
        const truckOptions = timingApp.equipmentUptime
            .getUniqueEquipmentSiteNames(trucks)
            .map((truckName) => ({
                label: truckName,
                value: truckName,
            }))
            .sort(sortTruckOptions);

        const shifts = shiftUtils.getAllShiftShortNamesForDate(filters.Date);
        return {
            shifts: filterShiftOptionsToReactSelectFormat(
                shifts.rawShiftsInDay,
                getMissingFilterResults(filters.Shift, shifts.shiftShortNames),
            ),
            trucks: truckOptions,
        };
    },
);

export const getMapFiltersToAPIParams = createSelector(
    filtersSelectors.getFilters,
    (filters) => {
        if (!filters.Date) {
            return null;
        }
        const params = {
            TruckName: filters.Truck,
        };

        const shiftIdsSelectedShiftsHistory = shiftUtils.generateShiftIdRange(
            shiftUtils
                .createMomentInSiteTime(filters.Date)
                .subtract(14, 'days')
                .format(shiftUtils.DATE_FORMAT__DISPLAY),
            filters.Date,
            filters.Shift.split(SELECT_STRING_DELIMITER),
        );
        const shiftIdsSelectedShifts = shiftUtils.generateShiftIdRange(
            filters.Date,
            filters.EndDate,
            filters.Shift.split(SELECT_STRING_DELIMITER),
        );

        params.shiftIdsSelectedShiftsHistory = shiftUtils.getAllFirstLastFromArray(
            shiftIdsSelectedShiftsHistory,
        );
        params.shiftIdsSelectedShifts = shiftUtils.getAllFirstLastFromArray(
            shiftIdsSelectedShifts,
        );

        return params;
    },
);
