import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Section = {
    label: string;
    visibility: Visibility;
    items: Item[];
};

export type Item = {
    label: string;
    borderColor: string;
    fillColor: string;
    visibility: Visibility;
};

type Legend = {
    sections: Section[];
};

export type Visibility = 'VISIBLE' | 'HIDDEN';

export type State = Legend;

type SetSectionsPayload = Section[];

type ChangeSectionVisibilityPayload = {
    sectionLabel: string;
    visibility: Visibility;
};

type ChangeItemVisibilityPayload = {
    itemLabel: string;
    sectionLabel: string;
    visibility: Visibility;
};
export const createSliceWithName = (name: string, initialState: State) =>
    createSlice({
        name,
        initialState,
        reducers: {
            SetSections: (state, action: PayloadAction<SetSectionsPayload>) => {
                state.sections = action.payload;
            },
            ChangeSectionVisibility: (
                state,
                action: PayloadAction<ChangeSectionVisibilityPayload>,
            ) => {
                const { sectionLabel, visibility } = action.payload;
                const allSections = state.sections.map((section) => {
                    if (section.label === sectionLabel) {
                        section.visibility = visibility;
                    }
                    return section;
                });

                state.sections = allSections;
            },
            ChangeItemVisibility: (
                state,
                action: PayloadAction<ChangeItemVisibilityPayload>,
            ) => {
                const { sectionLabel, itemLabel, visibility } = action.payload;
                selectItemBySectionLabelAndItemLabel(
                    sectionLabel,
                    itemLabel,
                )(state).visibility = visibility;
            },
        },
    });

const selectSelf = (state: State) => state;

export const selectSections = (state: State) => selectSelf(state).sections;

export const selectSectionLabels = (state: State) =>
    selectSections(state).map((s) => s.label);

export const selectVisibleSectionLabels = (state: State) =>
    selectSections(state)
        .filter((s) => s.visibility === 'VISIBLE')
        .map((s) => s.label);

export const selectVisibleSections = (state: State) =>
    selectSections(state).filter((s) => s.visibility === 'VISIBLE');

export const selectSectionByLabel = (sectionLabel: string) => (state: State) =>
    selectSections(state).find((s) => s.label === sectionLabel)!;

export const selectItemsBySectionLabel = (sectionLabel: string) => (
    state: State,
) => selectSectionByLabel(sectionLabel)(state)?.items!;

export const selectVisibleItemLabelsBySectionLabel = (sectionLabel: string) => (
    state: State,
) =>
    selectItemsBySectionLabel(sectionLabel)(state)
        .filter((i) => i.visibility === 'VISIBLE')
        .map((i) => i.label);

export const selectItemLabelsBySectionLabel = (sectionLabel: string) => (
    state: State,
) => selectItemsBySectionLabel(sectionLabel)(state).map((i) => i.label);

export const selectItemBySectionLabelAndItemLabel = (
    sectionLabel: string,
    itemLabel: string,
) => (state: State) =>
    selectItemsBySectionLabel(sectionLabel)(state).find(
        (i) => i.label === itemLabel,
    )!;

export const selectBorderColorBySectionLabelAndItemLabel = (
    sectionLabel: string,
    itemLabel: string,
) => (state: State) =>
    selectItemBySectionLabelAndItemLabel(sectionLabel, itemLabel)(state)
        .borderColor;

export const selectFillColorBySectionLabelAndItemLabel = (
    sectionLabel: string,
    itemLabel: string,
) => (state: State) =>
    selectItemBySectionLabelAndItemLabel(sectionLabel, itemLabel)(state)
        .fillColor;
