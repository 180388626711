import React from 'react';
import DownIcon from '@ant-design/icons/DownOutlined';
import UpIcon from '@ant-design/icons/UpOutlined';
import PropTypes from 'prop-types';

const SortArrow = ({ sortBy, ascending, ownKey, ...otherProps }) => {
    if (sortBy !== ownKey) {
        return null;
    }
    if (ascending) {
        return <DownIcon {...otherProps} />;
    }
    return <UpIcon {...otherProps} />;
};

SortArrow.propTypes = {
    // What the currently selected sort by is
    sortBy: PropTypes.string.isRequired,
    // which direction it should display
    ascending: PropTypes.bool.isRequired,
    // String to compare against
    ownKey: PropTypes.string.isRequired,
};

export default SortArrow;
