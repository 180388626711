import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_RAMP_EVENTS_SUCCESS = `${namespace}/FETCH_RAMP_EVENTS_SUCCESS`;
    const fetchRampEventsSuccess = (resultsArray) =>
        action(FETCH_RAMP_EVENTS_SUCCESS, resultsArray);

    const FETCH_RAMP_EVENTS_ERROR = `${namespace}/FETCH_RAMP_EVENTS_ERROR`;
    const fetchRampEventsError = ({ error }) =>
        action(FETCH_RAMP_EVENTS_ERROR, { error });

    return {
        FETCH_RAMP_EVENTS_SUCCESS,
        fetchRampEventsSuccess,
        FETCH_RAMP_EVENTS_ERROR,
        fetchRampEventsError,
    };
}
