import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    selectItemLabelsBySectionLabel,
    selectVisibleSections,
    State,
} from './reduxUtil';
import { Item } from './Item';

export interface SectionPureProps {
    label: string;
    children: any;
}
export const SectionPure = ({ label, children }: SectionPureProps) => {
    return (
        <div className="Legend__Box__Polygons__Container">
            <div className="Legend__Box__Polygons__Container__Title">
                {label}
            </div>
            <div className="Legend__Box__Polygons__Container__Scale">
                <ul className="Legend__Box__Polygons__Container__Labels">
                    {children}
                </ul>
            </div>
        </div>
    );
};

export interface SectionProps {
    stateSelector: (state: any) => State;
    sectionLabel: string;
}

export const Section = ({ stateSelector, sectionLabel }: SectionProps) => {
    const itemLabels = useSelector((state) =>
        selectItemLabelsBySectionLabel(sectionLabel)(stateSelector(state)),
    );

    return (
        <SectionPure label={sectionLabel}>
            {itemLabels.map((label, i) => (
                <Item
                    stateSelector={stateSelector}
                    itemLabel={label}
                    sectionLabel={sectionLabel}
                    key={i}
                />
            ))}
        </SectionPure>
    );
};
