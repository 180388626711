import { wktAggregate } from '@rs/core/application';

const { getWKTResponseGeoJSON } = wktAggregate;

export default function createSelectors(statePath) {
    function getAll(state) {
        return statePath(state);
    }

    function getGeoJSON(state) {
        const wkt = getAll(state);
        return wkt && getWKTResponseGeoJSON(wkt.WKTLocuses);
    }

    return {
        getAll,
        getGeoJSON,
    };
}
