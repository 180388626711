import React, { ReactNode } from 'react';
import cn from 'classnames';

interface Props {
    className?: string;
    children?: ReactNode;
    [x: string]: any;
}

const PageControlsContainer = ({ className, ...props }: Props) => (
    <div className={cn('PageControlsContainer', className)} {...props} />
);

export default PageControlsContainer;
