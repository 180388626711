/**
 * Private function to check if the value is an unsortable type
 * @private
 * @param {any} value
 * @returns {boolean}
 */
export function valueIsNullType(value) {
    if (value === null || value === undefined || Number.isNaN(value)) {
        return true;
    }
    return false;
}

/**
 * Compares two numbers together
 * @param {number} valueA
 * @param {number} valueB
 * @returns {number}
 */
export function compareNumber(valueA, valueB) {
    if (!Number.isFinite(valueA)) {
        return 1;
    }
    if (!Number.isFinite(valueB)) {
        return -1;
    }
    return valueB - valueA;
}

/**
 * Compares two strings together
 * @param {string} valueA
 * @param {string} valueB
 * @returns {number}
 */
export function compareString(valueA, valueB) {
    if (typeof valueA !== 'string') {
        return 1;
    }
    if (typeof valueB !== 'string') {
        return -1;
    }
    return valueB.localeCompare(valueA);
}

/**
 * Creates a function that can be used with Array.sort() to compare object properties
 * Array.prototype.sort() expects a function which returns:
 * 0 if a === b
 * any positive number if a > b
 * any negative number if a < b
 * Values should be of the same type, non sortable values will be pushed to the end of the array
 * @param {string|Function} property The property to compare
 * @param {boolean} reverse true = [z-a], false = [a-z]
 * @returns {Function}
 */
export function createSortByPropertyFn(property, reverse = false) {
    return (obj1, obj2) => {
        const valueA = reverse
            ? selectValue(obj1, property)
            : selectValue(obj2, property);
        const valueB = reverse
            ? selectValue(obj2, property)
            : selectValue(obj1, property);
        if (valueA === valueB) {
            return 0;
        } else if (valueIsNullType(selectValue(obj1, property))) {
            // Ignoring the reversed values and always comparing the first value
            return 1;
        } else if (valueIsNullType(selectValue(obj2, property))) {
            // Ignoring the reversed values and always comparing the second value
            return -1;
        } else if (typeof valueA === 'number') {
            return compareNumber(valueA, valueB);
        }
        return compareString(valueA, valueB);
    };
}

/**
 * Creates a function that can be used with Array.sort() to compare multiple object properties
 * Supports multiple levels of sorting
 *
 * Example:
 * const sortFn = createMultiKeySortFn([{name: 'TotalConformance', reverse: true}, {name: 'Driver', reverse: false}])
 * array.sort(sortFn);
 *
 * @param {array} - sortProperties [{name: 'somename', reverse: false}]
 * @returns {Function}
 */
export function createMultiKeySortFn(sortProperties) {
    return (obj1, obj2) => {
        let i = 0;
        let result = 0;
        const numberOfProperties = sortProperties.length;
        // Try getting a different result from 0 (equal) as long as we have extra properties to compare
        while (result === 0 && i < numberOfProperties) {
            const { name, reverse = false } = sortProperties[i];
            result = createSortByPropertyFn(name, reverse)(obj1, obj2);
            i += 1;
        }
        return result;
    };
}

/**
 * Helper function to select a value using:
 * 1. A String to select a property on an object
 * 2. A Function to select a value
 * 3. A Primative value
 * @param objectOrValue
 * @param stringOrFn
 * @return {*}
 */
export function selectValue(objectOrValue, stringOrFn) {
    if (typeof stringOrFn === 'string') {
        return objectOrValue[stringOrFn];
    } else if (typeof stringOrFn === 'function') {
        return stringOrFn(objectOrValue);
    }
    return objectOrValue;
}
