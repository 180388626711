import * as React from 'react';

function SvgStatusBrokenDown(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <circle cx={512} cy={512} r={498.84} fill="#fff" />
            <path d="M512 50a462.14 462.14 0 01179.82 887.71A462.13 462.13 0 01332.18 86.29 459.06 459.06 0 01512 50m0-50C229.23 0 0 229.23 0 512s229.23 512 512 512 512-229.23 512-512S794.77 0 512 0z" />
            <path d="M418.63 474.2a10 10 0 01-12.88 1.38c-18.95-12.83-58.07-42.36-99.07-39.28-44.12 3.31-71.33-50.13-83.12-85.94a10 10 0 0114.36-11.88l61.77 34.4a10 10 0 0013.52-3.73L353.14 300a10 10 0 00-1.92-12.38c-10.09-9.23-31.81-28.53-54.63-45.31a10 10 0 014.72-18c136-17.28 120.21 49.31 124.08 88.3 3.19 32.13 41.06 72 52.44 86.92a10 10 0 01-.7 12.94zM553.5 589.2a10 10 0 00-1.45 11.59c8.77 16 46.28 71.57 49.56 104.62 3.87 39-12 105.58 124.08 88.3a10 10 0 004.72-18c-22.82-16.78-44.54-36.08-54.63-45.31a10 10 0 01-1.92-12.38l39.93-69.2a10 10 0 0113.52-3.74l61.77 34.41a10 10 0 0014.36-11.88c-11.78-35.81-39-89.25-83.12-85.94-41 3.08-82.22-37.74-101.37-50.57a10 10 0 00-12.95 1.51zM733.88 247.57l-128.22 73.6a10 10 0 00-5 7.85L596 384.9a10 10 0 01-2.9 6.24L394.65 589.62 372 567a10 10 0 00-14.14 0L249.05 675.84a64.13 64.13 0 000 90.7 66.22 66.22 0 0093.63 0l107.37-107.38a10 10 0 000-14.14l-22-22 198.56-198.49a10 10 0 016.13-2.89l55.4-5.21a10 10 0 007.71-4.93l74.23-127.66a10 10 0 00-1.58-12.09l-22.58-22.58a10 10 0 00-12.04-1.6z" />
        </svg>
    );
}

export default SvgStatusBrokenDown;
