import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const SORT_TABLE = `${namespace}/SORT_TABLE`;

    const sortTable = (sortingKey) => action(SORT_TABLE, { sortingKey });

    return {
        SORT_TABLE,
        sortTable,
    };
}
