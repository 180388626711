import { takeLatest, put, select, call, all } from 'redux-saga/effects';
import shiftUtils from '@rs/core/utils/shiftUtils';
import {
    actions as filtersActions,
    selectors as filtersSelectors,
} from '../TruckAlertsAnalysis/TruckHistory/Modules/filters';
import { actions as spinnerActions } from '../TruckAlertsAnalysis/TruckHistory/Modules/spinner';
import * as timingEquipmentUptimeModule from '../TruckAlertsAnalysis/TruckHistory/Modules/timingEquipmentUptime';
import * as truckAlertEventsModule from '../TruckAlertsAnalysis/TruckHistory/Modules/truckAlertEvents';
import { getMapFiltersToAPIParams } from '../TruckAlertsAnalysis/TruckHistory/Selectors/filters';
import {
    convertURLQueryStringToObject,
    updateURLQueryString,
} from '../Lib/queryStringUtils';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import * as resourceSagas from './resourceSagas';
import { API_ARRAY_DELIMITER } from '../Api';

export function* filterUpdated() {
    const filters = yield select(filtersSelectors.getFilters);
    // If dates are invalid go back to waiting for FILTER_UPDATED
    if (
        !shiftUtils.isDateStringValid(
            filters.Date,
            shiftUtils.DATE_FORMAT__VALIDATE,
        ) ||
        (filters.rangeSelected &&
            !shiftUtils.isDateStringValid(
                filters.EndDate,
                shiftUtils.DATE_FORMAT__VALIDATE,
            ))
    ) {
        return;
    }
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    yield all([call(getTruckAlertEvents), call(getTimingTruckUptime)]);
    updateURLQueryString(filters);
    yield put(spinnerActions.setSpinnerState(false));
}

export function* getTruckAlertEvents() {
    const { shiftIdsSelectedShiftsHistory, TruckName } = yield select(
        getMapFiltersToAPIParams,
    );
    if (!TruckName) return;
    const { response, error } = yield call(
        resourceSagas.getTruckAlertEventsByShiftId,
        {
            ShiftId: shiftIdsSelectedShiftsHistory.all.join(
                API_ARRAY_DELIMITER,
            ),
            TruckName,
        },
    );
    if (error) {
        return;
    }
    yield put(
        truckAlertEventsModule.actions.fetchTruckAlertEventsSuccess(response),
    );
}

export function* getTimingTruckUptime() {
    const { shiftIdsSelectedShifts } = yield select(getMapFiltersToAPIParams);
    const { response, error } = yield call(
        resourceSagas.getTimingEquipmentUptime,
        {
            ShiftId: shiftIdsSelectedShifts.all.join(API_ARRAY_DELIMITER),
        },
    );
    if (error) {
        return;
    }
    yield put(
        timingEquipmentUptimeModule.actions.fetchTimingEquipmentUptimeSuccess(
            response,
        ),
    );
}

export function* updateFiltersWithURLParams() {
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const urlFilterValues = convertURLQueryStringToObject();
    yield put(filtersActions.setFiltersWithUrlParams(urlFilterValues));
    yield call(getTimingTruckUptime);
    const { TruckName } = yield select(getMapFiltersToAPIParams);
    if (TruckName) {
        yield call(getTruckAlertEvents);
    }
    yield put(spinnerActions.setSpinnerState(false));
}

export default function* watch() {
    yield all([
        takeLatest(
            filtersActions.UPDATE_FILTERS_WITH_URL_PARAMS,
            updateFiltersWithURLParams,
        ),
        takeLatest(filtersActions.FILTER_UPDATED, filterUpdated),
    ]);
}
