import { createSelector } from 'reselect';
import * as networkCoverageReportModule from '../Modules/networkCoverageReport';

const getCurrentH3Index = createSelector(
    networkCoverageReportModule.selectors.getAll,
    (netWorkCoverageState) => {
        const { h3Resolution } = netWorkCoverageState;

        return h3Resolution;
    },
);
export default getCurrentH3Index;
