import * as React from 'react';

function SvgSoilCompactor(props) {
    return (
        <svg viewBox="0 0 998 561" {...props}>
            <g>
                <path
                    d="m873.815,370.52498l-260.69,0l0,-92.28l-274.38,0l0,-111.14l-239.39,0l-70.76,140.87l0,119.09l76.16,0c0,-55.41 45.08,-100.5 100.5,-100.5s100.5,45.08 100.5,100.5l239.58,0l0,56.54l330.77,0l92.3,-26.99l0,-63.15l-94.59,-22.93l0,-0.01z"
                    fill="#fff200"
                />
                <path d="m993.395,373.80498l-39.86,-9.66c-23.08,-45.99 -70.67,-77.57 -125.64,-77.57c-47.19,0 -88.95,23.27 -114.42,58.96l-75.36,0l0,-92.28l-18.84,0l-69.22,-250.82l-238.44,0l1.39,139.68l-60.63,0l0,-94.47l-42.95,-45.21l-36.25,34.44l29.2,30.74l0,74.5l-118.44,0l-80.34,159.94l0,150.01l63.38,0c11.79,65.67 69.22,115.5 138.28,115.5s126.48,-49.83 138.28,-115.5l176.8,0l0,56.54l193.15,0c25.48,35.69 67.23,58.96 114.43,58.96c56.34,0 104.93,-33.17 127.32,-81.05l38.17,-11.16l0,-101.55l-0.01,0zm-628.81,-127.23l0,-36.83l58.23,0l0,36.83l-58.23,0zm145.93,-194.14l48.08,194.14l-85.78,0l0,-86.83l-108.23,0l-2.97,-107.31l148.9,0zm-305.27,435.13c-33.36,0 -60.5,-27.14 -60.5,-60.5s27.14,-60.5 60.5,-60.5s60.5,27.14 60.5,60.5s-27.14,60.5 -60.5,60.5zm138.28,-85.5c-11.79,-65.67 -69.22,-115.5 -138.28,-115.5s-126.48,49.83 -138.28,115.5l-13.38,0l0,-88.16l61.18,-121.79l198.72,0l0.25,24.95l0,86.19l274.37,0l0,98.82l-244.59,0l0.01,-0.01zm226.8,56.54l0,-6.54l67.79,0l0,-56.54l52.85,0c-2.33,10.14 -3.56,20.69 -3.56,31.54s1.23,21.4 3.56,31.54l-120.63,0l-0.01,0z" />
            </g>
        </svg>
    );
}

export default SvgSoilCompactor;
