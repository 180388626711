export interface HaulTruckFillFactor {
    CycleSummariesShiftDataId: number;
    EvaluatedPassCount: number | null;
    EventTime: number;
    HaulTruckCustomisation: string;
    HaulTruckModel: string;
    HaulTruckSiteName: string;
    HaulTruckType: string;
    IsBadPayload: number;
    Latitude: number;
    LoadUnitCategory: string;
    LoadUnitDerivedName: string;
    LoadUnitModel: string;
    LoadUnitOperatorCrew: string;
    LoadUnitReportedName: string;
    LoadUnitSiteName: string;
    LoadUnitType: string;
    Longitude: number;
    MaterialClass: string;
    MaterialType: string;
    MeasuredPassCount: number;
    OperatorDisplayName: string;
    PayloadMaxMine: number | null;
    PayloadMeasured: number;
    PayloadReported: number;
    PayloadReportedSource: Source;
    PayloadTarget: number;
    ReportedPassCount: number;
    ReportedPassCountSource: Source;
    Shift: string;
    ShiftId: number;
    StartTime: Date;
    StopTime: Date;
}

export enum Source {
    MaxMine = 'MaxMine',
    Measured = 'Measured',
}

/*
 * Helper function to convert the HaulTruckModel / HaulTruckCustomisation
 * into a usable single string
 */
export function truckModelCustomisationToString(
    HaulTruckModel,
    HaulTruckCustomisation,
): string {
    if (HaulTruckCustomisation) {
        return `${HaulTruckModel} [${HaulTruckCustomisation}]`;
    }
    return HaulTruckModel;
}

/*
 * Helper function to convert the a stringified version of
 * the HaulTruckModel / HaulTruckCustomisation back into its parts
 */
export function truckModelCustomisationFromString(
    str,
): {
    HaulTruckModel: string;
    HaulTruckCustomisation: string;
} {
    return {
        HaulTruckModel: str.split('[')[0].trim(),
        HaulTruckCustomisation: (str.split('[')[1] || '').slice(0, -1),
    };
}

/*
 * Helper function to convert an array of stringified HaulTruckModels/Customisations
 * into 2 arrays, one containing the Models, and the other the Customisations
 */
export function getModelsAndCustomisations(
    array: string[],
): {
    Models: string[];
    Customisations: string[];
} {
    const Models = [];
    const Customisations = [];
    array.forEach((row) => {
        const {
            HaulTruckModel,
            HaulTruckCustomisation,
        } = truckModelCustomisationFromString(row);
        if (HaulTruckModel) {
            // If we have a valid truck model
            Models.push(HaulTruckModel);
            // Push a null string so that we can query APIs for trucks
            // explicitly without a customisation
            Customisations.push(HaulTruckCustomisation || 'null');
        }
    });
    return {
        Models,
        Customisations,
    };
}
