import React from 'react';
import PropTypes from 'prop-types';
import { typeConformanceLegendRangeBands } from '../../types';

const addValueSuffix = (suffix) => (value) => `${value}${suffix}`;

const ConformanceLegend = ({ rangeBands, ltSymbol, gtSymbol, valueSuffix }) => {
    const formatValue = addValueSuffix(valueSuffix);
    return (
        <div className="ConformanceLegend">
            {rangeBands.map((band, i) => {
                let displayCharacters;
                if (band.text) {
                    displayCharacters = [band.text];
                } else {
                    displayCharacters = [
                        formatValue(band.min),
                        '-',
                        formatValue(band.max),
                    ];

                    if (!Number.isFinite(band.min)) {
                        displayCharacters[0] = '';
                        displayCharacters[1] = ltSymbol;
                    }

                    if (!Number.isFinite(band.max)) {
                        displayCharacters = [
                            '',
                            gtSymbol,
                            formatValue(band.min),
                        ];
                    }
                }

                return (
                    <div key={i} className="ConformanceLegend__Row">
                        <svg className="ConformanceLegend__Icon">
                            <rect
                                width={20}
                                height={20}
                                className={band.className}
                            />
                        </svg>
                        <div className="ConformanceLegend__Label">
                            {displayCharacters.join(' ').trim()}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

ConformanceLegend.defaultProps = {
    valueSuffix: '%',
    ltSymbol: 'Less than',
    gtSymbol: 'Greater than',
};

ConformanceLegend.propTypes = {
    /** The value is compared against these min/max values, first match is used */
    rangeBands: typeConformanceLegendRangeBands,
    /** The value to use for less-than */
    ltSymbol: PropTypes.string,
    /** The value to use for greater-than */
    gtSymbol: PropTypes.string,
    /** The character to use at the end of the value */
    valueSuffix: PropTypes.string,
};

export default ConformanceLegend;
