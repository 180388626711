export interface Activity {
    DestinationLatitude: number;
    DestinationLongitude: number;
    DestinationName: string;
    EngineRunning: 'Yes' | 'No' | 'Unknown';
    EquipmentSiteName: string;
    KeyOn: 'Yes' | 'No' | 'Unknown';
    MachineMoving: 'Yes' | 'No' | 'Unknown';
    ShiftDate: string;
    ShiftType: string;
    SourceLatitude: number;
    SourceLongitude: number;
    SourceName: string;
    StartTime: string;
    // The Status property is put in by javascript, not from the API
    Status?: ActivityClassification;
    StopTime: string;
    TotalTime: number;
}

export interface ActivitiesByEquipmentName {
    [key: string]: Array<Activity>;
}

export enum ActivityClassification {
    MOVING = 'Moving',
    STATIONARY = 'Stopped',
    UNKNOWN = 'Unknown',
    NO_GPS = 'No GPS',
    NO_UPDATES = 'No updates',
}

export interface EquipmentDelay {
    equipmentSiteName: string;
    location: string;
    status: ActivityClassification;
    duration: number;
    lastUpdated?: number;
}

export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
