export const ROLE__DEV_VIEWER = 'DevViewer';
export const ROLE__ADMINSTRATOR = 'ROLE__ADMINISTRATOR';
export const ROLE__FULL_ACCESS = 'FullAccess';
export const ROLE__MAINTENANCE = 'Maintenance';
export const ROLE__OPERATOR_TRUCK = 'OperatorTruck';
export const ROLE__OPERATOR_LOADER = 'OperatorLoader';
/* ToDo - Update centralAPI to do things differently or make a role called S32WMCManager, or do permissions differently altogether. */
export const ROLE__TELFER__MANAGER = 'TelferManager';
export const ROLE__MANAGER = 'Manager';
export const ROLE__OPERATOR = 'Operator';
export const ROLE__TELFER__SITE_CHAMPION = 'Telfer1';
export const ROLE__TELFER__OPERATOR_TRUCK = 'Telfer2';
export const ROLE__PRODUCTION = 'Production';
