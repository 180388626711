import L from 'leaflet';

// Creates a new polyline type extending the base polyline with some
// additional methods to support markerClusterGroup: getLatLng and setLatLng methods.
L.PolylineClusterable = L.Polyline.extend({
    _associatedData: {},
    _originalInitialize: L.Polyline.prototype.initialize,
    _originalOnAdd: L.Polyline.prototype.onAdd,

    initialize(latLngs, options) {
        this._originalInitialize(latLngs, options);
        this._latlng = this.getBounds().getCenter();
    },

    getLatLng() {
        return this._latlng;
    },

    // dummy method.
    /* eslint-disable @typescript-eslint/no-empty-function */
    setLatLng() {},

    /**
     * Setter method to bind data into Leaflet polyline object
     * @param {*} data
     */
    setAssociatedData(data) {
        this._associatedData = {
            ...data,
        };
    },

    /**
     * Getter for binded data
     */
    getAssociatedData() {
        return this._associatedData;
    },
});
