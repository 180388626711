import { createReducer, action } from '../../Lib/reduxUtils';
import namespace from './namespace';

function createActions(ns) {
    const SEARCH_BOX_UPDATED = `${ns}/SEARCH_BOX_UPDATED`;
    const searchBoxUpdated = (value) => action(SEARCH_BOX_UPDATED, { value });

    const SEARCH_BOX_CLEARED = `${ns}/SEARCH_BOX_CLEARED`;
    const searchBoxCleared = () => action(SEARCH_BOX_CLEARED);

    return {
        SEARCH_BOX_UPDATED,
        searchBoxUpdated,
        SEARCH_BOX_CLEARED,
        searchBoxCleared,
    };
}

function createSelectors(statePath) {
    function get(state) {
        return statePath(state);
    }

    function isPartOfSearchTerm(state, equipmentName) {
        const searchTerm = get(state);
        if (!searchTerm || !equipmentName) {
            return false;
        }
        const searchTermLower = searchTerm.toLowerCase();
        const equipmentNameLower = equipmentName.toLowerCase();
        return equipmentNameLower.includes(searchTermLower);
    }

    return {
        get,
        isPartOfSearchTerm,
    };
}

export const actions = createActions(namespace);
export const selectors = createSelectors((state) => state[namespace].searchBox);

export const initialState = '';
export const onSearchboxUpdated = (state, { value }) => {
    return value;
};

function onSearchBoxCleared() {
    return initialState;
}

export const reducer = createReducer(initialState, {
    [actions.SEARCH_BOX_UPDATED]: onSearchboxUpdated,
    [actions.SEARCH_BOX_CLEARED]: onSearchBoxCleared,
});
