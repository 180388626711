import createReducer from '../../Modules/init/reducer';
import createSelectors from '../../Modules/init/selectors';
import { actions } from './wiki';
import namespace from './namespace';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].status;

/**
 * Create the module parts
 */

export const reducer = createReducer({
    onRequest: actions.FETCH_WIKI_REQUESTED,
    onSuccess: actions.FETCH_WIKI_SUCCESS,
    onError: actions.FETCH_WIKI_ERROR,
});

export const selectors = createSelectors(statePath);
