/**
 * Checks if a node is part of the same tree
 * @param {node} - node to check
 * @param {root} - the root to check if the node belongs to it
 * @returns {boolean}
 */
export function isNodeInRoot(node, root) {
    let element = node;
    while (element) {
        if (element === root) {
            return true;
        }
        element = element.parentNode;
    }
    return false;
}

export function offset(el) {
    const rect = el.getBoundingClientRect();
    const scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

/**
 * Finds out the size of the scrollbar width
 * @returns {number}
 */
export function measureScrollbar() {
    const body = document.body;
    const scrollDiv = document.createElement('div');
    scrollDiv.className = 'modal-scrollbar-measure';
    body.append(scrollDiv);
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    scrollDiv.remove();
    return scrollbarWidth;
}
