import { createSelector } from 'reselect';
import {
    selectSelectedEquipmentClasses,
    selectSelectedEquipmentSubClasses,
    selectSelectedEquipmentModels,
    selectstate,
} from '../Modules/equipmentFilters';

const getEquipmentFilters = createSelector(
    selectSelectedEquipmentClasses(selectstate),
    selectSelectedEquipmentSubClasses(selectstate),
    selectSelectedEquipmentModels(selectstate),
    (classes, subClasses, models) => {
        return {
            classes,
            subClasses,
            models,
        };
    },
);

export default getEquipmentFilters;
