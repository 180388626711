import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import errorLogging from '@rs/core/utils/errorLogging';
import { batchDispatchMiddleware } from 'redux-batched-actions';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './createRootReducer';
import rootSaga from '../Sagas';
import { appInitRequest } from './Actions';
import * as controlRoomActions from '../ActivityManagement/Actions';
import { getItem } from '../Lib/sessionStorage';
import queryString from '@rs/core/utils/queryStringUtils';
import history from './createHistory';

queryString.init(history);
const isProduction = process.env.NODE_ENV === 'production';

const configureStore = (preloadedState, storeHistory = history) => {
    const sagaMiddleware = createSagaMiddleware({
        onError: errorLogging.logException,
    });

    const middlewares = applyMiddleware(
        routerMiddleware(storeHistory),
        sagaMiddleware,
        batchDispatchMiddleware,
    );

    // If production don't use devtools
    const composeEnhancers = isProduction
        ? compose
        : // In development, check if redux devtools is installed, otherwise fallback to redux compose
          /* eslint-disable no-underscore-dangle */
          window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    /* eslint-disable no-underscore-dangle */
    const storeEnhancers = composeEnhancers(middlewares);

    const store = createStore(
        createRootReducer(storeHistory),
        preloadedState,
        storeEnhancers,
    );

    sagaMiddleware.run(rootSaga);

    store.dispatch(appInitRequest());
    store.dispatch(
        controlRoomActions.initialiseCircuitOrder(getItem('circuitsOrder')),
    );

    return store;
};

export default configureStore;
