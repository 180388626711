export default function createSelectors(statePath) {
    function getAllAsArray(state) {
        const slice = statePath(state);
        return slice.features;
    }

    return {
        getAllAsArray,
    };
}
