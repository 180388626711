import { all, takeLatest, put, call } from 'redux-saga/effects';
import { actions } from '../ManageUser';
import { forwardTo } from '../App/createHistory';
import * as resourceSagas from './resourceSagas';
import { actions as spinnerActions } from '../ManageUser/Modules/spinner';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import { downloadFile, addExtensionToFilename } from '../Utils/files';
import { notification } from '@rs/core/falcon';
import {
    AUTH_TOKEN_BACKUP_KEY,
    AUTH_TOKEN_KEY,
    getItem,
    REFRESH_TOKEN_KEY,
} from '../Lib/localStorage';
import * as storage from '../Lib/localStorage';
import { tokenValidate } from '../Auth/Actions';
import errorLogging from '@rs/core/utils/errorLogging';

function* loadUsers() {
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const { response, error } = yield call(resourceSagas.getUserList);

    if (error) {
        yield all([
            put(actions.manageUserError(error.message)),
            put(spinnerActions.setSpinnerState(false)),
        ]);
        return;
    }

    response.rows.forEach((row) => {
        row.EmployeeId = String(row.EmployeeId);
        if (row.EmployeeId === 'null') {
            row.EmployeeId = '';
        }
        if (!row.UserName) {
            row.UserName = '';
        }
    });

    yield all([
        put(actions.loadUsersList(response)),
        put(spinnerActions.setSpinnerState(false)),
    ]);
}

/* eslint-disable require-yield */
// Could move this elsewhere, just call inside the component
// or use a <Link> component from react-router
// possible to use it here in the future
export function* linkToPage({ payload }) {
    forwardTo(
        `${payload.url}?email=${encodeURIComponent(payload.userData.Email)}`,
    );
}

export function* assignGroups({ payload }) {
    forwardTo(
        `${payload.url}?email=${encodeURIComponent(payload.userData.Email)}`,
    );
}
/* eslint-enable */

export function* deleteUser({ payload }) {
    yield put(
        spinnerActions.setSpinnerState(
            true,
            LOADING_MESSAGES.LOADING__PROCESSING,
        ),
    );
    const { error } = yield call(resourceSagas.postDeleteUser, {
        Email: payload.userData.Email,
    });
    if (error) {
        yield all([
            put(actions.manageUserError(error.message)),
            put(spinnerActions.setSpinnerState(false)),
        ]);
        return;
    }

    yield all([
        put(actions.requestUsersList()),
        put(spinnerActions.setSpinnerState(false)),
    ]);
}

export function* exportUsersAsCSV() {
    const { response, error } = yield call(resourceSagas.getUserProfiles, {
        asCSV: true,
    });

    if (error) {
        yield all([put(actions.manageUserError(error.message))]);
        return;
    }

    const filename = addExtensionToFilename('UserProfiles', 'csv');
    downloadFile(response, filename);
}

export function* stopImpersonateUser() {
    try {
        yield put(
            spinnerActions.setSpinnerState(
                true,
                LOADING_MESSAGES.LOADING__PROCESSING,
            ),
        );

        const backupAccessToken = yield call(getItem, AUTH_TOKEN_BACKUP_KEY);

        const refreshToken = yield call(getItem, REFRESH_TOKEN_KEY);

        // 1. restore the existing token
        yield call(storage.clear);
        yield call(storage.setItem, storage.AUTH_TOKEN_KEY, backupAccessToken);
        yield call(storage.setItem, storage.REFRESH_TOKEN_KEY, refreshToken);

        // 2. stop the loading spinner and redirect user to home page
        yield put(spinnerActions.setSpinnerState(false));
        // redirect to the target user home page
        yield put(
            tokenValidate({
                token: backupAccessToken,
                refreshToken,
            }),
        );
    } catch (e) {
        errorLogging.logException(new Error('Unable to stop impersonate.'));
    }
}

export function* impersonateUser({ payload }) {
    try {
        yield put(
            spinnerActions.setSpinnerState(
                true,
                LOADING_MESSAGES.LOADING__PROCESSING,
            ),
        );

        const currentAccessToken = yield call(getItem, AUTH_TOKEN_KEY);

        const result = yield call(resourceSagas.getImpersonateToken, {
            Email: payload.usernameToBeImpersonated,
            Password: payload.currentUserPassword,
        });
        if (result.error) {
            yield put(spinnerActions.setSpinnerState(false));
            yield put(
                notification.warning({
                    message: `Unable to impersonate user ${payload.usernameToBeImpersonated}`,
                    // Don't close automatically
                    duration: 0,
                }),
            );
            return;
        }

        // 1. backup the existing token
        yield call(
            storage.setItem,
            storage.AUTH_TOKEN_BACKUP_KEY,
            currentAccessToken,
        );

        // 2. replace the impersonate token in local storage
        yield call(
            storage.setItem,
            storage.AUTH_TOKEN_KEY,
            result.response.access_token,
        );

        // 3. stop the loading spinner and redirect user to home page
        yield put(spinnerActions.setSpinnerState(false));
        // redirect to the target user home page
        yield put(
            tokenValidate({
                token: result.response.access_token,
                refreshToken: result.response.refresh_token,
            }),
        );
    } catch (e) {
        errorLogging.logException(
            new Error(
                `Unable to start impersonate as ${payload.usernameToBeImpersonated}`,
            ),
        );
    }
}

export default function* watch() {
    yield all([
        takeLatest(actions.LINK_TO_PAGE, linkToPage),
        takeLatest(actions.ASSIGN_GROUPS, assignGroups),
        takeLatest(actions.REQUEST_USERS_LIST, loadUsers),
        takeLatest(actions.DELETE_USER, deleteUser),
        takeLatest(actions.EXPORT_USERS_AS_CSV, exportUsersAsCSV),
        takeLatest(actions.IMPERSONATE_USER, impersonateUser),
        takeLatest(actions.STOP_IMPERSONATE_USER, stopImpersonateUser),
    ]);
}
