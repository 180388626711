export default function createSelectors(statePath: any) {
    function getAll(state: any) {
        const slice = statePath(state);
        // const overallScore = ...
        return {
            ...slice,
            // overallScore
        };
    }

    function getPreviousState(state: any) {
        const all = getAll(state);
        return all.previousState;
    }

    return {
        getAll,
        getPreviousState,
    };
}
