/**
 * Returns an array of driver unique driver ids from an array of worker names
 * @param workers
 * @constructor
 */
export function operatorNamesToUniqueId(
    workers: (number | string)[],
): (number | string)[] {
    return workers.map((worker) => operatorNameToUniqueId(worker));
}

/**
 * Returns the unique driver id from a worker name
 * @param worker
 * @constructor
 */
export function operatorNameToUniqueId(worker: string | number): string {
    const workerName = worker.toString();
    const parts = workerName.match(/.*\((\d+)\)/);

    return (parts && String(parts[1])) || '-1';
}
