import moment from 'moment';

/**
 * Functions to format mmUnits into display units
 * @type {{toKilometersPerHour(*): *}}
 */

const metersPerSecond = {
    /**
     * Convert value to kilometers per hour
     * @param {number} value - The value in meters per second
     * @return {number}
     */
    toKilometersPerHour(value) {
        if (!Number.isFinite(value)) {
            return NaN;
        }
        return value * 3.6;
    },
};

const revolutionsPerSecond = {
    /**
     * Convert value to revolutions per minute
     * @param {number} value - The value in revolutions per second
     * @return {number}
     */
    toRevolutionsPerMinute(value) {
        if (!Number.isFinite(value)) {
            return NaN;
        }
        return value * 60;
    },
};

/**
 * Calcualtes a value into a new metric
 * @param {number} value
 * @param {object} unit
 * @return {*|number|*}
 */
function convertMMUnit(value, unit) {
    if (unit && unit.name && unit.name.long) {
        switch (unit.name.long) {
            case 'revolutions per second':
                return revolutionsPerSecond.toRevolutionsPerMinute(value);
            case 'metres per second':
                return metersPerSecond.toKilometersPerHour(value);
            default:
                return value;
        }
    }
    return value;
}

/**
 * Gets the short & long names for a unit based on their metric
 * @param {string} metric
 * @return {*|{short: string, long: string}}
 */
function getUnitForMetric(metric) {
    const meters = {
        short: 'm',
        long: 'Meters',
    };

    const kilometersPerHour = {
        short: 'km/h',
        long: 'Kilometers per hour',
    };

    const litresPerSecond = {
        short: 'L/s',
        long: 'Litres per second',
    };

    const percent = {
        short: '%',
        long: 'Percent',
    };

    // We may use this in the figure again
    /* eslint-disable no-unused-vars */
    const revolutionsPerSec = {
        short: 'RPS',
        long: 'Revolutions per second',
    };
    /* eslint-enable */

    const revolutionsPerMinute = {
        short: 'RPM',
        long: 'Revolutions per minute',
    };

    const kilopascals = {
        short: 'kPa',
        long: 'Kilopascals',
    };

    const megaPascals = {
        short: 'MPa',
        long: 'Megapascals',
    };

    const celsius = {
        short: 'C',
        long: 'Celsius',
    };

    const tonnes = {
        short: 't',
        long: 'Tonnes',
    };

    const noUnit = {
        short: '',
        long: '',
    };

    const metersPerSecondPerSecond = {
        short: `m/s${String.fromCharCode(178)}`,
        long: 'Meters per second per second',
    };

    const per1Meter = {
        short: '1/m',
        long: 'Per one meter',
    };

    const map = {
        air_filter_1_pressure_loss: kilopascals,
        air_filter_2_pressure_loss: kilopascals,
        air_filter_3_pressure_loss: kilopascals,
        air_filter_4_pressure_loss: kilopascals,
        ambient_air_temp: celsius,
        auto_retarder_state: noUnit,
        brake_cooling_pump: revolutionsPerSec,
        c_o_m_p_lower_tolerance_speed: kilometersPerHour,
        c_o_m_p_upper_tolerance_speed: kilometersPerHour,
        c_o_m_p_x_y_accel_l_p_f: metersPerSecondPerSecond,
        c_o_m_p_x_y_speed: kilometersPerHour,
        c_o_m_p_x_y_velocity: kilometersPerHour,
        curvature: per1Meter,
        d_c_o_m_p_x_y_dist: meters,
        d_fuel: litresPerSecond,
        engine_fuel_level: percent,
        engine_fuel_filter_pressure_differential: kilopascals,
        engine_crankcase_pressure: kilopascals,
        engine_desired_speed: revolutionsPerMinute,
        engine_fuel_actuator_1_control_command: noUnit,
        engine_fuel_pressure: kilopascals,
        engine_injector_metering_rail_1_pressure: megaPascals,
        engine_intake_manifold_1_temperature: celsius,
        engine_load: percent,
        engine_oil_pressure: kilopascals,
        engine_percent_torque: percent,
        engine_speed: revolutionsPerMinute,
        exhaust_gas_temperature_1: celsius,
        exhaust_gas_temperature_2: celsius,
        front_brake_oil_temperature: celsius,
        ground_speed: kilometersPerHour,
        l_f_brake_oil_temperature: celsius,
        l_r_brake_oil_temperature: celsius,
        left_front_strut_pressure: kilopascals,
        left_rear_strut_pressure: kilopascals,
        left_wheel_speed: revolutionsPerMinute,
        manual_retarder_state: noUnit,
        manual_retarder: percent,
        park_brake: noUnit,
        reported_payload: tonnes,
        payload_vehicle_primary: tonnes,
        r_f_brake_oil_temperature: celsius,
        r_r_brake_oil_temperature: celsius,
        real_good_p_v_t_m: noUnit,
        rear_brake_oil_temperature: celsius,
        retarder: percent,
        retarder_brake_light: noUnit,
        retarder_mode: noUnit,
        right_front_strut_pressure: kilopascals,
        right_rear_strut_pressure: kilopascals,
        right_wheel_speed: revolutionsPerMinute,
        throttle_position: percent,
        trans_current_gear: noUnit,
        trans_selected_gear: noUnit,
        turbo_1_inlet_pressure: kilopascals,
        turbo_2_inlet_pressure: kilopascals,
        turbo_3_inlet_pressure: kilopascals,
        turbo_4_inlet_pressure: kilopascals,
        turbo_boost_pressure: kilopascals,
        x: meters,
        y: meters,
        spray_valve_left: percent,
        spray_valve_centre: percent,
        spray_valve_right: percent,
        water_pump_active: percent,
    };

    const units = map[metric];
    return units || noUnit;
}

//
/**
 * Converts a given number of minutes into a friendly string:
 *      getHoursAndMinutes(25) = "25 min"
 *      getHoursAndMinutes(63) = "1 h 3 min"
 *      getHoursAndMinutes(150000) = "24+ h"
 *      getHoursAndMinutes(125) = "2 h 5 min"
 *      getHoursAndMinutes(125, 2) = "2+"
 *
 * @param {number} minutes - the number of minutes
 * @param {number} maxHours - the total number of hours to show before showing a '+' sign (default 24)
 * @returns {string}
 */
const getHoursAndMinutes = (minutes, maxHours = 24) => {
    const momentDuration = moment.duration(minutes, 'minutes');
    const hours = Math.floor(momentDuration.asHours());
    const minutesRemainder = momentDuration.minutes();

    let formattedString = '';
    if (hours >= maxHours) {
        return `${maxHours}+ h`;
    } else if (hours > 0) {
        formattedString += `${hours} h `;
    }
    formattedString += `${minutesRemainder} min`;
    return formattedString;
};

export {
    metersPerSecond,
    revolutionsPerSecond,
    convertMMUnit,
    getUnitForMetric,
    getHoursAndMinutes,
};
