import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_EQUIPMENT_LATENCY_HISTORIC_SUCCESS = `${namespace}/FETCH_EQUIPMENT_LATENCY_HISTORIC_SUCCESS`;
    const fetchEquipmentLatencyHistoricSuccess = (response) =>
        action(FETCH_EQUIPMENT_LATENCY_HISTORIC_SUCCESS, response);

    const FETCH_EQUIPMENT_LATENCY_HISTORIC_ERROR = `${namespace}/FETCH_EQUIPMENT_LATENCY_HISTORIC_ERROR`;
    const fetchEquipmentLatencyHistoricError = (response) =>
        action(FETCH_EQUIPMENT_LATENCY_HISTORIC_ERROR, response);

    return {
        FETCH_EQUIPMENT_LATENCY_HISTORIC_SUCCESS,
        fetchEquipmentLatencyHistoricSuccess,
        FETCH_EQUIPMENT_LATENCY_HISTORIC_ERROR,
        fetchEquipmentLatencyHistoricError,
    };
}
