import { createSelector } from 'reselect';
import * as networkCoverageReportModule from '../Modules/networkCoverageReport';

const getAvailableH3ResolutionIndex = createSelector(
    networkCoverageReportModule.selectors.getAll,
    (netWorkCoverageState) => {
        const { availableH3ResolutionIndex } = netWorkCoverageState;
        return availableH3ResolutionIndex;
    },
);
export default getAvailableH3ResolutionIndex;
