import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@rs/core/falcon';

const AlertBox = (props) => {
    const { errorMessage, children, alertType } = props;
    const displayContent = children || errorMessage || undefined;
    if (!displayContent) return null;
    return (
        <div style={{ marginTop: 16, marginBottom: 8 }}>
            <Alert
                style={{ borderRadius: 4 }}
                closable={false}
                type={alertType || 'error'}
                message={displayContent}
            />
        </div>
    );
};

AlertBox.propTypes = {
    children: PropTypes.node,
    alertType: PropTypes.string,
    errorMessage: PropTypes.string,
};

export default AlertBox;
