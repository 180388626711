import { flatten } from '../../../Lib/unFlattenTree';

const defaultOutputFn = ({ Children, ...everythingElse }) => everythingElse;

/**
 * Flattens the minesiteAreasTree or subsection into an array
 * @param minesiteAreasTree - The whole tree or a piece
 * @param outputFn - Format the value to end up in the array, by default excludes the Children key
 */
export default (minesiteAreasTree, outputFn = defaultOutputFn) => {
    let treeData;
    if (!minesiteAreasTree) {
        treeData = [];
    } else if (!Array.isArray(minesiteAreasTree)) {
        treeData = [minesiteAreasTree];
    }
    return flatten(treeData, (node) => node.Children, outputFn);
};
