import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import Row from '../Row/Row';
import CONSTANTS from '../../constants';

const TruckHoldingStatePanel = (props) => (
    <div className={'TruckHoldingStatePanel'} {...props} />
);

const Header = (props) => <Row vgutter={CONSTANTS.spacing.sm} {...props} />;

const HeaderLeft = (props) => (
    <Col
        className={'TruckHoldingStatePanel__HeaderLeft'}
        span={12}
        {...props}
    />
);

const HeaderRight = (props) => (
    <Col
        span={12}
        className={'TruckHoldingStatePanel__HeaderRight'}
        {...props}
    />
);

const Content = (props) => (
    <Row>
        <Col span={24} {...props} />
    </Row>
);

TruckHoldingStatePanel.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

Header.propTypes = Row.propTypes;
HeaderLeft.propTypes = Col.propTypes;
HeaderRight.propTypes = Col.propTypes;
Content.propTypes = {
    ...Col.propTypes,
    children: PropTypes.node,
};

TruckHoldingStatePanel.Header = Header;
TruckHoldingStatePanel.Header.displayName = 'TruckHoldingStatePanel.Header';
TruckHoldingStatePanel.HeaderRight = HeaderRight;
TruckHoldingStatePanel.HeaderRight.displayName =
    'TruckHoldingStatePanel.HeaderRight';
TruckHoldingStatePanel.HeaderLeft = HeaderLeft;
TruckHoldingStatePanel.HeaderLeft.displayName =
    'TruckHoldingStatePanel.HeaderLeft';
TruckHoldingStatePanel.Content = Content;
TruckHoldingStatePanel.Content.displayName = 'TruckHoldingStatePanel.Content';

export default TruckHoldingStatePanel;
