import React from 'react';
import { Actions, Row, State } from './redux/redux';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row as AntdRow } from 'antd';
import AntdButton from '../Button/Button'
import Select from 'react-select';
import {
    selectEquipmentClasses,
    selectEquipmentModels,
    selectEquipmentSubClasses,
    selectSelectedEquipmentClasses,
    selectSelectedEquipmentModels,
    selectSelectedEquipmentSubClasses,
} from './selectors/selectors';

type Props = {
    stateSelector: (state: any) => State;
    actions: Actions;
    dataSelector: (state: any) => Row[];
};

const customStyles = {
    menu: (provided) => ({
        ...provided,
        zIndex: 99999,
    }),
};

const Equipmentfilters = (props: Props) => {
    const dispatch = useDispatch();
    const equipmentClasses = useSelector(
        selectEquipmentClasses(props.stateSelector, props.dataSelector),
    );
    const equipmentSubClasses = useSelector(
        selectEquipmentSubClasses(props.stateSelector, props.dataSelector),
    );
    const equipmentModels = useSelector(
        selectEquipmentModels(props.stateSelector, props.dataSelector),
    );
    const selectedClasses = useSelector(
        selectSelectedEquipmentClasses(props.stateSelector),
    );
    const selectedSubClasses = useSelector(
        selectSelectedEquipmentSubClasses(props.stateSelector),
    );
    const selectedModels = useSelector(
        selectSelectedEquipmentModels(props.stateSelector),
    );

    const allRows = useSelector(props.dataSelector);
    return (
        <div className={'EquipmentTypeFilters'}>
            <AntdRow
                className={'EquipmentTypeFilters__PrimaryRow'}
                gutter={[16, 16]}
            >
                <Col xs={24} md={12} lg={8}>
                    <SubSelector
                        name={'EquipmentClass'}
                        onSelected={(selected) =>
                            dispatch(
                                props.actions.setSelectedClasses({
                                    allRows,
                                    selectedClasses: selected,
                                }),
                            )
                        }
                        options={equipmentClasses}
                        selected={selectedClasses}
                    />
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <SubSelector
                        name={'EquipmentSubClass'}
                        onSelected={(selected) =>
                            dispatch(
                                props.actions.setSelectedSubClasses({
                                    allRows,
                                    selectedSubClasses: selected,
                                }),
                            )
                        }
                        options={equipmentSubClasses}
                        selected={selectedSubClasses}
                    />
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <SubSelector
                        name={'EquipmentModels'}
                        onSelected={(selected) =>
                            dispatch(
                                props.actions.setSelectedModels({
                                    allRows,
                                    selectedModels: selected,
                                }),
                            )
                        }
                        options={equipmentModels}
                        selected={selectedModels}
                    />
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <AntdButton type={'primary'} onClick={() => dispatch(props.actions.clearAll())}>
                        Clear Selection
                    </AntdButton>
                </Col>
            </AntdRow>
        </div>
    );
};

type SubSelectorProps = {
    name: string;
    options: Array<{ label: string; value: string }>;
    selected: string[];
    onSelected: (selected: Array<string>) => void;
};
const SubSelector = (props: SubSelectorProps) => (
    <>
        <label htmlFor={'equippmentClassOptions'}> {props.name}</label>
        <Select
            options={props.options}
            styles={customStyles}
            isMulti
            onChange={(e) => {
                props.onSelected(e.map((v) => v.value));
            }}
            value={props.selected.map((r) => ({ value: r, label: r }))}
        />
    </>
);
export default Equipmentfilters;
