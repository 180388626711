/**
 * Checks if there are any recorded values for the shift
 * @param {object} shiftResult the total scores for a shift
 * @return {boolean}
 */
export default function shiftHasData(shiftResult) {
    const { shiftId, ...classifications } = shiftResult;
    const hasValues = Object.keys(classifications).some(
        (classification) => shiftResult[classification] !== 0,
    );
    return hasValues;
}
