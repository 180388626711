/**
 * These functions help with HTML formatting of Highcharts tooltips.
 * The end goal is to produce HTML for the following cases:
 *
 *  ON THE CHART
 *
 *              ---------                 ---------
 *  1 series:   |  x:y  |    with no x:   |   y   |
 *              ---------                 ---------
 *
 *              -------------                -------------
 *  2+ series:  | x         |    with no x:  | name0:y0  |
 *              | name0:y0  |                | name1:y1  |
 *              | name1:y1  |                | ...       |
 *              | ...       |                -------------
 *              -------------
 *
 * TOP RIGHT OF THE CHART
 *
 *              ---------                  ---------
 *  1 series:   |  x:y  |     with no x:   |   y   |
 *              ---------                  ---------
 *
 *              ---------------------------------                  -----------------------------
 *  2+ series:  |     name0:y0   name2:y2   ... |     with no x:   | name0:y0   name2:y2   ... |
 *              | x   name1:y1   name3:y3   ... |                  | name1:y1   name3:y3   ... |
 *              ---------------------------------                  -----------------------------
 */
import Highcharts from 'highcharts/highcharts';

const ON_CHART = 'onchart';
const TOP_RIGHT = 'topright';

export default class TooltipFormatter {
    constructor(
        position,
        showX,
        xUnits,
        yUnits,
        decimalPlaces,
        isDateTime,
        dateTimeFormat,
        numberOfSeries,
    ) {
        this.position = position;
        this.showX = showX;
        this.xUnits = xUnits;
        this.yUnits = yUnits;
        this.decimalPlaces = decimalPlaces;
        this.isDateTime = isDateTime;
        this.format = dateTimeFormat;
        this.numberOfSeries = numberOfSeries;
    }

    getTooltipOptions() {
        /* eslint-disable @typescript-eslint/no-this-alias */
        const self = this;
        return {
            // Always show a crosshair
            crosshairs: true,
            // Don't render as SVG
            useHTML: true,

            shape: this._getShape(),
            positioner: this._getPositioner(),
            formatter() {
                // Send the formatter context as an object, and have 'this'
                // refer to our own class
                return self._getFormatter(this);
            },
        };
    }

    // If placing in the top right of the chart, use a square shape with no arrow
    _getShape() {
        if (this.position === TOP_RIGHT) {
            return 'square';
        }
        return undefined;
    }

    // This generates the HTML to have a nice pretty tooltip
    _getFormatter(context) {
        // Get x the value
        let x = this.isDateTime
            ? Highcharts.time.dateFormat(this.format, context.x)
            : context.x;

        // If a name for the point is already supplied, use that for the x value
        if (context.point && context.point.name !== undefined) {
            x = context.point.name;
        }

        x = this.showX ? x + this.xUnits : '';

        // Get the series values, names and colors
        const yValues = [];
        const names = [];
        const colors = [];

        const pointArray = context.points ? context.points : [context];
        pointArray.forEach((point) => {
            if (point.series.userOptions.type === 'arearange') {
                yValues.push(
                    `${point.point.low.toFixed(
                        this.decimalPlaces,
                    )} - ${point.point.high.toFixed(this.decimalPlaces)}${
                        this.yUnits
                    }`,
                );
            } else {
                yValues.push(point.y.toFixed(this.decimalPlaces) + this.yUnits);
            }
            names.push(point.series.name);
            colors.push(point.series.userOptions.color);
        });

        // This class is applied to all tooltips
        const tooltipClass = `FalconChart__tooltip FalconChart__tooltip--${this.position}`;

        // This helper function is called so that the values of each series are colored nicely
        const getSeriesClass = (index) => {
            if (colors[index]) {
                return `FalconChart__tooltip-series--${colors[index]}`;
            }
            return `FalconChart__tooltip-series--${index}`;
        };

        let result;
        if (this.position === ON_CHART) {
            if (yValues.length === 1) {
                // When there is only one series to show on the tooltip
                // prettier-ignore
                result = `
                <span class="${tooltipClass}">
                    ${x} <b class="${getSeriesClass(0)}">&nbsp;${yValues[0]}</b>
                </span>`;
            } else {
                // When there are multiple series
                let rows = '';
                yValues.forEach((y, i) => {
                    // prettier-ignore
                    rows += `
                        <tr>
                            <td>${names[i]}</td>                            
                            <td><b class="${getSeriesClass(i)}">&nbsp;${y}</b></td>
                        </tr>`;
                });

                // prettier-ignore
                result = `
                <span class="${tooltipClass}">
                    <table>
                    <tr>${x}</tr>
                    ${rows}
                    </table>
                </span>`
            }
        } else if (this.position === TOP_RIGHT) {
            // If displaying in the top right, we use a <table> element to format it
            const columnWidth = yValues[0].length * 27;
            const labelWidth = yValues.length * columnWidth;

            if (yValues.length === 1) {
                // prettier-ignore
                result = `
                <span class="${tooltipClass}" style="width: ${labelWidth}px">
                    <table>
                        <tr>
                            <td style="width: ${columnWidth}px">
                                ${x} <b class="${getSeriesClass(0)}">&nbsp;${yValues[0]}&nbsp;&nbsp;&nbsp;</b>
                            </td>
                        </tr>
                    </table>
                </span>`
            } else {
                let rows = '<tr><td></td>';
                const fontFactor = 27;
                // Even y values first
                for (let i = 0; i < yValues.length; i += 2) {
                    // prettier-ignore
                    rows += `<td style="width: ${yValues[i].length*fontFactor}px">
                        ${names[i]}<b class="${getSeriesClass(i)}">&nbsp;${yValues[i]}&nbsp;&nbsp;&nbsp;</b>
                    </td>`;
                }
                rows += `</tr><tr><td style="width: ${
                    x.length * fontFactor
                }px">${x}&nbsp;&nbsp;&nbsp;</td>`;
                // Odd y values next
                for (let i = 1; i < yValues.length; i += 2) {
                    // prettier-ignore
                    rows += `<td style="width: ${yValues[i].length*fontFactor}px">
                        ${names[i]}<b class="${getSeriesClass(i)}">&nbsp;${yValues[i]}&nbsp;&nbsp;&nbsp;</b>
                    </td>`;
                }
                rows += '</tr>';

                // prettier-ignore
                result = `
                <span class="${tooltipClass}" >
                    <table>
                    ${rows}
                    </table>
                </span>`;
            }
        }
        return result;
    }

    // This returns the correct callback function to position the tooltip
    _getPositioner() {
        if (this.position === ON_CHART) {
            return undefined;
        }
        if (this.position === TOP_RIGHT) {
            return function positioner(labelWidth) {
                const lineHeight = 15;
                // Set the y position depending on how many rows in the table
                const y = this.chart.series.length > 1 ? 0 : lineHeight;
                return {
                    x: this.chart.chartWidth - labelWidth,
                    // Factor in the given chart spacing
                    y: y + (this.chart.spacing[0] - 10),
                };
            };
        }
        return undefined;
    }
}
