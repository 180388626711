import * as React from 'react';

function SvgStatusSpotting(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <circle cx={512} cy={512} r={498.84} fill="#fff" />
            <path d="M512 50a462.14 462.14 0 01179.82 887.71A462.13 462.13 0 01332.18 86.29 459.06 459.06 0 01512 50m0-50C229.23 0 0 229.23 0 512s229.23 512 512 512 512-229.23 512-512S794.77 0 512 0z" />
            <path d="M857.72 506a9.66 9.66 0 00-1.66-1.66L684.43 368.71a9.67 9.67 0 00-15.62 7.63v96.5l-313.5.27v-96.75a9.66 9.66 0 00-15.62-7.63L167.94 504.38a9.69 9.69 0 000 15.26l171.63 135.65a9.67 9.67 0 0015.62-7.63v-96.5l313.5-.27v96.75a9.66 9.66 0 0015.62 7.63l171.75-135.65a9.7 9.7 0 001.66-13.62z" />
        </svg>
    );
}

export default SvgStatusSpotting;
