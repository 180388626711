import { takeLatest, call, put, all } from 'redux-saga/effects';
import * as resourceSagas from './resourceSagas';
import { forwardTo } from '../App/createHistory';
import { actions as spinnerActions } from '../AssignGroups/Modules/spinner';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import { actions as groupsActions } from '../AssignGroups/Modules/groups';
import { actions as editFormActions } from '../AssignGroups/Modules/editForm';
import { actions as usersActions } from '../AssignGroups/Modules/users';

function* loadUsers() {
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const { response, error } = yield call(resourceSagas.getUserList);
    if (error) {
        yield editFormActions.editFormError({ error });
        return;
    }

    response.rows.forEach((row) => {
        row.EmployeeId = String(row.EmployeeId);
        if (row.EmployeeId === 'null') {
            row.EmployeeId = '';
        }
        if (!row.UserName) {
            row.UserName = '';
        }
    });

    yield all([
        put(usersActions.fetchUsersSuccess(response.rows)),
        put(spinnerActions.setSpinnerState(false)),
    ]);
}

function* loadGroups() {
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const { response, error } = yield call(resourceSagas.getGroupList);

    if (error) {
        yield editFormActions.editFormError({ error });
        return;
    }
    yield call(loadUsers);
    yield all([
        put(groupsActions.fetchGroupsSuccess(response.rows)),
        put(spinnerActions.setSpinnerState(false)),
    ]);
}

function* assignGroup({ payload }) {
    yield put(
        spinnerActions.setSpinnerState(
            true,
            LOADING_MESSAGES.LOADING__PROCESSING,
        ),
    );

    const apiResponse = yield call(resourceSagas.patchAssignGroup, {
        UserId: payload.userInfo.userId,
        Groups: payload.userInfo.groups.filter(
            (group) =>
                group.Status === true && group.MaxmineControlled === false,
        ),
    });

    if (apiResponse.error) {
        yield put(editFormActions.editFormError(apiResponse.error.message));
        yield put(spinnerActions.setSpinnerState(false));
    } else {
        yield put(editFormActions.editFormError(null));
        yield put(spinnerActions.setSpinnerState(false));
        forwardTo(`/manage-user`);
    }
}

export default function* watch() {
    yield all([
        takeLatest(groupsActions.FETCH_GROUPS_REQUESTED, loadGroups),
        takeLatest(editFormActions.SUBMIT_ASSIGN_GROUPS, assignGroup),
    ]);
}
