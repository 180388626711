import * as React from 'react';
import { getAuthenticatedToken } from '../../Auth/Selectors/features';
import { useSelector } from 'react-redux';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import { getMinesiteId } from '../../App/Selectors';

type QueryParams = {
    [key: string]: string;
};

type FleetPlanIframeProp = {
    queryParams: QueryParams;
};

const isQueryParamsSpecify = (queryParams: QueryParams) => {
    return queryParams != null && !!Object.keys(queryParams).length;
};

const constructQueryParamsAsUrlQueryString = (queryParams: QueryParams) => {
    return new URLSearchParams(queryParams).toString();
};

const FleetPlanIframe = ({ queryParams }: FleetPlanIframeProp) => {
    const token = useSelector(getAuthenticatedToken);
    const [contentLoaded, setContentLoaded] = React.useState(false);
    const minesiteId = useSelector(getMinesiteId);

    const iframeUrlParams = `MinesiteId=${minesiteId}&Authorization=${token}${
        isQueryParamsSpecify(queryParams)
            ? '&' + constructQueryParamsAsUrlQueryString(queryParams)
            : ''
    }`;

    return (
        <>
            <LoadingSpinner isActive={!contentLoaded} message={'Loading'} />
            <iframe
                title={'FleetPlan Embed'}
                style={{ flex: 1, border: 0 }}
                src={`https://fleet-plan.production.max-mine.com/main?${iframeUrlParams}`}
                onLoad={() => setContentLoaded(true)}
            />
        </>
    );
};
export default FleetPlanIframe;
