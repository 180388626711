import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_REPORTS_CONFORMANCE_SUCCESS = `${namespace}/FETCH_REPORTS_CONFORMANCE_SUCCESS`;
    const fetchReportsConformanceSuccess = (resultsArray) =>
        action(FETCH_REPORTS_CONFORMANCE_SUCCESS, resultsArray);

    const FETCH_REPORTS_CONFORMANCE_ERROR = `${namespace}/FETCH_REPORTS_CONFORMANCE_ERROR`;
    const fetchReportsConformanceError = ({ error }) =>
        action(FETCH_REPORTS_CONFORMANCE_ERROR, { error });

    return {
        FETCH_REPORTS_CONFORMANCE_SUCCESS,
        fetchReportsConformanceSuccess,
        FETCH_REPORTS_CONFORMANCE_ERROR,
        fetchReportsConformanceError,
    };
}
