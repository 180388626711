import { createSelector } from 'reselect';
import * as networkCoverageReportModule from '../Modules/networkCoverageReport';

const getH3DataInMap = createSelector(
    networkCoverageReportModule.selectors.getAll,
    (netWorkCoverageState) => {
        const { dataInMap } = netWorkCoverageState;

        return dataInMap;
    },
);
export default getH3DataInMap;
