export default function createSelectors(statePath) {
    function _getAll(state) {
        return statePath(state);
    }

    function getFeatureId(state) {
        const s = _getAll(state);
        return s.featureId;
    }

    return {
        getFeatureId,
    };
}
