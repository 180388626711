import { action } from '../../Lib/reduxUtils';
import namespace from '../Modules/namespace';

export const MINESITE_POLLING_STARTED = `${namespace}/MINESITE_POLLING_STARTED`;
export const minesitePollingStarted = () => action(MINESITE_POLLING_STARTED);

export const MINESITE_POLLING_CANCELLED = `${namespace}/MINESITE_POLLING_CANCELLED`;
export const minesitePollingCancelled = () =>
    action(MINESITE_POLLING_CANCELLED);

export const LIVE_MAP_POLLING_STARTED = `${namespace}/LIVE_MAP_POLLING_STARTED`;
export const liveMapPollingStarted = () => action(LIVE_MAP_POLLING_STARTED);

export const LIVE_MAP_POLLING_CANCELLED = `${namespace}/LIVE_MAP_POLLING_CANCELLED`;
export const liveMapPollingCancelled = () => action(LIVE_MAP_POLLING_CANCELLED);

export const MOVE_TRUCK_REQUESTED = `${namespace}/MOVE_TRUCK_REQUESTED`;
export const moveTruck = (id, modifications) =>
    action(MOVE_TRUCK_REQUESTED, { id, modifications });

export const INITIALISE_CIRCUIT_ORDER = `${namespace}/INITIALISE_CIRCUIT_ORDER`;
export const initialiseCircuitOrder = (circuitsOrder) =>
    action(INITIALISE_CIRCUIT_ORDER, { circuitsOrder });

export const PATCH_HOLDING_STATE_REQUESTED = `${namespace}/PATCH_HOLDING_STATE_REQUESTED`;
export const patchHoldingStateRequested = (id, modifications) =>
    action(PATCH_HOLDING_STATE_REQUESTED, { id, modifications });

export const DELETE_CIRCUIT = `${namespace}/DELETE_CIRCUIT`;
export const deleteCircuit = (id) => action(DELETE_CIRCUIT, { id });

export const PUT_HOLDING_STATE_REQUESTED = `${namespace}/PUT_HOLDING_STATE_REQUESTED`;
export const putHoldingStateRequested = (id, modifications) =>
    action(PUT_HOLDING_STATE_REQUESTED, { id, modifications });

export const PATCH_CIRCUIT_REQUESTED = `${namespace}/PATCH_CIRCUIT_REQUESTED`;
export const patchCircuitRequested = (id, modifications) =>
    action(PATCH_CIRCUIT_REQUESTED, { id, modifications });

export const SYNC_URL_TO_STATE = `${namespace}/SYNC_URL_TO_STATE`;
export const syncURLToState = (params) => action(SYNC_URL_TO_STATE, params);

export const UPDATE_MAP_FILTERS_WITH_URL_PARAMS = `${namespace}/UPDATE_MAP_FILTERS_WITH_URL_PARAMS`;
export const updateMapFiltersWithUrlParams = () =>
    action(UPDATE_MAP_FILTERS_WITH_URL_PARAMS);

export const MAP_LEGEND_LAYER_FLIP = `${namespace}/MAP_LEGEND_LAYER_FLIP`;
export const mapLegendLayerFlip = (section, checked) => {
    return action(MAP_LEGEND_LAYER_FLIP, {
        sectionLabel: section,
        visibility: checked,
    });
};
