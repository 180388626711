/**
 * Returns the size of the containing element
 * @param element - Angular or Native element, if array is supplied first element is used.
 * @returns {{width: int, height: int, parentHeight: int}}
 */
function getParentElementSize(element) {
    let el = element;
    if (Array.isArray(el)) {
        el = el[0];
    }

    let parentEl = el.parentNode;
    if (typeof el.parent === 'function') {
        parentEl = el.parent()[0];
    }

    return {
        width: parentEl.clientWidth,
        height: parentEl.clientWidth,
        parentHeight: parentEl.clientHeight,
    };
}

/**
 * Returns the clientWidth & clientHeight of an element, If an array is supplied it uses the first element in the array
 * @param {HTMLElement|HTMLElement[]} element
 * @returns {{width: *, height: *}}
 */
function getElementSize(element) {
    const el = Array.isArray(element) ? element[0] : element;
    return {
        width: el.clientWidth,
        height: el.clientHeight,
    };
}

export { getParentElementSize, getElementSize };
