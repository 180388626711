import LoadingSpinner from './LoadingSpinner';

const LOADING_MESSAGES = {
    LOADING__DATA: 'Loading data...',
    LOADING__PROCESSING: 'Processing...',
    ERROR_MESSAGE__NETWORK: 'An error occurred retrieving the data',
    LOADING__REPORT: 'Loading report...',
};

export { LoadingSpinner, LOADING_MESSAGES };
