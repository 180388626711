import React from 'react';

import moment from 'moment';
import {
    takeLatest,
    takeEvery,
    delay,
    select,
    call,
    put,
    fork,
    take,
    cancel,
    cancelled,
    all,
} from 'redux-saga/effects';
import { MAP_LAYER_LABELS, notification } from '@rs/core/falcon';
import generateAPIUrl from '@rs/core/utils/generate-api-url';
import { getUnitForMetric } from '@rs/core/utils/unitFormatters';
import shiftUtils from '@rs/core/utils/shiftUtils';
import errorLogging from '@rs/core/utils/errorLogging';
import {
    convertURLQueryStringToObject,
    updateURLQueryString,
    clearURLQueryString,
} from '../Lib/queryStringUtils';
import * as dasActions from '../DAS/dasActions';
import parameterData from '../DAS/data/parameter_data';
import { machinesFromData, machineMetricsFromData } from '../DAS/dataFormatter';
import { getItem, AUTH_TOKEN_KEY } from '../Lib/localStorage';
import { action as reduxUtilAction } from '../Lib/reduxUtils';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import * as resourceSagas from './resourceSagas';
import * as WKTAggregateModule from '../DAS/Modules/WKTAggregate';
import * as minesiteMapModule from '../DAS/Modules/minesiteMap';
import { actions as spinnerActions } from '../DAS/Modules/spinner';
import { getComponentConfig } from '../App/Selectors';
import { BASE_STATE_NAME, REDUCER_KEY } from '../DAS/Reducers';
import getFilterOptions from '../DAS/selectors/getFilterOptions';
import { actions as dasMapFeaturesActions } from '../DAS/Modules/mapFeatures';
import { actions as dasLegendActions } from '../DAS/Modules/mapLegends';
import polygonLayers from '../DAS/selectors/getMinesiteMapPolygonLayers';
import { actions as loadinSpinnerActions } from '../DAS/Modules/spinner';
import polygonGeojson from '../DAS/selectors/getPolygonGeojson';
import spinnerState from '../DAS/selectors/getSpinnerState';
import { getGraphqlSdk } from './utils';
import { Sdk, GetMapFeaturesQuery } from '../Graphql/Services';
import { Visibility } from '@rs/core/falcon/components/NewLegend/reduxUtil';
import {
    getAllUniqueSpeedZones,
    getUniqueColorScales,
    getUniqueMaterialcolorScales,
    getUniqueMaterials,
} from '../Modules/mapFeatures_v2/helper';

export const BASE_URL = process.env.REACT_APP_API_URL || generateAPIUrl(3001);

// Gets the time interval between data values
function getInterval(machines) {
    const times = machines
        .filter((machine) => machine.times.length > 1)
        .map((machine) => machine.times[1] - machine.times[0]);
    return Math.min(...times);
}

export function* fetchParameterData() {
    const categoryEntries = Object.entries(parameterData.metric_categories);
    const categories = categoryEntries.map((obj) => {
        return {
            id: obj[0],
            ...obj[1],
        };
    });
    const parameterEntries = Object.entries(parameterData.metrics);
    const parameters = parameterEntries.map((obj) => {
        const unit = getUnitForMetric(obj[1].key);
        let unitTitle = '';
        if (unit.short) {
            unitTitle = ` (${unit.short})`;
        }
        return {
            id: obj[0],
            ...obj[1],
            label: `${obj[1].label}${unitTitle}`,
        };
    });
    yield put(dasActions.dasSetCategories(categories));
    yield put(dasActions.dasSetParameters(parameters));
}

// Fetch initial machine data with coordinates
export function* fetchMachineData(action) {
    const {
        payload: { zoomed },
    } = action;

    const { [REDUCER_KEY]: das } = yield select();
    const { startDate, endDate, zoomStart, zoomEnd, metrics } = das[
        BASE_STATE_NAME
    ];
    const authToken = yield call(getItem, AUTH_TOKEN_KEY);

    // The dates in the url are store in epoch seconds
    // Below turn the epoch string into moment object in the minesite TZ
    let dateStart = moment(
        shiftUtils.epochToTZFormatted(
            startDate,
            shiftUtils.timezoneAtMinesite ?? undefined,
            shiftUtils.DATE_FORMAT__WITH_TIMEZONE,
        ),
    );
    let dateEnd = moment(
        shiftUtils.epochToTZFormatted(
            endDate,
            shiftUtils.timezoneAtMinesite ?? undefined,
            shiftUtils.DATE_FORMAT__WITH_TIMEZONE,
        ),
    );

    if (zoomed) {
        if (!zoomStart || !zoomEnd) return;
        if (!startDate || !endDate) return;
        if (zoomStart === dateStart.unix() && zoomEnd === dateEnd.unix())
            return;

        dateStart = moment(
            shiftUtils.epochToTZFormatted(
                zoomStart / 1000,
                shiftUtils.timezoneAtMinesite ?? undefined,
                shiftUtils.DATE_FORMAT__WITH_TIMEZONE,
            ),
        );
        dateEnd = moment(
            shiftUtils.epochToTZFormatted(
                zoomEnd / 1000,
                shiftUtils.timezoneAtMinesite ?? undefined,
                shiftUtils.DATE_FORMAT__WITH_TIMEZONE,
            ),
        );
    }
    if (!dateStart || !dateEnd) {
        return;
    }

    if (zoomed) {
        yield put(dasActions.dasSetZoomedMachineData([], false));
        yield put(dasActions.dasZoomedDataLoading(true, false));
    } else {
        yield put(dasActions.dasSetMachineData([], 0));
        yield put(dasActions.dasDataLoading(true));
    }

    const fetchStartDate = dateStart.unix();

    const fetchEndDate = dateEnd.unix();

    const endpoint = `${BASE_URL}/equipmentbase/aggregate/max/metric/ground_speed?Features=equipment_site_name|equipment_class|equipment_sub_class&StartTime=${fetchStartDate}&EndTime=${fetchEndDate}&Format=geojson&Fill=0.0`;

    try {
        const response = yield call(fetch, endpoint, {
            headers: new Headers({ Authorization: authToken }),
        });

        if (response.status < 200 || response.status > 300) {
            notification.error({
                message: 'Error',
                description: `An error occured, please try again.`,
                duration: 5,
            });
            throw response;
        }

        const data = yield response.json();

        const supportEmailAddress = (yield select(
            getComponentConfig,
            'AppLanding',
        )).supportEmailAddress;

        const machines = machinesFromData(data);
        if (machines.length === 0) {
            notification.info({
                key: 'DAS_NO_DATA',
                message: 'No Data',
                description: [
                    'Data is not available. Please request this data range to be loaded into MaxMine via the service desk or',
                    //@ts-ignore
                    <br />,
                    <a href={`mailto: ${supportEmailAddress}`}>
                        {supportEmailAddress}
                    </a>,
                ],
                duration: 15,
            });
        }
        const interval = getInterval(machines);

        if (!zoomed) {
            yield put(dasActions.dasSetDataInterval(interval));
            yield put(dasActions.dasSetMachineData(machines));
        } else {
            yield put(dasActions.dasSetZoomedMachineData(machines, false));
        }
    } catch (err) {
        errorLogging.logException(err);
    }
    if (zoomed) {
        // If we already have metrics selected, load data for them
        yield all(
            metrics.map((metric) =>
                fetchChartData(
                    reduxUtilAction(dasActions.DAS_LOAD_ZOOMED_MACHINE_DATA, {
                        metric,
                        zoomed,
                    }),
                ),
            ),
        );
        yield put(dasActions.dasZoomedDataLoading(false, true));

        const state = yield select();

        if (!state[REDUCER_KEY][BASE_STATE_NAME].inZoom) {
            const playing = state[REDUCER_KEY][BASE_STATE_NAME].playing;

            if (playing) {
                yield put(dasActions.dasPlaybackPause());
            }

            yield put(
                dasActions.dasTrackPositionChanged(
                    state[REDUCER_KEY][BASE_STATE_NAME].zoomStart / 1000 +
                        shiftUtils.getOffsetInSecondsBetweenSiteAndBrowser(
                            fetchEndDate,
                        ),
                ),
            );

            if (playing) {
                yield put(dasActions.dasPlaybackStart());
            }
        }
    } else {
        yield all(
            metrics.map((metric) =>
                fetchChartData(
                    reduxUtilAction(dasActions.DAS_LOAD_CHART_DATA, {
                        metric,
                        zoomed,
                    }),
                ),
            ),
        );
        yield put(dasActions.dasDataLoading(false));
    }
}

// Grab data for a metric
export function* fetchChartData(action) {
    const {
        payload: { metric, zoomed },
    } = action;

    const { [REDUCER_KEY]: das } = yield select();
    const { startDate, endDate, zoomStart, zoomEnd, zoomLoaded } = das[
        BASE_STATE_NAME
    ];
    const authToken = yield call(getItem, AUTH_TOKEN_KEY);

    let machines = zoomed
        ? das[BASE_STATE_NAME].zoomedMachines
        : das[BASE_STATE_NAME].machines;
    // Check if we're already loading this metric and cancel out if we are
    if (
        machines.length === 0 ||
        (machines[0].values[metric] &&
            (machines[0].values[metric].loaded ||
                machines[0].values[metric].loading))
    ) {
        return;
    }

    // Initialise data structure for metric if it doesn't exist
    machines.forEach((machine, idx) => {
        if (!machines[idx].values[metric]) {
            machines[idx].values[metric] = {
                loading: true,
                loaded: true,
                data: [],
            };
        }
        machines[idx].values[metric].loading = true;
        machines[idx].values[metric].loaded = false;
    });

    // Check if we're loading data for zoomed range or full and set the initial machine data
    if (zoomed) {
        yield put(dasActions.dasSetZoomedMachineData(machines, false));
    } else {
        yield put(dasActions.dasSetMachineData(machines));
    }

    // The dates in the url are store in epoch seconds
    // Below turn the epoch string into moment object in the minesite TZ
    let dateStart = moment(
        shiftUtils.epochToTZFormatted(
            startDate,
            shiftUtils.timezoneAtMinesite ?? undefined,
            shiftUtils.DATE_FORMAT__WITH_TIMEZONE,
        ),
    );
    let dateEnd = moment(
        shiftUtils.epochToTZFormatted(
            endDate,
            shiftUtils.timezoneAtMinesite ?? undefined,
            shiftUtils.DATE_FORMAT__WITH_TIMEZONE,
        ),
    );

    if (zoomed) {
        dateStart = moment(
            shiftUtils.epochToTZFormatted(
                zoomStart / 1000,
                shiftUtils.timezoneAtMinesite ?? undefined,
                shiftUtils.DATE_FORMAT__WITH_TIMEZONE,
            ),
        );
        dateEnd = moment(
            shiftUtils.epochToTZFormatted(
                zoomEnd / 1000,
                shiftUtils.timezoneAtMinesite ?? undefined,
                shiftUtils.DATE_FORMAT__WITH_TIMEZONE,
            ),
        );
    }
    if (!dateStart || !dateEnd) {
        return;
    }

    const fetchStartDate = dateStart.unix();

    const fetchEndDate = dateEnd.unix();

    const endpoint = `${BASE_URL}/equipmentbase/aggregate/max/metric/${metric}?Features=equipment_site_name|equipment_class&StartTime=${fetchStartDate}&EndTime=${fetchEndDate}`;

    try {
        const response = yield call(fetch, endpoint, {
            headers: new Headers({ Authorization: authToken }),
        });

        if (response.status < 200 || response.status > 300) {
            notification.error({
                message: 'Error',
                description: `An error occured, please try again.`,
                duration: 5,
            });
            throw response;
        }

        const data = yield response.json();

        const state = yield select();
        machines = zoomed
            ? state[REDUCER_KEY][BASE_STATE_NAME].zoomedMachines
            : state[REDUCER_KEY][BASE_STATE_NAME].machines;

        machines = machineMetricsFromData(machines, data, metric, true);

        // Check if we're loading data
        if (zoomed) {
            yield put(dasActions.dasSetZoomedMachineData(machines, zoomLoaded));
        } else {
            yield put(dasActions.dasSetMachineData(machines));
        }
    } catch (err) {
        errorLogging.logException(err);
    }
}

// Move position time by interval/playback speed
export function* playbackData() {
    try {
        while (true) {
            const { [REDUCER_KEY]: das } = yield select();
            const {
                playbackSpeed,
                trackPosition,
                endDate,
                inZoom,
                zoomStart,
                zoomEnd,
            } = das[BASE_STATE_NAME];

            if (trackPosition >= endDate) {
                yield put(dasActions.dasPlaybackPause());
            } else {
                yield delay(1000);
                yield put(
                    dasActions.dasTrackPositionChanged(
                        trackPosition + 1 * playbackSpeed,
                    ),
                );
                if (inZoom && trackPosition + 1 * playbackSpeed >= zoomEnd) {
                    yield delay(1000);
                    yield put(
                        dasActions.dasTrackPositionChanged(zoomStart / 1000),
                    );
                }
            }
        }
    } finally {
        if (yield cancelled()) console.info('Timer cancelled');
    }
}

// Starts the timer playback
export function* startPlayback() {
    while (yield take(dasActions.DAS_PLAYBACK_START)) {
        const playbackDataTask = yield fork(playbackData);

        yield take(dasActions.DAS_PLAYBACK_PAUSE);

        yield cancel(playbackDataTask);
    }
}

function* fetchLocusData() {
    const { response } = yield call(resourceSagas.getLatestShiftId);
    if (!response) {
        return;
    }
    const latestShiftId = response[0].ShiftId;

    const wktResponse = yield call(resourceSagas.getWKTAggregate, {
        FirstShiftId: latestShiftId,
    });
    if (wktResponse.error) {
        return;
    }

    yield put(
        WKTAggregateModule.actions.fetchWKTAggregateSuccess(
            wktResponse.response,
        ),
    );
}

function* updateStateWithUrlParams() {
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );

    const urlFilterValues = convertURLQueryStringToObject(true);

    let { startDate, endDate } = urlFilterValues;
    // Get the start and end date from the URL
    const {
        selectedMachines,
        metrics,
        trackPosition,
        zoomStart,
        zoomEnd,
        zoomed,
        playbackSpeed,
        collapsed,
        ...minesiteMap
    } = urlFilterValues;

    const int = (str) => Number.parseInt(str, 10);

    if (!startDate || !endDate) {
        endDate = shiftUtils
            .createMomentInSiteTime()
            .subtract(3, 'hours')
            .unix();
        startDate = shiftUtils
            .createMomentInSiteTime()
            .subtract(5, 'hours')
            .unix();
    }

    // Stick the dates in the state
    yield put(dasActions.dasDateRangeChanged(startDate, endDate));

    // Set the track position
    if (int(trackPosition)) {
        yield put(dasActions.dasTrackPositionChanged(int(trackPosition)));
    }

    // Set the playback speed
    if (int(playbackSpeed)) {
        yield put(dasActions.dasPlaybackSpeedChanged(int(playbackSpeed)));
    }

    // Set the zoom sliders
    const { [REDUCER_KEY]: das } = yield select();
    if (int(zoomStart) || int(zoomEnd)) {
        yield put(
            dasActions.dasSetZoomRange(
                int(zoomStart) || das[BASE_STATE_NAME].zoomStart,
                int(zoomEnd) || das[BASE_STATE_NAME].zoomEnd,
            ),
        );
    }
    // check if the mapfeatures already exists in the state
    const mapFeatures = das?.MAP_FEATURES?.speedZonesGeojson?.features;
    // Load up the machines and parameters
    yield call(fetchParameterData);
    yield call(fetchMachineData, { payload: {} });

    if (mapFeatures.length === 0) {
        yield call(prepareToFetchMapFeatures);
    }

    if (metrics) {
        const metricsCalls = metrics
            .split(',')
            .map((metric) => call(fetchChartData, { payload: { metric } }));
        yield all(metricsCalls);
        yield put(dasActions.dasSetMetrics(metrics.split(',')));
    }

    // Click the zoom button if we were zoomed in
    if (zoomed) {
        yield call(fetchMachineData, { payload: { zoomed } });
    }

    // Set the map properties
    if (minesiteMap) {
        const { center, zoom, ...mapFilters } = minesiteMap;
        yield put(
            minesiteMapModule.actions.mapViewportChanged({
                center: center ? center.split(',').map(Number) : undefined,
                zoom: int(zoom),
            }),
        );
        for (const [name, checked] of Object.entries(mapFilters)) {
            yield put(
                minesiteMapModule.actions.mapFilterUpdated(name, checked),
            );
        }
    }

    // Set any selected machines
    if (selectedMachines) {
        yield put(
            dasActions.dasSetSelectedMachines(selectedMachines.split(',')),
        );
    }

    // Set any selected machines
    if (collapsed !== undefined) {
        yield put(dasActions.dasCollapseSider(collapsed));
    }
    yield put(spinnerActions.setSpinnerState(false));
}

export function* syncStateToUrl() {
    const { das } = yield select();
    const {
        startDate,
        endDate,
        selectedMachines,
        collapsed,
        metrics,
        inZoom,
        playbackSpeed,
        trackPosition,
        zoomStart,
        zoomEnd,
    } = das[BASE_STATE_NAME];

    updateURLQueryString(
        {
            ...das[BASE_STATE_NAME].minesiteMap,
            startDate,
            endDate,
            selectedMachines,
            collapsed,
            metrics,
            zoomed: inZoom,
            playbackSpeed,
            trackPosition,
            zoomStart,
            zoomEnd,
        },
        {},
        'replace',
    );
}

export function* prepareToFetchMapFeatures() {
    try {
        yield put(
            spinnerActions.setSpinnerState(
                true,
                LOADING_MESSAGES.LOADING__DATA,
            ),
        );

        const { shiftIdsSelectedShifts } = yield select(getFilterOptions);

        const { last } = shiftIdsSelectedShifts;

        // get the graphql sdk
        const sdk: Sdk = getGraphqlSdk();
        const result: GetMapFeaturesQuery = yield call(sdk.GetMapFeatures, {
            input: {
                shiftIds: [last],
            },
        });

        const response = result?.getMapFeatures?.featuresByShiftId;
        //filter out the mapfeatures according to the last processed shift
        const featuresForSelectedShift = response?.filter(
            (arr) => arr?.shiftId === last,
        )[0];

        const {
            speedZonesGeojson,
            womidsGeojson,
            trackingRegionsGeojson,
            materialMovementsGeojson,
        } = featuresForSelectedShift;

        const emptyFeatureCollection = {
            type: 'FeatureCollection',
            features: [],
        };
        const parsedSpeedZonesGeojson =
            speedZonesGeojson === '' ||
            JSON.parse(speedZonesGeojson)?.features === null
                ? emptyFeatureCollection
                : JSON.parse(speedZonesGeojson);
        const parsedWomidsGeojson =
            womidsGeojson === '' || JSON.parse(womidsGeojson)?.features === null
                ? emptyFeatureCollection
                : JSON.parse(womidsGeojson);
        const parsedTrackingRegionsGeojson =
            trackingRegionsGeojson === '' ||
            JSON.parse(trackingRegionsGeojson)?.features === null
                ? emptyFeatureCollection
                : JSON.parse(trackingRegionsGeojson);
        const parsedMaterialMovementsGeojson =
            materialMovementsGeojson === '' ||
            JSON.parse(materialMovementsGeojson)?.features === null
                ? emptyFeatureCollection
                : JSON.parse(materialMovementsGeojson);

        yield all([
            put(
                dasMapFeaturesActions.setSpeedZonesGeojson(
                    parsedSpeedZonesGeojson,
                ),
            ),
            put(dasMapFeaturesActions.setWomidsGeojson(parsedWomidsGeojson)),
            put(
                dasMapFeaturesActions.setTrackingRegionsGeojson(
                    parsedTrackingRegionsGeojson,
                ),
            ),
            put(
                dasMapFeaturesActions.setMaterialMovementsGeojson(
                    parsedMaterialMovementsGeojson,
                ),
            ),
        ]);

        yield put(spinnerActions.setSpinnerState(false));
    } catch (error) {
        yield put(spinnerActions.setSpinnerState(false));
        throw new Error(error.message);
    }
}

type FlipLegendPayload = {
    payload: { sectionLabel: string; visibility: Visibility };
};

export function* flipLegendLayer({ payload }: FlipLegendPayload) {
    try {
        // fire action to toggle legend layer
        yield put(dasLegendActions.ChangeSectionVisibility(payload));
    } catch (error) {
        throw new Error(error.message);
    }
}
export function* loadLegend() {
    try {
        const { isActive } = yield select(spinnerState);

        if (!isActive) {
            const {
                parsedSpeedZonesGeojson,
                parsedMaterialMovementsGeojson,
            } = yield select(polygonGeojson);

            const {
                speedRestrictedZones,
                materialMovement,
                mineFeatures,
                mineRegions,
            } = yield select(polygonLayers);

            // set map legend sections
            yield put(
                dasLegendActions.SetSections([
                    {
                        label: MAP_LAYER_LABELS.speedLimitedZones,
                        visibility: speedRestrictedZones,
                        items: Object.entries(
                            getUniqueColorScales(
                                getAllUniqueSpeedZones(
                                    parsedSpeedZonesGeojson,
                                ) as any,
                            ),
                        ).map(([key, val]) => {
                            return {
                                label: `${key}kph`,
                                borderColor: `${val}`,
                                fillColor: `${val}`,
                                visibility: 'VISIBLE',
                            };
                        }),
                    },
                    {
                        label: MAP_LAYER_LABELS.materialMovement,
                        visibility: materialMovement,
                        items: Object.entries(
                            getUniqueMaterialcolorScales(
                                getUniqueMaterials(
                                    parsedMaterialMovementsGeojson,
                                ) as any,
                            ),
                        ).map(([key, val]) => {
                            return {
                                label: key,
                                borderColor: val,
                                fillColor: val,
                                visibility: 'VISIBLE',
                            };
                        }),
                    },
                    {
                        /* any polygon that has Maintenance, TyreBay or Workshop in them will be categorised as Maintenence
                   anything else is ancilary/goline
                */

                        label: MAP_LAYER_LABELS.trackingRegions,
                        visibility: mineFeatures,
                        items: [
                            {
                                label: 'Maintenance',
                                borderColor: '#45818e',
                                fillColor: '#45818e',
                                visibility: 'VISIBLE',
                            },

                            {
                                label: 'Ancillary/GoLine',
                                borderColor: '#ff9900',
                                fillColor: '#ff9900',
                                visibility: 'VISIBLE',
                            },
                        ],
                    },
                    {
                        label: MAP_LAYER_LABELS.mineRegions,
                        visibility: mineRegions,
                        items: [
                            {
                                label: 'MineRegions',
                                borderColor: '#000000',
                                fillColor: '',
                                visibility: 'VISIBLE',
                            },
                        ],
                    },
                ]),
            );
        }
    } catch (error) {
        console.log(error);
    }
}
export default function* dasSagas() {
    yield all([
        takeLatest(dasActions.DAS_SYNC_URL_TO_STATE, updateStateWithUrlParams),
        takeEvery(
            [
                minesiteMapModule.actions.MAP_VIEWPORT_CHANGED,
                minesiteMapModule.actions.MAP_FILTER_UPDATED,
                dasActions.DAS_COLLAPSE_SIDER,
                dasActions.DAS_PLAYBACK_PAUSE,
                dasActions.DAS_PLAYBACK_START,
                dasActions.DAS_SET_ZOOM_RANGE,
                dasActions.DAS_PLAYBACK_SPEED_CHANGED,
                dasActions.DAS_SET_ZOOMED_MACHINE_DATA,
                dasActions.DAS_LOAD_ZOOMED_MACHINE_DATA,
                dasActions.DAS_SET_SELECTED_MACHINES,
                dasActions.DAS_DATE_RANGE_CHANGED,
                dasActions.DAS_SET_METRICS,
            ],
            syncStateToUrl,
        ),
        takeLatest(dasActions.DAS_LOAD_PARAMETERS, fetchParameterData),
        takeLatest(dasActions.DAS_LOAD_MACHINE_DATA, fetchMachineData),
        takeLatest(dasActions.DAS_LOAD_ZOOMED_MACHINE_DATA, fetchMachineData),
        takeLatest(dasActions.DAS_LOAD_LOCUS_DATA, fetchLocusData),
        takeEvery(dasActions.DAS_LOAD_CHART_DATA, fetchChartData),
        takeEvery(dasActions.DAS_LOAD_ZOOMED_CHART_DATA, fetchChartData),
        takeEvery(dasActions.DAS_CLEAR_URL_PARAMS, clearURLQueryString),
        takeEvery(dasActions.DAS_FETCH_MAP_FEATURES, prepareToFetchMapFeatures),
        takeEvery(dasActions.DAS_MAP_LEGEND_LAYER_FLIP, flipLegendLayer),
        takeEvery(loadinSpinnerActions.SET_SPINNER_STATE, loadLegend),
        startPlayback(),
    ]);
}
