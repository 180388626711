import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactMarker from './ReactMarker';
import arePropsEqual from '../../utils/arePropsEqual';

const MarkerPin = ({
    className,
    fill,
    icon,
    status,
    label,
    position,
    animatePosition,
    animateProgressIncrement,
    dataId,
    highlight,
    ...props
}) => {
    const tranformFactor = highlight ? 'scale(1.3)' : 'scale(1)';
    const zIndexOffset = highlight ? 999 : 0;

    return (
        <ReactMarker
            position={position}
            animatePosition={animatePosition}
            animateProgressIncrement={animateProgressIncrement}
            zIndexOffset={zIndexOffset}
            {...props}
        >
            <div
                className={cn('MarkerPin', className)}
                data-id={dataId}
                style={{ transform: tranformFactor }}
            >
                <svg width="32" height="48" viewBox="0 0 32 48" fill={'none'}>
                    <path
                        className={'MarkerPin__Outer'}
                        d="M29.7177 24.2403C31.1666 21.8334 32 19.014 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 19.0139 0.833332 21.8332 2.28215 24.24L16 48L29.7177 24.2403Z"
                        fill={fill.outer}
                    />
                    <circle
                        className={'MarkerPin__Inner'}
                        cx="16"
                        cy="16"
                        r="14"
                        fill={fill.inner}
                    />
                </svg>
                <div className={'MarkerPin__Icon'}>{icon}</div>
                <div className={'MarkerPin__Status'}>{status}</div>
            </div>
            {label && (
                <div
                    className={cn(
                        'leaflet-tooltip leaflet-tooltip-right MarkerPin__Label',
                        { hovered: highlight },
                    )}
                    style={{
                        transform: tranformFactor,
                        transformOrigin: '0 50%',
                        outline: highlight ? `solid 3px ${fill.outer}` : '',
                        transition: 'outline 0.2s linear',
                    }}
                >
                    {label}
                </div>
            )}
        </ReactMarker>
    );
};

MarkerPin.defaultProps = {
    fill: {
        outer: 'black',
        inner: 'white',
    },
};

MarkerPin.propTypes = {
    // Any additional classes to be applied
    className: PropTypes.string,
    // Fills the marker
    fill: PropTypes.shape({
        // Sets the outline and arrow part of the marker
        outer: PropTypes.string,
        // Sets the circle fill colour
        inner: PropTypes.string,
    }),
    // The icon Component for the type of equipment
    icon: PropTypes.node,
    // The icon Component that represents the equipment state
    status: PropTypes.node,
    // The component to display inside the Tooltip
    label: PropTypes.node,
    // The position of the marker
    position: PropTypes.node,
    // If the marker should be animated to it's new position
    animatePosition: PropTypes.bool,
    // How far through the animation should the marker progress each tick, from 0-1
    // This number effects how long it takes to transition from point A to B
    animateProgressIncrement: PropTypes.number,
    // Use this to set an ID on the MarkerPin DOM element
    // Useful for hooking up and using mouse events
    dataId: PropTypes.string,
    //it provides bool value when the mouse is hovered over the pin
    highlight: PropTypes.bool,
};

MarkerPin.displayName = 'MarkerPin';

export default memo(MarkerPin, arePropsEqual);
