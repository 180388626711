import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_WKT_AGGREGATE_REQUESTED = `${namespace}/FETCH_WKT_AGGREGATE_REQUESTED`;
    const fetchWKTAggregateRequested = (resultsArray) =>
        action(FETCH_WKT_AGGREGATE_REQUESTED, resultsArray);

    const FETCH_WKT_AGGREGATE_SUCCESS = `${namespace}/FETCH_WKT_AGGREGATE_SUCCESS`;
    const fetchWKTAggregateSuccess = (resultsArray) =>
        action(FETCH_WKT_AGGREGATE_SUCCESS, resultsArray);

    const FETCH_WKT_AGGREGATE_ERROR = `${namespace}/FETCH_WKT_AGGREGATE_ERROR`;
    const fetchWKTAggregateError = ({ error }) =>
        action(FETCH_WKT_AGGREGATE_ERROR, { error });

    return {
        FETCH_WKT_AGGREGATE_SUCCESS,
        fetchWKTAggregateSuccess,
        FETCH_WKT_AGGREGATE_ERROR,
        fetchWKTAggregateError,
        FETCH_WKT_AGGREGATE_REQUESTED,
        fetchWKTAggregateRequested,
    };
}
