const store = window.localStorage;
export const AUTH_TOKEN_KEY = 'auth-token';
export const AUTH_TOKEN_BACKUP_KEY = 'auth-token-backup';
export const REFRESH_TOKEN_KEY = 'refresh-token';

const KEYS_TO_NOT_JSON = [AUTH_TOKEN_KEY];

export function getItem(key) {
    if (KEYS_TO_NOT_JSON.indexOf(key) > -1) {
        return store.getItem(key);
    }
    return JSON.parse(store.getItem(key));
}

export function setItem(key, value) {
    if (KEYS_TO_NOT_JSON.indexOf(key) > -1 && value) {
        store.setItem(key, value);
    } else if (value) {
        store.setItem(key, JSON.stringify(value));
    } else {
        store.removeItem(key);
    }
}

export function clear() {
    store.clear();
}
