import React, { ReactNode } from 'react';
import { Tooltip } from 'antd';
import { getSymbolTooltip } from './LoadUnitImprovementSymbol';
import { SymbolAPIName } from './types';

interface Props {
    children: ReactNode;
    enableTooltip: boolean;
    symbols: SymbolAPIName;
    arrowDirection?: number;
}

function LoadUnitImprovementSymbolContainer({
    children,
    enableTooltip = true,
    symbols,
    arrowDirection,
}: Props) {
    const content = (
        <div className={'LoadUnitImprovementSymbolContainer'}>{children}</div>
    );
    if (enableTooltip) {
        const tooltip = getSymbolTooltip(symbols);
        return <Tooltip title={tooltip({ arrowDirection })}>{content}</Tooltip>;
    }
    return content;
}

export default LoadUnitImprovementSymbolContainer;
