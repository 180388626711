import { createSelector } from 'reselect';
import * as powerBiReportsModule from '../Modules/powerBiReports';

const getPreviewButtonStatus = createSelector(
    powerBiReportsModule.selectors.getAll,
    (powerBiReportsState) => {
        const { previewButtonStatus } = powerBiReportsState;
        return previewButtonStatus;
    },
);
export default getPreviewButtonStatus;
