import shiftUtils from '@rs/core/utils/shiftUtils';
import createActions from '../../Modules/filters/actions';
import createReducer, { filterUpdated } from '../../Modules/filters/reducer';
import createSelectors from '../../Modules/filters/selectors';
import namespace from './namespace';
import { FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS } from '../../App/Actions';
import { actions as driversActions } from './drivers';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].filters;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);
const defaultReducer = createReducer(
    {
        filterUpdated: actions.FILTER_UPDATED,
        setFiltersWithUrlParams: actions.SET_FILTERS_WITH_URL_PARAMS,
    },
    {
        // Reset the truck selection if it isn't valid for the current shift
        [driversActions.FETCH_DRIVERS_SUCCESS]: (state, drivers) => {
            const truckFilterIsValid = drivers.find(
                (driver) => driver.EquipmentId === parseInt(state.Truck, 10),
                10,
            );
            if (!truckFilterIsValid) {
                return filterUpdated(state, {
                    filterName: 'Truck',
                    filterValue: '',
                });
            }
            return state;
        },
    },
    {
        Date: undefined,
        Truck: '',
        Shift: '',
    },
);

function setInitialShift(state) {
    if (state.Shift) {
        return state;
    }
    const defaultDate = shiftUtils.newDefaultFilterDate();
    const firstShift = shiftUtils.getFirstShiftFromDate(defaultDate);
    return {
        ...state,
        Date: defaultDate,
        Shift: firstShift.shortName,
    };
}

export const reducer = (state, action) => {
    let nextState = state;
    if (action.type === FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS) {
        nextState = setInitialShift(state);
    }
    return defaultReducer(nextState, action);
};
export const selectors = createSelectors(statePath);
