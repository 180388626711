import shiftUtils from '@rs/core/utils/shiftUtils';
import { createReducer } from '../../Lib/reduxUtils';
import { FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS } from '../../App/Actions';
import { FILTER_UPDATED, SET_FILTERS_WITH_URL_PARAMS } from '../Actions';
import {
    setEndDateAfterDateSelected,
    setEndDateAfterRangeSelected,
} from '../../Utils/Filters/filters';

export const initialState = {
    Date: undefined,
    EndDate: undefined,
    Shift: '',
    Loader: '',
    Operator: '',
    rangeSelected: false,
};

const fetchPrivateSiteConfigurationSuccess = (state) => {
    const defaultDate = shiftUtils.newDefaultFilterDate();
    return {
        ...state,
        Date: defaultDate,
    };
};

export function filterUpdated(state, payload) {
    const { filterName, filterValue } = payload;
    const newState = {
        ...state,
        [filterName]: filterValue,
    };
    // TODO change these methods to not mutate the passed in state
    // Handle toggling rangeSelected
    setEndDateAfterDateSelected(newState, filterName);

    // Handle setting the EndDate to the same as Date if EndDate is before start Date
    setEndDateAfterRangeSelected(newState, filterName);

    return newState;
}

export function setFiltersWithUrlParams(state, filtersFromUrl) {
    return {
        ...state,
        ...filtersFromUrl,
    };
}

export default createReducer(initialState, {
    [FILTER_UPDATED]: filterUpdated,
    [SET_FILTERS_WITH_URL_PARAMS]: setFiltersWithUrlParams,
    [FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS]: fetchPrivateSiteConfigurationSuccess,
});
