import { action } from '../../Lib/reduxUtils';
import { GoogleAnalyticsEventBuilder } from '../../Utils/userAnalytics/googleAnalyticsEventBuilder';

const NAMESPACE = 'BiasLoadsDetail';

export const LOAD_LOADER_OPERATOR_LOGS = `${NAMESPACE}/LOAD_LOADER_OPERATOR_LOGS`;
export const loadLoaderOperatorLogs = (loaderOperatorLogs) =>
    action(LOAD_LOADER_OPERATOR_LOGS, loaderOperatorLogs);

export const LOAD_LOADING_EVENTS_BIAS_LOAD_REPORT = `${NAMESPACE}/LOAD_LOADING_EVENTS_BIAS_LOAD_REPORT`;
export const loadLoadingEventsBiasLoadReport = (loadingEvents) =>
    action(LOAD_LOADING_EVENTS_BIAS_LOAD_REPORT, loadingEvents);

export const LOAD_LOADING_EVENTS = `${NAMESPACE}/LOAD_LOADING_EVENTS`;
export const loadLoadingEvents = (loadingEvents) =>
    action(LOAD_LOADING_EVENTS, loadingEvents);

export const FILTER_UPDATED = `${NAMESPACE}/FILTER_UPDATED`;
export const filterUpdated = (filterName, filterValue) =>
    action(
        FILTER_UPDATED,
        { filterName, filterValue },
        {
            analytics: new GoogleAnalyticsEventBuilder(
                'SubFeatureUsage',
                'Filter Updated',
            )
                .setLabel(NAMESPACE)
                .build(),
        },
    );

export const SORT_TABLE = `${NAMESPACE}/SORT_TABLE`;
export const sortTable = (sortingKey) => action(SORT_TABLE, { sortingKey });

export const UPDATE_FILTERS_WITH_URL_PARAMS = `${NAMESPACE}/UPDATE_FILTERS_WITH_URL_PARAMS`;
export const updateFiltersWithUrlParams = () =>
    action(UPDATE_FILTERS_WITH_URL_PARAMS);

export const SET_FILTERS_WITH_URL_PARAMS = `${NAMESPACE}/SET_FILTERS_WITH_URL_PARAMS`;
export const setFiltersWithUrlParams = (filtersFromUrl) =>
    action(SET_FILTERS_WITH_URL_PARAMS, filtersFromUrl);

export const SET_SPINNER_STATE = `${NAMESPACE}/SET_SPINNER_STATE`;
export const setSpinnerState = (isActive = false, message = '', faIcon) =>
    action(SET_SPINNER_STATE, { isActive, message, faIcon });

export const LINK_TO_PAGE = `${NAMESPACE}/LINK_TO_PAGE`;
export const linkToPage = ({ url, operatorDisplayName }) =>
    action(LINK_TO_PAGE, { url, operatorDisplayName });
