import { takeLatest, put, call, all } from 'redux-saga/effects';
import { actions } from '../CreateGroup';
import { forwardTo } from '../App/createHistory';
import * as resourceSagas from './resourceSagas';
import { actions as spinnerActions } from '../CreateGroup/Modules/spinner';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';

function* loadFeatures() {
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const { response, error } = yield call(resourceSagas.getFeatureList);

    if (error) {
        yield put(spinnerActions.setSpinnerState(false));
        return;
    }

    yield all([
        put(actions.loadFeaturesList(response)),
        put(spinnerActions.setSpinnerState(false)),
    ]);
}

function* createGroup({ payload }) {
    yield put(
        spinnerActions.setSpinnerState(
            true,
            LOADING_MESSAGES.LOADING__PROCESSING,
        ),
    );
    const apiResponse = yield call(resourceSagas.postCreateGroup, {
        Name: payload.groupInfo.name,
        Description: payload.groupInfo.description,
        Features: payload.groupInfo.features,
    });

    if (apiResponse.error) {
        yield put(actions.createGroupError(apiResponse.error));
        yield put(spinnerActions.setSpinnerState(false));
    } else {
        yield put(actions.createGroupError(null));
        yield put(spinnerActions.setSpinnerState(false));
        forwardTo(`/manage-group`);
    }
}

export default function* watch() {
    yield all([
        takeLatest(actions.SUBMIT_CREATE_GROUP, createGroup),
        takeLatest(actions.REQUEST_FEATURES_LIST, loadFeatures),
    ]);
}
