import _ from 'lodash';

const ignoreFunctions = (a: any, b: any) => {
    if (typeof a === 'function' && typeof b === 'function') {
        return true;
    }
};
const arePropsEqual = (prevProps: any, nextProps: any) => {
    return _.isEqualWith(prevProps, nextProps, ignoreFunctions);
};

export default arePropsEqual;
