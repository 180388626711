import { call, put, takeLatest, all } from 'redux-saga/effects';
import shiftUtils from '@rs/core/utils/shiftUtils';
import * as resourceSagas from './resourceSagas';
import * as loadingSpinnerModule from '../ResultsDelay/Modules/spinner';
import * as equipmentLatencyModule from '../ResultsDelay/Modules/equipmentLatency';
import * as equipmentLatencyHistoricModule from '../ResultsDelay/Modules/equipmentLatencyHistoric';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import {
    FETCH_EQUIPMENT_LATENCY,
    FETCH_EQUIPMENT_LATENCY_HISTORIC,
} from '../ResultsDelay/Actions';

function* fetchEquipmentLatency() {
    yield put(
        loadingSpinnerModule.actions.setSpinnerState(
            true,
            LOADING_MESSAGES.LOADING__DATA,
        ),
    );

    const { response, error } = yield call(resourceSagas.getEquipmentLatency);

    if (error) {
        yield put(
            equipmentLatencyModule.actions.fetchEquipmentLatencyError(
                'Unable to load equipment latency',
            ),
        );
        return;
    }
    yield put(
        equipmentLatencyModule.actions.fetchEquipmentLatencySuccess(response),
    );
    yield put(loadingSpinnerModule.actions.setSpinnerState(false));
}

function* fetchEquipmentLatencyHistoric() {
    yield put(
        loadingSpinnerModule.actions.setSpinnerState(
            true,
            LOADING_MESSAGES.LOADING__DATA,
        ),
    );

    const endDate = shiftUtils.createMomentInSiteTime();
    const startDate = endDate.clone().subtract(7, 'days');
    const { response, error } = yield call(
        resourceSagas.getEquipmentLatencyHistoric,
        {
            StartTime: startDate.unix(),
            EndTime: endDate.unix(),
        },
    );

    if (error) {
        yield put(
            equipmentLatencyHistoricModule.actions.fetchEquipmentLatencyHistoricError(
                'Unable to load equipment latency history',
            ),
        );
    } else {
        yield put(
            equipmentLatencyHistoricModule.actions.fetchEquipmentLatencyHistoricSuccess(
                response,
            ),
        );
    }
    yield put(loadingSpinnerModule.actions.setSpinnerState(false));
}

export default function* watch() {
    yield all([
        takeLatest(FETCH_EQUIPMENT_LATENCY, fetchEquipmentLatency),
        takeLatest(
            FETCH_EQUIPMENT_LATENCY_HISTORIC,
            fetchEquipmentLatencyHistoric,
        ),
    ]);
}
