import nameSpace from './namespace';
import {
    createCustomSlice as createEquipmentFilterSlice,
    selectSelectedEquipmentClasses,
    selectSelectedEquipmentModels,
    selectSelectedEquipmentSubClasses,
} from '@rs/core/falcon/components/EquipmentFilters';

export const moduleName = 'equipmentFilters';
export const selectstate = (state: any) => {
    return state[nameSpace][moduleName];
};

const equipmentfilter = createEquipmentFilterSlice(
    `${nameSpace}/${moduleName}`,
);

export const reducer = equipmentfilter.reducer;
export const actions = equipmentfilter.actions;
export {
    selectSelectedEquipmentClasses,
    selectSelectedEquipmentModels,
    selectSelectedEquipmentSubClasses,
};
