import { combineReducers } from 'redux';
import { reducer as filters } from '../Modules/filters';
import { reducer as haulTruckFillFactor } from '../Modules/haulTruckFillFactor';
import { reducer as loadingEventOperatorTargets } from '../Modules/loadingEventOperatorTargets';
import { reducer as loadingEventsConformance } from '../Modules/loadingEventsConformanceReport';
import { reducer as loadingEventsHistogram } from '../Modules/loadingEventsHistogram';
import { reducer as spinner } from '../Modules/spinner';
import namespace from '../Modules/namespace';

export const REDUCER_KEY = namespace;
export default combineReducers({
    filters,
    haulTruckFillFactor,
    loadingEventOperatorTargets,
    loadingEventsConformance,
    loadingEventsHistogram,
    spinner,
});
