import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * This <LoadingSpinnerv2> Component has been imported from figma.
 * Because it uses a radialGradient, it couldn't be animated via css
 * Thus, we've done it with js.
 */

const LoadingSpinnerV2 = () => {
    const [currentCorner, setCurrentCorner] = useState(0);

    //the angles it should rotate
    const cornerRotations = [
        0,
        15,
        30,
        45,
        60,
        75,
        90,
        105,
        120,
        135,
        150,
        165,
        180,
        195,
        210,
        225,
        240,
        255,
        270,
        285,
        300,
        315,
        330,
    ];

    const nextCornerRotation =
        cornerRotations[(currentCorner + 1) % cornerRotations.length];
    //When transitioning from 270 to 0 degrees,
    //interpolate the rotation values to avoid the sudden jump
    const rotateValue =
        currentCorner === cornerRotations.length - 1
            ? nextCornerRotation - 360
            : nextCornerRotation;
    const gradientTransform = `translate(40 40) rotate(${rotateValue}) scale(40)`;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentCorner(
                (prevCorner) => (prevCorner + 1) % cornerRotations.length,
            );
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, [cornerRotations.length]);

    return (
        <svg width="100%" height="100%" viewBox="0 0 80 80" fill="none">
            <rect
                width="100%"
                height="100%"
                rx="4"
                stroke="url(#paint0_angular_2209_112276)"
                strokeWidth="24"
                mask="url(#path-1-inside-1_2209_112276)"
            />
            <defs>
                <radialGradient
                    id="paint0_angular_2209_112276"
                    cx="1"
                    cy="1"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform={gradientTransform}
                >
                    <stop stopColor="#F5A939" />
                    <stop offset="1" stopColor="#F5A939" stopOpacity="0.35" />
                </radialGradient>
            </defs>
        </svg>
    );
};

const LoadingSpinner = (props) => {
    const { isActive, classNames } = props;
    if (!isActive) return null;

    return (
        <div className={classNames.overlay}>
            <span className={classNames.container}>
                <div style={{ height: '80px', width: '80px' }}>
                    <LoadingSpinnerV2 />
                </div>
                {/*&nbsp;*/}
                {/*{message}*/}
            </span>
        </div>
    );
};

LoadingSpinner.defaultProps = {
    faIcon: 'loading-3-quarters',
    containerClassName: 'loading-spinner',
    classNames: {
        container: 'loading-spinner',
        overlay: 'loading-spinner-overlay',
        icon: 'loading-spinner-icon',
    },
};

LoadingSpinner.propTypes = {
    isActive: PropTypes.bool.isRequired,
    message: PropTypes.string,
    faIcon: PropTypes.string,
    classNames: PropTypes.shape({
        container: PropTypes.string,
        icon: PropTypes.string,
    }),
};

export default LoadingSpinner;
