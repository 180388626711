import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_USERS_REQUESTED = `${namespace}/FETCH_USERS_REQUESTED`;
    const fetchUsersRequested = () => action(FETCH_USERS_REQUESTED);

    const FETCH_USERS_SUCCESS = `${namespace}/FETCH_USERS_SUCCESS`;
    const fetchUsersSuccess = (resultsArray) =>
        action(FETCH_USERS_SUCCESS, resultsArray);

    const FETCH_USERS_ERROR = `${namespace}/FETCH_USERS_ERROR`;
    const fetchUsersError = ({ error }) => action(FETCH_USERS_ERROR, { error });

    return {
        FETCH_USERS_SUCCESS,
        fetchUsersSuccess,
        FETCH_USERS_ERROR,
        fetchUsersError,
        FETCH_USERS_REQUESTED,
        fetchUsersRequested,
    };
}
