import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import MinusIcon from '@ant-design/icons/MinusOutlined';
import identity from 'lodash/identity';
import Table from '../Table/Table';

// Determines whether a value is null
const isNull = (value) =>
    value === null || value === undefined || Number.isNaN(value);

const LeagueTable = ({ className, columns, footer, ...rest }) => (
    <div className={cn('LeagueTable', className)}>
        <Table columns={columns} {...rest} />
        {footer && (
            // We make a footer by appending an entirely new table
            // The table has no data and only a heading row (which becomes the footer)
            // Thus the footer is setup the same way you'd setup a header in a
            // regular table and pass it with the same format as a <Table> columns prop
            <Table
                columns={footer.map((foot) => ({
                    ...foot,
                }))}
                className={'LeagueTable__Footer'}
            />
        )}
    </div>
);

LeagueTable.Cell = ({ children, className, disabled, ...props }) => (
    <div
        className={cn(
            'LeagueTable__Cell',
            {
                'LeagueTable__Cell--Disabled': disabled,
            },
            className,
        )}
        data-testid={disabled ? 'cell-disabled' : undefined}
        {...props}
    >
        {children}
    </div>
);

// The element to display when the value is null
LeagueTable.NullCell = (
    <MinusIcon
        className={'LeagueTable__Dash'}
        data-testid={'cell-null'}
    />
);

// Simple conformance cell implementation, likely this will be enhanced down the track
LeagueTable.ConformanceCell = ({
    value,
    valueFormatter,
    thresholds,
    ...props
}) => {
    let conformanceClass = '';

    if (isNull(value)) {
        conformanceClass = 'LeagueTable__Cell__Conformance--Empty';
    } else if (value < thresholds.conformanceHighToMed) {
        conformanceClass = 'LeagueTable__Cell__Conformance--High';
    } else if (value < thresholds.conformanceMedToLow) {
        conformanceClass = 'LeagueTable__Cell__Conformance--Medium';
    } else {
        conformanceClass = 'LeagueTable__Cell__Conformance--Low';
    }

    return (
        <LeagueTable.Cell
            className={cn('LeagueTable__Cell__Conformance', conformanceClass)}
            {...props}
        >
            {isNull(value) ? LeagueTable.NullCell : valueFormatter(value)}
        </LeagueTable.Cell>
    );
};

// Number cells are right aligned
LeagueTable.NumberCell = ({ value, align, ...props }) => (
    <LeagueTable.Cell
        className={'LeagueTable__Cell__Number'}
        style={{ textAlign: align }}
        {...props}
    >
        {isNull(value) ? LeagueTable.NullCell : value}
    </LeagueTable.Cell>
);

// Text cells are left aligned
LeagueTable.TextCell = ({ value, align, ...props }) => (
    <LeagueTable.Cell
        className={'LeagueTable__Cell__Text'}
        style={{ textAlign: align }}
        {...props}
    >
        {isNull(value) ? LeagueTable.NullCell : value}
    </LeagueTable.Cell>
);

LeagueTable.HeaderCell = ({ children, className, align }) => (
    <div
        className={cn('LeagueTable__Header__Cell', className)}
        style={{ textAlign: align }}
    >
        {children}
    </div>
);

LeagueTable.Cell.displayName = 'Cell';
LeagueTable.ConformanceCell.displayName = 'ConformanceCell';
LeagueTable.NumberCell.displayName = 'NumberCell';
LeagueTable.TextCell.displayName = 'TextCell';
LeagueTable.HeaderCell.displayName = 'HeaderCell';

LeagueTable.defaultProps = Table.defaultProps;
LeagueTable.propTypes = Table.propTypes;

LeagueTable.Cell.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
};
LeagueTable.Cell.defaultProps = {
    disabled: false,
};

LeagueTable.NumberCell.propTypes = {
    value: PropTypes.number,
    align: PropTypes.string,
};

LeagueTable.TextCell.propTypes = {
    value: PropTypes.string,
    align: PropTypes.string,
};

LeagueTable.ConformanceCell.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    valueFormatter: PropTypes.func,
    thresholds: PropTypes.shape({
        conformanceHighToMed: PropTypes.number,
        conformanceMedToLow: PropTypes.number,
    }),
    selectThresholdClassName: PropTypes.func.isRequired,
};
LeagueTable.ConformanceCell.defaultProps = {
    valueFormatter: identity,
    thresholds: [],
};
LeagueTable.HeaderCell.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    align: PropTypes.oneOf(['left', 'center', 'right']),
};
LeagueTable.HeaderCell.defaultProps = {
    align: 'left',
};
export default LeagueTable;
