import { action } from '../../Lib/reduxUtils';
import { GoogleAnalyticsEventBuilder } from '../../Utils/userAnalytics/googleAnalyticsEventBuilder';

const NAMESPACE = 'OperatorFeedback';

export const LOAD_DRIVERS = `${NAMESPACE}/LOAD_DRIVERS`;
export const loadDrivers = (arrayOfDrivers) =>
    action(LOAD_DRIVERS, arrayOfDrivers);

export const SET_SPINNER_STATE = `${NAMESPACE}/SET_SPINNER_STATE`;
export const setSpinnerState = (isActive = false, message = '', faIcon) =>
    action(SET_SPINNER_STATE, {
        isActive,
        message,
        faIcon,
    });

export const UPDATE_FILTERS_WITH_URL_PARAMS = `${NAMESPACE}/UPDATE_FILTERS_WITH_URL_PARAMS`;
export const updateFiltersWithUrlParams = () =>
    action(
        UPDATE_FILTERS_WITH_URL_PARAMS,
        {},
        {
            analytics: new GoogleAnalyticsEventBuilder(
                'SubFeatureUsage',
                'Filter Updated',
            )
                .setLabel(NAMESPACE)
                .build(),
        },
    );

export const SET_FILTERS_WITH_URL_PARAMS = `${NAMESPACE}/SET_FILTERS_WITH_URL_PARAMS`;
export const setFiltersWithUrlParams = (filtersFromUrl) =>
    action(SET_FILTERS_WITH_URL_PARAMS, filtersFromUrl);

export const FILTER_UPDATED = `${NAMESPACE}/FILTER_UPDATED`;
export const filterUpdated = (filterName, filterValue) =>
    action(
        FILTER_UPDATED,
        { filterName, filterValue },
        {
            analytics: new GoogleAnalyticsEventBuilder(
                'SubFeatureUsage',
                'Filter Updated',
            )
                .setLabel(NAMESPACE)
                .build(),
        },
    );

export const LOAD_TYRES_AND_SAFETY_PRODUCTIVITY = `${NAMESPACE}/LOAD_TYRES_AND_SAFETY_PRODUCTIVITY`;
export const loadTyresAndSafetyProductivity = (resultsObject) =>
    action(LOAD_TYRES_AND_SAFETY_PRODUCTIVITY, resultsObject);

export const SET_DRIVER_SHIFT_PRODUCTIVITY_JSONS = `${NAMESPACE}/SET_DRIVER_SHIFT_PRODUCTIVITY_JSONS`;
export const setDriverShiftProductivityJsons = (arrayOfJsons) =>
    action(SET_DRIVER_SHIFT_PRODUCTIVITY_JSONS, arrayOfJsons);

export const SET_ERROR_MESSAGE = `${NAMESPACE}/SET_ERROR_MESSAGE`;
export const setErrorMessage = (errorMessage) =>
    action(SET_ERROR_MESSAGE, errorMessage);
