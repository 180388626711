import {
    all,
    takeEvery,
    takeLatest,
    put,
    select,
    call,
} from 'redux-saga/effects';
import shiftUtils from '@rs/core/utils/shiftUtils';
import errorLogging from '@rs/core/utils/errorLogging';
import queryString from '@rs/core/utils/queryStringUtils';
import { MAP_LAYER_LABELS, notification } from '@rs/core/falcon';
import { FEATURE_IDS } from '@rs/core/features';
import {
    actions as filtersActions,
    selectors as filtersSelectors,
} from '../TruckAlertsAnalysis/MapView/Modules/filters';
import { actions as spinnerActions } from '../TruckAlertsAnalysis/MapView/Modules/spinner';
import { actions as truckAlertEventsActions } from '../TruckAlertsAnalysis/MapView/Modules/truckAlertEvents';
import { actions as minesiteAreasTreeActions } from '../TruckAlertsAnalysis/MapView/Modules/minesiteAreasTree';
import { actions as WKTAggregateActions } from '../TruckAlertsAnalysis/MapView/Modules/WKTAggregate';
import { actions as mapFeaturesActions } from '../TruckAlertsAnalysis/MapView/Modules/mapFeatures';
import { actions as mapLabelsActions } from '../TruckAlertsAnalysis/MapView/Modules/mapLabels';
import { getMapFiltersToAPIParams } from '../TruckAlertsAnalysis/MapView/Selectors/filters';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import * as resourceSagas from './resourceSagas';
import { API_ARRAY_DELIMITER, checkRequestsForErrors } from '../Api';
import * as actions from '../TruckAlertsAnalysis/MapView/Actions';
import * as minesiteMapModule from '../TruckAlertsAnalysis/MapView/Modules/minesiteMap';
import getSyncStateToURL from '../TruckAlertsAnalysis/MapView/Selectors/getSyncStateToURL';
import { AUTH_TOKEN_KEY, getItem } from '../Lib/localStorage';
import { getPageTitle, getPDFSiteConfig } from '../App/Selectors';
import downloadFile from '../Utils/files/downloadFile';
import { actions as mapViewMapFilterActions } from '../TruckAlertsAnalysis/MapView/Modules/mapFeatures_v2';
import polygonLayers from '../TruckAlertsAnalysis/MapView/Selectors/getMinesiteMapPolygonLayers';
import { getGraphqlSdk } from './utils';
import { Sdk, GetMapFeaturesQuery } from '../Graphql/Services';
import {
    getAllUniqueSpeedZones,
    getUniqueColorScales,
    getUniqueMaterialcolorScales,
    getUniqueMaterials,
} from '../Modules/mapFeatures_v2/helper';
import { actions as mapViewLegendActions } from '../TruckAlertsAnalysis/MapView/Modules/mapLegends';

export function* filterUpdated(action) {
    const filters = yield select(filtersSelectors.getFilters);
    const filtersThatRefetchData = [
        'Date',
        'rangeSelected',
        'EndDate',
        'Shift',
    ];
    // If dates are invalid go back to waiting for FILTER_UPDATED
    if (
        !shiftUtils.isDateStringValid(
            filters.Date,
            shiftUtils.DATE_FORMAT__VALIDATE,
        ) ||
        (filters.rangeSelected &&
            !shiftUtils.isDateStringValid(
                filters.EndDate,
                shiftUtils.DATE_FORMAT__VALIDATE,
            ))
    ) {
        return;
    }
    if (filtersThatRefetchData.indexOf(action.payload.filterName) === -1) {
        yield call(syncStateToURL);
        return;
    }
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    yield call(getTruckAlertEvents, action);
    yield call(syncStateToURL);
    yield put(spinnerActions.setSpinnerState(false));
}

export function* getTruckAlertEvents() {
    const { response, error } = yield call(resourceSagas.getLatestShiftId);
    if (error || !response || !response.length) {
        errorLogging.logException(
            new Error(
                'Unable to find latest shift uploaded, check the DB has data',
            ),
        );
        return;
    }

    const { shiftIdsSelectedShifts } = yield select(getMapFiltersToAPIParams);

    const selectedOrLastUploadShiftId = shiftUtils.pickLatestUploadedShiftId(
        response[0].ShiftId,
        shiftIdsSelectedShifts.last,
    );

    const results: [] = yield all([
        resourceSagas.getWKTAggregate({
            FirstShiftId: selectedOrLastUploadShiftId,
        }),
        resourceSagas.getMapLabels({ ShiftId: selectedOrLastUploadShiftId }),
        resourceSagas.getMinesiteAreasTree(),
        call(resourceSagas.getTruckAlertEvents, {
            ShiftId: shiftIdsSelectedShifts.all.join(API_ARRAY_DELIMITER),
        }),
        resourceSagas.getS3Download(
            `${selectedOrLastUploadShiftId}/mapFeatures.geo.json`,
        ),
    ]);

    // Check for errors - ignore mapFeatures.geo.json error when missing as not critical & will prevent
    // other results from displaying
    const hasErrors = checkRequestsForErrors(
        results.slice(0, results.length - 2),
    );
    if (hasErrors) {
        yield put(
            truckAlertEventsActions.fetchTruckAlertEventsError({
                error: 'Failed to load truck alert events.',
            }),
        );
        yield put(spinnerActions.setSpinnerState(false));
        return;
    }

    const [
        wktAggregate,
        mapLabels,
        minesiteAreasTree,
        truckAlertEvents,
        mapFeatures,
    ] = results;

    yield put(mapLabelsActions.fetchMapLabelsSuccess(mapLabels.response));
    yield put(
        mapFeaturesActions.fetchMapFeaturesSuccess(
            (mapFeatures.response && mapFeatures.response.features) || [],
        ),
    );
    yield put(
        truckAlertEventsActions.fetchTruckAlertEventsSuccess(
            truckAlertEvents.response,
        ),
    );
    yield put(
        minesiteAreasTreeActions.fetchMinesiteAreasTreeSuccess(
            minesiteAreasTree.response,
        ),
    );
    yield put(
        WKTAggregateActions.fetchWKTAggregateSuccess(wktAggregate.response),
    );
}

export function* updateFiltersWithURLParams() {
    const { TruckAlertsMapView } = yield select();
    const mapFeatures =
        TruckAlertsMapView?.MAP_FEATURES?.speedZonesGeojson?.features;

    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const params = queryString.convertURLQueryStringToObject();
    yield put(actions.syncURLToState(params));
    yield call(getTruckAlertEvents);
    if (mapFeatures.length === 0) {
        yield call(prepareToFetchMapFeatures);
    }

    yield put(spinnerActions.setSpinnerState(false));
}

export function* syncStateToURL() {
    const state = yield select();
    const params = getSyncStateToURL(state);
    queryString.updateURLQueryString(params);
}

export function* printPDFMap({ payload }) {
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const token: string = yield call(getItem, AUTH_TOKEN_KEY);

    const { baseURL } = yield select(getPDFSiteConfig);
    const title: string = yield select(
        getPageTitle,
        FEATURE_IDS.TAB_ID__TRUCK_ALERTS_ANALYSIS,
    );

    const params = queryString.getQueryString({
        authToken: token,
        pageTitle: title,
        ...payload.params,
    });

    const url = `${baseURL}${payload.endpoint}?${params}`;
    const { response, error } = yield call(resourceSagas.getPDFMap, url);

    const spacelessTitle = title.replace(/\s+/g, '');
    const now = shiftUtils.createMomentInSiteTime().format('YYYYMMDD-HHmm');
    const filename = `${spacelessTitle}-${now}.pdf`;

    yield put(spinnerActions.setSpinnerState(false));
    if (error) {
        notification.error({
            message: 'File download failed',
            description: `Error downloading ${filename}`,
        });
        return;
    }
    downloadFile(response, filename);
}

export function* prepareToFetchMapFeatures() {
    try {
        const { shiftIdsSelectedShifts } = yield select(
            getMapFiltersToAPIParams,
        );

        const { last } = shiftIdsSelectedShifts;

        // get the graphql sdk
        const sdk: Sdk = getGraphqlSdk();
        const result: GetMapFeaturesQuery = yield call(sdk.GetMapFeatures, {
            input: {
                shiftIds: [last],
            },
        });

        const response = result?.getMapFeatures?.featuresByShiftId;
        //filter out the mapfeatures according to the last processed shift
        const featuresForSelectedShift = response?.filter(
            (arr) => arr?.shiftId === last,
        )[0];

        const {
            speedZonesGeojson,
            womidsGeojson,
            trackingRegionsGeojson,
            materialMovementsGeojson,
        } = featuresForSelectedShift;

        const emptyFeatureCollection = {
            type: 'FeatureCollection',
            features: [],
        };
        const parsedSpeedZonesGeojson =
            speedZonesGeojson === '' ||
            JSON.parse(speedZonesGeojson)?.features === null
                ? emptyFeatureCollection
                : JSON.parse(speedZonesGeojson);
        const parsedWomidsGeojson =
            womidsGeojson === '' || JSON.parse(womidsGeojson)?.features === null
                ? emptyFeatureCollection
                : JSON.parse(womidsGeojson);
        const parsedTrackingRegionsGeojson =
            trackingRegionsGeojson === '' ||
            JSON.parse(trackingRegionsGeojson)?.features === null
                ? emptyFeatureCollection
                : JSON.parse(trackingRegionsGeojson);
        const parsedMaterialMovementsGeojson =
            materialMovementsGeojson === '' ||
            JSON.parse(materialMovementsGeojson)?.features === null
                ? emptyFeatureCollection
                : JSON.parse(materialMovementsGeojson);

        yield all([
            put(
                mapViewMapFilterActions.setSpeedZonesGeojson(
                    parsedSpeedZonesGeojson,
                ),
            ),
            put(mapViewMapFilterActions.setWomidsGeojson(parsedWomidsGeojson)),
            put(
                mapViewMapFilterActions.setTrackingRegionsGeojson(
                    parsedTrackingRegionsGeojson,
                ),
            ),
            put(
                mapViewMapFilterActions.setMaterialMovementsGeojson(
                    parsedMaterialMovementsGeojson,
                ),
            ),
        ]);

        // get the state of the maplayers
        const {
            speedRestrictedZones,
            materialMovement,
            mineFeatures,
            mineRegions,
        } = yield select(polygonLayers);

        // set map legend sections
        yield put(
            mapViewLegendActions.SetSections([
                {
                    label: MAP_LAYER_LABELS.speedLimitedZones,
                    visibility: speedRestrictedZones,
                    items: Object.entries(
                        getUniqueColorScales(
                            getAllUniqueSpeedZones(
                                parsedSpeedZonesGeojson,
                            ) as any,
                        ),
                    ).map(([key, val]) => {
                        return {
                            label: `${key}kph`,
                            borderColor: `${val}`,
                            fillColor: `${val}`,
                            visibility: 'VISIBLE',
                        };
                    }),
                },
                {
                    label: MAP_LAYER_LABELS.materialMovement,
                    visibility: materialMovement,
                    items: Object.entries(
                        getUniqueMaterialcolorScales(
                            getUniqueMaterials(
                                parsedMaterialMovementsGeojson,
                            ) as any,
                        ),
                    ).map(([key, val]) => {
                        return {
                            label: key,
                            borderColor: val,
                            fillColor: val,
                            visibility: 'VISIBLE',
                        };
                    }),
                },
                {
                    /* any polygon that has Maintenance, TyreBay or Workshop in them will be categorised as Maintenence
                   anything else is ancilary/goline
                */

                    label: MAP_LAYER_LABELS.trackingRegions,
                    visibility: mineFeatures,
                    items: [
                        {
                            label: 'Maintenance',
                            borderColor: '#45818e',
                            fillColor: '#45818e',
                            visibility: 'VISIBLE',
                        },

                        {
                            label: 'Ancillary/GoLine',
                            borderColor: '#ff9900',
                            fillColor: '#ff9900',
                            visibility: 'VISIBLE',
                        },
                    ],
                },
                {
                    label: MAP_LAYER_LABELS.mineRegions,
                    visibility: mineRegions,
                    items: [
                        {
                            label: 'MineRegions',
                            borderColor: '#000000',
                            fillColor: '',
                            visibility: 'VISIBLE',
                        },
                    ],
                },
            ]),
        );
    } catch (error) {
        yield put(spinnerActions.setSpinnerState(false));
        throw new Error(error.message);
    }
}

type FlipLegendPayload = {
    payload: { sectionLabel: string; visibility: Visibility };
};

export function* flipLegendLayer({ payload }: FlipLegendPayload) {
    try {
        // fire action to toggle legend layer
        yield put(mapViewLegendActions.ChangeSectionVisibility(payload));
    } catch (error) {
        throw new Error(error.message);
    }
}

export default function* watch() {
    yield all([
        takeLatest(
            filtersActions.UPDATE_FILTERS_WITH_URL_PARAMS,
            updateFiltersWithURLParams,
        ),
        takeLatest(filtersActions.FILTER_UPDATED, filterUpdated),
        takeEvery(
            [
                minesiteMapModule.actions.MAP_VIEWPORT_CHANGED,
                minesiteMapModule.actions.MAP_FILTER_UPDATED,
            ],
            syncStateToURL,
        ),
        takeEvery(actions.PRINT_PDF_MAP, printPDFMap),
        takeEvery(actions.MAP_LEGEND_LAYER_FLIP, flipLegendLayer),
    ]);
}
