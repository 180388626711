import { reducer, sliceSelectors } from './reducer';

const AUTH_REDUCER_KEY = 'Auth';
const stateSelectors = {
    getSpinnerState: (state) =>
        sliceSelectors.getSpinnerState(state[AUTH_REDUCER_KEY]),
    getApiErrorMessage: (state) =>
        sliceSelectors.getApiErrorMessage(state[AUTH_REDUCER_KEY]),
    getPasswordResetSuccessMessage: (state) =>
        sliceSelectors.getPasswordResetSuccessMessage(state[AUTH_REDUCER_KEY]),
    getEmailAddress: (state) =>
        sliceSelectors.getEmailAddress(state[AUTH_REDUCER_KEY]),
    getFullName: (state) => sliceSelectors.getFullName(state[AUTH_REDUCER_KEY]),
    getUserRoles: (state) =>
        sliceSelectors.getUserRoles(state[AUTH_REDUCER_KEY]),
    hasRole: (state, roleId) =>
        sliceSelectors.hasRole(state[AUTH_REDUCER_KEY], roleId),
    getUsersHomePage: (state) =>
        sliceSelectors.getUsersHomePage(state[AUTH_REDUCER_KEY], state),
    getUniqueDriverId: (state) =>
        sliceSelectors.getUniqueDriverId(state[AUTH_REDUCER_KEY]),
    getLoginForm: (state) =>
        sliceSelectors.getLoginForm(state[AUTH_REDUCER_KEY]),
    getReferrer: (state) => state[AUTH_REDUCER_KEY].referrer,
    getAuthenticationMethod: (state) =>
        state[AUTH_REDUCER_KEY].authenticationMethod,
};

export { stateSelectors, sliceSelectors, AUTH_REDUCER_KEY };

export default reducer;
