import { withLeaflet, Path } from 'react-leaflet';
import * as L from 'leaflet';
import 'leaflet-polylinedecorator';
import 'leaflet.arc';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';

function createLayers(props) {
    const { positions, leaflet, options } = props;

    const innerPath = L.polyline(positions, {
        color: options.innerColor,
        weight: 6,
        opacity: options.opacity,
        pane: leaflet.pane,
    });

    const outerPath = L.polyline(positions, {
        color: 'black',
        weight: 8,
        opacity: options.opacity,
        pane: leaflet.pane,
    });

    const innerArrowHead = L.polylineDecorator(innerPath, {
        patterns: [
            {
                offset: '100%',
                repeat: 0,
                symbol: L.Symbol.arrowHead({
                    pixelSize: 20,
                    polygon: true,
                    headAngle: 45,
                    pathOptions: {
                        color: options.innerColor,
                        weight: 6,
                        stroke: true,
                        opacity: options.opacity,
                        fillColor: options.innerColor,
                        fillOpacity: 1,
                        pane: leaflet.pane,
                    },
                }),
            },
        ],
    });

    const outerArrowHead = L.polylineDecorator(innerPath, {
        patterns: [
            {
                offset: '100%',
                repeat: 0,
                symbol: L.Symbol.arrowHead({
                    pixelSize: 20,
                    polygon: true,
                    headAngle: 45,
                    pathOptions: {
                        color: 'black',
                        weight: 8,
                        stroke: true,
                        opacity: options.opacity,
                        fillColor: options.fillColor,
                        fillOpacity: options.opacity,
                        pane: leaflet.pane,
                    },
                }),
            },
        ],
    });

    return [outerPath, innerPath, outerArrowHead, innerArrowHead];
}

class DecoratedPath extends Path {
    updateLeafletElement(fromProps, toProps) {
        this.leafletElement.eachLayer(() => {
            // Rerendering whenever different because the path decorating lib
            // doesn't update if you just change the styles.
            // This is possible an issue later but since we only render ~5 paths
            // it isn't a problem now
            if (!deepEqual(toProps, fromProps)) {
                // Discard layers
                this.leafletElement.clearLayers();

                // Redraw layers
                createLayers(this.props).forEach((layer) => {
                    this.leafletElement.addLayer(layer);
                });
            }
        });
    }

    createLeafletElement() {
        return L.layerGroup(createLayers(this.props));
    }
}

DecoratedPath.propTypes = {
    positions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
};

export default withLeaflet(DecoratedPath);
