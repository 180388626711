import { createReducer } from '../../Lib/reduxUtils';

const initialState = {
    isActive: false,
    message: '',
    faIcon: undefined,
};

export function setSpinnerState(state, { isActive, message, faIcon }) {
    return {
        isActive,
        message,
        faIcon,
    };
}

/**
 * Creates a reducer for this module. It has some default behaviour but also allows custom actions to be supplied
 * @param {object} actions {<internalActionName>: <ACTION_TYPE>} maps an internalActionName to an ACTION_TYPE. The handler for this ACTION_TYPE is the default behaviour
 * @param {object} customActions {<ACTION_TYPE>: actionHandler} maps an ACTION_TYPE to an action handler
 * @return {function(*=, {type: *, payload?: *})}
 */
export default function createReducers(actions, customActions = {}) {
    return createReducer(initialState, {
        [actions.setSpinnerState]: setSpinnerState,
        /**
         * Ensure any custom action handlers pass along state + any additional arguments needed for the
         * action handlers
         */
        ...customActions,
    });
}
