import { arrayToObject } from '@rs/core/utils/dataUtils';
import createActions from '../../../Modules/truckAlertEventsByTruckName/actions';
import createReducer from '../../../Modules/truckAlertEventsByTruckName/reducer';
import createSelectors from '../../../Modules/truckAlertEventsByTruckName/selectors';
import namespace from './namespace';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].maxMineAlertCategories;

/**
 * Create the module parts
 */
export const actions = createActions(`${namespace}/MaxMineAlertCategories`);
export const reducer = createReducer(
    {
        onSuccess: actions.FETCH_TRUCK_ALERT_EVENTS_BY_TRUCK_NAME_SUCCESS,
        onError: actions.FETCH_TRUCK_ALERT_EVENTS_BY_TRUCK_NAME_ERROR,
    },
    {},
    {
        [actions.FETCH_TRUCK_ALERT_EVENTS_BY_TRUCK_NAME_SUCCESS]: (
            state,
            payload,
        ) =>
            arrayToObject(
                payload,
                'ManufacturerAlertId',
                (value) => value.MaxMineAlertCategory,
            ),
    },
);

const defaultSelectors = createSelectors(statePath);

export const selectors = {
    ...defaultSelectors,
    getUniqueMaxMineAlertCategories: (rootState) => {
        const state = statePath(rootState);
        return Array.from(
            Object.keys(state).reduce((collection, alertId) => {
                const maxMineAlertCategory = state[alertId];
                collection.add(maxMineAlertCategory);
                return collection;
            }, new Set()),
        );
    },
};
