import { action } from '../../Lib/reduxUtils';
import { GoogleAnalyticsEventBuilder } from '../../Utils/userAnalytics/googleAnalyticsEventBuilder';

export const SET_SPINNER_STATE = `Auth/SET_SPINNER_STATE`;

export const setSpinnerState = (isActive = false, message = '', faIcon) =>
    action(SET_SPINNER_STATE, {
        isActive,
        message,
        faIcon,
    });

export const SET_USER_EMAIL = 'Auth/SET_USER_EMAIL';
export const setUserEmail = (email) => action(SET_USER_EMAIL, email);

export const LOGIN_REQUESTED = 'Auth/LOGIN_REQUESTED';
export const loginRequested = (credentials) =>
    action(LOGIN_REQUESTED, credentials);

export const RESET_PASSWORD_LINK_REQUESTED =
    'Auth/RESET_PASSWORD_LINK_REQUESTED';
export const resetPasswordLinkRequested = (credentials) =>
    action(RESET_PASSWORD_LINK_REQUESTED, credentials);

export const RESET_PASSWORD_SUCCESS = 'Auth/RESET_PASSWORD_SUCCESS';
export const resetPasswordSuccess = (successMessage) =>
    action(RESET_PASSWORD_SUCCESS, successMessage);

export const RESET_PASSWORD_ERROR = 'Auth/RESET_PASSWORD_ERROR';
export const resetPasswordError = (apiErrorMessage) =>
    action(RESET_PASSWORD_ERROR, apiErrorMessage);

export const AUTH_USERNAME_SUBMITTED = 'Auth/AUTH_USERNAME_SUBMITTED';
export const authUsernameSubmitted = (username) =>
    action(AUTH_USERNAME_SUBMITTED, username);

export const AUTH_PASSWORD_SUBMITTED = 'Auth/AUTH_PASSWORD_SUBMITTED';
export const authPasswordSubmitted = (username) =>
    action(AUTH_PASSWORD_SUBMITTED, username);

export const AUTH_LOGIN_SUBMITTED = 'Auth/AUTH_LOGIN_SUBMITTED';
export const authLoginSubmitted = (loginForm) =>
    action(AUTH_LOGIN_SUBMITTED, loginForm);

export const LOGIN_SUCCESS = 'Auth/LOGIN_SUCCESS';
export const loginSuccess = ({ token, refreshToken, decodedToken }) =>
    action(
        LOGIN_SUCCESS,
        { token, refreshToken, decodedToken },
        {
            // TODO UAP
            analytics: new GoogleAnalyticsEventBuilder(
                'UserAuthentication',
                'LoginSuccessfully',
            ).build(),
        },
    );

export const CLEAR_API_ERROR_MESSAGE = 'Auth/CLEAR_API_ERROR_MESSAGE';
export const clearApiErrorMessage = () => action(CLEAR_API_ERROR_MESSAGE);

export const TOKEN_UPDATED_SUCCESS = 'Auth/TOKEN_UPDATED_SUCCESS';
export const tokenUpdatedSuccess = ({ token }) =>
    action(TOKEN_UPDATED_SUCCESS, { token });

export const SET_REFERRER = 'Auth/SET_REFERRER';
export const setReferrer = (referrer) => action(SET_REFERRER, referrer);

export const SET_AUTHENTICTION_METHOD = 'Auth/SET_AUTHENTICTION_METHOD';
export const setAuthenticationMethod = (method) =>
    action(SET_AUTHENTICTION_METHOD, method);

export const GET_AUTHENTICTION_METHOD_REQUESTED =
    'Auth/GET_AUTHENTICTION_METHOD_REQUESTED';
export const getAuthenticationMethodRequested = (email) =>
    action(GET_AUTHENTICTION_METHOD_REQUESTED, email);

export const GET_AUTHENTICTION_METHOD_ERROR =
    'Auth/GET_AUTHENTICTION_METHOD_ERROR';
export const getAuthenticationMethodError = (apiErrorMessage) =>
    action(GET_AUTHENTICTION_METHOD_ERROR, apiErrorMessage, {
        analytics: new GoogleAnalyticsEventBuilder(
            'UserAuthentication',
            'Get Authentication Method Failed',
        )
            .setLabel(apiErrorMessage)
            .build(),
    });

export const LOGIN_ERROR = 'Auth/LOGIN_ERROR';
export const loginError = (apiErrorMessage) =>
    action(LOGIN_ERROR, apiErrorMessage, {
        analytics: new GoogleAnalyticsEventBuilder(
            'UserAuthentication',
            'LoginFailed',
            // TODO UAP
        )
            .setLabel(apiErrorMessage)
            .build(),
    });

export const TOKEN_VALIDATE = 'Auth/TOKEN_VALIDATE';
export const tokenValidate = ({ token, refreshToken }) =>
    action(TOKEN_VALIDATE, { token, refreshToken });

export const LOGOUT = 'Auth/LOGOUT';
export const logout = () =>
    action(LOGOUT, null, {
        analytics: new GoogleAnalyticsEventBuilder(
            'UserAuthentication',
            'LogoutSuccessfully',
        ).build(),
    });

export const FORM_STEP_FORWARD = 'Auth/FORM_STEP_FORWARD';
export const formStepForward = () => action(FORM_STEP_FORWARD);

export const FORM_STEP_BACKWARD = 'Auth/FORM_STEP_BACKWARD';
export const formStepBackward = () => action(FORM_STEP_BACKWARD);
