import * as React from 'react';

function SvgStatusDumping(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <circle cx={512} cy={512} r={498.84} fill="#fff" />
            <path d="M512 50a462.14 462.14 0 01179.82 887.71A462.13 462.13 0 01332.18 86.29 459.06 459.06 0 01512 50m0-50C229.23 0 0 229.23 0 512s229.23 512 512 512 512-229.23 512-512S794.77 0 512 0z" />
            <path d="M538.77 375.76c-47.81 1.05-90.87 9.57-128.15 25.35-36.56 15.48-68 38-93.32 67.07-63.38 72.52-59 129.18-59.44 186.29l-88.28.45a9.82 9.82 0 00-7.75 15.75l137.34 172.41a9.79 9.79 0 0015.28-.11l135.48-173.7a9.75 9.75 0 00-7.78-15.75l-92.24.47c.41-43-7.24-75.32 37-125.62 33.8-38.47 85-58.83 152.37-60.6a10 10 0 009.7-10v-72a10 10 0 00-10.21-10.01z" />
            <path d="M861.87 485.26L598.83 670a9.31 9.31 0 01-10.22.15l-102.84-62.44a10.33 10.33 0 01-4-13.46l13.41-25a9.07 9.07 0 0112.91-3.2l76 46.12a9.31 9.31 0 0010.22-.15l215.25-151.11a9.3 9.3 0 003.65-9.32L784 287.17a9 9 0 017.67-10.85l28.2-3.4a10.35 10.35 0 0111.13 8.59l34.51 194.43a9.28 9.28 0 01-3.64 9.32z" />
        </svg>
    );
}

export default SvgStatusDumping;
