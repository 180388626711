import React from 'react';
import PropTypes from 'prop-types';
import {
    LayerGroup,
    LayersControl,
    Marker,
    Pane,
    Tooltip,
} from 'react-leaflet';
import shouldComponentUpdateUtil from './utils/shouldComponentUpdate';
import { ZINDEX } from './constants';

// TODO handle label rotation?
class RoadLabels extends React.Component {
    static propTypes = {
        // TODO data shape
        labels: PropTypes.arrayOf(PropTypes.shape({})),
        // The text that will be displayed in the layer control
        name: PropTypes.string.isRequired,
        // The initial state of if to display the layer
        checked: PropTypes.bool.isRequired,
        shouldComponentUpdate: PropTypes.func,
        // The order a layer should be rendered in, higher number displays on top
        priority: PropTypes.number.isRequired,
    };

    shouldComponentUpdate(nextProps) {
        return shouldComponentUpdateUtil(this.props, nextProps);
    }

    render() {
        // This is offsetting the marker size so the label is where the marker would be pointing
        const tooltipOffset = [0, 41];
        const {
            labels,
            name,
            checked,
            priority,
            shouldComponentUpdate,
            ...leafletInjectedProps
        } = this.props;

        const zIndex = ZINDEX.MAP_PANE + priority;

        return (
            <LayersControl.Overlay
                {...leafletInjectedProps}
                checked={checked}
                name={name}
            >
                <LayerGroup>
                    <Pane name="RoadLabels" style={{ zIndex }}>
                        {labels.map((label, i) => (
                            <Marker
                                key={i}
                                opacity={0}
                                position={[label.Latitude, label.Longitude]}
                            >
                                <Tooltip
                                    direction="top"
                                    opacity={1}
                                    offset={tooltipOffset}
                                    permanent={true}
                                    className="RoadLabel"
                                    interactive={false}
                                >
                                    <span>{label.DisplayText}</span>
                                </Tooltip>
                            </Marker>
                        ))}
                    </Pane>
                </LayerGroup>
            </LayersControl.Overlay>
        );
    }
}

export default RoadLabels;
