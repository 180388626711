// NOTE: These should be in sync with the less variables
const spacingBase = 8;
const spacing = {
    sm: spacingBase,
    md: spacingBase * 2,
    lg: spacingBase * 3,
    xl: spacingBase * 4,
    xxl: spacingBase * 5,
};

export default {
    spacing,
};
