import { createSelector } from 'reselect';
import shiftUtils from '@rs/core/utils/shiftUtils';
import { filterShiftOptionsToReactSelectFormat } from '../../Utils/Filters/filterOptionsToReactSelectFormat';
import { selectors as haulRoadConditionSummarySelectors } from '../Modules/haulRoadConditionSummary';
import { selectors as minesiteAreasTreeSelectors } from '../Modules/minesiteAreasTree';
import getFilters from './filters/getFilters';
import { getTopLevelWhereOnMinesiteIdDepth } from '../../App/Selectors';
import filterOutTopLevelWOMIDs from '../../Modules/minesiteAreasTree/utils/filterOutTopLevelWomids';

const { getHaulRoadConditionSummary } = haulRoadConditionSummarySelectors;

export const getFilterOptions = createSelector(
    getHaulRoadConditionSummary,
    getFilters,
    minesiteAreasTreeSelectors.getAll,
    getTopLevelWhereOnMinesiteIdDepth,
    (
        haulRoadConditionSummary,
        filters,
        minesiteAreasTree,
        topLevelWhereOnMinesiteIdDepth,
    ) => {
        const uniqueWhereOnMinesiteIds = filterOutTopLevelWOMIDs(
            minesiteAreasTree,
            topLevelWhereOnMinesiteIdDepth,
        );

        const whereOnMinesiteIds = uniqueWhereOnMinesiteIds.map((row) => ({
            label: row.Name,
            value: row.WhereOnMinesiteId,
        }));

        const shifts = shiftUtils.getAllShiftShortNamesForDate(filters.Date);

        return {
            shifts: filterShiftOptionsToReactSelectFormat(
                shifts.rawShiftsInDay,
                shifts.shiftShortNames,
            ),
            isLoaded: [
                { label: 'Loaded', value: 'Loaded' },
                { label: 'Unloaded', value: 'Unloaded' },
            ],
            whereOnMinesiteIds,
        };
    },
);

export const getMapFiltersToAPIParams = createSelector(
    getFilters,
    (filters) => {
        const daysToIncludeInResults = 30;
        const start = shiftUtils
            .createMomentInSiteTime(
                filters.Date,
                shiftUtils.DATE_FORMAT__VALIDATE,
            )
            .subtract(daysToIncludeInResults, 'day')
            .format(shiftUtils.DATE_FORMAT__DISPLAY);

        const shiftIds = shiftUtils.generateShiftIdRange(start, filters.Date);
        const shiftIdSelected = shiftUtils.getShiftIdFromDateString(
            filters.Date,
            filters.Shift,
        );

        return {
            shiftIds: shiftUtils.getAllFirstLastFromArray(shiftIds),
            shiftIdSelected,
        };
    },
);
