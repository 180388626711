import PropTypes from 'prop-types';

export const typeGeoJSON = PropTypes.shape({
    type: PropTypes.string.isRequired,
    coordinates: PropTypes.arrayOf(
        PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number.isRequired)),
    ),
});

export const typeMapFeature = PropTypes.shape({
    type: PropTypes.string.isRequired,
    geometry: typeGeoJSON,
    geometry_lat_lng: typeGeoJSON,
    properties: PropTypes.shape({
        category: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        firstshift: PropTypes.number.isRequired,
        lastshift: PropTypes.number.isRequired,
        iteration: PropTypes.number,
    }),
});
