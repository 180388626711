import { reducer, sliceSelectors } from './reducer';

export const OPERATOR_FEEDBACK_REDUCER_KEY = 'OperatorFeedback';
export const stateSelectors = {
    getAllDrivers: (state) =>
        sliceSelectors.getAllDrivers(state[OPERATOR_FEEDBACK_REDUCER_KEY]),
    getFilters: (state) =>
        sliceSelectors.getFilters(state[OPERATOR_FEEDBACK_REDUCER_KEY]),
    getFilterOptions: (state) =>
        sliceSelectors.getFilterOptions(state[OPERATOR_FEEDBACK_REDUCER_KEY]),
    getSpinnerState: (state) =>
        sliceSelectors.getSpinnerState(state[OPERATOR_FEEDBACK_REDUCER_KEY]),
    getDefinedFilters: (state) =>
        sliceSelectors.getDefinedFilters(state[OPERATOR_FEEDBACK_REDUCER_KEY]),
    getWktData: (state) =>
        sliceSelectors.getWktData(state[OPERATOR_FEEDBACK_REDUCER_KEY]),
    getTyresAndSafetyData: (state) =>
        sliceSelectors.getTyresAndSafetyData(
            state[OPERATOR_FEEDBACK_REDUCER_KEY],
        ),
    getCornerLeagueTotal: (state) =>
        sliceSelectors.getCornerLeagueTotal(
            state[OPERATOR_FEEDBACK_REDUCER_KEY],
        ),
    getDriverProductivityEvent: (state, eventId) =>
        sliceSelectors.getDriverProductivityEvent(
            state[OPERATOR_FEEDBACK_REDUCER_KEY],
            eventId,
        ),
    getTopEventsByLostTime: (state, count) =>
        sliceSelectors.getTopEventsByLostTime(
            state[OPERATOR_FEEDBACK_REDUCER_KEY],
            count,
        ),
    getShiftProductivityEvent: (state, id) =>
        sliceSelectors.getShiftProductivityEvent(
            state[OPERATOR_FEEDBACK_REDUCER_KEY],
            id,
        ),
    getDriverEventJsons: (state) =>
        sliceSelectors.getDriverEventJsons(
            state[OPERATOR_FEEDBACK_REDUCER_KEY],
        ),
    getErrorMessage: (state) =>
        sliceSelectors.getErrorMessage(state[OPERATOR_FEEDBACK_REDUCER_KEY]),
    getConformanceSummaryBadEvents: (state) =>
        sliceSelectors.getConformanceSummaryBadEvents(
            state[OPERATOR_FEEDBACK_REDUCER_KEY],
        ),
    getDriverEventsByShiftProductivityEventId: (
        state,
        shiftProductivityEventId,
    ) =>
        sliceSelectors.getDriverEventsByShiftProductivityEventId(
            state[OPERATOR_FEEDBACK_REDUCER_KEY],
            shiftProductivityEventId,
        ),
    getDriversShift: (state) =>
        sliceSelectors.getDriversShift(state[OPERATOR_FEEDBACK_REDUCER_KEY]),
    getMapFeatures: (state) =>
        sliceSelectors.getMapFeatures(state[OPERATOR_FEEDBACK_REDUCER_KEY]),
    getMinesiteAreasTree: (state) =>
        sliceSelectors.getMinesiteAreasTree(
            state[OPERATOR_FEEDBACK_REDUCER_KEY],
        ),
};

export { sliceSelectors };

export default reducer;
