import { getAllIndicesOfString } from '@rs/core/utils/dataUtils';

/**
 * Adds the correct classes to table elements
 * @param {string} input
 * @return {string}
 */
function updateTableClasses(input) {
    const html = new DOMParser().parseFromString(input, 'text/html');
    const tables = html.querySelectorAll('table');
    tables.forEach((table) => {
        table.classList.add('Table--Default');
    });
    // Modern browsers
    if (html && html.body) {
        return html.body.outerHTML;
    }
    // IE doesn't have body
    return html.outerHTML;
}

/**
 * Replaces a youtube link with an embedded youtube iframe
 * @param {string} input
 * @return {string}
 */
function embedYoutubeLink(input) {
    const anchorStartIndexes = getAllIndicesOfString(input, '<a');
    if (!anchorStartIndexes.length) return input;
    return anchorStartIndexes.reduce((str, anchorStartIndex) => {
        const subString = input.slice(anchorStartIndex);
        const anchorEndIndex = subString.indexOf('</a>');

        if (!anchorEndIndex || !subString.length) {
            return str + subString;
        }

        const anchorSubstring = input.slice(
            anchorStartIndex,
            anchorStartIndex + anchorEndIndex,
        );

        if (anchorSubstring.indexOf('youtube.com') === -1) {
            return str + subString;
        }
        const parts = anchorSubstring.split('"');

        const iframe = `<div class="IFrameVideoWrapper"><iframe src="${parts[1]}" frameBorder="0" allow="autoplay; encrypted-media" width="560" height="349" allowFullScreen ></iframe ></div>`;
        const anchorClosingTagLength = '</a>'.length;
        return [
            input.slice(0, anchorStartIndex),
            iframe,
            input.slice(
                anchorStartIndex + anchorEndIndex + anchorClosingTagLength,
            ),
        ].join('');
    }, '');
}

export default {
    embedYoutubeLink,
    updateTableClasses,
};
