import { put, all, takeLeading, call, takeLatest } from 'redux-saga/effects';
import * as loadingSpinnerModule from '../PowerBi/Modules/spinner';
import { actions as PowerBiReportsAction } from '../PowerBi/Modules/powerBiReports';
import * as resourceSagas from './resourceSagas';
import { checkRequestsForErrors } from '../Api';
import isEmpty from 'lodash/isEmpty';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import errorLogging from '@rs/core/utils/errorLogging';
import * as AuthActions from '../Auth/Actions';

export function* prepareToFetchAllReports() {
    try {
        const powerBiReports = yield call(resourceSagas.getAllPowerBiReports, {
            skiptoken: true,
        });

        if (checkRequestsForErrors([powerBiReports])) {
            errorLogging.logException(
                `Fetch all Power Bi Report API request failed.`,
            );
            return;
        }
        const requireReportFields = powerBiReports.response.map((report) => {
            return {
                id: report.Id,
                reportName: report.ReportName,
                reportEmbedUrl: report.ReportEmbedUrl,
                notes: report.Notes,
                updatedAt: report.UpdatedAt,
                accessToken: report.AccessToken,
                accessTokenExpireAt: report.AccessTokenExpireAt,
            };
        });

        yield put(
            PowerBiReportsAction.fetchAllReportsSuccess(requireReportFields),
        );
    } catch (e) {
        console.error(e);
    }
}

export function* prepareToFetchReport({ payload }) {
    try {
        yield put(PowerBiReportsAction.clearData());
        yield put(loadingSpinnerModule.actions.setSpinnerState(true));
        const powerBiReport = yield call(resourceSagas.getPowerBiReport, {
            Id: payload,
        });
        if (checkRequestsForErrors([powerBiReport])) {
            yield put(
                PowerBiReportsAction.fetchReportError({
                    message: 'There are no results for this query.',
                }),
            );
            yield put(loadingSpinnerModule.actions.setSpinnerState(false));
            return;
        }

        const report = powerBiReport.response.PowerBiReports[0];
        // as api returns a empty object
        if (isEmpty(report)) {
            yield put(PowerBiReportsAction.setPowerBiReportStatus('CREATE'));
            yield put(PowerBiReportsAction.fetchReportSuccess(null));
            yield put(loadingSpinnerModule.actions.setSpinnerState(false));
            return;
        }
        const requireReportFields = {
            id: report.Id,
            reportName: report.ReportName,
            reportEmbedUrl: report.ReportEmbedUrl,
            notes: report.Notes,
            updatedAt: report.UpdatedAt,
            accessToken: report.AccessToken,
            accessTokenExpireAt: report.AccessTokenExpireAt,
        };
        yield put(PowerBiReportsAction.setPowerBiReportStatus('EDIT'));
        yield put(PowerBiReportsAction.fetchReportSuccess(requireReportFields));
        yield put(loadingSpinnerModule.actions.setSpinnerState(false));
    } catch (e) {
        console.error(e);
    }
}

export function* prepareToFetchEditReport({ payload }) {
    try {
        yield put(
            loadingSpinnerModule.actions.setSpinnerState(
                true,
                LOADING_MESSAGES.LOADING__DATA,
            ),
        );
        // clear the active state
        yield put(PowerBiReportsAction.clearData());

        const powerBiReport = yield call(resourceSagas.getPowerBiReport, {
            Id: payload,
        });

        if (checkRequestsForErrors([powerBiReport])) {
            yield put(
                PowerBiReportsAction.fetchReportError({
                    message: 'There are no results for this query.',
                }),
            );
            yield put(loadingSpinnerModule.actions.setSpinnerState(false));
            return;
        }

        const report = powerBiReport.response.PowerBiReports[0];

        // If api returns a empty object
        if (isEmpty(report) || report === undefined) {
            yield put(PowerBiReportsAction.setPowerBiReportStatus('CREATE'));
            yield put(PowerBiReportsAction.previewButtonToggle('DISABLE'));
            yield put(loadingSpinnerModule.actions.setSpinnerState(false));
            yield put(
                PowerBiReportsAction.fetchCurrentEditReportSuccess({
                    id: payload,
                }),
            );
            return;
        } else {
            const requiredResponseFields = {
                reportName: report.ReportName,
                reportEmbedUrl: report.ReportEmbedUrl,
                id: report.Id,
            };
            yield put(PowerBiReportsAction.setPowerBiReportStatus('EDIT'));
            yield put(
                PowerBiReportsAction.fetchCurrentEditReportSuccess(
                    requiredResponseFields,
                ),
            );
            yield put(PowerBiReportsAction.previewButtonToggle('ENABLE'));
        }

        yield put(loadingSpinnerModule.actions.setSpinnerState(false));
    } catch (e) {
        console.error(e);
    }
}

export default function* watch() {
    try {
        yield all([
            takeLatest(
                PowerBiReportsAction.PREPARE_TO_FETCH_REPORT,
                prepareToFetchReport,
            ),
            takeLeading(
                PowerBiReportsAction.PREPARE_TO_FETCH_EDIT_REPORT,
                prepareToFetchEditReport,
            ),
            takeLatest(AuthActions.LOGIN_SUCCESS, prepareToFetchAllReports),
            takeLatest(
                PowerBiReportsAction.PREPARE_TO_FETCH_ALL_REPORTS,
                prepareToFetchAllReports,
            ),
        ]);
    } catch (e) {
        console.error(e);
    }
}
