import { action } from '../../Lib/reduxUtils';
import { GoogleAnalyticsEventBuilder } from '../../Utils/userAnalytics/googleAnalyticsEventBuilder';

const NAMESPACE = 'O4ROperator';

export const LOAD_O4ROPERATOR = `${NAMESPACE}/LOAD_O4ROPERATOR`;
export const loadO4ROperatorDataTable = (o4rOperatorDataTable) =>
    action(LOAD_O4ROPERATOR, o4rOperatorDataTable);

export const FILTER_UPDATED = `${NAMESPACE}/FILTER_UPDATED`;
export const filterUpdated = (filterName, filterValue) =>
    action(
        FILTER_UPDATED,
        { filterName, filterValue },
        {
            analytics: new GoogleAnalyticsEventBuilder(
                'SubFeatureUsage',
                'Filter Updated',
            )
                .setLabel(NAMESPACE)
                .build(),
        },
    );

export const LOAD_FILTER_OPTIONS = `${NAMESPACE}/LOAD_FILTER_OPTIONS`;
export const loadFilterOptions = (filterOptions) =>
    action(LOAD_FILTER_OPTIONS, filterOptions);

export const UPDATE_FILTERS_WITH_URL_PARAMS = `${NAMESPACE}/UPDATE_FILTERS_WITH_URL_PARAMS`;
export const updateFiltersWithUrlParams = () =>
    action(
        UPDATE_FILTERS_WITH_URL_PARAMS,
        {},
        {
            analytics: new GoogleAnalyticsEventBuilder(
                'SubFeatureUsage',
                'Filter Updated',
            )
                .setLabel(NAMESPACE)
                .build(),
        },
    );

export const SET_FILTERS_WITH_URL_PARAMS = `${NAMESPACE}/SET_FILTERS_WITH_URL_PARAMS`;
export const setFiltersWithUrlParams = (filtersFromUrl) =>
    action(SET_FILTERS_WITH_URL_PARAMS, filtersFromUrl);

export const SET_SPINNER_STATE = `${NAMESPACE}/SET_SPINNER_STATE`;
export const setSpinnerState = (isActive = false, message = '', faIcon) =>
    action(SET_SPINNER_STATE, { isActive, message, faIcon });
