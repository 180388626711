import createActions from '../../Modules/powerBi/actions';
import createReducer from '../../Modules/powerBi/reducer';
import createSelectors from '../../Modules/powerBi/selectors';

import namespace from './namespace';
import { State } from '../../Modules/powerBi/types';
/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state: any): State => state[namespace].powerBiReports;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);

export const reducer = createReducer(
    {
        clearData: actions.CLEAR_DATA,
        fetchAllReportsSuccess: actions.FETCH_ALL_REPORTS_SUCCESS,
        fetchReportSuccess: actions.FETCH_REPORT_SUCCESS,
        fetchReportError: actions.FETCH_REPORT_ERROR,
        fetchEditReportSuccess: actions.FETCH_CURRENT_EDIT_REPORT_SUCCESS,
        fetchEditReportError: actions.FETCH_CURRENT_EDIT_REPORT_ERROR,
        submitReportInputField: actions.SUBMIT_EDIT_REPORT_INPUT_FIELD,
        setEditReportInputField: actions.SET_EDIT_REPORT_INPUT_FIELD,
        setEditReportFieldError: actions.SET_EDIT_REPORT_FIELD_ERROR,
        previewTokenReceived: actions.PREVIEW_TOKEN_RECEIVED,
        showPreviewReportModal: actions.PREVIEW_MODAL_STATUS,
        previewButtonToggle: actions.PREVIEW_BUTTON_TOGGLE,
        submitCreateReportRequest: actions.SUBMIT_CREATE_REPORT_REQUEST,
        submitEditReportRequest: actions.SUBMIT_EDIT_REPORT_REQUEST,
        setPowerBiReportStatus: actions.SET_POWER_BI_REPORT_STATUS,
    },
    {},
    {},
);

export const selectors = createSelectors(statePath);
