import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Popover } from 'antd';
import Spin from '../Spin/Spin';

// Equipment Icons
import Dozer from '../../assets/js/Dozer';
import Excavator from '../../assets/js/Excavator';
import GenericVehicle from '../../assets/js/GenericVehicle';
import Grader from '../../assets/js/Grader';
import HaulTruck from '../../assets/js/HaulTruck';
import LightVehicle from '../../assets/js/LightVehicle';
import ServiceTruck from '../../assets/js/ServiceTruck';
import Shovel from '../../assets/js/Shovel';
import WaterTruck from '../../assets/js/WaterTruck';
import WheelLoader from '../../assets/js/WheelLoader';
import SoilCompactor from '../../assets/js/SoilCompactor';
import Drill from '../../assets/js/Drill';
import FloatTruck from '../../assets/js/FloatTruck';
import RopeShovel from '../../assets/js/RopeShovel';
import BellyDumper from '../../assets/js/BellyDumper';

// Status Icons
import StatusAlert from '../../assets/js/StatusAlert';
import StatusAvailable from '../../assets/js/StatusAvailable';
import StatusBrokenDown from '../../assets/js/StatusBrokenDown';
import StatusDumping from '../../assets/js/StatusDumping';
import NoWifi from '../../assets/js/NoWifi';
import StatusLoading from '../../assets/js/StatusLoading';
import StatusOff from '../../assets/js/StatusOff';
import StatusQueuing from '../../assets/js/StatusQueuing';
import StatusSpotting from '../../assets/js/StatusSpotting';
import StatusTooLongAtNode from '../../assets/js/StatusTooLongAtNode';
import StatusTooLongAtQueue from '../../assets/js/StatusTooLongAtQueue';
import StatusUnavailable from '../../assets/js/StatusUnavailable';
import StatusUnexpectedLocation from '../../assets/js/StatusUnexpectedLocation';
import StatusCapacity from './StatusCapacity';

const Equipment = {};

class Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hovered: false };
    }

    toggleHover = () => {
        this.setState((prevState) => ({ hovered: !prevState.hovered }));
    };

    render() {
        const {
            highlight,
            header,
            footer,
            pending,
            children,
            ...props
        } = this.props;
        return (
            <Spin
                spinning={pending}
                wrapperClassName={'Equipment__Card__Container'}
                title={pending ? 'Pending...' : undefined}
                {...props}
            >
                <div
                    className={cn('Equipment__Card', {
                        'Equipment__Card--highlight': highlight,
                        'Equipment__Card--hover': this.state.hovered,
                    })}
                    onMouseEnter={this.toggleHover}
                    onMouseLeave={this.toggleHover}
                >
                    <div className={'Equipment__Card__Header'}>{header}</div>
                    <div className={'Equipment__Card__Content'}>{children}</div>
                    <div className={'Equipment__Card__Footer'}>{footer}</div>
                </div>
            </Spin>
        );
    }
}
Equipment.Card = Card;

Card.defaultProps = {
    pending: false,
};

const HaulTruckWithDirection = ({ direction, ...props }) => {
    // Transform to apply to the SVG to flip the icon left/right
    let transform;
    if (direction === 'right') {
        transform = 'scale(1,1)';
    } else if (direction === 'left') {
        transform = 'scale(-1,1)';
    }

    return (
        <HaulTruck
            transform={transform}
            data-testid={'truck-icon'}
            {...props}
        />
    );
};

Equipment.Types = {
    HaulTruck: {
        component: HaulTruckWithDirection,
        tooltip: 'Haul Truck',
    },
    Dozer: {
        component: Dozer,
        tooltip: 'Dozer',
    },
    Excavator: {
        component: Excavator,
        tooltip: 'Excavator',
    },
    ServiceTruck: {
        component: ServiceTruck,
        tooltip: 'Service Truck',
    },
    GenericVehicle: {
        component: GenericVehicle,
        tooltip: 'Mobile Equipment',
    },
    Grader: {
        component: Grader,
        tooltip: 'Grader',
    },
    LightVehicle: {
        component: LightVehicle,
        tooltip: 'Light Vehicle',
    },
    Shovel: {
        component: Shovel,
        tooltip: 'Shovel',
    },
    WaterTruck: {
        component: WaterTruck,
        tooltip: 'Water Truck',
    },
    WheelLoader: {
        component: WheelLoader,
        tooltip: 'Wheel Loader',
    },
    FloatTruck: {
        component: FloatTruck,
        tooltip: 'Float Truck',
    },
    Drill: {
        component: Drill,
        tooltip: 'Drill',
    },
    SoilCompactor: {
        component: SoilCompactor,
        tooltip: 'Soil Compactor',
    },
    RopeShovel: {
        component: RopeShovel,
        tooltip: 'Rope Shovel',
    },
    BellyDumper: {
        component: BellyDumper,
        tooltip: 'Belly Dumper',
    },
};

Equipment.Icon = ({ type, ...props }) => {
    // If we have an equipment for the given type
    if (Object.values(Equipment.Types).indexOf(type) !== -1) {
        const Icon = type.component;
        const tooltip = type.tooltip;
        return (
            <div className={'Equipment__Icon'} title={tooltip}>
                <Icon style={{ height: '100%', width: '100%' }} {...props} />
            </div>
        );
    }
    return null;
};

Equipment.StatusTypes = {
    Alert: {
        component: StatusAlert,
        tooltip: 'General alert',
    },
    Available: {
        component: StatusAvailable,
        tooltip: 'Available',
    },
    BrokenDown: {
        component: StatusBrokenDown,
        tooltip: 'Broken down',
    },
    Dumping: {
        component: StatusDumping,
        tooltip: 'Dumping',
    },
    Ghosted: {
        component: NoWifi,
        tooltip: 'No signal',
    },
    HelpIAmLost: {
        component: NoWifi,
        tooltip: 'Unassigned',
    },
    Loading: {
        component: StatusLoading,
        tooltip: 'Loading',
    },
    Off: {
        component: StatusOff,
        tooltip: 'Off',
    },
    Queuing: {
        component: StatusQueuing,
        tooltip: 'Queuing',
    },
    Spotting: {
        component: StatusSpotting,
        tooltip: 'Spotting',
    },
    TooLongAtNode: {
        component: StatusTooLongAtNode,
        tooltip: 'Too long at location',
    },
    TooLongAtQueue: {
        component: StatusTooLongAtQueue,
        tooltip: 'Too long in queue',
    },
    Unavailable: {
        component: StatusUnavailable,
        tooltip: 'Unavailable',
    },
    UnexpectedLocation: {
        component: StatusUnexpectedLocation,
        tooltip: 'Unexpected location',
    },
    TooBusyInQueue: {
        component: StatusCapacity,
        tooltip: 'Exceeded queue capacity of ',
    },
    TooBusyAtNode: {
        component: StatusCapacity,
        tooltip: 'Exceeded location capacity of ',
    },
};

Equipment.StatusIcon = ({ type, ...props }) => {
    // If we have an icon for the given type
    if (Object.values(Equipment.StatusTypes).indexOf(type) !== -1) {
        const Icon = type.component;

        // Allows setting data dependant tooltips
        let tooltip = type.tooltip;
        switch (type) {
            case Equipment.StatusTypes.TooBusyAtNode:
            case Equipment.StatusTypes.TooBusyInQueue:
                tooltip += props.maxCapacity;
                break;
            default:
        }

        return (
            <div
                className={'Equipment__StatusIcon'}
                title={tooltip}
                data-testid={'status-icon'}
            >
                <Icon style={{ height: '100%', width: '100%' }} {...props} />
            </div>
        );
    }
    return null;
};

Equipment.Cluster = ({ content, title, children }) => {
    // This will be displayed in the red notification badge
    const numberOfChildren = React.Children.count(content);

    const wrappedContent = (
        <div className={'Equipment__Card__Cluster__Container'}>{content}</div>
    );

    return (
        <Popover
            title={title}
            placement={'right'}
            content={wrappedContent}
            trigger="click"
        >
            <div
                className={'Equipment__Card__Cluster'}
                data-badge={numberOfChildren}
            >
                {children}
            </div>
        </Popover>
    );
};

Card.propTypes = {
    children: PropTypes.node,
    highlight: PropTypes.bool,
    header: PropTypes.node,
    footer: PropTypes.node,
    pending: PropTypes.bool,
    badge: PropTypes.string,
};

HaulTruckWithDirection.propTypes = {
    direction: PropTypes.oneOf(['left', 'right']),
};

Equipment.Icon.propTypes = {
    type: PropTypes.oneOf(Object.values(Equipment.Types)).isRequired,
};

Equipment.StatusIcon.propTypes = {
    type: PropTypes.oneOf(Object.values(Equipment.StatusTypes)).isRequired,
    maxCapacity: PropTypes.number,
};

Equipment.Cluster.propTypes = {
    content: PropTypes.node,
    title: PropTypes.string,
    children: PropTypes.node,
};

// This fixes the issue where the components show up as "unknown" components in storybook
Equipment.Card.displayName = 'Equipment.Card';
Equipment.Icon.displayName = 'Equipment.Icon';
Equipment.StatusIcon.displayName = 'Equipment.StatusIcon';
Equipment.Cluster.displayName = 'Equipment.Cluster';

export default Equipment;
