import React from 'react';
import PropTypes from 'prop-types';
import Control from 'react-leaflet-control';
import { withLeaflet } from 'react-leaflet';
import resetButtonIcon from '../../assets/reset_button.png';

class ResetControl extends React.PureComponent {
    static propTypes = {
        // This is the bounds that the map with reset to on click. Should be a A L.latLngBounds instance
        bounds: PropTypes.shape({}),
        // Internal only, Injected by react-leaflet (withLeaflet HOC)
        leaflet: PropTypes.shape({
            map: PropTypes.shape({
                flyToBounds: PropTypes.func.isRequired,
            }),
        }),
    };

    // hacky work around to make the legend display on page load
    // https://github.com/LiveBy/react-leaflet-control/issues/27#issuecomment-430564722
    componentDidMount() {
        this.forceUpdate();
    }

    resetToBounds = () => {
        const { leaflet, bounds } = this.props;
        leaflet.map.flyToBounds(bounds);
    };

    render() {
        return (
            <Control position="topleft">
                <div className="leaflet-control-zoom leaflet-bar">
                    <a onClick={this.resetToBounds}>
                        <img src={resetButtonIcon} alt="" />
                    </a>
                </div>
            </Control>
        );
    }
}

export default withLeaflet(ResetControl);
