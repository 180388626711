import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const SET_FEATURE_ID = `${namespace}/SET_FEATURE_ID`;

    const setFeatureId = (featureId) => action(SET_FEATURE_ID, featureId);

    const UNSET_FEATURE_ID = `${namespace}/UNSET_FEATURE_ID`;

    const unsetFeatureId = (originFeatureId) =>
        action(UNSET_FEATURE_ID, originFeatureId);

    return {
        SET_FEATURE_ID,
        setFeatureId,
        UNSET_FEATURE_ID,
        unsetFeatureId,
    };
}
