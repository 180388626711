import isEmpty from 'lodash/isEmpty';
import errorLogging from '@rs/core/utils/errorLogging';
import { getNodeByType, NODE_TYPES } from './nodes';

function loadNodeToCircuitFormValues(node) {
    return {
        loadMaxDuration:
            node['maximum-time'] === null
                ? node['expected-time']
                : node['maximum-time'],
        loadMaxCapacity: node['maximum-occupants'],
        // Cast to Bool when this field isn't returned by the API
        loadEnableQueue: !!node.queue,
        loadMaxQueueDuration: node['maximum-queue-time'],
        loadMaxQueueCapacity: node['maximum-queue-length'],
    };
}

function dumpNodeToCircuitFormValues(node) {
    return {
        dumpMaxDuration:
            node['maximum-time'] === null
                ? node['expected-time']
                : node['maximum-time'],
        dumpMaxCapacity: node['maximum-occupants'],
        // Cast to Bool when this field isn't returned by the API
        dumpEnableQueue: !!node.queue,
        dumpMaxQueueDuration: node['maximum-queue-time'],
        dumpMaxQueueCapacity: node['maximum-queue-length'],
    };
}

function haulNodeToCircuitFormValues(node) {
    return {
        haulMaxDuration:
            node['maximum-time'] === null
                ? node['expected-time']
                : node['maximum-time'],
        haulMaxCapacity: node['maximum-occupants'],
    };
}

function returnNodeToCircuitFormValues(node) {
    return {
        returnMaxDuration:
            node['maximum-time'] === null
                ? node['expected-time']
                : node['maximum-time'],
        returnMaxCapacity: node['maximum-occupants'],
    };
}

export function parseMinesiteCircuitIntoFormModel(formValues, circuit) {
    if (isEmpty(circuit)) {
        const msg =
            'Attempting to parse edit circuit form into an API model failed. No node configuration was supplied';
        errorLogging.logException(msg);
        throw new Error(msg);
    }

    const loadNode = getNodeByType(NODE_TYPES.LOAD, circuit);
    const haulNode = getNodeByType(NODE_TYPES.HAUL, circuit);
    const returnNode = getNodeByType(NODE_TYPES.RETURN, circuit);
    const dumpNode = getNodeByType(NODE_TYPES.DUMP, circuit);

    return {
        ...formValues,
        id: circuit.id,
        source: loadNode.name,
        destination: dumpNode.name,
        transitMaxDuration: circuit['transit-maximum-time'] || 0,
        ...loadNodeToCircuitFormValues(loadNode),
        ...dumpNodeToCircuitFormValues(dumpNode),
        ...haulNodeToCircuitFormValues(haulNode),
        ...returnNodeToCircuitFormValues(returnNode),
    };
}
