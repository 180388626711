import createActions from '../../Modules/minesiteMap/actions';
import createReducer from '../../Modules/minesiteMap/reducer';
import createSelectors from '../../Modules/minesiteMap/selectors';
import namespace from './namespace';
import { APP_INIT_SUCCESS } from '../../App/Actions';
import { getComponentConfig } from '../../App/Selectors';
import { getEnabledBackgroundLayers } from '../../Modules/minesiteMap/utils';
import getMapExtentBounds from '../../../../core/falcon/components/MinesiteMap/utils/getMapExtentBounds';
import { SYNC_URL_TO_STATE } from '../Actions';
import * as L from 'leaflet';
import { MAP_LAYER_LABELS } from '@rs/core/falcon';
import { normaliseString } from '@rs/core/utils';

type State = MinesiteMapState;
/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state: any) => state[namespace].minesiteMap;
const mapLabelKeys = normaliseString.mapLayerLabelsToCamelCase(
    MAP_LAYER_LABELS,
);
/**
 * Create the module parts
 */
export const actions = createActions(namespace);

const initialState: State = {
    [mapLabelKeys.background]: true,
    [mapLabelKeys.backgroundClientProvided]: true,
    [mapLabelKeys.roadPath]: true,
    maxBounds: undefined,
    bounds: undefined,
    boundsAsString: undefined,
};

function appInitSuccess(state: State, payload: any) {
    const mapConfig = payload.component.MinesiteMap;
    const defaultLayers = getEnabledBackgroundLayers(mapConfig);
    const maxBounds = getMapExtentBounds(mapConfig.bounds);
    return {
        ...state,
        ...defaultLayers,
        maxBounds,
        bounds: maxBounds,
        boundsAsString: maxBounds.toBBoxString(),
    };
}

export const reducer = createReducer(
    {
        mapFilterUpdated: actions.MAP_FILTER_UPDATED,
        mapViewportChanged: actions.MAP_VIEWPORT_CHANGED,
    },
    {
        [actions.ON_BOUNDS_CHANGE]: (state: State, payload: any) => {
            const newBoundsAsString = payload.toBBoxString();

            return {
                ...state,
                bounds: payload,
                boundsAsString: newBoundsAsString,
            };
        },
        [actions.RESET_MAP_SETTINGS]: (
            state: State,
            _: any,
            rootState: State,
        ) => {
            const mapConfig = getComponentConfig(rootState, 'MinesiteMap');
            const defaultLayers = getEnabledBackgroundLayers(mapConfig);
            return {
                ...initialState,
                ...defaultLayers,
            };
        },
        [SYNC_URL_TO_STATE]: (state: State, payload: any) => {
            if (!payload.bounds) {
                // URL is not initialised yet
                return state;
            }
            const bounds = payload.bounds
                .replace(new RegExp('%2C', 'g'), ',')
                .split(',');
            if (bounds.length !== 4) return state; // the bounds string is not correct
            const LBounds = L.latLngBounds(
                L.latLng(bounds[1], bounds[0]),
                L.latLng(bounds[3], bounds[2]),
            );

            return {
                ...state,
                [mapLabelKeys.background]: Boolean(
                    payload[mapLabelKeys.background],
                ),
                [mapLabelKeys.backgroundClientProvided]: Boolean(
                    payload[mapLabelKeys.backgroundClientProvided],
                ),
                [mapLabelKeys.roadPath]: Boolean(
                    payload[mapLabelKeys.roadPath],
                ),
                bounds: LBounds,
                boundsAsString: LBounds.toBBoxString(),
            };
        },
        [APP_INIT_SUCCESS]: appInitSuccess,
    },
    initialState,
);

export const selectors = createSelectors(statePath);
