import { createSelector } from 'reselect';
import { arrayToGroupedObject } from '@rs/core/utils/dataUtils';
import { selectors as truckAlertEvents } from '../Modules/truckAlertEvents';
import { selectors as filtersSelectors } from '../Modules/filters';
import { filterRecordByFilter } from '../../../Utils/Filters/filters';
import { compareString } from '../../../Lib/sortingUtils';

export const getFilteredTruckAlertEvents = createSelector(
    truckAlertEvents.getAll,
    filtersSelectors.getFilters,
    (truckAlerts, filters) => {
        return truckAlerts.filter(
            (row) =>
                filterRecordByFilter(filters.Truck, row.TruckName) &&
                filterRecordByFilter(filters.AlertType, row.AlertType) &&
                filterRecordByFilter(
                    filters.AlertCode,
                    row.ManufacturerAlertId,
                ) &&
                filterRecordByFilter(
                    filters.AlertSeverity !== null &&
                        filters.AlertSeverity.toString(),
                    row.AlertLevel !== null && row.AlertLevel.toString(),
                ) &&
                filterRecordByFilter(
                    filters.InWorkshop.toString(),
                    row.InWorkshop.toString(),
                ),
        );
    },
);

export const getChartData = createSelector(
    getFilteredTruckAlertEvents,
    (truckAlerts) => {
        const byShiftId = arrayToGroupedObject(truckAlerts, 'ShiftId');
        const byTruckName = arrayToGroupedObject(truckAlerts, 'TruckName');

        const shiftFrequencyEventsPerTruck = Object.keys(byShiftId)
            .reduce((collection, shiftId) => {
                const results = byShiftId[shiftId];
                const eventCount = results.length;
                const trucksInShiftId = results.reduce((uniqueTrucks, row) => {
                    uniqueTrucks.add(row.TruckName);
                    return uniqueTrucks;
                }, new Set());

                const eventsByCategory = arrayToGroupedObject(
                    results,
                    'ManufacturerAlertId',
                );
                const eventCountsByCategory = Object.keys(eventsByCategory)
                    .sort((a, b) => compareString(b, a))
                    .reduce((manufacturerAlertIdCounts, key) => {
                        manufacturerAlertIdCounts.push({
                            manufacturerAlertId: key,
                            eventCount: eventsByCategory[key].length,
                        });
                        return manufacturerAlertIdCounts;
                    }, []);

                collection.push({
                    shiftId,
                    eventCount,
                    normalisedScore: eventCount / trucksInShiftId.size,
                    eventCountsByCategory,
                });
                return collection;
            }, [])
            .sort((a, b) => compareString(b.shiftId, a.shiftId));

        const alertsPerShiftData = shiftFrequencyEventsPerTruck;
        const numberOfEvents = 5;
        const miscellaneousEventsLabel = 'Miscellaneous';

        alertsPerShiftData.forEach((shift) => {
            shift.eventCountsByCategory.sort(
                (a, b) => b.eventCount - a.eventCount,
            );
            shift.eventCountsByCategory = shift.eventCountsByCategory.reduce(
                (collection, value, index) => {
                    if (index < numberOfEvents) {
                        collection.push(value);
                    } else if (index === numberOfEvents) {
                        value.manufacturerAlertId = miscellaneousEventsLabel;
                        collection.push(value);
                    } else {
                        collection[numberOfEvents].eventCount +=
                            value.eventCount;
                    }
                    return collection;
                },
                [],
            );
        });

        const truckFrequencyEventsPerShift = Object.keys(byTruckName)
            .reduce((collection, truckName) => {
                const results = byTruckName[truckName];
                const eventCount = results.length;
                const shiftIdsForTruck = results.reduce((uniqueShifts, row) => {
                    uniqueShifts.add(row.ShiftId);
                    return uniqueShifts;
                }, new Set());

                const eventsByCategory = arrayToGroupedObject(
                    results,
                    'ManufacturerAlertId',
                );
                const eventCountsByCategory = Object.keys(eventsByCategory)
                    .sort((a, b) => compareString(b, a))
                    .reduce((manufacturerAlertIdCounts, key) => {
                        manufacturerAlertIdCounts.push({
                            manufacturerAlertId: key,
                            eventCount: eventsByCategory[key].length,
                        });
                        return manufacturerAlertIdCounts;
                    }, []);

                collection.push({
                    truckName,
                    eventCount,
                    normalisedScore: eventCount / shiftIdsForTruck.size,
                    eventCountsByCategory,
                });
                return collection;
            }, [])
            .sort((a, b) => compareString(b.truckName, a.truckName));

        const alertsPerTruckData = truckFrequencyEventsPerShift;

        alertsPerTruckData.forEach((truck) => {
            truck.eventCountsByCategory.sort(
                (a, b) => b.eventCount - a.eventCount,
            );
            truck.eventCountsByCategory = truck.eventCountsByCategory.reduce(
                (collection, value, index) => {
                    if (index < numberOfEvents) {
                        collection.push(value);
                    } else if (index === numberOfEvents) {
                        value.manufacturerAlertId = miscellaneousEventsLabel;
                        collection.push(value);
                    } else {
                        collection[numberOfEvents].eventCount +=
                            value.eventCount;
                    }
                    return collection;
                },
                [],
            );
        });
        return {
            alertsPerShiftData,
            alertsPerTruckData,
        };
    },
);
