import {
    all,
    takeLatest,
    put,
    select,
    call,
    takeEvery,
} from 'redux-saga/effects';
import shiftUtils from '@rs/core/utils/shiftUtils';
import { stateSelectors, actions } from '../Productivity';
import {
    convertURLQueryStringToObject,
    updateURLQueryString,
} from '../Lib/queryStringUtils';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import * as resourceSagas from './resourceSagas';
import { API_ARRAY_DELIMITER, checkRequestsForErrors } from '../Api';
import { actions as productivityMapFeaturesActions } from '../Productivity/Modules/mapFeatures';
import { actions as mapLegendActions } from '../Productivity/Modules/mapLegends';
import { getGraphqlSdk } from './utils';
import { Sdk, GetMapFeaturesQuery } from '../Graphql/Services';
import {
    getAllUniqueSpeedZones,
    getUniqueMaterials,
    getUniqueColorScales,
    getUniqueMaterialcolorScales,
} from '../Modules/mapFeatures_v2/helper';
import { Visibility } from '@rs/core/falcon/components/NewLegend/reduxUtil';
import { MAP_LAYER_LABELS } from '@rs/core/falcon/components/MinesiteMap/constants';

export function* loadDriversAndMapData() {
    const { shiftIds } = yield select(stateSelectors.getDefinedFilters);

    // NOTE: Productivity doesn't operator on a date range so there is only 1 shift
    // Fetch data
    const results: [] = yield all([
        call(resourceSagas.getWKTAggregate, { FirstShiftId: shiftIds.last }),
        call(resourceSagas.getUniqueDrivers, { ShiftId: shiftIds.last }),
        call(resourceSagas.getShiftProductivityEvents, {
            ShiftId: shiftIds.last,
        }),
        call(resourceSagas.getMapLabels, { ShiftId: shiftIds.last }),
        call(resourceSagas.getDriverShiftProductivityEvents, {
            ShiftId: shiftIds.last,
        }),
        call(resourceSagas.getSpeedLimitedZones, { ShiftId: shiftIds.last }),
        call(
            resourceSagas.getS3Download,
            `${shiftIds.last}/mapFeatures.geo.json`,
        ),
        call(resourceSagas.getMinesiteAreasTree),
        call(resourceSagas.getConditionalLimitEvents, {
            ShiftId: shiftIds.all.join(API_ARRAY_DELIMITER),
            GearLimitExists: true,
        }),
    ]);

    const [
        wktData,
        uniqueDrivers,
        shiftProductivityEvents,
        mapLabels,
        driverShiftProductivityEvents,
        speedLimitedZones,
        mapFeatures,
        minesiteAreasTree,
        gearLockdown,
    ] = results;

    // Dispatch to reducer
    yield put(
        actions.loadDriversAndMapData({
            wktData: wktData.response,
            uniqueDrivers: uniqueDrivers.response,
            shiftProductivityEvents: shiftProductivityEvents.response,
            mapLabels: mapLabels.response,
            driverShiftProductivityEvents:
                driverShiftProductivityEvents.response,
            speedLimitedZones: speedLimitedZones.response,
            mapFeatures: mapFeatures.response,
            minesiteAreasTree: minesiteAreasTree.response,
            gearLockdown: gearLockdown.response,
        }),
    );
}

export function* updateFiltersWithURLParams() {
    const { Productivity } = yield select();
    const mapFeatures = Productivity?.MAP_FEATURES?.speedZonesGeojson?.features;

    yield put(actions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA));
    const urlFilterValues = convertURLQueryStringToObject();
    yield put(actions.setFiltersWithUrlParams(urlFilterValues));
    yield put(actions.setMinesiteMapLayers(urlFilterValues));

    yield call(loadDriversAndMapData);
    if (mapFeatures.length === 0) {
        // call the map feature generator function
        yield call(prepareToFetchMapFeatures);
    }

    const filters = yield select(stateSelectors.getFilters);
    if (filters.Event) {
        yield call(loadDriverEvents);
    }
    yield put(actions.setSpinnerState(false));
}
type Payload = {
    payload: { filterName: string; filterValue: any };
};
export function* filterUpdated({ payload }: Payload) {
    const { filterName, filterValue } = payload;
    const filters = yield select(stateSelectors.getFilters);
    // get mapLayers
    const layers = yield select(stateSelectors.getMineSiteLayers);
    const combinedlayersAndFilters = {
        ...filters,
        ...layers,
    };
    // If dates are invalid go back to waiting for FILTER_UPDATED
    if (
        !shiftUtils.isDateStringValid(
            filters.Date,
            shiftUtils.DATE_FORMAT__VALIDATE,
        )
    ) {
        return;
    }
    yield put(actions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA));

    if (
        filterName === 'Date' ||
        filterName === 'Shift' ||
        (filterName === 'Crew' && !filterValue) ||
        (filterName === 'Worker' && !filterValue)
    ) {
        yield call(prepareToFetchMapFeatures);
        yield call(loadDriversAndMapData);
    } else if (filterName === 'Event') {
        yield call(loadDriverEvents);
    }
    updateURLQueryString(combinedlayersAndFilters);
    yield put(actions.setSpinnerState(false));
}

/**
 * Fetches the data from driver event JSON files.
 */
export function* loadDriverEvents() {
    const s3EventJsonPaths: [] = yield select(
        stateSelectors.getDriverEventJsonPaths,
    );
    if (!s3EventJsonPaths.length) return;
    const results: [] = yield all(
        s3EventJsonPaths.map(({ category, files }) =>
            resourceSagas
                .getProductivityDownloadsBatch(files)
                .then((errorOrResponse) => ({
                    ...errorOrResponse,
                    category,
                })),
        ),
    );
    const hasErrors = checkRequestsForErrors(results);
    if (hasErrors) {
        yield put(
            actions.loadDriverEventJSONError(
                'Error: Failed to load event files',
            ),
        );
        return;
    }
    const jsonResults = results.map((data) => ({
        category: data.category,
        json: data.response || [],
    }));
    yield put(actions.loadDriverEventJSON(jsonResults));
}

export function* syncStateToURL() {
    // get filter state
    const filters: {} = yield select(stateSelectors.getFilters);
    // get mapLayers
    let layers: {} = yield select(stateSelectors.getMineSiteLayers);
    // combine the filters and map layers
    const combinedlayersAndFilters = {
        ...filters,
        ...layers,
    };
    // update the url
    updateURLQueryString(combinedlayersAndFilters);
}

export function* prepareToFetchMapFeatures() {
    try {
        const { shiftIds: selectedShiftIds } = yield select(
            stateSelectors.getDefinedFilters,
        );

        const { last } = selectedShiftIds;

        // get the graphql sdk
        const sdk: Sdk = getGraphqlSdk();
        const result: GetMapFeaturesQuery = yield call(sdk.GetMapFeatures, {
            input: {
                shiftIds: [last],
            },
        });

        const response = result?.getMapFeatures?.featuresByShiftId;
        //filter out the mapfeatures according to the last processed shift
        const featuresForSelectedShift = response?.filter(
            (arr) => arr?.shiftId === last,
        )[0];

        const {
            speedZonesGeojson,
            womidsGeojson,
            trackingRegionsGeojson,
            materialMovementsGeojson,
        } = featuresForSelectedShift;

        const emptyFeatureCollection = {
            type: 'FeatureCollection',
            features: [],
        };
        const parsedSpeedZonesGeojson =
            speedZonesGeojson === '' ||
            JSON.parse(speedZonesGeojson)?.features === null
                ? emptyFeatureCollection
                : JSON.parse(speedZonesGeojson);
        const parsedWomidsGeojson =
            womidsGeojson === '' || JSON.parse(womidsGeojson)?.features === null
                ? emptyFeatureCollection
                : JSON.parse(womidsGeojson);
        const parsedTrackingRegionsGeojson =
            trackingRegionsGeojson === '' ||
            JSON.parse(trackingRegionsGeojson)?.features === null
                ? emptyFeatureCollection
                : JSON.parse(trackingRegionsGeojson);
        const parsedMaterialMovementsGeojson =
            materialMovementsGeojson === '' ||
            JSON.parse(materialMovementsGeojson)?.features === null
                ? emptyFeatureCollection
                : JSON.parse(materialMovementsGeojson);

        yield all([
            put(
                productivityMapFeaturesActions.setSpeedZonesGeojson(
                    parsedSpeedZonesGeojson,
                ),
            ),
            put(
                productivityMapFeaturesActions.setWomidsGeojson(
                    parsedWomidsGeojson,
                ),
            ),
            put(
                productivityMapFeaturesActions.setTrackingRegionsGeojson(
                    parsedTrackingRegionsGeojson,
                ),
            ),
            put(
                productivityMapFeaturesActions.setMaterialMovementsGeojson(
                    parsedMaterialMovementsGeojson,
                ),
            ),
        ]);

        // get the state of the maplayers
        const {
            speedRestrictedZones,
            materialMovement,
            mineFeatures,
            mineRegions,
        } = yield select(stateSelectors.getPolygonLayers);
        // set map legend sections
        yield put(
            mapLegendActions.SetSections([
                {
                    label: MAP_LAYER_LABELS.speedLimitedZones,
                    visibility: speedRestrictedZones,
                    items: Object.entries(
                        getUniqueColorScales(
                            getAllUniqueSpeedZones(
                                parsedSpeedZonesGeojson,
                            ) as any,
                        ),
                    ).map(([key, val]) => {
                        return {
                            label: `${key}kph`,
                            borderColor: `${val}`,
                            fillColor: `${val}`,
                            visibility: 'VISIBLE',
                        };
                    }),
                },
                {
                    label: MAP_LAYER_LABELS.materialMovement,
                    visibility: materialMovement,
                    items: Object.entries(
                        getUniqueMaterialcolorScales(
                            getUniqueMaterials(
                                parsedMaterialMovementsGeojson,
                            ) as any,
                        ),
                    ).map(([key, val]) => {
                        return {
                            label: key,
                            borderColor: val,
                            fillColor: val,
                            visibility: 'VISIBLE',
                        };
                    }),
                },
                {
                    /* any polygon that has Maintenance, TyreBay or Workshop in them will be categorised as Maintenence
                   anything else is ancilary/goline
                */

                    label: MAP_LAYER_LABELS.trackingRegions,
                    visibility: mineFeatures,
                    items: [
                        {
                            label: 'Maintenance',
                            borderColor: '#45818e',
                            fillColor: '#45818e',
                            visibility: 'VISIBLE',
                        },

                        {
                            label: 'Ancillary/GoLine',
                            borderColor: '#ff9900',
                            fillColor: '#ff9900',
                            visibility: 'VISIBLE',
                        },
                    ],
                },
                {
                    label: MAP_LAYER_LABELS.mineRegions,
                    visibility: mineRegions,
                    items: [
                        {
                            label: 'Mine Regions',
                            borderColor: '#000000',
                            fillColor: '',
                            visibility: 'VISIBLE',
                        },
                    ],
                },
            ]),
        );
    } catch (error) {
        yield put(actions.setSpinnerState(false));
        throw new Error(error.message);
    }
}

type FlipLegendPayload = {
    payload: { sectionLabel: string; visibility: Visibility };
};
export function* flipLegendLayer({ payload }: FlipLegendPayload) {
    try {
        // fire action to toggle legend layer
        yield put(mapLegendActions.ChangeSectionVisibility(payload));
    } catch (error) {
        throw new Error(error.message);
    }
}

export default function* watch() {
    yield all([
        takeLatest(
            actions.UPDATE_FILTERS_WITH_URL_PARAMS,
            updateFiltersWithURLParams,
        ),
        takeLatest(actions.FILTER_UPDATED, filterUpdated),
        takeEvery(actions.MAP_VIEWPORT_CHANGED, syncStateToURL),
        takeLatest(actions.MAP_LAYER_UPDATED, filterUpdated),
        takeLatest(actions.MAP_LEGEND_LAYER_FLIP, flipLegendLayer),
    ]);
}
