import errorLogging from '@rs/core/utils/errorLogging';
import { getAverageOfArray } from '@rs/core/utils/mathHelpers';

/**
 * Returns which x/y keys should be used for that jsonKey
 * @param jsonKey {string} - a key from the SurfaceRoughness.json file
 * @return {xKey: string, yKey: string}
 */

export const getXYKeyFromJsonKey = (jsonKey) => {
    const lfOrRf = jsonKey.includes('_LF_') ? 'LF' : 'RF';
    return {
        xKey: `longitude${lfOrRf}`,
        yKey: `latitude${lfOrRf}`,
    };
};

/**
 * Group the pathsegments into the correct bins. Each WhereOnMinesiteId pathSegment can have different Bin thresholds
 * as well as Loaded / Unloaded also having seperate Bin thresholds.
 * This also calculates the average of the left (LF) & right wheels (RF) as a single value
 *
 * @param {Array} loadedOrUnloadedKeys - List of the keys to evaluate, 'loaded' or 'unloaded'
 * @param {Function} loadedBinScale- a d3 scale or similar. Should have a .range fn which returns all the possible bins
 * @param {Function} unLoadedBinScale- a d3 scale or similar. Should have a .range fn which returns all the possible bins
 * @param pathSegments {array} - the SurfaceRoughness.json results
 * @return {[]Object}
 */
export function binPathSegments(
    loadedOrUnloadedKeys,
    loadedBinScale,
    unLoadedBinScale,
    pathSegments = [],
) {
    // Note: the d3 scale used is expecting range to output all the possible bins
    const allBins = loadedBinScale.range();
    if (loadedBinScale.range().length !== unLoadedBinScale.range().length) {
        errorLogging.logException(
            new Error(
                'The loadedBinScale & unloadedBinScale range should be the same',
            ),
        );
    }

    const binnedPathSegmentsNullPadded = pathSegments.map((d) => {
        const nullFilledArray = loadedOrUnloadedKeys.reduce(
            (collection, jsonKey) => {
                // Initialise empty object for each jsonKey
                collection[jsonKey] = {};
                // Prefill an array with null values for each of the bins in the scale
                // This prevents accidently connecting two points together that aren't part of the same path
                const lfKey = createJsonKeyString('LF', jsonKey);
                const rfKey = createJsonKeyString('RF', jsonKey);
                const length = Math.max(d[lfKey].length, d[rfKey].length);

                allBins.forEach((bin) => {
                    collection[jsonKey][bin] = Array(length).fill(null);
                });

                return collection;
            },
            {},
        );

        // For whichever bin the value fits in, replace the null value with the real value
        loadedOrUnloadedKeys.forEach((loadedOrUnloaded) => {
            const lfKey = createJsonKeyString('LF', loadedOrUnloaded);
            const rfKey = createJsonKeyString('RF', loadedOrUnloaded);
            Object.keys(nullFilledArray[loadedOrUnloaded]).forEach((bin) => {
                nullFilledArray[loadedOrUnloaded][bin].forEach(
                    (val, nullFilledArrayIndex) => {
                        const lfVal = d[lfKey][nullFilledArrayIndex];
                        const rfVal = d[rfKey][nullFilledArrayIndex];

                        const values = [lfVal, rfVal].filter((value) => {
                            return value !== null && !Number.isNaN(value);
                        });

                        // Avoid applying the scale to a null/NaN value
                        // so it doesn't get categorised
                        if (!values.length) {
                            return;
                        }

                        // Apply the correct binScale to the value
                        const binScale =
                            loadedOrUnloaded === 'loaded'
                                ? loadedBinScale
                                : unLoadedBinScale;

                        const avgLFRF = getAverageOfArray(
                            values,
                            values.length,
                        );

                        const binnedValue = binScale(avgLFRF);
                        nullFilledArray[loadedOrUnloaded][binnedValue][
                            nullFilledArrayIndex
                        ] = {
                            avgLFRF,
                            bin: binnedValue,
                            x: d.longitudeLF[nullFilledArrayIndex],
                            y: d.latitudeLF[nullFilledArrayIndex],
                        };
                    },
                );
            });
        });
        return nullFilledArray;
    });

    return binnedPathSegmentsNullPadded;
}

/**
 * Combines data into a single array per key, (eg. 'loaded' or 'unloaded') seperated by a null
 * The purpose is to avoid rendering many lines with Svg & instead render fewer large lines
 * eg. turn this
 * {
 *  Pit4: [{
 *      loaded: [{
 *          <bin>: [values]
 *      }]
 *  }]
 * }
 * into
 * {
 *      loaded: {
 *          <bin> [pathSegment, null, pathSegment]
 *      }
 * }
 * @param {object} pathSegmentsByWhereOnMinesiteId
 * @return {object}
 */
export function combinePathSegmentsByClassification(
    pathSegmentsByWhereOnMinesiteId,
) {
    return Object.keys(pathSegmentsByWhereOnMinesiteId).reduce(
        (collection, womId) => {
            const pathSegments = pathSegmentsByWhereOnMinesiteId[womId];
            pathSegments.forEach((pathSegment) => {
                Object.keys(pathSegment).forEach((loadedOrUnloaded) => {
                    Object.keys(pathSegment[loadedOrUnloaded]).forEach(
                        (classification) => {
                            collection[loadedOrUnloaded] =
                                collection[loadedOrUnloaded] || {};
                            collection[loadedOrUnloaded][classification] =
                                collection[loadedOrUnloaded][classification] ||
                                [];
                            // Remove any arrays with only null values
                            if (
                                pathSegment[loadedOrUnloaded][
                                    classification
                                ].every((d) => d === null)
                            ) {
                                return;
                            }
                            // Merge all the paths in the same jsonKey / bin into a single array
                            collection[loadedOrUnloaded][classification] = [
                                ...collection[loadedOrUnloaded][classification],
                                // This prevents accidently connecting two points together that aren't part of the same path
                                null,
                                ...pathSegment[loadedOrUnloaded][
                                    classification
                                ],
                            ];
                        },
                    );
                });
            });
            return collection;
        },
        {},
    );
}

/**
 * Formats the pathSegments into something that is easier to render with in the view
 * @param {object} - pathSegmentsByJsonKey
 * @return {array}
 */
export function formatPathSegmentsForMapDisplay(pathSegmentsByJsonKey) {
    /**
     * Format nicer for view to render
     * @returns [
     *  {
     *      bin: Number,
     *      path: array,
     *      grouping: string,
     *      x: string,
     *      y: string,
     *  }
     * ]
     */
    return Object.keys(pathSegmentsByJsonKey).reduce(
        (collection, loadedOrUnloaded) => {
            Object.entries(pathSegmentsByJsonKey[loadedOrUnloaded]).forEach(
                ([bin, path]) => {
                    const lat_longs = path.reduce(
                        (paths, row) => {
                            if (!row) {
                                if (!paths.curr.length) {
                                    return paths;
                                }
                                paths.all.push(paths.curr);
                                paths.curr = [];
                                return paths;
                            }
                            paths.curr.push([row.y, row.x]);
                            return paths;
                        },
                        {
                            curr: [],
                            all: [],
                        },
                    );
                    if (lat_longs.curr.length > 0) {
                        lat_longs.all.push(lat_longs.curr);
                    }
                    collection.push({
                        bin: parseInt(bin, 10),
                        path,
                        lat_longs: lat_longs.all,
                        grouping: loadedOrUnloaded,
                    });
                },
            );
            return collection;
        },
        [],
    );
}

export const createJsonKeyString = (lfOrRF, loadedOrUnloaded) =>
    `Mean_${lfOrRF}_Force_rms_${loadedOrUnloaded}`;
