module.exports = {
    development: {
        GA_MEASUREMENT_ID: 'G-GPCNGE9RH1',
    },
    staging: {
        GA_MEASUREMENT_ID: '',
    },
    production: {
        GA_MEASUREMENT_ID: 'G-FCR9KLNN26',
    },
};
