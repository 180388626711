import { combineReducers } from 'redux';

import { reducer as spinner } from '../Modules/spinner';
import { reducer as equipmentLatency } from '../Modules/equipmentLatency';
import { reducer as equipmentLatencyHistoric } from '../Modules/equipmentLatencyHistoric';
import namespace from '../Modules/namespace';

export const REDUCER_KEY = namespace;
export default combineReducers({
    equipmentLatency,
    equipmentLatencyHistoric,
    spinner,
});
