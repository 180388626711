import { createSelector } from 'reselect';
import {
    arrayToFriendlyString,
    stringDelimetedToArray,
} from '@rs/core/utils/dataUtils';
import { selectors as filtersSelectors } from '../Modules/filters';

// TODO This is currently only used for the PDF possibly to be deleted when PDF is next reviewed
const getMapLabel = createSelector(filtersSelectors.getFilters, (filters) => {
    const SEPERATOR = '; ';
    const DRIVERS_PREFIX = 'Drivers -';
    const CUSTOM_FILTER = 'Custom Filter';
    const ALL_DRIVERS = 'All Drivers';

    const driverFilter = filters.Worker;
    const driverFilterAsArray = stringDelimetedToArray(driverFilter);
    const crewFilter = filters.Crew;
    const crewFilterAsArray = stringDelimetedToArray(crewFilter);
    const driversAndCrews = [...driverFilterAsArray, ...crewFilterAsArray];
    const shiftLabelOptions = {
        D: 'Day',
        N: 'Night',
    };

    const shiftFilter = filters.Shift;
    const shiftFilterAsArray = stringDelimetedToArray(shiftFilter);
    const shiftLabel =
        shiftFilterAsArray.length === 1
            ? shiftLabelOptions[shiftFilterAsArray[0]]
            : 'All Shifts';

    const endDateFilter = filters.EndDate;
    const dateFilter = filters.Date;
    const dateLabel = endDateFilter
        ? `${dateFilter} - ${endDateFilter}`
        : dateFilter;
    const labels = {
        date: dateLabel,
        shift: shiftLabel,
        driversAndCrew: '',
    };

    const singleDriverSelected = false;
    if (singleDriverSelected) {
        // Selected individual driver
        labels.driversAndCrew = `${DRIVERS_PREFIX} ${singleDriverSelected.Driver}`;
    } else if (driversAndCrews.length === 0) {
        // No Filters
        labels.driversAndCrew = ALL_DRIVERS;
    } else if (driversAndCrews.length > 5) {
        // Too many filters selected
        labels.driversAndCrew = `${DRIVERS_PREFIX} ${CUSTOM_FILTER}`;
    } else {
        // Combination of crews & drivers
        const crewString = arrayToFriendlyString(crewFilterAsArray);
        const driverString = arrayToFriendlyString(driverFilterAsArray);
        const maybeSeperator =
            crewString.length === 0 || driverString.length === 0
                ? ''
                : SEPERATOR;
        labels.driversAndCrew = `${DRIVERS_PREFIX} ${crewString}${maybeSeperator}${driverString}`;
    }

    return labels;
});

export default getMapLabel;
