import { action } from '../../Lib/reduxUtils';
import {
    FetchMapDataErrorPayload,
    FetchMapDataSuccessPayload,
    H3ResolutionIndex,
    H3ResolutionIndexChangedPayload,
    SyncUrlToStatePayload,
} from './types';

export default function createActions(ns: string) {
    const PREPARE_TO_FETCH_DATA = `${ns}/PREPARE_TO_FETCH_DATA`;
    const prepareToFetchData = () => action(PREPARE_TO_FETCH_DATA);

    const SYNC_URL_TO_STATE = `${ns}/SYNC_URL_TO_STATE`;
    const syncURLToState = (payload: SyncUrlToStatePayload) => {
        return action(SYNC_URL_TO_STATE, payload);
    };

    const FETCH_MAP_DATA_SUCCESS = `${ns}/FETCH_MAP_DATA_SUCCESS`;
    const fetchMapDataSuccess = (results: FetchMapDataSuccessPayload) =>
        action(FETCH_MAP_DATA_SUCCESS, results);

    const FETCH_MAP_DATA_ERROR = `${ns}/FETCH_MAP_DATA_ERROR`;
    const fetchMapDataError = (error: FetchMapDataErrorPayload) =>
        action(FETCH_MAP_DATA_ERROR, error);

    const PRE_FETCH_MAP_DATA_OF_INDEX = `${ns}/PRE_FETCH_MAP_DATA_OF_INDEX`;
    const preFetchMapDataOfIndex = (payload: { index: H3ResolutionIndex }) =>
        action(PRE_FETCH_MAP_DATA_OF_INDEX, payload);

    const H3_RESOLUTION_INDEX_CHANGED = `${ns}/H3_RESOLUTION_INDEX_CHANGED`;
    const h3ResolutionIndexChanged = (
        payload: H3ResolutionIndexChangedPayload,
    ) => action(H3_RESOLUTION_INDEX_CHANGED, payload);

    const H3_RESOLUTION_BEST_INDEX_CHANGED = `${ns}/H3_RESOLUTION_BEST_INDEX_CHANGED`;
    const h3ResolutionBestIndexChanged = (
        payload: H3ResolutionIndexChangedPayload,
    ) => action(H3_RESOLUTION_BEST_INDEX_CHANGED, payload);

    const CLEAR_DATA = `${ns}/CLEAR_DATA`;
    const clearData = () => action(CLEAR_DATA);

    return {
        FETCH_MAP_DATA_SUCCESS,
        fetchMapDataSuccess,
        SYNC_URL_TO_STATE,
        syncURLToState,
        FETCH_MAP_DATA_ERROR,
        fetchMapDataError,
        H3_RESOLUTION_INDEX_CHANGED,
        h3ResolutionIndexChanged,
        H3_RESOLUTION_BEST_INDEX_CHANGED,
        h3ResolutionBestIndexChanged,
        PREPARE_TO_FETCH_DATA,
        prepareToFetchData,
        PRE_FETCH_MAP_DATA_OF_INDEX,
        preFetchMapDataOfIndex,
        CLEAR_DATA,
        clearData,
    };
}
