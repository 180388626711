import { arrayToGroupedObject } from '@rs/core/utils/dataUtils';
import { createReducer } from '../../Lib/reduxUtils';
import { createSortByPropertyFn } from '../../Lib/sortingUtils';
import { filterUniqueItems } from '../../Utils/Filters/filters';

export const initialState = {
    byShiftId: {},
    shiftIdAscending: [],
};

export const onSuccess = (state, haulRoadConditionSummary) => {
    const shiftIdSort = createSortByPropertyFn('ShiftId');
    const shiftIdAscending = haulRoadConditionSummary
        .slice()
        .sort(shiftIdSort)
        .map((classification) => classification.ShiftId)
        .filter(filterUniqueItems);
    return {
        ...state,
        byShiftId: arrayToGroupedObject(haulRoadConditionSummary, 'ShiftId'),
        shiftIdAscending,
    };
};

export const onError = () => initialState;

/**
 * Creates a reducer for this module. It has some default behaviour but also allows custom actions to be supplied
 * @param {object} actions {<internalActionName>: <ACTION_TYPE>} maps an internalActionName to an ACTION_TYPE. The handler for this ACTION_TYPE is the default behaviour
 * @param {object} customActions {<ACTION_TYPE>: actionHandler} maps an ACTION_TYPE to an action handler
 * @return {function(*=, {type: *, payload?: *})}
 */
export default function createReducers(actions, customActions = {}) {
    return createReducer(initialState, {
        // Not in use right now
        // [actions.onRequest]: onRequest,
        [actions.onSuccess]: onSuccess,
        [actions.onError]: onError,
        /**
         * Ensure any custom action handlers pass along state + any additional arguments needed for the
         * action handlers
         */
        ...customActions,
    });
}
