import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    typeConformanceWeighting,
    typeCornerLeagueRow,
    typeCornerLeagueTotal,
    typeGearLockdown,
} from '@rs/core/types';
import { ConformanceLegend, GearLockdown } from '@rs/core/components';
import secondsToFormattedString from '@rs/core/utils/secondsToFormattedString';
import { Row, Col } from '@rs/core/falcon';
import { ErrorMessage } from '../../Components/ErrorMessage';
import { createMultiKeySortFn } from '../../Lib/sortingUtils';
import { FEATURE_IDS } from '../../Auth/featureIds';
import { getComponentConfig, isFeatureEnabled } from '../../App/Selectors';
import LeagueTableHeaderCell from './LeagueTableHeaderCell';
import LeagueTableDriverCell from './LeagueTableDriverCell';
import LeagueTableNonConformanceCell from './LeagueTableNonConformanceCell';
import LeagueTableConformanceCell from './LeagueTableConformanceCell';
import { SECONDS_FORMAT_STRING } from './LeagueTablePage';
import { stateSelectors } from '../Reducers';
import { HasFeature } from '../../Components/HasFeature';
import withComponentConfig from '../../HOC/withComponentConfig';

const ConformanceLegendWithConfig = withComponentConfig(
    'ConformanceDefault',
    ConformanceLegend,
);

class LeagueTableTable extends Component {
    constructor(props) {
        super(props);
        this.renderErrorMessage = this.renderErrorMessage.bind(this);
    }

    renderErrorMessage() {
        const { errorMessage } = this.props;
        return (
            <tr>
                <ErrorMessage enclosingComponent="td">
                    {errorMessage}
                </ErrorMessage>
            </tr>
        );
    }

    render() {
        const {
            predicate,
            currentOrder,
            onColumnHeaderClick,
            isFeatureToggleEnabled,
            filteredCornerLeague,
            onTableLinkClick,
            isProductivityLinkDisabled,
            filteredCornerLeagueTotals,
            errorMessage,
            gearLockdownShifts,
            cornerLeagueWeightings,
            tableOverrides,
        } = this.props;

        const columns = [
            {
                key: 'Driver',
                className: 'LeagueTable--NonConformanceCell',
                label: 'Name',
                Header: LeagueTableHeaderCell,
                Cell: LeagueTableDriverCell,
                Footer: ({ index, column }) => (
                    <td
                        key={`${index}-${column.key}`}
                        className="LeagueTable--NonConformanceCell"
                    >
                        Overall
                    </td>
                ),
                customProps: {
                    onTableLinkClick,
                    isProductivityLinkDisabled,
                    onColumnHeaderClick,
                },
            },
            {
                key: 'Crew',
                className:
                    'Table__Cell--Number LeagueTable--NonConformanceCell',
                label: 'Crew',
                hasCellTooltip: true,
                Header: LeagueTableHeaderCell,
                Cell: LeagueTableNonConformanceCell,
                Footer: ({ index, column }) => (
                    <td
                        key={`${index}-${column.key}`}
                        className="LeagueTable--NonConformanceCell"
                    />
                ),
            },
            {
                key: 'TotalCorners',
                className:
                    'Table__Cell--Number LeagueTable--NonConformanceCell',
                label: 'Total Corners',
                Header: LeagueTableHeaderCell,
                Cell: LeagueTableNonConformanceCell,
                Footer: LeagueTableNonConformanceCell,
                featureId: FEATURE_IDS.LEAGUE__TABLE_SHOW_CORNER_COUNT,
            },
            {
                key: 'TotalRegionEvents',
                className:
                    'Table__Cell--Number LeagueTable--NonConformanceCell',
                label: 'Speed Zone Passes',
                Header: LeagueTableHeaderCell,
                Cell: LeagueTableNonConformanceCell,
                Footer: LeagueTableNonConformanceCell,
                featureId: FEATURE_IDS.LEAGUE__TABLE_SHOW_TOTAL_ZONES_COLUMN,
            },
            {
                key: 'TotalRampEvents',
                className:
                    'Table__Cell--Number LeagueTable--NonConformanceCell',
                label: 'Total Ramp Passes',
                Header: LeagueTableHeaderCell,
                Cell: LeagueTableNonConformanceCell,
                Footer: LeagueTableNonConformanceCell,
                featureId: FEATURE_IDS.LEAGUE__TABLE_SHOW_TOTAL_RAMPS_COLUMN,
            },
            {
                key: 'DrivingDuration',
                className:
                    'Table__Cell--Number LeagueTable--NonConformanceCell',
                label: `Driving Duration (${SECONDS_FORMAT_STRING})`,
                Header: LeagueTableHeaderCell,
                Cell: LeagueTableNonConformanceCell,
                Footer: LeagueTableNonConformanceCell,
                featureId:
                    FEATURE_IDS.LEAGUE__TABLE_SHOW_DRIVING_DURATION_COLUMN,
                customProps: {
                    valueFormatter: (value) =>
                        secondsToFormattedString(value, SECONDS_FORMAT_STRING),
                },
            },
            {
                key: 'OverspeedConformance',
                className: 'Table__Cell--Number LeagueTable--ConformanceCell',
                label: 'Corner',
                weighting: cornerLeagueWeightings.CornerWeighting,
                Header: LeagueTableHeaderCell,
                Cell: LeagueTableConformanceCell,
                Footer: LeagueTableConformanceCell,
                featureId:
                    FEATURE_IDS.LEAGUE__TABLE_SHOW_CORNER_CONFORMANCE_COLUMN,
            },
            {
                key: 'StraightConformance',
                className: 'Table__Cell--Number LeagueTable--ConformanceCell',
                label: 'Zone',
                weighting: cornerLeagueWeightings.ZoneWeighting,
                Header: LeagueTableHeaderCell,
                Cell: LeagueTableConformanceCell,
                Footer: LeagueTableConformanceCell,
            },
            {
                key: 'RampConformance',
                className: 'Table__Cell--Number LeagueTable--ConformanceCell',
                label: 'Ramp',
                weighting: cornerLeagueWeightings.RampWeighting,
                Header: LeagueTableHeaderCell,
                Cell: LeagueTableConformanceCell,
                Footer: LeagueTableConformanceCell,
            },
            {
                key: 'SpotConformance',
                className: 'Table__Cell--Number LeagueTable--ConformanceCell',
                label: 'Spot',
                weighting: cornerLeagueWeightings.SpottingWeighting,
                Header: LeagueTableHeaderCell,
                Cell: LeagueTableConformanceCell,
                Footer: LeagueTableConformanceCell,
                featureId: FEATURE_IDS.LEAGUE__TABLE_SHOW_SPOT_COLUMN,
            },
            {
                key: 'DumpConformance',
                className: 'Table__Cell--Number LeagueTable--ConformanceCell',
                label: 'Dump',
                weighting: cornerLeagueWeightings.DumpWeighting,
                Header: LeagueTableHeaderCell,
                Cell: LeagueTableConformanceCell,
                Footer: LeagueTableConformanceCell,
                featureId: FEATURE_IDS.LEAGUE__TABLE_SHOW_DUMP_COLUMN,
            },
            {
                key: 'ProductivityConformance',
                className: 'Table__Cell--Number LeagueTable--ConformanceCell',
                label: 'OPR Effic.',
                tooltip: 'Operator Efficiency',
                weighting: cornerLeagueWeightings.ProductivityWeighting,
                Header: LeagueTableHeaderCell,
                Cell: LeagueTableConformanceCell,
                Footer: LeagueTableConformanceCell,
                featureId: FEATURE_IDS.LEAGUE__TABLE_SHOW_PRODUCTIVITY_COLUMN,
            },
            {
                key: 'TotalConformance',
                className: 'Table__Cell--Number LeagueTable--ConformanceCell',
                label: 'Overall',
                Header: LeagueTableHeaderCell,
                Cell: LeagueTableConformanceCell,
                Footer: LeagueTableConformanceCell,
            },
        ]
            .filter((column) => {
                if (column.featureId) {
                    return isFeatureToggleEnabled[column.featureId];
                }
                return true;
            })
            .map((column) => {
                const overrides =
                    tableOverrides &&
                    tableOverrides.columns &&
                    tableOverrides.columns[column.key];
                if (!overrides) {
                    return column;
                }
                return {
                    ...column,
                    ...overrides,
                };
            });

        const numberOfConformanceColumns = columns.filter((column) =>
            column.className.includes('LeagueTable--ConformanceCell'),
        ).length;
        const numberOfNonConformanceColumns = columns.filter((column) =>
            column.className.includes('LeagueTable--NonConformanceCell'),
        ).length;
        const conformanceColumnCountClassName = `LeagueTable--ConformanceColumnCountIs${numberOfConformanceColumns}`;
        const nonConformanceColumnCountClassName = `LeagueTable--NonConformanceColumnCountIs${numberOfNonConformanceColumns}`;
        const tableClassName = `Table--Default Table--Scroll LeagueTable ${conformanceColumnCountClassName} ${nonConformanceColumnCountClassName} Printing--Hide`;

        const sortFn = createMultiKeySortFn([
            { name: currentOrder, reverse: predicate },
            { name: 'Driver', reverse: false },
        ]);

        const sortedFilteredCornerLeague = filteredCornerLeague
            .slice()
            .sort(sortFn);

        return (
            <Fragment>
                <table
                    className="LeagueTablePrint Printing--Show"
                    style={{ width: '90%' }}
                >
                    <thead className="LeagueTablePrint__Thead">
                        <tr>
                            {columns.map((column, index) =>
                                column.Header({
                                    column,
                                    index,
                                    predicate,
                                    currentOrder,
                                    onColumnHeaderClick,
                                }),
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedFilteredCornerLeague.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {columns.map((column) =>
                                    column.Cell({
                                        column,
                                        index: rowIndex,
                                        value: row[column.key],
                                        isForPrinting: true,
                                    }),
                                )}
                            </tr>
                        ))}
                    </tbody>
                    <tbody>
                        <tr className="LeagueTablePrint__Tfoot__Tr">
                            {columns.map((column, index) =>
                                column.Footer({
                                    column,
                                    index,
                                    value:
                                        filteredCornerLeagueTotals[column.key],
                                    isForPrinting: true,
                                }),
                            )}
                        </tr>
                    </tbody>
                </table>
                <table role="grid" className={tableClassName}>
                    <thead>
                        <tr>
                            <th
                                className="LeagueTable--NonConformanceCell"
                                aria-label={'Empty Cell'}
                            />
                            <th className="LeagueTable--ConformanceCell">
                                Conformance %
                            </th>
                        </tr>
                        <tr>
                            {columns.map((column, index) =>
                                column.Header({
                                    column,
                                    index,
                                    predicate,
                                    currentOrder,
                                    onColumnHeaderClick,
                                }),
                            )}
                        </tr>
                    </thead>
                    <tbody className="tbody">
                        {errorMessage
                            ? this.renderErrorMessage()
                            : sortedFilteredCornerLeague.map(
                                  (row, rowIndex) => (
                                      <tr key={rowIndex}>
                                          {columns.map((column) =>
                                              column.Cell({
                                                  column,
                                                  index: rowIndex,
                                                  value: row[column.key],
                                                  hasCellTooltip:
                                                      column.hasCellTooltip,
                                              }),
                                          )}
                                      </tr>
                                  ),
                              )}
                    </tbody>
                    <tfoot>
                        <tr style={{ fontWeight: 700 }}>
                            {columns.map((column, index) =>
                                column.Footer({
                                    column,
                                    index,
                                    value:
                                        filteredCornerLeagueTotals[column.key],
                                }),
                            )}
                        </tr>
                    </tfoot>
                </table>
                <Row className="Printing--hide">
                    <Col xl={12}>
                        <ConformanceLegendWithConfig />
                    </Col>
                    <Col xl={12}>
                        <HasFeature
                            featureId={
                                FEATURE_IDS.LEAGUE__TABLE__SHOW_GEAR_LOCKDOWN
                            }
                        >
                            <GearLockdown shifts={gearLockdownShifts} />
                        </HasFeature>
                    </Col>
                </Row>
                <div className="Printing--Show">
                    <div style={{ width: '90%' }}>
                        <ConformanceLegendWithConfig />
                    </div>
                    <div style={{ width: '90%' }}>
                        <HasFeature
                            featureId={
                                FEATURE_IDS.LEAGUE__TABLE__SHOW_GEAR_LOCKDOWN
                            }
                        >
                            <GearLockdown shifts={gearLockdownShifts} />
                        </HasFeature>
                    </div>
                </div>
            </Fragment>
        );
    }
}

LeagueTableTable.propTypes = {
    onColumnHeaderClick: PropTypes.func,
    filteredCornerLeagueTotals: typeCornerLeagueTotal,
    filteredCornerLeague: PropTypes.arrayOf(typeCornerLeagueRow),
    currentOrder: PropTypes.string,
    predicate: PropTypes.bool,
    errorMessage: PropTypes.string,
    onTableLinkClick: PropTypes.func.isRequired,
    isProductivityLinkDisabled: PropTypes.bool.isRequired,
    isFeatureToggleEnabled: PropTypes.shape({}).isRequired,
    gearLockdownShifts: PropTypes.arrayOf(typeGearLockdown),
    cornerLeagueWeightings: typeConformanceWeighting,
    tableOverrides: PropTypes.shape({
        columns: PropTypes.shape({}),
    }),
};

const mapStateToProps = (state) => ({
    isFeatureToggleEnabled: {
        [FEATURE_IDS.LEAGUE__TABLE_SHOW_CORNER_COUNT]: isFeatureEnabled(
            state,
            FEATURE_IDS.LEAGUE__TABLE_SHOW_CORNER_COUNT,
        ),
        [FEATURE_IDS.LEAGUE__TABLE_SHOW_TOTAL_ZONES_COLUMN]: isFeatureEnabled(
            state,
            FEATURE_IDS.LEAGUE__TABLE_SHOW_TOTAL_ZONES_COLUMN,
        ),
        [FEATURE_IDS.LEAGUE__TABLE_SHOW_TOTAL_RAMPS_COLUMN]: isFeatureEnabled(
            state,
            FEATURE_IDS.LEAGUE__TABLE_SHOW_TOTAL_RAMPS_COLUMN,
        ),
        [FEATURE_IDS.LEAGUE__TABLE_SHOW_DRIVING_DURATION_COLUMN]: isFeatureEnabled(
            state,
            FEATURE_IDS.LEAGUE__TABLE_SHOW_DRIVING_DURATION_COLUMN,
        ),
        [FEATURE_IDS.LEAGUE__TABLE_SHOW_CORNER_CONFORMANCE_COLUMN]: isFeatureEnabled(
            state,
            FEATURE_IDS.LEAGUE__TABLE_SHOW_CORNER_CONFORMANCE_COLUMN,
        ),
        [FEATURE_IDS.LEAGUE__TABLE_SHOW_SPOT_COLUMN]: isFeatureEnabled(
            state,
            FEATURE_IDS.LEAGUE__TABLE_SHOW_SPOT_COLUMN,
        ),
        [FEATURE_IDS.LEAGUE__TABLE_SHOW_DUMP_COLUMN]: isFeatureEnabled(
            state,
            FEATURE_IDS.LEAGUE__TABLE_SHOW_DUMP_COLUMN,
        ),
        [FEATURE_IDS.LEAGUE__TABLE_SHOW_PRODUCTIVITY_COLUMN]: isFeatureEnabled(
            state,
            FEATURE_IDS.LEAGUE__TABLE_SHOW_PRODUCTIVITY_COLUMN,
        ),
    },
    gearLockdownShifts: stateSelectors.getGearLockdown(state),
    tableOverrides: getComponentConfig(state, 'HaulTruckLeagueTable'),
});

export default connect(mapStateToProps)(LeagueTableTable);
