import { action } from '../../Lib/reduxUtils';
import { GoogleAnalyticsEventBuilder } from '../../Utils/userAnalytics/googleAnalyticsEventBuilder';

const NAMESPACE = 'HaulRoadCondition';

export const FILTER_UPDATED = `${NAMESPACE}/FILTER_UPDATED`;
export const filterUpdated = (filterName, filterValue) =>
    action(
        FILTER_UPDATED,
        { filterName, filterValue },
        {
            analytics: new GoogleAnalyticsEventBuilder(
                'SubFeatureUsage',
                'Filter Updated',
            )
                .setLabel(NAMESPACE)
                .build(),
        },
    );

export const PLOT_DISPLAY_OPTION_UPDATED = `${NAMESPACE}/PLOT_DISPLAY_OPTION_UPDATED`;
export const plotDisplayOptionUpdated = (
    displayGroup,
    displayOptionName,
    value,
) =>
    action(PLOT_DISPLAY_OPTION_UPDATED, {
        displayGroup,
        displayOptionName,
        value,
    });

export const FETCH_MAP_DATA_SUCCESS = `${NAMESPACE}/FETCH_MAP_DATA_SUCCESS`;
export const fetchMapDataSuccess = ({
    wktData,
    mapLabels,
    haulRoadConditionSummary,
    mapFeatures,
    latestHaulRoadConditionSummary,
}) =>
    action(FETCH_MAP_DATA_SUCCESS, {
        wktData,
        mapLabels,
        haulRoadConditionSummary,
        mapFeatures,
        latestHaulRoadConditionSummary,
    });

export const FETCH_MAP_DATA_ERROR = `${NAMESPACE}/FETCH_MAP_DATA_ERROR`;
export const fetchMapDataError = ({ error }) =>
    action(FETCH_MAP_DATA_ERROR, { error });

export const FETCH_SURFACE_ROUGHNESS_SUCCESS = `${NAMESPACE}/FETCH_SURFACE_ROUGHNESS_SUCCESS`;
export const fetchSurfaceRoughnessSuccess = (arrayOfSurfaceRoughness) =>
    action(FETCH_SURFACE_ROUGHNESS_SUCCESS, arrayOfSurfaceRoughness);

export const FETCH_SURFACE_ROUGHNESS_ERROR = `${NAMESPACE}/FETCH_SURFACE_ROUGHNESS_ERROR`;
export const fetchSurfaceRoughnessError = ({ error }) =>
    action(FETCH_SURFACE_ROUGHNESS_ERROR, { error });

export const UPDATE_FILTERS_WITH_URL_PARAMS = `${NAMESPACE}/UPDATE_FILTERS_WITH_URL_PARAMS`;
export const updateFiltersWithUrlParams = () =>
    action(
        UPDATE_FILTERS_WITH_URL_PARAMS,
        {},
        {
            analytics: new GoogleAnalyticsEventBuilder(
                'SubFeatureUsage',
                'Filter Updated',
            )
                .setLabel(NAMESPACE)
                .build(),
        },
    );

export const SET_FILTERS_WITH_URL_PARAMS = `${NAMESPACE}/SET_FILTERS_WITH_URL_PARAMS`;
export const setFiltersWithUrlParams = (filtersFromUrl) =>
    action(SET_FILTERS_WITH_URL_PARAMS, filtersFromUrl);

export const SET_SPINNER_STATE = `${NAMESPACE}/SET_SPINNER_STATE`;
export const setSpinnerState = (isActive = false, message = '', faIcon) =>
    action(SET_SPINNER_STATE, { isActive, message, faIcon });

export const SYNC_URL_TO_STATE = `${NAMESPACE}/SYNC_URL_TO_STATE`;
export const syncURLToState = (params) => action(SYNC_URL_TO_STATE, params);

export const PRINT_PDF_MAP = `${NAMESPACE}/PRINT_PDF_MAP`;
export const printPDFMap = ({ endpoint, params }) =>
    action(
        PRINT_PDF_MAP,
        { endpoint, params },
        {
            analytics: new GoogleAnalyticsEventBuilder(
                'SubFeatureUsage',
                'PrintPDF - Map',
            )
                .setLabel(NAMESPACE)
                .build(),
        },
    );

export const MAP_LEGEND_LAYER_FLIP = 'TyresAndSafety/MAP_LEGEND_LAYER_FLIP';
export const mapLegendLayerFlip = (section, checked) => {
    return action(MAP_LEGEND_LAYER_FLIP, {
        sectionLabel: section,
        visibility: checked,
    });
};
