import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { schemeCategory10, scaleOrdinal } from 'd3-scale';
import { PortalWithState } from 'react-portal';
import * as types from '@rs/core/types';
import {
    MinesiteMap,
    MAP_LAYER_LABELS,
    getMapExtentBounds,
    Row,
    Col,
    CONSTANTS,
    Equipment,
} from '@rs/core/falcon';
import { wktAggregate } from '@rs/core/application';
import { Modal } from '../../Components/Modal';
import OperatorFeedbackProductivityEventButton from './OperatorFeedbackProductivityEventButton';
import { ProductivityCharts } from '../../Components/ProductivityCharts';
import DigitalGlobeBackground from '../../Components/DigitalGlobeBackground/DigitalGlobeBackground';
import { getEnabledBackgroundLayers } from '../../Modules/minesiteMap/utils';

const { getWKTResponseGeoJSON } = wktAggregate;

class OperatorFeedbackProductivityModal extends Component {
    constructor(props) {
        super(props);
        this.colorScale = scaleOrdinal(schemeCategory10);
        this.state = {
            highlightedEventIndex: null,
            truckLocation: {
                latitude: null,
                longitude: null,
            },
        };

        this.handleEventButtonHover = this.handleEventButtonHover.bind(this);
        this.handleChartHover = this.handleChartHover.bind(this);
        this.handleEventButtonClick = this.handleEventButtonClick.bind(this);
        this.mapWorstDriverEventsToForceSimulation = this.mapWorstDriverEventsToForceSimulation.bind(
            this,
        );
    }

    handleEventButtonHover(eventId) {
        this.setState({
            highlightedEventIndex: eventId,
        });
    }

    handleChartHover(point) {
        if (point) {
            this.setState((state) => ({
                ...state,
                truckLocation: {
                    latitude: point.latitude,
                    longitude: point.longitude,
                },
            }));
        }
    }

    handleEventButtonClick(eventIndex) {
        this.props.onEventSelected(eventIndex);
        this.setState((state) => ({
            ...state,
            truckLocation: {
                latitude: null,
                longitude: null,
            },
        }));
    }

    mapWorstDriverEventsToForceSimulation() {
        const { colorScale } = this;
        const { highlightedEventIndex } = this.state;
        const { worstDriverEvents } = this.props;
        return worstDriverEvents.map((event, i) => {
            const opacity =
                highlightedEventIndex !== null && highlightedEventIndex !== i
                    ? 0.2
                    : 1;
            const color = colorScale(i);
            return {
                ...event,
                X: event.LeaderLineX,
                Y: event.LeaderLineY,
                color,
                opacity,
            };
        });
    }

    render() {
        const {
            colorScale,
            handleEventButtonHover,
            handleEventButtonClick,
        } = this;

        const {
            driverEventJsons,
            wktData,
            worstDriverEvents,
            onModalClose,
            isModalOpen,
            selectedEventIndex,
            minesiteMapConfig,
        } = this.props;

        const { truckLocation } = this.state;

        if (!worstDriverEvents.length) return null;
        const [worstDriverEvent, secondWorstDriverEvent] = worstDriverEvents;

        const event = worstDriverEvents[selectedEventIndex];
        const data = driverEventJsons[event.ShiftProductivityEventId];

        const bounds = getMapExtentBounds(minesiteMapConfig.bounds);

        return (
            <div>
                {isModalOpen && (
                    <PortalWithState closeOnEsc={true} onClose={onModalClose}>
                        {() => (
                            <Modal
                                closeModal={onModalClose}
                                modalClassName="modal-lg"
                                modalTitle={`Event ${selectedEventIndex + 1}`}
                            >
                                <Row>
                                    <Col xl={12}>
                                        {data && (
                                            <ProductivityCharts
                                                data={data}
                                                onHover={this.handleChartHover}
                                                chartHeight={140}
                                                showLegend={false}
                                            />
                                        )}
                                    </Col>
                                    <Col xl={12}>
                                        <Row vgutter={CONSTANTS.spacing.md}>
                                            <Col span={24}>
                                                <MinesiteMap
                                                    maxBounds={bounds}
                                                    style={{
                                                        width: '100%',
                                                        height: 570,
                                                    }}
                                                    onOverlayAdd={
                                                        this.handleOverlayAdd
                                                    }
                                                    onOverlayRemove={
                                                        this.handleOverlayRemove
                                                    }
                                                >
                                                    <MinesiteMap.LayersControl
                                                        collapsed={true}
                                                        position="topright"
                                                    >
                                                        <DigitalGlobeBackground
                                                            checked={
                                                                getEnabledBackgroundLayers(
                                                                    minesiteMapConfig,
                                                                ).background
                                                            }
                                                        />
                                                        <MinesiteMap.Background
                                                            name={
                                                                MAP_LAYER_LABELS.backgroundClientProvided
                                                            }
                                                            checked={
                                                                getEnabledBackgroundLayers(
                                                                    minesiteMapConfig,
                                                                )
                                                                    .backgroundClientProvided
                                                            }
                                                            url={
                                                                minesiteMapConfig
                                                                    .mapLayers
                                                                    .clientProvided
                                                                    .url
                                                            }
                                                            options={
                                                                minesiteMapConfig
                                                                    .mapLayers
                                                                    .clientProvided
                                                                    .options
                                                            }
                                                        />
                                                        <MinesiteMap.RoadPath
                                                            priority={1}
                                                            checked={true}
                                                            name={
                                                                MAP_LAYER_LABELS.roadPath
                                                            }
                                                            data={getWKTResponseGeoJSON(
                                                                wktData.WKTLocuses,
                                                            )}
                                                        />
                                                        <MinesiteMap.EventPaths
                                                            priority={3}
                                                            checked={true}
                                                            name={
                                                                MAP_LAYER_LABELS.eventPaths
                                                            }
                                                            data={
                                                                worstDriverEvents
                                                            }
                                                            highlightIndex={
                                                                selectedEventIndex
                                                            }
                                                            colorPicker={
                                                                colorScale
                                                            }
                                                        />
                                                        {truckLocation.longitude &&
                                                            truckLocation.latitude && (
                                                                <MinesiteMap.MarkerPin
                                                                    priority={5}
                                                                    key={
                                                                        selectedEventIndex
                                                                    }
                                                                    fill={{
                                                                        outer: colorScale(
                                                                            selectedEventIndex,
                                                                        ),
                                                                        inner:
                                                                            'white',
                                                                    }}
                                                                    position={[
                                                                        truckLocation.latitude,
                                                                        truckLocation.longitude,
                                                                    ]}
                                                                    icon={
                                                                        <Equipment.Icon
                                                                            type={
                                                                                Equipment
                                                                                    .Types
                                                                                    .HaulTruck
                                                                            }
                                                                        />
                                                                    }
                                                                    animatePosition={
                                                                        false
                                                                    }
                                                                />
                                                            )}
                                                    </MinesiteMap.LayersControl>
                                                </MinesiteMap>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12}>
                                                <OperatorFeedbackProductivityEventButton
                                                    eventIndex={0}
                                                    onClick={
                                                        handleEventButtonClick
                                                    }
                                                    onMouseEnter={
                                                        handleEventButtonHover
                                                    }
                                                    onMouseLeave={
                                                        handleEventButtonHover
                                                    }
                                                    buttonColor={colorScale(0)}
                                                    totalLostTime={
                                                        worstDriverEvent.TotalLostTime
                                                    }
                                                    totalPasses={
                                                        worstDriverEvent.PassCount
                                                    }
                                                />
                                            </Col>
                                            <Col xl={12}>
                                                <OperatorFeedbackProductivityEventButton
                                                    eventIndex={1}
                                                    onClick={
                                                        handleEventButtonClick
                                                    }
                                                    onMouseEnter={
                                                        handleEventButtonHover
                                                    }
                                                    onMouseLeave={
                                                        handleEventButtonHover
                                                    }
                                                    buttonColor={colorScale(1)}
                                                    totalLostTime={
                                                        secondWorstDriverEvent.TotalLostTime
                                                    }
                                                    totalPasses={
                                                        secondWorstDriverEvent.PassCount
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Modal>
                        )}
                    </PortalWithState>
                )}
            </div>
        );
    }
}

OperatorFeedbackProductivityModal.defaultProps = {};

OperatorFeedbackProductivityModal.propTypes = {
    wktData: types.typeWktData,
    isModalOpen: PropTypes.bool,
    worstDriverEvents: PropTypes.arrayOf(
        PropTypes.shape({
            ShiftProductivityEventId: PropTypes.number,
        }),
    ),
    driverEventJsons: PropTypes.shape({}),
    onModalClose: PropTypes.func.isRequired,
    selectedEventIndex: PropTypes.number,
    onEventSelected: PropTypes.func,
    mapFeatures: PropTypes.arrayOf(types.typeMapFeature),
    minesiteAreasTree: types.typeMinesiteAreaTree,
    topLevelWhereOnMinesiteIdDepth: PropTypes.number,
    minesiteMapConfig: types.typeMinesiteMapConfig,
};

export default OperatorFeedbackProductivityModal;
