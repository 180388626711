import React from 'react';
import { LayerGroup, LayersControl, Marker, Pane } from 'react-leaflet';
import PropTypes from 'prop-types';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { createSVGMarker, createSVGClusterMarker } from './createMarker';
import { ZINDEX } from './constants';

class RampEvents extends React.PureComponent {
    static propTypes = {
        // The text that will be displayed in the layer control
        name: PropTypes.string.isRequired,
        // The initial state of if to display the layer
        checked: PropTypes.bool.isRequired,
        data: PropTypes.arrayOf(PropTypes.shape({})),
        // The order a layer should be rendered in, higher number displays on top
        priority: PropTypes.number.isRequired,
    };

    render() {
        const {
            data,
            name,
            checked,
            priority,
            ...leafletInjectedProps
        } = this.props;

        const zIndex = ZINDEX.MAP_PANE + priority;
        const paneId = 'RampEvents';

        if (!data || !Array.isArray(data) || !data.length) {
            return null;
        }
        return (
            <LayersControl.Overlay
                {...leafletInjectedProps}
                checked={checked}
                name={name}
            >
                <LayerGroup>
                    <Pane name={paneId} style={{ zIndex }}>
                        <MarkerClusterGroup
                            iconCreateFunction={(cluster) =>
                                createSVGClusterMarker(cluster, 'declineEvent')
                            }
                            chunkedLoading={true}
                            clusterPane={paneId}
                        >
                            {data.map((d, i) => (
                                <Marker
                                    icon={createSVGMarker('declineEvent')}
                                    key={i}
                                    position={[
                                        d.MedianNonConformantLatitude,
                                        d.MedianNonConformantLongitude,
                                    ]}
                                />
                            ))}
                        </MarkerClusterGroup>
                    </Pane>
                </LayerGroup>
            </LayersControl.Overlay>
        );
    }
}

export default RampEvents;
