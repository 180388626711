import { createReducer } from '../../Lib/reduxUtils';
import {
    State,
    FetchAllReportsSuccessPayload,
    FetchAllReportsErrorPayload,
    FetchReportSuccessPayload,
    FetchReportErrorPayload,
    PowerBiReportStatus,
} from './types';
import { notification } from '@rs/core/falcon';

export const defaultState: State = {
    powerBiReports: [],
    powerBiReportStatus: 'UNKNOWN',
    currentEditPowerBiReport: null,
    activePowerBiReport: null,
    previewAccessToken: null,
    previewReportModalStatus: 'HIDDEN',
    previewButtonStatus: 'DISABLE',
    error: null,
};
export const initialState: State = defaultState;

export function fetchAllReportsSuccess(
    state: State,
    payload: FetchAllReportsSuccessPayload,
) {
    return {
        ...state,
        powerBiReports: payload,
        error: null, // clear the error status
    };
}

export function fetchReportSuccess(
    state: State,
    payload: FetchReportSuccessPayload,
) {
    return {
        ...state,
        activePowerBiReport: payload,
        error: null,
    };
}

export function fetchCurrentEditReportError(
    state: State,
    payload: FetchReportErrorPayload,
) {
    notification.error({
        message: payload.message,
        description: [payload.description],
        duration: 10,
        key: 'PowerBNotification',
    });
    return {
        ...state,
        currentEditPowerBiReport: null,
        error: payload,
    };
}

export function fetchCurrentEditReportSuccess(
    state: State,
    payload: FetchReportSuccessPayload,
) {
    return {
        ...state,
        currentEditPowerBiReport: payload,
        error: null,
    };
}
export const onError = (state: State, payload: FetchAllReportsErrorPayload) => {
    notification.error({
        message: payload.message,
        description: [payload.description],
        duration: 10,
        key: 'PowerBNotification',
    });
    return {
        ...state,
        error: payload,
    };
};
export const setEditReportFieldError = (state: State, payload: object) => {
    return {
        ...state,
        error: payload,
    };
};

export const setPreviewAccessToken = (state: State, payload: string) => {
    return {
        ...state,
        previewAccessToken: payload,
    };
};

export const setPreviewReportModal = (state: State, payload: string) => {
    return {
        ...state,
        previewReportModalStatus: payload,
    };
};
export const setPreviewButtonStatus = (state: State, payload: string) => {
    return {
        ...state,
        previewButtonStatus: payload,
    };
};

export const setPowerBiReportStatus = (
    state: State,
    payload: PowerBiReportStatus,
) => {
    return {
        ...state,
        powerBiReportStatus: payload,
    };
};

export const clearData = (state: State) => {
    return {
        ...state,
        currentEditPowerBiReport: null,
        activePowerBiReport: null,
    };
};

export default function createReducers(
    actions: any,
    customActions = {},
    customInitialState: any,
) {
    return createReducer(
        {
            ...initialState,
            ...customInitialState,
        },
        {
            [actions.fetchAllReportsSuccess]: fetchAllReportsSuccess,
            [actions.fetchReportSuccess]: fetchReportSuccess,
            [actions.fetchEditReportSuccess]: fetchCurrentEditReportSuccess,
            [actions.fetchEditReportError]: fetchCurrentEditReportError,
            [actions.setEditReportInputField]: fetchCurrentEditReportSuccess,
            [actions.fetchReportError]: onError,
            [actions.clearData]: clearData,
            [actions.setEditReportFieldError]: setEditReportFieldError,
            [actions.previewTokenReceived]: setPreviewAccessToken,
            [actions.showPreviewReportModal]: setPreviewReportModal,
            [actions.previewButtonToggle]: setPreviewButtonStatus,
            [actions.setPowerBiReportStatus]: setPowerBiReportStatus,
            /**
             * Ensure any custom action handlers pass along state + any additional arguments needed for the
             * action handlers
             */
            ...customActions,
        },
    );
}
