import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_CYCLE_SUMMARIES_PAYLOAD_SUCCESS = `${namespace}/FETCH_CYCLE_SUMMARIES_PAYLOAD_SUCCESS`;
    const fetchCycleSummariesPayloadSuccess = (resultsArray) =>
        action(FETCH_CYCLE_SUMMARIES_PAYLOAD_SUCCESS, resultsArray);

    const FETCH_CYCLE_SUMMARIES_PAYLOAD_ERROR = `${namespace}/FETCH_CYCLE_SUMMARIES_PAYLOAD_ERROR`;
    const fetchCycleSummariesPayloadError = ({ error }) =>
        action(FETCH_CYCLE_SUMMARIES_PAYLOAD_ERROR, { error });

    return {
        FETCH_CYCLE_SUMMARIES_PAYLOAD_SUCCESS,
        fetchCycleSummariesPayloadSuccess,
        FETCH_CYCLE_SUMMARIES_PAYLOAD_ERROR,
        fetchCycleSummariesPayloadError,
    };
}
