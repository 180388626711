import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { LayersControl, Pane } from 'react-leaflet';
import isEqual from 'lodash/isEqual';
import shouldComponentUpdateUtil from './utils/shouldComponentUpdate';
import { ZINDEX } from './constants';
import UpdatingGeoJSON from './UpdatingGeoJSON';

class RoadPath extends React.Component {
    static propTypes = {
        // The text that will be displayed in the layer control
        name: PropTypes.string.isRequired,
        // The initial state of if to display the layer
        checked: PropTypes.bool.isRequired,
        shouldComponentUpdate: PropTypes.func,
        // TODO add GeoJson data type - This exists in another branch
        data: PropTypes.shape({
            coordinates: PropTypes.arrayOf(
                PropTypes.arrayOf(
                    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
                ),
            ),
            type: PropTypes.string,
        }),
        priority: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.layerRef = createRef();
    }

    shouldComponentUpdate(nextProps) {
        // TODO fix re render loops...
        // we shouldn't have to check if props get updated
        if (
            this.layerRef.current &&
            !isEqual(nextProps.data, this.props.data)
        ) {
            const currentLayer = this.layerRef.current.layer;
            const layerControl = this.layerRef.current.props;

            // 1. remove from control
            layerControl.removeLayerControl(currentLayer);
            // 2. take out the leaflet layer also
            layerControl.removeLayer(currentLayer);
        }

        return shouldComponentUpdateUtil(this.props, nextProps);
    }

    render() {
        const {
            data,
            checked,
            name,
            shouldComponentUpdate,
            priority,
            ...leafletInjectedProps
        } = this.props;

        const zIndex = ZINDEX.MAP_PANE + priority;
        const paneId = 'RoadPath';
        if (
            !data ||
            !Array.isArray(data.coordinates) ||
            !data.coordinates.length
        ) {
            return null;
        }

        return (
            <LayersControl.Overlay
                {...leafletInjectedProps}
                checked={checked}
                name={name}
                ref={this.layerRef}
            >
                <Pane name={paneId} style={{ zIndex }}>
                    <UpdatingGeoJSON
                        data={data}
                        style={{
                            color: '#eee',
                            fillOpacity: 1,
                        }}
                    />
                </Pane>
            </LayersControl.Overlay>
        );
    }
}

export default RoadPath;
