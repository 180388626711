import { cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { typeChildren } from '@rs/core/types';
import { isFeatureEnabled as isFeatureEnabledSelector } from '../../App/Selectors';

// NOTE: destructuring some additional arguments to not pass along.
// TODO This might be nicer to use as a HOC or render prop to avoid this problem
export const HasFeature = ({
    isFeatureEnabled,
    children = null,
    featureId,
    dispatch,
    ...passAlongOtherProps
}) => {
    if (!isFeatureEnabled) return null;
    return Children.map(children, (child) =>
        cloneElement(child, {
            ...passAlongOtherProps,
            ...child.props,
        }),
    );
};

HasFeature.propTypes = {
    featureId: PropTypes.number,
    isFeatureEnabled: PropTypes.bool,
    children: typeChildren,
};

const mapStateToProps = (state, ownProps) => ({
    isFeatureEnabled: isFeatureEnabledSelector(state, ownProps.featureId),
});

export default connect(mapStateToProps)(HasFeature);
