import { createSelector } from 'reselect';
import * as powerBiReportsModule from '../Modules/powerBiReports';

const isCurrentEditReportHasAllKeys = createSelector(
    powerBiReportsModule.selectors.getAll,
    (powerBiReportsState) => {
        const {
            currentEditPowerBiReport,
            powerBiReportStatus,
        } = powerBiReportsState;
        if (powerBiReportStatus === 'CREATE') {
            return (
                currentEditPowerBiReport != null &&
                currentEditPowerBiReport.reportName != null &&
                currentEditPowerBiReport.reportEmbedUrl != null
            );
        } else {
            return (
                currentEditPowerBiReport != null &&
                currentEditPowerBiReport.reportName != null &&
                currentEditPowerBiReport.reportEmbedUrl != null &&
                currentEditPowerBiReport.id != null
            );
        }
    },
);
export default isCurrentEditReportHasAllKeys;
