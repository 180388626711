import createActions from '../../Modules/minesiteMap/actions';
import createReducer, {
    syncURLToState,
} from '../../Modules/minesiteMap/reducer';
import createSelectors from '../../Modules/minesiteMap/selectors';
import namespace from './namespace';
import { SYNC_URL_TO_STATE } from '../Actions';
import { APP_INIT_SUCCESS } from '../../App/Actions';
import { getComponentConfig } from '../../App/Selectors';
import { getEnabledBackgroundLayers } from '../../Modules/minesiteMap/utils';
import { MAP_LAYER_LABELS } from '@rs/core/falcon';
import { normaliseString } from '@rs/core/utils';
/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].minesiteMap;
const mapLabelKeys = normaliseString.mapLayerLabelsToCamelCase(
    MAP_LAYER_LABELS,
);
/**
 * Create the module parts
 */
export const actions = createActions(namespace);

const initialState = {
    [mapLabelKeys.background]: true,
    [mapLabelKeys.backgroundClientProvided]: false,
    [mapLabelKeys.roadPath]: true,
    [mapLabelKeys.hrcUnloaded]: true,
    [mapLabelKeys.hrcLoaded]: true,
    [mapLabelKeys.hrcEventsLow]: false,
    [mapLabelKeys.hrcEventsMed]: false,
    [mapLabelKeys.hrcEventsHigh]: false,
    [mapLabelKeys.speedLimitedZones]: true,
    [mapLabelKeys.mineRegions]: true,
    [mapLabelKeys.trackingRegions]: false,
    [mapLabelKeys.materialMovement]: false,
    [mapLabelKeys.geofenceLabels]: true,
    center: undefined,
    zoom: 13,
};
function appInitSuccess(state, payload) {
    const mapConfig = payload.component.MinesiteMap;
    const defaultLayers = getEnabledBackgroundLayers(mapConfig);
    return { ...state, ...defaultLayers };
}

export const reducer = createReducer(
    {
        mapFilterUpdated: actions.MAP_FILTER_UPDATED,
        mapViewportChanged: actions.MAP_VIEWPORT_CHANGED,
    },
    {
        [SYNC_URL_TO_STATE]: syncURLToState,
        [actions.RESET_MAP_SETTINGS]: (state, _, rootState) => {
            const mapConfig = getComponentConfig(rootState, 'MinesiteMap');
            const defaultLayers = getEnabledBackgroundLayers(mapConfig);
            return {
                ...initialState,
                ...defaultLayers,
            };
        },
        [APP_INIT_SUCCESS]: appInitSuccess,
    },
    initialState,
);

const defaultSelectors = createSelectors(statePath);
const getEnabledLoadKeys = (state) => {
    const slice = statePath(state);
    const keys = [];

    if (slice.hrcUnloaded) {
        keys.push('unloaded');
    }
    if (slice.hrcLoaded) {
        keys.push('loaded');
    }
    return keys;
};
export const selectors = {
    ...defaultSelectors,
    getEnabledLoadKeys,
};
