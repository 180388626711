import { action } from '../../Lib/reduxUtils';
import namespace from '../Modules/namespace';

export const SYNC_URL_TO_STATE = `${namespace}/SYNC_URL_TO_STATE`;
export const syncURLToState = (payload) => action(SYNC_URL_TO_STATE, payload);

// export const A_EXAMPLE = `${namespace.ts}/A_EXAMPLE`;
// export const aExample = () => action(A_EXAMPLE);

// TODO hook up with date time picker and filter
