import { action } from '../../../Lib/reduxUtils';

export const SYNC_URL_TO_STATE = 'TruckAlertsMapView/SYNC_URL_TO_STATE';
export const syncURLToState = (params) => action(SYNC_URL_TO_STATE, params);

export const PRINT_PDF_MAP = 'TruckAlertsMapView/PRINT_PDF_MAP';
export const printPDFMap = ({ endpoint, params }) =>
    action(PRINT_PDF_MAP, { endpoint, params });

export const MAP_LEGEND_LAYER_FLIP = 'TyresAndSafety/MAP_LEGEND_LAYER_FLIP';
export const mapLegendLayerFlip = (section, checked) => {
    return action(MAP_LEGEND_LAYER_FLIP, {
        sectionLabel: section,
        visibility: checked,
    });
};
