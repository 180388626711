import createActions from '../../Modules/circuitSuggest/actions';
import createReducer, {
    initialState,
} from '../../Modules/circuitSuggest/reducer';
import createSelectors from '../../Modules/circuitSuggest/selectors';
import { actions as customActions } from './editCircuitForm';
import namespace from './namespace';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].circuitSuggest;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);
export const reducer = createReducer(
    {
        onRequest: actions.GET_CIRCUIT_SUGGEST_REQUESTED,
        onSuccess: actions.GET_CIRCUIT_SUGGEST_SUCCESS,
        onError: actions.GET_CIRCUIT_SUGGEST_ERROR,
    },
    {
        [customActions.RESET_FORM]: () => initialState,
    },
);
export const selectors = createSelectors(statePath);
