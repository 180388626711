import { roundTo } from '@rs/core/utils/mathHelpers';
import shiftUtils from '@rs/core/utils/shiftUtils';
import {
    arrayToObject,
    arrayToGroupedObject,
    flattenArray,
} from '@rs/core/utils/dataUtils';
import { operator } from '@rs/core/application';
import { createReducer } from '../../Lib/reduxUtils';
import {
    LOAD_DRIVERS_AND_MAP_DATA,
    LOAD_DRIVER_EVENT_JSON,
    SET_SPINNER_STATE,
    SET_FILTERS_WITH_URL_PARAMS,
    FILTER_UPDATED,
    SET_HIGHLIGHTED_EVENT_PATH_INDEX,
    LOAD_DRIVER_EVENT_JSON_ERROR,
    SET_MINESITEMAP_LAYERS,
    MAP_LAYER_UPDATED,
    MAP_VIEWPORT_CHANGED,
    INIT,
} from '../Actions';
import { APP_INIT_SUCCESS } from '../../App/Actions';
import {
    processDriverEventJson,
    formatChartData,
    aggregateDriverEventChunksIntoAverages,
    driverEventsToS3Path,
    getDriversByLostTimeOverPasscount,
    normaliseTotalLostTimeOverPasscount,
} from '../driverEventJson';
import { compareNumber, createMultiKeySortFn } from '../../Lib/sortingUtils';
import { formatEventFilterLabel } from '../Utils/shiftProductivityEvents';
import {
    calculateSectionOpportunity,
    sumDriverProductivityEventTimeValues,
} from '../Utils/driverShiftProductivityEvents';
import {
    filterOptionsToReactSelectFormat,
    filterShiftOptionsToReactSelectFormat,
} from '../../Utils/Filters/filterOptionsToReactSelectFormat';
import {
    getFilterOptionsFromArray,
    getMissingFilterResults,
} from '../../Utils/Filters/filters';
import { SELECT_STRING_DELIMITER } from '../../Components/Select';
import { FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS } from '../../App/Actions';
import { getOperatorDisplayNameType } from '../../App/Selectors';
import { MAP_LAYER_LABELS } from '@rs/core/falcon';
import { normaliseString } from '@rs/core/utils';
import { getEnabledBackgroundLayers } from '../../Modules/minesiteMap/utils';

import {
    reducer as mapLegends,
    moduleName as mapLegendsName,
} from '../Modules/mapLegends';
import {
    reducer as mapFeatures_v2,
    moduleName as mapFeaturesName,
} from '../Modules/mapFeatures';
import combineReducers from 'combine-reducers-global-state';

export const BASE_STATE_NAME = 'base';
/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */

const mapLabelKeys = normaliseString.mapLayerLabelsToCamelCase(
    MAP_LAYER_LABELS,
);

export const initialState = {
    shiftProductivityEvents: {
        byId: {},
        orderedByTotalLostTime: [], // [<ShiftProductivityEvent.Id>]
    },
    driverShiftProductivityEvents: {
        byId: {},
        byUniqueDriverId: {}, // {[UniqueDriverId]: [<DriverShiftProductivityEvent.Id>]}
        byShiftProductivityEventId: {}, // {[ShiftProductivityEventId]: [<DriverShiftProductivityEvent.Id>]}
    },
    uniqueDriversById: {},
    wktData: {},
    speedLimitedZones: [],
    driverEventJSONError: '',
    driverEventJSON: {
        shiftAverage: {
            averagedResults: [],
        },
        topFive: {
            averagedResults: [],
        },
        bottomFive: {
            averagedResults: [],
        },
        worker: {
            averagedResults: [],
        },
    },
    highlightedEventPathIndex: null,
    filters: {
        Date: undefined,
        Shift: '',
        Event: '',
        Worker: '',
        Crew: '',
    },
    spinner: {
        isActive: false,
        message: '',
        faIcon: undefined,
    },
    highlightedShiftProductivityEventId: null,
    mapFeatures: [],
    minesiteAreasTree: {},
    gearLockdown: [],
    minesiteMap: {
        [mapLabelKeys.background]: false,
        [mapLabelKeys.backgroundClientProvided]: true,
        [mapLabelKeys.mineRegions]: false,
        [mapLabelKeys.roadPath]: true,
        [mapLabelKeys.focusAreaLabels]: true,
        [mapLabelKeys.eventPaths]: true,
        [mapLabelKeys.speedLimitedZones]: true,
        [mapLabelKeys.materialMovement]: false,
        [mapLabelKeys.trackingRegions]: false,
        [mapLabelKeys.geofenceLabels]: true,
        center: undefined,
        zoom: 13,
    },
};

// //////////////////////////////////////////////////////////////////////
//  SELECTORS
// //////////////////////////////////////////////////////////////////////

const getFilters = (state) => state.filters;
const getMineSiteLayers = (state) => state.minesiteMap;

const getMinesiteMapWithViewPort = (state) => {
    const mapLayers = getMineSiteLayers(state);
    const isViewportValid = Array.isArray(mapLayers.center);

    return {
        ...mapLayers,
        isViewportValid,
    };
};

const getDefinedFilters = (state) => {
    const filters = getFilters(state);

    const params = {
        Date: filters.Date,
        Shift: filters.Shift,
    };
    if (filters.Worker) {
        params.UniqueDriverId = String(filters.Worker);
    }
    if (filters.Event) {
        params.Event = filters.Event;
    }
    // NOTE: Productivity doesn't currently work with a date range but using the same function for consistency
    params.shiftIds = shiftUtils.getAllFirstLastFromArray(
        shiftUtils.generateShiftIdRange(
            filters.Date,
            filters.EndDate,
            filters.Shift.split(SELECT_STRING_DELIMITER),
        ),
    );
    return params;
};
const getDriverEventJsonPaths = (state) => {
    const filters = getFilters(state);

    const driverEventIds =
        state.driverShiftProductivityEvents.byShiftProductivityEventId[
            filters.Event
        ];
    if (!driverEventIds) return [];
    const driverEvents = driverEventIds.map(
        (driverId) => state.driverShiftProductivityEvents.byId[driverId],
    );

    if (!filters.Worker) {
        const sortedByTotalLostTimeOverPassCount = getDriversByLostTimeOverPasscount(
            driverEvents,
        );

        const topFiveDriverEvents = flattenArray(
            sortedByTotalLostTimeOverPassCount
                .slice(0, 5)
                .map((d) => d.driverEvents),
        );

        const bottomFiveDriverEvents = flattenArray(
            sortedByTotalLostTimeOverPassCount
                .slice(-5)
                .map((d) => d.driverEvents),
        );

        return [
            {
                category: 'topFive',
                files: driverEventsToS3Path(topFiveDriverEvents),
            },
            {
                category: 'bottomFive',
                files: driverEventsToS3Path(bottomFiveDriverEvents),
            },
            {
                category: 'shiftAverage',
                files: driverEventsToS3Path(driverEvents),
            },
        ];
    }
    const eventIds =
        state.driverShiftProductivityEvents.byUniqueDriverId[filters.Worker] ||
        [];
    const workerDriverEvents = eventIds
        // Only get the driver events that match the selected ShiftProductivityEventId
        .filter(
            (driverEventId) =>
                state.driverShiftProductivityEvents.byShiftProductivityEventId[
                    filters.Event
                ].indexOf(driverEventId) > -1,
        )
        // Select the driver event
        .map(
            (driverEventId) =>
                state.driverShiftProductivityEvents.byId[driverEventId],
        );

    return [
        { category: 'worker', files: driverEventsToS3Path(workerDriverEvents) },
        { category: 'shiftAverage', files: driverEventsToS3Path(driverEvents) },
    ];
};
const getSpinnerState = (state) => state.spinner;
const getWktData = (state) => state.wktData || {};
const getShiftProductivityEventIdsByTotalLostTime = (state, limit) => {
    const allResults = state.shiftProductivityEvents.orderedByTotalLostTime;
    if (limit) {
        return limit < 0 ? allResults.slice(limit) : allResults.slice(0, limit);
    }
    return allResults;
};
const getFormattedAveragedResults = (state) => {
    const filters = getFilters(state);
    if (!filters.Event) return [];

    return formatChartData(state.driverEventJSON);
};
const getHighlightedEventPathIndex = (state) => state.highlightedEventPathIndex;
const getTopEventsByLostTime = (state, numberOfEventsToReturn = 5) => {
    const filters = getFilters(state);
    if (filters.Crew || filters.Worker) {
        const driverList = Object.keys(state.uniqueDriversById)
            .reduce(
                (collection, uniqueDriverId) => [
                    ...collection,
                    ...state.uniqueDriversById[uniqueDriverId],
                ],
                [],
            )
            .filter(
                (driver) =>
                    !filters.Worker || filters.Worker === driver.UniqueDriverId,
            )
            .filter((driver) => !filters.Crew || filters.Crew === driver.Crew);

        const driverEvents = flattenArray(
            driverList.map(
                (driver) =>
                    state.driverShiftProductivityEvents.byUniqueDriverId[
                        driver.UniqueDriverId
                    ],
            ),
        ).map(
            (driverEventId) =>
                state.driverShiftProductivityEvents.byId[driverEventId],
        );

        const driverEventsByShiftProductivityEventId = arrayToGroupedObject(
            driverEvents,
            'ShiftProductivityEventId',
        );

        const driverEventsSortedByShiftProductivityEventIds = Object.keys(
            driverEventsByShiftProductivityEventId,
        ).sort((a, b) => {
            const sumA = sumDriverProductivityEventTimeValues(
                driverEventsByShiftProductivityEventId[a],
            );
            const sumB = sumDriverProductivityEventTimeValues(
                driverEventsByShiftProductivityEventId[b],
            );
            return sumB.totalLostTime - sumA.totalLostTime;
        });

        return driverEventsSortedByShiftProductivityEventIds
            .slice(0, numberOfEventsToReturn)
            .map((shiftProductivityEventId) => {
                const {
                    totalPassCount,
                    totalLostTime,
                    totalDriverTime,
                } = sumDriverProductivityEventTimeValues(
                    driverEventsByShiftProductivityEventId[
                        shiftProductivityEventId
                    ],
                );
                const event =
                    state.shiftProductivityEvents.byId[
                        shiftProductivityEventId
                    ];
                return {
                    ...event,
                    totalPassCount,
                    totalLostTime,
                    roundedTotalLostTime: normaliseTotalLostTimeOverPasscount(
                        totalLostTime,
                        totalPassCount,
                    ),
                    roundedTotalPassCount: Math.ceil(totalPassCount),
                    sectionOpportunity: calculateSectionOpportunity(
                        totalLostTime,
                        totalDriverTime,
                    ),
                };
            });
    }

    const shiftProductivityEventIds = getShiftProductivityEventIdsByTotalLostTime(
        state,
        5,
    );
    return shiftProductivityEventIds.reduce(
        (collection, shiftProductivityEventId) => {
            const driverEventIds =
                state.driverShiftProductivityEvents.byShiftProductivityEventId[
                    shiftProductivityEventId
                ];
            if (driverEventIds) {
                const driverEvents = driverEventIds.map(
                    (driverEventId) =>
                        state.driverShiftProductivityEvents.byId[driverEventId],
                );
                const {
                    totalLostTime,
                    totalDriverTime,
                    totalPassCount,
                } = sumDriverProductivityEventTimeValues(driverEvents);

                const roundedTotalPassCount = Math.ceil(totalPassCount);
                const roundedTotalLostTime = roundTo(totalLostTime, 0);
                const sectionOpportunity = calculateSectionOpportunity(
                    roundedTotalLostTime,
                    totalDriverTime,
                );
                const shiftProductivityEvent =
                    state.shiftProductivityEvents.byId[
                        shiftProductivityEventId
                    ] || {};
                collection.push({
                    ...shiftProductivityEvent,
                    sectionOpportunity,
                    roundedTotalPassCount,
                    roundedTotalLostTime,
                    totalPassCount,
                    totalLostTime,
                });
            }
            return collection;
        },
        [],
    );
};

const getTopDriversForShiftProductivityEventId = (
    state,
    numberOfEvents = 0,
) => {
    if (!state.highlightedShiftProductivityEventId) {
        return {
            isSingleTableMode: true,
            data: [],
        };
    }

    // Get driverEvents for the highlighted ShiftProductivityEventId
    const driverEvents = state.driverShiftProductivityEvents.byShiftProductivityEventId[
        state.highlightedShiftProductivityEventId
    ].map((driverId) => state.driverShiftProductivityEvents.byId[driverId]);

    const sortedByTotalLostTimeOverPassCount = getDriversByLostTimeOverPasscount(
        driverEvents,
    );

    const sortedByTotalLostTimeOverPassCountWithDriverName = sortedByTotalLostTimeOverPassCount.map(
        (driverEventAggregate) => {
            const driver =
                state.uniqueDriversById[driverEventAggregate.uniqueDriverId] ||
                {};
            return {
                // 'UNKNOWN' is a temporary workaround until DP fixes setting incorrect driverShiftProductivityEvents.UniqueDriverId
                DriverName: (driver[0] && driver[0].WorkerName) || 'UNKNOWN',
                totalLostTime: driverEventAggregate.totalLostTimeOverPassCount,
            };
        },
    );

    // If there are only a few results, return the all results
    if (sortedByTotalLostTimeOverPassCountWithDriverName.length <= 10) {
        return {
            isSingleTableMode: true,
            data: sortedByTotalLostTimeOverPassCountWithDriverName,
        };
    }

    if (numberOfEvents < 0) {
        const data = sortedByTotalLostTimeOverPassCountWithDriverName
            .slice(numberOfEvents)
            // reverse the sort order
            .sort((a, b) => compareNumber(b, a));
        return {
            isSingleTableMode: false,
            data,
        };
    }
    return {
        isSingleTableMode: false,
        data: sortedByTotalLostTimeOverPassCountWithDriverName.slice(
            0,
            numberOfEvents,
        ),
    };
};
export const getFilterOptions = (state, rootState) => {
    const operatorNameDisplayType = getOperatorDisplayNameType(rootState);
    const driverList = Object.keys(state.uniqueDriversById).reduce(
        (collection, uniqueDriverId) => [
            ...collection,
            ...state.uniqueDriversById[uniqueDriverId],
        ],
        [],
    );

    const filters = getFilters(state);

    const driverSelector = (d) => d.WorkerName;
    const crewSelector = (d) => d.Crew;

    const driverOptions = getFilterOptionsFromArray(
        driverList,
        [[crewSelector, filters.Crew]],
        driverSelector,
    ).map((driver) => ({
        label: operator.displayName.format(operatorNameDisplayType, driver),
        value: operator.operatorNameToUniqueId(driver),
    }));

    const crewOptions = getFilterOptionsFromArray(
        driverList,
        [[driverSelector, filters.Worker]],
        crewSelector,
    );

    const eventOptions = getTopEventsByLostTime(state, 5).map(
        (event, index) => ({
            label: formatEventFilterLabel(
                index,
                event.sectionOpportunity,
                event.roundedTotalLostTime,
                event.roundedTotalPassCount,
            ),
            value: event.ShiftProductivityEventId.toString(),
        }),
    );

    const shifts = shiftUtils.getAllShiftShortNamesForDate(filters.Date);

    return {
        shifts: filterShiftOptionsToReactSelectFormat(
            shifts.rawShiftsInDay,
            shifts.shiftShortNames,
        ),
        workers: driverOptions,
        events: eventOptions,
        crews: filterOptionsToReactSelectFormat(
            getMissingFilterResults(filters.Crew, crewOptions),
        ),
    };
};

const getDriverEventJsonError = (state) => state.driverEventJSONError;
const getSpeedLimitedZones = (state) => state.speedLimitedZones;
const getMapFeatures = (state) => state.mapFeatures;
const getMinesiteAreasTree = (state) => state.minesiteAreasTree;
const getGearLockdown = (state) => state.gearLockdown;

// get map polygon layer state
const getPolygonsLayerState = (state) => {
    let minesiteMapLayers = getMineSiteLayers(state);
    // set true to 'VISIBLE' and false to 'HIDDEN'
    return Object.keys(minesiteMapLayers).reduce((acc, item) => {
        acc[item] = minesiteMapLayers[item] === true ? 'VISIBLE' : 'HIDDEN';
        return acc;
    }, {});
};

// get only geofencelabels state
const getGeofenceLabels = (state) => {
    let { geofenceLabels } = getMineSiteLayers(state);
    return geofenceLabels;
};

export const sliceSelectors = {
    getFilters,
    getMineSiteLayers,
    getMinesiteMapWithViewPort,
    getDefinedFilters,
    getSpinnerState,
    getFilterOptions,
    getWktData,
    getTopEventsByLostTime,
    getFormattedAveragedResults,
    getHighlightedEventPathIndex,
    getDriverEventJsonPaths,
    getTopDriversForShiftProductivityEventId,
    getDriverEventJsonError,
    getSpeedLimitedZones,
    getMapFeatures,
    getMinesiteAreasTree,
    getGearLockdown,
    getPolygonsLayerState,
    getGeofenceLabels,
};

// //////////////////////////////////////////////////////////////////////
//  REDUCERS
// //////////////////////////////////////////////////////////////////////

/**
 * Not sure why the Crews have trailing whitespace
 * @param {array} uniqueDrivers
 * @returns {array} uniqueDrivers
 */
const removeWhitespaceCrewNames = (uniqueDrivers) =>
    uniqueDrivers.map((driver) => ({
        ...driver,
        Crew: driver.Crew.trim(),
    }));

export function loadDriversAndMapData(
    state,
    {
        mapLabels,
        shiftProductivityEvents,
        uniqueDrivers,
        wktData,
        driverShiftProductivityEvents,
        speedLimitedZones,
        mapFeatures,
        minesiteAreasTree,
        gearLockdown,
    },
) {
    const sortingFn = createMultiKeySortFn([
        { name: 'TotalLostTime', reverse: true },
    ]);
    const orderedByTotalLostTime = [...shiftProductivityEvents]
        .sort(sortingFn)
        .map((shiftEvent) => shiftEvent.ShiftProductivityEventId);

    const driverShiftEventsByShiftProductivityEventId = arrayToGroupedObject(
        driverShiftProductivityEvents,
        'ShiftProductivityEventId',
    );
    const byShiftProductivityEventId = Object.keys(
        driverShiftEventsByShiftProductivityEventId,
    ).reduce((collection, shiftProductivityEventId) => {
        collection[
            shiftProductivityEventId
        ] = driverShiftEventsByShiftProductivityEventId[
            shiftProductivityEventId
        ].map((driverEvent) => driverEvent.Id);
        return collection;
    }, {});

    const driverShiftEventsByUniqueDriverId = arrayToGroupedObject(
        driverShiftProductivityEvents,
        'UniqueDriverId',
    );
    const byUniqueDriverId = Object.keys(
        driverShiftEventsByUniqueDriverId,
    ).reduce((collection, shiftProductivityEventId) => {
        collection[
            shiftProductivityEventId
        ] = driverShiftEventsByUniqueDriverId[shiftProductivityEventId].map(
            (driverEvent) => driverEvent.Id,
        );
        return collection;
    }, {});
    return {
        ...state,
        shiftProductivityEvents: {
            ...state.shiftProductivityEvents,
            byId: arrayToObject(
                shiftProductivityEvents,
                'ShiftProductivityEventId',
            ),
            orderedByTotalLostTime,
        },
        driverShiftProductivityEvents: {
            ...state.driverShiftProductivityEvents,
            byId: arrayToObject(driverShiftProductivityEvents, 'Id'),
            byUniqueDriverId,
            byShiftProductivityEventId,
        },
        shiftProductivityEventsById: arrayToObject(
            shiftProductivityEvents,
            'ShiftProductivityEventId',
        ),
        uniqueDriversById: arrayToGroupedObject(
            removeWhitespaceCrewNames(uniqueDrivers),
            'UniqueDriverId',
        ),
        wktData,
        speedLimitedZones,
        mapFeatures: (mapFeatures && mapFeatures.features) || [],
        minesiteAreasTree,
        gearLockdown,
    };
}

export function loadDriverEventJSON(state, driverEventJsons) {
    const filters = getFilters(state);
    const groupedDriverEventJsons = driverEventJsons.reduce(
        (collection, driverEventJson) => {
            const averagedDriverEventJSON = aggregateDriverEventChunksIntoAverages(
                driverEventJson.json,
            );
            collection[driverEventJson.category] = processDriverEventJson(
                averagedDriverEventJSON,
            );
            return collection;
        },
        {},
    );
    return {
        ...state,
        driverEventJSONError: '',
        driverEventJSON: {
            ...state.driverEventJSON,
            ...groupedDriverEventJsons,
            worker: !filters.Worker ? {} : groupedDriverEventJsons.worker,
        },
    };
}

export function replaceObjectInState(key, state, payload) {
    return {
        ...state,
        [key]: {
            ...state[key],
            ...payload,
        },
    };
}

export const setFiltersWithUrlParams = (state, filtersFromUrl) => {
    // remove minesitemap layers
    const sanitised = Object.keys(filtersFromUrl)
        .filter((key) => Object.keys(state.filters).includes(key))
        .reduce((obj, key) => {
            return {
                ...obj,
                [key]: filtersFromUrl[key],
            };
        }, {});
    // store only filter values
    const filtersAsStringValues = {};
    Object.keys(sanitised).forEach((key) => {
        filtersAsStringValues[key] = sanitised[key].toString();
    });
    const newState = replaceObjectInState(
        'filters',
        state,
        filtersAsStringValues,
    );

    return newState;
};
export const setSpinnerState = (state, spinner) =>
    replaceObjectInState('spinner', state, spinner);
export const filterUpdated = (state, { filterName, filterValue }) => {
    const newState = {
        ...state,
        filters: {
            ...state.filters,
            [filterName]: filterValue || '',
        },
        driverEventJSONError: '',
        driverEventJSON: {
            shiftAverage: {
                averagedResults: [],
            },
            topFive: {
                averagedResults: [],
            },
            bottomFive: {
                averagedResults: [],
            },
            worker: {
                averagedResults: [],
            },
        },
    };
    if (filterName === 'Date' || filterName === 'Shift') {
        newState.filters.Worker = '';
        newState.filters.Crew = '';
        newState.filters.Event = '';
        newState.driverEventJSON = {};
        newState.shiftProductivityEventsById = {};
    } else if (filterName === 'Crew') {
        newState.filters.Worker = '';
        newState.filters.Event = '';
    } else if (filterName === 'Worker') {
        newState.filters.Event = '';
    }
    return newState;
};
export const setHighlightedEventPathIndex = (state, { index, row }) => {
    const id = !row ? null : row.ShiftProductivityEventId;
    return {
        ...state,
        highlightedShiftProductivityEventId: id,
        highlightedEventPathIndex: index,
    };
};

export const loadDriverEventJSONError = (state, { error }) => ({
    ...state,
    driverEventJSONError: error,
});

export function fetchPrivateSiteConfigurationSuccess(state) {
    if (state.filters.Shift) {
        return state;
    }
    const defaultDate = shiftUtils.newDefaultFilterDate();
    const firstShift = shiftUtils.getFirstShiftFromDate(defaultDate);
    return {
        ...state,
        filters: {
            ...state.filters,
            Shift: firstShift.shortName,
            Date: defaultDate,
        },
    };
}

export const setMapLayersWithUrlParams = (state, mapLayersFromUrl) => {
    const validKeys = Object.keys(state.minesiteMap);
    const newState = Object.entries(mapLayersFromUrl)
        .filter(
            ([key, value]) =>
                validKeys.find((vk) => vk === key) && value !== undefined,
        )
        .reduce(
            (agg, [key, value]) => {
                // Format into the correct types
                switch (key) {
                    case 'center':
                        agg.center = value.split(',').map((v) => parseFloat(v));
                        break;
                    case 'zoom':
                        agg.zoom = Math.round(value * 10) / 10;
                        break;
                    default:
                        agg[key] = Boolean(value);
                }
                return agg;
            },
            { ...state.minesiteMap },
        );

    return {
        ...state,
        minesiteMap: newState,
    };
};

export function setMinesiteMapCenterZoom(state, viewport) {
    return {
        ...state,
        minesiteMap: {
            ...state.minesiteMap,
            center: viewport.center,
            zoom: viewport.zoom,
        },
    };
}

export function updateMinesiteMap(state, { filterName, filterValue }) {
    return {
        ...state,
        minesiteMap: {
            ...state.minesiteMap,
            [filterName]: filterValue,
        },
    };
}

export function appInitSuccess(state, config) {
    const mapConfig = config.component.MinesiteMap;
    const defaultLayers = getEnabledBackgroundLayers(mapConfig);
    return {
        ...state,
        minesiteMap: {
            ...state.minesiteMap,
            ...defaultLayers,
        },
    };
}

/**
 * A function that initialise the Productivity state
 * @param state
 */
export function init(state) {
    return initialState;
}

const baseReducer = createReducer(initialState, {
    [LOAD_DRIVERS_AND_MAP_DATA]: loadDriversAndMapData,
    [LOAD_DRIVER_EVENT_JSON]: loadDriverEventJSON,
    [LOAD_DRIVER_EVENT_JSON_ERROR]: loadDriverEventJSONError,
    [SET_SPINNER_STATE]: setSpinnerState,
    [SET_FILTERS_WITH_URL_PARAMS]: setFiltersWithUrlParams,
    [FILTER_UPDATED]: filterUpdated,
    [SET_HIGHLIGHTED_EVENT_PATH_INDEX]: setHighlightedEventPathIndex,
    [FETCH_PRIVATE_SITE_CONFIGURATION_SUCCESS]: fetchPrivateSiteConfigurationSuccess,
    [SET_MINESITEMAP_LAYERS]: setMapLayersWithUrlParams,
    [MAP_LAYER_UPDATED]: updateMinesiteMap,
    [MAP_VIEWPORT_CHANGED]: setMinesiteMapCenterZoom,
    [APP_INIT_SUCCESS]: appInitSuccess,
    [INIT]: init,
});

export const reducer = combineReducers({
    base: baseReducer,
    [mapLegendsName]: mapLegends,
    [mapFeaturesName]: mapFeatures_v2,
});
