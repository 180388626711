import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import * as resourceSagas from './resourceSagas';
import { actions as spinnerActions } from '../EditUser/Modules/spinner';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import { actions as usersActions } from '../EditUser/Modules/users';
import { actions as editFormActions } from '../EditUser/Modules/editForm';
import { getMinesiteId } from '../App/Selectors';

function* editUser({ payload }) {
    yield put(
        spinnerActions.setSpinnerState(
            true,
            LOADING_MESSAGES.LOADING__PROCESSING,
        ),
    );

    const minesiteId = yield select(getMinesiteId);

    const apiResponse = yield call(resourceSagas.patchEditUser, {
        Id: payload.userInfo.id,
        OperatorPin: payload.userInfo.operatorPin,
        Crew: payload.userInfo.crew,
        ProfilePicture: payload.userInfo.profilePicture,
        Email: payload.userInfo.email,
        MinesiteId: minesiteId,
        FirstName: payload.userInfo.firstName,
        LastName: payload.userInfo.lastName,
        ContactNumber: payload.userInfo.contactNumber,
        EmployeeId: payload.userInfo.employeeId,
        UserName: payload.userInfo.userName,
        Password: payload.userInfo.password,
        Department: payload.userInfo.department,
        Role: payload.userInfo.role,
        // flag as internal user so not showing to customer
        IsMaxmineUser: payload.userInfo.department === 'MaxMine Internal',
    });

    if (apiResponse.error) {
        yield put(editFormActions.editFormError(apiResponse.error));
        yield put(spinnerActions.setSpinnerState(false));
    } else {
        yield put(
            editFormActions.editFormSuccess(
                apiResponse.response.SuccessMessage,
            ),
        );
        yield put(spinnerActions.setSpinnerState(false));
    }
}

function* loadUsers() {
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const { response, error } = yield call(resourceSagas.getUserList);
    if (error) {
        yield editFormActions.editFormError({ error });
        return;
    }

    response.rows.forEach((row) => {
        row.EmployeeId = String(row.EmployeeId);
        if (row.EmployeeId === 'null') {
            row.EmployeeId = '';
        }
        if (!row.EmployeeId) {
            row.EmployeeId = '';
        }
        if (row.UserName === 'null') {
            row.UserName = '';
        }
        if (!row.UserName) {
            row.UserName = '';
        }
    });

    yield all([
        put(usersActions.fetchUsersSuccess(response.rows)),
        put(spinnerActions.setSpinnerState(false)),
    ]);
}

export default function* watch() {
    yield all([
        takeLatest(usersActions.FETCH_USERS_REQUESTED, loadUsers),
        takeLatest(editFormActions.SUBMIT_EDIT_USER, editUser),
    ]);
}
