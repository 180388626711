import React from 'react';
import { LayerGroup, LayersControl, Marker, Pane } from 'react-leaflet';
import PropTypes from 'prop-types';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import cn from 'classnames';
import { createClusterMarker, createIconMarker, markers } from './createMarker';
import shouldComponentUpdateUtil from './utils/shouldComponentUpdate';
import { ZINDEX } from './constants';

function createTruckAlertIconMarker(type, severity) {
    const imgSrc = markers[type];
    if (!imgSrc) {
        throw new Error(`Invalid marker type, got: ${type}`);
    }

    const classNames = cn('Marker', `Marker--${type}`);
    const markerBackgroundClass = `Marker__Background--${severity.toLowerCase()}`;

    return createIconMarker(imgSrc, classNames, markerBackgroundClass);
}

function createTruckAlertClusterMarker(cluster, severity) {
    const markerBackgroundClass = `MinesiteMap__MarkerClusterContainer--${severity.toLowerCase()}`;
    return createClusterMarker(cluster, markerBackgroundClass);
}

// A small optimisation to create the markers once, rather than many times each render
// See ticket for more info: DR-1779
const HRCEventMarkers = {
    Low: createTruckAlertIconMarker('HRCEvent', 'Low'),
    Medium: createTruckAlertIconMarker('HRCEvent', 'Medium'),
    High: createTruckAlertIconMarker('HRCEvent', 'High'),
};

class HRCEvents extends React.Component {
    static propTypes = {
        // The text that will be displayed in the layer control
        name: PropTypes.string.isRequired,
        // The initial state of if to display the layer
        checked: PropTypes.bool.isRequired,
        // The data to map over and make Markers of
        data: PropTypes.arrayOf(
            PropTypes.shape({
                HaulTruckSiteName: PropTypes.string,
                Latitude: PropTypes.number,
                Level: PropTypes.string,
                EquipmentId: PropTypes.number,
                Longitude: PropTypes.number,
                MaterialType: PropTypes.string,
                ShiftId: PropTypes.number,
                Time: PropTypes.number,
                Type: PropTypes.string,
                WhereOnMinesiteName: PropTypes.string,
                X: PropTypes.number,
                Y: PropTypes.number,
                id: PropTypes.string.isRequired,
            }),
        ),
        // This applies the correct classes based on the level of the event
        severity: PropTypes.oneOf(['Low', 'Medium', 'High']).isRequired,
        // The order a layer should be rendered in, higher number displays on top
        priority: PropTypes.number.isRequired,
        shouldComponentUpdate: PropTypes.func,
    };

    shouldComponentUpdate(nextProps) {
        return shouldComponentUpdateUtil(this.props, nextProps);
    }

    render() {
        const {
            data,
            name,
            checked,
            severity,
            priority,
            shouldComponentUpdate,
            ...leafletInjectedProps
        } = this.props;

        const zIndex = ZINDEX.MAP_PANE + priority;
        const paneId = `HRCEvents-${severity}`;

        return (
            <LayersControl.Overlay
                {...leafletInjectedProps}
                checked={checked}
                name={name}
            >
                <LayerGroup>
                    <Pane name={paneId} style={{ zIndex }}>
                        <MarkerClusterGroup
                            clusterPane={paneId}
                            iconCreateFunction={(cluster) =>
                                createTruckAlertClusterMarker(cluster, severity)
                            }
                            chunkedLoading={true}
                        >
                            {data
                                .filter(
                                    (row) =>
                                        row.Latitude !== null &&
                                        row.Longitude !== null,
                                )
                                .map((row) => (
                                    <Marker
                                        icon={HRCEventMarkers[severity]}
                                        key={row.id}
                                        position={[row.Latitude, row.Longitude]}
                                    />
                                ))}
                        </MarkerClusterGroup>
                    </Pane>
                </LayerGroup>
            </LayersControl.Overlay>
        );
    }
}

export default HRCEvents;
