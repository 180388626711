import { roundTo, sumNumbers } from '@rs/core/utils/mathHelpers';
import { arrayToGroupedObject } from '@rs/core/utils/dataUtils';
import { createMultiKeySortFn } from '../Lib/sortingUtils';

/**
 * Formats the averaged driver event json data into a format for the charts.
 * Basically does a transpose from:
 *
 *      shiftAverage: {                 chart1: [{
 *          chart1                          shiftAverage
 *          chart2                          topFive
 *          chart3                          bottomFive
 *          ...                             ...
 *      }                   ==>         }]
 *      topFive: {                      chart2: [{
 *          chart1                          shiftAverage
 *          chart2                          topFive
 *          chart3                          bottomFive
 *          ...                             ...
 *      }                               }]
 *      ...                             ...
 *
 * @param {array} driverEventJson
 * @returns {array}
 */
export function formatChartData(driverEventJson) {
    const dataGroups = Object.keys(driverEventJson);
    const results = dataGroups.reduce((collection, dataGroup) => {
        const { averagedResults } = driverEventJson[dataGroup];
        if (averagedResults) {
            averagedResults.forEach((array) => {
                const category = array[0].category;
                if (!collection[category]) {
                    collection[category] = [];
                }
                collection[category][dataGroup] = array;
            });
        }
        return collection;
    }, []);
    return results;
}

/**
 * Processes the Driver event chunk & calculates values that need to be converted
 * @param {array} - data, an Event
 * @returns {object}
 */
export function processDriverEventJson(data) {
    if (Object.keys(data).length === 0) {
        return {
            averagedResults: [],
        };
    }

    const categoryKeys = Object.keys(data).filter(
        (key) =>
            (key.includes('Mean') && !key.includes('Frank')) || key === 'Z',
    );

    data.displacement = calculateDisplacementFromdCompXYDist(
        data.dCOMP_XY_Dist,
    );

    const averagedResults = categoryKeys.reduce((collection, categoryKey) => {
        const categoryCollection = data[categoryKey].map((d, i) => {
            const frankName = getFrankName(categoryKey);
            const xPos = data.X[i];
            const averagedData = {
                category: categoryKey,
                average: d,
                frank: data[frankName][i],
                dCOMP_XY_Dist: data.dCOMP_XY_Dist[i],
                displacement: data.displacement[i],
                x: xPos,
                y: data.Y[i],
                latitude: data.latitude[i],
                longitude: data.longitude[i],
            };
            if (categoryKey === 'Mean_COMP_Lower_Tolerance_Speed_Driver') {
                averagedData.average = convertMetersSecToKilometersHour(
                    averagedData.average,
                );
                averagedData.frank = convertMetersSecToKilometersHour(
                    averagedData.frank,
                );
                averagedData.frank_min = convertMetersSecToKilometersHour(
                    data.Mean_Frank_Speed_Lower_Driver[i],
                );
            }
            return averagedData;
        });
        collection.push(categoryCollection);
        return collection;
    }, []);

    return {
        averagedResults,
    };
}

/**
 * Creates a 'displacement' of the distance the truck has moved
 * @param dCompXYDist {[]number]}
 * @returns {[]number}
 */
export function calculateDisplacementFromdCompXYDist(dCompXYDist) {
    return dCompXYDist.reduce((displacement, next, index) => {
        const displacementSoFar = index === 0 ? 0 : displacement[index - 1];
        displacement.push(displacementSoFar + next);
        return displacement;
    }, []);
}

/**
 * Values are in metres/sec so multiply by 3.6 to get to km/h
 * @param value {number}
 * @returns {*}
 */
export function convertMetersSecToKilometersHour(value) {
    // check for nulls as null * 3.6 = 0
    if (value !== null) {
        return value * 3.6;
    }
    return value;
}

/**
 * Creates the Frankenstein name from a category. The returned string should match a key in the Driver Events JSON
 * eg. Mean_Gear_Ratio -> Mean_Frank_Gear_Ratio
 * @param {string} - categoryKey
 * @returns {string}
 */
export function getFrankName(categoryKey) {
    if (categoryKey === 'Z') {
        return 'Z';
    }
    const parts = categoryKey.split('_');
    const rest = parts.slice(1, parts.length).join('_');
    return `${parts[0]}_Frank_${rest}`;
}

/**
 * This aggregates multiple driver event json chunks into the average values
 * @param {Array} driverEventJSONs - array of driver event JSON chunks
 */
export function aggregateDriverEventChunksIntoAverages(driverEventJSONs = []) {
    const keysToAverage = [
        'Mean_COMP_Lower_Tolerance_Speed_Driver',
        'Mean_Frank_COMP_Lower_Tolerance_Speed_Driver',
        'Mean_Frank_Speed_Lower_Driver',
        'Mean_Frank_Throttle_Position_Driver',
        'Mean_Frank_Trans_Current_Gear_Driver',
        'Mean_Throttle_Position_Driver',
        'Mean_Trans_Current_Gear_Driver',
    ];

    // Only display one of the Retarder charts, dropping the results from other retarder
    const showBrakeLightRetarder = driverEventJSONs.every((chunk) =>
        chunk.Mean_Retarder_Driver.every((d) => d === null),
    );
    if (showBrakeLightRetarder) {
        keysToAverage.push(
            'Mean_Frank_Retarder_Brake_Light_Driver',
            'Mean_Retarder_Brake_Light_Driver',
        );
    } else {
        keysToAverage.push(
            'Mean_Frank_Retarder_Driver',
            'Mean_Retarder_Driver',
        );
    }

    // The values for these keys are the same across all chunks so no ned to average
    const keysToNotAverage = [
        'X',
        'Y',
        'Z',
        'dCOMP_XY_Dist',
        'latitude',
        'longitude',
    ];

    // Get the passcount for each index position in the array
    const passCountCollection = driverEventJSONs.reduce(
        (collection, section) => {
            section.Pass_Count.forEach((d, i) => {
                collection[i] = collection[i] || [];
                collection[i].push(d);
            });
            return collection;
        },
        {},
    );

    // Get the other value for each position in the array multiplied by the passcount the object belongs too
    const combinedRuns = driverEventJSONs.reduce(
        (collection, item, itemIndex) => {
            Object.keys(item)
                .filter((itemKey) => keysToAverage.indexOf(itemKey) > -1)
                .forEach((itemKey) => {
                    item[itemKey].forEach((d, passCountIndex) => {
                        const passCount =
                            passCountCollection[passCountIndex][itemIndex];
                        collection[itemKey] = collection[itemKey] || {};
                        collection[itemKey][passCountIndex] =
                            collection[itemKey][passCountIndex] || [];
                        collection[itemKey][passCountIndex].push(d * passCount);
                    });
                });
            return collection;
        },
        {},
    );

    // Sum up other values & passcount to get the weighted value
    const averagedRuns = Object.keys(combinedRuns).reduce(
        (collection, categoryKey) => {
            collection[categoryKey] = Object.keys(
                combinedRuns[categoryKey],
            ).map((passCountIndex) => {
                const summedOtherValues = sumNumbers(
                    combinedRuns[categoryKey][passCountIndex],
                );
                const summedPassCount = sumNumbers(
                    passCountCollection[passCountIndex],
                );
                return summedOtherValues / summedPassCount;
            });
            return collection;
        },
        {},
    );

    // attach the other fields back on
    keysToNotAverage.forEach((key) => {
        averagedRuns[key] = driverEventJSONs[0][key];
    });

    // attach the total passcounts
    averagedRuns.Pass_Count = Object.keys(passCountCollection).map((index) =>
        sumNumbers(passCountCollection[index]),
    );

    // reset any nan values to null so it doesn't get rendered
    Object.keys(averagedRuns).forEach((key) => {
        averagedRuns[key].forEach((value, i) => {
            if (isNaN(value)) {
                averagedRuns[key][i] = null;
            }
        });
    });
    return averagedRuns;
}

/**
 * Formats driverEvents into the s3 path for the API to download
 * @param driverEvents
 */
export function driverEventsToS3Path(driverEvents) {
    return driverEvents.map((driverEvent) => {
        const {
            UniqueDriverId,
            EquipmentId,
            DataFileStartOffset,
            DataFileEndOffset,
            ShiftId,
        } = driverEvent;
        return {
            path: `${ShiftId}/fleet/by-class/haultruckfleet/Productivity/${UniqueDriverId}/${EquipmentId}/Events.json`,
            dataFileStartOffset: DataFileStartOffset,
            dataFileEndOffset: DataFileEndOffset,
        };
    });
}

/**
 * Normalises driverEvent TotalLostTime by PassCount and sorts results
 * @param driverEvents {array} - collection of driverEvents for a ShiftProductivityEventId
 */
export function getDriversByLostTimeOverPasscount(driverEvents) {
    // Group the driverEvents by UniqueDriverId
    const groupedDriverEvents = arrayToGroupedObject(
        driverEvents,
        'UniqueDriverId',
    );

    // Normalize the TotalLostTime by Passcount, otherwise drivers who have only done it once
    // will likely have the least amount of time lost
    const driversByNormalizedTimelostOverPasscount = Object.keys(
        groupedDriverEvents,
    ).reduce((collection, uniqueDriverId) => {
        const { totalTime, totalPassCount } = groupedDriverEvents[
            uniqueDriverId
        ].reduce(
            (aggregation, next) => {
                aggregation.totalTime += next.TotalLostTime;
                aggregation.totalPassCount += next.PassCount;
                return aggregation;
            },
            {
                totalTime: 0,
                totalPassCount: 0,
            },
        );
        collection.push({
            totalLostTimeOverPassCount: roundTo(totalTime / totalPassCount, 0),
            driverEvents: groupedDriverEvents[uniqueDriverId],
            uniqueDriverId,
        });
        return collection;
    }, []);

    // Sorts the results Best to worst
    const sortedByTotalLostTimeOverPassCount = driversByNormalizedTimelostOverPasscount.sort(
        createMultiKeySortFn([
            { name: 'totalLostTimeOverPassCount', reverse: false },
        ]),
    );

    return sortedByTotalLostTimeOverPassCount;
}

/**
 * This normalises the total lost time as there is no such thing as a '0.9 pass' from the top level review of scores.
 * see DR-799 for more info
 * @param totalLostTime
 * @param passCount
 * @returns {number}
 */
export function normaliseTotalLostTimeOverPasscount(totalLostTime, passCount) {
    return roundTo((totalLostTime * Math.ceil(passCount)) / passCount, 0);
}
