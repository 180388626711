import {
    call,
    put,
    select,
    take,
    race,
    takeLatest,
    takeEvery,
    delay,
    all,
    fork,
} from 'redux-saga/effects';
import shiftUtils from '@rs/core/utils/shiftUtils';
import errorLogging from '@rs/core/utils/errorLogging';
import { MAP_LAYER_LABELS, message } from '@rs/core/falcon';
import isEmpty from 'lodash/isEmpty';
import { batchActions } from 'redux-batched-actions';
import * as resourceSagas from './resourceSagas';
import * as pageActions from '../ActivityManagement/Actions';
import * as mineSnapshotModule from '../ActivityManagement/Modules/mineSnapshot';
import * as circuitsOrderModule from '../ActivityManagement/Modules/circuitsOrder';
import { setItem } from '../Lib/sessionStorage';
import * as pendingTransactionsModule from '../ActivityManagement/Modules/pendingTransactions';
import * as circuitSuggestModule from '../ActivityManagement/Modules/circuitSuggest';
import * as editCircuitFormModule from '../ActivityManagement/Modules/editCircuitForm';
import * as circuitNodesModule from '../ActivityManagement/Modules/circuitNodes';
import * as fmsTruckAllocationEquipmentModule from '../ActivityManagement/Modules/fmsTruckAllocationEquipment';
import * as WKTAggregateModule from '../ActivityManagement/Modules/WKTAggregate';
import { parseCircuitFormToAPIModel } from '../ActivityManagement/Utils/parseCircuitFormToAPIModel';
import { isCircuitId, isHoldingStateId } from '../ActivityManagement/Utils/id';
import { getEntityDisplayName } from '../ActivityManagement/Utils/nodes';
import { RESOURCE_PATHS } from '../Api';
import getSyncStateToURL from '../ActivityManagement/selectors/getSyncStateToURL';
import * as minesiteMapModule from '../ActivityManagement/Modules/minesiteMap';
import queryString from '@rs/core/utils/queryStringUtils';
import * as actions from '../ActivityManagement/Actions';
import { actions as spinnerActions } from '../ActivityManagement/Modules/spinner';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import { actions as activityManagementActions } from '../ActivityManagement/Modules/mapFeatures_v2';
import polygonLayers from '../ActivityManagement/selectors/getMinesiteMapPolygonLayers';
//graphql
import { getGraphqlSdk } from './utils';
import { Sdk, GetMapFeaturesQuery } from '../Graphql/Services';
import { getLatestShiftId } from './resourceSagas';
import {
    getAllUniqueSpeedZones,
    getUniqueColorScales,
    getUniqueMaterialcolorScales,
    getUniqueMaterials,
} from '../Modules/mapFeatures_v2/helper';
import { actions as LiveMapLegendActions } from '../ActivityManagement/Modules/mapLegends';
import { Visibility } from '@rs/core/falcon/components/NewLegend/reduxUtil';
import { actions as liveMapLegendActions } from '../ActivityManagement/Modules/mapLegends';

export const MINESITE_POLLING_INTERVAL = 10000;
export const MAX_REQUEST_DURATION_TIMEOUT = 60000;

let currentLatestShiftId = null;

function* fetchMinesite() {
    const { response, error } = yield call(
        resourceSagas.getFMSTruckAllocationMinesite,
    );
    if (error) {
        yield put(
            mineSnapshotModule.actions.fetchMinesiteError({
                error: `Failed to fetch ${RESOURCE_PATHS.FMS_TRUCK_ALLOCATION_MINESITE}`,
            }),
        );
        return;
    }
    yield put(mineSnapshotModule.actions.fetchMinesiteSuccess(response));
}

function* fetchLocus(shiftId) {
    const { response, error } = yield call(resourceSagas.getWKTAggregate, {
        FirstShiftId: shiftId,
    });
    if (error) {
        yield put(
            WKTAggregateModule.actions.fetchWKTAggregateError({
                error: `Failed to fetch locus data`,
            }),
        );
        return;
    }
    yield put(WKTAggregateModule.actions.fetchWKTAggregateSuccess(response));
}

function* fetchLiveMap() {
    const { response } = yield call(resourceSagas.getLatestShiftId);
    const latestShiftId =
        response && Array.isArray(response) && response[0].ShiftId;
    if (currentLatestShiftId !== latestShiftId) {
        currentLatestShiftId = latestShiftId;
        yield fork(fetchLocus, latestShiftId);
    }

    const [truckAllocationEquipment, circuitNodes] = yield all([
        call(resourceSagas.getFMSTruckAllocationEquipment),
        call(resourceSagas.getFMSCircuitNodes),
    ]);
    if (circuitNodes.error) {
        yield put(
            circuitNodesModule.actions.getCircuitNodesError({
                error: `Failed to fetch ${RESOURCE_PATHS.FMS_CIRCUIT_NODES}`,
            }),
        );
    }
    if (truckAllocationEquipment.error) {
        yield put(
            fmsTruckAllocationEquipmentModule.actions.fetchFMSTruckALlocationEquipmentError(
                {
                    error: `Failed to fetch ${RESOURCE_PATHS.FMS_TRUCK_ALLOCATION_EQUIPMENT}`,
                },
            ),
        );
        return;
    }
    const now = shiftUtils.createMomentInSiteTime();
    yield put(
        batchActions(
            [
                fmsTruckAllocationEquipmentModule.actions.fetchFMSTruckALlocationEquipmentSuccess(
                    {
                        features: truckAllocationEquipment.response,
                        lastUpdated: now.unix(),
                    },
                ),
                !circuitNodes.error &&
                    circuitNodesModule.actions.getCircuitNodesSuccess(
                        circuitNodes.response,
                    ),
            ].filter(Boolean),
        ),
    );
}

function* patchTruckAllocation({ payload }) {
    // Start a transaction so we can set spinners early
    yield put(
        pendingTransactionsModule.actions.transactionStarted({
            referenceId: payload.id,
        }),
    );

    const { response, error } = yield call(
        resourceSagas.patchTruckAllocation,
        payload,
    );

    let details = {};
    // Try and find the name of the patched node (can be a circuit or holding state)
    if (isCircuitId(payload.id)) {
        details = yield select(
            mineSnapshotModule.selectors.getCircuitById,
            payload.id,
        );
    } else if (isHoldingStateId(payload.id)) {
        details = yield select(
            mineSnapshotModule.selectors.getHoldingStateById,
            payload.id,
        );
    }
    const nodeName = getEntityDisplayName(details);

    if (error) {
        errorLogging.logException(`Failed to update ${nodeName}`, error);
        message.error(`Failed to update ${nodeName}, please try again`);
    } else {
        message.success(`${nodeName} updated`);
        yield put(
            pendingTransactionsModule.actions.transactionPending({
                referenceId: payload.id,
                pendingRequestNum: response['pending-request-num'],
            }),
        );
    }
    yield call(fetchMinesite);
}

function* putTruckAllocation({ payload }) {
    // Start a transaction so we can set spinners early
    yield put(
        pendingTransactionsModule.actions.transactionStarted({
            referenceId: payload.id,
        }),
    );

    const { response, error } = yield call(
        resourceSagas.putTruckAllocation,
        payload,
    );
    const truckDetails = yield select(
        mineSnapshotModule.selectors.getTruckEquipmentById,
        payload.id,
    );
    const truckName = getEntityDisplayName(truckDetails);

    if (error) {
        errorLogging.logException(`Failed to update ${truckName}`, error);
        message.error(`Failed to update ${truckName}, please try again`);
    } else {
        message.success(`${truckName} updated`);
        yield put(
            pendingTransactionsModule.actions.transactionPending({
                referenceId: payload.id,
                pendingRequestNum: response['pending-request-num'],
            }),
        );
    }
    yield call(fetchMinesite);
}

function* deleteTruckAllocation({ payload }) {
    // Start a transaction so we can set spinners early
    yield put(
        pendingTransactionsModule.actions.transactionStarted({
            referenceId: payload.id,
        }),
    );

    const { response, error } = yield call(
        resourceSagas.deleteTruckAllocation,
        payload,
    );
    const circuitDetails = yield select(
        mineSnapshotModule.selectors.getCircuitById,
        payload.id,
    );
    const circuitName = getEntityDisplayName(circuitDetails);
    if (error) {
        errorLogging.logException(`Failed to delete ${circuitName}`, error);
        message.error(`Failed to delete ${circuitName}, please try again`);
    } else {
        message.success(`${circuitName} deleted`);
        yield put(
            pendingTransactionsModule.actions.transactionPending({
                referenceId: payload.id,
                pendingRequestNum: response['pending-request-num'],
            }),
        );
    }
    yield call(fetchMinesite);
}

/**
 * Store the circuits order in the sessionStorage so it can persist as long as the tab lives
 */
function* circuitsRowMoved() {
    const circuitsOrder = yield select(circuitsOrderModule.selectors.get);
    setItem('circuitsOrder', circuitsOrder);
}

export function* getCircuitSuggest({ payload: { source, destination } }) {
    const { response, error } = yield call(resourceSagas.getFMSCircuitSuggest, {
        SourceName: source,
        DestinationName: destination,
    });
    if (error) {
        errorLogging.logException(
            'Failed to load circuit node sequence',
            error,
        );
        yield put(
            circuitSuggestModule.actions.getCircuitSuggestError({
                error: 'Failed to load circuit node sequence',
            }),
        );
        return;
    }
    yield put(circuitSuggestModule.actions.getCircuitSuggestSuccess(response));
}

export function* submitCircuit() {
    const { values, form } = yield select(editCircuitFormModule.selectors.get);
    if (!form.isValid) return;
    const { nodeConfig } = yield select(circuitSuggestModule.selectors.get);
    const originalCircuit = yield select(
        mineSnapshotModule.selectors.getCircuitById,
        values.id,
    );

    // When creating a New circuit the suggested node config will exist.
    // Otherwise use the existing circuit config from /minesite as the base structure to submit
    const baseCircuitConfig = isEmpty(nodeConfig)
        ? originalCircuit
        : nodeConfig;
    const modifications = parseCircuitFormToAPIModel(values, baseCircuitConfig);

    // Start a transaction so we can set spinners early
    yield put(
        pendingTransactionsModule.actions.transactionStarted({
            referenceId: values.id,
        }),
    );

    const { response, error } = yield call(resourceSagas.putTruckAllocation, {
        id: values.id,
        modifications,
    });

    const circuitName = getEntityDisplayName(modifications);
    if (error) {
        errorLogging.logException(`Failed to update ${circuitName}`, error);
        message.error(`Failed to update ${circuitName}, please try again`);
    } else {
        message.success(`${circuitName} updated`);
        yield all([
            put(
                pendingTransactionsModule.actions.transactionPending({
                    referenceId: values.id,
                    pendingRequestNum: response['pending-request-num'],
                }),
            ),
            put(editCircuitFormModule.actions.resetForm()),
        ]);
    }
    yield call(fetchMinesite);
}

// Delay this function from finishing for 10 seconds
// to achieve polling at a 10 second interval
function* pollMinesite() {
    let retryCount = 0;
    while (true) {
        const { failed, timeout } = yield race({
            response: call(fetchMinesite),
            failed: take(mineSnapshotModule.actions.FETCH_MINESITE_ERROR),
            timeout: delay(MAX_REQUEST_DURATION_TIMEOUT),
        });

        // Polling has succeeded
        if (!failed && !timeout) {
            // Reset failed connection count
            retryCount = 0;
            // Do our 10 second delay
            yield delay(MINESITE_POLLING_INTERVAL);
        }

        // Avoid spaming the server if there are errors, for example if the Sim is down
        // It retries instantly and then backs off to a max of one minute retry interval
        if (failed || timeout) {
            const waitFor = Math.min(
                5000 * retryCount,
                MAX_REQUEST_DURATION_TIMEOUT,
            );
            yield delay(waitFor);
            retryCount += 1;
        }

        // Logging of errors & notifications
        if (timeout) {
            errorLogging.logException(
                'Fetching /minesite has timed out. This may indicate a connection error or a fault.',
            );
            message.error(
                'Failed to load data, this will automatically retry or you may refresh the page',
            );
        } else if (failed) {
            errorLogging.logException(`Fetching /minesite has failed.`);
            message.error(
                'Failed to load data, this will automatically retry or you may refresh the page',
            );
        }
    }
}

// Delay this function from finishing for 10 seconds
// to achieve polling at a 10 second interval
function* pollLiveMap() {
    let retryCount = 0;
    while (true) {
        const { failed, timeout } = yield race({
            response: call(fetchLiveMap),
            failed: take(
                fmsTruckAllocationEquipmentModule.actions
                    .FETCH_FMS_TRUCK_ALLOCATION_EQUIPMENT_ERROR,
            ),
            timeout: delay(MAX_REQUEST_DURATION_TIMEOUT),
        });

        // Polling has succeeded
        if (!failed && !timeout) {
            // Reset failed connection count
            retryCount = 0;
            // Do our 10 second delay
            yield delay(MINESITE_POLLING_INTERVAL);
        }

        // Avoid spaming the server if there are errors, for example if the Sim is down
        // It retries instantly and then backs off to a max of one minute retry interval
        if (failed || timeout) {
            const waitFor = Math.min(
                5000 * retryCount,
                MAX_REQUEST_DURATION_TIMEOUT,
            );
            yield delay(waitFor);
            retryCount += 1;
        }

        // Logging of errors & notifications
        if (timeout) {
            errorLogging.logException(
                `Fetching ${RESOURCE_PATHS.FMS_TRUCK_ALLOCATION_EQUIPMENT} has timed out. This may indicate a connection error or a fault.`,
            );
            message.error(
                'Failed to load data, this will automatically retry or you may refresh the page',
            );
        } else if (failed) {
            errorLogging.logException(
                `Fetching ${RESOURCE_PATHS.FMS_TRUCK_ALLOCATION_EQUIPMENT} has failed.`,
            );
            message.error(
                'Failed to load data, this will automatically retry or you may refresh the page',
            );
        }
    }
}

function* startPollingMinesite() {
    yield race({
        response: call(pollMinesite),
        cancel: take(pageActions.MINESITE_POLLING_CANCELLED),
    });
}

function* startPollingLiveMap() {
    yield race({
        response: call(pollLiveMap),
        cancel: take(pageActions.LIVE_MAP_POLLING_CANCELLED),
    });
}

export function* onFieldChange({ payload: { fieldName } }) {
    const { values } = yield select(editCircuitFormModule.selectors.get);
    if (
        (fieldName === 'source' || fieldName === 'destination') &&
        values.source &&
        values.destination
    ) {
        yield put(
            circuitSuggestModule.actions.getCircuitSuggestRequested({
                source: values.source,
                destination: values.destination,
            }),
        );
    }
}

function* createCircuit() {
    yield put(circuitNodesModule.actions.getCircuitNodesRequested());
    const { response, error } = yield call(resourceSagas.getFMSCircuitNodes);
    if (error) {
        yield put(circuitNodesModule.actions.getCircuitNodesError({ error }));
        return;
    }
    yield put(circuitNodesModule.actions.getCircuitNodesSuccess(response));
}

export function* syncStateToURL() {
    const state = yield select();
    const params = getSyncStateToURL(state);
    queryString.updateURLQueryString(params);
}

function* updateFiltersWithURLParams() {
    const { ActivityManagement } = yield select();
    // check if the mapfeatures already exists in the state
    const mapFeatures =
        ActivityManagement?.MAP_FEATURES?.speedZonesGeojson?.features;
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const params = queryString.convertURLQueryStringToObject();
    yield put(actions.syncURLToState(params));
    if (mapFeatures.length === 0) {
        yield call(prepareToFetchMapFeatures);
    }
    yield put(spinnerActions.setSpinnerState(false));
}

// map features

export function* prepareToFetchMapFeatures() {
    try {
        yield put(
            spinnerActions.setSpinnerState(
                true,
                LOADING_MESSAGES.LOADING__DATA,
            ),
        );
        const { response } = yield call(getLatestShiftId);
        const { ShiftId } = response[0];
        // get the graphql sdk
        const sdk: Sdk = getGraphqlSdk();
        const result: GetMapFeaturesQuery = yield call(sdk.GetMapFeatures, {
            input: {
                shiftIds: [`${ShiftId}`],
            },
        });

        const getFeaturesByShiftId = result?.getMapFeatures?.featuresByShiftId;

        const featuresForSelectedShift = getFeaturesByShiftId?.[0] as any;

        const {
            speedZonesGeojson,
            womidsGeojson,
            trackingRegionsGeojson,
            materialMovementsGeojson,
        } = featuresForSelectedShift;

        const emptyFeatureCollection = {
            type: 'FeatureCollection',
            features: [],
        };
        const parsedSpeedZonesGeojson =
            speedZonesGeojson === '' ||
            JSON.parse(speedZonesGeojson)?.features === null
                ? emptyFeatureCollection
                : JSON.parse(speedZonesGeojson);
        const parsedWomidsGeojson =
            womidsGeojson === '' || JSON.parse(womidsGeojson)?.features === null
                ? emptyFeatureCollection
                : JSON.parse(womidsGeojson);
        const parsedTrackingRegionsGeojson =
            trackingRegionsGeojson === '' ||
            JSON.parse(trackingRegionsGeojson)?.features === null
                ? emptyFeatureCollection
                : JSON.parse(trackingRegionsGeojson);
        const parsedMaterialMovementsGeojson =
            materialMovementsGeojson === '' ||
            JSON.parse(materialMovementsGeojson)?.features === null
                ? emptyFeatureCollection
                : JSON.parse(materialMovementsGeojson);

        yield all([
            put(
                activityManagementActions.setSpeedZonesGeojson(
                    parsedSpeedZonesGeojson,
                ),
            ),
            put(
                activityManagementActions.setWomidsGeojson(parsedWomidsGeojson),
            ),
            put(
                activityManagementActions.setTrackingRegionsGeojson(
                    parsedTrackingRegionsGeojson,
                ),
            ),
            put(
                activityManagementActions.setMaterialMovementsGeojson(
                    parsedMaterialMovementsGeojson,
                ),
            ),
        ]);
        // get the state of the maplayers
        const {
            speedRestrictedZones,
            materialMovement,
            mineFeatures,
            mineRegions,
        } = yield select(polygonLayers);

        // set map legend sections
        yield put(
            LiveMapLegendActions.SetSections([
                {
                    label: MAP_LAYER_LABELS.speedLimitedZones,
                    visibility: speedRestrictedZones,
                    items: Object.entries(
                        getUniqueColorScales(
                            getAllUniqueSpeedZones(
                                parsedSpeedZonesGeojson,
                            ) as any,
                        ),
                    ).map(([key, val]) => {
                        return {
                            label: `${key}kph`,
                            borderColor: `${val}`,
                            fillColor: `${val}`,
                            visibility: 'VISIBLE',
                        };
                    }),
                },
                {
                    label: MAP_LAYER_LABELS.materialMovement,
                    visibility: materialMovement,
                    items: Object.entries(
                        getUniqueMaterialcolorScales(
                            getUniqueMaterials(
                                parsedMaterialMovementsGeojson,
                            ) as any,
                        ),
                    ).map(([key, val]) => {
                        return {
                            label: key,
                            borderColor: val,
                            fillColor: val,
                            visibility: 'VISIBLE',
                        };
                    }),
                },
                {
                    /* any polygon that has Maintenance, TyreBay or Workshop in them will be categorised as Maintenence
                   anything else is ancilary/goline
                */

                    label: MAP_LAYER_LABELS.trackingRegions,
                    visibility: mineFeatures,
                    items: [
                        {
                            label: 'Maintenance',
                            borderColor: '#45818e',
                            fillColor: '#45818e',
                            visibility: 'VISIBLE',
                        },

                        {
                            label: 'Ancillary/GoLine',
                            borderColor: '#ff9900',
                            fillColor: '#ff9900',
                            visibility: 'VISIBLE',
                        },
                    ],
                },
                {
                    label: MAP_LAYER_LABELS.mineRegions,
                    visibility: mineRegions,
                    items: [
                        {
                            label: 'MineRegions',
                            borderColor: '#000000',
                            fillColor: '',
                            visibility: 'VISIBLE',
                        },
                    ],
                },
            ]),
        );
        yield put(spinnerActions.setSpinnerState(false));
    } catch (error) {
        yield put(spinnerActions.setSpinnerState(false));
        throw new Error(error.message);
    }
}

type FlipLegendPayload = {
    payload: { sectionLabel: string; visibility: Visibility };
};

export function* flipLegendLayer({ payload }: FlipLegendPayload) {
    try {
        // fire action to toggle legend layer
        yield put(liveMapLegendActions.ChangeSectionVisibility(payload));
    } catch (error) {
        throw new Error(error.message);
    }
}

export default function* watch() {
    yield all([
        takeLatest(pageActions.MINESITE_POLLING_STARTED, startPollingMinesite),
        takeLatest(pageActions.LIVE_MAP_POLLING_STARTED, startPollingLiveMap),
        takeLatest(
            circuitSuggestModule.actions.GET_CIRCUIT_SUGGEST_REQUESTED,
            getCircuitSuggest,
        ),
        takeEvery(editCircuitFormModule.actions.ON_FIELD_CHANGE, onFieldChange),
        takeEvery(
            [
                pageActions.MOVE_TRUCK_REQUESTED,
                pageActions.PUT_HOLDING_STATE_REQUESTED,
            ],
            putTruckAllocation,
        ),
        takeEvery(
            [
                pageActions.PATCH_CIRCUIT_REQUESTED,
                pageActions.PATCH_HOLDING_STATE_REQUESTED,
            ],
            patchTruckAllocation,
        ),
        takeEvery(
            circuitsOrderModule.actions.CIRCUITS_ROW_MOVED,
            circuitsRowMoved,
        ),
        takeEvery(pageActions.DELETE_CIRCUIT, deleteTruckAllocation),
        takeEvery(editCircuitFormModule.actions.CREATE_CIRCUIT, createCircuit),
        takeLatest(editCircuitFormModule.actions.SUBMIT_CIRCUIT, submitCircuit),
        takeEvery(
            [
                minesiteMapModule.actions.MAP_VIEWPORT_CHANGED,
                minesiteMapModule.actions.MAP_FILTER_UPDATED,
            ],
            syncStateToURL,
        ),
        takeEvery(
            pageActions.UPDATE_MAP_FILTERS_WITH_URL_PARAMS,
            updateFiltersWithURLParams,
        ),
        takeEvery(pageActions.MAP_LEGEND_LAYER_FLIP, flipLegendLayer),
    ]);
}
