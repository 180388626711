import * as React from 'react';

function SvgStatusQueuing(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <circle cx={512} cy={512} r={498.84} fill="#fff" />
            <path d="M512 50a462.14 462.14 0 01179.82 887.71A462.13 462.13 0 01332.18 86.29 459.06 459.06 0 01512 50m0-50C229.23 0 0 229.23 0 512s229.23 512 512 512 512-229.23 512-512S794.77 0 512 0z" />
            <path d="M700.68 504.25L565 366.74c-5.06-5.11-12.48-.5-12.48 7.75v43.43a10 10 0 002.89 7l78.24 79.3a11.09 11.09 0 010 15.5l-78.24 79.3a10 10 0 00-2.89 7v43.44c0 8.24 7.42 12.85 12.48 7.74l135.68-137.45c3.88-3.94 3.88-11.56 0-15.5zM332.88 366.74c-5.06-5.11-12.47-.5-12.47 7.75v275c0 8.24 7.41 12.85 12.47 7.74l135.68-137.48c3.89-3.94 3.89-11.56 0-15.5z" />
        </svg>
    );
}

export default SvgStatusQueuing;
