import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@rs/core/falcon';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import { LOADING_MESSAGES } from '../../Components/LoadingSpinner';

const HelpPageContent = ({
    helpPageContent,
    contentId,
    isInProgress,
    hasError,
}) => {
    let content;
    if (isInProgress) {
        content = (
            <LoadingSpinner
                message={LOADING_MESSAGES.LOADING__DATA}
                isActive={true}
            />
        );
    } else if (hasError || !contentId || helpPageContent === '') {
        // It's possible the content was loaded but it might be empty
        content = (
            <Alert
                message="Page not found"
                description="Sorry we couldn't find the page your looking for."
                type="error"
            />
        );
    } else {
        content = (
            <React.Fragment>
                <div
                    dangerouslySetInnerHTML={{
                        __html: helpPageContent,
                    }}
                />
            </React.Fragment>
        );
    }
    return content;
};

HelpPageContent.propTypes = {
    contentId: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
    ]),
    helpPageContent: PropTypes.string,
    isInProgress: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired,
};

export default HelpPageContent;
