import React from 'react';
import cn from 'classnames';
import { LoadUnitImprovementSymbolProps } from './types';
import LoadUnitSymbolPayloadUnderload from '../../assets/js/LoadUnitSymbolPayloadUnderload';

const PayloadIncreasePayloadsSymbol = ({
    className,
}: LoadUnitImprovementSymbolProps) => {
    return (
        <LoadUnitSymbolPayloadUnderload
            className={cn('LoadUnitImprovementSymbol__Icon', className)}
        />
    );
};

export default PayloadIncreasePayloadsSymbol;
