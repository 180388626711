import createActions from '../../Modules/APIArrayResponse/actions';
import createReducer from '../../Modules/APIArrayResponse/reducer';
import createSelectors from '../../Modules/APIArrayResponse/selectors';
import { getLoadingEventsAggregate } from '../../Sagas/resourceSagas';
import namespace from './namespace';

export const name = 'loadingEventsAggregate';
export const request = getLoadingEventsAggregate;

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace][name];

/**
 * Create the module parts
 */
export const actions = createActions(namespace, name);
export const reducer = createReducer({
    onRequest: actions.API_REQUESTED,
    onSuccess: actions.API_SUCCESS,
    onError: actions.API_ERROR,
});
export const selectors = createSelectors(statePath);
