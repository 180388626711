import { action, createReducer } from '../../../Lib/reduxUtils';
import namespace from './namespace';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].maxMineAlertCategories;

/**
 * Create the module parts
 */
function createActions(ns) {
    const FETCH_MAX_MINE_ALERT_CATEGORIES_SUCCESS = `${ns}/FETCH_MAX_MINE_ALERT_CATEGORIES_SUCCESS`;
    const fetchMaxMineAlertCategoriesSuccess = (resultsArray) =>
        action(FETCH_MAX_MINE_ALERT_CATEGORIES_SUCCESS, resultsArray);

    const FETCH_MAX_MINE_ALERT_CATEGORIES_ERROR = `${ns}/FETCH_MAX_MINE_ALERT_CATEGORIES_ERROR`;
    const fetchMaxMineAlertCategoriesError = ({ error }) =>
        action(FETCH_MAX_MINE_ALERT_CATEGORIES_ERROR, { error });

    return {
        FETCH_MAX_MINE_ALERT_CATEGORIES_SUCCESS,
        fetchMaxMineAlertCategoriesSuccess,
        FETCH_MAX_MINE_ALERT_CATEGORIES_ERROR,
        fetchMaxMineAlertCategoriesError,
    };
}
export const actions = createActions(namespace);

const initialState = [];
export const reducer = createReducer(initialState, {
    [actions.FETCH_MAX_MINE_ALERT_CATEGORIES_SUCCESS]: (state, payload) =>
        utils.getUniqueMaxMineAlertCategories(payload),
    [actions.FETCH_MAX_MINE_ALERT_CATEGORIES_ERROR]: () => initialState,
});

export const selectors = {
    getAll(state) {
        return statePath(state);
    },
};

export const utils = {
    getUniqueMaxMineAlertCategories(truckAlerts) {
        return Array.from(
            truckAlerts.reduce(
                (set, row) => set.add(row.MaxMineAlertCategory),
                new Set(),
            ),
        );
    },
};
