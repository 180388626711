import { createSelector } from 'reselect';
import { selectors as minesiteMapSelectors } from '../Modules/minesiteMap';
import getFilters from './filters/getFilters';

/**
 * This should return a serialisable object that will be encoded in the url
 * query params
 */
const getSyncStateToURL = createSelector(
    minesiteMapSelectors.get,
    getFilters,
    (minesiteMap, filters) => {
        return {
            ...minesiteMap,
            ...filters,
        };
    },
);

export default getSyncStateToURL;
