import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace, apiNamespace = '') {
    const prefix = (item) => `${namespace}/${apiNamespace}/${item}`;

    const API_REQUESTED = prefix('API_REQUESTED');
    const request = () => action(API_REQUESTED);

    const API_SUCCESS = prefix('API_SUCCESS');
    const success = (results) => action(API_SUCCESS, results);

    const API_ERROR = prefix('API_ERROR');
    const error = (errorMessage) => action(API_ERROR, errorMessage);

    return {
        API_REQUESTED,
        request,
        API_SUCCESS,
        success,
        API_ERROR,
        error,
    };
}
