import { shallowEqual } from 'react-redux';

/**
 * A helper function to call shouldComponentUpdateUtil if provided by the consumer of the Component
 *
 * @param nextProps
 * @param nextProps
 * @return {*}
 */
// Note: this would probably normally be done as a HOC, however I didn't want to introduce
// extra layers of components into the leaflet hierarchy as I want to render the minimal amount of Components
// and to avoid having to deal with more prop forwarding. Also soon we can write this as a Hook instead of HOC really soon.
export default function shouldComponentUpdate(currProps, nextProps) {
    if (!nextProps.shouldComponentUpdate) {
        return !shallowEqual(currProps, nextProps);
    }
    return nextProps.shouldComponentUpdate(currProps, nextProps);
}
