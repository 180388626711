import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_TRUCK_ALERT_EVENTS_BY_SHIFT_ID_SUCCESS = `${namespace}/FETCH_TRUCK_ALERT_EVENTS_BY_SHIFT_ID_SUCCESS`;
    const fetchTruckAlertEventsByShiftIdSuccess = (resultsArray) =>
        action(FETCH_TRUCK_ALERT_EVENTS_BY_SHIFT_ID_SUCCESS, resultsArray);

    const FETCH_TRUCK_ALERT_EVENTS_BY_SHIFT_ID_ERROR = `${namespace}/FETCH_TRUCK_ALERT_EVENTS_BY_SHIFT_ID_ERROR`;
    const fetchTruckAlertEventsByShiftIdError = ({ error }) =>
        action(FETCH_TRUCK_ALERT_EVENTS_BY_SHIFT_ID_ERROR, { error });

    return {
        FETCH_TRUCK_ALERT_EVENTS_BY_SHIFT_ID_SUCCESS,
        fetchTruckAlertEventsByShiftIdSuccess,
        FETCH_TRUCK_ALERT_EVENTS_BY_SHIFT_ID_ERROR,
        fetchTruckAlertEventsByShiftIdError,
    };
}
