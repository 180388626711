import { action, createReducer } from '../../Lib/reduxUtils';
import namespace from './namespace';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].editForm;

/**
 * Create the module parts - Actions
 */
const SUBMIT_EDIT_USER = `${namespace}/SUBMIT_EDIT_USER`;
const submitEditUser = (userInfo) => {
    return action(SUBMIT_EDIT_USER, { userInfo });
};

const EDIT_FORM_RESET = `${namespace}/EDIT_FORM_RESET`;
const editFormReset = () => action(EDIT_FORM_RESET);

const EDIT_FORM_SUCCESS = `${namespace}/EDIT_FORM_SUCCESS`;
const editFormSuccess = (apiSuccessMessage) => {
    return action(EDIT_FORM_SUCCESS, apiSuccessMessage);
};

const EDIT_FORM_ERROR = `${namespace}/EDIT_FORM_ERROR`;

const editFormError = (apiErrorMessage) => {
    return action(EDIT_FORM_ERROR, apiErrorMessage);
};

export const actions = {
    SUBMIT_EDIT_USER,
    submitEditUser,
    EDIT_FORM_RESET,
    editFormReset,
    EDIT_FORM_ERROR,
    editFormError,
    EDIT_FORM_SUCCESS,
    editFormSuccess,
};

/**
 * Create the module parts - Reducer
 */
const initialState = {
    errorMessage: '',
    apiSuccessMessage: '',
};

function reset() {
    return initialState;
}

function setEditFormError(state, error) {
    return {
        ...state,
        errorMessage: error,
        apiSuccessMessage: '',
    };
}

function setEditFormSuccess(state, apiSuccessMessage) {
    return {
        ...state,
        errorMessage: '',
        apiSuccessMessage,
    };
}

export const reducer = createReducer(initialState, {
    [EDIT_FORM_RESET]: reset,
    [SUBMIT_EDIT_USER]: reset,
    [EDIT_FORM_ERROR]: setEditFormError,
    [EDIT_FORM_SUCCESS]: setEditFormSuccess,
});

/**
 * Create the module parts - Selectors
 */
const getEditForm = (state) => statePath(state);
const getApiErrorMessage = (state) => state.EditUser.editForm.errorMessage;
const getApiSuccessMessage = (state) =>
    state.EditUser.editForm.apiSuccessMessage;
const getCurrentUserEmail = (state) => state.Auth.decodedToken.email;
export const selectors = {
    getCurrentUserEmail,
    getEditForm,
    getApiErrorMessage,
    getApiSuccessMessage,
};
