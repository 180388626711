import { FEATURE_IDS } from '@rs/core/features';

export default {
    metrics: {
        air_filter_1_pressure_loss: {
            key: 'air_filter_1_pressure_loss',
            label: 'Air filter 1 pressure loss',
            category_id: '3',
            description: 'Differential pressure across air filter 1',
        },
        air_filter_2_pressure_loss: {
            key: 'air_filter_2_pressure_loss',
            label: 'Air filter 2 pressure loss',
            category_id: '3',
            description: 'Differential pressure across air filter 2',
        },
        air_filter_3_pressure_loss: {
            key: 'air_filter_3_pressure_loss',
            label: 'Air filter 3 pressure loss',
            category_id: '3',
            description: 'Differential pressure across air filter 3',
        },
        air_filter_4_pressure_loss: {
            key: 'air_filter_4_pressure_loss',
            label: 'Air filter 4 pressure loss',
            category_id: '3',
            description: 'Differential pressure across air filter 4',
        },
        ambient_air_temp: {
            key: 'ambient_air_temp',
            label: 'Ambient air temp',
            category_id: '1',
            description: 'Temperature of the air surrounding the vehicle',
        },
        auto_retarder_state: {
            key: 'auto_retarder_state',
            label: 'Auto retarder state',
            category_id: '1',
            description: '',
            featureId:
                FEATURE_IDS.SHIFT_REPLAY_TOOL__ENABLE_METRIC__auto_retarder_state,
        },
        c_o_m_p_x_y_speed: {
            key: 'c_o_m_p_x_y_speed',
            label: 'Speed',
            category_id: '1',
            description: 'Vehicle speed in the X-Y plane',
        },
        c_o_m_p_x_y_accel_l_p_f: {
            key: 'c_o_m_p_x_y_accel_l_p_f',
            label: 'Acceleration',
            category_id: '1',
            description: '',
        },
        d_fuel: {
            key: 'd_fuel',
            label: 'Fuel Consumption Rate',
            category_id: '2',
            description: 'The volume of fuel consumed per second',
        },
        engine_fuel_level: {
            key: 'engine_fuel_level',
            label: 'Fuel Tank Level',
            category_id: '2',
            description: 'The p[ercentage of fuel remaining in the fuel tank',
        },
        engine_fuel_filter_pressure_differential: {
            key: 'engine_fuel_filter_pressure_differential',
            label: 'Fuel Filter Pressure Differential',
            category_id: '2',
            description: 'Pressure differential across the fuel filter',
        },

        engine_crankcase_pressure: {
            key: 'engine_crankcase_pressure',
            label: 'Engine crankcase pressure',
            category_id: '2',
            description: 'Gauge pressure inside engine crankcase',
        },
        engine_desired_speed: {
            key: 'engine_desired_speed',
            label: 'Engine desired speed',
            category_id: '2',
            description:
                'Optimal operating speed of the engine under prevailing conditions, as indicated by the engine control module',
        },
        engine_fuel_pressure: {
            key: 'engine_fuel_pressure',
            label: 'Engine fuel pressure',
            category_id: '2',
            description:
                'Gauge pressure of fuel in the fuel system, as delivered by the fuel supply pump',
        },
        engine_injector_metering_rail_1_pressure: {
            key: 'engine_injector_metering_rail_1_pressure',
            label: 'Engine injector metering rail 1 pressure',
            category_id: '2',
            description:
                'Gauge pressure of fuel in the primary metering rail, as delivered from the fuel supply pump',
        },
        engine_intake_manifold_1_temperature: {
            key: 'engine_intake_manifold_1_temperature',
            label: 'Engine intake manifold 1 temperature',
            category_id: '2',
            description:
                'Intake air temperture at the manifold (pre-combustion)',
        },
        engine_load: {
            key: 'engine_load',
            label: 'Engine load',
            category_id: '2',
            description:
                'The ratio of the output torque to the maximum available torque at a given engine speed, a measure of engine output',
        },
        engine_oil_pressure: {
            key: 'engine_oil_pressure',
            label: 'Engine oil pressure',
            category_id: '2',
            description:
                'Gauge pressure of the oil in the engine lubrication system',
        },
        engine_percent_torque: {
            key: 'engine_percent_torque',
            label: 'Engine percent torque',
            category_id: '2',
            description:
                'Calculated output torque of the engine torque as a percentage of a reference torque',
        },
        engine_speed: {
            key: 'engine_speed',
            label: 'Engine speed',
            category_id: '2',
            description: 'Rotational speed of engine crankshaft',
        },
        exhaust_gas_temperature_1: {
            key: 'exhaust_gas_temperature_1',
            label: 'Exhaust gas temperature 1',
            category_id: '3',
            description:
                'Temperature of combustion by-products, as measured by exhaust gas temperature sensor #1',
        },
        exhaust_gas_temperature_2: {
            key: 'exhaust_gas_temperature_2',
            label: 'Exhaust gas temperature 2',
            category_id: '3',
            description:
                'Temperature of combustion by-products, as measured by exhaust gas temperature sensor #2',
        },
        l_f_brake_oil_temperature: {
            key: 'l_f_brake_oil_temperature',
            label: 'Left front brake oil temperature',
            category_id: '1',
            description: '',
            featureId:
                FEATURE_IDS.SHIFT_REPLAY_TOOL__ENABLE_METRIC__l_f_brake_oil_temperature,
        },
        l_r_brake_oil_temperature: {
            key: 'l_r_brake_oil_temperature',
            label: 'Left rear brake oil temperature',
            category_id: '1',
            description: '',
            featureId:
                FEATURE_IDS.SHIFT_REPLAY_TOOL__ENABLE_METRIC__l_r_brake_oil_temperature,
        },
        left_rear_strut_pressure: {
            key: 'left_rear_strut_pressure',
            label: 'Left rear strut pressure',
            category_id: '4',
            description: 'Fluid pressure in the left rear strut assembly',
        },
        left_wheel_speed: {
            key: 'left_wheel_speed',
            label: 'Left wheel speed',
            category_id: '1',
            description: 'Left rear wheel rotational speed',
        },
        left_front_strut_pressure: {
            key: 'left_front_strut_pressure',
            label: 'Left front strut pressure',
            category_id: '4',
            description: 'Fluid pressure in the left front strut assembly',
        },
        manual_retarder_state: {
            key: 'manual_retarder_state',
            label: 'Manual retarder state',
            category_id: '1',
            description: '',
            featureId:
                FEATURE_IDS.SHIFT_REPLAY_TOOL__ENABLE_METRIC__manual_retarder_state,
        },
        manual_retarder: {
            key: 'manual_retarder',
            label: 'Manual retarder',
            category_id: '1',
            description: '',
            featureId:
                FEATURE_IDS.SHIFT_REPLAY_TOOL__ENABLE_METRIC__manual_retarder,
        },
        park_brake: {
            key: 'park_brake',
            label: 'Park brake',
            category_id: '1',
            description: '',
            featureId: FEATURE_IDS.SHIFT_REPLAY_TOOL__ENABLE_METRIC__park_brake,
        },
        reported_payload: {
            key: 'reported_payload',
            label: 'Payload',
            category_id: '1',
            description: '',
        },
        r_f_brake_oil_temperature: {
            key: 'r_f_brake_oil_temperature',
            label: 'Right front brake oil temperature',
            category_id: '1',
            description: '',
            featureId:
                FEATURE_IDS.SHIFT_REPLAY_TOOL__ENABLE_METRIC__r_f_brake_oil_temperature,
        },
        r_r_brake_oil_temperature: {
            key: 'r_r_brake_oil_temperature',
            label: 'Right rear brake oil temperature',
            category_id: '1',
            description: '',
            featureId:
                FEATURE_IDS.SHIFT_REPLAY_TOOL__ENABLE_METRIC__r_r_brake_oil_temperature,
        },
        retarder_brake_light: {
            key: 'retarder_brake_light',
            label: 'Retarder brake light',
            category_id: '1',
            description: 'True when the Retarder Brake light is on',
        },
        retarder: {
            key: 'retarder',
            label: 'Retarder',
            category_id: '1',
            description:
                'The ratio of current engine retard force to maximum retard force available',
        },
        right_front_strut_pressure: {
            key: 'right_front_strut_pressure',
            label: 'Right front strut pressure',
            category_id: '4',
            description: 'Fluid pressure in the right front strut assembly',
        },
        right_rear_strut_pressure: {
            key: 'right_rear_strut_pressure',
            label: 'Right rear strut pressure',
            category_id: '4',
            description: 'Fluid pressure in the right rear strut assembly',
        },
        right_wheel_speed: {
            key: 'right_wheel_speed',
            label: 'Right wheel speed',
            category_id: '1',
            description: 'Right rear wheel rotational speed',
        },
        throttle_position: {
            key: 'throttle_position',
            label: 'Throttle position',
            category_id: '2',
            description:
                'How open the throttle valve is. 0% means the valve is closed and 100% means the valve is fully open',
        },
        trans_current_gear: {
            key: 'trans_current_gear',
            label: 'Current Gear',
            category_id: '2',
            description: 'The actual gear engaged in the transmission',
        },
        trans_selected_gear: {
            key: 'trans_selected_gear',
            label: 'Selected gear',
            category_id: '2',
            description:
                'The transmission gear that has been selected. This can differ from the Current Gear if the truck is in the process of shifting or is waiting for a torque reduction before shifting',
        },
        turbo_1_inlet_pressure: {
            key: 'turbo_1_inlet_pressure',
            label: 'Turbo 1 inlet pressure',
            category_id: '3',
            description:
                'Gauge pressure of the air upstream of the turbo #1 compressor',
        },
        turbo_2_inlet_pressure: {
            key: 'turbo_2_inlet_pressure',
            label: 'Turbo 2 inlet pressure',
            category_id: '3',
            description:
                'Gauge pressure of the air upstream of the turbo #2 compressor',
        },
        turbo_3_inlet_pressure: {
            key: 'turbo_3_inlet_pressure',
            label: 'Turbo 3 inlet pressure',
            category_id: '3',
            description:
                'Gauge pressure of the air upstream of the turbo #3 compressor',
        },
        turbo_4_inlet_pressure: {
            key: 'turbo_4_inlet_pressure',
            label: 'Turbo 4 inlet pressure',
            category_id: '3',
            description:
                'Gauge pressure of the air upstream of the turbo #4 compressor',
        },
        turbo_boost_pressure: {
            key: 'turbo_boost_pressure',
            label: 'Turbo boost pressure',
            category_id: '2',
            description:
                'Gauge pressure at compressor discharge side of the turbocharger',
        },
        spray_valve_left: {
            key: 'spray_valve_left',
            label: 'Spray valve left',
            category_id: '5',
            description:
                'Indicates when the left water spray valve is active or inactive',
        },
        spray_valve_centre: {
            key: 'spray_valve_centre',
            label: 'Spray valve centre',
            category_id: '5',
            description:
                'Indicates when the centre water spray valve is active or inactive',
        },
        spray_valve_right: {
            key: 'spray_valve_right',
            label: 'Spray valve right',
            category_id: '5',
            description:
                'Indicates when the left water spray valve is active or inactive',
        },
        water_pump_active: {
            key: 'water_pump_active',
            label: 'Water pump',
            category_id: '5',
            description: 'Indicates when the water pump is active or inactive',
        },
    },
    metric_categories: {
        '1': {
            label: 'Vehicle & Operator',
        },
        '2': {
            label: 'Engine & Fuel',
        },
        '3': {
            label: 'Inlet & Exhaust',
        },
        '4': {
            label: 'Suspension',
        },
        '5': {
            label: 'Water Truck',
        },
    },
};
