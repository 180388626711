import SpeedLimitedZonesLegend from './SpeedLimitedZonesLegend';

const createSpeedLimitedZonesLegend = (speedLimitedZones) => {
    const groupedZones = speedLimitedZones.reduce((a, b) => {
        a[b.SignPostedSpeedLimitUpper] = b.Color;
        return a;
    }, {});
    return Object.keys(groupedZones).map((speedLimitUpper) => ({
        limit: speedLimitUpper,
        color: groupedZones[speedLimitUpper],
    }));
};

export { SpeedLimitedZonesLegend, createSpeedLimitedZonesLegend };
