import React from 'react';
import PropTypes from 'prop-types';
import { typeConformanceLegendRangeBands } from '../../types';
import ConformanceLegend from '../ConformanceLegend/ConformanceLegend';
import PayloadGoodJobSymbol from '../LoadUnitImprovementSymbols/PayloadGoodJobSymbol';
import PayloadIncreasePayloadsSymbol from '../LoadUnitImprovementSymbols/PayloadIncreasePayloadsSymbol';
import PayloadDecreasePayloadsSymbol from '../LoadUnitImprovementSymbols/PayloadDecreasePayloadsSymbol';
import PayloadImproveConsistencySymbol from '../LoadUnitImprovementSymbols/PayloadImproveConsistencySymbol';
import PayloadBiasGoodJobSymbol from '../LoadUnitImprovementSymbols/PayloadBiasGoodJobSymbol';
import PayloadBiasImproveLongitudinalConsistencySymbol from '../LoadUnitImprovementSymbols/PayloadBiasImproveLongitudinalConsistencySymbol';
import PayloadBiasImproveLateralConsistencySymbol from '../LoadUnitImprovementSymbols/PayloadBiasImproveLateralConsistencySymbol';
import PayloadBiasDirectionSymbol, {
    ARROW_DIRECTIONS,
} from '../LoadUnitImprovementSymbols/PayloadBiasDirectionSymbol';

const LoadUnitSymbologyLegend = ({
    conformanceLegendRangeBands,
    renderAnnotations,
}) => {
    const loadsInRangeKey = [
        { Component: <PayloadGoodJobSymbol />, label: 'On Target' },
        {
            Component: <PayloadIncreasePayloadsSymbol />,
            label: 'Increase Payload',
        },
        {
            Component: <PayloadDecreasePayloadsSymbol />,
            label: 'Decrease Payload',
        },
        {
            Component: <PayloadImproveConsistencySymbol />,
            label: 'Improve Consistency',
        },
    ];

    const biasLoadsKey = [
        { Component: <PayloadBiasGoodJobSymbol />, label: 'On Target' },
    ];

    const improveConsistencyKey = [
        {
            Component: <PayloadBiasImproveLongitudinalConsistencySymbol />,
            label: 'Improve Truck Fore-Aft Consistency',
        },
        {
            Component: <PayloadBiasImproveLateralConsistencySymbol />,
            label: 'Improve Truck Lateral Consistency',
        },
    ];

    const directionKeys = [
        {
            Component: (
                <PayloadBiasDirectionSymbol
                    arrowDirection={ARROW_DIRECTIONS.OFFSIDE}
                />
            ),
            label: 'To Offside',
        },
        {
            Component: (
                <PayloadBiasDirectionSymbol
                    arrowDirection={ARROW_DIRECTIONS.ONSIDE}
                />
            ),
            label: 'To Onside',
        },
        {
            Component: (
                <PayloadBiasDirectionSymbol
                    arrowDirection={ARROW_DIRECTIONS.HEADBOARD}
                />
            ),
            label: 'To Headboard',
        },
        {
            Component: (
                <PayloadBiasDirectionSymbol
                    arrowDirection={ARROW_DIRECTIONS.REAR_OF_TRAY}
                />
            ),
            label: 'To Rear of Tray',
        },
        {
            Component: (
                <PayloadBiasDirectionSymbol
                    arrowDirection={ARROW_DIRECTIONS.TRUCK_FRONT_RIGHT}
                />
            ),
            label: 'To Truck Front-Right',
        },
        {
            Component: (
                <PayloadBiasDirectionSymbol
                    arrowDirection={ARROW_DIRECTIONS.TRUCK_FRONT_LEFT}
                />
            ),
            label: 'To Truck Front-Left',
        },
        {
            Component: (
                <PayloadBiasDirectionSymbol
                    arrowDirection={ARROW_DIRECTIONS.TRUCK_BACK_RIGHT}
                />
            ),
            label: 'To Truck Back-Right',
        },
        {
            Component: (
                <PayloadBiasDirectionSymbol
                    arrowDirection={ARROW_DIRECTIONS.TRUCK_BACK_LEFT}
                />
            ),
            label: 'To Truck Back-Left',
        },
    ];

    return (
        <div className="LoadUnitSymbologyLegend__Container">
            <div className="LoadUnitSymbologyLegend">
                <div className="LoadUnitSymbologyLegend__LeftColumn LoadUnitSymbologyLegend__LeftColumn--Border">
                    <div className="LoadUnitSymbologyLegend__Title">
                        Loads In Range Key
                    </div>
                    {loadsInRangeKey.map((row) => (
                        <div
                            key={row.label}
                            className="LoadUnitSymbologyLegend__Row"
                        >
                            <div className="LoadUnitSymbologyLegend__Icon">
                                {row.Component}
                            </div>
                            <div className="LoadUnitSymbologyLegend__Label">
                                {row.label}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="LoadUnitSymbologyLegend__RightColumn">
                    <div className="LoadUnitSymbologyLegend__RightColumnInnerContainer">
                        <div className="LoadUnitSymbologyLegend__LeftColumn">
                            <div className="LoadUnitSymbologyLegend__Title">
                                Bias Loads Key
                            </div>
                            {biasLoadsKey.map((row) => (
                                <div
                                    key={row.label}
                                    className="LoadUnitSymbologyLegend__Row"
                                >
                                    <div className="LoadUnitSymbologyLegend__Icon">
                                        {row.Component}
                                    </div>
                                    <div className="LoadUnitSymbologyLegend__Label">
                                        {row.label}
                                    </div>
                                </div>
                            ))}
                            <div className="LoadUnitSymbologyLegend__Title LoadUnitSymbologyLegend__Title--Minor">
                                Improve Consistency
                            </div>
                            {improveConsistencyKey.map((row) => (
                                <div
                                    key={row.label}
                                    className="LoadUnitSymbologyLegend__Row"
                                >
                                    <div className="LoadUnitSymbologyLegend__Icon">
                                        {row.Component}
                                    </div>
                                    <div className="LoadUnitSymbologyLegend__Label">
                                        {row.label}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="LoadUnitSymbologyLegend__RightColumn">
                            <div className="LoadUnitSymbologyLegend__Title LoadUnitSymbologyLegend__Title--Minor">
                                Improve Scores by Aiming Your Load
                            </div>
                            <div className="LoadUnitSymbologyLegend__ImproveScores">
                                {directionKeys.map((row) => (
                                    <div
                                        key={row.label}
                                        className="LoadUnitSymbologyLegend__ImproveScoresRowContainer"
                                    >
                                        <div className="LoadUnitSymbologyLegend__Row">
                                            <div className="LoadUnitSymbologyLegend__Icon">
                                                {row.Component}
                                            </div>
                                            <div className="LoadUnitSymbologyLegend__Label">
                                                {row.label}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="LoadUnitSymbologyLegend__ColourRow">
                <div className="LoadUnitSymbologyLegend__Title">Colour Key</div>
                <div className="LoadUnitSymbologyLegend__Conformance">
                    <ConformanceLegend
                        rangeBands={conformanceLegendRangeBands}
                    />
                </div>
                <div className={'LoadUnitSymbologyLegend__Annotation'}>
                    {renderAnnotations()}
                </div>
            </div>
        </div>
    );
};

LoadUnitSymbologyLegend.defaultProps = {
    renderAnnotations: () => null,
};

LoadUnitSymbologyLegend.propTypes = {
    conformanceLegendRangeBands: typeConformanceLegendRangeBands,
    renderAnnotations: PropTypes.func,
};

export default LoadUnitSymbologyLegend;
