import React from 'react';
import { withLeaflet, Path } from 'react-leaflet';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import L from 'leaflet';
import './utils/polylineClusterable';
import 'leaflet.arc';
import 'leaflet-polylinedecorator';
import deepEqual from 'deep-equal';
import groupBy from 'lodash/groupBy';
import debounce from 'lodash/debounce';
import { createSVGClusterMarker } from './createMarker';
import { calculateDestination } from './utils/calculateDistance';

/**
 * Store all the polyline as a map object
 * So they can be clustered by id
 * Essentially it looks like
 *
 * Map(length) {
 *    clusterid1 => Array(length),
 *    clusterid2 => Array(length)
 * }
 */
let arcPolylineMap = new Map();
let hoveredArcClusterId = '';

const setHoveredArcClusterId = debounce((id) => {
    // debouncing to prevent the pop up flicking if cursor moving around too fast
    if (
        (hoveredArcClusterId !== '' && id === '') || // <- when there is a popup opened, and now nothing get hovered
        (hoveredArcClusterId !== '' && hoveredArcClusterId !== id) // <- when there is a popup opened, and now other arc get hovered
    ) {
        arcsMouseOut(hoveredArcClusterId); // closing the active pop up
    }

    if (hoveredArcClusterId !== id && id !== '') {
        // <- a cluster of arcs is now hovered and it is not active
        arcsMouseOver(id); // open new pop up
    }

    // All works done and update the active cluster
    hoveredArcClusterId = id;
}, 400);

const MultipleCornerEventsTooltip = ({ data }) => {
    // Group data by the event label
    // so it could be displayed dynamically
    const groupedDataByLabel = groupBy(data, 'Label');

    return (
        <table>
            <tbody>
                <tr>
                    <td>
                        <b>CornerEvents:</b>
                    </td>
                    <td>
                        <b className="FalconChart__tooltip-series--0">
                            {data.length}
                        </b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <hr style={{ borderTop: 'dotted 1px' }} />
                    </td>
                    <td />
                </tr>
                {Object.keys(groupedDataByLabel).map((label) => (
                    <tr key={`grouped_data_${label}`}>
                        <td>{label}:</td>
                        <td>
                            <b>{groupedDataByLabel[label].length}</b>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const SingleCornerEventTooltip = ({ data }) => {
    return <p>{data.Label}</p>;
};

/**
 * Event action when cluster icon is hovered
 * To display the event summary
 * @param {*} cluster
 */
function clusterIconMouseOver(cluster) {
    const allMarkers = cluster.layer.getAllChildMarkers();

    const allMarkersData = allMarkers.map((marker) => {
        return marker.getAssociatedData();
    });

    // create a tooltip
    return cluster.sourceTarget
        .bindPopup(
            ReactDOMServer.renderToString(
                <MultipleCornerEventsTooltip data={allMarkersData} />,
            ),
            { className: 'MinesiteMap__MarkerClusterTooltip' },
        )
        .openPopup();
}
/**
 * Event action when mouse exit from cluster icon
 * Close the popup
 * @param {*} cluster
 */
function clusterIconMouseOut(cluster) {
    return cluster.sourceTarget.closePopup();
}
/**
 * Event action when an arcLine is hovered
 * To display the event summary
 * @param {*} cluster
 */
function arcsMouseOver(clusterId) {
    const arcs = arcPolylineMap.get(clusterId);
    arcs.forEach((arc) => {
        // if it is not rendered in DOM yet
        if (arc.getElement().getAttribute !== undefined) {
            arc.mySavedWeight = arc.options.weight;
            arc.mySavedClassName = arc.getElement().getAttribute('class');

            // thicken arc line by double and makes them more OBVIOUS
            arc.setStyle({ weight: arc.mySavedWeight * 3 });
            arc.getElement().setAttribute(
                'class',
                `${arc.mySavedClassName} highlight`,
            );
        }
    });

    if (arcs.length > 1) {
        // cluster of arc lines
        const allArcsData = arcs.map((arc) => arc.getAssociatedData());
        arcs[0]
            .bindPopup(
                ReactDOMServer.renderToString(
                    <MultipleCornerEventsTooltip data={allArcsData} />,
                ),
                { className: 'MinesiteMap__MarkerClusterTooltip' },
            )
            .openPopup();
    } else if (arcs.length === 1) {
        // a single arc line
        arcs[0]
            .bindPopup(
                ReactDOMServer.renderToString(
                    <SingleCornerEventTooltip
                        data={arcs[0].getAssociatedData()}
                    />,
                ),
                { className: 'MinesiteMap__MarkerClusterTooltip slim' },
            )
            .openPopup();
    }
}
/**
 * Event action when an arcLine is hovered
 * To display the event summary
 * @param {*} cluster
 */
function arcsMouseOut(clusterId) {
    const arcs = arcPolylineMap.get(clusterId);
    arcs.forEach(function (arc) {
        arc.setStyle({ weight: arc.mySavedWeight });
        arc.getElement().setAttribute('class', arc.mySavedClassName);
    });
    arcs[0].closePopup();
}

function createLayers(props) {
    const { data, paneId } = props;

    const deflateFeatures = L.markerClusterGroup({
        spiderfyOnMaxZoom: false,
        disableClusteringAtZoom: 18,
        maxClusterRadius: 100,
        iconCreateFunction: (cluster) =>
            createSVGClusterMarker(cluster, 'cornerEvent'),
        chunkedLoading: true,
        clusterPane: paneId,
    });

    deflateFeatures.on('clustermouseover', clusterIconMouseOver);
    deflateFeatures.on('clustermouseout', clusterIconMouseOut);

    data.forEach((label) => {
        // Information to define the arc
        const center = [
            label.CircleOriginLatitude,
            label.CircleOriginLongitude,
        ];
        const radius = label.CircleRadius;
        const startBearing =
            label.HeadingFromCircleOriginToArcCenter - label.CornerAngle / 2;
        const endBearing =
            label.HeadingFromCircleOriginToArcCenter + label.CornerAngle / 2;

        // customising the arc styling
        const arcStyling = {
            className: 'MinesiteMap__ClusterArcs',
            pane: paneId,
        };

        // creating the arc
        const arc = L.arc({
            center,
            radius,
            startBearing,
            endBearing,
            pane: paneId,
        });

        // get the last latlngs of the arc
        const arcPath = arc.getLatLngs();
        const lastInPath = arcPath[arcPath.length - 1];
        const firstInPath = arcPath[0];

        // Configure the direction of the corner
        // positive curvature -> ccw rotation
        // negative curvature -> cw rotation
        let arrowPosition;
        let arrowBearing;
        if (label.Curvature > 0) {
            arrowPosition = firstInPath;
            arrowBearing = startBearing - 90;
        } else {
            arrowPosition = lastInPath;
            arrowBearing = endBearing + 90;
        }

        // Calculate the arrow positions
        const leftWingPoint = calculateDestination(
            arrowPosition,
            arrowBearing - 45,
            // Negative distance so the arrow lines go backwards
            -10,
        );

        const rightWingPoint = calculateDestination(
            arrowPosition,
            arrowBearing + 45,
            // Negative distance so the arrow lines go backwards
            -10,
        );

        const arrowPath = [
            [leftWingPoint.lat, leftWingPoint.lng],
            [arrowPosition.lat, arrowPosition.lng],
            [rightWingPoint.lat, rightWingPoint.lng],
        ];

        const arcPolyline = new L.PolylineClusterable(
            // Apply arrow at the start or end of the arc
            label.Curvature > 0
                ? [...arrowPath, ...arcPath]
                : [...arcPath, ...arrowPath],
            arcStyling,
        );

        // Attach data into the polylines so we can obtain the data down the layers
        arcPolyline.setAssociatedData(label);

        // store the arcPolyline reference
        // so it can be reuse for the hover event
        // it will be clustered by id
        // as there are two types of tooltip need to be shown
        // 1. single
        // 2. clustered
        if (arcPolylineMap.get(label.ClusterId)) {
            arcPolylineMap.set(
                label.ClusterId,
                // insert the new one
                [arcPolyline, ...arcPolylineMap.get(label.ClusterId)],
            );
        } else {
            arcPolylineMap.set(label.ClusterId, [arcPolyline]);
        }

        arcPolyline.on('mouseover', function () {
            const clusterIdOfThisArc = this.getAssociatedData().ClusterId;

            setHoveredArcClusterId(clusterIdOfThisArc);
        });

        arcPolyline.on('mouseout', function () {
            setHoveredArcClusterId('');
        });

        deflateFeatures.addLayer(arcPolyline);
    });

    return [deflateFeatures];
}

class ClusteredArcs extends Path {
    static propTypes = {
        data: PropTypes.arrayOf(
            PropTypes.shape({
                ArcCenterLatitude: PropTypes.number,
                ArcCenterLongitude: PropTypes.number,
                ArcCenterX: PropTypes.number,
                ArcCenterY: PropTypes.number,
                ArcLength: PropTypes.number,
                CircleOriginLatitude: PropTypes.number,
                CircleOriginLongitude: PropTypes.number,
                CircleOriginX: PropTypes.number,
                CircleOriginY: PropTypes.number,
                CircleRadius: PropTypes.number,
                ClusterId: PropTypes.number,
                CornerAngle: PropTypes.number,
                Curvature: PropTypes.number,
                HeadingFromCircleOriginToArcCenter: PropTypes.number,
                Label: PropTypes.string,
                Speed: PropTypes.number,
                SpeedLimitLower: PropTypes.number,
                SpeedLimitUpper: PropTypes.number,
            }),
        ),
    };

    createLeafletElement(props) {
        return L.layerGroup(createLayers(props));
    }

    /**
     * As we've rendered using leaflet, we need to manually
     * handle any changes to the layers including deleting when
     * there is no longer data.
     */
    updateLeafletElement(fromProps, toProps) {
        this.leafletElement.eachLayer(() => {
            if (!deepEqual(toProps.data, fromProps.data)) {
                // Discard layers
                this.leafletElement.clearLayers();

                // Redraw layers
                createLayers(toProps).forEach((layer) => {
                    this.leafletElement.addLayer(layer);
                });
            }
        });
    }
}

export default withLeaflet(ClusteredArcs);
