import { put, select, call, takeLatest, all } from 'redux-saga/effects';
import { batchActions } from 'redux-batched-actions';
import {
    actions as filtersActions,
    selectors as filtersSelectors,
} from '../TruckAlertsAnalysis/FleetHistory/Modules/filters';
import { actions as spinnerActions } from '../TruckAlertsAnalysis/FleetHistory/Modules/spinner';
import { actions as truckAlertEventsByShiftIdActions } from '../TruckAlertsAnalysis/FleetHistory/Modules/truckAlertEventsByShiftId';
import { actions as maxMineAlertCategoriesActions } from '../TruckAlertsAnalysis/FleetHistory/Modules/maxMineAlertCategories';
import * as timingEquipmentUptimeModule from '../TruckAlertsAnalysis/FleetHistory/Modules/timingEquipmentUptime';
import { getMapFiltersToAPIParams } from '../TruckAlertsAnalysis/FleetHistory/Selectors/filters';
import {
    convertURLQueryStringToObject,
    updateURLQueryString,
} from '../Lib/queryStringUtils';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import * as resourceSagas from './resourceSagas';
import { API_ARRAY_DELIMITER, checkRequestsForErrors } from '../Api';

export function* filterUpdated(action) {
    const filters = yield select(filtersSelectors.getFilters);
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    yield call(getTruckAlertEvents, action);
    updateURLQueryString(filters);
    yield put(spinnerActions.setSpinnerState(false));
}

export function* getTruckAlertEvents() {
    const maxMineAlertCategoriesParams = yield select(getMapFiltersToAPIParams);
    const maxMineAlertCategoriesParamsShiftIds = maxMineAlertCategoriesParams.shiftIdsSelectedShifts.all.join(
        API_ARRAY_DELIMITER,
    );

    const maxMineAlertCategories = yield call(
        resourceSagas.getTruckAlertEventsByShiftId,
        {
            ShiftId: maxMineAlertCategoriesParamsShiftIds,
        },
    );

    // If there's an error then abort since it's the same endpoint to fetch the real data
    if (checkRequestsForErrors([maxMineAlertCategories])) {
        yield put(
            maxMineAlertCategoriesActions.fetchMaxMineAlertCategoriesError({
                error: 'Failed to load truck alert events.',
            }),
        );
        return;
    }

    yield put(
        maxMineAlertCategoriesActions.fetchMaxMineAlertCategoriesSuccess(
            maxMineAlertCategories.response,
        ),
    );

    const { shiftIdsSelectedShifts, ...otherParams } = yield select(
        getMapFiltersToAPIParams,
    );
    const shiftIds = maxMineAlertCategoriesParams.shiftIdsSelectedShifts.all.join(
        API_ARRAY_DELIMITER,
    );

    const [truckAlerts, truckUptime] = yield all([
        call(resourceSagas.getTruckAlertEventsByShiftId, {
            ShiftId: shiftIds,
            ...otherParams,
        }),
        call(resourceSagas.getTimingEquipmentUptime, {
            ShiftId: shiftIds,
        }),
    ]);

    if (checkRequestsForErrors([truckAlerts, truckUptime])) {
        yield put(
            batchActions([
                truckAlertEventsByShiftIdActions.fetchTruckAlertEventsByShiftIdError(
                    { error: 'Failed to load truck alert events.' },
                ),
                timingEquipmentUptimeModule.actions.fetchTimingEquipmentUptimeError(
                    {
                        error: 'Failed to load truck alert events.',
                    },
                ),
            ]),
        );
        return;
    }
    yield put(
        batchActions([
            truckAlertEventsByShiftIdActions.fetchTruckAlertEventsByShiftIdSuccess(
                truckAlerts.response,
            ),
            timingEquipmentUptimeModule.actions.fetchTimingEquipmentUptimeSuccess(
                truckUptime.response,
            ),
        ]),
    );
}

export function* updateFiltersWithURLParams(action) {
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const urlFilterValues = convertURLQueryStringToObject();
    if (Number.isFinite(urlFilterValues.InWorkshop)) {
        urlFilterValues.InWorkshop = urlFilterValues.InWorkshop.toString();
    }
    if (Number.isFinite(urlFilterValues.AlertSeverity)) {
        urlFilterValues.AlertSeverity = urlFilterValues.AlertSeverity.toString();
    }
    yield put(filtersActions.setFiltersWithUrlParams(urlFilterValues));
    yield call(getTruckAlertEvents, action);
    yield put(spinnerActions.setSpinnerState(false));
}

export default function* watch() {
    yield all([
        takeLatest(
            filtersActions.UPDATE_FILTERS_WITH_URL_PARAMS,
            updateFiltersWithURLParams,
        ),
        takeLatest(filtersActions.FILTER_UPDATED, filterUpdated),
    ]);
}
