/**
 * Removes any extra space from given input
 * @param {string} input eg.  Fred Brown
 * @return {string}
 */
import camelCase from 'lodash/camelCase';

export function removeSpaces(input) {
    return input.trim().replace(/\s\s+/g, ' ');
}

/**
 * Removes HTML tags from a string and removes whitespace from start / end.
 * Doesn't change whitespace inside of the string
 * @param str
 */
export function stripHTMLTags(str: string): string {
    if (str === null || str === '') {
        return '';
    }
    const asString = str.toString();
    return asString.replace(/<[^>]*>/g, '').trim();
}

export const mapLayerLabelsToCamelCase = (MAP_LAYER_LABELS: any) => {
    let camelCaseObject: any = {};
    Object.keys(MAP_LAYER_LABELS).forEach((label) => {
        // strip the html and tags from the values
        let stripedWord = stripHTMLTags(MAP_LAYER_LABELS[label]);
        // change it to camelCase
        let formattedName = camelCase(stripedWord);
        //push back to the camelCaseObject
        camelCaseObject[label] = formattedName;
    });
    return camelCaseObject;
};
