import { compareString } from '../../utils/sortingUtils';
import {
    CRITICALITY,
    PARAMETERS,
    GROUPS,
    GROUPS_SORT_PRIORITY,
    ParameterCheck,
    ParameterGroup,
} from './constants';

/**
 * Returns only checks for critical parameters
 *
 * @param parameterChecks
 */
export function getCriticalParameters(
    parameterChecks: ParameterCheck[],
): ParameterCheck[] {
    return parameterChecks.filter(
        (parameterCheck) => parameterCheck.Requirement === CRITICALITY.CRITICAL,
    );
}

/**
 * Replaces the names of all parameters with those specified
 * in the names config
 *
 * @param parameterChecks
 * @param nameLookup
 * @param groupLookup
 */
export function replaceParameterNames(
    parameterChecks: ParameterCheck[],
    nameLookup = PARAMETERS,
    groupLookup = GROUPS,
): ParameterCheck[] {
    return parameterChecks.map((parameterCheck) => {
        const { Parameter, DataType } = parameterCheck;

        // Get the readable Group name
        let readableGroupName = groupLookup[DataType];
        if (!readableGroupName) {
            console.error(
                `Couldn't find a readable group name for ${JSON.stringify(
                    parameterCheck,
                )}`,
            );
            // If not found, just use the given Group name
            readableGroupName = DataType;
        }

        // Get the readable Parameter name
        let readableParameterName =
            nameLookup[readableGroupName] &&
            nameLookup[readableGroupName][Parameter];
        if (!readableParameterName) {
            console.error(
                `Couldn't find a readable parameter name for ${JSON.stringify(
                    parameterCheck,
                )}`,
            );
            // If not found, just use the given Parameter name
            readableParameterName = Parameter;
        }

        return {
            ...parameterCheck,
            Parameter: readableParameterName,
            DataType: readableGroupName,
        };
    });
}

/**
 * Takes an array of ParameterChecks and returns a tree structure
 * representing all available parameter groups and their respective parameters
 *
 * @param parameterChecks
 */
export function getParameterGroups(
    parameterChecks: ParameterCheck[],
): ParameterGroup[] {
    return parameterChecks.reduce((collection, parameterCheck) => {
        const { DataType, Parameter } = parameterCheck;
        // Find the parameter group if it exists
        const group = collection.find((g) => g.name === DataType);
        // If the group exists
        if (group) {
            // ...and the parameter hasn't been added already
            if (group.parameters.indexOf(Parameter) === -1) {
                group.parameters.push(Parameter);
            }
        } else {
            // Add the new group
            const newGroup = {
                name: DataType,
                parameters: [Parameter],
            };
            collection.push(newGroup);
        }
        return collection;
    }, []);
}

/**
 * Sorts an array of ParameterGroups by sorting the groups according to the given priority,
 * and the parameters by alphabetical order
 *
 * Note: sorts the groups array in-place
 *
 * @param parameterGroups
 * @param sortPriority
 */
export function sortParameterGroups(
    parameterGroups: ParameterGroup[],
    sortPriority = GROUPS_SORT_PRIORITY,
): void {
    parameterGroups.sort((a, b) => sortPriority[a.name] - sortPriority[b.name]);
    parameterGroups.forEach((group) =>
        group.parameters.sort((a, b) => compareString(b, a)),
    );
}
