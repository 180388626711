import { arrayToGroupedObject } from '@rs/core/utils/dataUtils';
import { createReducer } from '../../Lib/reduxUtils';
import { LOAD_LOADER_OPERATOR_LOGS } from '../Actions';

const initialState = {};

export function setDrivers(state, loaderOperatorLogs) {
    return arrayToGroupedObject(loaderOperatorLogs, 'OperatorSiteId');
}

export default createReducer(initialState, {
    [LOAD_LOADER_OPERATOR_LOGS]: setDrivers,
});
