import React from 'react';
import cn from 'classnames';
import { LoadUnitImprovementSymbolProps } from './types';

const PayloadBiasGoodJobSymbol = ({
    className,
    size,
}: LoadUnitImprovementSymbolProps) => (
    <i className={cn('fa fa-check', size, className)} />
);

PayloadBiasGoodJobSymbol.defaultProps = {
    size: 'fa-2x',
};

export default PayloadBiasGoodJobSymbol;
