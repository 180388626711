import { combineReducers } from 'redux';
import { reducer as filters } from '../Modules/filters';
import { reducer as drivers } from '../Modules/drivers';
import { reducer as spinner } from '../Modules/spinner';

export default combineReducers({
    filters,
    drivers,
    spinner,
});
