export default function createSelectors(statePath) {
    function get(state) {
        return statePath(state);
    }

    function getTotal(state) {
        const slice = statePath(state);
        return slice.Total;
    }

    return {
        get,
        getTotal,
    };
}
