import {
    createReducer,
    withoutNestedPreviousState,
} from '../../Lib/reduxUtils';
import {
    State,
    StateWithoutPreviousState,
    H3ResolutionIndexChangedPayload,
    OnSuccessPayload,
    FetchMapDataErrorPayload,
} from './types';
import { notification } from '@rs/core/falcon';

const initialDataInMap = {
    7: null,
    8: null,
    9: null,
    10: null,
    11: null,
    12: null,
    13: null,
    14: null,
};
export const defaultState: StateWithoutPreviousState = {
    h3Resolution: 9, // TODO how to calculate the default h3 index
    h3ResolutionBest: 9,
    availableH3ResolutionIndex: [9, 10, 11],
    data: null,
    dataInMap: initialDataInMap,
};
export const initialState: State = {
    ...defaultState,
    previousState: null,
};

// TODO hook up with date time picker
export function h3ResolutionIndexChanged(
    state: State,
    payload: H3ResolutionIndexChangedPayload,
) {
    return {
        ...state,
        previousState: withoutNestedPreviousState(state),
        h3Resolution: payload,
    };
}

export function h3ResolutionBestIndexChanged(
    state: State,
    payload: H3ResolutionIndexChangedPayload,
) {
    return {
        ...state,
        h3ResolutionBest: payload,
    };
}

export const syncURLToState = (state: State, params: Object) => {
    // Filter out the keys that this part of state doesn't care about
    const validKeys = Object.keys(state);

    return Object.entries(params)
        .filter(
            ([key, value]) =>
                validKeys.find((vk) => vk === key) && value !== undefined,
        )
        .reduce(
            (agg, [key, value]) => {
                // Let's keep it as same logic with other pages
                switch (key) {
                    case 'h3Resolution':
                        agg.h3Resolution = value;
                        break;
                    default:
                        //@ts-ignore
                        agg[key] = Boolean(value);
                }
                return agg;
            },
            {
                ...state,
                previousState: withoutNestedPreviousState(state),
            },
        );
};

export function onSuccess(state: State, payload: OnSuccessPayload) {
    const h3Data = payload.metrics[0];

    const h3ResolutionChangeTo = h3Data.h3resolution;

    const newDataInMap = {
        ...state.dataInMap,
    };
    // maybe pre calculate the color scale?
    //@ts-ignore
    newDataInMap[h3ResolutionChangeTo] = h3Data.data;

    return {
        ...state,
        previousState: withoutNestedPreviousState(state),
        data: h3Data,
        dataInMap: newDataInMap,
        error: null, // clear the error status
    };
}

export const onError = (state: State, payload: FetchMapDataErrorPayload) => {
    notification.error({
        message: payload.message,
        description: [payload.description],
        duration: 10,
        key: 'DatePickerNotification',
    });
    return {
        ...state,
        previousState: withoutNestedPreviousState(state),
        data: null,
        dataInMap: initialDataInMap,
        error: payload,
    };
};

export const clearData = (state: State) => {
    return {
        ...state,
        data: null,
        dataInMap: initialDataInMap,
    };
};

export default function createReducers(
    actions: any,
    customActions = {},
    customInitialState: any,
) {
    return createReducer(
        {
            ...initialState,
            ...customInitialState,
        },
        {
            [actions.onSuccess]: onSuccess,
            [actions.onError]: onError,
            [actions.syncURLToState]: syncURLToState,
            [actions.h3ResolutionBestIndexChanged]: h3ResolutionBestIndexChanged,
            [actions.h3ResolutionIndexChanged]: h3ResolutionIndexChanged,
            [actions.clearData]: clearData,
            /**
             * Ensure any custom action handlers pass along state + any additional arguments needed for the
             * action handlers
             */
            ...customActions,
        },
    );
}
