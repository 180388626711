import namespace from '../Modules/namespace';
import {
    createSliceWithName as createMapFeatureSlice,
    initialState,
} from '../../Modules/mapFeatures_v2/reducer';
import { createSelectors } from '../../Modules/mapFeatures_v2/selectors';

export const moduleName = 'MAP_FEATURES';
export const selectState = (state: any) => {
    return state[namespace][moduleName];
};

const mapFeatures = createMapFeatureSlice(
    `${namespace}/${moduleName}`,
    initialState,
);

export const reducer = mapFeatures.reducer;
export const actions = mapFeatures.actions;
export const selectors = createSelectors(selectState);
