import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'react-leaflet';
import { withParentSize } from '@vx/responsive';
import noop from 'lodash/noop';
import ResetControl from './ResetControl';

class MinesiteMap extends React.Component {
    static propTypes = {
        // The outlying bounds of the map. Should be an instance of L.latLngBounds
        maxBounds: PropTypes.shape({}).isRequired,
        // How snappy the bounds are when trying to leave them. (0-1) 1 = Solid wall
        maxBoundsViscosity: PropTypes.number,
        // The initial zoom level the map displays
        zoom: PropTypes.number.isRequired,
        // The minimum level the map can be zoomed out
        minZoom: PropTypes.number.isRequired,
        // The maximum level the map can be zoomed out
        maxZoom: PropTypes.number.isRequired,
        // Inline styling that should apply to the map component
        style: PropTypes.shape({
            width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
        center: PropTypes.arrayOf(PropTypes.number),
        // What to display on the map
        children: PropTypes.node,
        // Injected by withParentSize. Gets the parent element height
        parentHeight: PropTypes.number,
        // Injected by withParentSize. Gets the parent element width
        parentWidth: PropTypes.number,
        // Leaflet events: called when the layer is added. Not called on initial render
        onOverlayAdd: PropTypes.func,
        // Leaflet events: called when the layer is removed. Not called on initial render
        onOverlayRemove: PropTypes.func,
        // Leaflet events: called when the map viewport changes. eg. zooming, panning, outside of bounds. Not called on initial render
        onViewportChanged: PropTypes.func,
        //How many scroll pixels mean a change of one full zoom level
        wheelPxPerZoomLevel: PropTypes.number,
        //Controls how much the map's zoom level will change after zoom controls
        zoomDelta: PropTypes.number,
        //By default, the zoom level snaps to the nearest integer; lower values (e.g. 0.5 or 0.1) allow for greater granularity
        zoomSnap: PropTypes.number,
        whenCreated: PropTypes.func
    };

    static defaultProps = {
        zoom: 13,
        minZoom: 12,
        maxZoom: 19,
        maxBoundsViscosity: 1,
        onOverlayAdd: noop,
        onOverlayRemove: noop,
        onViewportChanged: noop,
        wheelPxPerZoomLevel: 120,
        zoomSnap: 0.5,
        zoomDelta: 0.5,
        whenCreated: noop
    };

    constructor(props) {
        super(props);

        this.mapRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        const prevSize = this.getSize(prevProps);
        const currSize = this.getSize(this.props);

        if (this.mapRef && typeof this.props.whenCreated === 'function') {
            this.props.whenCreated(this.mapRef.current.leafletElement);
        }

        if (this.mapRef && this.sizeHasChanged(prevSize, currSize)) {
            this.mapRef.current.leafletElement.invalidateSize();
        }
    }

    /**
     * Gets the height & width of
     * @param style
     * @param parentHeight
     * @param parentWidth
     * @return {{height: *, width: *}}
     */
    getSize({ style, parentHeight, parentWidth }) {
        return {
            height: (style && style.height) || parentHeight,
            width: (style && style.width) || parentWidth,
        };
    }

    /**
     * Tests if the size is the different to the previous size
     * @param {object} prevSize
     * @param {object} currSize
     * @return {boolean}
     */
    sizeHasChanged(prevSize, currSize) {
        return (
            prevSize.height !== currSize.height ||
            prevSize.width !== currSize.width
        );
    }

    render() {
        const {
            maxBounds,
            maxBoundsViscosity,
            zoom,
            minZoom,
            maxZoom,
            center,
            children,
            style,
            onOverlayAdd,
            onOverlayRemove,
            onViewportChanged,
            wheelPxPerZoomLevel,
            zoomSnap,
            zoomDelta,
            zoomLevel,
            whenReady,
            ...leafletOptions
        } = this.props;

        const styles = {
            ...style,
            ...this.getSize(this.props),
        };

        const { viewport, ...otherLeafletOptions } = leafletOptions;

        // Can only have bounds or viewport not both
        let view = { bounds: maxBounds };
        if (viewport) {
            view = { viewport };
        }
        return (
            <Map
                whenReady={whenReady}
                ref={this.mapRef}
                center={center}
                zoom={zoom}
                minZoom={minZoom}
                maxZoom={maxZoom}
                maxBounds={maxBounds}
                maxBoundsViscosity={maxBoundsViscosity}
                style={styles}
                onOverlayAdd={onOverlayAdd}
                onOverlayRemove={onOverlayRemove}
                onViewportChanged={onViewportChanged}
                zoomSnap={zoomSnap}
                zoomDelta={zoomDelta}
                wheelPxPerZoomLevel={wheelPxPerZoomLevel}
                {...view}
                {...otherLeafletOptions}
            >
                {children}
                <ResetControl bounds={maxBounds} />
            </Map>
        );
    }
}

const wrappedComponent = withParentSize(MinesiteMap);

wrappedComponent.displayName = 'MinesiteMap';
wrappedComponent.propTypes = MinesiteMap.propTypes;
wrappedComponent.defaultProps = MinesiteMap.defaultProps;

export default wrappedComponent;
