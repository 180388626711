import createActions from '../../Modules/speedingEvents/actions';
import createReducer, { onSuccess } from '../../Modules/speedingEvents/reducer';
import createSelectors from '../../Modules/speedingEvents/selectors';
import { LOAD_RESULTS_SUCCESS } from '../Actions';
import namespace from './namespace';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].speedingEvents;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);

export const reducer = createReducer(
    {
        onSuccess: actions.FETCH_SPEEDING_EVENTS_SUCCESS,
        onError: actions.FETCH_SPEEDING_EVENTS_ERROR,
    },
    {
        [LOAD_RESULTS_SUCCESS]: (state, { speedingEvents }) =>
            onSuccess(state, speedingEvents),
    },
);

export const selectors = createSelectors(statePath);
