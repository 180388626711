import React from 'react';
import cn from 'classnames';
import { LoadUnitImprovementSymbolProps } from './types';

const PayloadBiasImproveLongitudinalConsistencySymbol = ({
    className,
    size,
}: LoadUnitImprovementSymbolProps) => (
    <i className={cn('fa fa-arrows-v', size, className)} />
);

PayloadBiasImproveLongitudinalConsistencySymbol.defaultProps = {
    size: 'fa-2x',
};

export default PayloadBiasImproveLongitudinalConsistencySymbol;
