import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { roundTo } from '@rs/core/utils/mathHelpers';
import shiftUtils from '@rs/core/utils/shiftUtils';
import HaulRoadConditionKpiDirection from './HaulRoadConditionKpiDirection';
import arePropsEqual from '@rs/core/falcon/utils/arePropsEqual';

const conformanceClassNames = {
    ExtremelyRough: 'HaulRoadConditionLastShift__Conformance--Extreme',
    Light: '',
    Moderate: '',
    Rough: '',
    Smooth: 'HaulRoadConditionLastShift__Conformance--Smooth',
};

const formattedLabels = {
    ExtremelyRough: 'Extreme',
    Light: 'Light',
    Moderate: 'Moderate',
    Rough: 'Rough',
    Smooth: 'Smooth',
};

const formatKpiDifferenceLabel = (value) => {
    if (Number.isNaN(value)) return null;
    return `${roundTo(value, 1)}%`;
};

const HaulRoadConditionLastShift = ({ classifications = {} }) => {
    if (!Object.keys(classifications.totals).length) return null;
    const { totals, shiftId, lastXShiftsAverage } = classifications;
    const formattedDate = shiftUtils.shiftIdToDateAndDay(shiftId);
    return (
        <div className="HaulRoadConditionLastShift">
            <div className="HaulRoadConditionLastShift__Title">
                {`Last Shift KPIs - ${formattedDate}`}
            </div>
            <div className="HaulRoadConditionLastShift__Row">
                {['ExtremelyRough', 'Smooth'].map((key, i) => {
                    const kpiDifferentOverTime =
                        totals[key] - lastXShiftsAverage[key];
                    const kpiDifferenceLabel = formatKpiDifferenceLabel(
                        kpiDifferentOverTime,
                    );
                    const kpiLastShiftsAverage = formatKpiDifferenceLabel(
                        lastXShiftsAverage[key],
                    );
                    const tooltip = Number.isFinite(lastXShiftsAverage[key])
                        ? `3 day average: ${kpiLastShiftsAverage}`
                        : `3 day average not available`;

                    return (
                        <div
                            key={i}
                            className="HaulRoadConditionLastShift__Row"
                            data-rh={tooltip}
                        >
                            {i === 0 && (
                                <div className="HaulRoadConditionLastShift__KpiDirectionContainer">
                                    <HaulRoadConditionKpiDirection
                                        value={kpiDifferentOverTime}
                                        goodClassName="HaulRoadConditionKpiDirection--Bad"
                                        badClassName="HaulRoadConditionKpiDirection--Good"
                                    />
                                    {kpiDifferenceLabel}
                                </div>
                            )}
                            <div className="HaulRoadConditionLastShift__Cell">
                                {formattedLabels[key]}
                                <div
                                    className={`HaulRoadConditionLastShift__Conformance ${conformanceClassNames[key]}`}
                                >
                                    {roundTo(totals[key], 1)}%
                                </div>
                            </div>
                            {i !== 0 && (
                                <div className="HaulRoadConditionLastShift__KpiDirectionContainer">
                                    <HaulRoadConditionKpiDirection
                                        value={kpiDifferentOverTime}
                                        goodClassName="HaulRoadConditionKpiDirection--Good"
                                        badClassName="HaulRoadConditionKpiDirection--Bad"
                                    />
                                    {kpiDifferenceLabel}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

HaulRoadConditionLastShift.defaultProps = {};

const typeClassifications = PropTypes.shape({
    ExtremelyRough: PropTypes.number,
    Light: PropTypes.number,
    Moderate: PropTypes.number,
    Rough: PropTypes.number,
    Smooth: PropTypes.number,
});

HaulRoadConditionLastShift.propTypes = {
    classifications: PropTypes.shape({
        totals: typeClassifications,
        shiftId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        lastXShiftsAverage: typeClassifications,
    }),
};

export default memo(HaulRoadConditionLastShift, arePropsEqual);
