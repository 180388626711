import '@rs/core/polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import errorLogging, {
    sentryDefaultOptions,
    sentryURL,
} from '@rs/core/utils/errorLogging';
import configureStore from './App/configureStore';
import Root from './App/Root';
import {
    browserLocationShouldBeHttps,
    redirectToHttps,
} from './Lib/browserLocationShouldBeHttps';

function startApp() {
    // Start the mock api server
    if (process.env.REACT_APP_WITH_MOCK_API === 'true') {
        console.log('Starting mock api server');
        // We do not want to import the mock api in other env
        import('@rs/mock-rww-api').then(({ startMirage }) => {
            startMirage({
                environment: process.env.NODE_ENV,
            });
        });
    }

    // Enforce a redirect to https if we are hosted somewhere.
    if (browserLocationShouldBeHttps()) {
        redirectToHttps();
    } else {
        const store = configureStore();
        ReactDOM.render(
            <Root store={store} />,
            document.getElementById('root'),
        );
    }
}

if (process.env.NODE_ENV === 'production') {
    errorLogging.init(sentryURL, {
        ...sentryDefaultOptions,
        // Tag the env just for sanity so we don't debug non prod issues
        environment: process.env.NODE_ENV,
    });
    startApp();
} else {
    startApp();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
