import React from 'react';
import PropTypes from 'prop-types';
import identity from 'lodash/identity';
import merge from 'lodash/merge';
import LineChart from './LineChart';

// We use a Higher Order Component to extend the existing LineChart
const WithDashedLine = (WrappedLineChart) => {
    const LineChartWithDashedLine = ({
        data,
        dataFormatter,
        dashedData,
        dashedDataFormatter,
        options,
        ...props
    }) => {
        const solidSeries = dataFormatter(data);
        const dashedSeries = dashedDataFormatter(dashedData);

        dashedSeries.forEach((series) => {
            series.className = 'LineChart__series--dashed';
            if (series.color) {
                series.className += ` LineChart__series--dashed-${series.color}`;
            }
            series.zIndex = 2;
        });

        solidSeries.forEach((series) => {
            series.zIndex = 1;
        });

        const chartOptions = {
            plotOptions: {
                line: {
                    marker: {
                        symbol: 'circle',
                    },
                },
            },
            tooltip: {
                shared: true,
            },
        };

        // Merge in any option overrides given to us
        merge(chartOptions, options);

        return (
            <WrappedLineChart
                data={solidSeries.concat(dashedSeries)}
                options={chartOptions}
                {...props}
            />
        );
    };

    // For now just use the proptypes of the regular LineChart
    LineChartWithDashedLine.propTypes = {
        dashedData: PropTypes.arrayOf(PropTypes.any).isRequired,
        dashedDataFormatter: PropTypes.func,
        ...LineChart.propTypes,
    };
    LineChartWithDashedLine.defaultProps = {
        dashedDataFormatter: identity,
        ...LineChart.defaultProps,
    };

    return LineChartWithDashedLine;
};

export default WithDashedLine;
