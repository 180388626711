import * as Sentry from '@sentry/browser';
import { v4 as uuid } from 'uuid';

interface UserContext {
    email: string;
    mine_site_id: string;
}
export interface ErrorLoggingInterface {
    logException: (
        err: string | Error,
        context?: { [key: string]: string },
    ) => void;
    setErrorLoggingUserContext: ({
        email,
        mine_site_id,
        ...rest
    }: UserContext) => void;
}

export const sentryDefaultOptions = {
    // Ignore some of the clutter
    // https://gist.github.com/impressiver/5092952
    ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // IE11 Last pass extension errors
        'sendLpImprove',
        // Network error cannot load chunk
        /Loading chunk .* failed/,
    ],
    blacklistUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
};

export const sentryURL =
    'https://76b93b8879d24817acb22419a651f8a1@sentry.io/60636';

class ErrorLogging implements ErrorLoggingInterface {
    localStorageKey = 'sentryOffline';
    cacheSize = 10;

    /**
     * Initialises the error logging with Sentry options
     *
     * @param SENTRY_DSN
     * @param sentryOptions - See sentry doc options
     */
    init(
        SENTRY_DSN: string,
        sentryOptions: Sentry.BrowserOptions = sentryDefaultOptions,
    ) {
        Sentry.init({
            dsn: SENTRY_DSN,
            ...sentryOptions,
        });
    }

    logException(
        err: string | Error,
        context?: { [key: string]: string },
    ): string {
        const traceId = uuid();
        console.error(`Logging exception: ${traceId}`, err);
        Sentry.withScope(function (scope) {
            if (context != null) {
                scope.setTags(context);
                scope.setTag('trace_id', traceId);
            }
            Sentry.captureException(err);
        });
        return traceId;
    }

    /**
     * Set's the logged in users identity & mineSite
     * @param context - The decoded token payload
     */
    setErrorLoggingUserContext({ email, mine_site_id, ...rest }: UserContext) {
        Sentry.configureScope((scope) => {
            // Setting as a tag also as this will allow for better searchability
            scope.setTag('minesite', mine_site_id);
            scope.setUser({
                email,
                mine_site_id,
                rest,
            });
        });
    }
}

export default new ErrorLogging() as ErrorLoggingInterface;
