import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const GET_CIRCUIT_SUGGEST_REQUESTED = `${namespace}/GET_CIRCUIT_SUGGEST_REQUESTED`;
    const getCircuitSuggestRequested = ({ source, destination }) =>
        action(GET_CIRCUIT_SUGGEST_REQUESTED, { source, destination });

    const GET_CIRCUIT_SUGGEST_SUCCESS = `${namespace}/GET_CIRCUIT_SUGGEST_SUCCESS`;
    const getCircuitSuggestSuccess = (results) =>
        action(GET_CIRCUIT_SUGGEST_SUCCESS, results);

    const GET_CIRCUIT_SUGGEST_ERROR = `${namespace}/GET_CIRCUIT_SUGGEST_ERROR`;
    const getCircuitSuggestError = ({ error }) =>
        action(GET_CIRCUIT_SUGGEST_ERROR, { error });

    return {
        GET_CIRCUIT_SUGGEST_REQUESTED,
        getCircuitSuggestRequested,
        GET_CIRCUIT_SUGGEST_SUCCESS,
        getCircuitSuggestSuccess,
        GET_CIRCUIT_SUGGEST_ERROR,
        getCircuitSuggestError,
    };
}
