import pick from 'lodash/pick';
import { createReducer, action } from '../../Lib/reduxUtils';
import * as mineSnapshotModule from './mineSnapshot';
import namespace from './namespace';

function createActions(ns) {
    const TRANSACTION_STARTED = `${ns}/TRANSACTION_STARTED`;
    const transactionStarted = ({ referenceId }) =>
        action(TRANSACTION_STARTED, { referenceId });

    const TRANSACTION_PENDING = `${ns}/TRANSACTION_PENDING`;
    const transactionPending = ({ referenceId, pendingRequestNum }) =>
        action(TRANSACTION_PENDING, { referenceId, pendingRequestNum });

    return {
        TRANSACTION_STARTED,
        transactionStarted,
        TRANSACTION_PENDING,
        transactionPending,
    };
}

function createSelectors(statePath) {
    function get(state) {
        return statePath(state);
    }

    function isItemPending(state, id) {
        const mineSnapshot = mineSnapshotModule.selectors.get(state);
        const slice = get(state);
        // No pending actions or data not loaded
        if (
            !slice[id] ||
            !(
                mineSnapshot.minesite &&
                mineSnapshot.minesite['last-request-num']
            )
        ) {
            return false;
        }

        return (
            slice[id].pendingRequestNum >
                mineSnapshot.minesite['last-request-num'] || slice[id].started
        );
    }

    return {
        get,
        isItemPending,
    };
}

export const actions = createActions(namespace);
export const selectors = createSelectors(
    (state) => state[namespace].pendingTransactions,
);

export const initialState = {};
export const transactionStarted = (state, { referenceId }) => ({
    ...state,
    [referenceId]: { started: true },
});
export const transactionPending = (
    state,
    { referenceId, pendingRequestNum },
) => ({
    ...state,
    [referenceId]: { pendingRequestNum },
});

export const clearOldTransactions = (state, { minesite }) => {
    const unProcessedIds = Object.keys(state).filter((id) => {
        const transaction = state[id];
        return transaction.pendingRequestNum > minesite['last-request-num'];
    });
    return pick(state, unProcessedIds);
};

export const reducer = createReducer(initialState, {
    [actions.TRANSACTION_STARTED]: transactionStarted,
    [actions.TRANSACTION_PENDING]: transactionPending,
    [mineSnapshotModule.actions.FETCH_MINESITE_SUCCESS]: clearOldTransactions,
});
