// @ts-ignore
import combineReducers from 'combine-reducers-global-state';
import namespace from '../Modules/namespace';
import { reducer as cornerEvents } from '../Modules/cornerEvents';
import { reducer as cornerLeague } from '../Modules/cornerLeague';
import { reducer as ui } from '../Modules/ui';
import { reducer as drivers } from '../Modules/drivers';
import { reducer as filters } from '../Modules/filters';
import { reducer as mapFeatures } from '../Modules/mapFeatures';
import { reducer as mapLabels } from '../Modules/mapLabels';
import { reducer as minesiteAreasTree } from '../Modules/minesiteAreasTree';
import { reducer as minesiteMap } from '../Modules/minesiteMap';
import { reducer as rampEvents } from '../Modules/rampEvents';
import { reducer as conformance } from '../Modules/conformance';
import { reducer as speedingEvents } from '../Modules/speedingEvents';
import { reducer as speedLimitedZones } from '../Modules/speedLimitedZones';
import { reducer as spinner } from '../Modules/spinner';
import { reducer as WKTAggregate } from '../Modules/WKTAggregate';
import { reducer as topClusters } from '../Modules/topClusters';
import {
    moduleName as mapLegendsName,
    reducer as mapLegends,
} from '../Modules/mapLegends';
import {
    moduleName as mapFeaturesName,
    reducer as mapFeatures_v2,
} from '../Modules/mapFeatures_v2';

export const REDUCER_KEY = namespace;

const reducers = combineReducers({
    cornerEvents,
    cornerLeague,
    ui,
    drivers,
    filters,
    mapFeatures,
    mapLabels,
    minesiteAreasTree,
    minesiteMap,
    rampEvents,
    conformance,
    speedingEvents,
    speedLimitedZones,
    spinner,
    WKTAggregate,
    topClusters,
    [mapLegendsName]: mapLegends,
    [mapFeaturesName]: mapFeatures_v2,
});

export default reducers;
