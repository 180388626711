import {
    arrayToGroupedObject,
    flattenArray,
    objectToArray,
} from '../../utils/dataUtils';
import { compareString, createMultiKeySortFn } from '../../utils/sortingUtils';

// The various states the data for a shift / equipment can be in
export const DATA_STATUS = {
    DATA: 'Data',
    INSUFFICIENT_DATA: 'Insufficient data',
    NO_DATA: 'No data',
    OFFLINE: 'Offline',
};

/**
 * Adds the relative efficiency score to the metrics
 * @param {array} engineDiagnosticsPowerCurveMetrics - engineDiagnosticPowerCurveMetrics should include 'Median' results in the data
 * @return {array}
 */
export function appendRelativeEfficiency(engineDiagnosticsPowerCurveMetrics) {
    return Object.values(
        arrayToGroupedObject(engineDiagnosticsPowerCurveMetrics, 'ShiftId'),
    ).reduce((c, values) => {
        const { data, medians } = splitDataAndMedianResults(values);
        return [...c, ...calculateRelativeEfficiency(data, medians)];
    }, []);
}

/**
 * Calculates the relative efficiency score
 * @param {array} engineDiagnosticPowerCurveMetrics
 * @param {object} medians
 * @return {array}
 */
export function calculateRelativeEfficiency(
    engineDiagnosticPowerCurveMetrics,
    medians,
) {
    return engineDiagnosticPowerCurveMetrics.map((metric) => {
        const modelMedian =
            medians[metric.EquipmentClass] &&
            medians[metric.EquipmentClass][metric.EquipmentSubClass] &&
            medians[metric.EquipmentClass][metric.EquipmentSubClass][
                metric.EquipmentModel
            ];
        const percent = (metric.AreaUnderCurve / modelMedian) * 100;
        return {
            ...metric,
            RelativeEfficiency: metric.AreaUnderCurve === null ? null : percent,
        };
    });
}

/**
 * Separates the results returned from the API into data and medians
 * NOTE: If your data set spans multiple shiftIds this will not handle it as there will be a median per ShiftId/Model
 *
 * @param {array} engineDiagnosticPowerCurveMetricResults - The data returned from the API endpoint
 * @return {{data: [], medianByModel: {}}}
 */
export function splitDataAndMedianResults(
    engineDiagnosticPowerCurveMetricResults,
) {
    return engineDiagnosticPowerCurveMetricResults.reduce(
        (collection, row) => {
            if (row.EquipmentSiteName === 'Median') {
                let classMedians = collection.medians[row.EquipmentClass];
                if (classMedians == null) {
                    classMedians = {};
                    collection.medians[row.EquipmentClass] = classMedians;
                }
                let subClassMedians = classMedians[row.EquipmentSubClass];
                if (subClassMedians == null) {
                    subClassMedians = {};
                    classMedians[row.EquipmentSubClass] = subClassMedians;
                }
                subClassMedians[row.EquipmentModel] = row.AreaUnderCurve;
            } else {
                collection.data.push(row);
            }
            return collection;
        },
        {
            data: [],
            medians: {},
        },
    );
}

/**
 * Gets all the unique equipment site names from engineDiagnosticPowerCurve results
 * @param engineDiagnosticsPowerCurve
 * @return {string[]}
 */
export function getUniqueEquipmentSiteNames(engineDiagnosticsPowerCurve) {
    return Array.from(
        engineDiagnosticsPowerCurve.reduce((equipmentNames, row) => {
            if (row.EquipmentSiteName !== 'Median') {
                equipmentNames.add(row.EquipmentSiteName);
            }
            return equipmentNames;
        }, new Set()),
    );
}

/**
 * Makes the data set more complete by filling in any missing holes
 * For example data that looks like below for a query
 * all possible shifts = [1,2,3]
 * all possible trucks = [DT01, DT02, DT03]
 *
 * INPUT:
 {
        1: { DT01: 12, DT02: 12 },
        2: { DT02: 5 },
 }
 * OUTPUT:
 {
        1: {
            DT01: { ...the rest },
            DT02: { ...the rest },
            DT03: { ...the rest },
        },
        2: {
            DT01: { ...the rest },
            DT02: { ...the rest },
            DT03: { ...the rest },
        },
        3: {
            DT01: { ...the rest },
            DT02: { ...the rest },
            DT03: { ...the rest },
        },
 }
 *
 * @param {array} allShifts
 * @param {array} allTrucks
 * @param {array} engineDiagnosticsByShiftId
 * @param {Object} truckUptimesByShiftId
 * @return {Object}
 */
export function padEngineDiagnosticPowerCurveMetricResults(
    allShifts,
    allTrucks,
    engineDiagnosticsByShiftId,
    truckUptimesByShiftId,
) {
    return allShifts.reduce((collection, shiftId) => {
        collection[shiftId] = allTrucks.reduce((trucksForShiftId, truck) => {
            const result =
                engineDiagnosticsByShiftId[shiftId] &&
                engineDiagnosticsByShiftId[shiftId][truck];

            const equipmentUptime =
                truckUptimesByShiftId[shiftId] &&
                truckUptimesByShiftId[shiftId][truck];
            // Truck was off
            if (equipmentUptime && equipmentUptime.Uptime === 0) {
                trucksForShiftId[truck] = {
                    EquipmentSiteName: truck,
                    RelativeEfficiency: null,
                    status: DATA_STATUS.OFFLINE,
                };

                // No data - no uptime or result data
            } else if (!equipmentUptime || !result) {
                trucksForShiftId[truck] = {
                    EquipmentSiteName: truck,
                    RelativeEfficiency: null,
                    status: DATA_STATUS.NO_DATA,
                };

                // Not enough data to calculate results
            } else if (result.RelativeEfficiency === null) {
                trucksForShiftId[truck] = {
                    EquipmentSiteName: truck,
                    RelativeEfficiency: null,
                    status: DATA_STATUS.INSUFFICIENT_DATA,
                };

                // Valid results
            } else {
                trucksForShiftId[truck] = {
                    ...result,
                    status: DATA_STATUS.DATA,
                };
            }
            return trucksForShiftId;
        }, {});
        return collection;
    }, {});
}

/**
 * Restructures the data so it's easier to use in the view
 * Transposes the map so it's organised by truck name rather than shiftId the object map so a 'row' is all the shift results for a truck
 * INPUT:
 {
        1: { DT01: 12,   DT02: 12,   DT03: null },
        2: { DT01: null, DT02: 5,    DT03: null },
        3: { DT01: null, DT02: null, DT03: null },
    }
 * OUTPUT:
 {
        DT01: {
            1: { equipmentSiteName: 'DT01', ...the rest },
            2: { equipmentSiteName: 'DT01', ...the rest },
            3: { equipmentSiteName: 'DT01', ...the rest },
        },
        DT02: {
            1: { equipmentSiteName: 'DT02', ...the rest },
            2: { equipmentSiteName: 'DT02', ...the rest },
            3: { equipmentSiteName: 'DT02', ...the rest },
        },
        DT03: {
            1: { equipmentSiteName: 'DT03', ...the rest },
            2: { equipmentSiteName: 'DT03', ...the rest },
            3: { equipmentSiteName: 'DT03', ...the rest },
        },
    }
 *
 * @param {Object} data
 * @return {Object}
 */
export function formatResults(data) {
    return Object.keys(data).reduce((collection, shiftId) => {
        const row = data[shiftId];
        Object.entries(row).forEach(([equipmentSiteName, result]) => {
            collection[equipmentSiteName] = collection[equipmentSiteName] || {};
            collection[equipmentSiteName][shiftId] = result;
        });
        return collection;
    }, {});
}

/**
 * Formats engine diagnostic power curve metrics into a format suitable for pivotgrid
 * @param {array} engineDiagnosticsPowerCurveMetrics
 * @param {array} allShiftIds
 * @param {array} allTrucks
 * @param {Object} truckUptimesByShiftId
 * @return {{}}
 */
export const getFormattedEngineDiagnosticPowerCurveMetrics = (
    engineDiagnosticsPowerCurveMetrics,
    allShiftIds,
    allTrucks,
    truckUptimesByShiftId,
) => {
    if (!engineDiagnosticsPowerCurveMetrics) {
        return {};
    }

    const metricsWithRelativeEfficiency = appendRelativeEfficiency(
        engineDiagnosticsPowerCurveMetrics,
    );

    const engineDiagnosticsPowerCurveMetricsByShiftId = metricsWithRelativeEfficiency.reduce(
        (collection, row) => {
            collection[row.ShiftId] = collection[row.ShiftId] || {};
            collection[row.ShiftId][row.EquipmentSiteName] = row;
            return collection;
        },
        {},
    );

    const paddedResults = padEngineDiagnosticPowerCurveMetricResults(
        allShiftIds,
        allTrucks,
        engineDiagnosticsPowerCurveMetricsByShiftId,
        truckUptimesByShiftId,
    );
    return formatResults(paddedResults);
};

/**
 * sorts formatted engine diagnostic power curve metrics
 * @param {object} formattedMetrics
 * @param {string} sortKey
 * @param {boolean} isAscending
 * @return {*[]}
 */
export function sortFormattedEngineDiagnosticPowerCurveMetrics(
    formattedMetrics,
    sortKey,
    isAscending,
) {
    const flattenedData = flattenArray(
        objectToArray(formattedMetrics).map(objectToArray),
    );

    const sortStatusData = createMultiKeySortFn([
        {
            name: 'RelativeEfficiency',
            reverse: isAscending,
        },
        {
            name: 'EquipmentSiteName',
            reverse: false,
        },
    ]);

    const sortEquipmentSiteName = createMultiKeySortFn([
        {
            name: 'EquipmentSiteName',
            reverse: isAscending,
        },
    ]);

    if (sortKey === 'EquipmentSiteName') {
        return flattenedData.sort(sortEquipmentSiteName);
    }

    const byStatus = flattenedData.reduce((collection, row) => {
        collection[row.status] = collection[row.status] || [];
        collection[row.status].push(row);
        return collection;
    }, {});

    return [
        ...(byStatus[DATA_STATUS.DATA] || []).sort(sortStatusData),
        ...(byStatus[DATA_STATUS.OFFLINE] || []).sort(sortEquipmentSiteName),
        ...(byStatus[DATA_STATUS.INSUFFICIENT_DATA] || []).sort(
            sortEquipmentSiteName,
        ),
        ...(byStatus[DATA_STATUS.NO_DATA] || []).sort(sortEquipmentSiteName),
    ];
}
