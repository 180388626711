import { combineReducers } from 'redux';
import { createReducer } from '../../Lib/reduxUtils';
import {
    FETCH_USER_INFORMATION_SUCCESS,
    FETCH_USER_INFORMATION_ERROR,
} from '../Actions';
import { LOGIN_ERROR, LOGOUT } from '../../Auth/Actions';
import createInitReducer from '../../Modules/init/reducer';

const initialState = {};

const onSuccess = (state, userInformation) => ({
    ...state,
    ...userInformation.CurrentUser,
});

const reset = () => initialState;

const userInformationReducer = createReducer(initialState, {
    [LOGOUT]: reset,
    [LOGIN_ERROR]: reset,
    [FETCH_USER_INFORMATION_ERROR]: reset,
    [FETCH_USER_INFORMATION_SUCCESS]: onSuccess,
});

const initReducer = createInitReducer({
    onSuccess: FETCH_USER_INFORMATION_SUCCESS,
    onError: FETCH_USER_INFORMATION_ERROR,
});

export default combineReducers({
    current_user: userInformationReducer,
    init: initReducer,
});
