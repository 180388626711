import { put, select, call, takeLatest, all } from 'redux-saga/effects';
import { operator } from '@rs/core/application';
import shiftUtils from '@rs/core/utils/shiftUtils';
import { stateSelectors, actions } from '../LeagueTable';
import {
    convertURLQueryStringToObject,
    updateURLQueryString,
    getQueryString,
} from '../Lib/queryStringUtils';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import * as resourceSagas from './resourceSagas';
import { API_ARRAY_DELIMITER, checkRequestsForErrors } from '../Api';
import { forwardTo } from '../App/createHistory';
import { FEATURE_META } from '../Auth/featureMeta';
import { FEATURE_IDS } from '../Auth/featureIds';

export function* filterUpdated() {
    const state = yield select();
    const filters = stateSelectors.getFilters(state);
    // If dates are invalid go back to waiting for FILTER_UPDATED
    if (
        !shiftUtils.isDateStringValid(
            filters.Date,
            shiftUtils.DATE_FORMAT__VALIDATE,
        ) ||
        (filters.rangeSelected &&
            !shiftUtils.isDateStringValid(
                filters.EndDate,
                shiftUtils.DATE_FORMAT__VALIDATE,
            ))
    ) {
        return;
    }
    yield put(actions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA));
    yield call(getDriversAndCornerLeague);
    updateURLQueryString(filters);
    yield put(actions.setSpinnerState(false));
}

export function* getDriversAndCornerLeague() {
    const minesiteAreasTree = yield call(resourceSagas.getMinesiteAreasTree);
    yield put(
        actions.fetchMinesiteAreasTreeSuccess(minesiteAreasTree.response),
    );
    const {
        shiftIdsAllShifts,
        shiftIdsSelectedShifts,
        ...allOtherParams
    } = yield select(stateSelectors.getDefinedFilters);
    const results = yield all([
        call(resourceSagas.getDrivers, {
            ShiftId: shiftIdsAllShifts.all.join(API_ARRAY_DELIMITER),
        }),
        call(resourceSagas.getCornerLeague, {
            ...allOtherParams,
            ShiftId: shiftIdsSelectedShifts.all.join(API_ARRAY_DELIMITER),
        }),
        call(resourceSagas.getConditionalLimitEvents, {
            ShiftId: shiftIdsSelectedShifts.all.join(API_ARRAY_DELIMITER),
            GearLimitExists: true,
        }),
    ]);
    const hasError = checkRequestsForErrors(results);
    if (hasError) {
        return;
    }
    const [drivers, cornerLeague, gearLockdown] = results;
    yield put(actions.loadDrivers(drivers.response));
    yield put(actions.loadCornerLeague(cornerLeague.response));
    yield put(actions.fetchConditionalLimitEevents(gearLockdown.response));
}

export function* updateFiltersWithURLParams() {
    yield put(actions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA));
    const urlFilterValues = convertURLQueryStringToObject();
    yield put(actions.setFiltersWithUrlParams(urlFilterValues));
    yield call(getDriversAndCornerLeague);
    yield put(actions.setSpinnerState(false));
}

export function* linkToPage({ payload }) {
    const state = yield select();
    const filters = stateSelectors.getFilters(state);
    let filtersToString;
    if (
        payload.url ===
        FEATURE_META[FEATURE_IDS.TAB_ID__OVERALL_PRODUCTIVITY_EVENTS].path
    ) {
        const drivers = stateSelectors.getAllDrivers(state);
        const uniqueDriverId = String(
            operator.operatorNameToUniqueId(payload.worker),
        );
        const driverLog = drivers.find(
            (driver) =>
                String(driver.UniqueDriverId) === String(uniqueDriverId),
        );
        const {
            EndDate,
            Crew,
            rangeSelected,
            ...validProductivityFilters
        } = filters;
        filtersToString = {
            ...validProductivityFilters,
            Shift: driverLog.Shift || '',
            Worker: uniqueDriverId,
        };
    } else {
        filtersToString = {
            ...filters,
            Worker: payload.worker,
        };
    }
    forwardTo(`${payload.url}?${getQueryString(filtersToString)}`);
}

export default function* watch() {
    yield all([
        takeLatest(actions.LINK_TO_PAGE, linkToPage),
        takeLatest(actions.FILTER_UPDATED, filterUpdated),
        takeLatest(
            actions.UPDATE_FILTERS_WITH_URL_PARAMS,
            updateFiltersWithURLParams,
        ),
    ]);
}
