export default function createSelectors(statePath) {
    function get(state) {
        return statePath(state).data;
    }

    function hasError(state) {
        return statePath(state).hasError;
    }

    function getErrorMessage(state) {
        return statePath(state).error;
    }

    function isLoading(state) {
        return statePath(state).isLoading;
    }

    return {
        get,
        hasError,
        getErrorMessage,
        isLoading,
    };
}
