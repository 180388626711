import { all, takeLatest, put, select, call } from 'redux-saga/effects';
import shiftUtils from '@rs/core/utils/shiftUtils';
import moment from 'moment';
import errorLogging from '@rs/core/utils/errorLogging';
import * as spinner from '../O4ROperator/Modules/spinner';
import * as filtersModule from '../O4ROperator/Modules/filters';
import * as o4rOperatorByDriverShift from '../O4ROperator/Modules/o4rOperatorByDriverShift';
import * as driversModule from '../O4ROperator/Modules/drivers';
import {
    convertURLQueryStringToObject,
    updateURLQueryString,
} from '../Lib/queryStringUtils';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import * as resourceSagas from './resourceSagas';
import { getMapFiltersToAPIParams } from '../O4ROperator/Selectors/filters';
import { API_ARRAY_DELIMITER } from '../Api';

export function* filterUpdated(action) {
    const filters = yield select(filtersModule.selectors.getFilters);
    if (
        !shiftUtils.isDateStringValid(
            filters.Date,
            shiftUtils.DATE_FORMAT__VALIDATE,
        ) ||
        (filters.rangeSelected &&
            !shiftUtils.isDateStringValid(
                filters.EndDate,
                shiftUtils.DATE_FORMAT__VALIDATE,
            ))
    ) {
        return;
    }
    yield put(
        spinner.actions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    yield call(getData, action);
    yield call(updateURLQueryString, filters);
    yield put(spinner.actions.setSpinnerState(false));
}

export function* getData(triggeringAction) {
    const filters = yield select(filtersModule.selectors.getFilters);
    const {
        shiftIdsAllShifts,
        shiftIdsSelectedShifts,
        ...params
    } = yield select(getMapFiltersToAPIParams);
    let shiftIdsSelected = shiftIdsSelectedShifts.all.join(API_ARRAY_DELIMITER);
    let shiftIdsAll = shiftIdsAllShifts.all.join(API_ARRAY_DELIMITER);

    if (
        (triggeringAction.type ===
            filtersModule.actions.UPDATE_FILTERS_WITH_URL_PARAMS &&
            filters.DateShortcut === 'Last Shift') ||
        (triggeringAction.type === filtersModule.actions.FILTER_UPDATED &&
            triggeringAction.payload.filterName === 'DateShortcut' &&
            triggeringAction.payload.filterValue === 'Last Shift')
    ) {
        const { response, error } = yield call(resourceSagas.getLatestShiftId);
        if (error) {
            errorLogging.logException(
                new Error('Failed to load any shift results, is the DB empty?'),
            );
            return;
        }
        const shiftId = response[0].ShiftId;
        const date = shiftUtils
            .createMomentInSiteTime(
                shiftId,
                shiftUtils.DATE_FORMAT__SHIFT_ID_DATE,
            )
            .format(shiftUtils.DATE_FORMAT__DISPLAY);

        yield put(
            filtersModule.actions.filterUpdatedBatch([
                { filterName: 'Date', filterValue: date },
                {
                    filterName: 'Shift',
                    filterValue: shiftUtils.shiftIdToShortName(shiftId),
                },
                { filterName: 'rangeSelected', filterValue: false },
            ]),
        );
        shiftIdsSelected = response[0].ShiftId;
        const startDate = moment(
            shiftIdsSelected,
            shiftUtils.DATE_FORMAT__SHIFT_ID_DATE,
        ).format(shiftUtils.DATE_FORMAT__DISPLAY);
        const allShiftShortNames = shiftUtils.getAllShiftShortNamesForDate(
            startDate,
        );
        shiftIdsAll = shiftUtils
            .generateShiftIdRange(startDate, startDate, [allShiftShortNames])
            .join(API_ARRAY_DELIMITER);
    }

    const [drivers, o4rOperator] = yield all([
        call(resourceSagas.getDrivers, {
            ShiftId: shiftIdsAll,
        }),
        call(resourceSagas.getO4ROperator, {
            ...params,
            ShiftId: shiftIdsSelected,
        }),
    ]);

    if (drivers.error) {
        yield put(driversModule.actions.fetchDriversSuccess([]));
    } else {
        yield put(driversModule.actions.fetchDriversSuccess(drivers.response));
    }

    if (o4rOperator.error) {
        yield put(
            o4rOperatorByDriverShift.actions.fetchO4rOperatorByDriverShiftSuccess(
                [],
            ),
        );
    } else {
        yield put(
            o4rOperatorByDriverShift.actions.fetchO4rOperatorByDriverShiftSuccess(
                o4rOperator.response,
            ),
        );
    }
}

export function* updateFiltersWithURLParams(action) {
    yield put(
        spinner.actions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const urlFilterValues = convertURLQueryStringToObject();
    yield put(
        filtersModule.actions.setFiltersWithUrlParams({
            ...urlFilterValues,
            InWorkshop:
                (urlFilterValues &&
                    urlFilterValues.InWorkshop &&
                    urlFilterValues.InWorkshop.toString()) ||
                '',
        }),
    );
    yield call(getData, action);
    yield put(spinner.actions.setSpinnerState(false));
}

export default function* watch() {
    yield all([
        takeLatest(
            filtersModule.actions.UPDATE_FILTERS_WITH_URL_PARAMS,
            updateFiltersWithURLParams,
        ),
        takeLatest(filtersModule.actions.FILTER_UPDATED, filterUpdated),
    ]);
}
