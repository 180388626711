import * as reactLeaflet from 'react-leaflet';
import Background from './Background';
import RoadLabels from './RoadLabels';
import WhereOnMinesite from './WhereOnMinesite';
import HRCEvents from './HRCEvents';
import RoadPath from './RoadPath';
import SurfaceRoughness from './SurfaceRoughness';
import Legend from './Legend';
import SpeedLimitedZones from './SpeedLimitedZones';
import RampEvents from './RampEvents';
import SpeedingInZones from './SpeedingInZones';
import FocusAreaLabels from './FocusAreaLabels';
import CornerEvents from './CornerEvents';
import MinesiteMap from './MinesiteMap';
import EventPaths from './EventPaths';
import TruckMarker from './TruckMarker';
import ReactMarker from './ReactMarker';
import MarkerPin from './MarkerPin';
import TruckAlertEvents from './TruckAlertEvents';
import UpdatingGeoJSON from './UpdatingGeoJSON';
import MapControl from './MapControl';
import TruckPath from './TruckPath';
import CustomLayersControl from './customLayersControl';
import Polygons from './Polygons';
// Add the components that are dependant on MinesiteMap
MinesiteMap.Background = Background;
MinesiteMap.RoadLabels = RoadLabels;
MinesiteMap.WhereOnMinesite = WhereOnMinesite;
MinesiteMap.HRCEvents = HRCEvents;
MinesiteMap.TruckAlertEvents = TruckAlertEvents;
MinesiteMap.RoadPath = RoadPath;
MinesiteMap.SurfaceRoughness = SurfaceRoughness;
MinesiteMap.Legend = Legend;
MinesiteMap.SpeedLimitedZones = SpeedLimitedZones;
MinesiteMap.RampEvents = RampEvents;
MinesiteMap.FocusAreaLabels = FocusAreaLabels;
MinesiteMap.CornerEvents = CornerEvents;
MinesiteMap.SpeedingInZones = SpeedingInZones;
MinesiteMap.EventPaths = EventPaths;
MinesiteMap.TruckMarker = TruckMarker;
MinesiteMap.ReactMarker = ReactMarker;
MinesiteMap.MarkerPin = MarkerPin;
MinesiteMap.UpdatingGeoJSON = UpdatingGeoJSON;
MinesiteMap.MapControl = MapControl;
MinesiteMap.TruckPath = TruckPath;
MinesiteMap.Polygons = Polygons;
// Re-export react-leaflet
// MinesiteMap.Leaflet.Circle = reactLeaflet.Circle;
// MinesiteMap.Leaflet.displayName = 'Circle';
// MinesiteMap.Leaflet.CircleMarker = reactLeaflet.CircleMarker;
// MinesiteMap.Leaflet.displayName = 'CircleMarker';
// MinesiteMap.Leaflet.DivOverlay = reactLeaflet.DivOverlay;
// MinesiteMap.Leaflet.displayName = 'DivOverlay';
// MinesiteMap.Leaflet.FeatureGroup = reactLeaflet.FeatureGroup;
// MinesiteMap.Leaflet.displayName = 'FeatureGroup';
// MinesiteMap.Leaflet.GeoJSON = reactLeaflet.GeoJSON;
// MinesiteMap.Leaflet.displayName = 'GeoJSON';
// MinesiteMap.Leaflet.GridLayer = reactLeaflet.GridLayer;
// MinesiteMap.Leaflet.displayName = 'GridLayer';
// MinesiteMap.Leaflet.ImageOverlay = reactLeaflet.ImageOverlay;
// MinesiteMap.Leaflet.displayName = 'ImageOverlay';
MinesiteMap.LayerGroup = reactLeaflet.LayerGroup;
MinesiteMap.LayerGroup.displayName = 'LayerGroup';
MinesiteMap.LayersControl = reactLeaflet.LayersControl;
MinesiteMap.LayersControl.displayName = 'LayersControl';
MinesiteMap.CustomLayersControl = CustomLayersControl;

// MinesiteMap.Leaflet.Map = reactLeaflet.Map;
// MinesiteMap.Leaflet.displayName = 'Map';
// MinesiteMap.Leaflet.MapComponent = reactLeaflet.MapComponent;
// MinesiteMap.Leaflet.displayName = 'MapComponent';
// MinesiteMap.Leaflet.MapControl = reactLeaflet.MapControl;
// MinesiteMap.Leaflet.displayName = 'MapControl';
// MinesiteMap.Leaflet.MapEvented = reactLeaflet.MapEvented;
// MinesiteMap.Leaflet.displayName = 'MapEvented';
// MinesiteMap.Leaflet.MapLayer = reactLeaflet.MapLayer;
// MinesiteMap.Leaflet.displayName = 'MapLayer';
MinesiteMap.Marker = reactLeaflet.Marker;
MinesiteMap.displayName = 'Marker';
MinesiteMap.Pane = reactLeaflet.Pane;
MinesiteMap.Pane.displayName = 'Pane';
// MinesiteMap.Leaflet.Path = reactLeaflet.Path;
// MinesiteMap.Leaflet.displayName = 'Path';
// MinesiteMap.Leaflet.Polygon = reactLeaflet.Polygon;
// MinesiteMap.Leaflet.displayName = 'Polygon';
// MinesiteMap.Leaflet.Polyline = reactLeaflet.Polyline;
// MinesiteMap.Leaflet.displayName = 'Polyline';
// MinesiteMap.Leaflet.Popup = reactLeaflet.Popup;
// MinesiteMap.Leaflet.displayName = 'Popup';
// MinesiteMap.Leaflet.Rectangle = reactLeaflet.Rectangle;
// MinesiteMap.Leaflet.displayName = 'Rectangle';
// MinesiteMap.Leaflet.ScaleControl = reactLeaflet.ScaleControl;
// MinesiteMap.Leaflet.displayName = 'ScaleControl';
// MinesiteMap.Leaflet.TileLayer = reactLeaflet.TileLayer;
// MinesiteMap.Leaflet.displayName = 'TileLayer';
MinesiteMap.Tooltip = reactLeaflet.Tooltip;
MinesiteMap.displayName = 'Tooltip';
// MinesiteMap.Leaflet.VideoOverlay = reactLeaflet.VideoOverlay;
// MinesiteMap.Leaflet.displayName = 'VideoOverlay';
// MinesiteMap.Leaflet.WMSTileLayer = reactLeaflet.WMSTileLayer;
// MinesiteMap.Leaflet.displayName = 'WMSTileLayer';
// MinesiteMap.Leaflet.ZoomControl = reactLeaflet.ZoomControl;
// MinesiteMap.Leaflet.displayName = 'ZoomControl';
export const withLeaflet = reactLeaflet.withLeaflet;
export const useLeaflet = reactLeaflet.useLeaflet;

// Utils
export {
    createClusterMarker,
    createIconMarker,
    createTextMarker,
} from './createMarker';
export { MAP_LAYER_LABELS, ZINDEX } from './constants';
export { default as getMapExtentBounds } from './utils/getMapExtentBounds';

export default MinesiteMap;
