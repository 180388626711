import { createSelector } from 'reselect';
import shiftUtils from '@rs/core/utils/shiftUtils';
import {
    filterOptionsToReactSelectFormat,
    filterShiftOptionsToReactSelectFormat,
} from '../../../Utils/Filters/filterOptionsToReactSelectFormat';
import { selectors as filtersSelectors } from '../Modules/filters';
import { selectors as maxMineAlertCategories } from '../Modules/maxMineAlertCategories';
import { SELECT_STRING_DELIMITER } from '../../../Components/Select';
import { compareString } from '../../../Lib/sortingUtils';

export const getFilterOptions = createSelector(
    filtersSelectors.getFilters,
    maxMineAlertCategories.getUniqueMaxMineAlertCategories,
    (filters, alertCategories) => {
        const shifts = shiftUtils.getAllShiftShortNamesForDate(filters.Date);
        return {
            shifts: filterShiftOptionsToReactSelectFormat(
                shifts.rawShiftsInDay,
                shifts.shiftShortNames,
            ),
            dateShortcuts: filterOptionsToReactSelectFormat([
                'Last Shift',
                'Last Day',
                'Last Week',
                'Last Month',
            ]),
            inWorkshop: [
                { label: 'Exclude Workshop', value: 0 },
                { label: 'Workshop Only', value: 1 },
            ],
            maxMineAlertCategories: filterOptionsToReactSelectFormat(
                alertCategories.sort((a, b) => compareString(b, a)),
            ),
        };
    },
);

export const getMapFiltersToAPIParams = createSelector(
    filtersSelectors.getFilters,
    (filters) => {
        const shiftIdsSelectedShifts = shiftUtils.generateShiftIdRange(
            filters.Date,
            filters.EndDate,
            filters.Shift.split(SELECT_STRING_DELIMITER),
        );
        const params = {
            shiftIdsSelectedShifts: shiftUtils.getAllFirstLastFromArray(
                shiftIdsSelectedShifts,
            ),
            InWorkshop: filters.InWorkshop,
        };
        if (filters.MaxMineAlertCategories.length > 0) {
            params.MaxMineAlertCategory = filters.MaxMineAlertCategories;
        }
        return params;
    },
);
