import { createReducer } from '../../Lib/reduxUtils';
import { SORT_TABLE } from '../Actions';

export const initialState = {
    sortBy: 'OperatorDisplayName',
    reverse: false,
};

export function sortTable(state, { sortingKey }) {
    const { sortBy, reverse } = state;
    return {
        sortBy: sortingKey,
        reverse: sortBy === sortingKey ? !reverse : reverse,
    };
}

export default createReducer(initialState, {
    [SORT_TABLE]: sortTable,
});
