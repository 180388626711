import { createSelector } from 'reselect';
import shiftUtils from '@rs/core/utils/shiftUtils';
import { compareString } from '@rs/core/utils/sortingUtils';
import {
    filterOptionsToReactSelectFormat,
    filterShiftOptionsToReactSelectFormat,
} from '../../../Utils/Filters/filterOptionsToReactSelectFormat';
import * as maxMineAlertCategoriesModule from '../Modules/maxMineAlertCategories';
import { selectors as filtersSelectors } from '../Modules/filters';
import { SELECT_STRING_DELIMITER } from '../../../Components/Select';

export const getFilterOptions = createSelector(
    filtersSelectors.getFilters,
    maxMineAlertCategoriesModule.selectors.getAll,
    (filters, maxMineAlertCategories) => {
        const shifts = shiftUtils.getAllShiftShortNamesForDate(filters.Date);
        return {
            shifts: filterShiftOptionsToReactSelectFormat(
                shifts.rawShiftsInDay,
                shifts.shiftShortNames,
            ),
            inWorkshop: [
                { label: 'Exclude Workshop', value: '0' },
                { label: 'Workshop Only', value: '1' },
            ],
            alertSeverities: [
                { label: 'Level 1', value: '1' },
                { label: 'Level 2', value: '2' },
                { label: 'Level 3', value: '3' },
            ],
            maxMineAlertCategories: filterOptionsToReactSelectFormat(
                maxMineAlertCategories.sort((a, b) => compareString(b, a)),
            ),
        };
    },
);

export const calculateShiftIdRange = (filters) => {
    const endDate = shiftUtils.createMomentInSiteTime(
        filters.Date,
        shiftUtils.DATE_FORMAT__VALIDATE,
    );
    const startDate = endDate.clone().subtract(30, 'days');
    return shiftUtils.generateShiftIdRange(
        startDate.format(shiftUtils.DATE_FORMAT__DISPLAY),
        endDate.format(shiftUtils.DATE_FORMAT__DISPLAY),
        filters.Shift.split(SELECT_STRING_DELIMITER),
    );
};

export const getMapFiltersToAPIParams = createSelector(
    filtersSelectors.getFilters,
    (filters) => {
        const shiftIdsSelectedShifts = calculateShiftIdRange(filters);
        const params = {
            shiftIdsSelectedShifts: shiftUtils.getAllFirstLastFromArray(
                shiftIdsSelectedShifts,
            ),
            InWorkshop: filters.InWorkshop,
        };

        if (filters.AlertSeverity.length > 0) {
            params.AlertLevel = filters.AlertSeverity;
        }

        if (filters.MaxMineAlertCategory.length > 0) {
            params.MaxMineAlertCategory = filters.MaxMineAlertCategory;
        }
        return params;
    },
);
