import * as React from 'react';
import { connect } from 'react-redux';
import shiftUtils from '@rs/core/utils/shiftUtils';
import { notification } from '@rs/core/falcon';
import moment from 'moment';
//TO-D0 fix the TS path resolve issue
//@ts-ignore
import DatePicker from '@rs/core/falcon/components/DatePicker/DatePickerOld';
import { getComponentConfig } from '../../App/Selectors';

interface DatepickerWithInputProps {
    onFilterChange: (a: any, b: any) => void;
    startDate: string;
    endDate: string;
    label: string;
    allowModeSwitch?: boolean;
    rangeSelected?: boolean;
    earliestDay?: Date;
    supportEmailAddress: string;
}

export function formatDate(date: moment.Moment): string {
    if (!date) {
        return '';
    }
    return date.format(shiftUtils.DATE_FORMAT__DISPLAY);
}

export function parseDate(date: string): null | moment.Moment {
    if (!date || !shiftUtils.isDateStringValid(date)) {
        return null;
    }
    return moment(date, shiftUtils.DATE_FORMAT__VALIDATE);
}

/**
 * Validates the range of the start / end dates
 * Returns true if the range is less than a year, false if otherwise
 * Displays warning if 1 month < range < 1 year
 * Displays error if range > 1 year
 *
 * @param start
 * @param end
 * @param supportEmailAddress
 */
export const validateRange = (
    start: moment.Moment,
    end: moment.Moment,
    supportEmailAddress: string,
): boolean => {
    if (!start || !end) {
        return true;
    }
    // Close any open notifications when the date is changed
    notification.close('DatePickerNotification');

    const differenceInDays = end.diff(start, 'days');
    if (differenceInDays < 0) {
        // End is before Start, no good
        return false;
    } else if (differenceInDays < 31) {
        // Less than a month, all good
        return true;
    } else if (differenceInDays < 365) {
        // More than a month but less than a year, show a warning and allow
        notification.warning({
            message: 'Large date range selected',
            description: [
                'Please note that requests with such a large range may take some time to load.',
                <br />,
                <br />,
                'If your request takes too long, please contact our service desk for assistance at',
                <br />,
                <a href={`mailto: ${supportEmailAddress}`}>
                    {supportEmailAddress}
                </a>,
            ],
            // Don't close automatically
            duration: 0,
            key: 'DatePickerNotification',
        });
        return true;
    } else {
        // Too large, show an error and disallow
        notification.error({
            message: 'Date Range Exceeds Limit',
            description: [
                'If you wish to request a date range longer than one year or you require a custom report, please contact our service desk at',
                <br />,
                <a href={`mailto: ${supportEmailAddress}`}>
                    {supportEmailAddress}
                </a>,
            ],
            duration: 10,
            key: 'DatePickerNotification',
        });
        return false;
    }
};

const DatepickerWithInput = ({
    onFilterChange,
    startDate,
    endDate,
    label,
    allowModeSwitch,
    rangeSelected,
    earliestDay,
    supportEmailAddress,
}: DatepickerWithInputProps) => {
    const onStartDateChange = (date: any) => onFilterChange('Date', date);
    const onEndDateChange = (date: any) => onFilterChange('EndDate', date);
    const onModeChange = (mode: any) =>
        onFilterChange('rangeSelected', mode === 'range');
    const earliestDayMoment = moment(earliestDay);

    return (
        <div className={'DatepickerWithInput'}>
            <DatePicker
                label={label}
                onChange={(value: any) => {
                    if (rangeSelected) {
                        // If handling a range picker
                        const startMoment = value[0];
                        const endMoment = value[1];
                        if (
                            validateRange(
                                startMoment,
                                endMoment,
                                supportEmailAddress,
                            )
                        ) {
                            // Only send the changes if validated
                            onEndDateChange(formatDate(endMoment));
                            onStartDateChange(formatDate(startMoment));
                        }
                    } else {
                        // If handling a single date picker
                        onStartDateChange(formatDate(value));
                    }
                }}
                // Disable all dates earlier than the first shift pattern we have
                disabledDate={(date: any) => date.isBefore(earliestDayMoment)}
                mode={rangeSelected ? 'range' : 'single'}
                allowModeSwitch={allowModeSwitch}
                onModeChange={onModeChange}
                value={
                    rangeSelected
                        ? [parseDate(startDate), parseDate(endDate)]
                        : parseDate(startDate)
                }
            />
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    // Get the support email address to display in the 30+ days notification box
    supportEmailAddress: getComponentConfig(state, 'AppLanding')
        .supportEmailAddress,
});

export default connect(mapStateToProps)(DatepickerWithInput);
