import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_HAUL_ROAD_CONDITION_EVENTS_SUCCESS = `${namespace}/FETCH_HAUL_ROAD_CONDITION_EVENTS_SUCCESS`;
    const fetchHaulRoadConditionEventsSuccess = (resultsArray) =>
        action(FETCH_HAUL_ROAD_CONDITION_EVENTS_SUCCESS, resultsArray);

    const FETCH_HAUL_ROAD_CONDITION_EVENTS_ERROR = `${namespace}/FETCH_HAUL_ROAD_CONDITION_EVENTS_ERROR`;
    const fetchHaulRoadConditionEventsError = ({ error }) =>
        action(FETCH_HAUL_ROAD_CONDITION_EVENTS_ERROR, { error });

    return {
        FETCH_HAUL_ROAD_CONDITION_EVENTS_SUCCESS,
        fetchHaulRoadConditionEventsSuccess,
        FETCH_HAUL_ROAD_CONDITION_EVENTS_ERROR,
        fetchHaulRoadConditionEventsError,
    };
}
