import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_O4R_OPERATOR_BY_DRIVER_SHIFT_SUCCESS = `${namespace}/FETCH_O4R_OPERATOR_BY_DRIVER_SHIFT_SUCCESS`;
    const fetchO4rOperatorByDriverShiftSuccess = (resultsArray) =>
        action(FETCH_O4R_OPERATOR_BY_DRIVER_SHIFT_SUCCESS, resultsArray);

    const FETCH_O4R_OPERATOR_BY_DRIVER_SHIFT_ERROR = `${namespace}/FETCH_O4R_OPERATOR_BY_DRIVER_SHIFT_ERROR`;
    const fetchO4rOperatorByDriverShiftError = ({ error }) =>
        action(FETCH_O4R_OPERATOR_BY_DRIVER_SHIFT_ERROR, { error });

    return {
        FETCH_O4R_OPERATOR_BY_DRIVER_SHIFT_SUCCESS,
        fetchO4rOperatorByDriverShiftSuccess,
        FETCH_O4R_OPERATOR_BY_DRIVER_SHIFT_ERROR,
        fetchO4rOperatorByDriverShiftError,
    };
}
