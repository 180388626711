import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Layout as AntLayout, Drawer, Col } from 'antd';
import MediaQuery from 'react-responsive';
import Row from '../Row/Row';

const Layout = ({ ...props }) => <AntLayout className={'Layout'} {...props} />;

Layout.SubLayout = ({ ...props }) => (
    <AntLayout className={'Layout__SubLayout'} {...props} />
);

Layout.Header = ({ ...props }) => (
    <AntLayout.Header className={'Layout__Header'} {...props} />
);

Layout.Footer = ({ ...props }) => (
    <AntLayout.Footer className={'Layout__Footer'} {...props} />
);

Layout.SubHeader = ({ children, ...props }) => (
    <AntLayout.Header className={'Layout__SubHeader'} {...props}>
        <Row type={'flex'} align={'middle'}>
            {children}
        </Row>
    </AntLayout.Header>
);

Layout.SubHeader.Left = ({ ...props }) => (
    <Col className={'Layout__SubHeader__Left'} span={12} {...props} />
);
Layout.SubHeader.Right = ({ ...props }) => (
    <Col className={'Layout__SubHeader__Right'} span={12} {...props} />
);
Layout.SubHeader.Title = ({ ...props }) => (
    <strong className={'Layout__Title'} {...props} />
);

Layout.Sider = ({ onClose, collapse, showMenu, ...props }) => {
    // If the page screen has a small enough width,
    // render the menu inside a Drawer component
    return collapse ? (
        <Drawer
            className={'Layout__Drawer'}
            // The open/close state of the Drawer is controlled on this prop
            visible={showMenu}
            // Pass through the onClose handler (probably to update showMenu)
            onClose={onClose}
            // Put the Drawer on the left side of the screen
            placement={'left'}
            // Make the Drawer and the Sider the same width
            width={props.width}
            // Hide the (x) button in the top right corner
            closable={false}
            // Render the drawer inside the parent instead of document.body
            getContainer={null}
        >
            <AntLayout.Sider className={'Layout__Sider'} {...props} />
        </Drawer>
    ) : (
        <AntLayout.Sider className={'Layout__Sider'} {...props} />
    );
};

Layout.Content = ({ ...props }) => (
    <AntLayout.Content className={'Layout__Content'} {...props} />
);

Layout.Logo = ({ className }) => (
    <div className={cn('Layout__Logo__Container', className)}>
        <div className={'Layout__Logo'} />
    </div>
);

// Use a media query to determine if on mobile or a desktop and then
// pass the variable using the Render Props Pattern
Layout.Page = ({ collapsedWidth, ...props }) => (
    <div className={'Layout__Page'}>
        <MediaQuery query={`(max-width: ${collapsedWidth})`}>
            {(collapse) => (
                <Layout {...props}>{props.children(collapse)}</Layout>
            )}
        </MediaQuery>
    </div>
);

Layout.SubHeader.propTypes = {
    children: PropTypes.node,
};

Layout.Logo.propTypes = {
    className: PropTypes.string,
};

Layout.Sider.propTypes = {
    showMenu: PropTypes.bool,
    width: PropTypes.number,
    onClose: PropTypes.func,
    collapse: PropTypes.bool,
};

Layout.Page.propTypes = {
    collapsedWidth: PropTypes.string,
    children: PropTypes.func,
};

// This fixes the issue where the components show up as "unknown" components in storybook
Layout.SubLayout.displayName = 'Layout.SubLayout';
Layout.Header.displayName = 'Layout.Header';
Layout.Footer.displayName = 'Layout.Footer';
Layout.Sider.displayName = 'Layout.Sider';
Layout.Content.displayName = 'Layout.Content';
Layout.Logo.displayName = 'Layout.Logo';
Layout.Page.displayName = 'Layout.Page';

export default Layout;
