import * as React from 'react';

function SvgStatusTooLongAtNode(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <circle cx={512} cy={512} r={498.84} fill="#fff" />
            <path d="M512 50a462.14 462.14 0 01179.82 887.71A462.13 462.13 0 01332.18 86.29 459.06 459.06 0 01512 50m0-50C229.23 0 0 229.23 0 512s229.23 512 512 512 512-229.23 512-512S794.77 0 512 0z" />
            <path d="M512 190.31c-177.65 0-321.69 144-321.69 321.69S334.35 833.69 512 833.69 833.69 689.65 833.69 512 689.65 190.31 512 190.31zm0 588.81c-147.49 0-267.12-119.63-267.12-267.12S364.51 244.88 512 244.88 779.12 364.51 779.12 512 659.49 779.12 512 779.12z" />
            <path d="M631.34 621.57L529 547.53V369.81a5.76 5.76 0 00-5.75-5.74h-34.59a5.75 5.75 0 00-5.74 5.74v197.76a5.67 5.67 0 002.37 4.66l118.77 86.6a5.79 5.79 0 008-1.22l20.54-28a5.73 5.73 0 00-1.3-8z" />
        </svg>
    );
}

export default SvgStatusTooLongAtNode;
