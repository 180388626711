import { createReducer } from '../../Lib/reduxUtils';

export const initialState = {
    minesite: {
        circuits: {},
        'holding-states': {},
        'rest-of-minesite': [],
    },
    equipment: [],
};

/**
 * Returns the same object but with the id field set on the object
 * @param {object} objectById
 * @return {object}
 */
function withId(objectById) {
    return Object.entries(objectById).reduce((collection, [key, value]) => {
        collection[key] = {
            ...value,
            id: key,
        };
        return collection;
    }, {});
}

export const onSuccess = (state, { minesite, equipment }) => {
    const circuits = (minesite && minesite.circuits) || {};
    const holdingStates = (minesite && minesite['holding-states']) || {};
    return {
        minesite: {
            ...minesite,
            // Adding ids because it's more convenient
            circuits: withId(circuits),
            'holding-states': withId(holdingStates),
        },
        equipment,
    };
};

/**
 * Creates a reducer for this module. It has some default behaviour but also allows custom actions to be supplied
 * @param {object} actions {<internalActionName>: <ACTION_TYPE>} maps an internalActionName to an ACTION_TYPE. The handler for this ACTION_TYPE is the default behaviour
 * @param {object} customActions {<ACTION_TYPE>: actionHandler} maps an ACTION_TYPE to an action handler
 * @return {function(*=, {type: *, payload?: *})}
 */
export default function createReducers(actions, customActions = {}) {
    return createReducer(initialState, {
        [actions.onSuccess]: onSuccess,
        /**
         * Ensure any custom action handlers pass along state + any additional arguments needed for the
         * action handlers
         */
        ...customActions,
    });
}
