import { takeLatest, call, put, all } from 'redux-saga/effects';
import { forwardTo } from '../App/createHistory';
import * as resourceSagas from './resourceSagas';
import { actions as spinnerActions } from '../EditGroup/Modules/spinner';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import { actions as groupsActions } from '../EditGroup/Modules/groups';
import { actions as featuresActions } from '../EditGroup/Modules/features';
import { actions as editFormActions } from '../EditGroup/Modules/editForm';

function* editGroup({ payload }) {
    yield put(
        spinnerActions.setSpinnerState(
            true,
            LOADING_MESSAGES.LOADING__PROCESSING,
        ),
    );

    const apiResponse = yield call(resourceSagas.patchEditGroup, {
        Id: payload.groupInfo.id,
        Name: payload.groupInfo.name,
        Description: payload.groupInfo.description,
        Features: payload.groupInfo.features.filter(
            (feature) =>
                feature.Status === true && feature.MaxmineControlled === 2,
        ),
    });

    if (apiResponse.error) {
        yield put(editFormActions.editFormError(apiResponse.error));
        yield put(spinnerActions.setSpinnerState(false));
    } else {
        yield put(editFormActions.editFormError(null));
        yield put(spinnerActions.setSpinnerState(false));
        forwardTo(`/manage-group`);
    }
}

function* loadFeatures() {
    const { response, error } = yield call(resourceSagas.getFeatureList);
    if (error) {
        return;
    }
    yield all([put(featuresActions.fetchFeaturesSuccess(response.rows))]);
}

function* loadGroups() {
    yield put(
        spinnerActions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA),
    );
    const { response, error } = yield call(resourceSagas.getGroupList);

    if (error) {
        yield editFormActions.editFormError({ error });
        return;
    }
    yield call(loadFeatures);
    yield all([
        put(groupsActions.fetchGroupsSuccess(response.rows)),
        put(spinnerActions.setSpinnerState(false)),
    ]);
}

export default function* watch() {
    yield all([
        takeLatest(groupsActions.FETCH_GROUPS_REQUESTED, loadGroups),
        takeLatest(editFormActions.SUBMIT_EDIT_GROUP, editGroup),
    ]);
}
