import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    Features,
    MaterialMovementsGeojson,
    SpeedZonesGeojson,
    TrackingRegionsGeojson,
    WomidsGeojson,
} from './types';

export type State = {
    speedZonesGeojson: SpeedZonesGeojson;
    womidsGeojson: WomidsGeojson;
    trackingRegionsGeojson: TrackingRegionsGeojson;
    materialMovementsGeojson: MaterialMovementsGeojson;
};

export const emptyFeatureCollection: Features = {
    type: 'FeatureCollection',
    features: [],
};
export const initialState: State = {
    speedZonesGeojson: emptyFeatureCollection,
    womidsGeojson: emptyFeatureCollection,
    trackingRegionsGeojson: emptyFeatureCollection,
    materialMovementsGeojson: emptyFeatureCollection,
};

export const createSliceWithName = (name: string, initialState: State) =>
    createSlice({
        name,
        initialState,
        reducers: {
            setSpeedZonesGeojson: (
                state: State,
                action: PayloadAction<SpeedZonesGeojson>,
            ) => {
                state.speedZonesGeojson = action.payload;
            },
            setWomidsGeojson: (
                state: State,
                action: PayloadAction<WomidsGeojson>,
            ) => {
                state.womidsGeojson = action.payload;
            },
            setTrackingRegionsGeojson: (
                state: State,
                action: PayloadAction<TrackingRegionsGeojson>,
            ) => {
                state.trackingRegionsGeojson = action.payload;
            },
            setMaterialMovementsGeojson: (
                state: State,
                action: PayloadAction<MaterialMovementsGeojson>,
            ) => {
                state.materialMovementsGeojson = action.payload;
            },
        },
    });
