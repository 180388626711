import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_CORNER_EVENTS_SUCCESS = `${namespace}/FETCH_CORNER_EVENTS_SUCCESS`;
    const fetchCornerEventsSuccess = (resultsArray) =>
        action(FETCH_CORNER_EVENTS_SUCCESS, resultsArray);

    const FETCH_CORNER_EVENTS_ERROR = `${namespace}/FETCH_CORNER_EVENTS_ERROR`;
    const fetchCornerEventsError = ({ error }) =>
        action(FETCH_CORNER_EVENTS_ERROR, { error });

    return {
        FETCH_CORNER_EVENTS_SUCCESS,
        fetchCornerEventsSuccess,
        FETCH_CORNER_EVENTS_ERROR,
        fetchCornerEventsError,
    };
}
