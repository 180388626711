import { LatLngBounds } from 'leaflet';

/**
 * Converts a Leaflet LatLngBounds object to a simple array of lat/longs
 */
export default function boundsToLatLngArray(bounds: LatLngBounds): number[][] {
    return [
        // The order of these are very important!
        [bounds.getNorth(), bounds.getWest()],
        [bounds.getSouth(), bounds.getWest()],
        [bounds.getSouth(), bounds.getEast()],
        [bounds.getNorth(), bounds.getEast()],
    ];
}
