import { createSelector } from 'reselect';
import * as powerBiReportsModule from '../Modules/powerBiReports';

const getError = createSelector(
    powerBiReportsModule.selectors.getAll,
    (powerBiReportsState) => {
        const { error } = powerBiReportsState;
        return error;
    },
);
export default getError;
