import { arrayToObject } from '@rs/core/utils/dataUtils';
import { createReducer } from '../../Lib/reduxUtils';

const initialState = {
    byId: {},
};

export function onSuccess(state, results) {
    const groups = results.map((group) => ({
        ...group,
        groupId: group.Id,
    }));
    const byId = arrayToObject(groups, 'groupId');
    return {
        byId,
    };
}

export function onError() {
    return initialState;
}

export function reset() {
    return initialState;
}

export default function createReducers(actions, customActions = {}) {
    return createReducer(initialState, {
        [actions.onSuccess]: onSuccess,
        [actions.onError]: onError,
        /**
         * Ensure any custom action handlers pass along state + any additional arguments needed for the
         * action handlers
         */
        ...customActions,
    });
}
