import createActions from '../../Modules/minesiteAreasTree/actions';
import createReducer from '../../Modules/minesiteAreasTree/reducer';
import createSelectors from '../../Modules/minesiteAreasTree/selectors';
import namespace from './namespace';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].minesiteAreasTree;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);

// TODO the reducer should get created here but it won't work with
// the current reducer setup of Tyres & Safety.
export const reducer = createReducer({
    onSuccess: actions.FETCH_MINESITE_AREAS_TREE_SUCCESS,
    onError: actions.FETCH_MINESITE_AREAS_TREE_ERROR,
});

export const selectors = createSelectors(statePath);
