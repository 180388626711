export default function createSelectors(statePath) {
    function getAll(state) {
        return statePath(state);
    }

    function isInProgress(state) {
        const s = getAll(state);
        return s.inProgress;
    }

    function hasError(state) {
        const s = getAll(state);
        return !s.inProgress && s.error !== '';
    }

    return {
        getAll,
        isInProgress,
        hasError,
    };
}
