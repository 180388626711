/**
 * To give flexibility to some components, a prop can be a string for simple cases or a function
 * for complex scenarios
 * @param {string|Function} maybeFn - a string or a function
 * @param {*} data - Any arguments that could be passed to a function
 * @return {*}
 */
export function callOrValue(maybeFn, data) {
    if (typeof maybeFn === 'function') {
        return maybeFn(data);
    }
    return maybeFn;
}
