import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { LoadUnitImprovementSymbolProps } from './types';

export const ARROW_DIRECTIONS = {
    OFFSIDE: 0,
    ONSIDE: 4,
    HEADBOARD: 2,
    REAR_OF_TRAY: 6,
    TRUCK_FRONT_RIGHT: 1,
    TRUCK_FRONT_LEFT: 3,
    TRUCK_BACK_RIGHT: 7,
    TRUCK_BACK_LEFT: 5,
};

type Props = LoadUnitImprovementSymbolProps & {
    arrowDirection?: number;
};

const PayloadBiasDirectionSymbol = ({ arrowDirection, className }: Props) => (
    <FontAwesomeIcon
        className={cn('LoadUnitImprovementSymbol__Icon', className)}
        style={{ transform: `rotate(${arrowDirection * -45}deg)` }}
        icon="long-arrow-alt-right"
    />
);

export default PayloadBiasDirectionSymbol;
