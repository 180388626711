import { action, createReducer } from '../../Lib/reduxUtils';
import namespace from './namespace';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].editForm;

/**
 * Create the module parts - Actions
 */
const SUBMIT_ASSIGN_GROUPS = `${namespace}/SUBMIT_ASSIGN_GROUPS`;
const submitAssignGroup = (userInfo) => {
    return action(SUBMIT_ASSIGN_GROUPS, { userInfo });
};

const EDIT_FORM_RESET = `${namespace}/EDIT_FORM_RESET`;
const editFormReset = () => action(EDIT_FORM_RESET);

const EDIT_FORM_ERROR = `${namespace}/EDIT_FORM_ERROR`;

const editFormError = (apiErrorMessage) => {
    return action(EDIT_FORM_ERROR, apiErrorMessage);
};

export const actions = {
    SUBMIT_ASSIGN_GROUPS,
    submitAssignGroup,
    EDIT_FORM_RESET,
    editFormReset,
    EDIT_FORM_ERROR,
    editFormError,
};

/**
 * Create the module parts - Reducer
 */
const initialState = {
    errorMessage: '',
};

function reset() {
    return initialState;
}

function setEditFormError(state, error) {
    return {
        ...state,
        errorMessage: error,
    };
}
export const reducer = createReducer(initialState, {
    [EDIT_FORM_RESET]: reset,
    [SUBMIT_ASSIGN_GROUPS]: reset,
    [EDIT_FORM_ERROR]: setEditFormError,
});

/**
 * Create the module parts - Selectors
 */
const getEditForm = (state) => statePath(state);
const getApiErrorMessage = (state) => state.EditUser.editForm.errorMessage;
export const selectors = {
    getEditForm,
    getApiErrorMessage,
};
