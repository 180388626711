import { action } from '../../Lib/reduxUtils';
import createActions from '../../Modules/minesiteAreasTree/actions';
import { GoogleAnalyticsEventBuilder } from '../../Utils/userAnalytics/googleAnalyticsEventBuilder';

const NAMESPACE = 'LeagueTable';

export const LOAD_DRIVERS = `${NAMESPACE}/LOAD_DRIVERS`;
export const loadDrivers = (arrayOfDrivers) =>
    action(LOAD_DRIVERS, arrayOfDrivers);

export const FILTER_UPDATED = `${NAMESPACE}/FILTER_UPDATED`;
export const filterUpdated = (filterName, filterValue) =>
    action(
        FILTER_UPDATED,
        { filterName, filterValue },
        {
            analytics: new GoogleAnalyticsEventBuilder(
                'SubFeatureUsage',
                'Filter Updated',
            )
                .setLabel(NAMESPACE)
                .build(),
        },
    );

export const LOAD_CORNER_LEAGUE = `${NAMESPACE}/LOAD_CORNER_LEAGUE`;
export const loadCornerLeague = (result) => action(LOAD_CORNER_LEAGUE, result);

export const FETCH_CONDITIONAL_LIMIT_EVENTS = `${NAMESPACE}/FETCH_CONDITIONAL_LIMIT_EVENTS`;
export const fetchConditionalLimitEevents = (arrayOfEvents) =>
    action(FETCH_CONDITIONAL_LIMIT_EVENTS, arrayOfEvents);

export const SORT_TABLE = `${NAMESPACE}/SORT_TABLE`;
export const sortTable = (sortingKey) => action(SORT_TABLE, { sortingKey });

export const UPDATE_FILTERS_WITH_URL_PARAMS = `${NAMESPACE}/UPDATE_FILTERS_WITH_URL_PARAMS`;
export const updateFiltersWithUrlParams = () =>
    action(UPDATE_FILTERS_WITH_URL_PARAMS);

export const SET_FILTERS_WITH_URL_PARAMS = `${NAMESPACE}/SET_FILTERS_WITH_URL_PARAMS`;
export const setFiltersWithUrlParams = (filtersFromUrl) =>
    action(SET_FILTERS_WITH_URL_PARAMS, filtersFromUrl);

export const SET_SPINNER_STATE = `${NAMESPACE}/SET_SPINNER_STATE`;
export const setSpinnerState = (isActive = false, message = '', faIcon) =>
    action(SET_SPINNER_STATE, { isActive, message, faIcon });

export const LINK_TO_PAGE = `${NAMESPACE}/LINK_TO_PAGE`;
export const linkToPage = ({ url, worker }) =>
    action(LINK_TO_PAGE, { url, worker });

export const {
    FETCH_MINESITE_AREAS_TREE_SUCCESS,
    fetchMinesiteAreasTreeSuccess,
    FETCH_MINESITE_AREAS_TREE_ERROR,
    fetchMinesiteAreasTreeError,
} = createActions('LeagueTable');
