/**
 * Calculates the average of numbers in an array
 *
 * WARNING: Do not use this to averages values that are already averages, Please used getAverageOfAveragesInArray
 * instead!
 *
 * @param {array} arr - The array to iterate over
 * @param {number} total - The total number of results, usually the array length
 * @param {string} key - If the array has objects, the key determines which property to use
 * @returns {number}
 */
export function getAverageOfArray(arr, total, key) {
    const sum = arr.reduce((a, b) => {
        const bVal = key ? b[key] : b;
        return a + (bVal || 0);
    }, 0);
    const result = sum / total;
    if (Number.isFinite(result)) {
        return result;
    }
    return 0;
}

/**
 * The calculates the averages of a list of averages and their corresponding denominator that was used to achieve each average.
 * i.e. Assume an array as follows:
 *
 * [{'AverageSpeed': 50, 'Count': 100'}, {'AverageSpeed': 2, 'Count': 1'}]
 *
 * Then calling this method getAverageOfAveragesInArray() should result in (50*100 + 2*1) / 101 = 49.525
 *
 * @param {array} arr - The array to iterate over
 * @param {string} keyForAverageValue - This is the key for the average value
 * @param {string} keyForDenominator - This is the key for the denominator.
 *
 * @param {number} - The resulting average taking into account the denominator.
 */
export function getAverageOfAveragesInArray(
    arr,
    keyForAverageValue,
    keyForDenominator,
) {
    const initialValues = { totalAvgTimesDenominator: 0, totalDenominator: 0 };

    const finalValues = arr.reduce((runningValues, thisRecords) => {
        runningValues.totalAvgTimesDenominator +=
            thisRecords[keyForAverageValue] * thisRecords[keyForDenominator];
        runningValues.totalDenominator += thisRecords[keyForDenominator];

        return runningValues;
    }, initialValues);

    if (finalValues.totalDenominator === 0) {
        return 0;
    }
    const result =
        finalValues.totalAvgTimesDenominator / finalValues.totalDenominator;

    if (Number.isFinite(result)) {
        return result;
    }
    return 0;
}

export function getConformancePercent(count, total) {
    return (1 - count / total) * 100;
}

/**
 * Calculates the percentage of two numbers & rounds
 * @param {number} numerator
 * @param {number} denominator
 * @param {number} roundToPlace
 * @return {number}
 */
export const getRoundedPercent = (numerator, denominator, roundToPlace = 0) => {
    const utilisationPercent = (numerator / denominator) * 100;

    // Handle when denominator is zero
    return Number.isFinite(utilisationPercent)
        ? roundTo(utilisationPercent, roundToPlace)
        : 0;
};

export function getMaxOfArray(numArray) {
    numArray = numArray.map((value) => {
        /* eslint-disable eqeqeq */
        // TODO This should be using '===' but I don't want to change the behaviour without tests
        if (value == undefined) {
            /* eslint-enable */
            value = 0;
        }
        return value;
    });
    const result = Math.max.apply(null, numArray);
    if (Number.isFinite(result)) {
        return result;
    }
    return 0;
}

export function getMinOfArray(numArray) {
    numArray = numArray.map((value) => {
        /* eslint-disable eqeqeq */
        // TODO This should be using '===' but I don't want to change the behaviour without tests
        if (value == undefined) {
            /* eslint-enable */
            value = 0;
        }
        return value;
    });
    const result = Math.min.apply(null, numArray);
    if (Number.isFinite(result)) {
        return result;
    }
    return 0;
}

export function roundTo(num, rounding) {
    /* eslint-disable prefer-template */
    return +(Math.round(num + `e+${rounding}`) + `e-${rounding}`);
    /* eslint-enable */
}

/**
 * Returns the sum of an array. Array can have objects or be a flat array.
 * @param dataArray
 * @param key
 * @returns {*}
 */
export function sumNumbers(dataArray, key) {
    return dataArray.reduce((sum, record) => {
        if (key) {
            return sum + record[key];
        }
        return sum + record;
    }, 0);
}

/**
 * Takes an array of values and calculates the quartile points
 * @param arrayOfValues
 * @returns {{q1: *, q2: *, q3: *}}
 */
export function calculateQuartiles(arrayOfValues) {
    const q1Index = (arrayOfValues.length + 1) / 4;
    const q1 = getQuartileValue(arrayOfValues, q1Index);
    const q2Index = (arrayOfValues.length + 1) / 2;
    const q2 = getQuartileValue(arrayOfValues, q2Index);
    const q3Index = (3 * (arrayOfValues.length + 1)) / 4;
    const q3 = getQuartileValue(arrayOfValues, q3Index);

    return {
        q1,
        q2,
        q3,
    };

    function getQuartileValue(arr, index) {
        if (arr.length === 0) {
            return undefined;
        }
        if (arr.length === 1) {
            return arr[0];
        }
        if (index % 2 !== 0) {
            const minIndex = Math.floor(index);
            const maxIndex = Math.ceil(index);
            return (arr[minIndex] + arr[maxIndex]) / 2;
        }
        return arr[index];
    }
}

/**
 * Same as roundTo, but returns a null if num is a null (roundTo returns a NaN when passed a null)
 */
export function nullRoundTo(num, rounding) {
    if (num === null) {
        return null;
    }
    return roundTo(num, rounding);
}

/**
 * Returns the number or min/max if number is outside bounds
 * @param min {number} - The min allowed number
 * @param max {number} - The max allowed number
 * @param number {number} - The number to check
 * @returns {number}
 */
export function getNumberWithinBounds(min, max, number) {
    return Math.min(Math.max(number, min), max);
}
