import { objectToArray } from '@rs/core/utils/dataUtils';

export default function createSelectors(statePath) {
    function getAll(state) {
        const slice = statePath(state);
        return slice.byId;
    }

    function getAllAsArray(state) {
        const slice = statePath(state);
        return objectToArray(slice.byId);
    }

    function getById(state, id) {
        const slice = statePath(state);
        return slice.byId[id];
    }

    return {
        getAll,
        getAllAsArray,
        getById,
    };
}
