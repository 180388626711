import { combineReducers } from 'redux';
import { reducer as filters } from '../Modules/filters';
import { reducer as spinner } from '../Modules/spinner';
import { reducer as equipmentActivityBasic } from '../Modules/equipmentActivityBasic';
import { reducer as equipmentOperatorAlertEvents } from '../Modules/equipmentOperatorAlertEvents';
import { reducer as equipmentIdentifiers } from '../Modules/equipmentIdentifiers';
import { reducer as operators } from '../Modules/operators';
import namespace from '../Modules/namespace';

export const REDUCER_KEY = namespace;
export default combineReducers({
    filters,
    spinner,
    equipmentActivityBasic,
    equipmentOperatorAlertEvents,
    equipmentIdentifiers,
    operators,
});
