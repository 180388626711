import { action } from '../../Lib/reduxUtils';

export const SUBMIT_CREATE_GROUP = 'CreateNewGroup/SUBMIT_CREATE_GROUP';
export const submitCreateGroup = (groupInfo) => {
    return action(SUBMIT_CREATE_GROUP, { groupInfo });
};

export const CREATE_GROUP_ERROR = 'CreateNewGroup/CREATE_GROUP_ERROR';
export const createGroupError = (apiErrorMessage) => {
    return action(CREATE_GROUP_ERROR, apiErrorMessage);
};

export const LOAD_FEATURES_LIST = 'CreateNewGroup/LOAD_FEATURES_LIST';
export const loadFeaturesList = (featuresList) => {
    return action(LOAD_FEATURES_LIST, featuresList);
};

export const REQUEST_FEATURES_LIST = 'CreateNewGroup/REQUEST_FEATURES_LIST';
export const requestFeaturesList = () => action(REQUEST_FEATURES_LIST);
