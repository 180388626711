import React from 'react';
import PropTypes from 'prop-types';
import { ConformanceCell } from '@rs/core/components';
import { typeTableColumn, typeTableValue } from './tablePropTypes';
import conformancePng from '../../styles/conformancePng1x1';
import withComponentConfig from '../../HOC/withComponentConfig';

const ConformanceCellWithConfig = withComponentConfig(
    'ConformanceDefault',
    ConformanceCell,
);

const LeagueTableConformanceCell = ({
    column,
    index,
    value,
    isForPrinting = false,
}) => {
    if (!isForPrinting) {
        return (
            <ConformanceCellWithConfig
                key={`${index}-${column.key}`}
                conformanceValue={value}
                roundToPlace={1}
                className="Table__Cell--Number LeagueTable--ConformanceCell"
            />
        );
    }
    return (
        <ConformanceCellWithConfig
            key={`${index}-${column.key}`}
            conformanceValue={value}
            roundToPlace={1}
            render={({ formattedValue, conformanceClassName }) => (
                <td className="LeagueTableConformanceCell Table__Cell--Number">
                    <div className="LeagueTableConformanceCell__Wrapper">
                        <img
                            src={`${conformancePng[conformanceClassName]}`}
                            alt=""
                            className="LeagueTableConformanceCell__BackgroundImg"
                        />
                        <div className="LeagueTableConformanceCell__Value">
                            {formattedValue}
                        </div>
                    </div>
                </td>
            )}
        />
    );
};

LeagueTableConformanceCell.propTypes = {
    column: typeTableColumn,
    index: PropTypes.bool.isRequired,
    value: typeTableValue,
    isForPrinting: PropTypes.bool,
};

export default LeagueTableConformanceCell;
