/**
 * Converts filters with values into a filename
 *
 * NOTE: Invalid characters such as \/:*?"<>| get converted to whatever the browser deems fit
 * We are currently abusing this by using characters such as '|' '/' so it may have some unexpected results
 *
 * See for more info - https://en.wikipedia.org/wiki/Filename#Comparison_of_filename_limitations
 *
 * @param {string} filename - Normally the name of the page or a specific report if there are multiple
 * @param {string} minesiteDisplayName
 * @param {object} filters
 * @return {string}
 */
export default function filtersToFilename(
    filename,
    filters,
    minesiteDisplayName,
) {
    const separator = '_';
    const identity = (v) => v;
    const formatOperatorName = (v) =>
        String(v).replace(/ /g, '').replace(/,/g, '');
    const formatDate = (v) => v.replace(/\//g, '-');

    const filterFormatters = {
        Date: formatDate,
        EndDate: formatDate,
        Shift: identity,
        Loader: identity,
        Operator: formatOperatorName,
        TruckType: identity,
        TruckId: identity,
        Crew: (v) => v.replace(/ /g, '-'),
        Worker: formatOperatorName,
        MaxMineAlertCategories: identity,
    };

    const displayOrder = [
        'Date',
        'EndDate',
        'Shift',
        'Crew',
        'Loader',
        'TruckType',
        'TruckId',
        'Operator',
        'Worker',
        'MaxMineAlertCategories',
    ];

    const filenameParts = [filename];
    if (minesiteDisplayName) {
        filenameParts.unshift(minesiteDisplayName);
    }

    const parts = displayOrder.reduce((args, key) => {
        const formatter = filterFormatters[key];
        const value = filters[key];
        if (value && formatter) {
            args.push(formatter(value));
        }
        return args;
    }, filenameParts);

    return parts.join(separator);
}
