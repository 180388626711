export const NODE_TYPES = {
    HAUL: 'haul',
    LOAD: 'load',
    DUMP: 'dump',
    RETURN: 'return',
    TRANSIT: 'transit-nodes',
    ANCILLARY: 'ancillary',
};

/**
 * Normalises the access of a node of a particular type
 * @param {string} type
 * @param {object} record
 * @return {undefined|*}
 */
export function getNodeByType(type, record) {
    // For holding states
    if (record && Array.isArray(record[type])) {
        if (type === NODE_TYPES.TRANSIT) {
            // Transit nodes have a number of possible incoming nodes
            return record[type];
        }
        return record[type][0];
    }

    // For circuits
    const nodes = record && record.nodes;
    if (!nodes) return undefined;

    // For suggested circuit config
    if (Array.isArray(nodes)) {
        return record.nodes.find((node) => node.type === type);
    }

    // For minesite circuit format
    const selectedNodeType = nodes[type];
    return selectedNodeType && Array.isArray(selectedNodeType)
        ? selectedNodeType[0]
        : selectedNodeType;
}

/**
 * Aggregates the max time for each node to calculate the total cycle duration
 * @param {object} circuit
 * @return {number}
 */
export function calculateTotalCycleDuration(circuit) {
    const nodes = (circuit && circuit.nodes) || {};

    // Handle response form from circuit/suggest
    if (Array.isArray(nodes)) {
        return nodes
            .filter((node) => node.type !== NODE_TYPES.TRANSIT)
            .reduce((sum, node) => {
                return sum + node['maximum-time'];
            }, 0);
    }

    // Object format from /minesite
    return Object.keys(nodes)
        .filter((nodeType) => nodeType !== NODE_TYPES.TRANSIT)
        .reduce((sum, nodeType) => {
            const node = getNodeByType(nodeType, nodes);
            return sum + node['maximum-time'];
        }, 0);
}

/**
 * Gets the equipments progress through the current node
 * @param equipment
 */
export function getEquipmentCurrentProgresss(equipment) {
    const currNode = equipment.assignments[0];
    if (!currNode || !currNode['half-cycle-status']) {
        return 0;
    }
    return currNode['half-cycle-status'].progress;
}

/**
 * Gets an array of equipmentIds that are in transit into a holding state
 * @param {object} record
 * @return {Array}
 */
export function getTransitNodeEquipmentIds(record) {
    const transitNodes = getNodeByType(NODE_TYPES.TRANSIT, record);
    let inTransitEquipments = [];
    if (Array.isArray(transitNodes)) {
        transitNodes.forEach((node) => {
            inTransitEquipments = inTransitEquipments.concat(node.equipments);
            inTransitEquipments = inTransitEquipments.concat(
                node['queued-equipments'],
            );
        });
    }
    return inTransitEquipments;
}

/**
 * Gets an array of equipmentIds for equipment in the holding state
 * @param {object} record
 * @return {Array}
 */
export function getHoldingStateEquipmentIds(record) {
    // Combines the equipments array with the queued-equipments array
    const holdingStateNode = getNodeByType(NODE_TYPES.ANCILLARY, record);
    const equipmentIds = holdingStateNode.equipments.concat(
        holdingStateNode['queued-equipments'],
    );
    return equipmentIds;
}

/**
 * Gets the name associated with the given entity
 * @param {object} entity - Details for a circuit / holding-state / equipment
 * @return {string}
 */
export function getEntityDisplayName(entity) {
    if (entity && entity.type === 'circuit') {
        return entity.name || 'Circuit';
    }
    if (entity && entity.type === 'holding-state') {
        return entity.name || 'Holding State';
    }
    if (entity && entity.id && entity.id.includes('equipment')) {
        return entity['site-name'] || 'Equipment';
    }
    return 'Entity';
}

/**
 * Check if the given equipment is assigned to the given node
 * @param {object} equipmentDetails - Details for the equipment
 * @param {string} nodeId
 * @return {boolean}
 */
export function isEquipmentAssignedToNode(equipmentDetails, nodeId) {
    if (equipmentDetails && Array.isArray(equipmentDetails.assignments)) {
        return equipmentDetails.assignments.some(
            (assignment) => assignment.sequence === nodeId,
        );
    }
    return false;
}

/**
 * Split an array of trucks into 2 arrays given a threshold
 * e.g. a threshold of 4 gives the following
 *  3 trucks:  { trucks: [1, 2, 3], cluster: [] }
 *  4 trucks:  { trucks: [1, 2, 3, 4], cluster: [] }
 *  4+ trucks: { trucks: [1, 2, 3], cluster: [4, 5, 6, 7] }
 *
 * @param {Array} truckArray
 * @param {object} threshold
 * @return {object}
 */
export function getTruckCollection(truckArray, threshold) {
    const collection = {
        trucks: [],
        cluster: [],
    };
    collection.trucks = truckArray;
    if (truckArray.length > threshold) {
        collection.trucks = truckArray.slice(0, threshold - 1);
        collection.cluster = truckArray.slice(threshold - 1);
    }
    return collection;
}

/**
 * Gets the count of equipment from minesite
 * @param mineSnapshot
 * @return {*|arg is Array<any>|number}
 */
export function getEquipmentCount(equipments) {
    return (Array.isArray(equipments) && equipments.length) || 0;
}
