import { combineReducers } from 'redux';
import { reducer, sliceSelectors } from './usersList';
import { reducer as spinner } from '../Modules/spinner';

const REDUCER_KEY = 'UsersList';

const stateSelectors = {
    getApiErrorMessage: (state) =>
        sliceSelectors.getApiErrorMessage(state[REDUCER_KEY]),
};

export { stateSelectors, sliceSelectors, REDUCER_KEY };

export default combineReducers({
    reducer,
    spinner,
});
