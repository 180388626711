import { createSelector } from 'reselect';
import * as powerBiReportsModule from '../Modules/powerBiReports';

const getActiveReport = createSelector(
    powerBiReportsModule.selectors.getAll,
    (powerBiReportsState) => {
        const { activePowerBiReport } = powerBiReportsState;
        return activePowerBiReport;
    },
);
export default getActiveReport;
