import PropTypes from 'prop-types';
import React, { Component } from 'react';
import identity from 'lodash/identity';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

class SynchronisedChart extends Component {
    constructor(props) {
        super(props);
        this.handleHover = this.handleHover.bind(this);
        // Use a simple array to store the charts
        this.charts = [];
    }

    // Manually fire off the hover actions for each chart
    handleHover(e) {
        const { onHover } = this.props;

        let point;
        this.charts.forEach((chart) => {
            // Find coordinates within the chart
            const event = chart.pointer.normalize(e);
            // Find a visible series
            const series = chart.series.find((s) => s.visible === true);

            if (series) {
                // Get the hovered point
                point = series.searchPoint(event, true);
                if (point) {
                    // This will move the crosshair, update the tooltip etc.
                    point.highlight(e);
                }
            }
        });

        // We want to have a callback prop method that is fired when the chart is hovered.
        // We do that here, and give it the hovered point as an argument (can be null)
        onHover(point);
    }

    render() {
        const { children } = this.props;
        const numberOfChildren = children.length;

        // Iterate through each child and inject props necessary to synchronise them all
        const charts = React.Children.map(children, (chart, index) => {
            const options = chart.props.options
                ? cloneDeep(chart.props.options)
                : {};

            const synchronisedOptions = {
                chart: {
                    // Give the top chart some space to fit in the legend
                    spacingTop: index === 0 ? 30 : undefined,

                    // Apply a unique class to each chart
                    className: `SynchronisedChart__chart-${index}`,
                },
                xAxis: {
                    // Only show the x axis on the bottom chart to conserve space
                    visible: numberOfChildren - 1 === index,
                },
                tooltip: {
                    animation: false,
                },
                plotOptions: {
                    series: {
                        events: {
                            legendItemClick: (event) => {
                                // We'll handle the hiding/showing ourself
                                event.preventDefault();

                                const clickedSeriesName = event.target.name;
                                const isVisible = event.target.visible;

                                // When we click the legend to toggle a series on one chart,
                                // we also want to toggle the same series on all other charts
                                this.charts.forEach((subChart) => {
                                    subChart.series.forEach((series) => {
                                        if (series.name === clickedSeriesName) {
                                            if (!isVisible) {
                                                series.show();
                                            } else {
                                                series.hide();
                                            }
                                        }
                                    });

                                    const allSeriesAreHidden = subChart.series.every(
                                        (s) => s.visible === false,
                                    );
                                    // If all series are now hidden, remove the tooltip
                                    if (allSeriesAreHidden) {
                                        subChart.xAxis[0].hideCrosshair();
                                    }
                                });
                            },
                        },
                    },
                },
            };

            return React.cloneElement(chart, {
                // Merge any specific options given to us
                options: merge(synchronisedOptions, options),
                onChartLoad: (chartObject) => {
                    // Overwrite the function that hides the tooltip, crosshair and hover state when the mouse moves out of the chart. This means that our synchronised chart will remain visible on mouse leave
                    chartObject.pointer.reset = () => undefined;

                    // Add the chart our local array
                    this.charts.push(chartObject);
                },
                tooltipPosition: 'topright',
                showXValueOnTooltip: false,
            });
        });

        return (
            <div
                className="SynchronisedChart__container"
                onMouseMove={this.handleHover}
            >
                {charts}
            </div>
        );
    }
}

SynchronisedChart.propTypes = {
    children: PropTypes.node,
    onHover: PropTypes.func,
};

SynchronisedChart.defaultProps = {
    onHover: identity,
};

export default SynchronisedChart;
