import { call, put } from 'redux-saga/effects';
import errorLogging from '@rs/core/utils/errorLogging';
import {
    request,
    RESOURCE_PATHS,
    METHODS,
    HEADERS,
    REQUEST_OBJECT_KEY,
} from '../Api';
import {
    getItem,
    AUTH_TOKEN_KEY,
    REFRESH_TOKEN_KEY,
} from '../Lib/localStorage';
import addOperatorSiteId from '../Modules/loadingEventsConformanceReport/utils/addOperatorSiteId';
import { logout } from '../Auth/Actions';
import { getQueryString } from '../Lib/queryStringUtils';
import { isEquipmentId } from '../ActivityManagement/Utils/id';

function* addCustomBearer(requestObject) {
    const token = yield call(getItem, AUTH_TOKEN_KEY);
    if (token) {
        requestObject[REQUEST_OBJECT_KEY.HEADERS] = {
            [HEADERS.AUTHORIZATION]: token,
        };
    }

    return requestObject;
}

function* addStandardBearer(requestObject) {
    const token = yield call(getItem, AUTH_TOKEN_KEY);
    if (token) {
        requestObject[REQUEST_OBJECT_KEY.HEADERS] = {
            [HEADERS.AUTHORIZATION]: `Bearer ${token}`,
        };
    }

    return requestObject;
}

function* baseLoadEntity(modifyHeader, url, method, transformResponse, body) {
    let requestObject = {
        [REQUEST_OBJECT_KEY.METHOD]: method,
        [REQUEST_OBJECT_KEY.BODY]: body,
    };

    if (typeof modifyHeader === 'function') {
        requestObject = yield modifyHeader(requestObject);
    }

    const { response, error } = yield request(url, requestObject);

    // 'error' should always be a standard or custom Error object
    if (error) {
        if (error.statusCode === 401) {
            yield put(logout());
            return { error: error.message };
        }
        return {
            error: error.message,
        };
    }
    if (typeof transformResponse === 'function') {
        return { response: transformResponse(response, body) };
    }

    return { response };
}

export const loadEntity = baseLoadEntity.bind(null, addCustomBearer);

export const getDrivers = loadEntity.bind(
    null,
    RESOURCE_PATHS.DRIVERS,
    METHODS.GET,
    (response) => response.Drivers || [],
);
export const getCornerLeague = loadEntity.bind(
    null,
    RESOURCE_PATHS.REPORTS_CORNERLEAGUE,
    METHODS.GET,
    (response) => {
        response.CornerLeague = response.CornerLeague || [];
        return response;
    },
);
export const getMapEvents = loadEntity.bind(
    null,
    RESOURCE_PATHS.CORNEREVENTS,
    METHODS.GET,
    (response) => {
        const defaultResponse = {
            CornerEvents: [],
        };
        if (response.CornerEvents === null) {
            return defaultResponse.CornerEvents;
        }
        return response.CornerEvents;
    },
);
export const getTopClusters = loadEntity.bind(
    null,
    RESOURCE_PATHS.REPORTS_WORSTCORNERS,
    METHODS.GET,
    (response) => response.WorstDrivers || [],
);
export const getWKTAggregate = loadEntity.bind(
    null,
    RESOURCE_PATHS.WKTSHIFTAGGREGATELOCUSES,
    METHODS.GET,
    (response) => {
        return {
            WKTLocuses: response.WKTLocuses || [],
            XMeterDomain: response.MapExtents.XMeterDomain,
            YMeterDomain: response.MapExtents.YMeterDomain,
            LatitudeDomain: response.MapExtents.LatitudeDomain,
            LongitudeDomain: response.MapExtents.LongitudeDomain,
        };
    },
);
export const getMapLabels = loadEntity.bind(
    null,
    RESOURCE_PATHS.MAPLABELS_ALL,
    METHODS.GET,
    (response, body) => {
        const { ShiftId } = body;
        const shiftIdAsInt = parseInt(ShiftId, 10);
        const labels = response.MapLabels || [];
        return labels.filter(
            (label) =>
                shiftIdAsInt >= label.StartShiftId &&
                shiftIdAsInt <= label.EndShiftId,
        );
    },
);
export const getSpeedLimitedZones = loadEntity.bind(
    null,
    RESOURCE_PATHS.SPEEDLIMITEDZONES,
    METHODS.GET,
    (response) => {
        // Tech Debt: DR-3372 Move this config outta here
        // Why was it even here in the first place
        const colorRange = [
            'orchid',
            'green',
            'steelblue',
            '#d62728',
            '#8000ff',
            '#feca58',
        ];
        if (!response.SpeedLimitedZones) return [];
        const uniqueSpeedZones = response.SpeedLimitedZones.map(
            (d) => d.SignPostedSpeedLimitUpper,
        )
            .filter((item, index, arr) => arr.indexOf(item) === index)
            .sort((a, b) => a - b);

        const colorScale = uniqueSpeedZones.reduce((a, b, i) => {
            a[b] = colorRange[i];
            return a;
        }, {});

        return response.SpeedLimitedZones.map((d) => {
            d.Color = colorScale[d.SignPostedSpeedLimitUpper];
            return d;
        });
    },
);
export const getConformance = loadEntity.bind(
    null,
    RESOURCE_PATHS.REPORTS_CONFORMANCE,
    METHODS.GET,
    (response) => response || [],
);
export const getSpeedingEvents = loadEntity.bind(
    null,
    RESOURCE_PATHS.SPEEDINGEVENTS,
    METHODS.GET,
    (response) => response.SpeedingEvents || [],
);
export const printPDF = loadEntity.bind(
    null,
    RESOURCE_PATHS.REPORTS_CORNERLEAGUE_PDF,
    METHODS.GET,
    null,
);
export const getRampEvents = loadEntity.bind(
    null,
    RESOURCE_PATHS.RAMP_EVENTS,
    METHODS.GET,
    (response) => response.RampEvents || [],
);
export const getUniqueDrivers = loadEntity.bind(
    null,
    RESOURCE_PATHS.DRIVERSHIFTPRODUCTIVITYEVENTS_UNIQUEDRIVERS,
    METHODS.GET,
    (response) => response.UniqueDrivers || [],
);
export const getShiftProductivityEvents = loadEntity.bind(
    null,
    RESOURCE_PATHS.SHIFTPRODUCTIVITYEVENTS,
    METHODS.GET,
    (response) => {
        if (!response.ShiftProductivityEvents) return [];
        return response.ShiftProductivityEvents.map((event) => {
            const leaderLineArr = event.LeaderLinePos.split(' ');
            const [x, y] = leaderLineArr;
            // Remove non digit characters except hyphen
            event.LeaderLineX = parseInt(x.replace(/[^0-9-]/g, ''), 10);
            event.LeaderLineY = parseInt(y.replace(/[^0-9-]/g, ''), 10);
            return event;
        });
    },
);
export const getDriverShiftProductivityEvents = loadEntity.bind(
    null,
    RESOURCE_PATHS.DRIVERSHIFTPRODUCTIVITYEVENTS,
    METHODS.GET,
    (response) => response.DriverShiftProductivityEvents || [],
);
export const getS3List = ({ ShiftId, UniqueDriverId }) => {
    let url;
    if (UniqueDriverId) {
        url = `${RESOURCE_PATHS.S3_LIST}/${ShiftId}/Productivity/${UniqueDriverId}`;
    } else {
        url = `${RESOURCE_PATHS.S3_LIST}/${ShiftId}/`;
    }
    return loadEntity.bind(
        null,
        url,
        METHODS.GET,
        (response) => response || [],
    );
};
export const getS3Download = (
    s3Path,
    dataFileStartOffset,
    dataFileEndOffset,
) => {
    const token = getItem(AUTH_TOKEN_KEY);
    const requestObject = {
        [REQUEST_OBJECT_KEY.METHOD]: 'GET',
        [REQUEST_OBJECT_KEY.HEADERS]: {
            [HEADERS.AUTHORIZATION]: token,
        },
    };
    if (
        dataFileStartOffset !== undefined &&
        dataFileStartOffset !== undefined
    ) {
        requestObject[
            REQUEST_OBJECT_KEY.HEADERS
        ].Range = `bytes ${dataFileStartOffset}-${dataFileEndOffset}`;
    }
    let requestUrl = `${RESOURCE_PATHS.S3_DOWNLOAD}/${s3Path}`;
    if (s3Path.includes('mapFeatures.geo.json')) {
        const qs = getQueryString({ Coords: 'latlon' });
        requestUrl = `${requestUrl}?${qs}`;
    }
    return request(requestUrl, requestObject);
};
export const getTruckPowerPerformancesFromPowerCurves = loadEntity.bind(
    null,
    RESOURCE_PATHS.TRUCKPOWERPERFORMANCESFROMPOWERCURVES,
    METHODS.GET,
    (response) => response.TruckPowerPerformancesFromPowerCurves || [],
);

export const getO4ROperator = loadEntity.bind(
    null,
    RESOURCE_PATHS.O4ROPERATOR,
    METHODS.GET,
    (response) => response || [],
);

/**
 * Valid fields - {Email, NewPassword, Password, Recover}
 * @type {()}
 */
export const login = loadEntity.bind(
    null,
    RESOURCE_PATHS.AUTHENTICATE,
    METHODS.POST,
    null,
);

export const getLoadUnitOperatorLogs = loadEntity.bind(
    null,
    RESOURCE_PATHS.LOADUNITOPERATORLOGS,
    METHODS.GET,
    (response) => response.LoadUnitOperatorLogs || [],
);

export const getLoadingEvents = loadEntity.bind(
    null,
    RESOURCE_PATHS.LOADINGEVENTS,
    METHODS.GET,
    (response) => response.LoadingEvents || [],
);

export const getLoadingEventsConformanceReport = loadEntity.bind(
    null,
    RESOURCE_PATHS.LOADINGEVENTS_CONFORMANCEREPORT,
    METHODS.GET,
    (response) => {
        if (!Array.isArray(response.LoadingEvents)) {
            return [];
        }
        return response.LoadingEvents;
    },
);

export const getLoadingEventsConformanceSymbologyReport = loadEntity.bind(
    null,
    RESOURCE_PATHS.LOADINGEVENTS_CONFORMANCESYMBOLOGYREPORT,
    METHODS.GET,
    (response) => {
        const defaultResponse = {
            rows: [],
            total: {},
        };
        if (!Array.isArray(response.LoadingEvents)) {
            return defaultResponse;
        }
        const { rows, total } = response.LoadingEvents.reduce(
            (collection, next) => {
                if (next.OperatorDisplayName === 'Total') {
                    collection.total = next;
                } else {
                    collection.rows.push(next);
                }
                return collection;
            },
            defaultResponse,
        );
        return {
            rows: addOperatorSiteId(rows),
            total,
        };
    },
);

export const getProductivityDownloadsBatch = (params) => {
    const token = getItem(AUTH_TOKEN_KEY);
    const requestObject = {
        [REQUEST_OBJECT_KEY.METHOD]: 'POST',
        [REQUEST_OBJECT_KEY.HEADERS]: {
            [HEADERS.AUTHORIZATION]: token,
            [HEADERS.CONTENT_TYPE]: HEADERS.CONTENT_TYPE__JSON,
        },
        [REQUEST_OBJECT_KEY.BODY]: { Files: params },
    };
    return request(
        `${RESOURCE_PATHS.PRODUCTIVITY_DOWNLOADS_BATCH}`,
        requestObject,
    );
};

export const getLoadingEventsBiasLoadReport = loadEntity.bind(
    null,
    RESOURCE_PATHS.LOADINGEVENTS_BIASLOADREPORT,
    METHODS.GET,
    (response) => {
        const defaultResponse = {
            rows: [],
            total: {},
        };
        if (!Array.isArray(response.BiasLoadReport)) {
            return defaultResponse;
        }
        return response.BiasLoadReport.reduce((collection, next) => {
            if (next.OperatorDisplayName === 'Total') {
                collection.total = next;
            } else {
                collection.rows.push(next);
            }
            return collection;
        }, defaultResponse);
    },
);

export const getLoadingEventsBiasLoadLeagueReport = loadEntity.bind(
    null,
    RESOURCE_PATHS.LOADINGEVENTS_BIASLOADREPORT,
    METHODS.GET,
    (response) => {
        const defaultResponse = {
            rows: [],
            total: {},
        };
        if (!Array.isArray(response.BiasLoadReport)) {
            return defaultResponse;
        }
        return response.BiasLoadReport.reduce((collection, next) => {
            if (next.OperatorDisplayName === 'Total') {
                collection.total = next;
            } else {
                collection.rows.push(next);
            }
            return collection;
        }, defaultResponse);
    },
);

export const getHaulTruckFillFactorReport = loadEntity.bind(
    null,
    RESOURCE_PATHS.REPORTS_CYCLESUMMARIES_HAULTRUCKFILLFACTOR,
    METHODS.GET,
    (response) => {
        // If it's a CSV
        if (response instanceof Blob) {
            return response;
        }
        if (!Array.isArray(response.HaulTruckFillFactor)) {
            return [];
        }
        return response.HaulTruckFillFactor;
    },
);

export const getHaulTruckFillFactorPayloadReport = loadEntity.bind(
    null,
    RESOURCE_PATHS.REPORTS_CYCLESUMMARIES_HAULTRUCKFILLFACTORPAYLOAD,
    METHODS.GET,
    (response) => {
        if (!Array.isArray(response.HaulTruckFillFactor)) {
            return [];
        }
        return response.HaulTruckFillFactor;
    },
);

export const getPublicDiscoverySiteConfiguration = loadEntity.bind(
    null,
    // TODO At app startup the endpoint strings are undefined, guessing there will be a circular dependency somewhere
    // https://resolutionsystems.atlassian.net/browse/DR-1377
    // RESOURCE_PATHS.DISCOVERY_PUBLIC_SITECONFIGURATION
    '/discovery/public/site-configuration.json',
    METHODS.GET,
    null,
);

export const getPrivateDiscoverySiteConfiguration = loadEntity.bind(
    null,
    // TODO At app startup the endpoint strings are undefined, guessing there will be a circular dependency somewhere
    // https://resolutionsystems.atlassian.net/browse/DR-1377
    // RESOURCE_PATHS.DISCOVERY_PRIVATE_SITECONFIGURATION
    '/discovery/private/site-configuration.json',
    METHODS.GET,
    null,
);

export const getUserInformation = loadEntity.bind(
    null,
    '/authenticate/v2/currentuser',
    METHODS.GET,
    null,
);

export const getClientReportsMainTimingSummary = loadEntity.bind(
    null,
    RESOURCE_PATHS.CLIENTREPORTS_MAIN_TIMINGSUMMARY,
    METHODS.GET,
    (response) => {
        // If it's a CSV
        if (response instanceof Blob) {
            return response;
        }
        if (!Array.isArray(response.timingSummaries)) {
            return [];
        }
        return response.timingSummaries;
    },
);

export const getClientReportsIdleTime = loadEntity.bind(
    null,
    RESOURCE_PATHS.CLIENTREPORTS_MAIN_IDLE_TIME,
    METHODS.GET,
    null,
);

export const getClientReportsQueueing = loadEntity.bind(
    null,
    RESOURCE_PATHS.CLIENTREPORTS_MAIN_QUEUEING,
    METHODS.GET,
    null,
);

export const getClientReportsEquipmentActivityBasic = loadEntity.bind(
    null,
    RESOURCE_PATHS.CLIENTREPORTS_MAIN_EQUIPMENT_ACTIVITY_BASIC,
    METHODS.GET,
    (response) => {
        // If it's a CSV
        if (response instanceof Blob) {
            return response;
        }
        if (!Array.isArray(response.EquipmentActivityBasic)) {
            return [];
        }
        return response.EquipmentActivityBasic;
    },
);

export const getClientReportsActivityDetail = loadEntity.bind(
    null,
    RESOURCE_PATHS.CLIENTREPORTS_MAIN_ACTIVITY_DETAIL,
    METHODS.GET,
    null,
);

export const getClientReportsShiftActivitySummary = loadEntity.bind(
    null,
    RESOURCE_PATHS.CLIENTREPORTS_MAIN_SHIFT_ACTIVITY_SUMMARY,
    METHODS.GET,
    null,
);

export const getClientReportsOperatorIdentificationLogs = loadEntity.bind(
    null,
    RESOURCE_PATHS.CLIENTREPORTS_MAIN_OPERATORS_IDENTIFICATION_LOGS,
    METHODS.GET,
    (response) => {
        // If it's a CSV
        if (response instanceof Blob) {
            return response;
        }
        if (!Array.isArray(response.OperatorIdentificationLogs)) {
            return [];
        }
        return response.OperatorIdentificationLogs;
    },
);

export const getClientReportsMainCycleSummaries = loadEntity.bind(
    null,
    RESOURCE_PATHS.CLIENTREPORTS_MAIN_CYCLESUMMARIES,
    METHODS.GET,
    null,
);

export const getMaxMineAlerts = loadEntity.bind(
    null,
    RESOURCE_PATHS.TRUCK_ALERT_EVENTS,
    METHODS.GET,
    null,
);

export const getLatestShiftId = loadEntity.bind(
    null,
    RESOURCE_PATHS.S3SHIFTKEYPATHS_LATEST,
    METHODS.GET,
    (response) => {
        if (!Array.isArray(response)) {
            return [];
        }
        return response;
    },
);

export const getHaulRoadConditionSummary = loadEntity.bind(
    null,
    RESOURCE_PATHS.HAULROADCONDITIONSUMMARY,
    METHODS.GET,
    (response) => {
        if (!Array.isArray(response.HaulRoadConditionSummary)) {
            return [];
        }
        return response.HaulRoadConditionSummary;
    },
);

export const getLoadingEventOperatorTargets = loadEntity.bind(
    null,
    RESOURCE_PATHS.LOADINGEVENTOPERATORTARGETS,
    METHODS.GET,
    (response) => {
        if (!Array.isArray(response.LoadingEventOperatorTargets)) {
            return [];
        }
        return response.LoadingEventOperatorTargets;
    },
);

export const getHaulRoadConditionSummaryLatest = loadEntity.bind(
    null,
    RESOURCE_PATHS.HAULROADCONDITIONSUMMARYLATEST,
    METHODS.GET,
    (response) => {
        if (!Array.isArray(response.HaulRoadConditionSummary)) {
            return [];
        }
        return response.HaulRoadConditionSummary;
    },
);

export const getAuthenticateMethod = loadEntity.bind(
    null,
    RESOURCE_PATHS.AUTHENTICATE_METHOD,
    METHODS.GET,
    null,
);

export const postAuthenticateFederatedToken = loadEntity.bind(
    null,
    RESOURCE_PATHS.AUTHENTICATE_FEDERATEDTOKEN,
    METHODS.POST,
    null,
);

export const getMinesiteAreasTree = loadEntity.bind(
    null,
    RESOURCE_PATHS.MINESITEAREAS_TREE,
    METHODS.GET,
    (response) => response.MinesiteAreas || {},
);

export const getTruckAlertEventsByTruckName = loadEntity.bind(
    null,
    RESOURCE_PATHS.TRUCKALERTEVENTS_BYTRUCKNAME,
    METHODS.GET,
    (response) => response.TruckAlertEvents || [],
);

export const getTruckAlertEventsByShiftId = loadEntity.bind(
    null,
    RESOURCE_PATHS.TRUCKALERTEVENTS_BYSHIFTID,
    METHODS.GET,
    (response) => response.TruckAlertEvents || [],
);

export const getHaulRoadConditionEvents = loadEntity.bind(
    null,
    RESOURCE_PATHS.HAULROADCONDITIONEVENTS,
    METHODS.GET,
    (response) => {
        // If it's a CSV
        if (response instanceof Blob) {
            return response;
        }
        return response.HaulRoadConditionEvents || [];
    },
);

export const getTruckAlertEvents = loadEntity.bind(
    null,
    RESOURCE_PATHS.TRUCKALERTEVENTS,
    METHODS.GET,
    (response) => response.TruckAlertEvents || [],
);

export const getTimingEquipmentUptime = loadEntity.bind(
    null,
    RESOURCE_PATHS.TIMING_EQUIPMENT_UPTIME,
    METHODS.GET,
    (response) => response.EquipmentUptime || [],
);

export const getConditionalLimitEvents = loadEntity.bind(
    null,
    RESOURCE_PATHS.CONDITIONALLIMITEVENTS,
    METHODS.GET,
    (response) => response.ConditionalLimitEvents || [],
);

export const getWikiByContentId = loadEntity.bind(
    null,
    RESOURCE_PATHS.WIKI_BYCONTENTID,
    METHODS.GET,
    null,
);

export const getClientReportsMainSpeedingEvents = loadEntity.bind(
    null,
    RESOURCE_PATHS.CLIENTREPORTS_MAIN_SPEEDINGEVENTS,
    METHODS.GET,
    (response) => {
        // If it's a CSV
        if (response instanceof Blob) {
            return response;
        }
        if (!Array.isArray(response.SpeedingEvents)) {
            return [];
        }
        return response.SpeedingEvents;
    },
);

export const getClientReportsMainCornerEvents = loadEntity.bind(
    null,
    RESOURCE_PATHS.CLIENTREPORTS_MAIN_CORNEREVENTS,
    METHODS.GET,
    (response) => {
        // If it's a CSV
        if (response instanceof Blob) {
            return response;
        }
        if (!Array.isArray(response.CornerEvents)) {
            return [];
        }
        return response.CornerEvents;
    },
);

export const getClientReportsMainRampEvents = loadEntity.bind(
    null,
    RESOURCE_PATHS.CLIENTREPORTS_MAIN_RAMPEVENTS,
    METHODS.GET,
    (response) => {
        // If it's a CSV
        if (response instanceof Blob) {
            return response;
        }
        if (!Array.isArray(response.RampEvents)) {
            return [];
        }
        return response.RampEvents;
    },
);

export const getClientReportsMainTruckAlertEvents = loadEntity.bind(
    null,
    RESOURCE_PATHS.CLIENTREPORTS_MAIN_TRUCKALERTEVENTS,
    METHODS.GET,
    null,
);

export const getClientReportsMainHaulTruckFillFactor = loadEntity.bind(
    null,
    RESOURCE_PATHS.CLIENTREPORTS_MAIN_HAULTRUCKFILLFACTOR,
    METHODS.GET,
    null,
);

export const getCycleSummariesPayload = loadEntity.bind(
    null,
    RESOURCE_PATHS.CYCLE_SUMMARIES_PAYLOAD,
    METHODS.GET,
    (response) => {
        if (!Array.isArray(response.CycleSummaryPayload)) {
            return [];
        }
        return response.CycleSummaryPayload;
    },
);

export const postCreateUser = loadEntity.bind(
    null,
    RESOURCE_PATHS.CREATE_USER,
    METHODS.POST,
    null,
);

export const patchEditUser = loadEntity.bind(
    null,
    RESOURCE_PATHS.EDIT_USER,
    METHODS.PATCH,
    null,
);

export const patchAssignGroup = loadEntity.bind(
    null,
    RESOURCE_PATHS.ASSIGN_GROUP,
    METHODS.PATCH,
    null,
);

export const postDeleteUser = loadEntity.bind(
    null,
    RESOURCE_PATHS.DELETE_USER,
    METHODS.POST,
    null,
);

export const getUserList = loadEntity.bind(
    null,
    RESOURCE_PATHS.FETCH_USER,
    METHODS.GET,
    (response) => {
        if (response instanceof Blob) {
            return response;
        }

        const defaultResponse = {
            rows: [],
        };
        if (!Array.isArray(response.user_details)) {
            return defaultResponse;
        }
        return {
            rows: response.user_details,
        };
    },
);

export const getUserProfiles = loadEntity.bind(
    null,
    RESOURCE_PATHS.FETCH_PROFILES,
    METHODS.GET,
    (response) => {
        if (response instanceof Blob) {
            return response;
        }

        const defaultResponse = {
            rows: [],
        };

        if (!Array.isArray(response.Profiles)) {
            return defaultResponse;
        }
        return {
            rows: response.Profiles,
        };
    },
);

export const patchEditGroup = loadEntity.bind(
    null,
    RESOURCE_PATHS.EDIT_GROUP,
    METHODS.PATCH,
    null,
);

export const postCreateGroup = loadEntity.bind(
    null,
    RESOURCE_PATHS.CREATE_GROUP,
    METHODS.POST,
    null,
);

export const getFeatureList = loadEntity.bind(
    null,
    RESOURCE_PATHS.FETCH_FEATURE,
    METHODS.GET,
    (response) => {
        const defaultResponse = {
            rows: [],
        };
        if (!Array.isArray(response.Features)) {
            return defaultResponse;
        }
        return {
            rows: response.Features,
        };
    },
);

export const getGroupList = loadEntity.bind(
    null,
    RESOURCE_PATHS.FETCH_GROUP,
    METHODS.GET,
    (response) => {
        const defaultResponse = {
            rows: [],
        };
        if (!Array.isArray(response.GroupsDetails)) {
            return defaultResponse;
        }
        return {
            rows: response.GroupsDetails,
        };
    },
);

export const postDeleteGroup = loadEntity.bind(
    null,
    RESOURCE_PATHS.DELETE_GROUP,
    METHODS.POST,
    null,
);

export const getClientReportsMainHaulRoadConditionEvents = loadEntity.bind(
    null,
    RESOURCE_PATHS.CLIENTREPORTS_MAIN_HAULROADCONDITIONEVENTS,
    METHODS.GET,
    null,
);

export const getPDFMap = (url) => {
    const token = getItem(AUTH_TOKEN_KEY);
    const requestObject = {
        [REQUEST_OBJECT_KEY.METHOD]: 'GET',
        [REQUEST_OBJECT_KEY.HEADERS]: {
            [HEADERS.AUTHORIZATION]: token,
        },
    };
    return request(url, requestObject, false);
};

export const getEquipmentLatency = loadEntity.bind(
    null,
    RESOURCE_PATHS.EQUIPMENT_LATENCY,
    METHODS.GET,
    (response) => {
        if (!Array.isArray(response.EquipmentLatency)) {
            return [];
        }
        return response.EquipmentLatency;
    },
);

export const getEquipmentLatencyHistoric = loadEntity.bind(
    null,
    RESOURCE_PATHS.EQUIPMENT_LATENCY_HISTORIC,
    METHODS.GET,
    (response) => {
        if (!Array.isArray(response.EquipmentLatencies)) {
            return [];
        }
        return response.EquipmentLatencies;
    },
);

export const resetPasswordLinkResquest = loadEntity.bind(
    null,
    RESOURCE_PATHS.AUTHENTICATE_RESET_PASSWORD,
    METHODS.PATCH,
    null,
);

export const getFMSTruckAllocationMinesite = loadEntity.bind(
    null,
    RESOURCE_PATHS.FMS_TRUCK_ALLOCATION_MINESITE,
    METHODS.GET,
    null,
);

export const patchTruckAllocation = ({ id, modifications }) => {
    const endpoint = `${RESOURCE_PATHS.FMS_TRUCK_ALLOCATION}/${id}`;
    if (!id) {
        errorLogging.logException(
            `Attempting to patch endpoint ${endpoint}, missing id`,
        );
        return Promise.reject();
    }
    const fn = loadEntity.bind(null, endpoint, METHODS.PATCH, null);
    return fn(modifications);
};

export const putTruckAllocation = ({ id, modifications }) => {
    const isEquipmentAssignment = isEquipmentId(id);
    let endpoint = `${RESOURCE_PATHS.FMS_TRUCK_ALLOCATION}/${id}`;
    if (isEquipmentAssignment) {
        endpoint = `${RESOURCE_PATHS.FMS_TRUCK_ALLOCATION}/${id}/assignment`;
    }
    if (!id) {
        errorLogging.logException(
            `Attempting to patch endpoint ${endpoint}, missing id`,
        );
        return Promise.reject();
    }
    const fn = loadEntity.bind(null, endpoint, METHODS.PUT, null);
    return fn({
        ...modifications,
        ...(isEquipmentAssignment ? { timeframe: 'immediate' } : {}),
    });
};

export const deleteTruckAllocation = ({ id }) => {
    const endpoint = `${RESOURCE_PATHS.FMS_TRUCK_ALLOCATION}/${id}`;
    if (!id) {
        errorLogging.logException(
            `Attempting to patch endpoint ${endpoint}, missing id`,
        );
        return Promise.reject();
    }
    const fn = loadEntity.bind(null, endpoint, METHODS.DELETE, null);
    return fn();
};

export const getFMSCircuitSuggest = loadEntity.bind(
    null,
    RESOURCE_PATHS.FMS_CIRCUIT_SUGGEST,
    METHODS.GET,
    null,
);

export const getFMSCircuitNodes = loadEntity.bind(
    null,
    RESOURCE_PATHS.FMS_CIRCUIT_NODES,
    METHODS.GET,
    null,
);

export const getFMSTruckAllocationEquipment = loadEntity.bind(
    null,
    RESOURCE_PATHS.FMS_TRUCK_ALLOCATION_EQUIPMENT,
    METHODS.GET,
    (response) => response.features,
);

export const getShiftLoggerDeployments = loadEntity.bind(
    null,
    RESOURCE_PATHS.SHIFT_LOGGER_DEPLOYMENTS,
    METHODS.GET,
    (response) => {
        if (!Array.isArray(response.EquipmentIdentifiers)) {
            return [];
        }
        return response.EquipmentIdentifiers;
    },
);

export const getEngineDiagnosticCurvesByTruck = loadEntity.bind(
    null,
    RESOURCE_PATHS.ENGINE_DIAGNOSTIC_CURVES,
    METHODS.GET,
    (response) => {
        if (!Array.isArray(response.EngineDiagnosticCurves)) {
            return [];
        }
        return response.EngineDiagnosticCurves;
    },
);

export const getEngineDiagnosticPowerCurveMetrics = loadEntity.bind(
    null,
    RESOURCE_PATHS.ENGINE_DIAGNOSTIC_POWER_CURVE_METRICS,
    METHODS.GET,
    (response) => {
        if (!Array.isArray(response.EngineDiagnosticPowerCurveMetrics)) {
            return [];
        }
        return response.EngineDiagnosticPowerCurveMetrics;
    },
);

export const getParameterChecks = loadEntity.bind(
    null,
    RESOURCE_PATHS.PARAMETER_CHECKS,
    METHODS.GET,
    (response) => {
        if (!Array.isArray(response.ParameterChecks)) {
            return [];
        }
        return response.ParameterChecks;
    },
);

export const getEquipmentOperatorAlertEvents = loadEntity.bind(
    null,
    RESOURCE_PATHS.EQUIPMENT_OPERATOR_ALERT_EVENTS,
    METHODS.GET,
    (response) => {
        if (!Array.isArray(response.OperatorAlertEvents)) {
            return [];
        }
        return response.OperatorAlertEvents;
    },
);

export const getClientReportsMainRampEventSummaries = loadEntity.bind(
    null,
    RESOURCE_PATHS.CLIENTREPORTS_MAIN_RAMP_EVENT_SUMMARIES,
    METHODS.GET,
    (response) => {
        // If it's a CSV
        if (response instanceof Blob) {
            return response;
        }
        if (!Array.isArray(response.rampevents)) {
            return [];
        }
        return response.rampevents;
    },
);

export const getClientReportsMainRampEventDetails = loadEntity.bind(
    null,
    RESOURCE_PATHS.CLIENTREPORTS_MAIN_RAMP_EVENT_DETAILS,
    METHODS.GET,
    (response) => {
        // If it's a CSV
        if (response instanceof Blob) {
            return response;
        }
        if (!Array.isArray(response.rampevents)) {
            return [];
        }
        return response.rampevents;
    },
);

export const getClientReportsMainHaulTruckOperatorLeague = loadEntity.bind(
    null,
    RESOURCE_PATHS.CLIENTREPORTS_MAIN_HAUL_TRUCK_OPERATOR_LEAGUE,
    METHODS.GET,
    (response) => {
        // If it's a CSV
        if (response instanceof Blob) {
            return response;
        }
        if (!Array.isArray(response.HaulTruckOperatorLeague)) {
            return [];
        }
        return response.HaulTruckOperatorLeague;
    },
);

export const getClientReportsMainLoadUnitOperatorLeague = loadEntity.bind(
    null,
    RESOURCE_PATHS.CLIENTREPORTS_MAIN_LOAD_UNIT_OPERATOR_LEAGUE,
    METHODS.GET,
    (response) => {
        // If it's a CSV
        if (response instanceof Blob) {
            return response;
        }
        if (!Array.isArray(response.LoadUnitOperatorLeague)) {
            return [];
        }
        return response.LoadUnitOperatorLeague;
    },
);

export const getTimeAccountingActivities = baseLoadEntity.bind(
    null,
    addStandardBearer,
    RESOURCE_PATHS.TIME_ACCOUNTING_ACTIVITIES,
    METHODS.GET,
    (response) => {
        // If it's a CSV
        if (response instanceof Blob) {
            return response;
        }
        if (!Array.isArray(response.activity)) {
            return [];
        }
        return response.activity;
    },
);

export const getLoadUnitConformanceWeightings = loadEntity.bind(
    null,
    RESOURCE_PATHS.LOAD_UNIT_CONFORMANCE_WEIGHTINGS,
    METHODS.GET,
    (response) => {
        const responseKey = 'LoadUnitConformanceWeightings';
        // If it's a CSV
        if (response instanceof Blob) {
            return response;
        }
        if (!Array.isArray(response[responseKey])) {
            return [];
        }
        return response[responseKey];
    },
);

export const getLoadingEventsAggregate = loadEntity.bind(
    null,
    RESOURCE_PATHS.LOADING_EVENTS_AGGREGATE,
    METHODS.GET,
    (response) => {
        if (!Array.isArray(response.AggregateLoadingEvents)) {
            return [];
        }
        return response.AggregateLoadingEvents;
    },
);

export const getLoadingEventsHistogram = loadEntity.bind(
    null,
    RESOURCE_PATHS.LOADING_EVENTS_HISTOGRAM,
    METHODS.GET,
    (response) => {
        if (
            !response.HistogramLoadingEvents ||
            !response.HistogramLoadingEvents.Avg
        ) {
            return {};
        }
        return response.HistogramLoadingEvents;
    },
);

export const getNetworkCoverageReport = loadEntity.bind(
    null,
    RESOURCE_PATHS.NETWORK_COVERAGE_REPORT,
    METHODS.GET,
    (response) => {
        const results = response.results;

        // TODO api result validation
        if (!results.unit || !results.metrics) {
            return {};
        }
        return results;
    },
);

export const getClientReportsMainEquipmentAlerts = loadEntity.bind(
    null,
    RESOURCE_PATHS.CLIENTREPORTS_MAIN_EQUIPMENT_ALERTS,
    METHODS.GET,
    (response) => {
        // If it's a CSV
        if (response instanceof Blob) {
            return response;
        }
        if (!Array.isArray(response.EquipmentAlerts)) {
            return [];
        }
        return response.EquipmentAlerts;
    },
);

export const getAllPowerBiReports = loadEntity.bind(
    null,
    `${RESOURCE_PATHS.POWER_BI_REPORT}/all`,
    METHODS.GET,
    (response) => {
        return response.PowerBiReports;
    },
);

export const getPowerBiReport = loadEntity.bind(
    null,
    `${RESOURCE_PATHS.POWER_BI_REPORT}`,
    METHODS.GET,
    null,
);

export const previewPowerBiReportToken = loadEntity.bind(
    null,
    `${RESOURCE_PATHS.POWER_BI_REPORT}/preview`,
    METHODS.POST,
    null,
);

export const submitCreatePowerBiReport = loadEntity.bind(
    null,
    `${RESOURCE_PATHS.POWER_BI_REPORT}`,
    METHODS.POST,
    null,
);

export const submitEditPowerBiReport = loadEntity.bind(
    null,
    `${RESOURCE_PATHS.POWER_BI_REPORT}`,
    METHODS.PATCH,
    null,
);

export function* uploadUserUploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);
    const url = `${RESOURCE_PATHS.USER_UPLOAD_FILES}/upload`;
    const token = yield call(getItem, AUTH_TOKEN_KEY);
    const requestObject = {
        [REQUEST_OBJECT_KEY.METHOD]: 'POST',
        [REQUEST_OBJECT_KEY.BODY]: formData,
    };
    if (token) {
        requestObject[REQUEST_OBJECT_KEY.HEADERS] = {
            [HEADERS.AUTHORIZATION]: token,
            [HEADERS.CONTENT_TYPE]: 'multipart/form-data',
        };
    }
    requestObject.isJson = false;
    requestObject.withoutContentTypeHeader = true;
    const { response, error } = yield request(url, requestObject);

    // 'error' should always be a standard or custom Error object
    if (error) {
        if (error.statusCode === 401) {
            yield put(logout());
            return { error: error.message };
        }
        return {
            error: error.message,
        };
    }

    return { response };
}

export const downloadUserUploadFile = (fileKey) => {
    const endpoint = `${RESOURCE_PATHS.USER_UPLOAD_FILES}/download?fileKey=${fileKey}`;
    if (!fileKey) {
        errorLogging.logException(
            `Attempting to download endpoint ${endpoint}, missing fileKey`,
        );
        return Promise.reject();
    }
    const fn = loadEntity.bind(null, endpoint, METHODS.GET, null);
    return fn();
};

export function* refresh() {
    const token = yield call(getItem, REFRESH_TOKEN_KEY);

    const requestObject = {
        [REQUEST_OBJECT_KEY.METHOD]: METHODS.POST,
        [REQUEST_OBJECT_KEY.HEADERS]: {
            [HEADERS.AUTHORIZATION]: token,
        },
    };

    const url = RESOURCE_PATHS.REFRESH_TOKEN;
    const { response, error } = yield request(url, requestObject);

    // 'error' should always be a standard or custom Error object
    if (error) {
        yield put(logout());
        return { error: error.message };
    }

    return { response };
}

export function* getImpersonateToken(body) {
    const token = yield call(getItem, AUTH_TOKEN_KEY);

    const requestObject = {
        [REQUEST_OBJECT_KEY.METHOD]: METHODS.POST,
        [REQUEST_OBJECT_KEY.HEADERS]: {
            [HEADERS.AUTHORIZATION]: token,
        },
        [REQUEST_OBJECT_KEY.BODY]: body,
    };

    const url = RESOURCE_PATHS.IMPERSONATE_USER;

    const { response, error } = yield request(url, requestObject);
    if (error) {
        return { error: error.message };
    }

    return { response };
}
