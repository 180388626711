import { action } from '../../Lib/reduxUtils';
import { GoogleAnalyticsEventBuilder } from '../../Utils/userAnalytics/googleAnalyticsEventBuilder';

const NAMESPACE = 'Productivity';

export const LOAD_DRIVERS_AND_MAP_DATA = `${NAMESPACE}/LOAD_DRIVERS_AND_MAP_DATA`;
export const loadDriversAndMapData = (data) =>
    action(LOAD_DRIVERS_AND_MAP_DATA, data);

export const LOAD_DRIVER_EVENT_JSON = `${NAMESPACE}/LOAD_DRIVER_EVENT_JSON`;
export const loadDriverEventJSON = (jsonData) =>
    action(LOAD_DRIVER_EVENT_JSON, jsonData);

export const LOAD_DRIVER_EVENT_JSON_ERROR = `${NAMESPACE}/LOAD_DRIVER_EVENT_JSON_ERROR`;
export const loadDriverEventJSONError = (error) =>
    action(LOAD_DRIVER_EVENT_JSON_ERROR, { error });

export const SET_HIGHLIGHTED_EVENT_PATH_INDEX = `${NAMESPACE}/SET_HIGHLIGHTED_EVENT_PATH_INDEX`;
export const setHighlightedEventPathIndex = (index, row) =>
    action(SET_HIGHLIGHTED_EVENT_PATH_INDEX, { index, row });

export const FILTER_UPDATED = `${NAMESPACE}/FILTER_UPDATED`;
export const filterUpdated = (filterName, filterValue) =>
    action(
        FILTER_UPDATED,
        { filterName, filterValue },
        {
            analytics: new GoogleAnalyticsEventBuilder(
                'SubFeatureUsage',
                'Filter Updated',
            )
                .setLabel(NAMESPACE)
                .build(),
        },
    );

export const UPDATE_FILTERS_WITH_URL_PARAMS = `${NAMESPACE}/UPDATE_FILTERS_WITH_URL_PARAMS`;
export const updateFiltersWithUrlParams = () =>
    action(UPDATE_FILTERS_WITH_URL_PARAMS);

export const SET_FILTERS_WITH_URL_PARAMS = `${NAMESPACE}/SET_FILTERS_WITH_URL_PARAMS`;
export const setFiltersWithUrlParams = (filtersFromUrl) =>
    action(SET_FILTERS_WITH_URL_PARAMS, filtersFromUrl);

export const SET_SPINNER_STATE = `${NAMESPACE}/SET_SPINNER_STATE`;
export const setSpinnerState = (isActive = false, message = '', faIcon) =>
    action(SET_SPINNER_STATE, {
        isActive,
        message,
        faIcon,
    });
export const SET_MINESITEMAP_LAYERS = `${NAMESPACE}/SET_MINESITEMAP_LAYERS`;
export const setMinesiteMapLayers = (payload) => {
    return action(SET_MINESITEMAP_LAYERS, payload);
};
export const MAP_VIEWPORT_CHANGED = `${NAMESPACE}/AP_VIEWPORT_CHANGED`;
export const mapViewportChanged = (viewport) =>
    action(MAP_VIEWPORT_CHANGED, viewport);

export const MAP_LAYER_UPDATED = `${NAMESPACE}/MAP_LAYER_UPDATED`;
export const onMapLayerUpdated = (filterName, filterValue) =>
    action(MAP_LAYER_UPDATED, { filterName, filterValue });

export const MAP_LEGEND_LAYER_FLIP = `${NAMESPACE}/MAP_LEGEND_LAYER_FLIP`;
export const mapLegendLayerFlip = (section, checked) => {
    return action(MAP_LEGEND_LAYER_FLIP, {
        sectionLabel: section,
        visibility: checked,
    });
};

export const INIT = `${NAMESPACE}/INIT`;
export const init = () => {
    return action(INIT);
};
