/**
 * Converts a shift value to the display value
 * @param shiftValue
 * @return {string}
 */
import shiftUtils from '@rs/core/utils/shiftUtils';
import { compareNumber } from '../../Lib/sortingUtils';

/**
 * Converts shiftValues into a label/value pair for use with the Select component
 * @param {string[]} shiftValues - A list of shift shortName values
 * @returns {Array}
 */
export function filterShiftOptionsToReactSelectFormat(
    rawShiftsInDay,
    shiftValues,
) {
    return shiftValues
        .filter((shiftIsNotEmptyString) => shiftIsNotEmptyString)
        .map((shift) => {
            const record = shiftUtils.getRawShiftInDayFromShortName(
                rawShiftsInDay,
                shift,
            );
            const shiftIdIndex = shiftUtils.getShiftIdIndexFromShortName(
                rawShiftsInDay,
                shift,
            );
            return {
                label: (record && record.name) || 'UNKNOWN',
                value: shift,
                shiftIdIndex,
            };
        })
        .sort((a, b) => compareNumber(b.shiftIdIndex, a.shiftIdIndex));
}

/**
 *
 * This is used temporarily to convert a flat array into the format that react-select wants
 * @param filterOptionsArray
 * @returns {{label: *, value: *}[]}
 */
export function filterOptionsToReactSelectFormat(filterOptionsArray) {
    return filterOptionsArray.map((d) => ({
        label: d,
        value: d,
    }));
}
