import createActions from '../../Modules/sortOrder/actions';
import createReducer from '../../Modules/sortOrder/reducer';
import createSelectors from '../../Modules/sortOrder/selectors';
import namespace from './namespace';

/**
 * Selects the local state from the root state, ie. defines where this chunk of state has been mounted in the overall state tree
 */
const statePath = (state) => state[namespace].table.order;

/**
 * Create the module parts
 */
export const actions = createActions(namespace);
export const reducer = createReducer(
    {
        sortTable: actions.SORT_TABLE,
    },
    {},
    {
        sortBy: 'OperatorDisplayName',
        reverse: false,
    },
);
export const selectors = createSelectors(statePath);
