import { createSelector } from 'reselect';
import * as networkCoverageReportModule from '../Modules/networkCoverageReport';
import * as datePickerModule from '../Modules/datePicker';

const getApiParams = createSelector(
    networkCoverageReportModule.selectors.getAll,
    datePickerModule.selectors.getAll,
    (netWorkCoverageState, dateTimePickerState) => {
        const { h3Resolution } = netWorkCoverageState;
        const { startTime, endTime } = dateTimePickerState;
        // map the api params if necessary
        return {
            //network coverage state
            StartTime: startTime,
            EndTime: endTime,
            H3Resolution: h3Resolution,
        };
    },
);
export default getApiParams;
