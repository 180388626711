import { combineReducers } from 'redux';
import { reducer as filters } from './Modules/filters';
import { reducer as fileExport } from './Modules/fileExport';
import namespace from './Modules/namespace';

export const REDUCER_KEY = namespace;
export default combineReducers({
    filters,
    fileExport,
});
