import namespace from '../Modules/namespace';
import {
    createSliceWithName as createLegendSlice,
    selectVisibleSections,
} from '@rs/core/falcon/components/NewLegend/reduxUtil';

export const moduleName = 'MAP_LEGENDS';
export const selectState = (state: any) => {
    return state[namespace][moduleName];
};

const legend = createLegendSlice(`${namespace}/${moduleName}`, {
    sections: [],
});

export const reducer = legend.reducer;
export const actions = legend.actions;
export const selectors = {
    selectVisibleSections: (state: any) =>
        selectVisibleSections(selectState(state)),
};
