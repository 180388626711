/**
 * Calculates the percentage of road in each classification
 * @param {array} haulRoadConditionSummary
 * @return {{}}
 */
export default function calculateClassificationPercents(
    haulRoadConditionSummary,
) {
    if (!haulRoadConditionSummary) return {};
    const sumRoadDistances = haulRoadConditionSummary.reduce(
        (collection, classification) => {
            collection[classification.Classification] =
                collection[classification.Classification] || 0;
            collection[classification.Classification] +=
                classification.RoadDistance;
            collection.totalRoadDistance += classification.RoadDistance;
            return collection;
        },
        {
            totalRoadDistance: 0,
        },
    );

    // Calculate percentage of road in each classification
    return Object.keys(sumRoadDistances)
        .filter((key) => key !== 'totalRoadDistance')
        .reduce((collection, key) => {
            collection[key] =
                (sumRoadDistances[key] / sumRoadDistances.totalRoadDistance) *
                    100 || 0;
            return collection;
        }, {});
}
