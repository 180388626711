import React from 'react';
import { TimePicker as AntTimePicker } from 'antd';
import PropTypes from 'prop-types';
import cn from 'classnames';

const TimePicker = ({ block, className, ...props }) => {
    return (
        <AntTimePicker
            className={cn(
                {
                    'TimePicker--Block': block,
                },
                className,
            )}
            {...props}
        />
    );
};

TimePicker.propTypes = {
    ...AntTimePicker.propTypes,
    // Makes the TimePicker full width
    block: PropTypes.bool,
};

export default TimePicker;
