import { DefaultRootState } from 'react-redux';
import { State } from './types';

export default function createSelectors(
    statePath: (rootState: DefaultRootState) => State,
) {
    function getAll(state: DefaultRootState) {
        const slice = statePath(state);
        return {
            ...slice,
        };
    }

    return {
        getAll,
    };
}
