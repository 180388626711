import { sumNumbers } from '@rs/core/utils/mathHelpers';

/**
 * Formats the averaged driver event json data into a format for the <ProductivityCharts> component
 * @param {object} driverEventJson
 * @returns {array}
 */
export function formatChartData(driverEventJson = {}) {
    return Object.keys(driverEventJson).reduce((eventCollection, key) => {
        const { averagedResults } = driverEventJson[key];
        if (averagedResults) {
            const results = averagedResults.reduce((collection, array) => {
                const category = array[0].category;
                if (!collection[category]) {
                    collection[category] = [];
                }
                // Both the frank and the worker average come from this array
                // These won't be mutated so don't need to worry about referencing issues
                collection[category].shiftAverage = array;
                collection[category].worker = array;
                return collection;
            }, []);
            eventCollection[key] = results;
        }
        return eventCollection;
    }, {});
}

/**
 * Formats the driverEventJSON. Also groups data based on xPos
 * @param {array} - data, an Event
 * @returns {object}
 */
export function formatDriverEventJSON(data) {
    if (Object.keys(data).length === 0) {
        return {
            averagedResults: [],
            categoriesByXPos: {},
        };
    }

    const categoryKeys = Object.keys(data).filter(
        (key) =>
            (key.includes('Mean') && !key.includes('Frank')) || key === 'Z',
    );

    data.displacement = [];
    data.dCOMP_XY_Dist.reduce((dispSoFar, value, i) => {
        dispSoFar += value;
        data.displacement[i] = dispSoFar;
        return dispSoFar;
    }, 0);

    const categoriesByXPos = {};
    const averagedResults = categoryKeys.reduce((collection, categoryKey) => {
        const categoryCollection = data[categoryKey].map((d, i) => {
            let frankName = getFrankName(categoryKey);
            if (categoryKey === 'Z') {
                frankName = 'Z';
            }
            const xPos = data.X[i];

            const averagedData = {
                category: categoryKey,
                average: d,
                frank: data[frankName][i],
                dCOMP_XY_Dist: data.dCOMP_XY_Dist[i],
                displacement: data.displacement[i],
                x: xPos,
                y: data.Y[i],
                latitude: data.latitude[i],
                longitude: data.longitude[i],
            };
            // Add frank_min
            // metres/sec so multiply by 3.6 to get to km/h
            // Check for nulls otherwise null * 3.6 = 0 which is not what is wanted
            if (
                categoryKey === 'Mean_COMP_Lower_Tolerance_Speed_Driver' &&
                averagedData.average !== null
            ) {
                averagedData.average *= 3.6;
            }
            if (
                categoryKey === 'Mean_COMP_Lower_Tolerance_Speed_Driver' &&
                averagedData.frank !== null
            ) {
                averagedData.frank *= 3.6;
            }
            if (
                categoryKey === 'Mean_COMP_Lower_Tolerance_Speed_Driver' &&
                data.Mean_Frank_Speed_Lower_Driver[i] !== null
            ) {
                averagedData.frank_min =
                    data.Mean_Frank_Speed_Lower_Driver[i] * 3.6;
            }
            categoriesByXPos[xPos] = categoriesByXPos[xPos] || {};
            categoriesByXPos[xPos][categoryKey] = averagedData;
            return averagedData;
        });
        collection.push(categoryCollection);
        return collection;
    }, []);

    return {
        averagedResults,
        categoriesByXPos,
    };
}

/**
 * Creates the Frankenstein name from a category. The returned string should match a key in the Driver Events JSON
 * eg. Mean_Gear_Ratio -> Mean_Frank_Gear_Ratio
 * @param {string} - categoryKey
 * @returns {string}
 */
export function getFrankName(categoryKey) {
    const parts = categoryKey.split('_');
    const rest = parts.slice(1, parts.length).join('_');
    return `${parts[0]}_Frank_${rest}`;
}

/**
 * This handles
 * @param {Array} driverEventJSONs - array of driver event JSON chunks
 */
export function calculateDriverEventJSONAverages(driverEventJSONs) {
    const keysToAverage = [
        'Mean_COMP_Lower_Tolerance_Speed_Driver',
        'Mean_Frank_COMP_Lower_Tolerance_Speed_Driver',
        'Mean_Frank_Speed_Lower_Driver',
        'Mean_Frank_Throttle_Position_Driver',
        'Mean_Frank_Trans_Current_Gear_Driver',
        'Mean_Throttle_Position_Driver',
        'Mean_Trans_Current_Gear_Driver',
    ];

    // Only display one of the Retarder charts, dropping the results from other retarder
    const showBrakeLightRetarder = driverEventJSONs.every((chunk) =>
        chunk.Mean_Retarder_Driver.every((d) => d === null),
    );
    if (showBrakeLightRetarder) {
        keysToAverage.push(
            'Mean_Frank_Retarder_Brake_Light_Driver',
            'Mean_Retarder_Brake_Light_Driver',
        );
    } else {
        keysToAverage.push(
            'Mean_Frank_Retarder_Driver',
            'Mean_Retarder_Driver',
        );
    }

    // The values for these keys are the same across all chunks so no ned to average
    const keysToNotAverage = [
        'X',
        'Y',
        'Z',
        'dCOMP_XY_Dist',
        'latitude',
        'longitude',
    ];

    // Get the passcount for each index position in the array
    const passCountCollection = driverEventJSONs.reduce(
        (collection, section) => {
            section.Pass_Count.forEach((d, i) => {
                collection[i] = collection[i] || [];
                collection[i].push(d);
            });
            return collection;
        },
        {},
    );

    // Get the other value for each position in the array multiplied by the passcount the object belongs too
    const combinedRuns = driverEventJSONs.reduce(
        (collection, item, itemIndex) => {
            Object.keys(item)
                .filter((itemKey) => keysToAverage.indexOf(itemKey) > -1)
                .forEach((itemKey) => {
                    item[itemKey].forEach((d, passCountIndex) => {
                        const passCount =
                            passCountCollection[passCountIndex][itemIndex];
                        collection[itemKey] = collection[itemKey] || {};
                        collection[itemKey][passCountIndex] =
                            collection[itemKey][passCountIndex] || [];
                        collection[itemKey][passCountIndex].push(d * passCount);
                    });
                });
            return collection;
        },
        {},
    );

    // Sum up other values & passcount to get the weighted value
    const averagedRuns = Object.keys(combinedRuns).reduce(
        (collection, categoryKey) => {
            collection[categoryKey] = Object.keys(
                combinedRuns[categoryKey],
            ).map((passCountIndex) => {
                const summedOtherValues = sumNumbers(
                    combinedRuns[categoryKey][passCountIndex],
                );
                const summedPassCount = sumNumbers(
                    passCountCollection[passCountIndex],
                );
                return summedOtherValues / summedPassCount;
            });
            return collection;
        },
        {},
    );

    // attach the other fields back on
    keysToNotAverage.forEach((key) => {
        averagedRuns[key] = driverEventJSONs[0][key];
    });

    // attach the total passcounts
    averagedRuns.Pass_Count = Object.keys(passCountCollection).map((index) =>
        sumNumbers(passCountCollection[index]),
    );

    // reset any nan values to null so it doesn't get rendered
    Object.keys(averagedRuns).forEach((key) => {
        averagedRuns[key].forEach((value, i) => {
            if (isNaN(value)) {
                averagedRuns[key][i] = null;
            }
        });
    });
    return averagedRuns;
}
