import React from 'react';
import PropTypes from 'prop-types';
import { truckAlerts as truckAlertsApp } from '../../application';
import PivotGridCell from './PivotGridCell';

function FleetHistoryPivotGridCell({
    dataStatus,
    eventCount,
    colorScale,
    hideCounts = false,
}) {
    switch (dataStatus) {
        case truckAlertsApp.byShiftId.DATA_STATUS.DATA: {
            return (
                <PivotGridCell background={colorScale(eventCount)}>
                    {!hideCounts && eventCount}
                </PivotGridCell>
            );
        }
        case truckAlertsApp.byShiftId.DATA_STATUS.NO_DATA: {
            return <PivotGridCell background={'white'}>-</PivotGridCell>;
        }
        case truckAlertsApp.byShiftId.DATA_STATUS.OFFLINE: {
            return (
                <PivotGridCell className={'Table__Cell--EquipmentOffline'} />
            );
        }
        default: {
            return <PivotGridCell />;
        }
    }
}

FleetHistoryPivotGridCell.propTypes = {
    dataStatus: PropTypes.oneOf(
        Object.values(truckAlertsApp.byShiftId.DATA_STATUS),
    ),
    eventCount: PropTypes.number,
    colorScale: PropTypes.func.isRequired,
    hideCounts: PropTypes.bool,
};

export default FleetHistoryPivotGridCell;
