import React from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = (props) => {
    const { children, enclosingComponent, className } = props;
    // NOTE: Variable name must be capitalized for this to work.
    // https://facebook.github.io/react/docs/jsx-in-depth.html#choosing-the-type-at-runtime
    const EnclosingComponent = `${enclosingComponent}`;

    return (
        <EnclosingComponent className={className}>
            {children}
        </EnclosingComponent>
    );
};

ErrorMessage.defaultProps = {
    enclosingComponent: 'div',
    className: 'ErrorMessage',
};

ErrorMessage.propTypes = {
    enclosingComponent: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.element,
        PropTypes.node,
    ]).isRequired,
    className: PropTypes.string,
};

export default ErrorMessage;
