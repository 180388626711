import { action } from '../../Lib/reduxUtils';

export const LOAD_GROUPS_LIST = 'GroupsList/LOAD_GROUPS_LIST';
export const loadGroupsList = (groupsList) => {
    return action(LOAD_GROUPS_LIST, groupsList);
};

export const REQUEST_GROUPS_LIST = 'GroupsList/REQUEST_GROUPS_LIST';
export const requestGroupsList = () => action(REQUEST_GROUPS_LIST);

export const LINK_TO_PAGE = 'GroupsList/LINK_TO_PAGE';
export const linkToPage = ({ url, groupData }) =>
    action(LINK_TO_PAGE, { url, groupData });

export const DELETE_GROUP = 'GroupsList/DELETE_GROUP';
export const submitDeleteGroup = ({ groupData }) =>
    action(DELETE_GROUP, { groupData });
