import combineReducers from 'combine-reducers-global-state';
import namespace from '../Modules/namespace';
import { reducer as datePicker } from '../Modules/datePicker';
import { reducer as networkCoverageReport } from '../Modules/networkCoverageReport';
import { reducer as spinner } from '../Modules/spinner';
import { reducer as minesiteMap } from '../Modules/minesiteMap';
import { reducer as meta } from '../Modules/meta';
import { reducer as WKTAggregate } from '../Modules/WKTAggregate';

export const REDUCER_KEY = namespace;

export default combineReducers({
    datePicker,
    spinner,
    networkCoverageReport,
    minesiteMap,
    meta,
    WKTAggregate,
});
