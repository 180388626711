import React from 'react';
import { Alert } from '@rs/core/falcon';
import { connect } from 'react-redux';
import { getUnsupportedFeatureWiki } from '../../App/Selectors';
import * as wikiModule from '../../Help/Modules/wiki';
import * as statusModule from '../../Help/Modules/status';
import HelpModal from '../Modal/HelpModal';

const UnsupportedFeatureMessage = ({
    wiki,
    fetchWiki,
    isInProgress,
    helpPageContent,
    hasError,
    ...otherProps
}: UnsupportedFeatureMessageProps) => {
    const [isOpen, toggleOpen] = React.useState(false);

    return (
        <React.Fragment>
            <Alert
                className={'UnsupportedFeatureMessage'}
                type="warning"
                message={
                    <span>
                        This feature is not currently available across all
                        equipment. For a full list of supported equipment, click{' '}
                        <button onClick={() => toggleOpen(true)}>here</button>.
                    </span>
                }
                {...otherProps}
            />
            <HelpModal
                closeModal={() => toggleOpen(false)}
                isOpen={isOpen}
                contentId={wiki.confluenceContentId}
                modalTitle={'Which equipment are supported on MaxMine?'}
            />
        </React.Fragment>
    );
};

interface UnsupportedFeatureMessageProps {
    wiki: {
        confluenceContentId: string;
    };
    fetchWiki: () => any;
    isInProgress: boolean;
    helpPageContent: string;
    hasError: boolean;
}

const mapState = (state: any) => {
    return {
        wiki: getUnsupportedFeatureWiki(state),
        helpPageContent: wikiModule.selectors.getAll(state),
        isInProgress: statusModule.selectors.isInProgress(state),
        hasError: statusModule.selectors.hasError(state),
    };
};

const mapActions = {
    fetchWiki: wikiModule.actions.fetchWikiRequested,
};

export default connect(mapState, mapActions)(UnsupportedFeatureMessage);
