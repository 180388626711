import React from 'react';
import { Carousel as AntdCarousel } from 'antd';
import RightIcon from '@ant-design/icons/RightOutlined';
import LeftIcon from '@ant-design/icons/LeftOutlined';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Carousel = ({ arrows = true, dots = false, ...props }) => {
    return (
        <div className={cn({ Carousel__Container: arrows })}>
            {/* Carousel__Container allows space for arrows */}
            <AntdCarousel
                arrows={arrows}
                dots={dots}
                nextArrow={arrows && <NextArrow />}
                prevArrow={arrows && <PrevArrow />}
                {...props}
            />
        </div>
    );
};

const NextArrow = ({ className, style, onClick, ...props }) => {
    return (
        <RightIcon
            className={cn('Carousel__NextArrow', className)}
            style={style}
            onClick={onClick}
            {...props}
        />
    );
};

const PrevArrow = ({ className, style, onClick, ...props }) => {
    return (
        <LeftIcon
            className={cn('Carousel__PrevArrow', className)}
            onClick={onClick}
            style={style}
            {...props}
        />
    );
};

Carousel.propTypes = AntdCarousel.propTypes;
NextArrow.propTypes = {
    className: PropTypes.string,
    // antd injected prop
    style: PropTypes.shape({}),
    // antd injected prop
    onClick: PropTypes.func.isRequired,
};
PrevArrow.propTypes = {
    className: PropTypes.string,
    // antd injected prop
    style: PropTypes.shape({}),
    // antd injected prop
    onClick: PropTypes.func.isRequired,
};

Carousel.NextArrow = NextArrow;
Carousel.NextArrow.displayName = 'Carousel.NextArrow';
Carousel.PrevArrow = PrevArrow;
Carousel.PrevArrow.displayname = 'Carousel.PrevArrow';

export default Carousel;
