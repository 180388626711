import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchBoxOption, SearchBoxOptions } from '../types';

export type State = {
    options: SearchBoxOptions;
    inputString: string;
};

export type Actions = ReturnType<typeof createCustomSlice>['actions'];

export const createCustomSlice = (name: string) =>
    createSlice({
        name,
        initialState: {
            options: [],
            inputString: '',
        } as State,
        reducers: {
            clearInput(state) {
                state.inputString = '';
            },
            setOptions(state, action: PayloadAction<SearchBoxOptions>) {
                state.options = action.payload;
            },
            setInputString(state, action: PayloadAction<string>) {
                state.inputString = action.payload;
            },
            onClear(_) {},
            selectOption(
                state,
                action: PayloadAction<SearchBoxOption['value']>,
            ) {
                // map the value back to the original label
                const found = state.options.find(
                    (option) => option.value === action.payload,
                );

                if (found === undefined) {
                    throw new Error(
                        'Unable to match the selected value with the options',
                    );
                }

                state.inputString = found.label;
            },
        },
    });
