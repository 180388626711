import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { typeSpinner } from '@rs/core/types';
import { PivotGrid } from '@rs/core/components';
import { Layout } from '@rs/core/falcon';
import { FEATURE_IDS } from '@rs/core/features';
import O4ROperatorFilters from './O4ROperatorFilters';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import * as actions from '../Actions';
import * as spinnerModule from '../Modules/spinner';
import { getFilterOptions } from '../Selectors/filters';
import * as filtersModule from '../Modules/filters';
import { getTableData } from '../Selectors/getTableData';
import { getComponentConfig, getPageTitle } from '../../App/Selectors';
import UnsupportedFeatureMessage from '../../Components/Layout/UnsupportedFeatureMessage';

const { SubHeader, Content } = Layout;

class O4ROperator extends Component {
    componentDidMount() {
        this.props.updateFiltersWithUrlParams();
    }

    renderBody() {
        const { o4rOperatorTableData, spinner } = this.props;

        if (!spinner.isActive && !o4rOperatorTableData.data.length) {
            return 'There are no results for this query';
        }

        return (
            <PivotGrid
                data={o4rOperatorTableData.data}
                columns={o4rOperatorTableData.columns}
            />
        );
    }

    render() {
        const {
            onFilterChange,
            filters,
            filterOptions,
            spinner,
            title,
            hasUnsupportedEquipment,
        } = this.props;

        return (
            <React.Fragment>
                <SubHeader>
                    <SubHeader.Left>
                        <SubHeader.Title>{title}</SubHeader.Title>
                    </SubHeader.Left>
                </SubHeader>
                {hasUnsupportedEquipment && <UnsupportedFeatureMessage />}
                <Content>
                    <LoadingSpinner
                        isActive={spinner.isActive}
                        message={spinner.message}
                    />
                    <O4ROperatorFilters
                        onFilterChange={onFilterChange}
                        filters={filters}
                        filterOptions={filterOptions}
                    />
                    {this.renderBody()}
                </Content>
            </React.Fragment>
        );
    }
}

O4ROperator.propTypes = {
    onFilterChange: PropTypes.func.isRequired,
    filters: PropTypes.shape({
        Date: PropTypes.string.isRequired,
        Shifts: PropTypes.string,
        Crews: PropTypes.string,
        MaxMineAlertId: PropTypes.string,
    }).isRequired,
    filterOptions: PropTypes.shape({
        Shifts: PropTypes.array.isRequired,
        Crews: PropTypes.array.isRequired,
        MaxMineAlertIds: PropTypes.array.isRequired,
    }).isRequired,
    o4rOperatorTableData: PropTypes.shape({
        data: PropTypes.array,
        columns: PropTypes.array,
    }),
    spinner: typeSpinner,
    updateFiltersWithUrlParams: PropTypes.func.isRequired,
    title: PropTypes.string,
    hasUnsupportedEquipment: PropTypes.bool,
};

const mapStateToProps = (state) => {
    const pageConfig = getComponentConfig(state, 'O4ROperator');
    return {
        filters: filtersModule.selectors.getFilters(state),
        filterOptions: getFilterOptions(state),
        o4rOperatorTableData: getTableData(state),
        spinner: spinnerModule.selectors.getSpinnerState(state),
        title: getPageTitle(state, FEATURE_IDS.TAB_ID__O4R_OPERATOR),
        hasUnsupportedEquipment:
            pageConfig && pageConfig.hasUnsupportedEquipment,
    };
};

const mapActionsToDispatch = {
    onFilterChange: actions.filterUpdated,
    updateFiltersWithUrlParams: actions.updateFiltersWithUrlParams,
};

export default connect(mapStateToProps, mapActionsToDispatch)(O4ROperator);
