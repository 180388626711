import { action } from '../../Lib/reduxUtils';
import namespace from '../../NetworkCoverage/Modules/namespace';
import { updateExistingURLQueryString } from '../../Lib/queryStringUtils';

export default function createActions(ns: string) {
    const MAP_VIEWPORT_CHANGED = `${ns}/MAP_VIEWPORT_CHANGED`;
    const mapViewportChanged = (viewport: any) =>
        action(MAP_VIEWPORT_CHANGED, viewport);

    const MAP_FILTER_UPDATED = `${ns}/MAP_FILTER_UPDATED`;
    const mapFilterUpdated = (filterName: string, filterValue: any) =>
        action(MAP_FILTER_UPDATED, { filterName, filterValue });

    const RESET_MAP_SETTINGS = `${ns}/RESET_MAP_SETTINGS`;
    const resetMapSettings = () => action(RESET_MAP_SETTINGS);

    const ON_BOUNDS_CHANGE = `${namespace}/ON_BOUNDS_CHANGE`;
    const onBoundsChange = (payload: any) => {
        const newBoundsAsString = payload.toBBoxString();
        updateExistingURLQueryString(
            { bounds: newBoundsAsString },
            {},
            'replace',
        );
        return action(ON_BOUNDS_CHANGE, payload);
    };

    return {
        MAP_VIEWPORT_CHANGED,
        mapViewportChanged,
        MAP_FILTER_UPDATED,
        mapFilterUpdated,
        RESET_MAP_SETTINGS,
        resetMapSettings,
        ON_BOUNDS_CHANGE,
        onBoundsChange,
    };
}
