import React from 'react';
import PropTypes from 'prop-types';
import {
    LayerGroup,
    LayersControl,
    Marker,
    Pane,
    Polyline,
} from 'react-leaflet';
import { createSVGTextMarker } from './createMarker';
import { ZINDEX } from './constants';

class FocusAreaLabels extends React.PureComponent {
    static propTypes = {
        // TODO data shape
        labels: PropTypes.arrayOf(
            PropTypes.shape({
                color: PropTypes.string,
                heading: PropTypes.number,
                opacity: PropTypes.number,
                position: PropTypes.shape({
                    lat: PropTypes.number,
                    lng: PropTypes.number,
                }),
                rank: PropTypes.number,
                x: PropTypes.number,
                y: PropTypes.number,
            }),
        ).isRequired,
        // The text that will be displayed in the layer control
        name: PropTypes.string.isRequired,
        // The initial state of if to display the layer
        checked: PropTypes.bool.isRequired,
        // The order a layer should be rendered in, higher number displays on top
        priority: PropTypes.number.isRequired,
    };

    render() {
        const {
            labels,
            name,
            checked,
            priority,
            ...leafletInjectedProps
        } = this.props;

        const zIndex = ZINDEX.MAP_PANE + priority;
        const paneId = 'FocusAreaLabels';

        // This renders to 2 panes in order to keep the path from overlapping the marker
        // SVGs don't utilise z-index so the panes are used instead to provide consistent ordering
        return (
            <LayersControl.Overlay
                {...leafletInjectedProps}
                checked={checked}
                name={name}
            >
                <LayerGroup>
                    <Pane name={`${paneId}--Paths`} style={{ zIndex }}>
                        {labels.map((label, i) => {
                            // There's a line which is on the exact point which links to a circle which we've
                            // offset by the following numbers 0.0015 and 0.0025
                            const latlngs = [
                                [
                                    label.position.lat + 0.0015,
                                    label.position.lng - 0.0025,
                                ],
                                [label.position.lat, label.position.lng],
                            ];
                            return (
                                <Polyline
                                    key={i}
                                    positions={latlngs}
                                    color={'black'}
                                    opacity={label.opacity}
                                    weight={4}
                                    stroke={true}
                                />
                            );
                        })}
                    </Pane>
                    <Pane name={`${paneId}--Markers`} style={{ zIndex }}>
                        {labels.map((label, i) => {
                            // There's a line which is on the exact point which links to a circle which we've
                            // offset by the following numbers 0.0015 and 0.0025
                            const latlngs = [
                                [
                                    label.position.lat + 0.0015,
                                    label.position.lng - 0.0025,
                                ],
                                [label.position.lat, label.position.lng],
                            ];
                            return (
                                <Marker
                                    key={i}
                                    opacity={label.opacity}
                                    position={latlngs[0]}
                                    icon={createSVGTextMarker(
                                        label.rank + 1,
                                        label.color,
                                        label.opacity,
                                    )}
                                    zIndexOffset={0}
                                />
                            );
                        })}
                    </Pane>
                </LayerGroup>
            </LayersControl.Overlay>
        );
    }
}

export default FocusAreaLabels;
