import { action } from '../../Lib/reduxUtils';

export default function createActions(namespace) {
    const FETCH_DRIVERS_SUCCESS = `${namespace}/FETCH_DRIVERS_SUCCESS`;
    const fetchDriversSuccess = (resultsArray) =>
        action(FETCH_DRIVERS_SUCCESS, resultsArray);

    const FETCH_DRIVERS_ERROR = `${namespace}/FETCH_DRIVERS_ERROR`;
    const fetchDriversError = ({ error }) =>
        action(FETCH_DRIVERS_ERROR, { error });

    return {
        FETCH_DRIVERS_SUCCESS,
        fetchDriversSuccess,
        FETCH_DRIVERS_ERROR,
        fetchDriversError,
    };
}
