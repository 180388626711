import * as React from 'react';

function SvgBellyDumper(props) {
    return (
        <svg
            width="544"
            height="228"
            viewBox="0 0 544 228"
            fill="none"
            {...props}
        >
            <rect
                width="38"
                height="87"
                transform="matrix(-1 0 0 1 534 93.3613)"
                fill="#FFF200"
            />
            <path
                d="M519 105.361H365V156.361H375.054H408.545H418.174H429.247H447.753H454.639H519V105.361Z"
                fill="#FFF200"
            />
            <circle
                r="47"
                transform="matrix(-1 0 0 1 497 178.361)"
                fill="black"
            />
            <path
                d="M486.859 31.3613L414.963 49.8526L408 93.3613H497L486.859 31.3613Z"
                fill="black"
            />
            <path
                d="M472.847 51.3613L429.224 61.5017L425 85.3613H479L472.847 51.3613Z"
                fill="white"
            />
            <circle
                r="22"
                transform="matrix(-1 0 0 1 499 180.361)"
                fill="white"
            />
            <path
                d="M411 10.914L70.1659 7.36133L36.083 59.8613L1.99998 112.361H375.202L392.846 59.8613L399.029 64.9929L411 49.4666V10.914Z"
                fill="#FFF200"
            />
            <path
                d="M329.988 107.361H3.99999L9.52522 188.361L57.6736 146.289H117.399L126.739 151.531L137.263 164.507L162.39 188.361H291.048L403 111.556L329.988 107.361Z"
                fill="#FFF200"
            />
            <rect
                width="142"
                height="17"
                transform="matrix(-1 0 0 1 534 91.3613)"
                fill="black"
            />
            <rect
                width="89"
                height="14"
                transform="matrix(0 -1 -1 0 540 180.361)"
                fill="black"
            />
            <rect
                width="63"
                height="16"
                transform="matrix(-1 0 0 1 474 148.361)"
                fill="black"
            />
            <rect
                width="45"
                height="15"
                transform="matrix(1 0 0 -1 327 116.361)"
                fill="black"
            />
            <rect
                width="127.614"
                height="15.3988"
                transform="matrix(0.529968 -0.848017 -0.860885 -0.5088 13.3507 116.749)"
                fill="black"
            />
            <path
                d="M15 191.361L15 108.361H-4.33396e-07L-4.33396e-07 191.361H15Z"
                fill="black"
            />
            <path
                d="M14.8352 191.394L33.0009 174.347L22.3666 163.091L4.2009 180.138L14.8352 191.394Z"
                fill="black"
            />
            <path
                d="M160 190.361L291 190.361V175.361L160 175.361V190.361Z"
                fill="black"
            />
            <path
                d="M320.297 152.155L281.833 177.806L290.46 190.655L328.924 165.004L320.297 152.155Z"
                fill="black"
            />
            <path
                d="M139.205 168.608L160.483 190.774L171.692 180.087L150.414 157.92L139.205 168.608Z"
                fill="black"
            />
            <path
                d="M13 116.361L330 116.361V101.361L13 101.361L13 116.361Z"
                fill="black"
            />
            <circle
                r="47"
                transform="matrix(-1 0 0 1 90 180.361)"
                fill="black"
            />
            <circle
                r="22"
                transform="matrix(-1 0 0 1 90 180.361)"
                fill="white"
            />
            <rect
                width="126.137"
                height="15.416"
                transform="matrix(-0.503643 0.863912 0.875486 0.483243 421.967 0)"
                fill="black"
            />
            <rect
                width="53.714"
                height="15.4281"
                transform="matrix(0.0210915 0.999778 0.999142 -0.0414266 390.729 52.4321)"
                fill="black"
            />
            <rect
                width="356"
                height="15"
                transform="matrix(1 0 0 -1 66 15.3613)"
                fill="black"
            />
            <path
                d="M328 180.361C328 206.319 349.043 227.361 375 227.361C400.957 227.361 422 206.319 422 180.361C422 154.404 400.957 133.361 375 133.361C349.043 133.361 328 154.404 328 180.361Z"
                fill="black"
            />
            <circle
                r="22"
                transform="matrix(-1 0 0 1 375 180.361)"
                fill="white"
            />
            <path
                d="M286.935 3.0531L253.395 36.1313L262.091 47.8875L295.631 14.8093L286.935 3.0531Z"
                fill="black"
            />
            <path
                d="M228.783 3.0531L195.243 36.1313L203.938 47.8875L237.479 14.8093L228.783 3.0531Z"
                fill="black"
            />
            <path
                d="M170.589 3.0531L137.048 36.1313L145.744 47.8875L179.284 14.8093L170.589 3.0531Z"
                fill="black"
            />
        </svg>
    );
}

export default SvgBellyDumper;
