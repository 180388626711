import React from 'react';
import { createSelector } from 'reselect';
import { scaleLinear } from 'd3-scale';
import { max } from 'd3-array';
import { PivotGridCell } from '@rs/core/components';
import { arrayToGroupedObject, arrayToObject } from '@rs/core/utils/dataUtils';
import { selectors as filtersSelectors } from '../Modules/filters';
import { selectors as driversSelectors } from '../Modules/drivers';
import { selectors as o4rOperatorByDriverShiftSelectors } from '../Modules/o4rOperatorByDriverShift';
import { sortAlphabetically } from '../../Utils/Filters/filters';
import OperatorDisplayName from '../../Components/OperatorDisplayName/OperatorDisplayName';

export const getTableData = createSelector(
    filtersSelectors.getFilters,
    driversSelectors.getAll,
    o4rOperatorByDriverShiftSelectors.getAll,
    (filters, driverList, o4rOperators) => {
        if (!Array.isArray(o4rOperators) || !o4rOperators.length) {
            return { data: [], columns: [] };
        }

        const byUniqueDriverId = arrayToGroupedObject(
            o4rOperators,
            'UniqueDriverId',
        );

        const uniqueHaulTruckSiteNames = Array.from(
            o4rOperators.reduce((collection, row) => {
                collection.add(row.HaulTruckSiteName);
                return collection;
            }, new Set()),
        ).sort(sortAlphabetically);

        // All the possible categories
        const categories = [
            'AbusiveShift',
            'BodyUpGroundSpeed',
            'BrakeTemperature',
            'EngineOverspeed',
            'PayloadOverload',
            'EnginePrelubeOverride',
        ];

        // The default object when there isn't a result
        const alertCategories = categories.reduce(
            (obj, category) => {
                obj[category] = null;
                return obj;
            },
            { total: null },
        );

        // For every driver create an entry for every truck
        const trucksByDrivers = Object.keys(byUniqueDriverId).map(
            (uniqueDriverId) => {
                // Create a lookup of all the trucks the drivers been on
                const byTruckName = arrayToObject(
                    byUniqueDriverId[uniqueDriverId],
                    'HaulTruckSiteName',
                );

                // Create an entry for every truck
                const driverRow = uniqueHaulTruckSiteNames.reduce(
                    (row, truckSiteName) => {
                        const driverTruckResult = byTruckName[truckSiteName];
                        if (!driverTruckResult) {
                            row[truckSiteName] = alertCategories;
                        } else {
                            const total = categories
                                .filter(
                                    (category) => driverTruckResult[category],
                                )
                                .reduce((tally, category) => {
                                    return tally + driverTruckResult[category];
                                }, null);

                            // Normalise variables into camelCase
                            row[truckSiteName] = {
                                abusiveShift: driverTruckResult.AbusiveShift,
                                bodyUpGroundSpeed:
                                    driverTruckResult.BodyUpGroundSpeed,
                                brakeTemperature:
                                    driverTruckResult.BrakeTemperature,
                                engineOverspeed:
                                    driverTruckResult.EngineOverspeed,
                                payloadOverload:
                                    driverTruckResult.PayloadOverload,
                                total,
                            };
                        }
                        return row;
                    },
                    {},
                );

                // Calculate the total for a operator
                const rowTotal = uniqueHaulTruckSiteNames.reduce(
                    (tally, truckName) => {
                        const truckTotal = driverRow[truckName].total;
                        return tally + truckTotal;
                    },
                    null,
                );

                const { Worker } = byUniqueDriverId[uniqueDriverId].find(
                    (row) =>
                        String(row.UniqueDriverId) === String(uniqueDriverId),
                );
                return {
                    worker: Worker,
                    uniqueDriverId,
                    ...driverRow,
                    rowTotal,
                };
            },
        );

        const truckColumns = uniqueHaulTruckSiteNames.map((truckName) => ({
            key: truckName,
            header: () => (
                <PivotGridCell rotated={true}>{truckName}</PivotGridCell>
            ),
            cell: (row) => (
                <PivotGridCell
                    background={`rgba(245, 127, 23, ${row.alphaScale(
                        row[truckName].total,
                    )})`}
                >
                    {row[truckName].total}
                </PivotGridCell>
            ),
            footer: (d) => d[truckName].truckTotal,
            width: 40,
        }));

        const columns = [
            {
                key: 'Operator',
                header: () => 'Operator',
                cell: (d) => (
                    <PivotGridCell
                        ellipsis={true}
                        style={{
                            width: 200,
                        }}
                    >
                        <OperatorDisplayName operatorName={d.worker} />
                    </PivotGridCell>
                ),
                footer: (d) => d.grandTotal,
                width: 220,
            },
            ...truckColumns,
            {
                key: 'RowTotal',
                header: () => (
                    <PivotGridCell rotated={true}>Total</PivotGridCell>
                ),
                cell: (row) => {
                    return (
                        <PivotGridCell
                            background={`rgba(245, 127, 23, ${row.alphaScale(
                                row.rowTotal,
                            )})`}
                        >
                            {row.rowTotal}
                        </PivotGridCell>
                    );
                },
                footer: (d) => d.grandRowTotal,
                width: 40,
            },
        ];

        // Calculate the totals for truck columns
        const footerRow = uniqueHaulTruckSiteNames.reduce(
            (hash, truckName) => {
                hash[truckName] = {};
                hash[truckName].truckTotal = trucksByDrivers.reduce(
                    (tally, row) => {
                        return tally + row[truckName].total;
                    },
                    null,
                );
                return hash;
            },
            {
                grandTotal: 'Grand Total',
            },
        );

        // Calculate the sum of all the row totals
        footerRow.grandRowTotal = trucksByDrivers.reduce((tally, row) => {
            return tally + row.rowTotal;
        }, null);

        // Create the alphaScale for a row
        trucksByDrivers.forEach((row) => {
            const maxForRow = max(
                uniqueHaulTruckSiteNames.map(
                    (truckName) => row[truckName].total,
                ),
            );
            row.alphaScale = scaleLinear().domain([0, maxForRow]).range([0, 1]);
        });

        const sortedTrucksByDrivers = trucksByDrivers.sort((a, b) =>
            sortAlphabetically(a.worker, b.worker),
        );

        return {
            data: [...sortedTrucksByDrivers, footerRow],
            columns,
        };
    },
);
