import { createReducer } from '../../Lib/reduxUtils';

export const initialState = {
    hasLoaded: false,
    inProgress: true,
    error: '',
};

const onRequest = (state) => ({
    ...state,
    inProgress: true,
    error: '',
});

const onSuccess = (state) => ({
    ...state,
    hasLoaded: true,
    inProgress: false,
    error: '',
});

const onError = (state, { error }) => ({
    ...state,
    hasLoaded: true,
    inProgress: false,
    error,
});

/**
 * Creates a reducer for this module. It has some default behaviour but also allows custom actions to be supplied
 * @param {object} actions {<internalActionName>: <ACTION_TYPE>} maps an internalActionName to an ACTION_TYPE. The handler for this ACTION_TYPE is the default behaviour
 * @param {object} customActions {<ACTION_TYPE>: actionHandler} maps an ACTION_TYPE to an action handler
 * @return {function(*=, {type: *, payload?: *})}
 */
export default function createReducers(
    actions,
    customInitialState = initialState,
    customActions = {},
) {
    return createReducer(customInitialState, {
        [actions.onSuccess]: onSuccess,
        [actions.onRequest]: onRequest,
        [actions.onError]: onError,
        ...customActions,
    });
}
