import { createSelector } from 'reselect';
import { selectors as minesiteMapSelector } from '../Modules/minesiteMap';
import * as datePickerModule from '../Modules/datePicker';

const getPageParams = createSelector(
    datePickerModule.selectors.getAll,
    minesiteMapSelector.get,
    (datePickerState, minesiteMapState) => {
        const { startTime, endTime, mode } = datePickerState;
        const {
            boundsAsString,
            satellite,
            aerial,
            roadPath,
        } = minesiteMapState;
        return {
            //date picker state
            startTime,
            endTime,
            mode,
            // minesite map coverage state
            satellite,
            aerial,
            roadPath,
            bounds: boundsAsString,
        };
    },
);
export default getPageParams;
