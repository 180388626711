import { createSelector } from 'reselect';
import * as spinnerModule from '../Modules/spinner';

const getPreviousApiParams = createSelector(
    spinnerModule.selectors.getSpinnerState,
    (spinnerState) => {
        return spinnerState;
    },
);
export default getPreviousApiParams;
