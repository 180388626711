import shiftUtils from '@rs/core/utils/shiftUtils';
import { createSelector } from 'reselect';
import {
    filterOptionsToReactSelectFormat,
    filterShiftOptionsToReactSelectFormat,
} from '../../../Utils/Filters/filterOptionsToReactSelectFormat';
import { selectors as filtersSelectors } from '../Modules/filters';
import { selectors as truckAlertEventsSelectors } from '../Modules/truckAlertEvents';
import { SELECT_STRING_DELIMITER } from '../../../Components/Select';
import { getFilteredTruckAlertEvents } from './truckAlerts';
import { getFilterOptionsFromArray } from '../../../Utils/Filters/filters';

export const getFilterOptions = createSelector(
    filtersSelectors.getFilters,
    truckAlertEventsSelectors.getAll,
    getFilteredTruckAlertEvents,
    (filters, truckAlertEvents) => {
        const shifts = shiftUtils.getAllShiftShortNamesForDate(filters.Date);
        const truckNameSelector = (d) => d.TruckName;
        const alertTypeSelector = (d) => d.AlertType;
        const alertSeveritySelector = (d) => d.AlertLevel;
        const alertCodeSelector = (d) => d.ManufacturerAlertId;

        const truckNameOptions = getFilterOptionsFromArray(
            truckAlertEvents,
            [
                [alertTypeSelector, filters.AlertType],
                [alertSeveritySelector, filters.AlertSeverity],
                [alertCodeSelector, filters.AlertCode],
            ],
            truckNameSelector,
        );

        const alertTypeOptions = getFilterOptionsFromArray(
            truckAlertEvents,
            [
                [truckNameSelector, filters.Truck],
                [alertSeveritySelector, filters.AlertSeverity],
                [alertCodeSelector, filters.AlertCode],
            ],
            alertTypeSelector,
        );

        const alertSeverityOptions = getFilterOptionsFromArray(
            truckAlertEvents,
            [
                [truckNameSelector, filters.Truck],
                [alertTypeSelector, filters.AlertType],
                [alertCodeSelector, filters.AlertCode],
            ],
            (d) =>
                d.AlertLevel !== null
                    ? alertSeveritySelector(d).toString()
                    : undefined,
        );

        const alertCodeOptions = getFilterOptionsFromArray(
            truckAlertEvents,
            [
                [truckNameSelector, filters.Truck],
                [alertTypeSelector, filters.AlertType],
                [alertSeveritySelector, filters.AlertSeverity],
            ],
            alertCodeSelector,
        );

        return {
            shifts: filterShiftOptionsToReactSelectFormat(
                shifts.rawShiftsInDay,
                shiftUtils.getShortNamesFromRawShiftsInDay(
                    shifts.rawShiftsInDay,
                ),
            ),
            trucks: filterOptionsToReactSelectFormat(truckNameOptions),
            alertTypes: filterOptionsToReactSelectFormat(alertTypeOptions),
            alertSeverity: filterOptionsToReactSelectFormat(
                alertSeverityOptions,
            ),
            alertCodes: filterOptionsToReactSelectFormat(alertCodeOptions),
            inWorkshop: [
                { label: 'Exclude Workshop', value: '0' },
                { label: 'Workshop Only', value: '1' },
            ],
        };
    },
);

export const getMapFiltersToAPIParams = createSelector(
    filtersSelectors.getFilters,
    (filters) => {
        if (!filters.Date) {
            return {};
        }
        const shiftIdsAll = shiftUtils.getAllFirstLastFromArray(
            shiftUtils.generateShiftIdRange(filters.Date, filters.EndDate),
        );
        const shiftIdsSelectedShifts = shiftUtils.getAllFirstLastFromArray(
            shiftUtils.generateShiftIdRange(
                filters.Date,
                filters.EndDate,
                filters.Shift.split(SELECT_STRING_DELIMITER),
            ),
        );
        const params = {
            shiftIdsAll,
            shiftIdsSelectedShifts,
        };

        const filterNamesToAPIName = {
            AlertType: 'AlertType',
            AlertSeverity: 'AlertLevel',
            AlertCode: 'MaxMineAlertId',
        };

        Object.keys(filterNamesToAPIName).forEach((filterName) => {
            if (filters[filterName] && filters[filterName].length > 0) {
                const apiName = filterNamesToAPIName[filterName];
                params[apiName] = filters[filterName];
            }
        });
        return params;
    },
);
