import * as React from 'react';

function SvgShovel(props) {
    return (
        <svg viewBox="0 0 1024 1024" {...props}>
            <path d="M1015.08 313.5a134.57 134.57 0 008.9-50.37l-2.42-149.75L785 61.83A64.82 64.82 0 00718.15 88l-114 162.79v-63.46l47.38-89.62H380.76l-36.34 140.13v62.76H0v311.88h343.25v70.33l-186-.09c-77.7 0-140.91 63-140.91 140.47s63.21 140.47 140.91 140.47h563.51c77.7 0 140.91-63 140.91-140.47 0-74.55-58.55-135.71-132.22-140.19l-123.65-.06.34-186.94 235.27-216.69 3.69 168.3L769.22 524c-20.86 42.3-20.94 54.87 6.41 97.63l107.46 79.27 114.48 28.36-56.76-82 14.89-178.78zm-42.35-59.57a134.63 134.63 0 01-8.89 50.89l-49.25 128.55-18.77 3.08-4.45-203.15 79.48-73.21zm-577.36-13.19l24.81-92.08h146.73l-13.76 26V300.6H395.37zm325.39 522.71c50.55 0 60 42.13 60 59.74 0 21.61-10.85 59.74-60 59.74H157.25c-51 0-60-34.71-60-59.74 0-24.51 9.67-59.74 60-59.74zm-84-365.06l-81.52 68.48v94.66H51v-210h585.71zm50.95-46.73V300.6h-56.32l110.06-166.85c15-21.39 33.2-28.64 58.72-23.07L915 142.29z" />
            <path
                fill="#fff200"
                d="M50.95 561.53h504.24v-94.65l81.52-68.49v-46.84H50.95v209.98zM800.17 110.68c-25.52-5.57-43.74 1.68-58.72 23.07L631.39 300.6h56.27v51.06L915 142.29zM970.85 160.09l-79.48 73.21 4.45 203.15 18.77-3.08 49.25-128.55a134.63 134.63 0 008.89-50.89z"
            />
        </svg>
    );
}

export default SvgShovel;
