import { all, put, select, call, takeLatest } from 'redux-saga/effects';
import shiftUtils from '@rs/core/utils/shiftUtils';
import { operator } from '@rs/core/application';
import { actions } from '../LoaderLeague';
import {
    getMapFiltersToAPIParams,
    getFilters,
} from '../LoaderLeague/Selectors/filters';
import {
    convertURLQueryStringToObject,
    updateURLQueryString,
    getQueryString,
} from '../Lib/queryStringUtils';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import * as resourceSagas from './resourceSagas';
import { forwardTo } from '../App/createHistory';

export function* filterUpdated() {
    const filters = yield select(getFilters);
    // If dates are invalid go back to waiting for FILTER_UPDATED
    if (
        !shiftUtils.isDateStringValid(
            filters.Date,
            shiftUtils.DATE_FORMAT__VALIDATE,
        ) ||
        (filters.rangeSelected &&
            !shiftUtils.isDateStringValid(
                filters.EndDate,
                shiftUtils.DATE_FORMAT__VALIDATE,
            ))
    ) {
        return;
    }
    yield put(actions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA));
    yield all([
        call(getLoadUnitOperatorLogs),
        call(getLoadingEventsConformanceSymbologyReport),
        call(getLoadUnitConformanceWeightings),
    ]);

    updateURLQueryString(filters);
    yield put(actions.setSpinnerState(false));
}

export function* getLoadUnitOperatorLogs() {
    const { ShiftIdDayAndNight } = yield select(getMapFiltersToAPIParams);
    const { response, error } = yield call(
        resourceSagas.getLoadUnitOperatorLogs,
        {
            ShiftId: ShiftIdDayAndNight,
        },
    );
    if (error) {
        return;
    }
    yield put(actions.loadLoaderOperatorLogs(response));
}

export function* getLoadingEventsConformanceSymbologyReport() {
    const { ShiftIdDayAndNight, ...params } = yield select(
        getMapFiltersToAPIParams,
    );
    const { response, error } = yield call(
        resourceSagas.getLoadingEventsConformanceSymbologyReport,
        {
            ...params,
            EventTimeLimit: 1000,
        },
    );
    if (error) {
        return;
    }
    yield put(actions.loadLoadingEvents(response));
}

function* getLoadUnitConformanceWeightings() {
    const { ShiftId } = yield select(getMapFiltersToAPIParams);
    const {
        response,
        error,
    } = yield call(resourceSagas.getLoadUnitConformanceWeightings, { ShiftId });
    if (error) {
        return;
    }
    yield put(actions.fetchLoadUnitConformanceWeightingsSuccess(response));
}

export function* updateFiltersWithURLParams() {
    yield put(actions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA));
    const urlFilterValues = convertURLQueryStringToObject();
    yield put(actions.setFiltersWithUrlParams(urlFilterValues));
    yield all([
        call(getLoadUnitOperatorLogs),
        call(getLoadingEventsConformanceSymbologyReport),
        call(getLoadUnitConformanceWeightings),
    ]);
    yield put(actions.setSpinnerState(false));
}

export function* linkToPage({ payload }) {
    const filters = yield select(getFilters);

    let OperatorId = operator.operatorNameToUniqueId(
        payload.operatorDisplayName,
    );
    if (OperatorId === -1) OperatorId = '';

    if (
        payload.page === 'LoadsInRangeDetail' ||
        payload.page === 'LoadingTimeDetail'
    ) {
        forwardTo(
            `${payload.url}?${getQueryString({
                ...filters,
                LoadUnit: filters.Loader,
                Operator: OperatorId,
            })}`,
        );
    }
    if (payload.page === 'BiasLoadsDetail') {
        forwardTo(
            `${payload.url}?${getQueryString({
                ...filters,
                Operator: payload.operatorDisplayName,
            })}`,
        );
    }
}

export default function* watch() {
    yield all([
        takeLatest(actions.LINK_TO_PAGE, linkToPage),
        takeLatest(
            actions.UPDATE_FILTERS_WITH_URL_PARAMS,
            updateFiltersWithURLParams,
        ),
        takeLatest(actions.FILTER_UPDATED, filterUpdated),
    ]);
}
