import React from 'react';
import { Row as AntRow } from 'antd';
import PropTypes from 'prop-types';
import CONSTANTS from '../../constants';

// Recommended way to deal with vertical spacing
// https://github.com/ant-design/ant-design/issues/4410
const Row = ({ vgutter = 0, gutter = CONSTANTS.spacing.md, ...props }) => {
    return (
        <AntRow style={{ marginBottom: vgutter }} gutter={gutter} {...props} />
    );
};

Row.propTypes = {
    // Add vertical spacing between the rows
    vgutter: PropTypes.number,
    ...AntRow.propTypes,
};

export default Row;
