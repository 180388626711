import { put, select, call, takeLatest, all } from 'redux-saga/effects';
import shiftUtils from '@rs/core/utils/shiftUtils';
import { actions } from '../HaulTruckFillFactor';
import { getFilters } from '../HaulTruckFillFactor/Selectors/filters/getFilters';
import { getMapFiltersToAPIParams } from '../HaulTruckFillFactor/Selectors/filters/getMapFiltersToAPIParams';
import {
    convertURLQueryStringToObject,
    updateURLQueryString,
} from '../Lib/queryStringUtils';
import { LOADING_MESSAGES } from '../Components/LoadingSpinner';
import * as resourceSagas from './resourceSagas';

export function* getHaulTruckFillFactorReport() {
    const params = yield select(getMapFiltersToAPIParams);
    const results = yield call(resourceSagas.getHaulTruckFillFactorReport, {
        ...params,
        IncludeNullResults: true,
        IsBadPayload: [0, 1],
    });
    if (results.error) {
        yield put(
            actions.fetchHaulTruckFillFactorError({
                error: 'There were no results found for this query',
            }),
        );
        return;
    }
    yield put(actions.fetchHaulTruckFillFactorSuccess(results.response));
}

export function* filterUpdated({ payload }) {
    const filters = yield select(getFilters);
    // Data is fetched for all shifts & then filtered on the frontend, so unless the Date filters are changed new data isn't required
    if (
        payload.filterName === 'Shift' ||
        payload.filterName === 'TruckId' ||
        payload.filterName === 'TruckType'
    ) {
        updateURLQueryString(filters);
        return;
    }
    // If dates are invalid go back to waiting for FILTER_UPDATED
    if (
        !shiftUtils.isDateStringValid(
            filters.Date,
            shiftUtils.DATE_FORMAT__VALIDATE,
        ) ||
        (filters.rangeSelected &&
            !shiftUtils.isDateStringValid(
                filters.EndDate,
                shiftUtils.DATE_FORMAT__VALIDATE,
            ))
    ) {
        return;
    }
    yield put(actions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA));
    yield call(getHaulTruckFillFactorReport);
    updateURLQueryString(filters);
    yield put(actions.setSpinnerState(false));
}

export function* updateFiltersWithURLParams() {
    yield put(actions.setSpinnerState(true, LOADING_MESSAGES.LOADING__DATA));
    const urlFilterValues = convertURLQueryStringToObject();
    yield put(actions.setFiltersWithUrlParams(urlFilterValues));
    yield call(getHaulTruckFillFactorReport);
    yield put(actions.setSpinnerState(false));
}

export default function* watch() {
    yield all([
        takeLatest(
            actions.UPDATE_FILTERS_WITH_URL_PARAMS,
            updateFiltersWithURLParams,
        ),
        takeLatest(actions.FILTER_UPDATED, filterUpdated),
    ]);
}
